import { useEffect } from "react";
import 'vanilla-cookieconsent';
import pluginConfig from "./CookieConsentConfig";

const CookieConsentComponent = () => {
  useEffect(() => {
    if (!document.getElementById('cc--main')) {
      window.CC = window.initCookieConsent();
      window.CC.run(pluginConfig);
    }
  }, []);

  return null;
};

export default CookieConsentComponent;