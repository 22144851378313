import React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from 'redux/store'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './global.scss'

const initialState = global.window && global.window.__PRELOADED_STATE__
const store = configureStore(initialState)

// global.window.snapSaveState = () => ({
//   __PRELOADED_STATE__: store.getState()
// })

// if (rootElement.hasChildNodes()) {
hydrate(<App store={store} />, document.getElementById('root'))
// } else {
//   render(<Provider store={store}><App /></Provider>, rootElement)
// }

// ReactDOM.hydrate(<Provider store={store}><App /></Provider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
