import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Link,
} from 'react-router-dom'
import {
  Button, 
  List,
  Input,
  Table
} from 'antd'
import {
  getGroupList,
  removeStudentFromGroup,
  approveStudentGroup,
  rejectStudentGroup,
  getGroupData,
  getExamsInGroup,
  getExamsNotInGroup,
  addExams,
  removeExam,
  updateExam,
  getListOfStudents,
} from '../../redux/group/action'
import {
  IconDelete,
} from '../IconSVG' 
import IconSearch from '../IconSVG/Search.svg'
import styles from './Group.module.scss'
import moment from 'moment'
import DefaultGroup from '../IconSVG/DefaultGroup.svg'
 
class ListStudentLeft extends Component {
  columns = [
    { title: '',
      dataIndex: 'profileImage',
      key: 'logo',
      align: 'center',
      render: logo => <img src={logo ? logo : DefaultGroup} alt='group_logo' className={styles.logoGroup}/>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'fullname',
      key: 'name',
    },
    {
      title: 'รหัสกลุ่ม',
      dataIndex: 'groupCode',
      key: 'groupCode',
    },
    {
      title: 'ชื่อกลุ่ม',
      dataIndex: 'groupName',
      key:'groupName'
    },
    {
      title: 'โรงเรียน',
      dataIndex: 'schoolName',
      key:'schoolName'
    },
    {
      title: 'วันที่ออกจากกลุ่ม',
      dataIndex: 'leftDate',
      key:'leftDate'
    }
  ]
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      studentJoinList: [],
      studentRequestList: [],
      studentGroupRequest: [],
      examsInGroup: [],
      filterExamsInGroup: [],
      examsNotInGroup: [],
      groupId: null,
      isOpenAddExamModal: false,
      listOfStudents: null,
      studentJoinGroup: [],
      filterStudentJoinGroup : [],
      studentRequestGroup: [],
      filterStudentRequestGroup: [],
      value: '',
      tabActiveKey : 1,
      placeholderInGroupDetail : props.auth.role === 'admin' ? 'ค้นหา (ชื่อ, โรงเรียน)' : 'ค้นหา (วิชา, รหัสข้อสอบ)',
      examsStudent : null,
      searchGroup: null,
      isShowQrCodeModal: false,
      isSearchModal: false,
      searchData: [],
      studentName : ''
    }
    if (props.auth.role === 'student'){
      this.setState({
        placeholderInGroupDetail : 'ค้นหา (ชื่อ, โรงเรียน)'
      })
      this.columns = this.columns.concat(
        [
          {
            title: 'สถานะ',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, group) => (
              <div>
                {(() => {
                  switch (group.status) {
                    case 'request':
                      return (<span>รอการอนุมัติ</span>)
                    default: return ''
                  }
                })()}
              </div>
            )
          },
          {
            title: '',
            key: 'action',
            render: (text, group) => (
              <div>
                {(() => {
                  switch (group.status) {
                    case 'request':
                      return (<Button
                        className={styles.btnCancel}
                        onClick={(e) => {
                          e.stopPropagation()
                          this.onCancelRequest(group)
                        }}
                      >ยกเลิกคำขอ</Button>)
                    case 'join':
                      return (
                        <span style={{ cursor: 'pointer' }} onClick={ e => {
                          e.stopPropagation()
                          this.onLeaveGroup(group)
                        }}>
                          <IconDelete style={{fontSize: 24, verticalAlign: 'text-bottom', marginRight: 5}}/>
                          <span style={{textDecoration: 'underline'}}>คัดออกจากกลุ่ม</span>
                        </span>)
                    default: return ''
                  }
                })()}
              </div>
            ),
          },
        ]
      )
    }
  }
  extractName = (arr) =>{
    try{
      return arr.map((n) => ({
        fullname : n.prefixName+' '+n.firstName+' '+n.lastName,
        groupCode : n.groupCode,
        groupName : n.groupName,
        leftDate : moment.unix(n.leftDate).format('DD/MM/YYYY'),
        profileImage : n.profileImage,
        schoolName : n.schoolName,
        teacherSeenLeft : n.teacherSeenLeft,
        _id : n._id
      }))
    }catch (e) {
      return []
    }
  }

  componentDidMount = async () => {
    const arr = await this.props.listOfStudents
    const listStudent = await this.extractName(arr)
    this.setState({
      studentName : listStudent
    })
  }
  render() {
    const searchKey = this.state.value
    let header , listStudentTable, listViewMobile
    const {studentName} = this.state
    // const dataTest = [{'_id':'5ddf80a421c79802729d3f4c','profileImage':'http://52.74.3.6:3100/storage/images/profile/profile-5ddf80a421c79802729d3f4c1576394994.png','prefixName':'นาย','firstName':'Student','lastName':'Tester','groupName':'Removed คัดออก','groupCode':'TT968','schoolName':'สามเสนวิทยาลัย','leftDate':1576597905,'teacherSeenLeft':false},
    //   {'_id':'5ddf80a421c79802729d3f4c','profileImage':'http://52.74.3.6:3100/storage/images/profile/profile-5ddf80a421c79802729d3f4c1576394994.png','prefixName':'นาย','firstName':'Student','lastName':'Tester','groupName':'Removed คัดออก','groupCode':'TT968','schoolName':'สามเสนวิทยาลัย','leftDate':1576597905,'teacherSeenLeft':false}]
    // const abc = this.extractName(dataTest) 
    header = (
      <div className='flex-space-between group-detail'>
        <div className='pagination'>
          <Link style={{color: '#006FAD', margin: 0}} to='/group'>กลุ่ม</Link>
          <span style={{color: '#828282'}}> {'>' +' รายชื่อคนออกจากกลุ่ม'}</span>
        </div>

        <div className='actions'>
          <div className='mobile'>
          </div>

          <div className='desktop'>
            {
              <div style = {{display : 'flex'}}>
                <Input
                  suffix={
                    <Button
                      type='primary'
                      style={{paddingLeft: 5, paddingRight: 10}}
                      onClick={(e) => {
                        e.stopPropagation()
                        this.searchStudentJoinGroup(searchKey)
                      }}>
                      <img src={IconSearch} alt='icon-search'/>ค้นหา
                      {/* <IconSearch style={{verticalAlign: 'middle'}}/>ค้นหา */}
                    </Button>}
                  placeholder={this.state.placeholderInGroupDetail}
                  maxLength={100}
                  className={styles.inputSearch}
                  onPressEnter={e => {
                    e.stopPropagation()
                    this.searchStudentJoinGroup(e.target.value)
                  }}
                  onChange={(e)=>this.setSearchKey(e.target.value)}
                />
              </div>
            }
          </div>
        </div>
      </div>
    )
    listStudentTable = (
      <div>
        <Table
          columns={this.columns}
          dataSource={this.state.studentName ? this.state.studentName : []}
          pagination={false}
          rowKey={ (record,i) => i}
        />
      </div>
    )
    listViewMobile = (
      <div>
        <div className='list-items'> 
          <List
            itemLayout='horizontal'
            dataSource={studentName}
            renderItem={item => {
              return (
                (
                  <List.Item>
                    {
                      item.profileImage ? (
                        <List.Item.Meta
                          avatar={<img src={item.profileImage} />}
                          title={item.fullname}
                          description={item.groupName}
                        />
                      ) : (
                        <List.Item.Meta
                          title={item.fullname}
                          description={item.groupName}
                        />
                      )
                    }
                    {                     
                      <div className='status'>
                        {item.leftDate}
                      </div>
                    }                    
                  </List.Item>
                )
              )
            }}
          />
        </div>       
      </div>
    )
    return(
      <div className = {styles.divListStudentLeft}>
        {header}
        <div className = {styles.desktopTable}>
          {listStudentTable}
        </div>
        <div className = {styles.mobileList}>
          {listViewMobile}
        </div>
      </div>
    )
  }   
}

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth.profile,
    group: state.group.groupList && state.group.groupList.find(group => group.id === props.match.params.id),
    exams: state.group.examsInGroup,
    listOfStudents: state.group.studentLeftList
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getGroupList,
      removeStudentFromGroup,
      approveStudentGroup,
      rejectStudentGroup,
      getGroupData,
      getExamsInGroup,
      getExamsNotInGroup,
      addExams,
      removeExam,
      updateExam,
      getListOfStudents,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ListStudentLeft)
