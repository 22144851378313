import React, { Component } from 'react'
import { Spin } from 'antd'

import Header from '../Header.js'
import Overview from '../Overview.js'

class ReportLoginOverview extends Component {
  static defaultProps = {
    collection: [],
    isLoading: false
  }

  render(){
    const { collection, isLoading } = this.props  
    return (
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading }>
          <Header  
            headerName='รายงานล็อคอิน'
            closeSearch={this.closeSearch}
            match={this.props.match}
            data={collection}
            year={this.props.year}
            spinOnLoading={this.props.spinOnLoading}
            spinOffLoading={this.props.spinOffLoading}
            getTransaction={this.props.getTransaction}
            getTransaction2={this.props.getTransaction2}
          />

          <Overview 
            typeStatic={'login'}
            loadStats={this.props.loadStats}
            data={collection}
            loadDetail={this.props.loadDetail}
            history={this.props.history}
            match={this.props.match}
            setYear={this.props.setYear}
            setMonth={this.props.setMonth}
            year={this.props.year}
            detail=''
          />
        </Spin>
      </div>)
  }
}

export default ReportLoginOverview
