import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  Spin,
  Input,
  Col,
  message,
  Row,
  Select,
  Form,
  Button,
  Modal
} from 'antd'
import {
  GetProfileDetail,
} from '../../redux/auth/action'
import {
  onSentFaqToUs
} from '../../redux/contact/action'
import styles from './ContactUsMember.module.scss'
import AddressIcon from '../IconSVG/AddressIcon.svg'
import TelephoneIcon from '../IconSVG/icon_tel.svg'
import FacebookIcon from '../IconSVG/icon_facebook_contact.svg'
import MailIcon from '../IconSVG/icon_mail.svg'
import { IconCorrect } from '../IconSVG'
import {
  GetContactDetail
} from '../../redux/master/action'
const { Option } = Select
const { TextArea } = Input
class ContactUsMember extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errorLogin: null,
      faqDetail:'',
      dataForSearch : {},
      UserDetail: [],
      faqData : {},
      name : '',
      email : '',
      title : '',
      message : '',
      telExtracted : [],
      optionTilte : [
        {value : 'สมัครสมาชิก', text : 'สมัครสมาชิก', key:'1'},
        {value : 'เข้าสู่ระบบ', text : 'เข้าสู่ระบบ', key : '2'},
        {value : 'คำถามอื่นๆ', text : 'คำถามอื่นๆ', key : '3'}
      ]
    }
  }
  componentDidMount = async() =>{
    await this.fetchGetUserDetail()
    await this.fetchContactUsDetail()
  }
  exTractPhoneNum = (num) =>{
    let numTel = num.split(' ')
    this.setState({
      telExtracted : numTel
    })
  }
  extractEmail = (email) => {
    const emailLst = email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
    if (emailLst.length > 0) {
      return emailLst[0];
    }
    return null;
  }
  fetchContactUsDetail = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.GetContactDetail()
    this.exTractPhoneNum(res.tel)
    this.setState({ 
      faqData : {},
      isLoading: false,
      contactDetail : res,
      contactEmail: this.extractEmail(res.email)
    })
    if (res.error) message.error(res.error)
  }
  fetchGetUserDetail = async() => {
    const { GetProfileDetail } = this.props
    this.setState({ isLoading: true })
    const res = await GetProfileDetail()
    this.setState({ 
      name : this.props.auth ? `${this.props.auth.firstName} ${this.props.auth.lastName}` : '',
      email : res !== [] ? res.email : 'example@gmail.com',
      isLoading: false })
  }
  setText = (value,name)=> {
    const {faqData} = this.state
    faqData['name'] = this.state.name
    faqData['email'] = this.state.email
    faqData[name] = value
    this.setState({
      faqData
    })
  }
  onAdFaq = async (e) => {
    const { onSentFaqToUs } = this.props
    e.preventDefault()
    this.props.form.validateFieldsAndScroll(async(err, values) => {   
      if(!err){ 
        this.setState({ isLoading: true })
        await onSentFaqToUs(values) 
        this.setState({ 
          isLoading: false 
        },()=>{
          const modal = Modal.success({
            closable: true,
            centered: true,
            icon: null,
            maskClosable: true,
            okButtonProps: {hidden: true},
            width: 220,
            content: (
              <div style={{textAlign: 'center'}}>
                <IconCorrect style={{fontSize: 120}}/>
                <h2 className='text-medium' style={{fontSize: 20}}>ส่งข้อความสำเร็จ</h2>
              </div>
            )
          })
          setTimeout(() => {
            modal.destroy()
          }, 2000)
        })
       
      }
    })
  }

  render() {
    const isAuthenticated = this.props.isAuthenticated
    const {optionTilte} = this.state
    const {form: { getFieldDecorator } } = this.props
    return (isAuthenticated ? 
      <div className = {styles.div_faq_container}>
        <label className = {styles.title}>สถาบันส่งเสริมการสอนวิทยาศาสตร์และเทคโนโลยี (สสวท.)</label>
        <div className = {styles.divLeftContacUs}>
          <Row>
            <Row style = {{marginBottom : '1em'}}>
              <Col sm = {1} xs = {2} style = {{marginRight : '1em'}}><img src = {AddressIcon} style = {{width : '17px'}}/></Col>
              <Col span={18}><label className = {styles.AddressInContac}>{this.state.contactDetail ? this.state.contactDetail.address : '' }</label></Col>
            </Row>
            <Row style = {{marginBottom : '1em'}}>
              <Col sm = {1} xs = {2} style = {{marginRight : '1em'}}><img src = {TelephoneIcon}/></Col>
              <Col span={18} style = {{display : 'flex'}}>
                <a className = {styles.linkInContact} href={this.state.telExtracted[0]}>
                  {this.state.telExtracted[0]}
                </a>
                {
                  this.state.telExtracted.map((tel,i) => {
                    if( i !== 0 ){
                      return(
                        <div style = {{marginLeft : 10}}>
                          <p key = {i}> {tel} </p>
                        </div>)
                    }
                  })
                }
              </Col>
            </Row>
            <Row style = {{marginBottom : '1em'}}>
              <Col sm = {1} xs = {2} style = {{marginRight : '1em'}}><img src = {FacebookIcon}/></Col>
              <Col span={18}><a className = {styles.linkInContact} href='https://www.facebook.com/IPST.OnlineTesting/' target='_blank' rel='noopener noreferrer'>IPST.Online Testing</a></Col>
            </Row>
            <Row style = {{marginBottom : '1em'}}>
              <Col sm = {1} xs = {2} style = {{marginRight : '1em'}}><img src = {MailIcon}/></Col>
              <Col span={18}><a className = {styles.linkInContact} href={this.state.contactEmail ? 'mailto:'+this.state.contactEmail : '' }>{this.state.contactDetail ? this.state.contactDetail.email : '' }</a></Col>
            </Row>
          </Row>
        </div>

        <div className = {styles.div_form} >
          <div className = {styles.contactForm}>
            <div style = {{textAlign : 'center',padding : '20px'}}>
              <label className = {styles.titleSendMsgToUs}> คำถามเพิ่มเติม </label>
            </div> 
            <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
              <Form onSubmit={this.onAdFaq} hideRequiredMark={true}>
                <Form.Item label='ชื่อ'>
                  {
                    getFieldDecorator('name',{
                      rules: [
                        {
                          required: true,
                          min: 1,
                          message: 'กรุณากรอกชื่อ',
                        }
                      ],
                      initialValue: this.state.name
                    })(<Input placeholder= 'ชื่อผู้ใช้งาน' className ={styles.inputInContact} disabled/>)
                  }
                </Form.Item>
                <Form.Item label='อีเมล'>
                  {
                    getFieldDecorator('email',{
                      rules: [
                        {
                          required: true,
                          min: 1,
                          message: 'กรุณากรอกอีเมล',
                        }
                      ],
                      initialValue: this.state.email
                    })(this.state.email ? (<Input className ={styles.inputInContact} disabled/>) : (<Input placeholder = 'อีเมล'/>))
                  }
                </Form.Item>
                <Form.Item label='หัวข้อคำถาม'>
                  {
                    getFieldDecorator('question',{
                      rules: [
                        {
                          required: true,
                          min: 1,
                          message: 'กรุณาเลือกหัวข้อคำถาม',
                        }
                      ]
                    })(<Select placeholder= 'กรุณาเลือก' 
                      className ={styles.inputInContact}
                      onChange = {(e)=> this.setText(e,'title')}
                    >
                      {                   
                        optionTilte.map((v, i) => {
                          return(
                            <Option value = {v.value} key = {i} >{v.text}</Option>  
                          )
                        })
                      }
                    </Select>)
                  }
                </Form.Item>
                <Form.Item label='ข้อความ'>
                  {
                    getFieldDecorator('text',{
                      rules: [
                        {
                          required: true,
                          min: 1,
                          message: 'กรุณาระบุข้อความ',
                        }
                      ]
                    })(
                      <TextArea
                        placeholder= 'รายละเอียด'
                        autosize={{ minRows: 4, maxRows: 6 }}
                      >                            
                      </TextArea>
                    )
                  }
                </Form.Item>
                <div style = {{textAlign : 'center'}}>
                  <Button htmlType='submit' type='primary'  id='' style={{ width: '50%', marginTop: 20 }}>
                ส่งคำถาม
                  </Button>
                </div>
              </Form>
          
            </Spin>
          </div>
       
        </div>
      </div>
      :
      <Redirect to='/examset' />
    )
  }
}

const mapStateToProps = state => ({
  // Redirect after login
  isAuthenticated: state.auth.token ? true : false,
  auth: state.auth.profile,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetProfileDetail,
      onSentFaqToUs,
      GetContactDetail
    },
    dispatch
  )
const WrappedFormDataSchool = Form.create({ name: 'Add_Form_Province' })(ContactUsMember)
export default connect(mapStateToProps, mapDispatchToProps)(WrappedFormDataSchool)
