import { FetchCycle, AxiosRequest } from '../fetch'

// API =====================================================================

const API_PREFIX = '/master/prefixes'

// Fetch ===================================================================

const getPrefixList  = ()              => () => AxiosRequest(API_PREFIX,'get')
const createPrefix   = (data)          => () => AxiosRequest(API_PREFIX,'post',data)
const updatePrefix   = (prefixId,data) => () => AxiosRequest(`${API_PREFIX}/${prefixId}`,'patch',data)
const removePrefix   = (prefixId)      => () => AxiosRequest(`${API_PREFIX}/${prefixId}`,'delete')
const updateMoveRow  = (prefixId,data) => () => AxiosRequest(`${API_PREFIX}/${prefixId}/seq`,'patch',data)

// Action =====================================================================

export const getAllPrefixList = ()              => FetchCycle(getPrefixList())
export const onUpdatePrefix   = (prefixId,data) => FetchCycle(updatePrefix(prefixId,data))
export const onCreatePrefix   = (data)          => FetchCycle(createPrefix(data))
export const onRemovePrefix   = (prefixId)      => FetchCycle(removePrefix(prefixId))
export const onUpdateMoveRow  = (prefixId,data) => FetchCycle(updateMoveRow(prefixId,data))