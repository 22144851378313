import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getProvincesList,
  onUpdateProvince
} from '../../redux/provinces/action'
import Header from './Header'
import styles from './Provinces.module.scss'
import ModalProvinces from './ModalProvinces'
import { 
  Spin,
  Table, 
  Select,
  message 
} from 'antd'   
import Edit from '../IconSVG/Edit.svg'
import EditGray from '../IconSVG/EditGray.svg'
import Delete from '../IconSVG/Delete.svg'
import DeleteGray from '../IconSVG/DeleteGray.svg' 

const { Option } = Select

class Provinces extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      provincesList: [],
      regionList: ['เหนือ','ตะวันออกเฉียงเหนือ','ตะวันตก','กลาง','ตะวันออก','ใต้'],
      modalVisible: false, 
      item: {}
    }
  } 
  columns = [
    {
      title: 'จังหวัด',
      dataIndex:'name',
      key: 'name',
      width: '160px',
      sorter: (strA, strB) => {
        let icmp = strA.name.toLowerCase().localeCompare(strB.name.toLowerCase())
        if (icmp !== 0) { 
          return icmp
        } 
        if (strA.name > strB.name) {
          return 1
        } else if (strA.name < strB.name) {
          return -1
        } else {
          return 0
        }
      },
    },
    {
      title: 'ภาค',
      dataIndex: 'region',
      key:'region', 
      render: (region,record ) => {
        const { regionList } = this.state
        return (
          <Select
            style ={{ width: '200px' }}
            value = {region}
            onChange={ (e) => { 
              this.onChangeSelect(e,record)
            }}
          >
            {
              regionList.map((r,i) => {
                return(
                  <Option value={r} key={i}>{r}</Option>
                )
              })
            }
          </Select>
        )
      },
      sorter: (strA, strB) => {
        let icmp = strA.region.toLowerCase().localeCompare(strB.region.toLowerCase())
        if (icmp !== 0) { 
          return icmp
        } 
        if (strA.region > strB.region) {
          return 1
        } else if (strA.region < strB.region) {
          return -1
        } else {
          return 0
        }
      },
      width: '225px',
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      render:(edit,record,index)=> {
        const { checkMouseOverEdit, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkMouseOverEdit: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouseOverEdit: false})
        }
        return (
          <div className={styles.icon_row} >
            <img alt='edit' title='แก้ไข' onClick={e => {
              e.stopPropagation() 
              this.onOpenModal(record,'edit') 
            }} 
            onMouseOver={mouserOver}  onMouseLeave={mouseLeave} src={checkMouseOverEdit && indexRow === index ? Edit : EditGray}/></div>
        )
      },
      width: '50px'
    },
    {
      title: '',
      dataIndex: '',
      key: 'delete',     
      render:(deleteRow,record,index)=> {
        const { checkMouserOverDelete, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkMouserOverDelete: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouserOverDelete: false})
        }
        return (
          <div className={styles.icon_row}>
            <img alt='delete' title='ลบข้อมูล' onClick={e =>{
              e.stopPropagation()
              this.onOpenModal(record,'delete')
            }} key={index} onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkMouserOverDelete && indexRow === index ? Delete : DeleteGray}/></div>
        )
      } 
    }
  ]
  componentDidMount = () => {
    this.fetchProvinces()
  }
  fetchProvinces = async() => {
    const { getProvincesList } = this.props
    this.setState({ isLoading: true  })
    const res = await getProvincesList()
    this.setState({ provincesList: res.items, isLoading: false })
  }
  onChangeSelect = async(value,record) => {
    const { onUpdateProvince } = this.props
    this.setState({ isLoading: true }) 
    let dataProvince = { 
      name: record.name,
      region: value
    }
    await onUpdateProvince(record._id,dataProvince)
    await this.fetchProvinces()
    message.success('แก้ไขภูมิภาคสำเร็จ')
    this.setState({ isLoading: false })
  }
  onCloseModal = () => {
    this.setState({
      modalVisible: false
    })
  }
  onOpenModal = (record, type) => {   
    if(type !== 'add'){
      this.fetchProvinces()
      this.setState({
        typeModal: type,
        item: record,
        modalVisible: true
      })
    }else{
      this.setState({ typeModal: type,modalVisible: true })
    } 
  } 
  setFiltersBySearch = (dataFilters,isEmptySearch) => { 
    if(!isEmptySearch) {
      this.setState({
        provincesList: dataFilters
      }) 
    }else{
      this.fetchProvinces()
    } 
  }
  render(){ 
    const { isLoading ,provincesList ,modalVisible, typeModal ,item} = this.state   
    return(
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading } key='spinnerProvinces'>
          <Header fetch={this.fetchProvinces} setFiltersBySearch={this.setFiltersBySearch} dataSearch={provincesList} onOpenModal={this.onOpenModal} provincesList={provincesList} key='ProvincesHeader'/>
          <Table
            scroll={{x:true}}
            rowKey={ (record,i) => i }
            columns={this.columns}
            pagination={false}
            dataSource={provincesList}
            components={this.components}
            onRow={(record, index) => ({ 
              onMouseEnter: () => this.setState({ indexRow: index })
            })}
          /> 
          <ModalProvinces item={item} typeModal={typeModal} onCloseModal={this.onCloseModal} modalVisible={modalVisible} fetch={this.fetchProvinces}/>
        </Spin> 
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {   
      getProvincesList,
      onUpdateProvince
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(Provinces)