import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux' 
import {
  message,
  Spin,
  Button,
  Input,
  Form,
  Row,
  Col
} from 'antd'
import {
  GetContactDetail,
  FetchToEditContactDetail
} from '../../redux/master/action'
import styles from './ContactUs.module.scss'
const { TextArea } = Input
class EditContactUs extends Component {
  constructor(props) {
    super(props)
    this.onSubmitEdited = this.onSubmitEdited.bind(this)
    this.state = {
      isLoading: false,
      contactDetail:''
    }
  }
  componentDidMount(){
    this.fetchContactUsDetail()
  }

  fetchContactUsDetail = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.GetContactDetail()
    this.setState({ 
      isLoading: false,
      contactDetail : res
    })
    if (res.error) message.error(res.error)
  }
  onSubmitEdited = async (e) => {
    e.preventDefault()
    if (!e.err) {
      await this.props.FetchToEditContactDetail(this.state.contactDetail)
      message.success('แก้ไขข้อมูลสำเร็จ')
      this.props.fetchContactUsDetail()
      this.props.goToEditComponent()
    }else{
      message.error(e.err)
    }
  }
  changeText = (e,name) =>{
    const {contactDetail} = this.state
    contactDetail[name] = e.target.value
    this.setState({
      contactDetail
    })
  }
  render() {
    return (
      <div className={styles.examSetDiv}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <div className='flex-space-between' style={{marginBottom: '30px'}}>
            <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>แก้ไขข้อมูล</h1>
          </div>
          <div>
            <Form onSubmit={this.onSubmitEdited} >
              <Row className = {styles.table_in_contact_us}>
                <Row className = {styles.row_table_contact_us}>
                  <Col span = {2} className = {styles.td_table_in_contact_us}><label>ชื่อองค์กร</label></Col>
                  <Col span = {6} className = {styles.td_table_in_edit_contact_us}><Input onChange = {e => this.changeText(e,'name')} value = {this.state.contactDetail ? this.state.contactDetail.name : '-' } ></Input></Col>
                </Row>
                <Row className = {styles.row_table_contact_us}>
                  <Col span = {2} className = {styles.td_table_in_contact_us}><label>อีเมล</label></Col>
                  <Col span = {6} className = {styles.td_table_in_edit_contact_us}><Input onChange = {e => this.changeText(e,'email')} value = {this.state.contactDetail ? this.state.contactDetail.email : '-' } ></Input></Col>
                </Row>
                <Row className = {styles.row_table_contact_us}>
                  <Col span = {2} className = {styles.td_table_in_contact_us}><label>โทร</label></Col>
                  <Col span = {6} className = {styles.td_table_in_edit_contact_us}><Input onChange = {e => this.changeText(e,'tel')} value = {this.state.contactDetail ? this.state.contactDetail.tel : '-' }></Input></Col>
                </Row>
                <Row className = {styles.row_table_contact_us}>
                  <Col span = {2} className = {styles.td_table_in_contact_us}><label>ที่อยู่</label></Col>
                  <Col span = {6} className = {styles.td_table_in_edit_contact_us}>
                    <TextArea className ={styles.inputInContact}
                      value = {this.state.contactDetail ? this.state.contactDetail.address : '-' }
                      onChange = {e => this.changeText(e,'address')}
                      autosize={{ minRows: 4, maxRows: 6 }}>
                    </TextArea>
                  </Col>
                </Row>
              </Row>
            </Form>
          </div>
          <div>
            <div>
              <div className = {styles.devide_line}>
              </div>
              <div>
                <Row>
                  <Col span={6}>
                    <div>
                      <a href='/#' onClick={this.props.goToEditComponent} className={styles.cancle_link}>ยกเลิก</a>
                    </div>
                  </Col>
                  <Col span={6}></Col>
                  <Col span={6}></Col>
                  <Col span={6}>
                    <div>
                      <Button className = {styles.save_btn} type='primary' htmlType='submit' onClick = {this.onSubmitEdited}>บันทึก</Button>
                    </div> 
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    
    )
  }
}

const mapStateToProps = state => {
  return {
    contactDetail : state.master.contactDetail
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetContactDetail,
      FetchToEditContactDetail
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(EditContactUs)