import React,{ Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as moment from 'moment'
import ReactToPrint from 'react-to-print'
import Header from './Header'
import { snapLocationRedirect, addMedalTier } from '../../util/helper'
import { ExamInfo, ExamDetailModal } from './ExamStudents'
import {
  Tabs,
  Table,
  Button,
  Checkbox,
  Spin,
  Avatar
} from 'antd'
import {
  getExamAllGroup,
  getExamByStudents,
  getExamDeepStat
} from '../../redux/report/action'
import { getExamSetDetail } from '../../redux/exam/action' 
import GroupDefaultIcon from '../IconSVG/GroupDefaultIcon.svg'
import ProfileDefault from '../IconSVG/ProfileDefault.svg'
import styles from './Report.module.scss'
import PrintYellowIcon from '../IconSVG/PrintYellowIcon.svg'
import AverageIcon from '../IconSVG/AverageIcon.svg'
import HighIcon from '../IconSVG/HighIcon.svg'
import LowIcon from '../IconSVG/LowIcon.svg'
import Report from '../IconSVG/Report.svg'
import ListItems from '../Display/ListItems'
import SearchModal from '../modal/SearchModal'
import GoldenMedalIcon from '../IconSVG/Golden_Medal_Icon.svg'
import SilverMedalIcon from '../IconSVG/Silver_Medal_Icon.svg'
import CopperMedalIcon from '../IconSVG/Copper_Medal_Icon.svg'
import ModalStat  from './ModalStat'

const { TabPane } = Tabs

export class StudentListTable extends Component {
  render(){
    const { studentList, columnsStudentList, onHover, onNotHover, isPrint, ExamDetail,groups } = this.props
    
    return (
      <div>
        {
          isPrint ? 
            <div>
              <div>
                <span style={{ margin: 0, fontWeight: 500,fontSize: '16spanx',color: '#05007A' }}>ชื่อชุดข้อสอบ</span>
                <span style={{ margin: 0, fontWeight: 450,fontSize: '16spanx',color: '#000000',paddingLeft: '10px'}}>{`${ExamDetail.name}`}</span>
              </div>
              <div>
                <span style={{ margin: 0, fontWeight: 500,fontSize: '16spanx',color: '#05007A' }}>วิชา</span>
                <span style={{ margin: 0, fontWeight: 450,fontSize: '16spanx',color: '#000000',paddingLeft: '10px'}}>{`${ExamDetail.subject}`}</span>
              </div>
              <div>
                <span style={{ margin: 0, fontWeight: 500,fontSize: '16spanx',color: '#05007A' }}>ระดับชั้น</span>
                <span style={{ margin: 0, fontWeight: 450,fontSize: '16spanx',color: '#000000',paddingLeft: '10px'}}>{`${ExamDetail.grade}`}</span>
              </div>
              <div> 
                <span style={{ margin: 0, fontWeight: 500,fontSize: '16spanx',color: '#05007A' }}>รหัสวิชา</span>
                <span style={{ margin: 0, fontWeight: 450,fontSize: '16spanx',color: '#000000',paddingLeft: '10px'}}>{`${ExamDetail.code}`}</span>
              </div>
              {
                groups.length !== 0 ? 
                  <div>
                    <span style={{ margin: 0, fontWeight: 500,fontSize: '16px',color: '#05007A'  }}>กลุ่ม</span>
                    <span style={{ margin: 0, fontWeight: 450,fontSize: '16px',color: '#000000',paddingLeft: '10px' }}>{groups[0].title}</span>
                  </div>
                  :''
              }
            </div>
            :''
        }
        <Table  
          id='dataStudentList'
          rowKey = { (record,i) => i}
          columns = { columnsStudentList }
          dataSource = { studentList }
          pagination = { false }
          onRow={(record,rowIndex) => {
            return {
              onMouseEnter: () => onHover(rowIndex),
              onMouseLeave: () => onNotHover(rowIndex)
            }
          }}
        />
      </div>
    )
  } 
}

class ExamTeachers extends Component {
  constructor(props){
    super(props)
    this.state = {
      groupExam: [],
      ExamDetail: [],
      ExamByStudents:[],
      filterExamByStudents: [],
      isLoading: false,
      isHover: false,
      indexRow: false,
      visibleModal: false,
      seeAllStudents: true,
      TabActiveKey: 1,
      totalStudentDoneTesting: 0,
      isSearchModal: false,
      searchResult: [],
      isPrint: false,
      checkSearch: false,
      searchKeyMobile: null,
      statesOfExam: { min: 0, max: 0, avg: 0}
      ,visibleModalStat: false,
      rowdata: {}
    }
  }
  componentDidMount =  () => {
    // console.log('-------------------')
    // console.log(this.state)
    // console.log(this.props)
    // console.log('-------------------')

    this.fetchGetExamAllGroup()
    // snapLocationRedirect(this.props.history.location.pathname)
  }
  columnsGroup = [
    {
      title: '',
      dataIndex: 'logo',
      key: 'logo',
      render: (logo) => {
        return ( 
          <Avatar src={logo ? logo : GroupDefaultIcon}/>
        )
      }
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
      render: (name ) => {  
        return(<div style={{ color: 'black' }}>{name}</div>) 
      }
    },
    {
      title: 'สมาชิก',
      dataIndex: 'totalStudent',
      key: 'totalStudent',
      align: 'center',
      render: (totalStudent) => { return(<div style={{ color: 'black' }}>{totalStudent}</div>) }
    },
    {
      title: 'จำนวนผู้ทำ',
      dataIndex: 'totalStudentTestings',
      key: 'totalStudentTestings',
      align: 'center',
      render: (totalStudentTestings) => { return(<div style={{ color: 'black' }}>{totalStudentTestings}</div>) }
    },
    {
      title: 'วันที่ทำล่าสุด',
      dataIndex: 'latestStartedAt',
      key: 'latestStartedAt',
      render: (latestStartedAt) => { return(<div style={{ color: 'black' }}>{moment.unix(latestStartedAt).format('DD/MM/YYYY')}</div>) },
      align: 'center'
    },
    {
      title: 'ต่ำสุด',
      dataIndex: 'minScore',
      key: 'minScore',
      align: 'center',
      render: (minScore) => { return(<div style={{ color: 'black' }}>{minScore.toFixed(2)}</div>) },
    },
    {
      title: 'สูงสุด',
      dataIndex: 'maxScore',
      key: 'maxScore',
      align: 'center',
      render: (maxScore) => { return(<div style={{ color: 'black' }}>{maxScore.toFixed(2)}</div>) },
    },
    {
      title: 'เฉลี่ย',
      dataIndex: 'avgScore',
      key: 'avgScore',
      render: (avgScore) => { return(<div style={{ color: 'black' }}>{avgScore.toFixed(2)}</div>) },
      align: 'center'
    },
  ]
  columnsStudentList = [
    {
      title: '',
      render: (student) => {
        return (
          <div className={styles.avatar}>
            <div>
              <Avatar className={styles.img_logo} src={student.profileImage ? student.profileImage : ProfileDefault} /> 
            </div>
            {student.medal >= 0 && student.medal <= 2 ? 
              <div style = {{ position: 'absolute', bottom: '-10px', right: '-10px' }}>
                <img alt='medal' style = {{ width: '25px' }} src={
                  student.medal === 0 ? GoldenMedalIcon :
                    student.medal === 1 ? SilverMedalIcon : 
                      student.medal === 2 ? CopperMedalIcon : null
                }/>
              </div>
              : ''}
          </div>

        )
      },
      width: 32
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
      render: (name ) => { 
        return(<div style={{ color: 'black' }}>{name}</div>)
      }
    },
    {
      title: 'โรงเรียน',
      dataIndex: 'schoolName',
      key: 'schoolName',
      render: (schoolName) => { return(<div style={{ color: 'black' }}>{schoolName ? schoolName : ''}</div>)}
    },
    {
      title: 'ชื่อกลุ่ม',
      dataIndex: 'group',
      key: 'groupName',
      render: (group ) => {  
        return (
          <div style={{ color: 'black' }}>
            {group ? group.name : ''}
          </div>)
      }
    },
    {
      title: 'จำนวนครั้งที่ทำ',
      dataIndex: 'count',
      key: 'count',
      align: 'center', 
      render: (center) => { return(<div style={{ color: 'black' }}>{center}</div>)}
    },
    {
      title: 'วันที่ทำล่าสุด',
      dataIndex: 'latestStartedAt',
      key: 'latestStartedAt',
      render: (latestStartedAt) => {  
        return( 
          <div style={{ color: 'black' }}>
            {
              moment.unix(latestStartedAt).format('DD/MM/YYYY') === 'Invalid date' ? 
                latestStartedAt
                : 
                moment.unix(latestStartedAt).add(543,'years').format('DD/MM/YYYY') 
            }
          </div> )
      }
    },
    {
      title: 'คะแนนที่ได้',
      dataIndex: 'latestScore',
      key: 'latestScore',
      align: 'center',
      render: (center) => { return(<div style={{ color: 'black' }}>{center}</div>)}
    },
    { 
      dataIndex: 'buttonAnswer',
      key: 'buttonAnswer',
      render: (buttonAnswer,record,index)=>{
        const { isPrint } = this.state
        const { auth } = this.props
        // console.log(auth)
        return (<div style={ isPrint ? { display: 'none' } : null}>
        {auth.extlink === undefined && <Button className={styles.button_hover} onClick={(e)=>{
          e.stopPropagation()
          this.onClickTesting(index,record)
        }}>ดูคำตอบ</Button>
        }<Button  className={styles.button_hover} onClick={(e)=>{
          e.stopPropagation()
          // console.log(record)
          this.setState({
            visibleModalStat: true,      
            rowdata: {StaticType: 'ตัวชี้วัด', tid: record.testingId, uid: record.userId, eid: this.props.match.params.examId} //gid: record.groupId, 
          })
        }}>ผลการทำสอบ (ตัวชี้วัด)</Button><Button  className={styles.button_hover} onClick={(e)=>{
          e.stopPropagation()
          this.setState({
            visibleModalStat: true,      
            rowdata: {StaticType: 'สาระการเรียนรู้', tid: record.testingId, uid: record.userId, eid: this.props.match.params.examId} // gid: record.groupId, 
          })
        }}>ผลการทำสอบ (สาระการเรียนรู้)</Button></div>)
      }
    }
  ] 
  spinLoading = (value = undefined) => {
    let isLoading = this.state 
    if(value !== undefined) {
      return this.setState({ isLoading: value })
    }else {
      this.setState({ isLoading: !isLoading }) 
    } 
  }
  fetchGetExamAllGroup = async() => {
    this.setState({ isLoading: true }) 
    const { match } = this.props
    const [ resGroup, resExam, resExamByStudents ] = await Promise.all([
      this.props.getExamAllGroup(match.params.examId),
      this.props.getExamSetDetail(match.params.examId),
      this.props.getExamByStudents(match.params.examId)
    ])  

    // console.log('-----resgroup-------')
    // console.log(resGroup)
    // console.log(resExam)
    // console.log(resExamByStudents)
    // console.log('-----resgroup-------')


    this.setState({
      groupExam: resGroup,
      ExamDetail: resExam,
      ExamByStudents: addMedalTier(resExamByStudents.studentListOfExam),
      filterExamByStudents: addMedalTier(resExamByStudents.studentListOfExam.filter((e) => e.isInGroup)),
      isLoading: false,
      statesOfExam: resExamByStudents.statsOfExam
    }) 
    // await resExamByStudents.studentListOfExam.map((data) => {
    //   data.latestStartedAt = moment.unix(data.latestStartedAt).format('DD/MM/YYYY')
    //   return data
    // })
    // await resExamByStudents.studentListOfExam.filter((e) => e.isInGroup)
  }

  handleSearch = async(value) => { 
    let { searchKeyword, examsFilters, groupExam, seeAllStudents, TabActiveKey, ExamByStudents, filterExamByStudents } = this.state
    let dataExcel = TabActiveKey === 2 ? groupExam : (seeAllStudents ? ExamByStudents : filterExamByStudents) 
    let isEmptySearch = true
    searchKeyword = value  
    this.setState({
      checkEdit : true
    })
    if (searchKeyword && this.props.match.path === '/report') {
      isEmptySearch = false
      examsFilters = dataExcel ? dataExcel.filter(r => r.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 || r.code.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)  : dataExcel
    }else if (searchKeyword) {
      isEmptySearch = false
      examsFilters = dataExcel ? dataExcel.filter(r => r.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)  : dataExcel
    }
    this.setExamsFiltersBySearch(examsFilters, isEmptySearch)
  }

  setExamsFiltersBySearch = (exam = [],isEmptySearch) => { 
    const { TabActiveKey, seeAllStudents } = this.state   
    this.setState({ isLoading: true })
    if(!isEmptySearch) {
      if(TabActiveKey === '2'){ 
        this.setState({
          groupExam: exam,
          checkSearch: true
        })
      }else {
        if(seeAllStudents){ 
          this.setState({
            ExamByStudents: exam,
            checkSearch: true
          })
        }else{
          this.setState({
            filterExamByStudents: exam,
            checkSearch: true
          })
        }
      }
    }else{
      this.fetchGetExamAllGroup()
      this.setState({ isLoading: false, checkSearch: false })
    }
    this.setState({ isLoading: false })
  }
  onHover = (index) => {
    this.setState({
      indexRow: index,
      isHover: true
    })
  }
  onNotHover = (index) => {
    this.setState({
      indexRow: index,
      isHover: false
    })
  }
  openModal = () => {
    this.setState({
      visibleModal: true
    })
  }
  closeModal = () => {
    this.setState({
      visibleModal: false
    })
  }
  onClickTesting = (index,record) => {  
    const { ExamByStudents, ExamDetail } = this.state 
    const { history } = this.props
    history.push(`/testing/${ExamByStudents[index].testingId}/solution`, { reportExamId: ExamDetail._id, cameFrom: 'report', groupId: record.groupId, groupName: record.group})
  }
  onSeeAllStudentsList = () => {
    let { seeAllStudents } = this.state
    this.setState({
      seeAllStudents: !seeAllStudents
    })
  }
  onCheckTabActiveKey = (key) => {
    this.setState({
      TabActiveKey: key
    })
  }

  overallScore = (text1, text2, text3) => {
    let { totalStudentDoneTesting ,ExamByStudents, statesOfExam } = this.state 
    totalStudentDoneTesting = ExamByStudents.reduce((total, t) => total + t.count, totalStudentDoneTesting)

    return (
      <Fragment>
        <div className='mobile'>
          <div className={styles.overall_score}>
            <div className={ styles.group_score_text }>
              <div className={ styles.score_text}><span>คะแนน</span></div>
              <div className='score'>
                <img src={LowIcon} alt='low-icon'/>{text1 || ''}
                <h4>{statesOfExam.min.toFixed(2)}</h4>
              </div>
              <div className='score'>
                <img src={HighIcon} alt='high-icon'/>{text2 || ''}
                <h4>{statesOfExam.max.toFixed(2)}</h4>
              </div>
              <div className='score'>
                <img src={AverageIcon} alt='average-icon'/>{text3 || ''} 
                <h4>{parseFloat(statesOfExam.avg).toFixed(2)}</h4>
              </div>
            </div>
            <div className={ styles.totalStudentDoneTesting } >
              <div className={ styles.text_1 }>
                <span  >จำนวนครั้งทั้งหมดที่ทำ</span>
              </div>
              <div className={ styles.text_2 }>
                <span >{`   ${totalStudentDoneTesting}   `}</span>
              </div>
              <span className={ styles.text_3 }>ครั้ง</span>
            </div>
          </div>
        </div>

        <div className='desktop'>
          <div className={styles.overall_score}>
            <h4>คะแนน</h4>

            <div className='all-score'>
              <div className='score'>
                <img src={LowIcon} alt='low-icon'/>{text1 || ''}
                <h4>{statesOfExam.min.toFixed(2)}</h4>
              </div>
              <div className='score'>
                <img src={HighIcon} alt='high-icon'/>{text2 || ''}
                <h4>{statesOfExam.max.toFixed(2)}</h4>
              </div>
              <div className='score'>
                <img src={AverageIcon} alt='average-icon'/>{text3 || ''}
                <h4>{parseFloat(statesOfExam.avg).toFixed(2)}</h4>
              </div>
              <div className={ styles.totalStudentDoneTesting } >
                <span className={ styles.text_1 } >จำนวนครั้งทั้งหมดที่ทำ</span>
                <span className={ styles.text_2 }>{`   ${totalStudentDoneTesting}   `}</span>
                <span className={ styles.text_3 }>ครั้ง</span>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
   
  showSearchModal = () => { 
    this.setState({ isSearchModal: !this.state.isSearchModal})
    
    this.state.isSearchModal && this.fetchGetExamAllGroup()
  }
   

  render (){
    const { groupExam, ExamDetail, isLoading, visibleModal, ExamByStudents,
      seeAllStudents,filterExamByStudents, TabActiveKey, isPrint , checkSearch
      ,rowdata, visibleModalStat
     } = this.state
     
    const { history, match, auth, getExamDeepStat } = this.props 
    let dataExcel = TabActiveKey == 2 ? groupExam : (seeAllStudents ? ExamByStudents : filterExamByStudents)  
    const groups = groupExam.map((item, i) => {
      const status = auth.role !== 'student' ? (
        <span className={styles.list_score}>
          <span>
            <img src={LowIcon} alt='low-icon'/>
            <span>{item.minScore.toFixed(2)}</span>
          </span>
          <span>
            <img src={HighIcon} alt='high-icon'/>
            <span>{item.maxScore.toFixed(2)}</span>
          </span>
          <span>
            <img src={AverageIcon} alt='average-icon'/>
            <span>{groupExam[i].avgScore.toFixed(2)}</span>
          </span>
        </span>
      ) : (
        ''
      )

      const actions = [
        {
          attribute: (
            <span >
              <img alt='report' onClick={e => {
                e.stopPropagation()
                this.props.history.push(`/report/${item._id}/group/${groupExam[i]._id}`,{ cameFrom: 'exam'})
              }}
              src={Report}
              style={{ cursor: 'pointer' }}
              /> ใบคะแนน
            </span>
          )
        },
        {
          attribute: (
            <span >
              <img alt='report' onClick={e => {
                e.stopPropagation()
                this.props.history.push(`/report/${item._id}/group/${groupExam[i]._id}`,{ cameFrom: 'exam'})
              }}
              src={Report}
              style={{ cursor: 'pointer' }}
              /> ใบผลสอบ
            </span>
          )
        },
        {
          attribute: (
            <span >
              <img alt='report' onClick={e => {
                e.stopPropagation()
                this.props.history.push(`/report/${item._id}/group/${groupExam[i]._id}`,{ cameFrom: 'exam'})
              }}
              src={Report}
              style={{ cursor: 'pointer' }}
              /> ดูคำตอบ
            </span>
          )
        }
      ]

      return {
        avatar: item.logo || GroupDefaultIcon,
        title: item.name,
        description: auth.role !== 'student' ?
          moment.unix(item.latestStartedAt).format('DD/MM/YYYY')
          : `#${i + 1} ${moment.unix(item.latestTestAt).format('DD/MM/YYYY')}`,
        status: status,
        actions: auth.role !== 'student' ? [] : actions,
        path: false,
        to: { path: `/report/${ExamDetail._id}/group/${groupExam[i]._id} `, groupId: groupExam[i]._id, groupName: groupExam[i].name } 
      }
    }) 
    const srcStudents = seeAllStudents ? ExamByStudents : filterExamByStudents 
    const students = srcStudents.map((item ) => { 
      return {
        avatar: item.profileImage ? item.profileImage : ProfileDefault,
        title: item.name,
        medal: item.medal,
        description: moment.unix(item.latestStartedAt).format('DD/MM/YYYY') !== 'Invalid date' ? moment.unix(item.latestStartedAt).format('DD/MM/YYYY') : item.latestStartedAt,
        // description: item.latestStartedAt,
        status: `${item.latestScore}`,
        actions: [],
        path: ''
      }
    }) 
    const TabsExam = (
      <div className={styles.report_tabs}>
        <Tabs defaultActiveKey='1'  onChange={this.onCheckTabActiveKey}>
          <TabPane tab={'รายชื่อ ('+ ExamByStudents.length +')'} key='1' >
            <div className='mobile'>
              {this.overallScore()}

              {auth.extlink === undefined && <div className='include-outside'>
                <Checkbox defaultChecked={true} onChange={ this.onSeeAllStudentsList } >รวมคนนอกกลุ่ม</Checkbox>
              </div>}

              <ListItems
                srcData={students}
                avatar
                status
                history={this.props.history}
                report={true}
                groupReport={true}
                isMedal={true}
              />
            </div>

            <div className='desktop' >
              {this.overallScore('ต่ำสุด', 'สูงสุด', 'เฉลี่ย')} 
              {auth.extlink === undefined && <div className='include-outside' >
                <Checkbox defaultChecked={true}
                  onChange={ this.onSeeAllStudentsList } >
                  รวมคนนอกกลุ่ม
                </Checkbox> 
                <div className={ styles.print_screen } >
                  <ReactToPrint 
                    onBeforeGetContent={ async() => {
                      await this.setState({ isPrint: true }) 
                    }} 
                    onAfterPrint={ async() => {
                      await this.setState({ isPrint: false }) 
                    }}
                    trigger={() => 
                      <Button ><img alt='print-yellow-icon' src={PrintYellowIcon}/>
                        <span className={ styles.print_text }>พิมพ์เอกสาร</span>
                      </Button>}
                    content={() => this.componentRef}
                  />
                </div>
              </div>}

              <StudentListTable   
                groups={groups}
                isPrint={isPrint}
                ExamDetail={ExamDetail}
                ref={el => this.componentRef = el}  
                studentList={seeAllStudents ? ExamByStudents : filterExamByStudents}
                columnsStudentList={this.columnsStudentList}
                onNotHover={this.onNotHover}
                onHover={this.onHover}
                seeAllStudents={seeAllStudents}
              />
            </div>
          </TabPane>
          <TabPane tab={'กลุ่ม ('+ groupExam.length +')'} key='2' >
            <div className='mobile'>
              <ListItems
                srcData={groups}
                avatar
                status
                history={this.props.history}
                report={true}
                checkReportTabGroup = { true }
              />
            </div>

            <div className='desktop'>
              {auth.extlink === undefined && <div style={{ paddingBottom: '10px' }}>
                <ReactToPrint
                  onBeforeGetContent={ async() => {
                    await this.setState({ isPrint: true }) 
                  }} 
                  onAfterPrint={ async() => {
                    await this.setState({ isPrint: false }) 
                  }}
                  trigger={() => 
                    <Button ><img src={PrintYellowIcon}/>
                      <span className={ styles.print_text }>พิมพ์เอกสาร</span>
                    </Button>}
                  content={() => this.componentRefGroup}
                />
              </div>}
              <div ref={el => this.componentRefGroup = el}>
                {
                  isPrint ? 
                    <div>
                      <div>
                        <span style={{ margin: 0, fontWeight: 500,fontSize: '16spanx',color: '#05007A' }}>ชื่อชุดข้อสอบ</span>
                        <span style={{ margin: 0, fontWeight: 450,fontSize: '16spanx',color: '#000000',paddingLeft: '10px'}}>{`${ExamDetail.name}`}</span>
                      </div>
                      <div>
                        <span style={{ margin: 0, fontWeight: 500,fontSize: '16spanx',color: '#05007A' }}>วิชา</span>
                        <span style={{ margin: 0, fontWeight: 450,fontSize: '16spanx',color: '#000000',paddingLeft: '10px'}}>{`${ExamDetail.subject}`}</span>
                      </div>
                      <div>
                        <span style={{ margin: 0, fontWeight: 500,fontSize: '16spanx',color: '#05007A' }}>ระดับชั้น</span>
                        <span style={{ margin: 0, fontWeight: 450,fontSize: '16spanx',color: '#000000',paddingLeft: '10px'}}>{`${ExamDetail.grade}`}</span>
                      </div>
                      <div> 
                        <span style={{ margin: 0, fontWeight: 500,fontSize: '16spanx',color: '#05007A' }}>รหัสวิชา</span>
                        <span style={{ margin: 0, fontWeight: 450,fontSize: '16spanx',color: '#000000',paddingLeft: '10px'}}>{`${ExamDetail.code}`}</span>
                      </div>
                    </div>
                    :''
                }
                <Table 
                  id='dataGroupList'
                  rowKey = { record => record._id }
                  columns = { this.columnsGroup }
                  dataSource = { groupExam }
                  pagination = { false }
                  onRow={(record,rowIndex) => {
                    return { 
                      onClick: () => history.push(`/report/${ExamDetail._id}/group/${groupExam[rowIndex]._id}`,{ cameFrom: 'exam', groupId: groupExam[rowIndex]._id, groupName: groupExam[rowIndex].name  })
                    }
                  }}
                />
              </div>
            </div>
          </TabPane> 
        </Tabs>
      </div>
    )    
    return (
      <div className={styles.report_exam_group} >
        <Spin size='large' top='Loading...' spinning={ isLoading }>
          <Header
            dataSearch = { dataExcel }
            setExamsFiltersBySearch={this.setExamsFiltersBySearch}
            history={history}
            match={match}
            examId={match.params.examId}
            TabActiveKey={TabActiveKey}
            showSearchModal={this.showSearchModal}
            // cameFrom={'myExam'}
            noSearch = {auth.extlink !== undefined}
            {...this.props}
          />

          <ExamInfo ExamDetail={ExamDetail} studentList={seeAllStudents ? ExamByStudents : filterExamByStudents} excelData={dataExcel} openModal={this.openModal} auth={auth} TabActiveKey={TabActiveKey} match={this.props.match} getExamDeepStat={getExamDeepStat} spinLoading={this.spinLoading}/>

          { TabsExam }

          <ExamDetailModal
            closeModal={this.closeModal}
            visibleModal={visibleModal}
            ExamDetail={ExamDetail}
            auth={auth}
          />

          <SearchModal
            isSearchModal = { this.state.isSearchModal }
            showSearchModal = { this.showSearchModal }
            title = 'ค้นหา'
            placeholder = { TabActiveKey === 1 ? 'ชื่อ (รายชื่อนักเรียน)' : 'ชื่อ (กลุ่ม)' }
            //action = { this.setExamsFiltersBySearch }
            action = { this.handleSearch }
            checkSearch 
          >
            {
              checkSearch ?
                <ListItems
                  srcData = { TabActiveKey === 1 ?  students : groups }
                  avatar
                  status
                  history = { this.props.history }
                  report = { true }
                  groupReport={ TabActiveKey === 1 ? true : false }
                  checkReport = { true }
                />
                : ''
            } 
          </SearchModal>
          <ModalStat
            visibleModalStat={visibleModalStat}
            rowdata={rowdata}
            onCloseModalResultScore={() => {
              this.setState({
                visibleModalStat: false
              })
            }}
          />
        </Spin>
        <iframe id='ifmcontentstoprint' style = {{ height: '0px', width: '0px', position: 'absolute', visibility: 'hidden' }} />
      </div>
    )
  }
}

const mapStateToProps = state => {

    // console.log('---AAAA----------------')
    // console.log(state)
    // console.log('---AAAA----------------')


    let tmpprop = null

    // console.log('---AAAA----------------')
    // console.log(tmpprop)

    // console.log('-------------------')
if (state.auth.profile === null)
{
    // this.props.auth = {}
    // this.props.auth.role = 'guest'
    tmpprop =  {role:'teacher',firstName:'',prefixName:'',lastName:'',extlink:true}
}
else
{
  tmpprop = state.auth.profile
}
    // console.log(tmpprop)
    // console.log('---AAAA----------------')


  return{
    auth: tmpprop,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getExamAllGroup,
      getExamSetDetail,
      getExamByStudents,
      getExamDeepStat
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ExamTeachers)
