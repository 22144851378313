import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  onCreateProvinces
} from '../../redux/provinces/action'  
import styles from './Provinces.module.scss'
import { 
  message,
  Row,
  Col,
  Divider,
  Button,
  Input,
  Form,
  Select
} from 'antd'
const { Option } = Select
class AddForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      createDepartment: { name: null }
    }
  }
  onGenerateProvince = (e) => {
    const { onCloseModal,onCreateProvinces,fetch } = this.props
    e.preventDefault() 
    this.props.form.validateFieldsAndScroll(async(err, values) => {   
      if(!err){ 
        await onCreateProvinces(values)
        await fetch()
        message.success('เพิ่มข้อมูลสำเร็จ')
        onCloseModal()
      }
    })
  }
  render(){
    const { onCloseModal,form: { getFieldDecorator } } = this.props 
    return (
      <Form onSubmit={this.onGenerateProvince} hideRequiredMark={true}>
        <h1 className={styles.header_modal}>เพิ่มข้อมูล</h1>
        <Row >
          <Col offset={4} span={16}  > 
            <Form.Item label='จังหวัด'>
              {
                getFieldDecorator('name',{
                  rules: [ 
                    {
                      required: true,
                      min: 1,
                      message: 'กรุณากรอกจังหวัด',
                    }, 
                  ],
                  initialValue: ''
                })(<Input/>)
              }
            </Form.Item> 
            <Form.Item label='ภาค'>
              {
                getFieldDecorator('region',{
                  rules: [ 
                    {
                      required: true,
                      min: 1,
                      message: 'กรุณาเลือกภูมิภาค',
                    }, 
                  ],
                  initialValue: ''
                })(<Select   
                  style={{ width: '100%' }}
                  placeholder='กรุณาระบุ'  
                > 
                  <Option key='เหนือ'>เหนือ</Option> 
                  <Option key='ตะวันออกเฉียงเหนือ'>ตะวันออกเฉียงเหนือ</Option> 
                  <Option key='ตะวันตก'>ตะวันตก</Option> 
                  <Option key='กลาง'>กลาง</Option> 
                  <Option key='ตะวันออก'>ตะวันออก</Option> 
                  <Option key='ใต้'>ใต้</Option> 
                </Select>)
              }
            </Form.Item> 
          </Col> 
        </Row>
        <Divider/>
        <Row>
          <Col span={12}>
            <span onClick={e => {e.stopPropagation(); onCloseModal()}} style={{ cursor:'pointer', color:'#828282', textDecorationLine: 'underline'}}>ยกเลิก</span>
          </Col>
          <Col span={12}>
            <Button htmlType='submit' type='primary' style={{ float: 'right' }}>เพิ่มข้อมูล</Button>
          </Col>
        </Row> 
      </Form>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {   
      onCreateProvinces
    },
    dispatch
  )
 
const WrappedFormDataSchool = Form.create({ name: 'Add_Form_Province' })(AddForm)
export default connect(mapStateToProps,mapDispatchToProps)(WrappedFormDataSchool)
