import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducers from './reducers'

// const preloadedState = global.window ? global.window.__PRELOADED_STATE__ : {} // eslint-disable-line
// delete global.window.__PRELOADED_STATE__ // eslint-disable-line


// eslint-disable-next-line no-undef
const composeEnhancers = global.window && global.window.__REDUX_DEVTOOLS_EXTENSION__ && global.window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


export default function configureStore(preloadedState = {}) {
  return createStore(
    rootReducers,
    preloadedState,
    composeEnhancers(
      applyMiddleware(thunkMiddleware)
    )
  )
}

// export default createStore(
//   rootReducers,
//   preloadedState,
//   composeEnhancers(
//     applyMiddleware(thunkMiddleware)
//   )
// )