import React, { Component, Fragment } from 'react'
import ImgAvatar from '../../../assets/ImgAvatar.svg'
import EllipseNotiInGroup from '../../IconSVG/EllipseNotiInGroup.svg'
const styles = {
  uploadButton: {
    position: 'relative',
    marginBottom: 5,
    cursor: 'pointer',
  },
  circleImage: {
    borderRadius: '50%',
  },
  iconCamera: {
    position: 'absolute',
    bottom: -5,
    right: -5,
  },
  avatarWrapper: {
    display: 'inline-block',
  },
  avatarEditor: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  iconCorrect: {
    marginLeft: '10px',
    fontSize: 40,
    cursor: 'pointer',
  },
  iconIncorrect: {
    marginRight: '10px',
    fontSize: 40,
    cursor: 'pointer',
  }
}
class AvatarGroupNoti extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageFile: null,
      imageBase64: null,
    }
  }
  render() {
    const { imageDefault } = this.props 
    const imageBase64 = this.state.imageBase64
    let frameIconSize = 38
    let cameraIconSize = 18

    if (this.props.size === 'large') {
      frameIconSize = 38
      cameraIconSize = 53
    }
    if (this.props.size === 'small') {
      frameIconSize = 32
      cameraIconSize = 18
    }
    const ImageAvatarProfile = (
      <Fragment>
        <img src={ imageDefault || ImgAvatar} alt='ImgAvatar' width='100%' style={{ borderRadius: '50%'}} />
        <img src={EllipseNotiInGroup} style={styles.iconCamera} alt='ImgCamera' width={cameraIconSize} height='auto'/>
      </Fragment>
    ) 
    return (
      <div>
        <Fragment>
          <div
            name='avatar'
          >
            <div style={{...styles.uploadButton, width: frameIconSize, height: frameIconSize}}>
              { imageBase64 ?
                <img src={imageBase64} style={styles.circleImage} alt='avatar' width='100%' height='100%' /> :
                ImageAvatarProfile
              }
            </div>
          </div>
        </Fragment>
      </div>
    )
  }
}
export default AvatarGroupNoti