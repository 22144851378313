exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Prefix_text_h1__3Mfvz {\n  font-size: 28px;\n  color: #05007A;\n  margin: 0;\n  padding-top: 15px;\n  padding-bottom: 15px; }\n\n.Prefix_icon_row__3iZdU img {\n  cursor: pointer; }\n\n.Prefix_header_modal__2I2AQ {\n  font-weight: 500;\n  font-size: 28px;\n  color: #05007A; }\n\n.Prefix_title_module__1l4-S {\n  font-size: 16px;\n  line-height: 24px; }\n\n.Prefix_button_save_modal__1EcRh {\n  float: right;\n  width: 70%; }\n\n.Prefix_header_delete_modal__2ztLv h1 {\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 29px; }\n\n.Prefix_button_delete_modal__biC-U {\n  width: 70%;\n  float: left;\n  margin-left: 15px; }\n\n@media (max-width: 1440px) {\n  .Prefix_modal__nAxqY {\n    width: 40%; }\n  .Prefix_button_save_modal__1EcRh {\n    height: 32px; }\n  .Prefix_button_delete_modal__biC-U {\n    width: 70%;\n    float: left;\n    margin-left: 15px; } }\n", ""]);

// Exports
exports.locals = {
	"text_h1": "Prefix_text_h1__3Mfvz",
	"icon_row": "Prefix_icon_row__3iZdU",
	"header_modal": "Prefix_header_modal__2I2AQ",
	"title_module": "Prefix_title_module__1l4-S",
	"button_save_modal": "Prefix_button_save_modal__1EcRh",
	"header_delete_modal": "Prefix_header_delete_modal__2ztLv",
	"button_delete_modal": "Prefix_button_delete_modal__biC-U",
	"modal": "Prefix_modal__nAxqY"
};