import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImgEmail from '../../assets/ImgEmail.svg'

export default class RegisterSuccess extends Component {

  handleNext = () => {
    this.props.onNext()
  }

  render() {
    const { minorAge } = this.props
    const msg = ( minorAge ? "อีเมลของนักเรียนที่ท่านให้ไว้" : "อีเมลของท่านที่ให้ไว้")
    return (
      <div style={{textAlign: 'center'}}>
        <div style={{marginTop: 36, marginBottom: 20}}>
          <img src={ImgEmail} alt='ImgEmail' width='45%'/>
        </div>
        {this.props.haveEmail ? (
          <div>
            <p className='text-lg text-medium'>ส่งคำร้องขอสำเร็จ</p>
            <p className='text-thin'>
            กรุณาตรวจสอบ{msg}<br/>
            ถ้าไม่ได้รับกรุณาเช็คที่อีเมลขยะ หรือสแปม<br/>
            สามารถติดต่อเราได้ที่<br/>
            อีเมล: etesting@ipst.ac.th
            </p>
          </div>
        ) : (
          <div>
            <p className='text-lg text-medium'>ลงทะเบียนสำเร็จ</p>
            <p className='text-thin'>
            สามารถเพิ่มอีเมลได้โดยการแก้ไข<br/>
            ข้อมูลผู้ใช้งานเมื่อเข้าสู่ระบบ<br/>
            สามารถติดต่อเราได้ที่<br/>
            อีเมล: etesting@ipst.ac.th
            </p>
          </div>
        )}
      </div>
    )
  }
}

RegisterSuccess.propTypes = {
  haveEmail: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
}
