import { FetchCycle, AxiosRequest, SetAxiosToken, AxiosRequestwithAuthHeader } from '../fetch'
import { setCookie, getCookie, deleteCookie } from '../../util/helper'
// Reducer =====================================================================

export const SET_USER               = 'user/SET_USER'


// API =====================================================================

const API_LOGIN                     = '/auth/login'
const API_PROFILE                   = '/auth/profile'
const API_CONFIRMEMAIL              = 'auth/confirm-email'
const API_LOGOUT                    = 'auth/logout'
const API_FORGET_PASSWORD           = 'auth/forget-password'
const API_SEEN_NOTICE               = 'auth/seen-notice'

const API_ML_PERFORMANCE = '/ml/performance'

// Fetch =====================================================================

const submitLogin = data => () => AxiosRequest(API_LOGIN, 'post', data)
const fetchProfile = () => AxiosRequest(API_PROFILE, 'get') 
const confirmEmail       = data => () => AxiosRequest(API_CONFIRMEMAIL, 'post', data)
const sentEmailForgetPassword = data => () => AxiosRequest(API_FORGET_PASSWORD+'/email', 'post', data)
const logout = () => AxiosRequest(API_LOGOUT, 'post')
const resetPassword = data => () => AxiosRequest(API_FORGET_PASSWORD, 'post', data)
const seenNotice = id => () => AxiosRequest(API_SEEN_NOTICE, 'patch', id)
const seenTerm = data => () => AxiosRequest(API_PROFILE, 'patch', data)
const seenTour = data => () => AxiosRequest(API_PROFILE, 'patch', data)

const getPerformance = () => AxiosRequest(API_ML_PERFORMANCE, 'get')
// Action Callback =====================================================================

const setUserProfile = (data) => {

  if (data && data.token) {
    setCookie('token', data.token)
    SetAxiosToken(data.token)
  }
  const profile = data ? {
    role: data.role,
    prefixName: data.prefixName,
    firstName: data.firstName,
    lastName: data.lastName,
    profileImage: data.profileImage,
    isSeenTermAndCondition: data.isSeenTermAndCondition,
    notices: data.notices ? data.notices : [],
    isImportJoin: data.isImportJoin ? true : false,
    isSeenTutorial: data.isSeenTutorial,
    isSeenDataPrivacy: data.isSeenDataPrivacy,
    privacyPolicy: data.privacyPolicy,
    id : data._id,
    hasSeenPrivacyPolicy: data.hasSeenPrivacyPolicy,
    birthYear: data.birthYear,
    parent: data.parent,
  } : null
  
  return {
    type: SET_USER,
    profile,
    token: data.token || getCookie('token'),
  }
}

const clearUserProfile = () => {
  deleteCookie('token')
  SetAxiosToken(null)
  return {
    type: SET_USER,
    profile: null,
    token: null,
  }
}

// Action =====================================================================

export const UserLogin                 = data => FetchCycle(submitLogin(data), setUserProfile) 
export const GetProfile                = ()   => FetchCycle(fetchProfile, setUserProfile, clearUserProfile)
export const GetProfileDetail          = ()   => FetchCycle(fetchProfile)
export const ConfirmEmail              = data => FetchCycle(confirmEmail(data), setUserProfile)
export const UserLogout                = ()   => FetchCycle(logout, clearUserProfile)
export const UserRequestForgetPassword = data => FetchCycle(sentEmailForgetPassword(data))
export const UserRequestResetPassword  = data => FetchCycle(resetPassword(data))
export const UserSeenNotice  = id => FetchCycle(seenNotice(id))
export const SetSeenTermAndConditionStatus = data => FetchCycle(seenTerm(data))
export const SetSeenTour = data => FetchCycle(seenTour(data))
export const GetPerformance = data => FetchCycle(getPerformance)