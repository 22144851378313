import {
  SET_MASTER_DATA,
  SET_CONTACT_DETAIL,
  SET_SERVER_STATUS
} from './action'

const defaultState = {
  prefixes: [],
  provinces: [],
  schools: [],
  departments: [],
  termAndCondition: {},
  dataPrivacy: {},
  serverStatus: {},
  relations: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_MASTER_DATA:
      return {
        ...state,
        ...action.data,
      } 
    case SET_CONTACT_DETAIL:
      return {
        ...state,
        ...action.data,
      } 
    case SET_SERVER_STATUS:
      return {
        ...state,
        serverStatus: action.data
      }
    default: return state
  }
}
