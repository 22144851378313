import React, { Fragment, useState } from 'react'
import {
  Layout,
  Menu,
  Divider,
  Row,
  Col,
  Dropdown
} from 'antd'
import { 
  Link,
  withRouter,
} from 'react-router-dom'
import { connect } from 'react-redux'
import styles from './LayoutWhiteGuest.module.scss'
import logo from '../../assets/LogoOnlineTesting.svg'
import LogoIpst from '../../assets/home/logo_ipst.svg'
import LogoGlo from '../../assets/home/logo_glo.svg'
import Facebook from '../IconSVG/facebook_icon.svg'
import ModalServerUnavailable from '../Server/ModalServerUnavailable'

const { Header, Content, Footer } = Layout

const LayoutWhiteGuest = (props) => {
  const { serverStatus } = props
  const [ isModalOffline, setIsModalOffline ]  = useState(false)
  // const classNamePageForBg = props.location.pathname  
  const goToRegister = () => { 
    const { serverStatus } = props  
    if(serverStatus.isOffline) {
      setIsModalOffline(true)
    }else{
      props.history.push('/register')
    }
  }
  const goToContact = () => { 
    const { serverStatus } = props   
    if(serverStatus.isOffline) {
      setIsModalOffline(true)
    }else{
      props.history.push('/contact')
    }
  }
  let layoutBG = null
  if(props.location.pathname === '/'){
    layoutBG = styles.bgInHome
  }else if(props.location.pathname === '/student'){
    layoutBG = styles.bgInStudent
  }else if(props.location.pathname === '/teacher'){
    layoutBG = styles.bgInTeacher
  }else if(props.location.pathname === '/about'){
    layoutBG = styles.bgInAboutUs
  }else if(props.location.pathname === '/contact'){
    layoutBG = styles.bgInContactUs
  }else if(['/login', '/forget-password'].includes(props.location.pathname)){
    layoutBG = styles.bgInLogin
  }else if(props.location.pathname === '/register' || props.location.pathname === '/faqguest'){
    layoutBG = styles.bgInRegister
  }
  const menuItems = (
    <Menu>
      <Menu.Item>
        <a target='_blank' rel='noopener noreferrer' href='http://www.alipay.com/'>
          นักเรียน
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target='_blank' rel='noopener noreferrer' href='http://www.taobao.com/'>
          ครู/อาจารย์
        </a>
      </Menu.Item>
    </Menu>
  )

  const containerMenu = (
    <div className='container'>
      <div className='left'>
        { props.location.pathname !== '/' 
        && <Link to = '/'>
          <a href = '/' style = {{marginLeft : '15px'}}><img src={logo} alt='logo'/></a> 
        </Link>
        }
        <Menu
          mode='horizontal'
          className={`${styles.layoutMenu} ${styles.layoutMenuHideMobile}`}
          id = 'lay_123'
        >
          <Menu.Item key='student'>
            <Link to='/student'>นักเรียน</Link>
          </Menu.Item>
          <Menu.Item key='teacher'>
            <Link to='/teacher'>ครู/อาจารย์</Link>
          </Menu.Item>
        </Menu>
      </div>

      <div className='right'>
        <Menu
          mode='horizontal'
          selectedKeys={[ props.location.pathname ]}
          className={styles.layoutMenu}
        >
          <Menu.Item key='/login' >
            <Link to='/login'>เข้าสู่ระบบ</Link>
          </Menu.Item>

          <Menu.Item key='/register'>
            {/* <Link to='/register'>สมัครสมาชิก</Link> */}
            <a href='register' onClick={goToRegister}>สมัครสมาชิก</a>
          </Menu.Item>

        </Menu>
        <Dropdown overlay={menuItems} className='dropdown-menu' trigger={['click']}>
          <a className='ant-dropdown-link' href='#'>
            เมนู
          </a>
        </Dropdown>
      </div>
      <div className='rightMobile'>        
        <Menu
          mode='horizontal'
          selectedKeys={[ props.location.pathname ]}
          className={styles.layoutMenu}
        >
          <Menu.Item key='/login' className = {styles.loginTab} style = {{width : 51, left : -16}}>
            <Link to='/login'>เข้าสู่ระบบ</Link>
          </Menu.Item>

          <Menu.Item key='/register' className = {styles.loginTab} style = {{width : 66, left : -21}}>
            <Link to='/register'>สมัครสมาชิก</Link>
          </Menu.Item>

          <Menu.Item key ='/menuItem' style = {{left : -21}}>
            <Dropdown overlay={menuItems} className='dropdown-menu' trigger={['click']}>
              <a className='ant-dropdown-link'>
                เมนู
              </a>
            </Dropdown>
          </Menu.Item> 
        </Menu>
      </div>
    </div>
  )
  

  return (
    <div className={layoutBG ? layoutBG : styles.notFound } >
      <div className={styles.bgtophome} >

      </div>
      {
        props.location.pathname == '/' ? 
          <div className={styles.layoutTopHeader}>
            <Layout className={styles.layout} style = {{maxWidth : '100%'}}>
              <Row>  {/*type='flex' justify='space-between */}
                <Col xs={{span:2}} className={styles.layoutTopHeaderIPST}>
                  <div>IPST</div>
                  <div>LEARNING</div>
                  <div>SPACE</div>
                </Col>
                <Col sm={{span:4}}>
                  <a href='http://teacherpd.ipst.ac.th/' rel='noopener noreferrer' target = '_blank'><div>ระบบอบรมครู</div></a>
                </Col>
                <Col sm={{span:5}}>
                  <a href='http://onlinetesting.ipst.ac.th/index.php?lang=th' rel='noopener noreferrer' target = '_blank'><div>ระบบการสอบออนไลน์</div></a>
                </Col>
                <Col sm={{span:5}} md={{span:5}} lg={{span:4}}>
                  <a href='http://www.scimath.org/' rel='noopener noreferrer' target = '_blank'><div>ระบบคลังความรู้</div></a>
                </Col>
                <Col sm={{span:5}}></Col>
                <Col xs={{span:12, offset:1}} lg={{span:3, offset: 0}} xl={{span:4}}>
                  <a href='http://www.ipst.ac.th' rel='noopener noreferrer' target = '_blank'><img src={LogoIpst} alt='logo' className={styles.onlineLogo} /></a>
                  <a href='http://www.glo.or.th/' rel='noopener noreferrer' target = '_blank'><img src={LogoGlo} alt='logo' className={styles.onlineLogo} /></a>
                </Col>
              </Row>
            </Layout> 
          </div> :
          <div style ={{height : 0}}>
          </div>
      }
      <div style ={{maxWidth: '100%',backgroundColor : ''}}>
        <Layout id = 'forCheckPath' sty className={styles.layout}>
          <Header className={styles.header_guest}>
            {
              props.location.pathname === '/' ? (
                <Fragment>
                  <div className='mobile'>
                    <div className='container'>
                      <div className='left'>
                        <Menu
                          mode='horizontal'
                          className={`${styles.layoutMenu} ${styles.layoutMenuHideMobile}`}
                          id = 'lay_123'
                        >
                          <Menu.Item key='student'>
                            <Link to='/student'>นักเรียน</Link>
                          </Menu.Item>
                          <Menu.Item key='teacher'>
                            <Link to='/teacher'>ครู/อาจารย์</Link>
                          </Menu.Item>
                        </Menu>
                
                      </div>

                      <div className='right'>
                        <Menu>
                          <Menu.Item>
                            <Dropdown overlay={menuItems} className='dropdown-menu' trigger={['click']}>
                              <a className='ant-dropdown-link' href='#'>
                          เมนู
                              </a>
                            </Dropdown>
                          </Menu.Item>
                        </Menu>
                        
                      </div>
                    </div>
                  </div>

                  <div className='desktop'>
                    {containerMenu}
                  </div>
                </Fragment>
              ) : containerMenu
            }
          </Header>
          <div style = {{width : '100%', height : 'auto', backgroundColor : 'white'}}>
            <Content className={styles.layoutContent} >
              <div style = {{maxWidth : 1170}}>
                {props.children}
              </div>
            </Content>
          </div>
          <div style = {{width : '100%' ,display : 'flex', justifyContent : 'center'}}>
            <Footer className={styles.layoutFooter}  style = {{maxWidth : 1170}}>
              <Divider/>
              <Row>
                {
                  window.screen.width >=  991 ?
                    <div>
                      <Col sm={9} xs={24} md = {12}>
                        <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                          <Menu mode='horizontal'
                            selectedKeys={[ props.location.pathname ]}
                            id = 'about'
                            style = {{marginLeft : -20}}
                          >
                            <Menu.Item key='/about'>
                              <a href='/about'>เกี่ยวกับเรา</a>
                            </Menu.Item>
                          </Menu>
                        </Col>
                        <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                          <Menu mode='horizontal'
                            selectedKeys={[ props.location.pathname ]}
                            id = 'contact'
                          >
                            <Menu.Item key='/contact'>
                              <a onClick={goToContact}>ติดต่อเรา</a>
                            </Menu.Item>
                          </Menu>
                        </Col>
                        <Col xs={{ span: 7, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                          <Menu mode='horizontal'
                            selectedKeys={[ props.location.pathname ]}
                            id = 'lay_3'
                          >
                            <Menu.Item key='/faqguest'>
                              <a href='/faqguest'>คำถามที่พบบ่อย</a>
                            </Menu.Item>
                          </Menu>
                        </Col>
                      </Col>

                      <Col sm ={1} md = {2} xs = {0}>
                      </Col>

                      <Col sm ={9} md = {10} xs={24} justify = {'end'} style = {{display : 'flex'}}>
                        <Col span = {11} sm = {11} >
                          <Menu mode='horizontal'
                            selectedKeys={[ props.location.pathname ]}
                            id = 'lay_4'
                          >
                            <Menu.Item key='/term'>
                              <a href='/term'>เงื่อนไขและข้อกำหนด</a>
                            </Menu.Item>
                          </Menu>
                        </Col>
                        <Col span = {9} sm = {11} >
                          <Menu mode='horizontal'
                            selectedKeys={[ props.location.pathname ]}
                            id = 'lay_5'
                          >
                            <Menu.Item key='data_pivacy'>
                              <a href='/data_pivacy'>นโยบายความเป็นส่วนตัว</a>
                            </Menu.Item>
                          </Menu>
                        </Col>
                        <Col sm = {1} xs = {24}>
                          <Menu mode='horizontal'
                            selectedKeys={[ props.location.pathname ]}
                            id = 'lay_5'
                          >
                            <Menu.Item key='/facebook'>
                              <a href = 'https://www.facebook.com/IPST.OnlineTesting/' rel='noopener noreferrer' target='_blank'><img src={Facebook}/></a>
                            </Menu.Item>
                          </Menu>
                        </Col>
                      </Col>
                    </div>
                    :
                    window.screen.width >=  768 ?
                      <div>
                        <Col>
                          <Col xs={{ span: 2, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                            <Menu mode='horizontal'
                              selectedKeys={[ props.location.pathname ]}
                              id = 'about'
                              style = {{marginLeft : -20}}
                            >
                              <Menu.Item key='/about'>
                                <a href='/about'>เกี่ยวกับเรา</a>
                              </Menu.Item>
                            </Menu>
                          </Col>
                          <Col xs={{ span: 2, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                            <Menu mode='horizontal'
                              selectedKeys={[ props.location.pathname ]}
                              id = 'contact'
                            >
                              <Menu.Item key='/contact'>
                                {/* <a href='/contact'>ติดต่อเรา</a> */}
                                <a onClick={goToContact}>ติดต่อเรา</a>
                              </Menu.Item>
                            </Menu>
                          </Col>
                          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                            <Menu mode='horizontal'
                              selectedKeys={[ props.location.pathname ]}
                              id = 'lay_3'
                            >
                              <Menu.Item key='/faqguest'>
                                <a href='/faqguest'>คำถามที่พบบ่อย</a>
                              </Menu.Item>
                            </Menu>
                          </Col>
                          <Col justify = {'end'} style = {{display : 'flex'}}>
                            <Col span = {11} sm = {11} >
                              <Menu mode='horizontal'
                                selectedKeys={[ props.location.pathname ]}
                                id = 'lay_4'
                              >
                                <Menu.Item key='/term'>
                                  <a href='term'>เงื่อนไขและข้อกำหนด</a>
                                </Menu.Item>
                              </Menu>
                            </Col>
                            <Col span = {9} sm = {11}>
                              <Menu mode='horizontal'
                                selectedKeys={[ props.location.pathname ]}
                                id = 'lay_5'
                              >
                                <Menu.Item key='data_pivacy'>
                                  <a href='/data_pivacy'>นโยบายความเป็นส่วนตัว</a>
                                </Menu.Item>
                              </Menu>
                            </Col>
                            <Col sm = {1} xs = {24}>
                              <Menu mode='horizontal'
                                selectedKeys={[ props.location.pathname ]}
                                id = 'lay_5'
                              >
                                <Menu.Item key='/facebook'>
                                  <a href = 'https://www.facebook.com/IPST.OnlineTesting/' rel='noopener noreferrer' target='_blank'><img src={Facebook}/></a>
                                </Menu.Item>
                              </Menu>
                            </Col>
                          </Col>
                        </Col>
                      </div>
                      :
                      window.screen.width < 768 ?
                        <div >
                          <Col>
                            <div style = {{display : 'flex', justifyContent : 'center'}}>
                              <Col>
                                <Menu mode='horizontal'
                                  selectedKeys={[ props.location.pathname ]}
                                  id = 'about'
                                >
                                  <Menu.Item key='/about'>
                                    <a href='/about'>เกี่ยวกับเรา</a>
                                  </Menu.Item>
                                </Menu>
                              </Col>
                              <Col >
                                <Menu mode='horizontal'
                                  selectedKeys={[ props.location.pathname ]}
                                  id = 'contact'
                                >
                                  <Menu.Item key='/contact'>
                                    {/* <a href='/contact'>ติดต่อเรา</a> */}
                                    <a onClick={goToContact}>ติดต่อเรา</a>
                                  </Menu.Item>
                                </Menu>
                              </Col>
                              <Col>
                                <Menu mode='horizontal'
                                  selectedKeys={[ props.location.pathname ]}
                                  id = 'lay_3'
                                >
                                  <Menu.Item key='/faqguest'>
                                    <a href='/faqguest'>คำถามที่พบบ่อย</a>
                                  </Menu.Item>
                                </Menu>
                              </Col>
                            </div>
                            <div style = {{display : 'flex', justifyContent : 'center'}}>
                              <Col>
                                <Menu mode='horizontal'
                                  selectedKeys={[ props.location.pathname ]}
                                  id = 'lay_4'
                                >
                                  <Menu.Item key='/term'>
                                    <a href='term'>เงื่อนไขและข้อกำหนด</a>
                                  </Menu.Item>
                                </Menu>
                              </Col>
                              <Col >
                                <Menu mode='horizontal'
                                  selectedKeys={[ props.location.pathname ]}
                                  id = 'lay_5'
                                >
                                  <Menu.Item key='data_pivacy'>
                                    <a href='/data_pivacy'>นโยบายความเป็นส่วนตัว</a>
                                  </Menu.Item>
                                </Menu>
                              </Col>
                            </div>
                            <div style = {{display : 'flex', justifyContent : 'center'}}>
                              <Col>
                                <Menu mode='horizontal'
                                  selectedKeys={[ props.location.pathname ]}
                                  id = 'lay_5'
                                >
                                  <Menu.Item key='/facebook'>
                                    <a href = 'https://www.facebook.com/IPST.OnlineTesting/' rel='noopener noreferrer' target='_blank'><img src={Facebook}/></a>
                                  </Menu.Item>
                                </Menu>
                              </Col>
                            </div>
                          </Col>
                        </div>
                        :
                        ''          
                }
              </Row>
              {
                window.screen.width < 768 ? 
                  <div className = {styles.copyrightLabel}>
                    <p className='text-thin' style ={{color : 'white', marginBottom : 15}} >© 2562 สถาบันส่งเสริมการสอนวิทยาศาสตร์และเทคโนโลยี (สสวท.)</p>
                  </div>
                  :
                  <div className = {styles.copyrightLabel}>
                    <p className='text-thin' style ={{color : 'white'}} >© 2562 สถาบันส่งเสริมการสอนวิทยาศาสตร์และเทคโนโลยี (สสวท.)</p>
                  </div>
              }
            </Footer>
        
          </div>

        
        </Layout>
      </div>
      <ModalServerUnavailable serverStatus={serverStatus} isModalOffline={isModalOffline} setIsModalOffline={setIsModalOffline} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    serverStatus: state.master.serverStatus,
  }
}


export default withRouter(connect(mapStateToProps)(LayoutWhiteGuest))