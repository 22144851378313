import React from 'react'
import { Icon } from 'antd'
import { ReactComponent as Correct } from './Correct.svg'
import { ReactComponent as CorrectGray } from './CorrectGray.svg'
import { ReactComponent as Incorrect } from './Incorrect.svg'
import { ReactComponent as Edit } from './Edit.svg'
import { ReactComponent as EditGray } from './EditGray.svg'
import { ReactComponent as EditProfile } from './EditProfile.svg'

import { ReactComponent as Exam } from './Exam.svg'
import { ReactComponent as ExamSet } from './ExamSet.svg'
import { ReactComponent as ExamAdd } from './ExamAdd.svg'
import { ReactComponent as ExamAddGray } from './ExamAddGray.svg'
import { ReactComponent as ExamMath } from './ExamMath.svg'
import { ReactComponent as ExamScience } from './ExamScience.svg'
import { ReactComponent as ExamSetCurriculumAdd } from './ExamSetCurriculumAdd.svg'
import { ReactComponent as ExamSetCompetitionAdd } from './ExamSetCompetitionAdd.svg'
import { ReactComponent as ExamSetCompetition } from './ExamSetCompetition.svg'
import ExamSetCustomAdd from './ExamSetCustomAdd.svg'
import ExamSetCATAdd    from './ExamSetCATAdd.svg'

import ExamBankLock from './ExamBankLock.svg'
import ExamBankUnlock    from './ExamBankUnlock.svg'

import { ReactComponent as ExamWarning } from './ExamWarning.svg'
import { ReactComponent as ExamSuccess } from './ExamSuccess.svg'

import { ReactComponent as Group } from './Group.svg'
import { ReactComponent as GroupAdd } from './GroupAdd.svg'
import { ReactComponent as Logout } from './Logout.svg'
import { ReactComponent as Report } from './Report.svg'
import { ReactComponent as ReportGray } from './ReportGray.svg'
import { ReactComponent as Search } from './Search.svg'
import { ReactComponent as Delete } from './Delete.svg'
import { ReactComponent as DeleteGray } from './DeleteGray.svg'
import { ReactComponent as Share } from './Share.svg'
import { ReactComponent as ShareGray } from './ShareGray.svg'

import { ReactComponent as Lesson } from './Lesson.svg'
import { ReactComponent as LessonNew } from './IconLessons.svg'
import { ReactComponent as LessonGray } from './LessonGray.svg'
import { ReactComponent as Indicator } from './Indicator.svg'
import { ReactComponent as IndicatorGray } from './IndicatorGray.svg'
import { ReactComponent as CriterionNone } from './CriterionNone.svg'
import { ReactComponent as CriterionNoneGray } from './CriterionNoneGray.svg'

import { ReactComponent as Copy } from './Copy.svg'
import { ReactComponent as Facebook } from './Facebook.svg'

import { ReactComponent as IconMasterData } from './IconMasterData.svg'
import { ReactComponent as IconSubjectsData } from './IconSubjectsData.svg'
import { ReactComponent as IconNotice } from './IconNotice.svg'
import { ReactComponent as IconListUser }from './IconListUser.svg'

export const IconValidate       = props => <Icon component={ props.correct !== undefined ? Correct : CorrectGray} {...props} />
export const IconCorrect        = props => <Icon id='correctIcon' component={ Correct } {...props} />
export const IconCorrectGray    = props => <Icon component={ CorrectGray } {...props} />
export const IconIncorrect      = props => <Icon id='incorrectIcon' component={ Incorrect } {...props} />
export const IconEdit           = props => <Icon component={ Edit } {...props} />
export const IconEditGray       = props => <Icon component={ EditGray } {...props} />
export const IconEditProfile    = props => <Icon component={ EditProfile } {...props} />

export const IconExam           = props => <Icon component={ Exam } {...props} />
export const IconExamAdd        = props => <Icon component={ ExamAdd } {...props} />
export const IconExamSet        = props => <Icon component={ ExamSet } {...props} />
export const IconExamAddGray    = props => <Icon component={ ExamAddGray } {...props} />
export const IconExamMath       = props => <Icon component={ ExamMath } {...props} />
export const IconExamScience    = props => <Icon component={ ExamScience } {...props} />
export const IconExamSetCurriculumAdd   = props => <Icon component={ ExamSetCurriculumAdd } {...props} />
export const IconExamSetCompetitionAdd  = props => <Icon component={ ExamSetCompetitionAdd } {...props} />
export const IconExamSetCompetition     = props => <Icon component={ ExamSetCompetition } {...props} />
export const IconExamSetCustomAdd       = props => <img alt='exam-custom-add' src={ ExamSetCustomAdd } {...props} />
export const IconExamSetCATAdd          = props => <img alt='exam-cat-add' src={ ExamSetCATAdd } {...props} />

export const IconExamBankLock    = props => <img alt='exambank-lock' src={ ExamBankLock } {...props} />
export const IconExamBankUnlock    = props => <img alt='exambank-unlock' src={ ExamBankUnlock } {...props} />

export const IconExamWarning    = props => <Icon component={ ExamWarning } {...props} />
export const IconExamSuccess    = props => <Icon component={ ExamSuccess } {...props} />

export const IconGroup          = props => <Icon component={ Group } {...props} />
export const IconGroupAdd       = props => <Icon component={ GroupAdd } {...props} />
export const IconLogout         = props => <Icon component={ Logout } {...props} />
export const IconReport         = props => <Icon component={ Report } {...props} />
export const IconReportGray     = props => <Icon component={ ReportGray } {...props} />
export const IconSearch         = props => <Icon component={ Search } {...props} />
export const IconDelete         = props => <Icon component={ Delete } {...props} />
export const IconDeleteGray     = props => <Icon component={ DeleteGray } {...props} />
export const IconShare          = props => <Icon component={ Share } {...props} />
export const IconShareGray      = props => <Icon component={ ShareGray } {...props} />

export const IconLesson               = props => <Icon component={ Lesson } {...props} />
export const IconLessonNew            = props => <Icon component={ LessonNew } {...props} />
export const IconLessonGray           = props => <Icon component={ LessonGray } {...props} />
export const IconIndicator            = props => <Icon component={ Indicator } {...props} />
export const IconIndicatorGray        = props => <Icon component={ IndicatorGray } {...props} />
export const IconCriterionNone        = props => <Icon component={ CriterionNone } {...props} />
export const IconCriterionNoneGray    = props => <Icon component={ CriterionNoneGray } {...props} />

export const IconCopy           = props => <Icon component={ Copy } {...props} />
export const IconFacebook       = props => <Icon component={ Facebook } {...props} />

export const IconMasterDataSideBar     = props => <Icon component={ IconMasterData } {...props} />
export const IconSubjectsDataSideBar   = props => <Icon component={ IconSubjectsData } {...props} />
export const IconNoticeDataSideBar   = props => <Icon component={ IconNotice } {...props} />
export const IconListUserSideBar     = props =>  <Icon component={ IconListUser } {...props} />