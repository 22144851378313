/* eslint-disable no-undef */
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import styles from '../LayoutGuest.module.scss'
import styles from './StudentComponent.module.scss'
import { Row, Button, Col } from 'antd'
import StudentContent1 from '../../IconSVG/StudentContent1.svg'
import StudentContent2 from '../../IconSVG/StudentContent2.svg'
import StudentContent3 from '../../IconSVG/StudentContent3.svg'
import Boy from '../../IconSVG/Boy.svg' 
import Girl from '../../IconSVG/Girl.svg'
import ArrowLeft from '../../IconSVG/ArrowLeftIcon.svg'
import ArrowRight from '../../IconSVG/ArrowRightIcon.svg'
import ModalServerUnavailable from '../../Server/ModalServerUnavailable'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css' 
import { LightAnimation } from '../../animations'
function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <img src = {ArrowRight} onClick={onClick}  style={{ ...style, display: 'block' }} className={className}></img>
  )
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <img src = {ArrowLeft} onClick={onClick}  style={{ ...style, display: 'block' }} className={className}></img>
  )
}

class StudentDetailComponent extends Component{
  constructor(props) {
    super(props)
    this.state = {
      widthInner : 0,
      isModalOffline: false
    }
  }
  componentDidMount = ()=>{
    this.setState({
      widthInner : window.innerWidth
    })
  }
  goToSuggestExam = () => { 
    const { serverStatus } = this.props 
    if(serverStatus.isOffline) {
      this.setState({ isModalOffline: true })
    }else {
      this.props.history.push('/homesuggest')
    } 
  }
  goToRegister = () => {
    const { serverStatus } = this.props 
    if(serverStatus.isOffline) {
      this.setState({ isModalOffline: true }) 
    }else {
      this.props.history.push('/register')
    } 
  } 
  render(){
    const { serverStatus } = this.props
    const { isModalOffline } = this.state
    const settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      centerPadding: '30px',
      slidesToShow: this.state.widthInner > 414 ? 3 : 1,
      slidesToScroll: 3,
      speed: 500,
      prevArrow : <SamplePrevArrow/> ,
      nextArrow: <SampleNextArrow/>,
      
    } 
    return(
      <div className = {styles.divStudentDetail} style = {{textAlign:'center'}}>
        <div className = {styles.slickShow}> 
          <div style ={{width : '80%',alignItems : 'center', marginLeft : '12%'}}>
            <Slider  {...settings} >
              <div>
                <div>
                  <label className = {styles.titleStudentContentFirst}>จัดชุดง่าย เลือกข้อสอบได้เอง</label>
                </div>
                <div>
                  <img src = {StudentContent1} className = {styles.StudentContent}></img>
                </div>
              </div>
              <div>
                <div>
                  <label className = {styles.titleStudentContentSecond}>ทำข้อสอบหลากหลายรูปแบบ</label>
                </div>
                <div>
                  <img src = {StudentContent2} className = {styles.StudentContent}></img>
                </div>
              </div>
              <div>
                <div>
                  <label className = {styles.titleStudentContentthird}>รู้คะแนนทันที พร้อมเฉลยคำตอบ</label>
                </div>
                <div>
                  <img src = {StudentContent3} className = {styles.StudentContent}></img>
                </div>
              </div>
              <div>
                <div>
                  <label className = {styles.titleStudentContentFirst}>จัดชุดง่าย เลือกข้อสอบได้เอง</label>
                </div>
                <div>
                  <img src = {StudentContent1} className = {styles.StudentContent}></img>
                </div>
              </div>
              <div>
                <div>
                  <label className = {styles.titleStudentContentSecond}>ทำข้อสอบหลากหลายรูปแบบ</label>
                </div>
                <div>
                  <img src = {StudentContent2} className = {styles.StudentContent}></img>
                </div>
              </div>
              <div>
                <div>
                  <label className = {styles.titleStudentContentthird}>รู้คะแนนทันที พร้อมเฉลยคำตอบ</label>
                </div>   
                <div>
                  <img src = {StudentContent3} className = {styles.StudentContent}></img>
                </div>                
              </div>
            </Slider>
          </div> 
        </div>

        <div className = {styles.divStudentDetail} style = {{marginTop : '0', textAlign : 'center'}}>
          {/* <img src = {Boy} className = {styles.Boy}></img> */}
          {/* <img src = {MaskStudent} className = {styles.LightMap}></img> */}
          {/* <img src = {Girl} className = {styles.Girl}></img> */}
          <Row className = {styles.rowObject}>
            <Col md = {8} xs = {6}>
              <img src = {Boy} className = {styles.Boy}></img>
            </Col>
            <Col md = {8} xs = {10}>
              <div className={styles.animation_content1}>
                <div className='mobile'>
                  <LightAnimation width={47} height={65} />
                </div>

                <div className='desktop'>
                  <LightAnimation width={88} height={123} />
                </div>
              </div>
            </Col>
            <Col md = {8} xs = {6}>
              <img src = {Girl} className = {styles.Girl}></img>
            </Col>
          </Row>  
        </div>

        <div className = {styles.divStudentDetailBtn} style = {{marginTop : 58, textAlign : 'center'}}>
          <Button onClick={this.goToSuggestExam} style={{ width: 200,height: '35' , margin: 10 }} 
            className = {styles.btnFirst}>ทดลองทำข้อสอบ
          </Button>
          <Button onClick={this.goToRegister} type='primary' style={{ width: 200, height: '35' , margin: 10}}
            className = {styles.btnSecond}>
            สมัครสมาชิก
          </Button>
          {/* <Button onClick={this.goToSuggestExam} style={{ width: 200,height: '35' , margin: 10 }} 
            className = {styles.btnFirstMobile}>ทดลองทำข้อสอบ
          </Button> */}
        </div>
        <ModalServerUnavailable serverStatus={serverStatus} isModalOffline={isModalOffline}/>
      </div>)
  }
}

const mapStateToProps = (state) => {
  return {
    serverStatus: state.master.serverStatus,
  }
}

export default connect(mapStateToProps)(StudentDetailComponent)