import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'  
import styles from './School.module.scss'
import { 
  Button,
  Input,
  Row,
  Col,
  Divider, 
  Select,
  Form,
  message  
} from 'antd'
const { Option } = Select

class EditForm extends Component {
  constructor(props){
    super(props) 
    this.state = {
      dataSchool: {
        name: null,
        addressNo: null,
        villageNo: null,
        lane: null,
        road:null,
        subDistrict:null,
        district: null,
        province: null,
        postalCode: null,
        department: null,
        remark: null, 
      },
    }
  }
  setStateData = () => {
    this.setState({ 
      dataSchool: {
        name: null,
        addressNo: null,
        villageNo: null,
        lane: null,
        road:null,
        subDistrict:null,
        district: null,
        province: null,
        postalCode: null,
        department: null,
        remark: null, 
      }, })
  }
  onAddData = (e) => { 
    const { fetch, onCloseModal, schoolId, provinceList } = this.props 
    e.preventDefault()  
    
    this.props.form.validateFieldsAndScroll( async(err, values) => {   
      if (!err ) { 
        let data = {
          addressNo: values.addressNo,
          district: values.district,
          lane: values.lane,
          name: values.name,
          postalCode: values.postalCode, 
          province:   provinceList.items.find(p => p.name === values.province)._id , 
          remark: values.remark,
          road: values.road,
          subDistrict: values.subDistrict,
          villageNo: values.villageNo,
          department: values.department
        }  
        await this.props.onUpdateSchool(schoolId,data) 
        await fetch()
        message.success('แก้ไขข้อมูลสำเร็จ') 
        this.setStateData()
        onCloseModal()
        this.setState({ isEdit: false})  
      }
    }) 
  }
  render(){
    const { onCloseModal,items,provinceList ,form: { getFieldDecorator },departmentFull } = this.props  
    return(
      <Form onSubmit={ this.onAddData } hideRequiredMark={true}> 
        <Row className={styles.row_form}>
          <h1 >แก้ไขข้อมูล</h1> 
          <Col offset={4} span={16} className={styles.col_form}> 
            <Row className={styles.row_in_form}> 
              <Form.Item label='โรงเรียน/หน่วยงาน'>
                {
                  getFieldDecorator('name', {
                    rules: [ 
                      { 
                        required: true,
                        min: 1, 
                        message: 'กรุณากรอกชื่อโรงเรียน',
                      }, 
                    ], 
                    initialValue: items ? items.name : ''
                  }
                  )(<Input/>)
                } 
              </Form.Item> 
            </Row>  
            <Row className={styles.row_in_form}>
              <Col span={11}> 
                <Form.Item label='เลขที่' >
                  {
                    getFieldDecorator('addressNo', {
                      rules: [ 
                        {
                          required: true,
                          min: 1,
                          message: 'กรุณากรอกเลขที่',
                        }, 
                      ], 
                      initialValue: items ? items.addressNo : ''
                    }
                    )(<Input/>)} 
                </Form.Item> 
              </Col>
              <Col offset={2} span={11}> 
                <Form.Item label='หมู่ที่ (ไม่บังคับ)' required={false}>
                  {
                    getFieldDecorator('villageNo', { 
                      rules: [ 
                        { 
                          pattern: /^[0-9/]*$/,
                          message: 'กรุณาใส่ตัวเลข'
                        } 
                      ],  
                      initialValue: items ? items.villageNo : ''
                    }
                    )(<Input/>)} 
                </Form.Item>  
              </Col>
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='ซอย (ไม่บังคับ)'>
                {
                  getFieldDecorator('lane', {
                    rules: [ 
                      {
                        min: 1,
                        message: 'กรุณากรอกซอย',
                      }, 
                    ], 
                    initialValue: items ? items.lane : ''
                  }
                  )(<Input/>)} 
              </Form.Item>   
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='ถนน (ไม่บังคับ)'>
                {
                  getFieldDecorator('road', {
                    rules: [ 
                      {
                        min: 1,
                        message: 'กรุณากรอกถนน',
                      }, 
                    ], 
                    initialValue: items ? items.road : ''
                  }
                  )(<Input/>)} 
              </Form.Item>  
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='ตำบล/แขวง'>
                {
                  getFieldDecorator('district', {
                    rules: [ 
                      {
                        required: true,
                        min: 1,
                        message: 'กรุณากรอกตำบล/แขวง',
                      }, 
                    ], 
                    initialValue: items ? items.district : ''
                  }
                  )(<Input/>)} 
              </Form.Item> 
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='อำเภอ/เขต'>
                {
                  getFieldDecorator('subDistrict', {
                    rules: [ 
                      {
                        required: true,
                        min: 1,
                        message: 'กรุณากรอกอำเภอ/เขต',
                      }, 
                    ], 
                    initialValue: items ? items.subDistrict : ''
                  }
                  )(<Input/>)} 
              </Form.Item>  
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='จังหวัด'>
                {
                  getFieldDecorator('province', {
                    rules: [ 
                      {
                        required: true,
                        min: 1,
                        message: 'กรุณากรอกจังหวัด',
                      }, 
                    ],
                    initialValue: items ? items.province : '' 
                    // initialValue: provinceItem ? provinceItem.name : ''
                  }
                  )(<Select  
                    showSearch
                    style={{ width: '100%' }}  
                  >
                    { 
                      provinceList ?
                        provinceList.items.map((p,i) => <Option key={i} value={p.name}>{p.name}</Option>)
                        :''
                    } 
                  </Select>)} 
              </Form.Item>    
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='รหัสไปรษณีย์'>
                {
                  getFieldDecorator('postalCode', {
                    rules: [ 
                      {
                        required: true,
                        pattern: /^[0-9]{5}$/,
                        message: 'กรุณาใส่รหัสไปรษณีย์ให้ครบ 5 หลัก'
                      }
                    ], 
                    initialValue: items ? items.postalCode : ''
                  }
                  )(<Input/>)} 
              </Form.Item> 
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='สังกัดของโรงเรียน/หน่วยงาน'>
                {
                  getFieldDecorator('department', {
                    rules: [ 
                      {
                        required: true,
                        min: 1,
                        message: 'กรุณากรอกสังกัดของโรงเรียน/หน่วยงาน',
                      }, 
                    ], 
                    initialValue: items ? items.department : ''
                  }
                  )(<Select  
                    showSearch
                    style={{ width: '100%' }}  
                    // placeholder={items ? items.department : ''}
                    // onChange={e => { this.setDataSchool(e,'department') }}
                  >
                    { 
                      departmentFull ?
                        departmentFull.items.map((d,i) => <Option key={i} value={d.name}>{d.name}</Option>)
                        :''
                    } 
                  </Select>  )} 
              </Form.Item> 
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='หมายเหตุ'>
                {
                  getFieldDecorator('remark', {
                    initialValue: items ? items.remark : ''
                  }
                  )(<Input/>)
                } 
              </Form.Item>  
            </Row>
          </Col>
          <Divider/>
          <Row> 
            <Col span={12}>
              <Form.Item>
                <p onClick={onCloseModal} className={styles.cancel_button}>ยกเลิก</p>
              </Form.Item>            
            </Col> 
            <Col span={12}>
              <Form.Item >
                <Button htmlType='submit'  type='primary' style={{ width: '178px', float: 'right' }}>
                  บันทึก
                </Button>
              </Form.Item>
            </Col>  
          </Row>
        </Row> 
      </Form>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
       
    },
    dispatch
  )
const WrappedFormDataSchool = Form.create({ name: 'Edit_form_school' })(EditForm)
export default connect(mapStateToProps,mapDispatchToProps)(WrappedFormDataSchool)