import React, { Component } from 'react'
import { Menu, Dropdown } from 'antd' 

import IconMore from '../../IconSVG/IconMore.svg'
import './DropdownItems.scss' 

class DropdownItems extends Component {
  static defaultProps = {
    menu: [],
    dropdownDivider: false,
    dropdownWidth: null
  }

  render () {
    const { menu, dropdownDivider, dropdownWidth } = this.props
    
    const menuItems = menu.length > 0 ? (
      <Menu style={ dropdownWidth ? { width: dropdownWidth } : null}>
        {
          menu.map((item, i) => { 
            if(i < menu.length - 1 && dropdownDivider ) {
              return [  
                <Menu.Item key={i}>
                  {item.attribute} 
                </Menu.Item>,
                <Menu.Divider key={`divider${i}`}/>
              ]
            }else {
              return (
                <Menu.Item key={i}>
                  {item.attribute} 
                </Menu.Item>
              )
            }
          })
        }
        
      </Menu>
    ) : null

    return (
      <div className='dropdown-items'>
        <Dropdown overlay={menuItems} trigger={['click']}>
          <a className='ant-dropdown-link' href='/#'>
            <img src={IconMore} alt='icon-more'/>
          </a>
        </Dropdown>
      </div>
    )
  }
}

export default DropdownItems
