import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom' 
 
import ExamsetOverview from './ExamsetOverview'
import ExamsetDescription from './ExamsetDescription'

class ReportExamSet extends Component {
  state = {
    isLoading: false,
  }

  static defaultProps = {
    collection: []
  }

  componentDidMount () {
    this.loadStats()
  }
  spinOnLoading = () => { this.setState({ isLoading: true }) }
  spinOffLoading = () => { this.setState({ isLoading: false }) }
  loadStats = async() => {
    const { year } = this.props
    this.spinOnLoading()
    const res = await this.props.loadStats('exam', year)
    this.spinOffLoading()
    return res
  }

  loadDetail = async(year, month, type) => { 
    this.spinOnLoading() 
    let monthString = month < 10 ? `0${month}` : month 
    const res = await this.props.loadStatsDetail('exam', year, monthString, type )  
    this.spinOffLoading()
    return res
  }

  loadType = async(year, month) => {
    this.spinOnLoading()   
    let monthString = month < 10 ? `0${month}` : month
    const res = await this.props.loadStatsType('exam', year, monthString)   
    this.spinOffLoading()
    return res
  }

  getStatExamTransaction = async(year, month) => {
    this.spinOnLoading()  
    let monthString = month < 10 ? `0${month}` : month
    const res = await this.props.getStatExamTransaction(year, monthString)
    this.spinOffLoading() 
    return res
  }

  render () { 
    return (
      <div>
        <Switch>
          <Route exact
            path='/reportexamset'
            render={() => <ExamsetOverview
              spinOnLoading={this.spinOnLoading}
              spinOffLoading={this.spinOffLoading}
              loadDetail={this.loadDetail}
              loadType={this.loadType}
              getTransaction={this.getStatExamTransaction}
              {...this.props} />
            }
          />

          <Route exact
            path='/reportexamset/description'
            render={() => <ExamsetDescription
              spinOnLoading={this.spinOnLoading}
              spinOffLoading={this.spinOffLoading}
              loadDetail={this.loadDetail}
              loadType={this.loadType}
              getTransaction={this.getStatExamTransaction}
              {...this.props} />
            }
          />
        </Switch>
      </div>
    )
  }
}

export default ReportExamSet
