import React, { Component } from 'react' 
import { ToastContainer } from 'react-toastify' 
import 'react-toastify/dist/ReactToastify.css'
 
class NoticeAlert extends Component {
  render() {  
    const { type } = this.props
    return (<div>{type === 'first' ? <ToastContainer style ={{width : '87%'}}/> : ''}</div>)
  }
}

export default NoticeAlert