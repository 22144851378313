import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  onRemoveFaq
} from '../../redux/master/action'
import {
  Modal,
  Button,
  Row,
  Col,
} from 'antd'
import BigWarningIcon from '../IconSVG/BigWarningIcon.svg'
import styles from './Faq.module.scss'
class ModalFaq extends Component {
  constructor(props){
    super(props)
    this.state = {
      createPrefix: {name: null}
    }
  }
 
  onRemoveFaq = async(faqId) => {
    const { fetchFaqList, onCloseModal, onRemoveFaq, backToHome } = this.props
    await onRemoveFaq(faqId)
    await fetchFaqList()
    backToHome()
    onCloseModal()
  }
  render(){
    const { modalVisible, onCloseModal, items, typeModal } = this.props
    const deleteModal = (
      <div>
        <Row>
          <Col span={24} align='center'>
            <div>
              <img alt='big-warning-icon' src={BigWarningIcon}/>
            </div>
            <h2>ลบคำถาม</h2>
            <div style={{width : '204px',whiteSpace : 'nowrap', overflow : 'hidden', textOverflow : 'ellipsis',marginBottom : '1em'}}>
              <span className = {styles.questionForDelete}>{items ? items.question : ''}</span>
            </div>
            <span>คุณต้องการลบคำถามนี้หรือไม่</span>
            <Row style={{ paddingTop: '25px' }} >
              <Col span={12} >
                <Button onClick={e=>{
                  e.stopPropagation()
                  onCloseModal()
                }} style={{width: '70%', float: 'right', marginRight: '15px'}}>ไม่ลบ</Button>
              </Col>
              <Col span={12}>
                <Button onClick={e => {
                  e.stopPropagation()
                  this.onRemoveFaq(items._id)
                }} style={{width: '70%', float: 'left',marginLeft: '15px' }} type='primary' >ลบคำถาม</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
    return ( 
      <div>
        <Modal 
          width='580px'
          closable={true}
          visible={modalVisible}
          onCancel={onCloseModal}
          footer={false}
        > 
          {
            typeModal === 'delete' ?
              <div>
                {deleteModal}
              </div>
              :
              ''
          }
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
      onRemoveFaq,      
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(ModalFaq)