import React,{ Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ResultScore from '../TestingResult/ResultScore'
import stylesTesting from '../Testing/Testing.module.scss'
import {
  Modal, 
} from 'antd'
import {
  FacebookShareButton,
  TwitterShareButton
} from 'react-share'
import { GetProfile } from '../../redux/auth/action'
import { getResultTesting } from '../../redux/testing/action'
import { getDetailMyExam } from '../../redux/exam/action'
import Download from 'downloadjs'
import * as htmlToImage from 'html-to-image'
import TwitterScoreShareIcon from '../IconSVG/TwitterScoreShareIcon.svg'
import FacebookScoreShareIcon from '../IconSVG/FacebookScoreShareIcon.svg'
import SaveScoreIcon from '../IconSVG/SaveScoreIcon.svg'
import styles from './Report.module.scss'

class ModalScore  extends Component {
  constructor(props){
    super(props)
    this.state = {
      visibleShare: false
    }
  }
  onOpenShare = async() => {
    this.setState({
      visibleShare: true,
      logo: true
    })
  }
  onCloseShare = () => {
    this.setState({
      visibleShare: false,
      logo: false
    })
  }
  onDownload = async () => {
    // eslint-disable-next-line no-undef
    const resultId = await document.getElementById('result') 
    await htmlToImage.toPng(resultId).then(async(dataUrl) => {
      await Download(dataUrl, 'Score.png', 'image/png') 
    })
  }
  render(){
    const { visibleShare, downloadScore } = this.state
    const { auth ,visibleModalScore, onCloseModalScore, testingDetail, examDetail, user, examTime} = this.props
     
    return (
      <Modal
        visible = { visibleModalScore }
        onCancel = { onCloseModalScore }
        footer = { false }
        // width={'80%'}
        className={styles.modal_score}
        // bodyStyle = {{ backgroundColor: '#112AAE', color: 'white' }}
      >
        <ResultScore profileImage={auth.profileImage} examTime={examTime} exam={examDetail} testingResult={testingDetail} user={user} visibleShare ={visibleShare} downloadScore={downloadScore}/>

        <div className='actions'>
          <div style={{ cursor: 'pointer' , zIndex: 2}}  className='save' onClick={this.onDownload}>
            <img alt='icon-save-score' src={SaveScoreIcon}/>
            <p>บันทึกรูปภาพ</p>
          </div>

          <div className='social'>
            <p>แชร์</p>

            <div className={stylesTesting.group_social_share_right}>
              <FacebookShareButton url={`${process.env.REACT_APP_API_URL}/share/fb/${testingDetail.id}/testing`} >
                <img alt='icon-facebook-score-share' style={{ cursor: 'pointer'}} src={FacebookScoreShareIcon}/>
              </FacebookShareButton>
            </div>

            <div className={stylesTesting.group_social_share_right}>
              <TwitterShareButton url={process.env.REACT_APP_WEB_URL} >
                <img alt='icon-twitter-score-share' style={{ cursor: 'pointer'}} src={TwitterScoreShareIcon}/>
              </TwitterShareButton>
            </div>
          </div>
        </div>

        {/* <Row className={stylesTesting.row_button} style={{ zIndex: '5' }}>
          <Col xl={16} lg={16} offset={4}>
            <Row>
              <Col xl={8} lg={8}>
                <div className={stylesTesting.group_social_share}>
                  <Col xl={12} lg={12} onClick={this.onDownload} align='center'>
                    <div >
                      <img style={{ cursor: 'pointer'}} src={SaveScoreIcon}/>
                    </div>
                    <div >
                      <span style={{ cursor: 'pointer'}}>บันทึกรูปภาพ</span>
                    </div>
                  </Col>
                </div>
              </Col>
              <Col xl={8} lg={8} offset={8}>
                <div className={stylesTesting.group_social_share_right}>
                  <Col xl={8} lg={8}>
                    <div style={{ paddingTop:'10px'}}>
                      <h3 className={stylesTesting.row_result_text_content} style={{ color: 'white'}}>แชร์</h3>
                    </div>
                  </Col>
                  <Col xl={8} lg={8}>
                    <div className={stylesTesting.group_social_share_right}>
                      <FacebookShareButton url={'http://52.74.3.6:3001'} >
                        <img style={{ cursor: 'pointer'}} src={FacebookScoreShareIcon}/>
                      </FacebookShareButton>
                    </div>
                  </Col>
                  <Col xl={8} lg={8}>
                    <div className={stylesTesting.group_social_share_right}>
                      <TwitterShareButton url={'http://52.74.3.6:3001'} >
                        <img style={{ cursor: 'pointer'}} src={TwitterScoreShareIcon}/>
                      </TwitterShareButton>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </Col>
        </Row> */}
      </Modal>
    )
  }
}
const mapStateToProps = state => {
  return{
    auth: state.auth.profile
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getResultTesting,
      getDetailMyExam,
      GetProfile
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ModalScore)
