import React, { Component , Suspense } from 'react'
import {
  Button,
  Input,
  Table,
  Modal,
  Avatar
} from 'antd'
import PropTypes from 'prop-types'
// import Loadable from 'react-loadable'
import Tour from 'reactour'

import IconGroupAdd from '../../IconSVG/GroupAdd.svg'
import IconSearch from '../../IconSVG/Search.svg' 
import GoupLeaveBigIcon from '../../IconSVG/GoupLeaveBigIcon.svg'
import styles from '../Group.module.scss'
import styles2 from './GroupHeader.module.scss'
import DefaultGroup from '../../IconSVG/DefaultGroup.svg'
import { stepsSearchGroup } from '../../OnBoarding/OnBoarding'

let MY_GROUP_COUNT = 0

// const Tour = Loadable({
//   loader: () => import('reactour'),
//   loading: () => null,
// })

export class TeacherSearchGroupInput extends Component {
  state = {
    value: '',
    checkEnter : false,
    isTeacherTourOpen: false,
    searchKey: ''
  }

  setSearchKey = (e) => { 
    let { searchKey } = this.state
    searchKey = e
    this.setState({ searchKey })

    if(e === ''){
      this.props.onSearchGroup(e)
    }
  }
  searchGroup (text1, text2) {
    const {searchKey} = this.state

    if (text1 && text2) {
      return (
        <div className={styles2.searchGroup}>
          <Input
            suffix={<Button type='primary'
              onClick={() => { this.props.onSearchGroup(searchKey) }}>
              <img src={IconSearch} alt='icon-search'/> {text1}
            </Button>}
            placeholder='รหัสกลุ่ม'
            maxLength={100}
            className={styles.inputSearch}
            onPressEnter={e => this.props.onSearchGroup(e.target.value)}
            onChange={(e) => this.setSearchKey(e.target.value)}
          />

          <Button type='primary' 
            onClick={()=>{this.props.onOpenAddGroup();setTimeout(()=>{this.props.nextStepTour()},500)}} 
            data-tut-teacher-group = 'TeacherCreateGroupFirst'
          >
            <img src={IconGroupAdd} alt='icon-group-add' /> {text2}
          </Button>
        </div>
      )
    }
    else {
      return (
        <div className={styles2.searchGroup}>
          <Button type='secondary'
            onClick={() => this.props.showSearchModal()}>
            <img src={IconSearch} alt='icon-search'/>
          </Button>
          {/* {
            this.showOnBoarding()
          } */}
          <Button type='primary' onClick={this.props.onOpenAddGroup}>
            <img src={IconGroupAdd} alt='icon-group-add' />
          </Button>
        </div>
      )
    }
  }

  render() { 
    return (
      <div className={styles2.teacherInputSearchGroup} style={{ display: 'flex' }}>
        <div className={styles2.mobile} >
          {this.searchGroup()}
        </div>

        <div className={styles2.desktop}>
          {this.searchGroup('ค้นหา', 'สร้างกลุ่ม')}
        </div>
      </div>
    )
  }
}

export class StudentSearchGroupInput extends Component {
  state = {
    value: '',
    isTourOpen : false
  }

  setSearchKey = (e) => {
    this.setState({ searchKey: e}) 
    if(e === ''){
      this.props.onSearchGroup(e)
    }
  }

  showOnBoarding = () => {
    return(
      <Suspense fallback={<React.Fragment />}>
        <Tour
          steps = {stepsSearchGroup}
          isOpen = {this.state.isTourOpen}
          onRequestClose={this.onRequestClose} 
          showNavigation={false}
          showButtons = {false}
          showNumber = {false}
        />
      </Suspense>)
  }

  onRequestClose = ()=>{
    this.setState({
      isTourOpen : false
    })
  }

  searchGroup (text) {
    const searchKey = this.state.searchKey 
    if (text) {
      return (
        <Input
          data-tut='step-first'
          suffix = {
            <Button type='primary'
              style={{paddingLeft: 5, paddingRight: 10}}
              onClick={() => this.props.onSearchGroup(searchKey)}>
              <img src={IconSearch} alt='icon-search'/>{text}
            </Button>
          }
          placeholder='กลุ่มของฉัน, กลุ่มอื่น (รหัสกลุ่ม)'
          maxLength={100}
          className={styles.inputSearch}
          onPressEnter={e => this.props.onSearchGroup(e.target.value)}
          onChange={(e) => this.setSearchKey(e.target.value)}
        />
      )
    }
    else {
      return (
        <Button type='secondary'
          style={{paddingLeft: 6, paddingRight: 6}}
          onClick={() => this.props.showSearchModal()}>
          <img src={IconSearch} alt='icon-search'/>
        </Button>
      )
    }
  }

  render() {   
    return (
      <div className={styles2.studentInputSearchGroup} style={{ display: 'flex' }}>
        <div className={styles2.mobile}>
          {this.searchGroup()}
        </div>
        {
          this.showOnBoarding()
        }
        <div className={styles2.desktop}>
          {this.searchGroup('ค้นหา', 'สร้างกลุ่ม')}
        </div>
      </div>
    )
  }
}

export class StudentSearchPage extends Component {
  static defaultProps = {
    otherGroupList: [],
    myGroupList: []
  }
  state = {
    visibleModalDelete : false,
    groupForModal : {},
    resultSearch : []
  }
  columns = [
    {
      title: '', dataIndex: 'logo', key: 'logo',
      align: 'center',
      render: logo => <img src={logo ? logo : DefaultGroup} alt='group_logo' className={styles.logoGroup}/>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'รหัสกลุ่ม',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'ผู้สร้างกลุ่ม',
      dataIndex: 'ownerName',
      key: 'ownerName',
      render: (ownerName) => { 
        return (<span>{`${ownerName.firstName} ${ownerName.lastName}`}</span>)
      },
    },
    {
      title: 'วันที่เข้าร่วม',
      dataIndex: 'joinAt',
      key: 'joinAt',
      align: 'center',
    },
    {
      title: '',
      key: 'action',
      render: (text, group) => {  
        return (
          <div>
            {(() => {
              switch (group.status) {
                case 'none':
                  return (<Button
                    type='primary'
                    onClick={() => this.props.onRequestToJoin(group)}
                  >ขอเข้าร่วมกลุ่ม</Button>)
                case 'left':
                  return (<Button
                    type='primary'
                    onClick={() => this.props.onRequestToJoin(group)}
                  >ขอเข้าร่วมกลุ่ม</Button>)
                case 'request':
                  return (<Button
                    className={styles.btnCancel}
                    onClick={() => {this.props.onCancelRequest(group.id)}}
                  >ยกเลิกคำขอ</Button>)
                case 'join':
                  return (
                    <span style={{ cursor: 'pointer' }} onClick={ e => {
                      e.stopPropagation()
                      this.setState({
                        groupForModal : group,
                        visibleModalDelete : true
                      })
                      this.props.onLeaveGroup(group)
                    }}>
                      <span style={{textDecoration: 'underline'}}>ออกจากกลุ่ม</span>
                    </span>)
                default: return ''
              }
            })()}
          </div>
        )
      },
    },
  ]
  handleCancel = ()=> {
    this.setState({
      visibleModalDelete : false
    })
  }
  deleteStudent = async() =>{
    await this.props.onLeaveGroup(this.state.groupForModal)
    this.setState({
      visibleModalDelete : false
    })
  }
  render() {
    const { searchWord, myGroupList, otherGroupList, myGroupForSearch } = this.props 
    if(MY_GROUP_COUNT == 0){
      this.setState({
        resultSearch : myGroupForSearch
      })
      MY_GROUP_COUNT++
    } 

    const foundCount = myGroupList.length + (otherGroupList ? otherGroupList.length : 0) 
    let myGroup = myGroupList.slice(0) || []
    let otherGroup = otherGroupList.slice(0) || []
    myGroup = myGroupList.find(item => {  
      if((item.code.toLowerCase()  === searchWord.toLowerCase() 
      || item.name.toLowerCase().indexOf(searchWord.toLowerCase() > -1)
      ) && (item.status === 'none' || item.status === 'request' || item.status === 'join')) { 
        return item
      } 
    }) ? [myGroupList.find(item => {  
        if((item.code.toLowerCase()  === searchWord.toLowerCase() 
      || item.name.toLowerCase().indexOf(searchWord.toLowerCase() > -1)
        ) && (item.status === 'none' || item.status === 'request' || item.status === 'join')) { 
          return item
        } 
      })] : []

    if(myGroup.length > 0 ) { 
      myGroup.map(item => {    
        if(item && item.code && otherGroup[0] && otherGroup[0].code && item.code ===  otherGroup[0].code  ) {
          otherGroup = []
          return
        } 
        return
      })
    } 
    return (
      <div className='student-search-page'>
        <div>
          <p>ผลการค้นหา “{searchWord}” {foundCount} รายการ</p>
        </div>
        <div>
          <h2 style={{ borderBottom: '1px solid #e8e8e8', padding: '10px 0px', fontSize: '18px' }}>กลุ่มของฉัน</h2>
          <Table
            columns={this.columns} 
            dataSource={ myGroup }
            pagination={false}
            onRow={(group) => { 
              if (group.status === 'join') {
                return{
                  onClick: () => { return this.props.history.push(`/group/${group.id}`) }
                }
              }
              return
            }}
          />
        </div>
        <br/>
        <br/>
        <div>
          <h2 style={{ borderBottom: '1px solid #e8e8e8', padding: '10px 0px', fontSize: '18px' }}>กลุ่มอื่น</h2>
          <Table
            columns={this.columns} 
            dataSource={otherGroup}
            pagination={false}
          />
        </div>
        <Modal
          visible={this.state.visibleModalDelete}
          onCancel={this.handleCancel}
          footer={false}
          centered
        >
          <div style={{textAlign: 'center'}}>
            <Avatar src={GoupLeaveBigIcon} shape='square' size={150}/>
            <h2 className='text-medium' style={{fontSize: 20}}>ขอออกจากกลุ่ม</h2>
            <p style={{ fontWeight: '500px', fontSize: '28px' }}>{  this.state.groupForModal ? this.state.groupForModal.name : '123' }</p>
            <p style={{ fontWeight: 'normal', fontSize: '16px' , color : '#3D3D3D', opacity : '0.5'}}>{  this.state.groupForModal ? this.state.groupForModal.code : '123' }</p>
            <p style={{color: '#4F4F4F', fontSize: 14}}>ต้องการออกจากกลุ่มนี้หรือไม่</p>
            <div className={styles.modalCreated} >
              <div className={styles.sub_modalCreated} ><Button onClick = {this.handleCancel}>ไม่ต้องการออก</Button></div>
              <div className={styles.sub_modalCreated_2} ><Button type = 'primary' onClick = {() => { this.deleteStudent()} }>ออกจากกลุ่ม</Button></div>
              <div className={styles.sub_modalCreated} ><Button onClick = {this.handleCancel}>ไม่ต้องการออก</Button></div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

TeacherSearchGroupInput.propTypes = {
  onSearchGroup: PropTypes.func.isRequired,
  onOpenAddGroup: PropTypes.func.isRequired
}

StudentSearchGroupInput.propTypes = {
  onSearchGroup: PropTypes.func.isRequired
}

StudentSearchPage.propTypes = {
  onRequestToJoin: PropTypes.func.isRequired,
  onCancelRequest: PropTypes.func.isRequired,
  onLeaveGroup: PropTypes.func.isRequired
}
