import React, { Component }  from 'react' 
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import styles from './AuditTrail.module.scss'

import {  
  Button,
} from 'antd'  
// import ReactExport from 'react-export-excel' 
import * as moment from 'moment'

import {
  getAuditListExcel,
  getProvinceList,
} from '../../redux/audittrail/action'



import ExcelIcon from '../IconSVG/ExcelIcon.svg'

// const ExcelFile = ReactExport.ExcelFile
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn


function provincefromid(province,province_id) {
   let nameSchool = ""
  
// console.log(province.length)
if (province.length > 0)
{
  province.forEach(p => {
// console.log(p)
    if(p._id === province_id){
    // console.log(p)
    // console.log(province_id)
      nameSchool = p.name
    }
  })
}


  return nameSchool
}

function b_to_text(a){
    if (a == true) return 'ให้ใช้ข้อมูล'
    if (a == false) return 'ไม่ให้ใช้ข้อมูล'

    return '';
  }


class Header extends Component {
  constructor(props){
    super(props)

    this.myebutt = React.createRef();
    this.state = {
resexcel2 :[], excel :[]
    }
  }


  componentDidMount= () => {




 this.fetchExcel()

  }


displayExcel = (resexcel2,field_names, provinceList) => {

    const resexcel = resexcel2 //this.state.resexcel2
    // const field_names = field_names2

    // //console.log('click3' + JSON.stringify(resexcel))
    // console.log('click3 excel' + JSON.stringify(provinceList))
    // console.log('click3' + JSON.stringify(resexcel !== undefined))
    // console.log('click3' + JSON.stringify(provinceList !== undefined))
    // console.log('click3' + JSON.stringify(resexcel.items !== undefined))
    // console.log('click3' + JSON.stringify(provinceList.items !== undefined))
    // console.log('click3' + JSON.stringify(field_names))

    let dataExcel = []
     if (field_names !== undefined && resexcel !== undefined && provinceList !== undefined && resexcel.items !== undefined)  //&& provinceList.items != undefined )
     {
    //console.log(JSON.stringify(resexcel) )
    // console.log(JSON.stringify(provinceList) )

      dataExcel = resexcel.items 

      dataExcel.map(data => {
            try {
                data.editDateTime_format = moment.unix(data.editDateTime).format('DD/MM/YYYY HH:mm:ss')
            } catch (error) {
                data.editDateTime_format = ''
            }
          // return data
        })

      const myprovinceList = provinceList

  // console.log("asdfasdf-->" + field_names)  
  // console.log("asdfasdf-->" + JSON.stringify(myprovinceList))  


      dataExcel.map(data => {
          let details = data.details
          let listItems = ""


            // try {
                if (Array.isArray(details))
                {
                      details.map(function(value) {
                      if(value.column == 'school.province')
                      {
                        if (provincefromid(myprovinceList,value.from) != '' && provincefromid(myprovinceList,value.to) != '')
                        {
                          listItems += "แก้ไข " + field_names[value.column] + " จาก '" + provincefromid(myprovinceList,value.from) + "' เป็น '" + provincefromid(myprovinceList,value.to) + "', \n"
                        }
                      }
                      else if(value.column === 'recommendation')
                      {
                        listItems += "แก้ไข " + field_names[value.column] + " จาก '" + (value.from !== undefined && value.from !== null ? b_to_text(value.from) : '') + "' เป็น '" + (value.to !== undefined && value.to !== null ? b_to_text(value.to) : '') + "', \n"
                      }
                      else
                      {
                        listItems += "แก้ไข " + field_names[value.column] + " จาก '" + (value.from !== undefined && value.from !== null ? value.from : '') + "' เป็น '" + (value.to !== undefined && value.to !== null ? value.to : '') + "', \n"
                      }
                    }
                    );
                }            
            // } catch (error) {
              
            // }


          //console.log(formatoutput.toString())
          listItems = listItems.replace(/^(\r\n|\n|\r)+|(\r\n|\n|\r)+$/g,"");
          listItems = listItems.trim()
          listItems = listItems.replace(/^,+|,+$/g, '');
          data.details_format = listItems

          // return data //.toString()

        })
        return dataExcel
        // this.setState({ excel :dataExcel })
    }
    // await 
    return []
   }


  fetchExcel =  () => {



(async () => {
const { field_names }  = await this.props   //

// console.log('click3 excel' + JSON.stringify(provinceList))
// console.log('click3 field_names' + JSON.stringify(field_names))

let provinceList = await this.props.getProvinceList()
let resexcel = await this.props.getAuditListExcel()
// console.log('click3 excel' + JSON.stringify(provinceList))
let dataExcel = await this.displayExcel(resexcel, field_names, provinceList.items)



let datalist = [];
dataExcel.forEach(element => {
datalist.push(
[
  {value: (element.editDateTime === null) ? "" : element.editDateTime},
  {value: (element.editDateTime_format === null) ? "" : element.editDateTime_format},
  {value: (element.ticket === null) ? "" : element.ticket},
  {value: (element.note === null) ? "" : element.note},
  {value: (element.editor_detail_shortName === null) ? "" : element.editor_detail_shortName},
  {value: (element.username_detail_shortName === null) ? "" : element.username_detail_shortName},
  {value: (element.ipaddress === null) ? "" : element.ipaddress},
  {value: (element.details_format === null) ? "" : element.details_format},
]
)
});

const newdataExcel = [
    {
        columns: [
{title: "แก้ไขข้อมูลเมื่อ (unix timestamp)", width: {wpx: 0}},//pixels width 
{title: "แก้ไขข้อมูลเมื่อ", width: {wpx: 150}},
{title: "หมายเลข Ticket", width: {wpx: 150}},
{title: "หมายเหตุ Ticket", width: {wpx: 150}},
{title: "ผู้แก้ไข", width: {wpx: 150}},
{title: "ผู้ใช้", width: {wpx: 150}},
{title: "IP Address", width: {wpx: 150}},
{title: "รายละเอียด", width: {wpx: 150}},
        ],
        data: datalist
    }
];


await this.setState({ excel :newdataExcel });
// await console.log('click4' + JSON.stringify(this.state.excel))
})();


  }



  render() {  
  


function handleClick() {
    this.myebutt.current.click()
  }


    const addButton = (
      <div style={{display: 'flex'}}> 


        <div style={{ paddingRight: '16px'}}>


<ExcelFile
            filename = {`ประวัติการแก้ไข ${moment().format('DD-MM-YYYY')}`} 
            // element={<Button innerref={this.buttonRef}></Button>}//onClick={} 
            element={( 
              <Button type = 'primary'><span><img alt='icon-excel' src={ExcelIcon}/>ดาวน์โหลด Excel</span> </Button>
            )}
          > 
<ExcelSheet dataSet={this.state.excel} name="exportAuditExcel"/>

            {/* <ExcelSheet data={ this.state.excel } name='exportAuditExcel'>
              <ExcelColumn label='แก้ไขข้อมูลเมื่อ' value='editDateTime' width={0} style={{width:{wpx: 0}}}/>
              <ExcelColumn label='แก้ไขข้อมูลเมื่อ (1)' value='editDateTime_format'/>
              <ExcelColumn label='หมายเลข Ticket' value='ticket'/> 
              <ExcelColumn label='หมายเหตุ Ticket' value='note'/>
              <ExcelColumn label='ผู้แก้ไข' value='editor_detail_shortName'/>
              <ExcelColumn label='ผู้ใช้' value='username_detail_shortName'/>
              <ExcelColumn label='IP Address' value='ipaddress'/>
              <ExcelColumn label='รายละเอียด' value='details_format'/>
            </ExcelSheet> */}
          </ExcelFile>   
        

        </div>  

      </div>

    ) 
    return (
      <div>
        <div className='flex-space-between' style={{marginBottom: '30px'}}>
          <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>ประวัติการแก้ไข</h1>
          {addButton}
        </div>
      </div>
    )
  }
}
const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {  
      getAuditListExcel,
      getProvinceList,

    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(Header)   