/* eslint-disable no-undef */
import React, { Component, Fragment }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { cloneArray } from '../../../util/helper'
import {
  message,
  Spin,
  Button,
  Tabs,
  Divider,
  Modal, 
  Select,
  Col,
  Row
} from 'antd' 
import {
  createExamSet,
  getExamSet,
  onCheckQuestionByCode,
  changeCreateStep,
  deleteExamSet
} from '../../../redux/exam/action' 
import { AxiosRequest } from '../../../redux/fetch'
import {
  getSubjectsList
} from '../../../redux/subjects/action'
import {
  getCompetitionOption, getCompetitionYear
} from '../../../redux/competitions/action'
import ExamSuccess from '../../IconSVG/ExamSuccess.svg'
import ExamWarning from '../../IconSVG/ExamWarning.svg'
import StepLesson from './TabPaneCreateExamSet/StepLesson'
import StepCriterion from './TabPaneCreateExamSet/StepCriterion'
import StepCondition from './TabPaneCreateExamSet/StepCondition'
import StepFinal from './TabPaneCreateExamSet/StepFinal'
import styles from '../Exam.module.scss'
import './CreateExamSet.scss'

import StepperCircle from '../../Display/StepperCircle'

import { USER_ROLE } from '../../../util/config'

const { Option } = Select
const { TabPane } = Tabs

const API_COMPETITIONNAME = '/master/competitions'
const API_LESSON = '/exams/lessons'
const API_BOOK = '/exams/lessonsbook'
const API_BOOK_NAME = '/exams/lessonsbookname'
const API_INDICATOR = '/exams/indicators'
const API_STRANDS = '/exams/strands'

const MAX_SELECT_QUANTITY = 50 
const labelCol = {
  sm: 10,
  md: 8,
}
const inputCol = {
  sm: 14,
  md: 16,
}
const genSelectSubject = ( label, options, onChange, value, nameAsValue = false ) => {
  const option = options.map( (p, index) => <Option key={index} id={ p === 'คณิตศาสตร์' ? 'คณิตศาสตร์' : 'วิทยาศาสตร์' } value={nameAsValue ? p : index}>{p}</Option> )
  // let selectType = null
  return (
    <Fragment>
      <Col className='textLabel' {...labelCol}>{label}</Col>
      <Col className='inputField' {...inputCol}>
        <Select         
          placeholder='กรุณาเลือก'
          className='inputSelect'
          value={value}  
          // open = {visibleSelect}   
          // onFocus = {()=>{
          //   setVisibleSelect()
          // }}
          id={'subject'}
          onChange={(value)=>{onChange(value)}} 
        >
          {option}
        </Select>
      </Col>
    </Fragment>
  )
}

const genSelectGrade = ( label, options, onChange, value, nameAsValue = false, a,b,c,onQuantityChange ) => {
  const option = options.map( (p, index) => <Option key={index} value={nameAsValue ? p : index}>{p}</Option> )
   
  return (
    <Fragment>
      <Col className='textLabel' {...labelCol}>{label}</Col>
      <Col className='inputField' {...inputCol}>
        <Select         
          placeholder='กรุณาเลือก'
          className='inputSelect'
          value={value}     
          // open = { visibleSelect }   
          // onFocus = {()=>{
          //   setVisibleSelect()
          // }} 
          id={'grade'}
          onChange={(value)=> { 
            if(value === 'ม.1') {
              onQuantityChange(0,5)
            }
            onChange(value)
          }} 
        >
          {option}
        </Select>
      </Col>
    </Fragment>
  )
}


class ExamSetCreate extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      createStep: '1',
      examType: null,
      competitionList: [],
      visibleSelectSubject: false,
      visibleSelectGrade: false,

      examBank: 'Public',
      // subject: 'วิทยาศาสตร์',
      subject: '',
      book: '',
      grades: ['ป.1', 'ป.2', 'ป.3', 'ป.4', 'ป.5', 'ป.6', 'ม.1', 'ม.2', 'ม.3'],
      grade: 'ม.1',
      level:['ง่าย','ปานกลาง','ยาก'],
      competitionIndex: 0,
      competitionYear: [],

      needFetchDataForCriterion: true,
      dataForCriterion: [],
      criterion: 'none',
      quantityList: [5],
      criterionQuantity: null,

      name: '',
      description: '',
      examSetTotal: 1,
      duration: 0,
      displaySolution: false,
      displayStartTime: false,
      displayStartTime2: '',
      displayEndTime: false,
      displayEndTime2: '',
      displayShuffle: false,
      displayHowTo: false,
      oneTimeDone: false,
      withoutRegistered: false,
      subjectList: [],
      bookList: [],
      visibleModalEdit : false,
      visibleModalCreatedSuccess: false,
      // testItem: [{0 : 0}],
      testItem: [],
      codeExam: [],
      checkErrorCode: null,
      gotoEditTab : false,
      paramsExam: [],
      currentIndex : 4,
      tabBefore : 4,
      visibleModalPreviousTab: false,
      yearsSelected: [],
      checkModalPrevious: false,
      allowEdit : false,
      visibleModalGoBack : false,
      typeOfLesson: 'quickCreate',
      quantityExamAlert: 0,
      visibleModalCreatedSuccessAlert: false,
      examId: undefined,
      isStepFinal: false,
      isModified: false,
      nextStep: 1,
      isBackButton: false,
      openOptionForTour : true, 
    }

  }

  componentDidMount = async () => {
    let { grade, grades } = this.state
    const { match } = this.props
    // const examType = match.path === '/examset/create/curriculum' ? 'curriculum' : '/examset/create/competition' ? 'competition' : '/examset/create/custom' ? 'custom' : 'cat'
    let examType = null
    if(match.path === '/examset/create/curriculumbook'){
      examType = 'curriculumbook' 
    }else if(match.path === '/examset/create/curriculum'){
      examType = 'curriculum' 
    }else if(match.path === '/examset/create/competition'){
      examType = 'competition'
    }else if(match.path === '/examset/create/custom'){
      examType = 'custom'
    } else if(match.path === '/examset/create/exercise'){
      examType = 'E'
    }else{
      examType = 'cat'
      grade = 'ป.4-6'
      grades = ['ป.4-6','ม.1-3']
    }
    // this.setState({examType, criterion: examType === 'curriculum' ? 'lesson' : examType === 'competition' ? 'indicator': 'none'})
    // console.log("111")
    await this.setState({ examType, criterion: 'none',grade, grades })
    // console.log("222")
    await this.fetchSubjectList()
    // console.log("333")
    await this.fetchData()
    // this.fetchBookList()

    // this.forceUpdate()
    // let { grade1, subject } = this.state
    await this.fetchBookList()

  }

  componentDidUpdate = (prevProps,prevState) => { 
    const { isStepFinal,createStep,subject,grade,book,level,competition,examBank ,
      quantity, criterion, quantityList ,
      duration, name, description, examSetTotal, displayShuffle, displayStartTime, displayEndTime, displayStartTime2, displayEndTime2, displayHowTo, displaySolution, oneTimeDone, withoutRegistered
    } = this.state
    
    if((createStep === '1' && isStepFinal &&
    (subject !== prevState.subject ||
     grade !== prevState.grade ||
    book !== prevState.book ||
      level.length !== prevState.level.length ||
      (competition && competition.project !== prevState.competition.project) || 
      (competition && competition.years[0] !== prevState.competition.years[0]) ||
      (competition && competition.years[1] !== prevState.competition.years[1]) ||
      (examBank && examBank !== prevState.examBank) 
    )) || (createStep === '2' && isStepFinal && 
    (quantityList[0] !== prevState.quantityList[0] || 
      criterion !==  prevState.criterion || 
      quantity !== prevState.quantity 
    )) || (createStep === '3' && isStepFinal && 
    ( duration !== prevState.duration ||
      name !== prevState.name || 
      description !== prevState.description ||
      examSetTotal !== prevState.examSetTotal || 
      displayStartTime !== prevState.displayStartTime ||
      displayEndTime !== prevState.displayEndTime ||
      displayStartTime2 !== prevState.displayStartTime2 ||
      displayEndTime2 !== prevState.displayEndTime2 ||
      displayShuffle !== prevState.displayShuffle ||
      displayHowTo !== prevState.displayHowTo ||
       displaySolution !== prevState.displaySolution || 
       oneTimeDone !== prevState.oneTimeDone || 
       withoutRegistered !== prevState.withoutRegistered)
    )){ 
      this.setState({ isModified: true })
    } 
  } 

  upadateCompetitionCondition = async () => {
    let { competitionList, subjectList, competitionIndex, grade, grades, subject, yearsSelected } = this.state
    const subjects  = (await this.props.getSubjectsList()).filter(s => s.isCompetitionActive)
    const project = competitionList[competitionIndex].name
    const competitionOptions = await this.props.getCompetitionOption(project)
    subjectList = subjects.filter(s => competitionOptions['learning_areas'].includes(s.name))
    grades = competitionOptions['key_stages']
    // console.log("==== subjectList ====")
    // console.log(subjectList)
    // console.log(grades)
    // console.log("==== subjectList ====")
    grade = grades.length > 0 ? grades[0] : null
    subject = subjectList.length > 0 ? subjectList[0].name : null
    const competitionYear = (grade && subject) ? await this.props.getCompetitionYear(project, subject, grade) : []
    yearsSelected = (grade && subject) ? [competitionYear[0], competitionYear[competitionYear.length - 1]] : [null, null]
    await this.setState({ subjectList, subject, grades, grade, yearsSelected, competitionYear })
  }

  // upadateCompetitionCondition = async () => {
  //   let { competitionList, subjectList, competitionIndex, grade, grades, subject, yearsSelected } = this.state
  //   const subjects  = (await this.props.getSubjectsList()).filter(s => s.isActive)
  //   const competitionOptions = await this.props.getCompetitionOption('โครงการอัจฉริยภาพ')
  //   console.log(competitionOptions)
  //   if (competitionList[competitionIndex].name === 'โครงการอัจฉริยภาพ') {
  //     subjectList = subjects.filter(s => ['MATH', 'SCI'].includes(s.id))
  //     grades = ['ป.3', 'ป.6']
  //     grade = 'ป.3'
  //     subject = 'คณิตศาสตร์'
  //     yearsSelected = ['2544', '2545']
  //   } else if (competitionList[competitionIndex].name === 'ข้อสอบ TIMSS') {
  //     subjectList = subjects.filter(s => s.id === 'MATH')
  //     grades = ['ป.4', 'ม.2']
  //     grade = 'ป.4'
  //     subject = 'คณิตศาสตร์'
  //     yearsSelected= ['2554', '2554']
  //   } else if (competitionList[competitionIndex].name === 'โครงการ TEDET') {
  //     subjectList = subjects.filter(s => s.id === 'MATH')
  //     grades = ['ป.3', 'ป.6', 'ม.3']
  //     grade = 'ป.3'
  //     subject = 'คณิตศาสตร์'
  //     yearsSelected = ['2553', '2554']
  //   } else {
  //     subjectList = subjects
  //     grades = ['ป.1', 'ป.2', 'ป.3', 'ป.4', 'ป.5', 'ป.6', 'ม.1', 'ม.2', 'ม.3']
  //   }
  //   await this.setState({ subjectList, subject, grades, grade, yearsSelected })
  // }

  setIsStepFinal = () => { this.setState({ isStepFinal: true }) } 
  fetchData = async () => {
    this.setState({ isLoading: true })
    let { competitionList,competitionIndex, yearsSelected } = this.state
    const res = await AxiosRequest(API_COMPETITIONNAME, 'get')
    this.setState({ isLoading: false })
    if (res.error) return message.error('ไม่สามารถรับข้อมูลได้ในขณะนี้')

    const currentRole = this.props.auth.role
    competitionList = res.filter(c => currentRole !== 'admin' ? c.isActive : true )
    yearsSelected[0] = competitionList[competitionIndex].years[0]
    yearsSelected[1] = competitionList[competitionIndex].years[competitionList[competitionIndex].years.length -1 ]
    localStorage.setItem('yearsCompetition',competitionList[competitionIndex].years[0])
    localStorage.setItem('lestYearsCompetition',competitionList[competitionIndex].years[competitionList[competitionIndex].years.length -1 ])
    await this.setState({ competitionList , yearsSelected })
    if (this.state.examType === 'competition') {
      await this.upadateCompetitionCondition()
    }
  }
  fetchSubjectList = async() => {
    const { examType } = this.state
    let { subject } = this.state
    const res = await this.props.getSubjectsList()
    // await console.log("fetchSubjectList",res);
    subject = ''
    if (res) {
      const sIndex = (
        examType == 'curriculum' || examType == 'cat' || examType == 'custom' ? res.findIndex(s => s.isActive) :
        examType == 'competition' ? res.findIndex(s => s.isCompetitionActive) :
        examType == 'curriculumbook' ? res.findIndex(s => s.isExerciseActive) : -1
      )
      if (sIndex != -1) {
        subject = res[sIndex].name
      }
    }
    await this.setState({
      subjectList: res ,subject
      // subject: res[0].name
    })
  }
  fetchBookList = async() => {
    let { subject, grade } = this.state
    
    let axiosReq = AxiosRequest(API_BOOK_NAME, 'get', { subject, grade }) //{ subject, grade, bankType: examBank, level: levelText, examType: 'G' }

    // if (!axiosReq) return this.setState({ dataForCriterion: []}) 
    const res = await axiosReq  
    // await console.log("book",res);

    if (res.error) return message.error('ไม่สามารถรับข้อมูลได้ในขณะนี้')
    // console.log("===========fetchBookList=========")
    // console.log(res)
    // console.log("===========fetchBookList=========")

    let book = ''
    if(res) {
      book = res.map(s => { 
        
          return s.name
        
        
      }).find(s => s !== undefined)
    }
    
    await this.setState({ bookList: res ,book }) //, needFetchDataForCriterion: false })
  }
  fetchDataForCriterion = async (criterion) => { 
    // console.log("===========fetchDataForCriterion=========")
    // console.log(this.state.dataForCriterion)
    // console.log(criterion)
    // console.log("===========fetchDataForCriterion=========")

    const { subject, grade, competitionYear, competitionList, competitionIndex, yearsSelected, examBank, level, book } = this.state
    const isCurriculum = this.state.examType === 'G'
    console.log('isCurriculum[' + isCurriculum.toString() + "]");
    this.setState({ isLoading: true, dataForCriterion: [], quantityList: [] })
    let axiosReq  
    let levelText = level.map(lvl => {
      switch(lvl) {
        case 'ง่าย': return '1'
        case 'ปานกลาง' : return '2'
        case 'ยาก' : return '3'
      }
    }).join(',') 
    if (criterion !== 'none') {
      // if(!criterion){ 
      if (!isCurriculum && (criterion === 'lesson')) {   
        // console.log('this.state.examType: ' + this.state.examType)
        if (this.state.examType === 'curriculumbook') {
          // console.log("API_BOOK:" + API_BOOK)
          axiosReq = AxiosRequest(API_BOOK, 'get', { subject, grade, book, bankType: examBank.toLowerCase() }) 
        } else {
          // console.log("API_LESSON:" + API_LESSON)
          axiosReq = AxiosRequest(API_LESSON, 'get', { subject, grade, bankType: examBank, level: levelText, examType: 'G' })
        }

      } else if (!isCurriculum && (criterion === 'indicator')) {
        // console.log("API_INDICATOR:" + API_INDICATOR)
        axiosReq = AxiosRequest(`${API_INDICATOR}?level=${levelText}&BankType=${examBank}`, 'get', { subject, grade, years: competitionYear })
      } else if (!isCurriculum && (criterion === 'strand')){
        // console.log("API_STRANDS:" + API_STRANDS)
        axiosReq = AxiosRequest(`${API_STRANDS}?subject=${subject}&grade=${grade}&type=C&competition[project]=${competitionList[competitionIndex].name}&competition[year]=${yearsSelected.join(',')}&BankType=${examBank}&level=${levelText}`, 'get')
      }
    }  
    // else { 
    //   this.setState({ quantityList: []})
    // }
    this.setState({ isLoading: false })
    if (!axiosReq) return this.setState({ dataForCriterion: []}) 
    const res = await axiosReq  
    
    if (res.error) return message.error('ไม่สามารถรับข้อมูลได้ในขณะนี้')
    const quantityList = []
    // console.log("res:" + res)
    await this.setState({ dataForCriterion: res, quantityList: quantityList, needFetchDataForCriterion: false })
    
  }

  onChangeInStep = async (data) => {
    // console.log("========onChangeInStep=======")
    // console.log(data)
    // console.log("========onChangeInStep=======")
    await this.setState(data)
    if (Object.keys(data).includes('competitionIndex')) {
      await this.upadateCompetitionCondition()
    }
    const { subject, grade, competitionYear, criterion, examBank, level, book } = data
    if (this.state.examType == 'competition') {
      if (grade) {
        const { competitionIndex, competitionList, subject } = this.state
        const project = competitionList[competitionIndex].name
        const competitionYear = await this.props.getCompetitionYear(project, subject, grade)
        const yearsSelected = [competitionYear[0], competitionYear[competitionYear.length - 1]]
        await this.setState({ yearsSelected, competitionYear })
      }  
      else if (subject) {
        const { competitionIndex, competitionList, grade } = this.state
        const project = competitionList[competitionIndex].name
        const competitionYear = await this.props.getCompetitionYear(project, subject, grade)
        const yearsSelected = [competitionYear[0], competitionYear[competitionYear.length - 1]]
        await this.setState({ yearsSelected, competitionYear })
      }
    }
    if (subject || grade) await this.fetchBookList()

    if (criterion) await this.fetchDataForCriterion(criterion)
    if (subject || grade || competitionYear || examBank || level || book) await this.setState({needFetchDataForCriterion: true})
  }

  getSelectQuantity = () => {
    const { criterion, quantityList } = this.state 
    let selectQuantity = 0 
    if (criterion === 'lesson') {
      selectQuantity = quantityList.reduce( (total, num) => total + num, 0 )
    } else if (criterion === 'indicator') {
      quantityList.forEach( items => selectQuantity = items.reduce( (total, num) => total + num, selectQuantity ) )
    } else if (criterion === 'none') {
      selectQuantity = quantityList[0] || 0 
    }
    return selectQuantity
  }

  onCancel = () => {
    this.props.history.push('/examset')
  }
  handleCancel = ()=> {
    this.setState({
      visibleModalEdit : false,
      visibleModalPreviousTab :false,
      allowEdit : false,
      visibleModalGoBack : false
    })
  }

  onTabs = async(key) => {
    const { examType, criterion, needFetchDataForCriterion, examSetTotal, quantityList,
      testItem, checkErrorCode, checkModalPrevious, isModified } = this.state  
    this.setState({ nextStep: key }) 
    let numKey = parseInt(key)
    if(this.state.createStep > numKey && this.state.createStep + numKey != '43'){
      key = this.state.createStep
      this.setState({
        // visibleModalPreviousTab : true,
        tabBefore : numKey
      })
      if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
      this.checkAllowGoBack()
    }
    if (numKey > 3 && examType !== 'cat') {
      if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
      if ((examSetTotal < 1)) {
        // don't have data ? back to step 3
        message.error('กรุณากรอกข้อมูลให้ครบถ้วน')
        key = '3'
        numKey = parseInt(key)
      } 
    }
    if (numKey > 2 && examType !== 'cat') {
      if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
      // if (this.getSelectQuantity(criterion, quantityList) <= 0 ) 
      if ((this.state.examType !== 'custom' && this.state.examType !== 'E') ? 
        quantityList[0] <= 0 : 
        (!testItem  || testItem.length === 0 || testItem[0] === null || checkErrorCode))
      {
        checkErrorCode ? message.error('กรุณาใส่รหัสข้อสอบให้ถูกต้อง') : message.error('กรุณาใส่จำนวนข้อให้ถูกต้อง')
        key = '2'
        numKey = parseInt(key)
      }
    }
    if (numKey > 1 && examType !== 'cat') {
      if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
      // if (this.state.examType === 'competition' && this.state.competitionYear.length < 1 && this.props.auth.role !== 'student')
      if (this.state.examType === 'competition' && this.state.yearsSelected.length < 1 && this.props.auth.role !== 'student')
      {
        // don't have years on competition ? back to step 1
        message.error('กรุณาเลือกปีที่แข่งขัน')
        key = '1'
        numKey = parseInt(key)
      }
    }
    if ( numKey === 2 && needFetchDataForCriterion && checkModalPrevious ) {
      if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
      this.fetchDataForCriterion(criterion); this.setState({ checkModalPrevious: false }) 
    }
    if ( numKey === 4 ) {
      if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
      this.makeCriterionQuantity() 
    }
    this.setState({
      createStep: key,
    })
  }

  onNext = () => {
    const { createStep, needFetchDataForCriterion, criterion, 
      examSetTotal, quantityList, testItem, checkErrorCode,  
      isModified 
    } = this.state 
    this.setState({ nextStep: null }) 
    switch(createStep) {
      case '1':
        // if (this.state.examType === 'competition' && this.state.competitionYear.length < 1 && this.props.auth.role !== 'student') return message.error('กรุณาเลือกปีที่แข่งขัน')
        if (this.state.examType === 'competition' && this.state.yearsSelected.length < 1 && this.props.auth.role !== 'student') return message.error('กรุณาเลือกปีที่แข่งขัน')
        if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
        if ( needFetchDataForCriterion ) this.fetchDataForCriterion(criterion) 
        break
      case '2':
        // if (this.getSelectQuantity(criterion, quantityList) <= 0  )   
        if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
        if((this.state.examType !== 'custom' && this.state.examType !== 'E') ? quantityList[0] <= 0 :  (!testItem  || testItem.length === 0 || testItem[0] === null || checkErrorCode ) )
        { 
          return checkErrorCode ? message.error('กรุณารหัสข้อสอบให้ถูกต้อง')  :  message.error('กรุณาใส่จำนวนข้อให้ถูกต้อง')
        } 
        break
      case '3':
        if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
        if ((examSetTotal < 1)){ return message.error('กรุณากรอกชื่อข้อสอบ') }
        else{ 
          this.gotoBottom('tourBottom')
        }    
        this.makeCriterionQuantity()
        break
      case '4': 
        if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
        if(this.state.examType === 'cat'){
          this.makeCriterionQuantity()
        }else{
          window.scrollTo(0,document.body.scrollHeight)
        }
        break
    } 
    if(this.state.examType === 'cat'){
      this.setState({ createStep: `${parseInt(createStep) + 3}` })
    } else{
      this.setState({ createStep: `${parseInt(createStep) + 1}` })
    }
  }

  onPrevious = async() => {
    const { createStep, isModified } = this.state
    let currentStep = parseInt(createStep)
    
    if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
    if(currentStep-1 != currentStep) {
      // await this.setState({ visibleModalGoBack: true })
      // if(allowEdit) {
      //   this.setState({ createStep: `${parseInt(createStep) - 1}`})
      // }  
      this.setState({ createStep: `${parseInt(createStep) - 1}`})
    }
    // if(currentStep-1 != currentStep){
    //   this.setState({
    //     visibleModalGoBack : true,
    //   },()=>{
    //     if(allowEdit){
    //       this.setState({ createStep: `${parseInt(createStep) - 1}`})
    //     }
    //   })
    // }
    setTimeout(()=>{this.props.previousTour()},100)
  }
  checkAllowEdit = () => {
    this.setState({
      visibleModalEdit : false,
      visibleModalPreviousTab :false
    },() => {
      this.setState({
        createStep: `${parseInt(this.state.currentIndex)}`
      })
    })
  }
  allowGoBack = () => {
    const { isModified } = this.state
    this.setState({
      visibleModalGoBack :false,
      allowEdit : true,
    }, () => {
      if ( isModified ) return this.setState({ visibleModalPreviousTab: true })
      if(this.state.allowEdit && this.state.createStep !== '1'){ 
        this.setState({
          createStep: `${parseInt(this.state.createStep-1)}`,
          allowEdit : false
        })
      }else if(this.state.allowEdit && this.state.createStep === '1'){
        this.setState({
          createStep: `${parseInt(this.state.createStep)}`,
          allowEdit : false
        })
      }

    })
  }
  checkAllowGoBack = () =>{
    this.setState({
      visibleModalPreviousTab :false,
      checkModalPrevious: true,
      allowEdit : true,
    },() => {
      this.setState({
        createStep: `${parseInt(this.state.tabBefore)}`
      })
    })
  }
  gotoSumation=()=>{
    this.setState({
      checkAllowEdit : true
    },()=>{
      this.setState({
        visibleModalEdit : false
      },()=>{
        this.setState({ createStep: `${parseInt(4)}` })
      })
    })
  }
  onEdit = (index) => {
    if(index == 1){
      this.setState({
        currentIndex : 1,
        visibleModalEdit : true
      })
    }
    if(index == 2){
      this.setState({
        currentIndex : 2,
        visibleModalEdit : true
      })
    }
    if(index == 3){
      this.setState({
        createStep: `${parseInt(index)}`
      })
    }
    if(index == 4){
      this.setState({
        currentIndex : 4,
        visibleModalEdit : true
      })
    }
  }
  gotoBottom = (id) => { 
    window.scrollTo(0, 1000) 
    var element = document.getElementById(id) 
    element.scrollTop = element.scrollHeight - element.clientHeight 
  }
   
  makeCriterionQuantity = () => {
    const {
      criterion, dataForCriterion, quantityList,
    } = this.state
    if (criterion === 'lesson' ) {
      let lessons = dataForCriterion.map((t, index) => {
        return {
          code: t.code,
          name: t.name,
          quantity: quantityList[index] || 0,
        }
      }) .filter((data,i) => {
        if(quantityList[i] !== 0 && quantityList.length > i) {
          return {
            code: data.code,
            name: data.name,
            quantity: data.quantity
          }
        }
      })
      this.setState({criterionQuantity: { lessons }})
    }else if(criterion === 'strand') {
      let strands = dataForCriterion.map((t, index) => {
        return {
          code: t.code,
          name: t.name,
          quantity: quantityList[index] || 0,
        }
      }) .filter((data,i) => {
        if(quantityList[i] !== 0 && quantityList.length > i) {
          return {
            code: data.code,
            name: data.name,
            quantity: data.quantity
          }
        }
      })
      this.setState({criterionQuantity: { strands }})
    } else if (criterion === 'indicator') {
      const indicators = []
      for (let indexH = 0; indexH < dataForCriterion.length; indexH++) {
        indicators.push( ...dataForCriterion[indexH].indicators.map((t, index) => ({
          code: t.code,
          name: t.name,
          quantity: (quantityList[indexH] && quantityList[indexH][index]) || 0,
        })))
      }
      this.setState({criterionQuantity: { indicators }})
    } else {
      this.setState({criterionQuantity: null})
    }
  }

  onSubmit = async () => {
    const { auth } = this.props
    const {
      examType, examBank, subject, grade, level,
      criterion, quantityList, criterionQuantity,
      name, description, examSetTotal, duration, displaySolution, displayStartTime, displayEndTime, displayStartTime2, displayEndTime2, displayShuffle, displayHowTo, oneTimeDone, withoutRegistered,
      competitionIndex, competitionList ,testItem, yearsSelected, book, bookList
    } = this.state 
    if(examType !== 'cat' && examType !== 'custom' && examType !== 'E' && quantityList.length < 1) return message.error('กรุณาระบุจำนวนข้อ')
    let { codeExam, paramsExam } = this.state
    const selectQuantity = this.getSelectQuantity(criterion, quantityList)
    let updatedCriterion = criterion
    let updatedCriterionQuantity = criterionQuantity
    if(criterion !== 'none'){
      criterionQuantity[`${criterion}s`] = criterionQuantity[`${criterion}s`].filter(criterion => criterion.quantity !== 0)
    } else if (examType == 'curriculumbook' && criterion === 'none') {
      // criterionQuantity['Lessons'];
      updatedCriterion = 'lesson'
      const bookObj = bookList.find(elem => elem.name === book)
      const bookId = bookObj.bookid
      updatedCriterionQuantity = []
      updatedCriterionQuantity['lessons'] = [{code: bookId, name: book, quantity: quantityList[0]}]
    }
    let ans = []
    if(examType === 'custom' || examType === 'E') {
      ans = testItem.filter((t) => t !== null)
    } 
    const params = {
      name: name ? name : `${subject} ${grade}`,
      description: description,
      subject: subject,
      grade: grade,
      level: level,
      criterion: updatedCriterion,
      quantity: examType === 'cat' ? 30 : selectQuantity,
      examSetTotal: examSetTotal,
      type: examType === 'curriculumbook' ? 'EX' : examType === 'curriculum' ? 'G' : examType === 'competition' ? 'C' : examType === 'custom' ? 'CUSTOM' : examType === 'E' ? 'E' : 'CAT' ,
      ...updatedCriterionQuantity,
      testItems: ans,
      duration: duration * 60,
      displaySolution: auth.role === 'student' ? true : displaySolution,
      displayStartTime: displayStartTime, 
      displayEndTime: displayEndTime,
      displayStartTime2: displayStartTime2, 
      displayEndTime2: displayEndTime2,
      displayShuffle: displayShuffle,
      displayHowTo:  displayHowTo ,
      oneTimeDone: oneTimeDone,
      withoutRegistered: withoutRegistered,
      bankType: examBank
    }

    // Fix condition by Nut
    if (params.type === 'C') { // For exam type 'competition'
      params.competition = {
        project: competitionList[competitionIndex].name,
        // years: competitionYear,
        years: yearsSelected
      }
    }


    // console.log("=========onSubmit=========")
    // console.log(params)
    // console.log("=========onSubmit=========")


    // TODO : Wait API indicators 
    // return message.info('Test')
    this.setState({  isLoading: true })
    const res = await this.props.createExamSet(params)  
    paramsExam = res
    if (res.error) {
      this.setState({ isLoading: false  })
      return message.error(res.error || 'ไม่สามารถรับข้อมูลได้ในขณะนี้')
    }
    const resExam = await this.props.getExamSet()
    let code = resExam.find(r => {
      if(r._id === res._id) return r
      return []
    })
    codeExam = code
    await this.setState({ paramsExam,codeExam,isLoading: false }) 
    if( params.quantity > res[0].questions.length && examType !== 'cat' && examType !== 'custom' && examType !== 'E' ) { 
      await this.setState({ 
        quantityExamAlert: res[0].questions.length,
        examId: res[0]._id,
        visibleModalCreatedSuccessAlert: true
      })
    }else if(examType === 'cat' && res[0].questions.length < 1){  
      this.props.deleteExamSet(res[0]._id)
      return message.error('ระดับชั้นมีข้อสอบไม่เพียงพอต่อการสร้างชุดข้อสอบ')
    } else {
      this.openModalExamCreated()
    } 
  }
  openModalExamCreated = () => { this.setState({ visibleModalCreatedSuccess : true })}
  closeModalExamCreated = () => { this.setState({ visibleModalCreatedSuccess : false })}

  setExamByCode = (data) => {
    let { testItem } = this.state
    data.map((d,i) => {
      testItem[i] = d.code
    }) 
    this.setState({ testItem })
  }

  checkExamByCode = async ( code,data ) => {
    let { testItem, checkErrorCode  } = this.state 
    
    const { onCheckQuestionByCode } = this.props
    const res = await onCheckQuestionByCode(code)
    if(res.result) {
      checkErrorCode = false
      this.setExamByCode(data)
    } else {
      checkErrorCode = true
    }
    this.setState({ checkErrorCode, testItem })
    return res
  }
    
  setValuesOfYears = (year,index) => {
    let { yearsSelected } = this.state
    yearsSelected[index] = year
    // console.log('set yearsSelected' + yearsSelected)
    this.setState({ yearsSelected })
  }
  selectTypeOfLesson = (value) => {    
    this.setState({ typeOfLesson: value })
    if (value == 'quickCreate') {
      this.setState({ criterion: 'none' })
    }
  }
  onSubjectChange = (subject) => {
    this.onChangeInStep({ subject: subject})
  }
  onGradeChange = (grade) => { 
    this.onChangeInStep({ grade: grade})
  } 
  onQuantityChange = (index, quantity, indexH = undefined) => { 
    const { quantityList } = this.state
    const newQuantityList = cloneArray(quantityList)
    let selectQuantity = 0
    if (indexH === undefined) {
      newQuantityList[index] = quantity
      selectQuantity = newQuantityList.reduce( (total, num) => total + num, 0 )
    } else {
      if (!newQuantityList[indexH]) newQuantityList[indexH] = []
      newQuantityList[indexH][index] = quantity
      newQuantityList.forEach( items => selectQuantity = items.reduce( (total, num) => total + num, selectQuantity ) )
    } 
    if (selectQuantity > MAX_SELECT_QUANTITY) { 
      Modal.error({
        closable: true,
        centered: true,
        okText: 'ตกลง',
        width: 580,
        icon: null,
        content: (
          <div style={{textAlign: 'center'}}> 
            <img src={ExamWarning} style={{ width: '256px' }}/>
            <h2 className='text-medium' style={{fontSize: 20}}>ข้อสอบเกินจำนวน</h2>
            <p style={{marginBottom: 0}}>สามารถเลือกข้อสอบได้ไม่เกิน {MAX_SELECT_QUANTITY} ข้อ</p>
            <p>ต่อ 1 ชุดข้อสอบ</p>
          </div>
        )
      })
      return
    }
    this.onChangeInStep({ quantityList: newQuantityList})
  }
  createLessonList = () => {
    const { dataForCriterion, quantityList } = this.state
    if (!dataForCriterion) return []

    return dataForCriterion.map( (t, index) => {
      const noitems = parseInt(t.noitems)
      return (
        <Row key={t.code} style={{marginBottom: 10}}>
          <Col className='textLabel dark' style={ noitems <= 0 ? { color: '#828282' } : null } {...labelCol}>
            {t.name}
            <br/>
            { noitems > 0
              ? <span className='textHint'>(จำนวน {noitems} ข้อ)</span>
              : null }
          </Col>
          <Col className='inputField right' {...inputCol}>
            { noitems > 0
              ? <StepperCircle value={ quantityList[index] } onChange={ value => this.onQuantityChange(index, value) } max={noitems}/>
              : null }
          </Col>
        </Row>
      )
    })
  }

  setVisibleSelectSubject = () => {
    this.setState({
      visibleSelectSubject : !this.state.visibleSelectSubject
    })
  }
  setVisibleSelectGrade = () => {
    this.setState({
      visibleSelectGrade : !this.state.visibleSelectGrade
    })
  }

  onChangeQuantity = (num) => {
    let { quantityList }  = this.state
    if(num === 0) {
      quantityList = []
    }else {
      quantityList[0] = num
    }
    this.setState({ quantityList })
  }
  render() {
    const { auth } = this.props 
    const {
      examType, subjectList, bookList,
      competitionList, examBank,  grade, grades, level, competitionIndex, competitionYear,
      criterion, dataForCriterion, quantityList,
      name, description, examSetTotal, duration, displaySolution, displayShuffle, displayStartTime, displayEndTime, displayStartTime2, displayEndTime2, displayHowTo, oneTimeDone, withoutRegistered,
      visibleModalCreatedSuccess, paramsExam, testItem, yearsSelected, typeOfLesson, createStep, subject, book, isLoading
    } = this.state         
    const isCurriculum = examType === 'curriculum' || examType === 'curriculumbook'   
    let dataSubject = []
    subjectList.filter(s => {
      if(examType === 'curriculum' && s.isActive){
        dataSubject.push(s.name)
      }
      else if (examType === 'curriculumbook' && s.isExerciseActive) {
        dataSubject.push(s.name)
      }
      else if (examType === 'competition' && s.isCompetitionActive) {
        dataSubject.push(s.name)
      }
      return
    })  
    const inputSubject = genSelectSubject('วิชา', dataSubject, this.onSubjectChange, subject , true, 'วิชา', this.state.visibleSelectSubject, this.setVisibleSelectSubject) 
    
    const inputGrade = genSelectGrade(examType !== 'cat' ? 'ระดับชั้น': 'ช่วงชั้น', grades, this.onGradeChange, grade, true , 'ระดับชั้น', this.state.visibleSelectGrade, this.setVisibleSelectGrade, this.onQuantityChange)
    const customFill = (
      <div>
        {inputSubject}
        {inputGrade}
      </div>
    )
    let selectQuantity = 0    
    if (typeOfLesson === 'quickCreate' && auth.role === 'student') { 
      selectQuantity = quantityList.reduce( (total, num) => total + num, 0 )
    }  
    const textSelectQuantity = (
      <div className='criterion-header'>
        <Col className='textHeader' span={7} sm={10} xs={24} md={8}>
          จำนวนที่เลือก {selectQuantity === 0 ? '' : selectQuantity} ข้อ
          <br/>
          <span className='textHint'>(สูงสุด {MAX_SELECT_QUANTITY} ข้อ)</span>
        </Col>
        <Col className='inputField' span={17} sm={14} xs={24} md={16}>
          {criterion === 'none' ? <StepperCircle value={ selectQuantity } onChange={ value => this.onQuantityChange(0, value) } max={MAX_SELECT_QUANTITY}/> : null}
        </Col>
      </div>
    )    
    return (
      <div className='create-examset' id = 'createExam'
        data-tut-teacher = 'TeacherCreateExamstepThird'
        data-tut-teacher-fourth = 'TeacherCreateExamstepFourth'
        data-tut-teacher-fifth = 'TeacherCreateExamstepFifth'
      >
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <div style={{ display: 'flex' }}>
            <h1 style={{ textAlign: 'left',width: '100%' }}>จัดชุด{examType === 'curriculumbook' ? 'แบบฝึกหัด' : examType === 'curriculum' ? 'ข้อสอบตามหลักสูตรฯ' : examType === 'competition' ? 'ข้อสอบแข่งขัน' : examType === 'E' ? '"แบบฝึกหัด"' :  examType === 'custom' ? '"กำหนดเอง"'  : '"CAT"'}</h1>
            {
              isCurriculum && auth.role === 'student' ? 
                <div style={{ textAlign: 'right',width: '100%' }} >
                  {
                    this.props.auth.isSeenTutorial.examCode === false ? 
                      <Select open = {this.state.openOptionForTour} defaultValue='quickCreate' style={{ width: '150px' }} 
                        onMouseEnter = {() => {
                          this.setState({
                            openOptionForTour : !this.state.openOptionForTour
                          })
                        }}
                        onChange={(e)=>{
                          this.selectTypeOfLesson(e)
                          this.setState({
                            openOptionForTour : !this.state.openOptionForTour
                          })
                        }}
                      >
                        <Option value={'quickCreate'} key={1} >จัดอย่างง่าย</Option>
                        <Option value={'fullOption'} key={2} data-tut = 'stepTourStudentCreateExamSecond'>จัดอย่างละเอียด</Option> 
                      </Select>
                      :
                      <Select defaultValue='quickCreate' id={'type_of_create_exam'} style={{ width: '150px' }} onChange={this.selectTypeOfLesson} >
                        <Option value={'quickCreate'} id={'easy_create_exam'} key={1} >จัดอย่างง่าย</Option>
                        <Option value={'fullOption'} id={'detail_create_exam'} key={2} data-tut = 'stepTourStudentCreateExamSecond'>จัดอย่างละเอียด</Option> 
                      </Select>
                  }
                </div> 
                :''
            }
            
          </div>
          {examType === 'cat' ?
            <Tabs
              defaultActiveKey='1'
              animated={true}
              activeKey={createStep}
              onChange={ this.onTabs }
            >
              <TabPane tab='วิชาและระดับ' key='1'>
                <StepLesson
                  setValuesOfYears = { this.setValuesOfYears }
                  yearsSelected={yearsSelected}
                  subjectList={subjectList}
                  examType={examType}
                  grades={grades}
                  currentRole = {auth.role}
                  isShowExamBank={auth.role===USER_ROLE.SUPER_TEACHER || auth.role===USER_ROLE.ADMIN}
                  isCurriculum={isCurriculum}
                  competitionList={competitionList}
                  examBank={examBank}
                  name={name}
                  subject={subject}
                  grade={grade}
                  level={level}
                  competitionIndex={competitionIndex}
                  competitionYear={competitionYear}
                  onChangeLesson={this.onChangeInStep}
                  onChangeCondition={this.onChangeInStep}
                />
              </TabPane>
              <TabPane tab='สรุป' key='4'>
                <StepFinal
                  examType={examType}
                  data={this.state}
                  onEdit={this.onEdit}
                  auth={this.props.auth}
                  setIsStepFinal={this.setIsStepFinal}
                />
              </TabPane>
            </Tabs>
            :
            (isCurriculum && typeOfLesson !== 'quickCreate') || !isCurriculum || auth.role !== 'student' ? 
              <Tabs
                defaultActiveKey='1'
                animated={true}
                activeKey={createStep}
                onChange={this.onTabs}
                tabBarExtraContent ={<div
                  style = {{width : 600, height : 30, position : 'absolute' ,left : 0}}
                  data-tut-teacher='stepTourTeachertCreateExam'>{}
                </div>}
                // data-tut-teacher='stepTourTeachertCreateExam'
              >
                {/* <div style = {{position : 'absolute', backgroundColor : 'black'}}>123</div> */}
                <TabPane tab={<div>{'วิชาและระดับ'}</div>} key='1' >
                  <StepLesson
                    setValuesOfYears = { this.setValuesOfYears }
                    name={name}
                    grades={grades}
                    yearsSelected={yearsSelected}
                    subjectList={subjectList}
                    bookList={bookList}
                    examType={examType}
                    currentRole = {auth.role}
                    isShowExamBank={auth.role===USER_ROLE.SUPER_TEACHER || auth.role===USER_ROLE.ADMIN}
                    isCurriculum={isCurriculum}
                    competitionList={competitionList}
                    examBank={examBank}
                    subject={subject}
                    book={book}
                    grade={grade}
                    level={level}
                    competitionIndex={competitionIndex}
                    competitionYear={competitionYear}
                    onChangeLesson={this.onChangeInStep}
                    onChangeCondition={this.onChangeInStep}
                  />
                </TabPane>
                <TabPane tab={'จำนวนข้อสอบ'} key='2'>
                  <StepCriterion
                    checkExamByCode={this.checkExamByCode} 
                    setExamByCode={this.setExamByCode}
                    // dragAndDropArrayTestItem={this.dragAndDropArrayTestItem}
                    testItem={testItem}
                    auth={this.props.auth}
                    examType={examType}
                    isCurriculum={isCurriculum}
                    criterion={criterion}
                    dataForCriterion={dataForCriterion}
                    quantityList={quantityList}
                    onChangeCriterion={this.onChangeInStep}
                    onChangeQuantity={this.onChangeQuantity}
                    grade={grade}
                    onGradeChange={this.onGradeChange}
                    getSelectQuantity={this.getSelectQuantity} 
                    book={book}
                  />
                </TabPane>
                <TabPane tab={'เงื่อนไขของชุด'+(examType==='curriculumbook' ? 'แบบฝึกหัด' : 'ข้อสอบ')} key='3'>
                  <StepCondition
                    examType={examType}
                    name={name}
                    subject={subject}
                    grade={grade}
                    auth={this.props.auth}
                    description={description}
                    examSetTotal={examSetTotal}
                    duration={duration}
                    displaySolution={displaySolution}
                    displayStartTime={displayStartTime}
                    displayEndTime={displayEndTime}
                    displayStartTime2={displayStartTime2}
                    displayEndTime2={displayEndTime2}
                    displayShuffle={displayShuffle}
                    displayHowTo={displayHowTo}
                    oneTimeDone={oneTimeDone}
                    withoutRegistered={withoutRegistered}
                    onChangeCondition={this.onChangeInStep}
                    getSelectQuantity={this.getSelectQuantity} 
                  />
                </TabPane>
                <TabPane tab='สรุป' key='4'>
                  <StepFinal
                    data={this.state}
                    onEdit={this.onEdit}
                    auth={this.props.auth}
                    setIsStepFinal={this.setIsStepFinal}
                  />
                </TabPane>
              </Tabs>
              : 
              <Tabs>
                <TabPane tab={<span data-tut = 'stepTourStudentCreateExamFirst'>{'วิชา ระดับชั้น และ จำนวนข้อสอบ'}</span>} key='1'>
                  <Row className={styles.createPanel}>
                    {customFill}
                    {textSelectQuantity}
                  </Row> 
                </TabPane>
              </Tabs>
          }
          { examType === 'cat' && createStep === '4'  ? null :  <Divider/>}
          <div className={`flex-space-between actions ${styles.footer_create_exam}`} >
            <Button type='link'  className={`${styles.btn_cancel} text_underline desktop`} onClick={this.onCancel}>ยกเลิก</Button>
            <div>
              <div className={ styles.btn_group_next_prev } >
                <Button type='secondary' hidden={createStep === '1' || createStep === '4' || (typeOfLesson === 'quickCreate' && auth.role === 'student' && isCurriculum )} className={styles.btn_previous} 
                  onClick={() => { 
                    this.onPrevious() 
                    this.setState({ isBackButton: true })
                  }}>ย้อนกลับ</Button>
                <Button type='primary' hidden={(createStep === '4'  ) 
                || (isCurriculum && typeOfLesson === 'quickCreate' && auth.role === 'student' ) }  
                className={ createStep === '1' || createStep === '4' ? styles.btn_next_alone : styles.btn_next } 
                data-tut-teacher='TeacherCreateExamstepThirdPointOne'
                onClick={()=>{this.onNext()}}>ต่อไป</Button>
              </div>
              
              <Button type='primary' hidden={ 
                (createStep !== '1' && typeOfLesson !== 'quickCreate' && !isCurriculum ) || 
                (createStep !== '4' && typeOfLesson === 'quickCreate' && !isCurriculum ) ||
                (createStep !== '4' && typeOfLesson !== 'quickCreate' && isCurriculum ) ||
                (createStep !== '4' && auth.role !== 'student')
              } className={styles.btn_ok} 
              id = 'tourBottom'
              onClick={()=>{this.onSubmit();this.props.closeTour('exam')}}
              data-tut = 'StudentCreateExamstepFive'
              data-tut-teacher = 'TeacherCreateExamstepSixth'
              >ยืนยันการจัดชุด</Button>
            </div>
            <Button type='link' className={`${styles.btn_cancel} text_underline mobile`} onClick={this.onCancel}>ยกเลิก</Button>
          </div>

          <Modal
            visible = { visibleModalCreatedSuccess }
            centered = {true}
            width = '580px'
            destroyOnClose= { true }
            maskClosable={false}
            closable={false}
            footer={false}
          >
            <div style={{textAlign: 'center'}} data-tut = 'StudentCreateExamstepSix'>
              <img src={ExamSuccess} style={{ width: '256px',marginTop: '-30px', marginBottom: '-10px' }}/>
              <h2 className='text-medium' style={{fontSize: 20}}>จัดทำข้อสอบสำเร็จ</h2>
              {/* <p style={{marginBottom: 0, fontSize: 16}}>{ paramsExam ? paramsExam[0].name : ''}</p>  */}
              <p style={{marginBottom: 0, fontSize: 16}}>{ paramsExam[0] ? paramsExam[0].name : ''}</p>
              <p style={{color: '#3D3D3D', opacity: 0.5, fontSize: 14}}>{paramsExam[0] ? paramsExam[0].code : ''}</p>
              {
                auth.role === 'student' ?
                  <div className={styles.modalCreatedStudent} >
                    <Button key='go' width='150px' type='primary'
                      onClick={e => {e.stopPropagation();this.props.history.push(`/testing/${paramsExam[0]._id}`,{cameFrom : 'createExam'})}}
                    >เริ่มทำข้อสอบ</Button>
                  </div>
                  :
                  <div className={styles.modalCreated} >
                    <div className={styles.sub_modalCreated} >
                      <Button key='back' onClick={e => {
                        e.stopPropagation()
                        return this.props.history.push(`/testing/${paramsExam[0]._id}/example`,{cameFrom : 'createExam',isExample: true})
                      }}>ดูตัวอย่าง</Button>
                    </div>
                    <div className={styles.sub_modalCreated_2} >
                      <Button key='go' type='primary'
                        // onClick={e => {e.stopPropagation();this.props.history.push(`/examset/${paramsExam[0]._id}`,{cameFrom : 'createExam'})}}
                        onClick={e => {e.stopPropagation();this.props.history.push(`/testing/${paramsExam[0]._id}`,{cameFrom : 'createExam'})}}
                      >ทดลองทำ</Button>
                    </div>
                  </div>
              }
              {
                this.props.auth.isSeenTutorial.examCode ?
                  <p onClick={e => { e.stopPropagation();this.props.history.push('/examset',{cameFrom : 'createExam'}) }} style={{ fontSize: '16px', color: '#828282', cursor: 'pointer', textDecorationLine: 'underline' }}>กลับสู่หน้าข้อสอบ</p>
                  :
                  <a href = '/examset' style={{ fontSize: '16px', color: '#828282', cursor: 'pointer', textDecorationLine: 'underline' }}>กลับสู่หน้าข้อสอบ</a>
              }
          
            </div>
          </Modal>
          <Modal
            visible = { this.state.visibleModalCreatedSuccessAlert }
            centered = { true }
            width = '580px'
            destroyOnClose= { true }
            maskClosable={ false }
            closable={ false }
            footer={ false }
          >
            <div style={{textAlign: 'center'}} data-tut = 'StudentCreateExamstepSix'>
              <img src={ExamWarning} style={{ width: '256px',marginTop: '-30px', marginBottom: '-10px' }}/>
              <h2 className='text-medium' style={{fontSize: 20}}>ข้อสอบไม่เพียงพอต่อการจัดชุด</h2> 
              <p style={{marginBottom: 0, fontSize: 16}}>สามารจัดชุดข้อสอบ { paramsExam[0] ? paramsExam[0].name : ''} ได้แค่ {this.state.quantityExamAlert} ข้อ</p>
              <p style={{color: '#3D3D3D', opacity: 0.5, fontSize: 14}}>{paramsExam[0] ? paramsExam[0].code : ''}</p>
              <div className={styles.modalCreated} >
                <div className={styles.sub_modalCreated} >
                  <Button key='back' onClick={e => {
                    e.stopPropagation()
                    this.props.deleteExamSet(this.state.examId)
                    this.props.history.push('/examset',{cameFrom : 'createExam'})
                    this.setState({ visibleModalCreatedSuccessAlert: false }) 
                  }}>ยกเลิก</Button>
                </div>
                <div className={styles.sub_modalCreated_2} >
                  <Button key='go' type='primary' 
                    onClick={() => {
                      this.setState({ visibleModalCreatedSuccessAlert: false })
                      this.openModalExamCreated()
                    }}
                  >ตกลง</Button>
                </div>
              </div> 
            </div>
          </Modal>
          <Modal
            visible={this.state.visibleModalEdit}
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={false}
          >
            <div style={{textAlign: 'center'}}>
              <img src={ExamWarning} style={{ width: '256px',marginTop: '-30px', marginBottom: '-10px' }}/>
              <h2 className='text-medium' style={{fontSize: 20}}>การแก้ไขข้อมูล</h2>
              {/* <p style={{marginBottom: 0, fontSize: 16}}>{name}</p>  */}
              {/* <p style={{color: '#4F4F4F', fontSize: 14}}>ถ้ามีการแก้ไขข้อมูลหน้านี้ เงื่อนไขที่เลือกจะถูกลบ</p> */}
              <p style={{color: '#4F4F4F', fontSize: 14}}>คุณต้องการแก้ไขข้อมูลใช่หรือไม่</p>
              {/* <p onClick={e => { e.stopPropagation();this.props.history.push('/examset') }} style={{ fontSize: '16px', color: '#828282', cursor: 'pointer', textDecorationLine: 'underline' }}>กลับสู่หน้าข้อสอบ</p> */}
              <div className={styles.modalCreated} >
                <div className={styles.sub_modalCreated} >
                  <Button onClick = {this.gotoSumation} >กลับหน้าสรุป</Button>
                </div>
                <div className={styles.sub_modalCreated_2} >
                  <Button type = 'primary' onClick = {this.checkAllowEdit} >แก้ไขข้อมูล</Button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            visible={this.state.visibleModalPreviousTab}
            onCancel={this.handleCancel}
            footer={false}
          >
            <div style={{textAlign: 'center'}}>
              <img src={ExamWarning} style={{ width: '256px',marginTop: '-30px', marginBottom: '-10px' }}/>
              <h2 className='text-medium' style={{fontSize: 20}}>การแก้ไขข้อมูล</h2>
              {/* <p style={{marginBottom: 0, fontSize: 16}}>{name}</p>  */}
              {/* <p style={{color: '#4F4F4F', fontSize: 14}}>ถ้ามีการแก้ไขข้อมูลหน้านี้ เงื่อนไขที่เลือกจะถูกลบ</p> */}
              <p style={{color: '#4F4F4F', fontSize: 14}}>คุณต้องการแก้ไขข้อมูลใช่หรือไม่</p>
              {/* <p onClick={e => { e.stopPropagation();this.props.history.push('/examset') }} style={{ fontSize: '16px', color: '#828282', cursor: 'pointer', textDecorationLine: 'underline' }}>กลับสู่หน้าข้อสอบ</p> */}
              <div className={styles.modalCreated} >
                <div className={styles.sub_modalCreated} ><Button onClick = {e => {
                  e.stopPropagation()
                  this.handleCancel()
                }}>ยกเลิก</Button></div>
                <div className={styles.sub_modalCreated_2} ><Button type = 'primary' onClick = {() => {
                  const { nextStep } = this.state 
                  this.setState({ createStep: this.state.isBackButton ? `${parseInt(createStep) - 1}` : nextStep ?  `${parseInt(nextStep)}` : `${parseInt(createStep) + 1}` ,
                    visibleModalPreviousTab: false,
                    isModified: false,
                    isBackButton: false })
                }}>แก้ไขข้อมูล</Button></div>
              </div>
            </div>
          </Modal>
          <Modal
            visible={this.state.visibleModalGoBack}
            onCancel={this.handleCancel}
            footer={false}
            centered
          >
            <div style={{textAlign: 'center'}}>
              <img src={ExamWarning} style={{ width: '256px',marginTop: '-30px', marginBottom: '-10px' }}/>
              <h2 className='text-medium' style={{fontSize: 20}}>การแก้ไขข้อมูล</h2> 
              <p style={{color: '#4F4F4F', fontSize: 14}}>คุณต้องการแก้ไขข้อมูลใช่หรือไม่</p> 
              <div className={styles.modalCreated} >
                <div className={styles.sub_modalCreated} ><Button onClick = {e => {
                  e.stopPropagation()
                  this.handleCancel()
                }}>ยกเลิก</Button></div>
                <div className={styles.sub_modalCreated_2} ><Button type = 'primary' onClick = {this.allowGoBack}>แก้ไขข้อมูล</Button></div>
              </div>
            </div>
          </Modal>
        </Spin>
     
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.profile,
    createStep: state.exam.createStep
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createExamSet,
      getSubjectsList,
      getExamSet,
      onCheckQuestionByCode,
      changeCreateStep,
      deleteExamSet,
      getCompetitionOption,
      getCompetitionYear
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ExamSetCreate)
