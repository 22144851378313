import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, } from 'react-router-dom';
import { StaticRouter } from 'react-router'
import AppRouter from './router'
// import ReactGA from 'react-ga'
// import ReactGA4 from 'react-ga4'
// import ReactPixel from 'react-facebook-pixel'

// if (process.env.REACT_APP_ENV === 'production') {
//   ReactGA.initialize('UA-50962149-2')
//   ReactGA.pageview(window.location.pathname + window.location.search)
  
//   ReactGA4.initialize('G-YGJB03L31Q')
//   //ReactGA4.initialize('G-EEFK9224XQ')
//   ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
//   //ReactGA4.send('pageview')
//   ReactGA4.event('screen_view', { 'app_name': 'otds', 'screen_name': window.location.pathname })

//   ReactPixel.init('1943749415868241', {}, {
//     autoConfig: true, // set pixel's autoConfig
//     debug: true, // enable logs
//   })
//   ReactPixel.pageView()
// }

function App (props) {
  return (
    <Provider store={props.store}>
      {
        props.location
          ? (
            <StaticRouter location={props.location} context={{}}>
              <AppRouter store={props.store} />
            </StaticRouter>
          ) : (
            <BrowserRouter>
              <AppRouter />
            </BrowserRouter>  
          )
      }
    </Provider>
  )
}

export default App
