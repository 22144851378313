import React, { Component, Fragment }  from 'react'
import { 
  Layout,
  Button,
  Icon
} from 'antd'
import {
  markQuestion
} from '../../redux/testing/action'
import ListTestingIcon from '../IconSVG/ListTestingIcon.svg'
import BookmarkIcon from '../IconSVG/BookmarkIcon.svg'
import UnBookmarkIcon from '../IconSVG/UnBookmarkIcon.svg'
import SentTestingIcon from '../IconSVG/SentTestingIcon.svg'
import { connect } from 'react-redux'
import styles from './Testing.module.scss'
import { bindActionCreators } from 'redux'

class HeaderTesting extends Component {
  constructor(props){
    super(props)
    this.state = {
      isMark: null
    }
  }

  markQ (text) {
    const { seqQuestion, onClickBookMark, progressTestings } = this.props
    const progressTesting = progressTestings.find(pt => pt.order === seqQuestion)
    let isMark = progressTesting ? progressTesting.isMark : false

    return (
      <div onClick={() => onClickBookMark(seqQuestion, isMark)} style={{ display: 'inline'}} >
        <img className='icon-mark' src={isMark? BookmarkIcon : UnBookmarkIcon } />
        <span>{text ? text : ''}</span>
      </div>
    )
  }

  isAbleToFinishCatExam () {
    const { exam, seqQuestion } = this.props
    return exam.type === 'CAT' && seqQuestion == 30
  }

  isAbleToClickNextQuestionCatExam () {
    const { seqQuestion, progressTestings } = this.props
    return progressTestings.length === seqQuestion
  }

  render() {
    const { seqQuestion, questionCount, onShowDrawer, onPreviousQuestion, onNextQuestion, onFinishTesting, isSolution, exam } = this.props
  
    return (
      <Layout.Footer className={styles.footer_testing}>
        <div className='footer-testing-detail'>
          <div className='left'>
            {
              exam.type !== 'CAT' && (
                <div onClick={onShowDrawer} style={{ display: 'inline', cursor: 'pointer'}} data-tut = 'step-testing-first'>
                  <img src={ListTestingIcon} /><span>{seqQuestion}/{questionCount}</span>
                </div>
              )
            }
            {
              (!isSolution && exam.type !== 'CAT') && (
                <Fragment>
                  <div className='mobile'>
                    {this.markQ()}
                  </div>
                  <div className='desktop' data-tut = 'step-testing-second' style={{ cursor: 'pointer' }}>
                    {this.markQ('มาร์คข้อสอบ')}
                  </div>
                </Fragment>
              )
            }
          </div>
          <div className='right'>
            {
              <div className={styles.footer_button}>
                {((seqQuestion === questionCount && exam.type !== 'CAT' && !isSolution) || this.isAbleToFinishCatExam()) && (
                  <Button onClick={ () => exam.type !== 'CAT' || (exam.type === 'CAT' && this.isAbleToClickNextQuestionCatExam()) ? onFinishTesting() : {} }
                    className={styles.footer_button}
                    type='primary' shape='round'
                  >
                    <img src={SentTestingIcon}/>ส่งข้อสอบ
                  </Button>
                )}
                {
                  ((exam.type !== 'CAT' && seqQuestion < questionCount) || (exam.type === 'CAT' && seqQuestion !== 30)) && (
                    <Button onClick={ () => 
                      exam.type !== 'CAT' ||
                      (exam.type === 'CAT' && 
                      this.isAbleToClickNextQuestionCatExam()) 
                        ? onNextQuestion() 
                        : 
                        {} 
                    }
                    className={styles.footer_button}
                    type='primary'
                    shape='circle'
                    >
                      <Icon type='right' data-tut = 'step-testing-fifth'/>
                    </Button>
                  )
                }
                {
                  (seqQuestion > 1 && exam.type !== 'CAT') && (
                    <Button style={{ marginRight: '15px'}}
                      onClick={()=>{
                        onPreviousQuestion() 
                      }}
                      className={styles.footer_button}
                      type='primary'
                      shape='circle'
                    >
                      <Icon type='left' />
                    </Button>
                  )
                }
              </div>
            }
          </div>
        </div>
      </Layout.Footer>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      markQuestion
    },
    dispatch
  )


export default connect(mapDispatchToProps)(HeaderTesting)
