import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Tabs,
  Switch,
  Table,
  Modal,
  Button,
  Avatar,
  Typography
} from 'antd'
import Axios from 'axios'
import Download from 'downloadjs'
import {
  updateUser
} from '../../redux/user/action'
import TopTitileComponent from '../Layout/TopTitleComponent'
import AddDataIcon from '../../assets/Icon/AddData.svg'
import AddUserIcon from '../../assets/Icon/AddUser.svg'
import UploadExcel from '../../assets/Icon/UploadExcel.svg'
import Delete from '../IconSVG/Delete.svg'
import DeleteGray from '../IconSVG/DeleteGray.svg'
import Edit from '../IconSVG/Edit.svg'
import EditGray from '../IconSVG/EditGray.svg'
import IconDownload from '../IconSVG/IconDownload.svg'
import WarningDeleteUserIcon from '../IconSVG/WarningDeleteUserIcon.svg'
import DefaultProfile from '../IconSVG/DefaultProfile.svg'
import styles from './User.module.scss'
const { TabPane } = Tabs
const { Paragraph } = Typography

class ListUsers extends Component {
  columns = [
    { 
      dataIndex: 'profileImage',
      align: 'center',
      render: profileImage => <div className={styles.title_list_all} ><img src={profileImage ? profileImage : DefaultProfile} alt='profileImage' className={styles.imageProfile} /></div>,
      width: '55px'
    },
    {
      title: 'ชื่อ',
      // dataIndex: 'name',
      render: user => <div className={styles.title_list_all}>
        <Paragraph ellipsis className={styles.clickable} style={{color: !user.isSeenModified ? 'red' : ''}}
          onClick={() => {this.toUserDetail(user)}} >
          {user.name}</Paragraph>
      </div>,
      width: '160px'
    },
    {
      title: 'โรงเรียน',
      // dataIndex: 'schoolName',
      render: 
      (user)=> 
        <div className={styles.clickable}>
          <Paragraph ellipsis style={{ color: !user.isSeenModified ? 'red' : ''}}
            onClick={() => {this.toUserDetail(user)}} >
            {user.schoolName}
          </Paragraph>
        </div>
      ,
      width: '160px'
    },
    {
      title: 'วันที่เป็นสมาชิก',
      // dataIndex: 'createdAt',
      render: user => <span className={styles.clickable} style={{color: !user.isSeenModified ? 'red' : ''}}
        onClick={() => {this.toUserDetail(user)}} >
        {user.createdAt}</span>,
      width:'150px'
    },
    {
      title: 'ใช้งานล่าสุด',
      dataIndex: 'lastLoggedInAt',
      render: (lastLoggedInAt,record) => {  
        return (
          <span className={styles.clickable} style={{color: !record.isSeenModified ? 'red' : ''}}
            onClick={() => {this.toUserDetail(record)}} >
            {lastLoggedInAt}</span>
        )
      },
      width:'150px'
    },
    {
      title: 'อีเมล',
      // dataIndex: 'email',
      render: user => <Paragraph ellipsis className={styles.clickable} style={{color: !user.isSeenModified ? 'red' : ''}}
        onClick={() => {this.toUserDetail(user)}} >
        {user.email}</Paragraph>,
      width:'170px'
    },
    {
      title: 'ชื่อผู้ใช้งาน',
      dataIndex: 'username',
      render: (username) => { 
        return (<span>{username ? username : ''}</span>)
      },
      width:'150px'
    },
    {
      title: 'นโยบาย',
      dataIndex: 'policy',
      render: policy => { 
        return (<span>{policy ? 'ยอมรับ' : 'ไม่ยอมรับ'}</span>)
      },
      width:'100px'
    },
    {
      title: 'การใช้งาน',
      render: user => (
        <span className={styles.tableAction}>
          <Switch checkedChildren='เปิด' unCheckedChildren='ปิด' defaultChecked={!user.isBanned} className={styles.switch} onChange={(isBan) => { this.props.onBanUser(user, isBan) }} />
        </span>
      ),
      width:'100px'
    },
    {
      title: 'การใช้งาน Offline',
      render: user => (
        <span className={styles.tableAction}>
          {
            user.role !== 'admin' ? 
              <Switch checkedChildren='เปิด' unCheckedChildren='ปิด' defaultChecked={user.isOfflineLoginable} className={styles.switch} onChange={(isOfflineLoginable) => { this.props.onToggleOfflineLoginable(user, isOfflineLoginable) }} /> : '-'
          }
        </span>
      ),
      width:'100px'
    },
    {
      title: 'ยืนยันตัวตน',
      render: user => { 
        if (user.isConfirmationEmail) {
          return 'เปิด'
        } else {
          let isConfirmationEmail = { isConfirmationEmail: true }
          return (
            <span className={styles.tableAction}>
              <Switch unCheckedChildren='ปิด' defaultChecked={user.isConfirmationEmail} className={styles.switch} onChange={ async() => { await this.props.updateUser(user.id,isConfirmationEmail); await this.props.fetchUsers()}}/>
            </span>
          )
        }
      },
      align: 'center',
      width:'120px',
    },{ 
      dataIndex: 'edit',
      width:'100px',
    },{ 
      dataIndex: 'delete', 
    }
  ]
  
  constructor (props) {
    super(props)
    this.state = {
      userData: '',
      deletePopupAlert: false,
      userList: '',
      filterTeacherList : {},
      filterStudentList : {},
      filterSuperTeacherList : {},
      filterAdminList : {},
      currentPage : '',
      checkEnter : false,
      checkMouseHoverEdit: false,
      checkMouseHoverDelete: false,
      indexRow: null
    }
  }
  onSearchUser = async (searchKeyword) => {
    await this.props.fetchUsers({ searchKeyword }) 
    this.setState({
      checkEnter : true
    })
  } 
  setSerchKey = async ( searchKeyword )=>{
    if(searchKeyword === '' && this.state.checkEnter === true){
      await this.props.fetchUsers({ searchKeyword })
      this.setState({
        checkEnter : false
      })
    }
  }
  openDeleteWindow = (user) => {
    this.setState({ 
      userData: user,
      deletePopupAlert: true
    })
  }
  closeDeleteWindow = () => {
    this.setState({deletePopupAlert: false})
  }

  toUserDetail = (user) => {
    this.props.openUserDetail(user)
  }

  onDownloadTemplate = () => {
    const fileName = 'user-importing-template.xlsx' 
    let url = `${process.env.REACT_APP_API_URL}/storage/template/${fileName}`
    Axios.get(url, { responseType: 'arraybuffer' })
      .then((response) => {
        // eslint-disable-next-line no-undef
        let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })  
        Download(blob, 'รายชื่อในระบบ DownloadTemplate.xlsx')
      })  
  }

  render() {
    const { teacherList, studentList, superTeacherList, adminList, onClickAddUser, openUploadExcelWindow, onPaginate } = this.props
    let { filterTeacherList, filterStudentList, filterSuperTeacherList, filterAdminList,userData,checkMouseHoverEdit,checkMouseHoverDelete,indexRow } = this.state
     
    let defaultImageDeleteIcon = userData.profileImage ? userData.profileImage.toString().slice(45) : DefaultProfile
    filterTeacherList = teacherList
    filterStudentList = studentList
    filterSuperTeacherList = superTeacherList
    filterAdminList = adminList 

    const userListTable = (userList, role) => {
      // checkMouseHoverEdit,checkMouseHoverDelete,indexRow
      let { items, totalCount } = userList
      const mouseHover = (type) => { 
        if(type === 'edit'){
          this.setState({ checkMouseHoverEdit: true })
        }else{
          this.setState({ checkMouseHoverDelete: true })
        }
      }
      const mouseLeave = (type) => {
        if(type === 'edit'){
          this.setState({ checkMouseHoverEdit: false })
        }else{
          this.setState({ checkMouseHoverDelete: false })
        }
      }
      items.forEach((list,index) => {
        list.edit = (<img 
          title='แก้ไขข้อมูล'
          src={ checkMouseHoverEdit && index === indexRow ? Edit : EditGray } 
          onMouseOver={(e) => { e.stopPropagation();mouseHover('edit') }}
          onMouseLeave={(e) => { e.stopPropagation();mouseLeave('edit') }}
          alt='edit' onClick={() => {this.props.onEditUser(list)}} className={styles.clickable}></img>)
        list.delete = (<img 
          title='ลบรายชื่อ'
          src={checkMouseHoverDelete && index === indexRow ? Delete : DeleteGray } 
          onMouseOver={(e) => {e.stopPropagation();mouseHover('delete')}}
          onMouseLeave={(e) => { e.stopPropagation();mouseLeave('delete')}}
          alt='delete' onClick={() => this.openDeleteWindow(list)} className={styles.clickable}></img>)
      }) 
      return (<Table 
        columns={this.columns}
        dataSource={items}
        pagination={{ simple: true, current: this.props.filtersUser.page[role], total: totalCount, position: 'both' }}
        scroll={{x:true}}
        onChange = {(pagination) => { onPaginate(role, pagination) }}
        onRow = {(record, rowIndex) => {
          return {
            onMouseEnter: () => {
              this.setState({ indexRow: rowIndex })
            }
          }
        }}
      />)
    } 
    return (
      <div>
        <TopTitileComponent 
          onSearchUser = {this.onSearchUser}
          setSerchKey = {this.setSerchKey}
          title={'รายชื่อในระบบ'}
          fetchUsersAll={this.props.fetchUsersAll}
          // csvData={}
          buttons={[
            {
              text: 'เพิ่มข้อมูล',
              icon: AddDataIcon,
              subMenu: [
                {
                  text: 'เพิ่มทีละรายการ',
                  icon: AddUserIcon,
                  onClick: onClickAddUser
                },
                {
                  text: 'อัปโหลด Excel',
                  icon: UploadExcel,
                  onClick: openUploadExcelWindow
                },
                {
                  text: 'Download Template',
                  icon: IconDownload,
                  onClick: () => this.onDownloadTemplate()
                }
              ]
            }, {
              text: 'ดาวน์โหลด Excel',
              key: 'exportExcelButton',
              icon: UploadExcel
            }
          ]} 
        /> 
        <Modal
          visible={this.state.deletePopupAlert}
          className={styles.modal_upload_file}
          onCancel={this.closeDeleteWindow}
          footer={[
            <Button className={styles.upload_file_button} key='back' onClick={this.closeDeleteWindow}>ยกเลิก</Button>,
            <Button className={styles.upload_file_button} key='submit' type='primary' 
              onClick={() => { 
                this.props.onDeleteUser(userData.id)
                this.closeDeleteWindow()
              }}
            >ลบจากระบบ</Button>
          ]}
          bodyStyle={{ textAlign:'center'}}
        >
          <div className={styles.upload_window_content}>
            <div className={styles.icon_delete_profile}> 
              <Avatar size={150} src={defaultImageDeleteIcon ? userData.profileImage ? userData.profileImage : WarningDeleteUserIcon : WarningDeleteUserIcon } shape={ defaultImageDeleteIcon ? userData.profileImage ? 'circle' : 'square' : 'circle'}/>
            </div>
            <div className={styles.upload_window_title}>ลบรายชื่อออกจากระบบ</div>
            <p style={{ color:'red'}}>{userData.name}</p>
            <p>ต้องการลบรายชื่อออกจากระบบหรือไม่ </p>
          </div>
        </Modal>
        <Tabs defaultActiveKey='1'>
          <TabPane tab={`ครู (${teacherList.totalCount})`} key='1'>
            { userListTable( filterTeacherList  , 'teacher') }
          </TabPane>
          <TabPane tab={`นักเรียน (${studentList.totalCount})`} key='2'>
            { userListTable(filterStudentList, 'student') }
          </TabPane>
          <TabPane tab={`ครูพิเศษ (${superTeacherList.totalCount})`} key='3'>
            { userListTable(filterSuperTeacherList, 'superTeacher') }
          </TabPane>
          <TabPane tab={`ผู้ดูแลระบบ (${adminList.totalCount})`} key='4'>
            { userListTable(filterAdminList, 'admin') }
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return { 
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { 
      updateUser
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers)