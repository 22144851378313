import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import {
  Spin,
  Modal,
} from 'antd'
import {
  ConfirmEmail,
  GetProfile,
} from '../../redux/auth/action'
import queryString from 'query-string'

class VerifyEmail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  componentDidMount = async () => {
    const params = queryString.parse(this.props.location.search)
    const erroModal = () => Modal.error({
      center: true,
      icon: null,
      onOk: () => {
        this.props.history.push('/login')
      },
      content: <div style={{textAlign: 'center'}}>ไม่สามารถยืนยันอีเมล</div>,
    })
    const ret = await this.props.ConfirmEmail(params)
    if (ret.error) return erroModal()
    this.props.history.push('/login')
  }

  render() {
    const isAuthenticated = this.props.isAuthenticated
    return ( !isAuthenticated ? 
      <div style={{ textAlign: 'center', width: '100%'}}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} ></Spin>
      </div>:
      <Redirect to='/login' />
    )
  }
}

const mapStateToProps = state => ({
  // Redirect after login
  isAuthenticated: state.user.token ? true : false,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ConfirmEmail,
      GetProfile,
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmail))