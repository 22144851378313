import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom' 
import TestingOverview from './TestingOverview'
import TestingDescription from './TestingDescription'

class ReportFinished extends Component {
  state = {
    isLoading: false,
  }

  static defaultProps = {
    collection: []
  }

  componentDidMount () {
    this.loadStats()
  }
  spinOnLoading = () => { this.setState({ isLoading: true }) }
  spinOffLoading = () => { this.setState({ isLoading: false }) }
  loadStats = async() => {
    const { year } = this.props
    this.spinOnLoading()
    await this.props.loadStats('testing', year)
    this.spinOffLoading()
  }

  loadDetail = async(year, month, type) => {
    this.spinOnLoading() 
    let monthString = month < 10 ? `0${month}` : month
    const res = await this.props.loadStatsDetail('testing', year, monthString, type )
    this.spinOffLoading()
    return res
  }

  loadType = async(year, month) => {
    this.spinOnLoading()
    let monthString = month < 10 ? `0${month}` : month
    await this.props.loadStatsType('testing', year, monthString)
    this.spinOffLoading()
  }

  getStatTestingTransaction = async(year, month) => {
    this.spinOnLoading()
    let monthString = month < 10 ? `0${month}` : month
    const res = await this.props.getStatTestingTransaction(year, monthString )
    this.spinOffLoading()
    return res
  }
  render () { 
    return (
      <div>
        <Switch>
          <Route exact
            path='/reporttesting'
            render={() => <TestingOverview
              spinOnLoading={this.spinOnLoading}
              spinOffLoading={this.spinOffLoading}
              loadDetail={this.loadDetail}
              loadType={this.loadType}
              {...this.props} />
            }
          />

          <Route exact
            path='/reporttesting/description'
            render={() => <TestingDescription
              spinOnLoading={this.spinOnLoading}
              spinOffLoading={this.spinOffLoading}
              loadDetail={this.loadDetail}
              loadType={this.loadType}
              getTransaction={this.getStatTestingTransaction}
              {...this.props} />
            }
          />
        </Switch>
      </div>
    )
  }
}

export default ReportFinished
