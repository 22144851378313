import {
  SET_EXAMSET_LIST,
  UPDATE_EXAMSET_IN_LIST,
  SET_EXAMSET_DETAIL,
} from './action'

const defaultState = {
  examSetList: [],
  examDetail: [],
  createStep: '1'
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_EXAMSET_LIST:
      return {
        ...state,
        examSetList: action.examSetList,
      }
    case UPDATE_EXAMSET_IN_LIST:
    {
      const newExamSetList = state.examSetList.slice(0) // duplicate array
      const index = newExamSetList.findIndex(examset => examset.id === action.id)
      newExamSetList[index] = {
        ...newExamSetList[index],
        ...action.data,
      }
      return {
        ...state,
        examSetList: newExamSetList,
      }
    }
    case SET_EXAMSET_DETAIL:
      return{
        ...state,
        examDetail: action.examDetail,
      }
    case 'CHANGE_STEP_CREATE_EXAM': 
      return {
        ...state,
        createStep: action.step
      }
    default: return state
  }
}