import { FetchCycle, AxiosRequest } from '../fetch'

// API =====================================================================

const API_DEPARTMENT        = '/master/departments' 

// Fetch ===================================================================

const departmentsList        = ()                  => () => AxiosRequest(API_DEPARTMENT,'get')
const createDepartment       = (data)              => () => AxiosRequest(API_DEPARTMENT,'post',data)
const importDepartment       = (departmentFile)    => () => AxiosRequest(API_DEPARTMENT+'import','port',departmentFile)
const updateDepartment       = (departmentId,data) => () => AxiosRequest(`${API_DEPARTMENT}/${departmentId}`,'patch',data)
const removeDepartment       = (departmentId)      => () => AxiosRequest(`${API_DEPARTMENT}/${departmentId}`,'delete')

// Action =====================================================================

export const getDepartmentsList        = ()                  => FetchCycle(departmentsList())
export const onCreateDepartment        = (data)              => FetchCycle(createDepartment(data))
export const uploadDepartmentFile      = (departmentFile)    => FetchCycle(importDepartment(departmentFile))
export const onUpdateDepartment        = (departmentId,data) => FetchCycle(updateDepartment(departmentId,data))
export const onDeleteDepartment        = (departmentId)      => FetchCycle(removeDepartment(departmentId))