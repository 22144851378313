/* eslint-disable no-undef */
import React , { Component } from 'react'
import { Table } from 'antd'
 
import LineVerticalYellowColor from '../../IconSVG/LineVerticalYellowColor.svg' 
import LineVerticalPurpleColor from '../../IconSVG/LineVerticalPurpleColor.svg' 
import LineVerticalPinkColor from '../../IconSVG/LineVerticalPinkColor.svg' 
import LineVerticalGreenColor from '../../IconSVG/LineVerticalGreenColor.svg'  
import LineVerticalRedColor from '../../IconSVG/LineVerticalRedColor.svg'  

import Header from '../Header.js'
import Detail from '../Detail'
import styles from '../ReportAdmin.module.scss'
import * as _ from 'lodash'

const gradeTemp = ['ป.1', 'ป.2', 'ป.3', 'ป.4', 'ป.5', 'ป.6', 'ป.1-3', 'ป.4-6', 'ม.1', 'ม.2', 'ม.3', 'ม.4', 'ม.5', 'ม.6', 'ม.1-3', 'ม.4-6']
const mergeGradeArray = (array) => {
  const temp = []
  array.forEach(ary => {
    const index = temp.findIndex(t => t[0] === ary[0])
    if (index > -1) {
      temp[index][1] += ary[1]
      temp[index][2] += ary[2]
      temp[index][3] += ary[3]
      temp[index][4] += ary[4]
    } else {
      temp.push(ary)
    }
  })
  const dataSort = temp.sort((a, b) => {
    if (gradeTemp.indexOf(a[0]) < gradeTemp.indexOf(b[0])) return -1
    if (gradeTemp.indexOf(a[0]) > gradeTemp.indexOf(b[0])) return 1
    return 0
  })
  return dataSort
}
export default class FinishedTestingDescription extends Component {
  constructor(props){
    super(props)
    this.state = {
      rowIndex: -1,
      colorCode: '#1DA9F7',
      checkClickOldRow: false,
      // colorCodeDonutChart: ['#00A0FA','#F7441D','#FFB800','#9B51E0','#F54BC5','#219653'],
      colorCodeDonutChart: ['#6C56F2', '#E656F2', '#FFCC6E', '#17931D', '#FA6157'],
      indexCheck: null
    }
  }
  componentDidMount =  () => { 
    var menuItems = [
      { name: 'ระดับชั้น', value: 'grade' },
      { name: 'เกณฑ์การวัดผล', value: 'criterion' },
      { name: 'สาระการเรียนรู้', value: 'subject' }
    ]
    const { year } = this.props
    let month = parseInt(localStorage.getItem('month'))   
    this.props.loadDetail(year, month + 1, menuItems[0].value)  
    this.props.loadType(year,month + 1)
  }
  render() {
    const { detail, type, year, month } = this.props
    const { rowIndex, colorCode , indexCheck , colorCodeDonutChart } = this.state
    const columns = [
      {
        title: 'ประเภท',
        dataIndex: 'type',
        key: 'type',
        render:(type) => { 
          let color = null
          switch (type) {
            case 'ตามหลักสูตร' : color = LineVerticalPurpleColor; break
            case 'แข่งขัน' : color = LineVerticalPinkColor; break
            case 'กำหนดเอง' : color = LineVerticalYellowColor; break
            case 'CAT' : color = LineVerticalGreenColor; break
            case 'แบบฝึกหัด' : color = LineVerticalRedColor; break
            default : color = LineVerticalGreenColor
          }
          return ( 
            <span>
              <img src={color}/>
              <span style={{ paddingLeft: '10px' }}>{type}</span>
            </span> 
          )
        }
      },
      {
        title: 'จำนวน',
        dataIndex: 'total',
        key: 'total',
        align: 'center'
      },
      {
        title: 'เปอร์เซ็น',
        dataIndex: 'percent',
        key: 'percent',
        align: 'center', 
      }
    ]

    var total = 0
    type.map(item => {
      total += item.total
    })
    let data1 = [
      { type: 'ตามหลักสูตร'      ,student: 0,teacher: 0,superTeacher: 0,admin: 0, total: 0, percent: 0 },
      { type: 'แข่งขัน'      ,student: 0,teacher: 0,superTeacher: 0,admin: 0, total: 0, percent: 0 },
      { type: 'CAT'    ,student: 0,teacher: 0,superTeacher: 0,admin: 0, total: 0, percent: 0 },
      { type: 'กำหนดเอง' ,student: 0,teacher: 0,superTeacher: 0,admin: 0, total: 0, percent: 0 },
      { type: 'แบบฝึกหัด' ,student: 0,teacher: 0,superTeacher: 0,admin: 0, total: 0, percent: 0 },
    ]
    let data1Chart = [
      ['type', 'percent'],
      ['ตามหลักสูตร',0],
      ['แข่งขัน',0],
      ['CAT',0],
      ['กำหนดเอง',0],
      ['แบบฝึกหัด',0]
    ]   
    type.map(item => {
      switch (item.type){
        case 'G'      : data1Chart[1] = [item.type,parseFloat(((item.total / total) * 100).toFixed(2))]; data1[0] = { type: 'ตามหลักสูตร', total: item.total,student: `${item.student}`,teacher: `${item.teacher}`,superTeacher: `${item.superTeacher}`,admin: `${item.admin}`, percent: `${((item.total / total) * 100).toFixed(2)}%`} ; break 
        case 'C'      : data1Chart[2] = [item.type,parseFloat(((item.total / total) * 100).toFixed(2))]; data1[1] = { type: 'แข่งขัน', total: item.total,student: `${item.student}`,teacher: `${item.teacher}`,superTeacher: `${item.superTeacher}`,admin: `${item.admin}`, percent: `${((item.total / total) * 100).toFixed(2)}%`} ; break  
        case 'CAT'    : data1Chart[3] = [item.type,parseFloat(((item.total / total) * 100).toFixed(2))]; data1[2] = { type: 'CAT', total: item.total,student: `${item.student}`,teacher: `${item.teacher}`,superTeacher: `${item.superTeacher}`,admin: `${item.admin}`, percent: `${((item.total / total) * 100).toFixed(2)}%`} ; break  
        case 'CUSTOM' : data1Chart[4] = [item.type,parseFloat(((item.total / total) * 100).toFixed(2))]; data1[3] = { type: 'กำหนดเอง', total: item.total,student: `${item.student}`,teacher: `${item.teacher}`,superTeacher: `${item.superTeacher}`,admin: `${item.admin}`, percent: `${((item.total / total) * 100).toFixed(2)}%`} ; break 
        case 'EX'     : data1Chart[5] = [item.type,parseFloat(((item.total / total) * 100).toFixed(2))]; data1[4] = { type: 'แบบฝึกหัด', total: item.total,student: `${item.student}`,teacher: `${item.teacher}`,superTeacher: `${item.superTeacher}`,admin: `${item.admin}`, percent: `${((item.total / total) * 100).toFixed(2)}%`} ; break 
        default : return
      }  
    }) 
    let data2Chart = []
    let data2ChartFilter = [ 
      ['type', 'ครู', 'ครูพิเศษ','นักเรียน', 'ผู้ดูแลระบบ'], 
    ] 
    // let typeSelect = rowIndex === 0 ? 'ตามหลักสูตร' : rowIndex === 1 ? 'แข่งขัน' : rowIndex === 2 ? 'CAT' : rowIndex === 3 ? 'กำหนดเอง' : rowIndex === 4 ? 'แบบฝึกหัด' : null
    let typeSelect = rowIndex === 0 ? 'G' : rowIndex === 1 ? 'C' : rowIndex === 2 ? 'CAT' : rowIndex === 3 ? 'CUSTOM' : rowIndex === 4 ? 'EX' : null
    
    detail.map((item ) => { 
      if(rowIndex === indexCheck && item.type === typeSelect){    
        data2ChartFilter.push([ `${item.grade || item.criterion || item.subject}`,item.teacher,item.superTeacher,item.student,item.admin ])
      }else if(rowIndex !== indexCheck){  
        data2ChartFilter.push([ `${item.grade || item.criterion || item.subject}`,item.teacher,item.superTeacher,item.student,item.admin ]) 
      } 
    })  
    data2Chart = mergeGradeArray(data2ChartFilter) 
    var menuItems = [
      { name: 'ระดับชั้น', value: 'grade' },
      { name: 'สาระการเรียนรู้', value: 'subject' }
    ]   
    let chart1 = data1Chart.slice(0)  
    chart1.map((c,i) => {
      if(i === 1) { c[0] = 'ตามหลักสูตร' }
      else if(i === 2) { c[0] = 'แข่งขัน'}
      else if(i === 3) { c[0] = 'CAT' }
      else if(i === 4) { c[0] = 'กำหนดเอง'} 
      else { c[0] = 'แบบฝึกหัด'} 
    }) 
    return (
      <div className={styles.report_admin_examset_detail}>
        <Header match={this.props.match}
          url='reportfinished'
          headerName='รายงานการส่งข้อสอบ'
          history={this.props.history}
          year={year}
          // month={parseInt(localStorage.getItem('month'))}
          month={month}
          detail={detail}
          getTransaction={this.props.getTransaction}
          statType={'finished'}
          data1={data1}
          loadDetail={this.props.loadDetail}
        />

        <Detail
          data1={chart1} 
          data2={data2Chart} 
          year={year}
          month={month}
          menuItems={menuItems}
          loadDetail={this.props.loadDetail}
          colorCode={colorCode}
          type={'finished'} 
          colorCodeDonutChart={colorCodeDonutChart}
        >
          <Table
            columns={columns}
            dataSource={data1}
            pagination={false}
            rowKey={(record, i) => i}
            onRow={(record, index) => {
              return {
                onClick: () => {  
                  let { colorCode, rowIndex, data2Chart,indexCheck } = this.state
                  data2Chart = [['province', 'count']] 
                  if(rowIndex === index){
                    rowIndex = null
                  }else{
                    rowIndex = index
                  }
                  indexCheck = index
                  if(rowIndex === 0){ 
                    colorCode = '#1DA9F7'
                  }else if(rowIndex === 1){ 
                    colorCode = '#F7441D' 
                  }else if(rowIndex === 2){ 
                    colorCode = '#FFB800' 
                  }else if(rowIndex === 3){ 
                    colorCode = '#9B51E0'
                  }else if(rowIndex === 4){ 
                    colorCode = '#F54BC5'
                  }else if(rowIndex === 5){ 
                    colorCode = '#219653'
                  } 
                  this.setState({ rowIndex, colorCode, data2Chart,indexCheck}) 
                },  
                style: {
                  backgroundColor: rowIndex === index ? '#E0E0E0' : null, 
                  cursor: 'pointer'
                }
              }
            }}
          />
        </Detail>
      </div>
    )
  }
}
