import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CKEditor } from 'ckeditor4-react'
import {
  message,
  Spin,
  Button,
  Input,
  Form,
  Row,
  Col,
  Checkbox,
  Select
} from 'antd'
import {
  onEditFaq
} from '../../redux/master/action'
import styles from '../ContactUs/ContactUs.module.scss'
const { TextArea } = Input
const {Option} = Select
class EditFaqComponent extends Component {
 
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      contactDetail:'',
      faqDetail : {},
      visible : [],
      newVisible : [],
      guestSatus : false,
      teacherStatus : false,
      studentStatus: false,
      dataForEdit : '',
      checkGuest : false,
      checkTeacher : false,
      checkStudent : false,
      ready: false,
      edited: false
    }
    this.handleInstanceReady = this.handleInstanceReady.bind(this)
  }
  componentDidMount(){
    //  this.fetchContactUsDetail()
    this.getData()
  }

  // componentDidUpdate(prevProps) {
  //   if (this.state.dataForEdit && this.state.ready && !this.state.edited) {
  //     CKEDITOR.instances['faqeditor'].setData(this.state.dataForEdit.answer)
  //     // this.state.ckeditor.instances['faqeditor'].setData(this.state.dataForEdit.answer)
  //     this.setState({edited: true})
  //   }
  // }

  getData = () =>{
    const data = this.props.goToEditComponent()
    for(let i = 0 ; i < data.visible.length ; i ++){
      if(data.visible[i].role === 'guest'){
        this.setState({
          checkGuest : true
        })
      }
      if(data.visible[i].role === 'teacher'){
        this.setState({
          checkTeacher : true
        })
      }
      if(data.visible[i].role === 'student'){
        this.setState({
          checkStudent : true
        })
      }
    }
    this.setState({
      dataForEdit : data
    })
  }
  handleCheckbox = (e)=>{
    if(e.target.value === 'guest'){
      if(this.state.checkGuest === true){
        this.setState({
          checkGuest : false
        })
      }else {
        this.setState({
          checkGuest : true
        })
      }
    }
    if(e.target.value === 'teacher'){
      if(this.state.checkTeacher === true){
        this.setState({
          checkTeacher : false
        })
      }else {
        this.setState({
          checkTeacher : true
        })
      }
    }
    if(e.target.value === 'student'){
      if(this.state.checkStudent === true){
        this.setState({
          checkStudent : false
        })
      }else {
        this.setState({
          checkStudent : true
        })
      }
    }
  }

  changeText = (e,name) =>{
    const {dataForEdit} = this.state
    if(name === 'answer'){
      dataForEdit[name] = e.editor.getData()
    }else{
      dataForEdit[name] = e.target.value
    }
    this.setState({
      dataForEdit
    })
  }
  changeOption = (e,name) =>{
    const {dataForEdit} = this.state
    dataForEdit[name] = e
    this.setState({
      dataForEdit
    })
  }
  onUpdateFaq = async (e) => {
    const {dataForEdit,checkGuest,checkTeacher,checkStudent} = this.state
    e.preventDefault()
    // dataForEdit.visible = []
    if(checkGuest === true && dataForEdit.visible.findIndex(vis => vis.role === 'guest') === -1) {
      dataForEdit.visible.push({ role: 'guest'})
    } else if (checkGuest === false && dataForEdit.visible.findIndex(vis => vis.role === 'guest') !== -1) {
      const i = dataForEdit.visible.findIndex(vis => vis.role === 'guest')
      dataForEdit.visible.splice(i, 1)
    }
    
    if(checkTeacher === true && dataForEdit.visible.findIndex(vis => vis.role === 'teacher') === -1) {
      dataForEdit.visible.push({ role: 'teacher'})
    } else if (checkTeacher === false && dataForEdit.visible.findIndex(vis => vis.role === 'teacher') !== -1) {
      const i = dataForEdit.visible.findIndex(vis => vis.role === 'teacher')
      dataForEdit.visible.splice(i, 1)
    }
    
    if(checkStudent === true && dataForEdit.visible.findIndex(vis => vis.role === 'student') === -1) {
      dataForEdit.visible.push({ role: 'student'})
    } else if (checkStudent === false && dataForEdit.visible.findIndex(vis => vis.role === 'student') !== -1) {
      const i = dataForEdit.visible.findIndex(vis => vis.role === 'student')
      dataForEdit.visible.splice(i, 1)
    }
    
    if (!e.err) {
      const res = await this.props.onEditFaq(this.state.dataForEdit._id,dataForEdit)
      if(!res.error){
        message.success('แก้ไขข้อมูลสำเร็จ')
        this.props.backToHome()
      }else{
        message.error(res.error)
      }
    }else{
      message.error(e.err)
    }
  }
  
  handleInstanceReady({ editor }) {
    const data = this.props.goToEditComponent()
    editor.setData(data.answer);
  }
  
  
  render() {
    return (
      <div className={styles.examSetDiv}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <div className='flex-space-between' style={{marginBottom: '30px'}}>
            <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>แก้ไข FAQ</h1>
          </div>
          <div>
            <Row>
              <Col span={12}>
                <div style = {{backgroundColor : ''}}>
                  <Row style = {{ marginBottom : '2em'}}>
                    <Row>
                      <label>สถานะ</label>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Checkbox onChange = {this.handleCheckbox} value = 'guest' checked={this.state.checkGuest} >ไม่ใช่สมาชิก</Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox onChange = {this.handleCheckbox} value = 'teacher' checked={this.state.checkTeacher}>ครู/ครูพิเศษ</Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox onChange = {this.handleCheckbox} value = 'student' checked={this.state.checkStudent}>นักเรียน</Checkbox>
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <Form onSubmit={this.onUpdateFaq}>
                      <Row>
                        <label style = {{color : '#3D3D3D'}}>หัวข้อ</label>
                        <Select placeholder= 'การเข้าสู่ระบบ' 
                          className ={styles.inputInContact} 
                          value = {this.state.dataForEdit.title ? this.state.dataForEdit.title : '-'}
                          onChange = {e => this.changeOption(e,'title')} 
                        >
                          <Option value = 'การเข้าสู่ระบบ'>การเข้าสู่ระบบ</Option>
                          <Option value = 'การสมัครสมาชิก'>การสมัครสมาชิก</Option>
                        </Select>
                        <br/>
                        <label style = {{color : '#3D3D3D'}}>คำถาม</label>
                        <TextArea className ={styles.inputInContact}
                          onChange = {e => this.changeText(e,'question')}
                          value = {this.state.dataForEdit.question ? this.state.dataForEdit.question : ''}
                          autosize={{ minRows: 2, maxRows: 6 }}>
                        </TextArea>
                        <label style = {{color : '#3D3D3D'}}>คำตอบ</label>
                        <CKEditor
                          initData= {this.state.dataForEdit.answer ? this.state.dataForEdit.answer : ''}
                          name='faqeditor'
                          onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                          onInstanceReady={this.handleInstanceReady}
                          config={{
                            toolbarGroups : [
                              { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                              { name: 'links', groups: [ 'links' ] },
                              { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                              { name: 'paragraph', groups: [ 'align', 'list', 'indent', 'blocks', 'bidi', 'paragraph' ] },
                              { name: 'insert', groups: [ 'insert' ] },
                              { name: 'document', groups: [ 'document', 'mode', 'doctools' ] },
                              { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                              { name: 'forms', groups: [ 'forms' ] },
                              '/',
                              '/',
                              { name: 'styles', groups: [ 'styles' ] },
                              { name: 'colors', groups: [ 'colors' ] },
                              { name: 'tools', groups: [ 'tools' ] },
                              { name: 'others', groups: [ 'others' ] },
                              { name: 'about', groups: [ 'about' ] }
                            ],
                            removeButtons : 'Source,Save,About,NewPage,Preview,Print,Templates,Maximize,ShowBlocks,BGColor,TextColor,Styles,Format,Font,FontSize,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Unlink,Anchor,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,CopyFormatting,RemoveFormat,Cut',
                            imageUploadUrl: 'https://exam.sobtid.me/admin/questions/image', 
                            uploadUrl: 'https://exam.sobtid.me/admin/questions/image?responseType=json', 
                            filebrowserUploadUrl: 'https://exam.sobtid.me/admin/questions/imagefile?responseType=json',
                            editorUrl: 'https://cdn.ckeditor.com/4.17.2/full/ckeditor.js',
                            extraPlugins: 'justify, bidi, smiley, iframe, font, colorbutton, showblocks, find, selectall, pagebreak',
                            language: 'th'
                          }}
                          onChange = {e => this.changeText(e,'answer')}
                        />
                      </Row>
                    </Form>
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                <div> 
                </div> 
              </Col>
            </Row>
          </div>
          <div>
            <div>
              <div className = {styles.devide_line}>
              </div>
              <div>
                <Row>
                  <Col span={6}>
                    <div>
                      <a onClick = {this.props.backToHome} className = {styles.cancle_link}>ยกเลิก</a>
                    </div>
                  </Col>
                  <Col span={6}></Col>
                  <Col span={6}></Col>
                  <Col span={6}>
                    <div styles = {styles.devide_line}>
                    </div> 
                    <div>
                      <Button className = {styles.save_btn} type='primary' htmlType='submit' onClick = {this.onUpdateFaq}>บันทึก</Button>
                    </div> 
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    
    )
  }
}

const mapStateToProps = state => {
  return {
    contactDetail : state.master.contactDetail
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onEditFaq,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(EditFaqComponent)