import React from 'react'
import {
  Row,
  Col,
} from 'antd'
import CompetitionScienceIcon from '../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../IconSVG/CompetitionMathIcon.svg'
import MathCATIcon from '../IconSVG/MathCATIcon.svg'
import ScienceCATIcon from '../IconSVG/ScienceCATIcon.svg'
import IconScienceExam from '../IconSVG/ExamScience.svg'
import IconMathExam from '../IconSVG/ExamMath.svg'
import CustomIconMath from '../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../IconSVG/CustomIconScience.svg'
import ExerciseIconMath from '../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../IconSVG/ExerciseIconScience.svg'

export default (props) => {
  const { exam } = props
  let subjectIcon = IconMathExam
  if(exam.type === 'G') {
    if(exam.subject === 'คณิตศาสตร์') {
      subjectIcon = IconMathExam
    } else {
      subjectIcon = IconScienceExam
    }
  } else if(exam.type === 'C'){
    if(exam.subject === 'คณิตศาสตร์') {
      subjectIcon = CompetitionMathIcon
    } else {
      subjectIcon = CompetitionScienceIcon
    }
  }else if(exam.type === 'CUSTOM'){
    if(exam.subject === 'คณิตศาสตร์') {
      subjectIcon = CustomIconMath
    } else {
      subjectIcon = CustomIconScience
    }
  } else if(exam.type === 'CAT') {
    if(exam.subject === 'คณิตศาสตร์') {
      subjectIcon = MathCATIcon
    } else {
      subjectIcon = ScienceCATIcon
    }
  } else {
    if(exam.subject === 'คณิตศาสตร์') {
      subjectIcon = ExerciseIconMath
    } else {
      subjectIcon = ExerciseIconScience
    }
  }
  const owner = exam.owner ? { prefixName: ['teacher', 'superTeacher'].includes(exam.owner.role) ? 'ครู' : '' , fullname: `${exam.owner.firstName} ${exam.owner.lastName}` } : undefined
  return (
    <Row>
      <Col xl={4} lg={6}>
        <img alt='icon-subject' src={subjectIcon} width={90} height={90} />
      </Col>
      <Col xl={19} offset={1} lg={18} style={{ marginTop: '10px'}}>
        <Row>
          <h2>{exam.name}</h2>
        </Row>
        <Row>
          <span style={{ color:'#FFFFFF'}}>{exam.code || ''}  โดย {owner ? `${owner.prefixName}${owner.fullname}`: ''}</span>
        </Row>
      </Col>
    </Row>
  )
}