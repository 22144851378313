import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Checkbox,
} from 'antd'

import styles from './ModalUpdateNotice.module.scss'

class ModalUpdateNotice extends Component {
  render() {
    const { isVisible, onOK, noticeType } = this.props
    return (
      <Modal
        visible={isVisible}
        closable={false}
        width={'630px'}
        wrapClassName={styles.modelWrap}
        destroyOnClose={true}
        centered
        footer={<div className='flex-space-center'>
          <Button key='submit' type='primary' htmlType='submit' className={ styles.modalTermOKBtn } onClick={onOK}>
            ตกลง
          </Button>
        </div>}
      >
          <div>
            ระบบการสอบออนไลน์ได้มีการเปลี่ยนแปลง<span style={{fontWeight: 'bold'}}>{noticeType[0]}</span> ท่านจะสามารถใช้งานเว็บไซต์ได้เมื่อยอมรับ{noticeType[1]}
          </div>
      </Modal>
    )
  }
}

ModalUpdateNotice.propTypes = {
  noticeType: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onOK: PropTypes.func.isRequired,
}

export default ModalUpdateNotice