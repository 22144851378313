/* eslint-disable no-undef */
import React, { Component }  from 'react'
import {
  Input,
  Layout 
} from 'antd'
import NoteAnswer from './Note'
import styles from './Testing.module.scss'
import CorrectIcon from '../IconSVG/Correct.svg'
import IncorrectIcon from '../IconSVG/Incorrect.svg'
import { displayer } from './helper'

const { Content } = Layout

class ShortAnswerTesting extends Component {

  constructor(props){
    super(props)
    this.myRef = React.createRef()
    this.state = {
      note: {
        text: '',
        file: null,
        checkFalseFormat: false,
        isTypeNumber: false
      }
    }
  }
  
  componentDidMount = () => {
    const { question } = this.props 
    if(question.answers[0].type === 'number') {
      this.setState({ isTypeNumber: true })
    }else {
      this.setState({ isTypeNumber: false })
    }
  }

  onChangeAnswer = (e) => {
    const { question } = this.props    
    let answer = {
      type: question.type,
      order: question.seq,
      orgorder: question.orgseq,
      answer: e.target.value,
      questionId: question._id,
    } 
     
    // let re = /^[+-]?\d+(.\d+)?$/
    let re = /^[+-]?\d+(.\d*[^a-zA-Zๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ])*(,\d*)?$/
    let isText = false 
    for(let i = 0; i < question.answers.length ; i++){
      if(question.answers[i].type === 'text') {
        isText = true
      }
    } 
    if(!isText && answer.answer.match(re) === null && answer.answer !== '') {
      this.setState({ checkFalseFormat: true }) 
    }else {
      this.setState({ checkFalseFormat: false }) 
    }
    // if(question.answers[0].type === 'number' && answer.answer.match(re) === null && answer.answer !== ''){
    //   this.setState({ checkFalseFormat: true })
    // }else {
    //   this.setState({ checkFalseFormat: false })
    // }
    this.props.onSetAnswer(answer)
  }

  setNote = (data, type) => {
    let { note } = this.state
    const { question, progressTesting } = this.props
    note =  progressTesting && progressTesting.note !== undefined ? progressTesting.note : note
    note[type] = data
    note.isChanged = true
    this.props.onSetNote(question.seq, note)
  }

  renderCorrectMark = (progressTesting) => {
    if (progressTesting === undefined || progressTesting.isCorrect === undefined) return ''
    if (progressTesting.isCorrect)
      return (<img className='check-mark' alt='ถูกต้อง' src={CorrectIcon} width={'60%'} height={'60%'} />)
    else
      return (<img className='check-mark' alt='ผิด' src={IncorrectIcon} width={'60%'} height={'60%'} />)
  }

  scrollToHead() {
    const node = this.myRef.current
    window.scrollTo(0,node)
  }

  render(){  
    const { progressTesting, question, isSolution, displayHowTo, testingId } = this.props 
    let { note, checkFalseFormat } = this.state
    let answerText = ''
    if (progressTesting) {
      answerText = progressTesting.answer
      note =  progressTesting.note !== undefined ? progressTesting.note : note
    }      
     
    return(
      <Content ref={this.myRef} className={styles.content_testing}>
        <div className='short-answer-testing'>
          <h2 className = { styles.num_of_question }>ข้อที่ {question.seq}.</h2>
          { displayer(question.text) } 
          <div className='question-content'>
            { isSolution && this.renderCorrectMark(progressTesting) }  

            <Input defaultValue={answerText} onChange={!isSolution && this.onChangeAnswer} /> <span>{question.unit}</span>
            {
              checkFalseFormat ? 
                <p style={{ color: 'red',fontSize: '14px', paddingLeft: '5px' }}>กรุณาตอบเป็นตัวเลข</p> : ''
            }
            
            {isSolution && (
              <div className='possible-answer'>
                <p>คำตอบที่เป็นไปได้</p> 
                <ul>
                  { question.answers.map((ans, i) => (<li key={i}><span dangerouslySetInnerHTML={{__html: ans.key}} /></li>))}
                </ul> 
                {/* <span dangerouslySetInnerHTML={{__html: question.explanation }} />  */}
	      <p style={{paddingTop: '4ex', paddingBottom: '0ex', marginBottom: '0ex', fontWeight: 'bold'}}>คำอธิบาย/แนวการตอบ</p>
                <span>{displayer(question.explanation)}</span>
              </div>
            )}
          </div> 
          
          { displayHowTo && (
            <div className='how-to' style={{ paddingTop:'50px' }}>
              <NoteAnswer explanation={note} onUploadFile={(data) => this.setNote(data, 'file')} onChangeNoteText={(data) => this.setNote(data, 'text')} note={note} testingId={testingId} questionId={question._id} isSolution={isSolution} />
            </div>
          )}
        </div>
      </Content>
    )
  }
}
export default ShortAnswerTesting
