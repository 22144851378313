import React, { Component, Fragment }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// import 'antd/dist/antd.css';
// import {  } from 'antd';
// import Highlighter from 'react-highlight-words';
// import { SearchOutlined } from '@ant-design/icons';

import {
  //getAuditList,
  getAuditListFull,
  getProvinceList
} from '../../redux/audittrail/action'


import Header from './Header'
import {
Icon,
  Table,
  Spin,
Input, Button
} from 'antd'
import * as moment from 'moment'
import ModalAuditTrail from './ModalAuditTrail'
import styles from './AuditTrail.module.scss'
// import Edit from '../IconSVG/Edit.svg'
// import EditGray from '../IconSVG/EditGray.svg'
// import Delete from '../IconSVG/Delete.svg'
// import DeleteGray from '../IconSVG/DeleteGray.svg'



const field_names = {
  'profileImage': 'รูปภาพ',
  'prefixName': 'คำนำหน้า',
  'firstName': 'ชื่อ',
  'lastName': 'นามสกุล',
  'email': 'อีเมล',
  'password': 'รหัสผ่าน',

  'school.name': 'โรงเรียน/หน่วยงาน',
  'school.addressNo': 'เลขที่',
  'school.villageNo': 'หมู่ที่',
  'school.lane': 'ซอย',
  'school.road': 'ถนน',

  'school.district': 'เขต/อำเภอ',
  'school.subDistrict': 'แขวง/ตำบล',

  'school.province': 'จังหวัด',
  'school.postalCode': 'รหัสไปรษณีย์',
  'school.department': 'สังกัด',

  'recommendation': 'ยินยอมให้ใช้ข้อมูล',
}

// const nulltostring = (x) => {  
//   if (typeof x == 'string')
//     return x
//   return ''
// }

// const cell_text = (a,rec)=> { return (<span style={{ cursor:'pointer' }} onClick={() => {this.onOpenModal(rec)}} >{a}</span>) }


function provincefromid(province,province_id) {
   let nameSchool = ""
  
// console.log(province.length)
if (province.length > 0)
{
  province.forEach(p => {
// console.log(p)
    if(p._id === province_id){
    // console.log(p)
    // console.log(province_id)
      nameSchool = p.name
    }
  })
}


  return nameSchool
}

// const ReplaceProvince = (props) => {  
//   let nameSchool = props.province.map(p => {
//     if(p._id === props.province_id){
//       return p.name
//     }
//     return ''
//   })
//   return (
//     <Fragment>{nameSchool}</Fragment>
//   )
// }

function b_to_text(a){
    if (a == true) return 'ให้ใช้ข้อมูล'
    if (a == false) return 'ไม่ให้ใช้ข้อมูล'

    return '';
  }

const Detailsfield = (props) => { 

        const details = props.details
        const myprovinceList = props.myprovinceList

        // console.log(JSON.stringify(details)) // + Array.isArray(details))

        let formatoutput = "" //JSON.stringify(details)
        let listItems = null
        // const myprovinceList = this.state.provinceList.items

      if (Array.isArray(details)) // && Array.isArray(myprovinceList))
        {
        // <span>{ details.forEach((list) => {JSON.stringify(list)}) }</span>
        // <span>{ JSON.stringify(details) }</span>
          // formatoutput.forEach((details,record) => { formatoutput += JSON.stringify(details) })

        listItems = details.map(function(value) {
          if(value.column === 'school.province')
          {
            if (provincefromid(myprovinceList,value.from) != '' && provincefromid(myprovinceList,value.to) != '')
            {
              return <li key={value.column}>แก้ไข {field_names[value.column]} จาก '<Fragment>{provincefromid(myprovinceList,value.from)}</Fragment>' เป็น '<Fragment>{provincefromid(myprovinceList,value.to)}</Fragment>'</li>
            }
            else
            {
              return ''
            }
            /* <li>แก้ไข {field_names[value.column]} จาก '{value.from}' เป็น '{value.to}'</li> */
          }
          else if(value.column === 'recommendation')
          {
            return <li key={value.column}>แก้ไข {field_names[value.column]} จาก '<Fragment>{b_to_text(value.from)}</Fragment>' เป็น '<Fragment>{b_to_text(value.to)}</Fragment>'</li>
          }
          else
          {
            return <li key={value.column}>แก้ไข {field_names[value.column]} จาก '{value.from}' เป็น '{value.to}'</li>
          }
        });

          formatoutput = <ul className={styles.detail_nospace}>{listItems}</ul>
          
        }
        else
        {
        // <span>{ details.forEach((list) => {JSON.stringify(list)}) }</span>
            formatoutput = ''
        }

        // console.log("formatoutput" + JSON.stringify(formatoutput))

        return (
          
          <span>{ formatoutput }</span>
        )
}


const myquery = params => {

let a = {
  // results: params.pagination.pageSize,
  // page: params.pagination.current,
  // ...params,

  // requery: {
      page: params.pagination.current,
      limit: params.pagination.pageSize,
      sort:{editDateTime: 'desc'},
      
  // },
}

if (params.sortField !== undefined)
{
  if (params.sortOrder === 'ascend')
  {
    a.sort = {};
    a.sort[params.sortField] = 'asc';
  }
  else if (params.sortOrder === 'descend')
  {
    a.sort = {};
    a.sort[params.sortField] = 'desc';
  }
}


const listk = ['ticket','note','editor_detail_shortName','username_detail_shortName','ipaddress'];

for (let i in listk) {
  let k = listk[i]
  // console.log('filters----['+k+']')

  if (params[k] !== undefined)
  {
    // console.log('filters>>>>>['+k+']=' + params[k][0])
    a['myfilters['+k+']'] = params[k][0];
  }
}


// a.sort = JSON.parse(a.sort)

return a};




class AuditTrail extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      // field_names: field_names,

    //   AuditListFull: [],
    //   AuditList: [],
    //   // excelSchool: [],
    //   indexRow: false,
    //   checkMouseOverEdit: false,
    //   typeModal: false,
    //   modalVisible: false, 
    //   item: [],
    //   //schoolId: null,
    //   provinceList: null,
    //   //provinceItem: [],
    //   searchValue: null,


provinceList: [],

data: [],
    pagination: {
    },
    } 
  }

getColumnSearchProps = (dataIndex,coltext) => ({
    
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}> 
{/* // className={styles.searchmove} */}
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`ค้นหา ` + coltext} // ${dataIndex}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            // icon={<EditGray />}
            size="small"
            style={{ width: 90 }}
          >
            ค้นหา
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            ยกเลิก
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            X
          </Button> */}
        </div>
      </div>
    ),
    
    filterIcon: filtered => <span className={styles.headercol} style={{ color: filtered ? '#F7941D' : undefined }} ><Icon type="search" /></span>, //<EditGray style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    // render: text =>
    //   this.state.searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[this.state.searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  columns = [
    {
      title: 'แก้ไขข้อมูลเมื่อ',
      dataIndex: 'editDateTime',
      key: 'editDateTime',
      width:'160px',
      // ...this.getColumnSearchProps('editDateTime'),
      defaultSortOrder : 'descend',
      sortDirections: ['ascend' , 'descend'],
      sorter: true,
      //sorter: (a, b) => a.editDateTime - b.editDateTime,
      render:(a,rec)=> { 
        return (
          <span style={{ cursor:'pointer' }} onClick={() => {this.onOpenModal(rec)}} >{moment.unix(a).format('DD/MM/YYYY HH:mm:ss')}</span>
        )
      },
    },
    {
      title: 'หมายเลข Ticket',
      dataIndex: 'ticket',
      key: 'ticket',
      width: '160px',
      ...this.getColumnSearchProps('ticket','หมายเลข Ticket'),
      sortDirections: ['ascend' , 'descend'],
      sorter: true,
      // sorter: (a, b) => nulltostring(a.ticket).localeCompare(nulltostring(b.ticket)),
      render: (a,rec)=> { return (<span style={{ cursor:'pointer' }} onClick={() => {this.onOpenModal(rec)}} >{a}</span>) }
    },
    {
      title: 'หมายเหตุ Ticket',
      dataIndex: 'note',
      key: 'note',
      width: '160px',
      ...this.getColumnSearchProps('note','หมายเหตุ Ticket'),
      sortDirections: ['ascend' , 'descend'],
      sorter: true,
      // sorter: (a, b) => nulltostring(a.note).localeCompare(nulltostring(b.note)),
      render: (a,rec)=> { return (<span style={{ cursor:'pointer' }} onClick={() => {this.onOpenModal(rec)}} >{a}</span>) }
    },
    {
      title: 'ผู้แก้ไข',
      dataIndex: 'editor_detail_shortName',
      key: 'editor_detail_shortName',
      width: '220px',
      ...this.getColumnSearchProps('editor_detail_shortName','ผู้แก้ไข'),
      sortDirections: ['ascend' , 'descend'],
      sorter: true,
      //sorter: (a, b) => nulltostring(a.editor_detail_shortName).localeCompare(nulltostring(b.editor_detail_shortName)),
      render: (a,rec)=> { return (<span style={{ cursor:'pointer' }} onClick={() => {this.onOpenModal(rec)}} >{a}</span>) }
    },
    {
      title: 'ผู้ใช้',
      dataIndex: 'username_detail_shortName',
      key: 'username_detail_shortName',
      width: '220px',
      ...this.getColumnSearchProps('username_detail_shortName','ผู้ใช้'),
      sortDirections: ['ascend' , 'descend'],
      sorter: true,
      //sorter: (a, b) => nulltostring(a.username_detail_shortName).localeCompare(nulltostring(b.username_detail_shortName)),
      render: (a,rec)=> { return (<span style={{ cursor:'pointer' }} onClick={() => {this.onOpenModal(rec)}} >{a}</span>) }
    },
    {
      title: 'IP Address',
      dataIndex: 'ipaddress',
      key: 'ipaddress',
      width: '160px',
      ...this.getColumnSearchProps('ipaddress','IP Address'),
      sortDirections: ['ascend' , 'descend'],
      sorter: true,
      //sorter: (a, b) => nulltostring(a.ipaddress).localeCompare(nulltostring(b.ipaddress)),
      render: (a,rec)=> { return (<span style={{ cursor:'pointer' }} onClick={() => {this.onOpenModal(rec)}} >{a}</span>) }
    },
    // {
    //   title: 'รายละเอียด',
    //   dataIndex: 'details',
    //   key: 'details',
    //   width: '300px',
    //   render: (a,rec) => { return (<span style={{ cursor:'pointer' }} onClick={() => {this.onOpenModal(rec)}} ><Detailsfield details={a} myprovinceList={this.state.provinceList} /></span>) }
    // },
  ]; 

  componentDidMount = () => {
    //this.fetchAuditTrail()


    const { pagination } = this.state;
    this.fetch({ pagination });
    this.fetch2();

  }

  fetch = (params = {}) => {
    this.setState({ loading: true });

    // console.log("fetch ORG " + JSON.stringify(params))
    // console.log("fetch QUERY " + JSON.stringify(myquery(params)))

    let auditdata = this.props.getAuditListFull(myquery(params))
    auditdata.then(data => {

      // let chk =  Object.assign({}, data)
      // console.log("fetch1 " + JSON.stringify(data.items.length));
      // chk.items = []
      // console.log("fetch2 " + JSON.stringify(chk));
      // console.log("fetch3 " + JSON.stringify(params.pagination));
      // // console.log("fetch " + data.items.length);
      this.setState({
        loading: false,
        data: data.items,
        pagination: {
          ...params.pagination, // add all old

          position: 'both',
          simple: true, 
          pageSize: 10,
          total: data.totalCount,
          // total: 200,
          // 200 is mock data, you should read it from server
           //total: data.totalCount,
        },
      });
    });
  };

  fetch2 = () => {
      this.setState({ loading: true });
      let resProvince = this.props.getProvinceList()
      resProvince.then(data => {
      this.setState({ 
        loading: false,
        provinceList: data.items, 
        })
      });
  };




  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };



  onOpenModal = (record) => {   //,type
    // if(type === 'edit' || type === 'delete'){
    //   this.setState({
    //     typeModal: type,
    //     item: record,
    //     schoolId: record._id,
    //     modalVisible: true
    //   })
    // }else{
      this.setState({
        // typeModal: type,
    rowdata: record,
        modalVisible: true
      })
    // }
  } 
  onCloseModal = () => {
    this.setState({
rowdata: null,
      modalVisible: false
    })
  } 
  render(){
    const { isLoading,modalVisible,  rowdata, //excelSchool,//item, //schoolId, indexRow, provinceList, //typeModal ,AuditList, 
       provinceList, pagination, data} = this.state   //provinceList, AuditListFull, searchValue, 

  // console.log("render provinceList " + JSON.stringify(provinceList));

    return (
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading }>
  {/*  fetchSchool={this.fetchAuditTrail} dataSearch={AuditList} setProvinceFiltersBySearch={this.setProvinceFiltersBySearch} onOpenModal={this.onOpenModal} 
setValueOfSearch={this.setValueOfSearch} fetchAuditTrail={this.fetchAuditTrail}  */}
{/* ReplaceProvince={ReplaceProvince}  */}
          <Header provinceList={provinceList} field_names={field_names} key='AuditHeader' />  
{/* //exportExcel={AuditList}  */}
          <Table 
            scroll={{x:true}}
            rowKey={record => record._id}//{ (record,i) => i }
            columns={this.columns} 
            dataSource={data}  
            pagination={pagination} //{simple: true, current: AuditListFull.page ,pageSize: 10, total: AuditListFull.totalCount, position: 'both' }} 
            
            // onRow={(record, index) => ({ 
            //   onMouseEnter: ()=> this.setState({ indexRow: index })
            // })}


            //onChange={(pagination) => this.nextPage(searchValue, pagination.current)}
            onChange={this.handleTableChange}
             
          />
          <h1 style={{fontSize: '18px', color: '#05007A', margin: '0'}}>จำนวนข้อมูลทั้งหมด {pagination.total}</h1>
          <ModalAuditTrail provinceList={provinceList} modalVisible={modalVisible} Detailsfield={Detailsfield} rowdata={rowdata} onCloseModal={this.onCloseModal}/>
{/* //provinceList={provinceList} indexRow={indexRow}   fetch={this.fetchSchool} schoolId={schoolId} typeModal={typeModal} items={item}  */}
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
      //getAuditList,
      // getAuditList ,
      getProvinceList,
      getAuditListFull
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(AuditTrail)