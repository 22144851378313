import React, { Component, Fragment }  from 'react'  
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Modal,Form,Row,Col,Divider,Button,Input,message } from 'antd'
import styles from './Subjects.module.scss'
import WarningIcon from '../IconSVG/WarningIcon.svg'
import { onCreateSubject,editSubject,deleteSubject } from '../../redux/subjects/action'
let formName = 'form'

class ModalSubject extends Component {
  componentDidMount = () => {
    formName = this.props.formName
  }
  onSubmit= (e) => {
    const { fetch, onCancel, formName, id } = this.props
    e.preventDefault()
    this.props.form.validateFieldsAndScroll(async(err, values) => {  
      if(!err){  
        if(formName === 'Add_Subject') {
          await this.props.onCreateSubject(values)
        }else if(formName === 'Edit_Subject'){
          await this.props.editSubject(values,id)
        }else{
          await this.props.deleteSubject(id)
        } 
        message.success( formName === 'Add_Subject' ? 'เพิ่มข้อมูลสำเร็จ' : formName === 'Edit_Subject' ? 'แก้ไขข้อมูลสำเร็จ' : 'ลบข้อมูลสำเร็จ')
        await fetch()
        onCancel()
      }
    })
  }
  render(){
    const { visible, onCancel, form: { getFieldDecorator }, formName, value }  = this.props  
    return (
      <Modal
        className= {styles.modal}
        centered={true}
        closable={true}
        visible={visible}
        onCancel={onCancel}
        destroyOnClose={true}
        footer={ false }
      >
        <Form onSubmit={this.onSubmit} hideRequiredMark={true}>
          <h1 className={styles.header_modal}>{ formName === 'Edit_Subject' ? 'แก้ไขข้อมูล' : formName === 'Add_Subject' ? 'เพิ่มข้อมูล' : 'ลบข้อมูล' }</h1>
          {
            formName !== 'Delete_Subject' ? 
              <Fragment>
                <Row> 
                  <Col offset={4} span={16}>
                    <Form.Item label='วิชา'>
                      {
                        getFieldDecorator('name',{
                          rules: [ 
                            {
                              required: true,  
                              message: 'กรุณากรอกวิชา',
                            }, 
                          ],
                          initialValue: formName === 'Edit_Subject' ? value : null
                        })(<Input/>)
                      }
                    </Form.Item>
                  </Col>
                </Row>
                <Divider/>
                <Row>
                  <Col span={12}>
                    <span onClick={e => {e.stopPropagation(); onCancel()}} style={{ cursor:'pointer', color:'#828282', textDecorationLine: 'underline'}}>ยกเลิก</span>
                  </Col>
                  <Col span={12}>
                    <Button htmlType='submit' type='primary' style={{ float: 'right', width: '100px' }}>{ formName === 'Delete_Subject' ? 'ลบข้อมูล' : 'บันทึก' }</Button>
                  </Col>
                </Row>
              </Fragment> :
              <Row>
                <Col span={24} align='center'>
                  <div>
                    <img alt='warning-icon' src={WarningIcon}/>
                  </div>
                  <p style={{ fontWeight: '500', fontSize: '20px' }}>ลบวิชา</p>
                  <p styule={{ fontWeight: '500', fontSize: '16px' }}>{value ? value : ''}</p>
                  <span>คุณต้องการลบวิชานี้หรือไม่</span>
                  <Row style={{ paddingTop: '25px' }} >
                    <Col span={12} >
                      <Button onClick={e=>{
                        e.stopPropagation()
                        onCancel()
                      }} style={{width: '70%', float: 'right', marginRight: '15px'}}>ไม่ลบ</Button>
                    </Col>
                    <Col span={12}>
                      <Button htmlType='submit' style={{width: '70%', float: 'left',marginLeft: '15px' }} type='primary' >ลบวิชา</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
          } 
        </Form>
      </Modal>
    )
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {  
      onCreateSubject,
      editSubject,
      deleteSubject
    },
    dispatch
  )
const WrappedFormDataSchool = Form.create({ name: formName })(ModalSubject)
export default connect(null,mapDispatchToProps)(WrappedFormDataSchool) 