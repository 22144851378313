import React, { Component } from 'react'
import {
  Form,
  Select,
  Icon,
  Input,
  AutoComplete
} from 'antd'

import SuffixPasswordIcon from '../components/IconSVG/SuffixPasswordIcon.svg'

const { Option } = Select
const FormItem = Form.Item 

export const generateSelectForm = ( params ) => { 
  const { options, fieldName, form, label, isFormDirty, isRequire, initialValue = null, 
    nameAsValue = false, freeInput = false, placeholder = '', handleSelectChange = null, 
    isEmph = false,
    isRegister = null, showArrow = true, editUser = false, searchSchool = null, editInRegister = false, onTyping, typing } = params
  const { getFieldDecorator } = form
  const validateTrigger = isFormDirty ? 'onChange' : 'onSubmit'  
 
  const option = options.map( (p,i) => {   
    let value
    if(fieldName === 'schoolName') { 
      value = p._id
    }else {
      value = nameAsValue ? p.name :  p._id || p.id
    }
    const currentValue = form.getFieldValue(fieldName) 
    const defaultCheck = ( value && (value === currentValue)) // || (!currentValue && index === 0)   
    return <Option key={i} value={ value } label= {p.remark ? `${p.name} (${p.remark})` : p.name} >
      {p.name}{p.remark ? ` (${p.remark})` : ''} 
      { defaultCheck ? <Icon type='check' className='ant-select-selected-icon' /> : '' }
    </Option>
  }) 
  const handleFormSelectInput = (value, fieldName) => {    
    if (!value || (fieldName === 'schoolName' && options.length === 0)) return 
    const fieldObject = {} 
    fieldObject[fieldName] = value  
    form.setFieldsValue(fieldObject) 
  }
  const emphStyles = isEmph ? {fontWeight: 'bold', color: '#F7941D'} : {}
  const emphBorderStyles = isEmph ? {border: '3px #F7941D solid', borderRadius: '5px'} : {}
  return <FormItem 
    // label={label} 
    label={( <span style={emphStyles}>{label} { isRequire && isRegister ? <span style={{ color: 'red', fontSize: '16px',fontWeight: 200 }}>*</span> : ''}</span> )} 
    key={ label }>
    {getFieldDecorator(fieldName, {
      rules: [{ required: isRequire, message: `กรุณาใส่${label}` }],
      initialValue: initialValue,
      // initialValue:  
      // options[0] && fieldName === 'schoolName' && isRegister ? placeholder : 
      //                 options[0] && (label === 'คำนำหน้า' || fieldName === 'province' || fieldName === 'department') && isRegister && !editInRegister ? options[0].name : 
      //                   options[0] && !editInRegister ? (editUser ? initialValue.province : fieldName === 'province' && !isRegister ? initialValue.id : initialValue ) : 
      //                     initialValue ? initialValue : null, 

      validateTrigger: validateTrigger
    })(
      <Select
        key = {(i) => i}
        placeholder={ placeholder }
        showSearch={freeInput === true}
        showArrow={showArrow}
        suffixIcon={<Icon type='down' style={{color: '#828282'}}/>}
        optionLabelProp='label'
        style={emphBorderStyles}
        onSearch={(val) => {      
          if(fieldName === 'schoolName' && val) {  
            searchSchool(val)   
          }
          if((val && fieldName !== 'schoolName') || (fieldName === 'schoolName' && isRegister)) {  
            handleFormSelectInput(val, fieldName)
            handleSelectChange(val) 
          }
        }}   
        onInputKeyDown={ (e) => {   
          if (e.keyCode === 13) {   
            if(options[0] && options[0].name) {
              // handleFormSelectInput(options[0].name)
              // handleSelectChange(options[0].name)  

              handleFormSelectInput(e.target.value)
              handleSelectChange(e.target.value)  
              // e.target.blur()
            }  
          }
        }}
        onSelect={(value,option) => {   
          if(handleSelectChange) handleSelectChange(value) 
        }}
        notFoundContent={fieldName === 'schoolName' ? 'ไม่พบโรงเรียน' : null}
      >
        { option }
      </Select>)}
  </FormItem>
}
export const generateAutoCompleteForm = ( params ) => { 
  const { options, fieldName, form, label, isFormDirty, isRequire, 
    placeholder = '', handleSelectChange = null, 
    isRegister = null,searchSchool = null ,onSchoolChangeId, initialValue = undefined
  } = params
  const { getFieldDecorator } = form
  const validateTrigger = isFormDirty ? 'onChange' : 'onSubmit'   

  const renderOption = (item,i) => {  
    return <Option value={item._id} key={i}>{item.name}</Option>
  }  
  return <FormItem 
    label={( <span>{label} { isRequire && isRegister ? <span style={{ color: 'red', fontSize: '16px',fontWeight: 200 }}>*</span> : ''}</span> )} 
    key={label}
    
  >
    {getFieldDecorator(fieldName, {
      rules: [{ required: isRequire, message: `กรุณาใส่${label}` }],
      validateTrigger: validateTrigger,
      initialValue: initialValue
    })(
      <AutoComplete
        dataSource={options.map(renderOption)} 
        onSearch={(value) => {
          searchSchool(value)
          handleSelectChange(value)
        }}
        onSelect={onSchoolChangeId}
        placeholder={placeholder} 
      />)} 
  </FormItem>
} 
export const generateSelectFormInitialValue = ( params ) => { 
  const { options, fieldName, form, label, isFormDirty, isRequire, initialValue = null, 
    nameAsValue = false, freeInput = false, placeholder = '', handleSelectChange = null, 
    isRegister = null, showArrow = true, editUser = false, searchSchool = null, editInRegister = false,
    isEmph = false,
    onSelectSchool } = params
  const { getFieldDecorator } = form
  const validateTrigger = isFormDirty ? 'onChange' : 'onSubmit'   
   
  const option = options.map( (p,i) => { 
    let value
    if(fieldName === 'schoolName') {
      value = p._id    
    }else {
      value = nameAsValue ? p.name :  p.id 
    } 
    const currentValue = form.getFieldValue(fieldName) 
    const defaultCheck = ( value && (value === currentValue)) // || (!currentValue && index === 0)   
    return <Option 
      key={value} 
      value={ value } label= {p.remark ? `${p.name} (${p.remark})` : p.name} >
      {p.name}{p.remark ? ` (${p.remark})` : ''} 
      { defaultCheck ? <Icon type='check' className='ant-select-selected-icon' /> : '' }
    </Option>
  }) 
  const handleFormSelectInput = (value, fieldName) => {   
    if (!value || (fieldName === 'schoolName' && options.length === 0)) return 
    const fieldObject = {}
    fieldObject[fieldName] = value  
    form.setFieldsValue(fieldObject) 
  }        
  const emphStyles = isEmph ? {fontWeight: 'bold', color: '#F7941D'} : {}
  const emphBorderStyles = isEmph ? {border: '3px #F7941D solid', borderRadius: '5px'} : {}
  return <FormItem 
    // label={label} 
    label={( <span style={emphStyles}>{label} { isRequire && isRegister ? <span style={{ color: 'red', fontSize: '16px',fontWeight: 200 }}>*</span> : ''}</span> )} 
    key={ label }>
    {getFieldDecorator(fieldName, {
      rules: [{ required: isRequire, message: `กรุณาใส่${label}` }],
      initialValue:  
      options[0] && fieldName === 'schoolName' && isRegister ? placeholder : 
        options[0] && (label === 'คำนำหน้า' || fieldName === 'province' || fieldName === 'department') && isRegister && !editInRegister ? options[0].name : 
          options[0] && !editInRegister ? (editUser ? initialValue.province : fieldName === 'province' && !isRegister ? initialValue.id : initialValue ) : 
            initialValue ? initialValue : null,  
      validateTrigger: validateTrigger
    })(
      <Select
        // key = {(i) => i}
        placeholder={ placeholder }
        showSearch={freeInput === true}
        showArrow={showArrow}
        suffixIcon={<Icon type='down' style={{color: '#828282'}}/>}
        optionLabelProp='label'
        style={emphBorderStyles}
        onSearch={(val) => {     
          if(fieldName === 'schoolName' && val) { 
            searchSchool(val)  
          }
          if((val && fieldName !== 'schoolName') || (fieldName === 'schoolName' && isRegister)) { 
          // if(val){
            handleFormSelectInput(val, fieldName)
            handleSelectChange(val) 
          }
        }} 
        
        onInputKeyDown={ (e) => { 
          if (e.keyCode === 13) {   
            if(options[0] && options[0].name) {
              // handleFormSelectInput(options[0].name)
              // handleSelectChange(options[0].name)  
              handleFormSelectInput(e.target.value)
              handleSelectChange(e.target.value)  
              // e.target.blur()
            }  
          }
        }}
        onSelect={(value,option) => {   
          if(handleSelectChange) {
            onSelectSchool(value)
            // handleSelectChange(value)
          } 
        }}
        notFoundContent={fieldName === 'schoolName' ? 'ไม่พบโรงเรียน' : null}
      >
        { option }
      </Select>)}
  </FormItem>
} 
class InputTel extends Component {
  handleNumberChange = (e) => {
    const number = e.target.value ? e.target.value.replace(/\D/g, '') : ''
    this.triggerChange(number);
  }
  triggerChange = (changedValue) => {
    const { onChange, value } = this.props
    if (onChange) {
      onChange(changedValue)
    }
  }
  render() {
    const { suffix, key, disabled, placeholder, maxLength, value } = this.props
    return <Input
      suffix={suffix}
      key={key}
      disabled={disabled}
      placeholder={placeholder}
      maxLength={maxLength}
      onChange={this.handleNumberChange}
      value={value}
    />
  }
}
export const generateInputForm = ( params ) => {
  const { fieldName, form, label, isFormDirty = false, isRequire = true, initialValue = null, limit = 50, isRegister = null, customRules = null, props = null, role = null } = params
  const { getFieldDecorator } = form 
  const validateTrigger = isFormDirty ? 'onChange' : 'onSubmit'
  const NodeInput = props && props.type === 'password' ? Input.Password : (props && props.type === 'number' ? InputTel : Input) 
  return <FormItem className='' 
    label={( <span>{label} { isRequire && isRegister ? <span style={{ color: 'red', fontSize: '16px',fontWeight: 200}}>*</span> : ''}</span> )}  
    key={label}>
    {getFieldDecorator(fieldName, {
      rules: customRules || [
        { required: isRequire, message: `กรุณาใส่${label}` },
      ],
      initialValue,
      validateTrigger: validateTrigger
    })(<NodeInput
      suffix={props && props.type === 'password' ? (<img src={SuffixPasswordIcon}/>) : false}
      key={label}
      disabled = { fieldName === 'inputForEdit' || role && role !== 'admin' && fieldName === 'emailForEdit' ? true : false}
      placeholder= {label}
      maxLength= {limit}
      {...props}
    />)}
  </FormItem>
}
export const generateSelectFormSchool = (params) => {
  const { options, fieldName, form, label, isFormDirty, isRequire, 
    placeholder = '', handleSelectChange = null, isRegister = null, searchSchool = null 
  } = params
  const { getFieldDecorator } = form
  const validateTrigger = isFormDirty ? 'onChange' : 'onSubmit'  
  const currentValue = form.getFieldValue(fieldName) 
  const option = () => options.map((p,i) => {     
    const defaultCheck = ( p._id  && (p._id  === currentValue)) // || (!currentValue && index === 0)   
    return (
      <Option 
        key={i} 
        value={p._id} 
        // value={p.name} 
      >
        {p.name}{p.remark ? ` (${p.remark})` : ''}
        { defaultCheck ? <Icon type='check' className='ant-select-selected-icon' /> : '' } 
      </Option>
    )
  })  
  const handleFormSelectInput = (value) => {      
    form.setFieldsValue({ schoolName: value }) 
  }          
  return <FormItem  
    label={( <span>{label} { isRequire && isRegister ? <span style={{ color: 'red', fontSize: '16px',fontWeight: 200 }}>*</span> : ''}</span> )} 
    key={ label }>
    {getFieldDecorator(fieldName, {
      rules: [{ required: isRequire, message: `กรุณาใส่${label}` }], 
      validateTrigger: validateTrigger
    })(
      <Select  
        placeholder={placeholder}
        showSearch={true}
        showArrow={true}
        suffixIcon={<Icon type='down' style={{color: '#828282'}}/>}
        optionLabelProp='label'
        filterOption={false}
        onSearch={(val) => {      
          searchSchool(val)  
        }}   
        onInputKeyDown={ (e) => {   
          if (e.keyCode === 13) {   
            if(options[0] && options[0].name) { 
              handleFormSelectInput(e.target.value)
              handleSelectChange(e.target.value)   
            }  
          }
        }}
        onSelect={(value) => handleSelectChange(value)}
        notFoundContent={fieldName === 'schoolName' ? 'ไม่พบโรงเรียน' : null}
      >
        { option() }
      </Select>)}
  </FormItem>
}