import React from 'react'
import Lottie from 'react-lottie'

// import json1 from '../srcJson/Map.json'
// import json2 from '../srcJson/Map_effect.json'

import star from '../srcAnimationJson/Star.json'

import './StarAnimation.module.scss'

class StarAnimation extends React.Component {
  static defaultProps = {
    height: 161,
    width: 161
  }

  render () {
    const { width, height } = this.props
    const atomEffect = {
      loop: true,
      autoplay: true,
      animationData: star,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <div className='star-animation' style={{ height }}>
        <Lottie
          options={atomEffect}
          height={height}
          width={width}
        />
      </div>
    )
  }
}

export default StarAnimation
