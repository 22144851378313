/* eslint-disable no-undef */
import React , { Component } from 'react'
import { Button, Row, Col, Empty, DatePicker } from 'antd'
import Chart from 'react-google-charts' 
import moment from 'moment'
  
import lineVerticalTeacher from '../IconSVG/lineVerticalTeacher.svg'
import lineVerticalSuperTeacher from '../IconSVG/lineVerticalSuperTeacher.svg'
import lineVerticalStudent from '../IconSVG/lineVerticalStudent.svg'
import LineVerticalGreenColor from '../IconSVG/LineVerticalGreenColor.svg' 

import styles from './ReportAdmin.module.scss'

export default class Overview extends Component {
  static defaultProps = {
    detail: false,
    type: [] //fix bug
  }
  constructor(props){
    super(props)
    this.state = {
      isOpenDatePicker: false, 
      year: props.year,
      showYearPicker: false
    }
  }
  
  handleYearChanged = async(year) => {   
    await this.setState({ year: parseInt(year) }) 
    await this.props.setYear(this.state.year)
    await this.props.loadStats(this.props.typeStatic, this.props.year)
  }

  onYearSelected = async(e) => {
    e.stopPropagation()
    await this.props.setYear(this.state.year)
    //await this.props.loadStats(this.props.type, this.props.year) // BUG
  }

  loadDetail (month) {
    const { year, detail, type } = this.props 
    console.log('loadDetail')
    if (type) { 
      // this.props.loadType(year, month + 1) 
    }

    if (detail || detail === '') {  
      this.props.loadDetail(year, month + 1, detail)  
    }

    this.props.setMonth(month + 1)
    this.props.history.push(
      `${this.props.match.path}/description`,
      { year }
    ) 
  }

  render () {
    const { data, typeStatic } = this.props
    const { isOpenDatePicker,year } = this.state 
    const dataLength = data.length
    var student = 0, superTeacher = 0, teacher = 0, admin = 0, total = 0

    for (var i = 0; i < dataLength; i++) {
      if (parseInt(data[i].year) === year) {
        student += data[i].student
        superTeacher += data[i].superTeacher
        teacher += data[i].teacher
        admin += data[i].admin
        total += data[i].total
      }
      else {
        student += 0
        superTeacher += 0
        teacher += 0
        admin += 0
        total += 0
      }
    }

    var avgStudent = (student !== 0 && ((student / total) * 100).toFixed(2)) || 0
    var avgSuperTeacher = (superTeacher !== 0 && ((superTeacher / total) * 100).toFixed(2)) || 0
    var avgTeacher = (teacher !== 0 && ((teacher / total) * 100).toFixed(2)) || 0
    var avgAdmin = (admin !== 0 && ((admin / total) * 100).toFixed(2)) || 0
    var avgTotal = (total !== 0 && ((total / total) * 100)) || 0

    var chartData = [
      ['เดือน', 'ครู', 'ครูพิเศษ', 'นักเรียน', 'ผู้ดูแลระบบ'],
      ['ม.ค.', 0, 0, 0, 0],
      ['ก.พ.', 0, 0, 0, 0],
      ['มี.ค.', 0, 0, 0, 0],
      ['เม.ย.', 0, 0, 0, 0],
      ['พ.ค.', 0, 0, 0, 0],
      ['มิ.ย.', 0, 0, 0, 0],
      ['ก.ค.', 0, 0, 0, 0],
      ['ส.ค.', 0, 0, 0, 0],
      ['ก.ย.', 0, 0, 0, 0],
      ['ต.ค.', 0, 0, 0, 0],
      ['พ.ย.', 0, 0, 0, 0],
      ['ธ.ค.', 0, 0, 0, 0],
    ]

    chartData = chartData.map((item, i) => {
      data.forEach((sItem ) => {
        const month = parseInt(sItem.month)

        if (parseInt(sItem.year) === year && (month === i && i !== 0)) {
          item[1] = sItem.teacher
          item[2] = sItem.superTeacher
          item[3] = sItem.student
          item[4] = sItem.admin
        }
        else { 
          return <Empty/>
        }
      })
      return item
    })  
    return (
      <div className={styles.report_admin_overview}>
        <div className='year-selected'> 
          <DatePicker 
            allowClear={false}
            mode='year' 
            format={'YYYY'}
            placeholder={`${year}`}
            value={moment(`${year}`,'YYYY')} 
            open={isOpenDatePicker} 
            onPanelChange={(e) => { 
              this.handleYearChanged(moment(e).format('YYYY'))
              this.setState({isOpenDatePicker: false})
            }}
            onOpenChange={(status) => {
              if(status){
                this.setState({isOpenDatePicker: true})
              } else {
                this.setState({isOpenDatePicker: false})
              }
            }}  
          />
          <Button type='primary' onClick={this.onYearSelected}>
            ค้นหา
          </Button>
        </div>

        <div className='total'>
          <h1>ยอดรวม</h1>

          <Row>
            <Col span={4}>
              <div className='title'>
                <img src={lineVerticalTeacher}/>
                <span>ครู</span>
              </div>

              <div className='statistic'>
                <h1 className='light'>{teacher} { typeStatic === 'exam' ? 'ชุด' : typeStatic === 'account' ? 'บัญชี' : 'ครั้ง' }</h1>
                <h4>{avgTeacher}%</h4>
              </div>
            </Col>
            <Col span={4}>
              <div className='title'>
                <img src={lineVerticalSuperTeacher}/>
                <span>ครูพิเศษ</span>
              </div>

              <div className='statistic'>
                <h1 className='light'>{superTeacher} { typeStatic === 'exam' ? 'ชุด' : typeStatic === 'account' ? 'บัญชี' : 'ครั้ง' }</h1>
                <h4>{avgSuperTeacher}%</h4>
              </div>
            </Col>
            <Col span={4}>
              <div className='title'>
                <img src={lineVerticalStudent}/>
                <span>นักเรียน</span>
              </div>

              <div className='statistic'>
                <h1 className='light'>{student} { typeStatic === 'exam' ? 'ชุด' : typeStatic === 'account' ? 'บัญชี' : 'ครั้ง' }</h1>
                <h4>{avgStudent}%</h4>
              </div>
            </Col>
            <Col span={4}>
              <div className='title'>
                <img src={LineVerticalGreenColor}/>
                <span>ผู้ดูแลระบบ</span>
              </div>

              <div className='statistic'>
                <h1 className='light'>{admin} { typeStatic === 'exam' ? 'ชุด' : typeStatic === 'account' ? 'บัญชี' : 'ครั้ง' }</h1>
                <h4>{avgAdmin}%</h4>
              </div>
            </Col>
            <Col span={4}>
              <div className='title'>
                ยอดรวม
              </div>

              <div className='statistic'>
                <h1 className='light'>{total} { typeStatic === 'exam' ? 'ชุด' : typeStatic === 'account' ? 'บัญชี' : 'ครั้ง' }</h1>
                <h4>{avgTotal}%</h4>
              </div>
            </Col>
          </Row>
        </div>

        <div className='chart'>
          <Chart
            width={800}
            height={400}
            chartType='ColumnChart'
            loader={<div className='chart'>Loading Chart</div>}
            data={chartData}
            options={{
              hAxis: {
                title: year,
                minValue: 0,
                baselineColor: 'white',
                titleTextStyle: {
                  italic: false,
                  fontName: 'Kanit'
                }
              },
              vAxis: {
                title: typeStatic === 'exam' ? 'ชุด' : typeStatic === 'account' ? 'บัญชี' : 'ครั้ง',
                gridlines: {
                  count: 5
                },
                titleTextStyle: {
                  italic: false,
                  fontName: 'Kanit'
                }
              },
              chartArea: { left: 50, top:50, width:'100%', height:'70%' },
              colors: ['#F7941D', '#F7441D', '#1DA9F7', '#18C562'], 
              animation: {
                duration: 500,
                easing: 'linear',
                startup: false,
              },
              isStacked: true,
              legend: 'none',
              bar: { groupWidth: 16 },
              focusTarget: 'category',
              tooltip: {
                isHtml: true
              }
            }}
            chartEvents={[
              {
                eventName: 'select',
                callback: ({ chartWrapper }) => {
                  const chart = chartWrapper.getChart()
                  const selection = chart.getSelection()

                  if (selection.length === 1) {
                    const [selectedItem] = selection
                    chartWrapper.getDataTable()
                    const { row } = selectedItem  
                    localStorage.setItem('month',row)
                    this.loadDetail(row) 
                  }
                }
              }
            ]}
          />
        </div>
      </div>
    )
  }
}
