import React, { Component }  from 'react'
import { connect } from 'react-redux'
import { Button, Breadcrumb } from 'antd' 
import * as moment from 'moment'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver' 

import ExcelIcon from '../IconSVG/ExcelIcon.svg'

import styles from './ReportAdmin.module.scss' 
class Header extends Component {  
  goBack = () => {
    const { history, url }  = this.props
    history.push(`/${url}`)
  }
  

  downloadStatReport = async () => {  
    const { headerName, data, match, url, detail, statType, statistic, data1 } = this.props  
    const monthSelected = this.props.month
    const yearProp = this.props.year
    // const { year, month } = statistic.collection[0]  
    let year,month
    statistic.collection.map((s) => {
      if(s.month === monthSelected) {
        year = s.year
        month = s.month
      }else{
        year = yearProp
        month = monthSelected
      }
    }) 
    let transactions = []
    let transactions2 = []  
    if(match.url === `/${url}/description`) transactions = await this.props.getTransaction(year, month)
    if(statType === 'login') transactions2 = await this.props.getTransaction2(year, month) 
      
    var monthLabel = ''
    switch (month) {
      case 1: monthLabel = 'มกราคม'
        break
      case 2: monthLabel = 'กุมภาพันธ์'
        break
      case 3: monthLabel = 'มีนาคม'
        break
      case 4: monthLabel = 'เมษายน'
        break
      case 5: monthLabel = 'พฤษภาคม'
        break
      case 6: monthLabel = 'มิถุนายน'
        break
      case 7: monthLabel = 'กรกฎาคม'
        break
      case 8: monthLabel = 'สิงหาคม'
        break
      case 9: monthLabel = 'กันยายน'
        break
      case 10: monthLabel = 'ตุลาคม'
        break
      case 11: monthLabel = 'พฤศจิกายน'
        break
      case 12: monthLabel = 'ธันวาคม'
        break
      default: break
    }
    const filename = `${headerName} ${moment().format('DD-MM-YYYY')}.xlsx`
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'   
    if(match.url !== `/${url}/description`){
      let csvData = [
        [`${headerName}  ประจำปี ${year}`],
        ['เดือน','ครู','ครูพิเศษ','นักเรียน','ผู้ดูแลระบบ','รวม'],
        ['มกราคม','0','0','0','0','0'],
        ['กุมภาพันธ์','0','0','0','0','0'],
        ['มีนาคม','0','0','0','0','0'],
        ['เมษายน','0','0','0','0','0'],
        ['พฤษภาคม','0','0','0','0','0'],
        ['มิถุนายน','0','0','0','0','0'],
        ['กรกฎาคม','0','0','0','0','0'],
        ['สิงหาคม','0','0','0','0','0'],
        ['กันยายน','0','0','0','0','0'],
        ['ตุลาคม','0','0','0','0','0'],
        ['พฤศจิกายน','0','0','0','0','0'],
        ['ธันวาคม','0','0','0','0','0'],
      ]  
      if(data.length !== 0){
        data.map((d) => {  
          csvData[d.month - 1 + 2] = [`${csvData[d.month - 1 + 2][0]}`,`${d.teacher}`,`${d.superTeacher}`,`${d.student}`,`${d.admin}`,`${d.total}`]
          return
        })
      }  
      const merge = {
        s:{ r: 0,c: 0 }, e:{ r:0, c: 5 }
      }
      const ws = XLSX.utils.aoa_to_sheet(csvData)
      if(!ws['!merges']) ws['!merges'] = []
      ws['!merges'].push(merge)
        
      const wb = { Sheets: { 'sheet 1': ws }, SheetNames: ['sheet 1' ] } 
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      // eslint-disable-next-line no-undef
      const dataExcel = new Blob([excelBuffer], {type: fileType})
      FileSaver.saveAs(dataExcel, filename)   
    }else{
      //loadDetail
      let resCsv2Data = []
      let resCsv3Data = []
      let resCsv4Data = [] 
      if(statType === 'examSet'){
        //sheet 2
        resCsv2Data = await this.props.loadDetail(year,month,'grade')
        let total = 0
        resCsv2Data.map(res => {
          res.total = res.student + res.teacher + res.superTeacher + res.admin
          total += res.total
        })
        resCsv2Data.map(res => {
          res.percent = parseFloat(((res.total/total)*100).toFixed(2))
        }) 
        total = 0
        //sheet 3
        resCsv3Data = await this.props.loadDetail(year,month,'criterion')
        resCsv3Data.map(res => {
          res.total = res.student + res.teacher + res.superTeacher + res.admin
          total += res.total
        })
        resCsv3Data.map(res => {
          res.percent = parseFloat(((res.total/total)*100).toFixed(2))
        })  
        total = 0
        //sheet 4
        resCsv4Data = await this.props.loadDetail(year,month,'subject') 
        resCsv4Data.map(res => {
          res.total = res.student + res.teacher + res.superTeacher + res.admin
          total += res.total
        })
        resCsv4Data.map(res => {
          res.percent = parseFloat(((res.total/total)*100).toFixed(2))
        })   
      }else if(statType === 'testing' || statType === 'finished'){
        //sheet 2
        let total = 0
        resCsv2Data = await this.props.loadDetail(year,month,'grade')
        resCsv3Data = await this.props.loadDetail(year,month,'subject') 
        resCsv2Data.map(res => {
          res.total = res.student + res.teacher + res.superTeacher + res.admin
          total += res.total
        })
        resCsv2Data.map(res => {
          res.percent = parseFloat(((res.total/total)*100).toFixed(2))
        }) 
        total = 0 
        //sheet 3
        resCsv3Data.map(res => {
          res.total = res.student + res.teacher + res.superTeacher + res.admin
          total += res.total
        })
        resCsv3Data.map(res => {
          res.percent = parseFloat(((res.total/total)*100).toFixed(2))
        })  
      }
      // sheet 1 
      let csvData = [
        [`${headerName} ประจำปี ${year} เดือน ${monthLabel}`], 
      ] 
      var total = 0
      detail.map(item => {
        total += item.count
      })
        
      if(statType === 'login' || statType === 'userAccount'){
        csvData.push(['จังวัด','ภาค','จำนวน','เปอร์เซ็น'])
        let data1Chart = []
        detail.map(item => {
          data1Chart.push([  parseFloat(((item.count / total) * 100).toFixed(2)) ])
        }) 
        let content = []
        detail.map((d,i) => {
          content.push(`${d.province}`,`${d.region}`, `${d.count}`,`${data1Chart[i]}%`)
          csvData.push(content)
          content = []
        })
      }else{
        csvData.push(['ประเภท','นักเรียน','ครู','ครูพิเศษ','แอดมิน','รวมทั้งหมด','เปอร์เซ็น'])  
        let content = [] 
        data1.map((d ) => {
          content.push(`${d.type}`,`${d.student}`,`${d.teacher}`,`${d.superTeacher}`,`${d.admin}`, `${d.total}`,`${d.percent}`)
          csvData.push(content)
          content = []
        })
      }
      let merge = {
        s:{ r: 0,c: 0 }, e:{ r:0, c: 6 }
      } 
      const ws = XLSX.utils.aoa_to_sheet(csvData)
      if(!ws['!merges']) ws['!merges'] = []
      ws['!merges'].push(merge)

      let csvData2 = []
      let csvData3 = []
      let csvData4 = []
      let csvData5 = [] 
      let ws2 = null
      let ws3 = null
      let ws4 = null
      let ws5 = null
      let wb = null
      switch (statType) {
        case 'login' : 
          // sheet 2  
          csvData2 = [ ['ลำดับ','Username','email','ชื่อ','นามสกุล','โรงเรียน','สิทธิ์การใช้งาน (นักเรียน ครู ครูพิเศษ)','วันที่เข้าใช้งาน','เวลาที่เข้าใช้งาน']]
          transactions.map(t => { csvData2.push([`${t.order}`,`${t.username}`,`${t.email}`,`${t.firstName}`,`${t.lastName}`,`${t.schoolName}`,`${t.role === 'teacher' ? 'ครู' : t.role === 'superTeacher' ? 'ครูพิเศษ' : 'นักเรียน'}`,`${t.date}`,`${t.time}`])})
          ws2 = XLSX.utils.aoa_to_sheet(csvData2)
          // sheet 3  
          csvData3 = [ ['ลำดับ','Username','email','ชื่อ','นามสกุล','โรงเรียน','จังหวัด','สิทธิ์การใช้งาน (นักเรียน ครู ครูพิเศษ)','จำนวนครั้งที่ล็อคอิน','วันที่ล็อคอินล่าสุด']]
          transactions2.map(t2 => { csvData3.push([`${t2.order}`,`${t2.username}`,`${t2.email}`,`${t2.firstName}`,`${t2.lastName}`,`${t2.schoolName}`,`${t2.provinceName}`,`${t2.role === 'teacher' ? 'ครู' : t2.role === 'superTeacher' ? 'ครูพิเศษ' : 'นักเรียน'}`,`${t2.loginCount}`,`${t2.lastLoginAt}`])})
          ws3 = XLSX.utils.aoa_to_sheet(csvData3)
          wb = { Sheets: { 'sheet 1': ws, 'sheet 2': ws2, 'sheet 3': ws3 }, SheetNames: ['sheet 1','sheet 2','sheet 3' ] } 
          break
        case 'userAccount' : 
          // sheet 2  
          csvData2 = [ ['ลำดับ','Username','email','ชื่อ','นามสกุล','โรงเรียน','สิทธิ์การใช้งาน (นักเรียน ครู ครูพิเศษ)','วันที่สมัครสมาชิก','เวลาที่สมัครสมาชิก']]
          transactions.map(t => { csvData2.push([`${t.order}`,`${t.username}`,`${t.email}`,`${t.firstName}`,`${t.lastName}`,`${t.schoolName}`,`${t.role === 'teacher' ? 'ครู' : t.role === 'superTeacher' ? 'ครูพิเศษ' : 'นักเรียน'}`,`${t.registeredDate}`,`${t.registeredTime}`])})
          ws2 = XLSX.utils.aoa_to_sheet(csvData2)
          wb = { Sheets: { 'sheet 1': ws, 'sheet 2': ws2 }, SheetNames: ['sheet 1','sheet 2' ] } 
          break
        case 'examSet' : 
          // sheet 2
          csvData2 = [ [`รายงานชุดข้อสอบ ประจำปี ${year} เดือน ${monthLabel}`],['ระดับชั้น','ประเภท','นักเรียน','ครู','ครูพิเศษ','ผู้ดูแลระบบ','รวมทั้งหมด','เปอร์เซ็น',] ]
          resCsv2Data.map(res => { csvData2.push([`${res.grade}`,`${res.type === 'G' ? 'ตามหลักสูตร' : res.type === 'C' ? 'แข่งขัน' : res.type === 'CUSTOM' ? 'กำหนดเอง' : res.type === 'CAT' ? 'CAT' : (res.type === 'EX' || res.type === 'E') ? 'แบบฝึกหัด' : '-' }`,`${res.student}`,`${res.teacher}`,`${res.superTeacher}`,`${res.admin}`,`${res.total}`,`${res.percent}%`,]) })
          ws2 = XLSX.utils.aoa_to_sheet(csvData2)
          // sheet 3
          csvData3 = [ [`รายงานชุดข้อสอบ ประจำปี ${year} เดือน ${month}`],['เกณฑ์การวัดผล','ประเภท','นักเรียน','ครู','ครูพิเศษ','ผู้ดูแลระบบ','รวมทั้งหมด','เปอร์เซ็น'] ]
          resCsv3Data.map(res => { csvData3.push([`${res.criterion === 'none' ? 'ไม่ระบุ' : res.criterion === 'lesson'? 'บทเรียน' : res.criterion === 'indicator'? 'ตัวชี้วัด' : 'สาระการเรียนรู้'}`,`${res.type === 'G' ? 'ตามหลักสูตร' : res.type === 'C' ? 'แข่งขัน' : res.type === 'CUSTOM' ? 'กำหนดเอง' : res.type === 'CAT' ? 'CAT' : (res.type === 'EX' || res.type === 'E') ? 'แบบฝึกหัด' : '-' }`,`${res.student}`,`${res.teacher}`,`${res.superTeacher}`,`${res.admin}`,`${res.total}`,`${res.percent}%`]) })
          ws3 = XLSX.utils.aoa_to_sheet(csvData3)
          // sheet 4
          csvData4 = [ [`รายงานชุดข้อสอบ ประจำปี ${year} เดือน ${month}`],['วิชา','ประเภท','นักเรียน','ครู','ครูพิเศษ','ผู้ดูแลระบบ','รวมทั้งหมด','เปอร์เซ็น',] ]
          resCsv4Data.map(res => { csvData4.push([`${res.subject}`,`${res.type === 'G' ? 'ตามหลักสูตร' : res.type === 'C' ? 'แข่งขัน' : res.type === 'CUSTOM' ? 'กำหนดเอง' : res.type === 'CAT' ? 'CAT' : (res.type === 'EX' || res.type === 'E') ? 'แบบฝึกหัด' : '-' }`,`${res.student}`,`${res.teacher}`,`${res.superTeacher}`,`${res.admin}`,`${res.total}`,`${res.percent}%`,]) })
          ws4 = XLSX.utils.aoa_to_sheet(csvData4)
          // sheet 5
          csvData5 = [ ['ลำดับ','Username','email','ชื่อ','นามสกุล','โรงเรียน','สิทธิ์การใช้งาน (นักเรียน ครู ครูพิเศษ)','รหัสชุดข้อสอบ','ชื่อชุดข้อสอบ','ประเภทการจัดชุด (ตามหลักสูตร, โครงการแข่งขัน (ให้แสดงเป็นชื่อโครงการแข่งขันเลย), CAT, กำหนดเอง)','ปีที่แข่งขัน (แสดงเฉพาะข้อสอบโครงการแข่งขัน ข้อประเภทประเภทอื่นให้แสดงเป็น “-” )','วิชา','ระดับชั้น','เกณฑ์การจัดชุด','จำนวนข้อ','จำนวนครั้งที่ถูกทำ','วันที่จัดชุด','เวลาที่จัดชุด ']]
          transactions.map(t => { csvData5.push([`${t.order}`,`${t.username ? t.username : ''}`,`${t.email}`,`${t.firstName}`,`${t.lastName}`,`${t.schoolName}`,`${t.role === 'teacher' ? 'ครู' : t.role === 'superTeacher' ? 'ครูพิเศษ' : 'นักเรียน'}`,`${t.code}`,`${t.name}`,`${t.type === 'G' ? 'ตามหลักสูตร' : t.type === 'C' ? 'แข่งขัน' : t.type === 'CAT' ? 'CAT' : t.type === 'CUSTOM' ? 'กำหนดเอง' : (t.type === 'EX' || t.type === 'E') ? 'แบบฝึกหัด' : '-'}`,`${t.competitionYears}`,`${t.subject}`,`${t.grade}`,`${t.criterion === 'none' ? 'ไม่ระบุ' : t.criterion === 'lesson' ? 'บทเรียน': 'ตัวชี้วัด'}`,`${t.quantity}`,`${t.testingCount}`,`${t.date}`,`${t.time}`])}) 
          ws5 = XLSX.utils.aoa_to_sheet(csvData5)

          wb = { Sheets: { 'sheet 1': ws, 'sheet 2': ws2,'sheet 3': ws3,'sheet 4': ws4,'sheet 5': ws5 }, SheetNames: ['sheet 1','sheet 2','sheet 3','sheet 4','sheet 5' ] } 
          break
        case 'testing': 

          csvData2 = [ [`สถิติผู้ทำข้อสอบ ประจำปี ${year} เดือน ${month}`],['ระดับชั้น','ประเภท','นักเรียน','ครู','ครูพิเศษ','ผู้ดูแลระบบ','รวมทั้งหมด','เปอร์เซ็น',] ]
          resCsv2Data.map(res => { csvData2.push([`${res.grade}`,`${res.type === 'G' ? 'ตามหลักสูตร' : res.type === 'C' ? 'แข่งขัน' : res.type === 'CUSTOM' ? 'กำหนดเอง' : (res.type === 'EX' || res.type === 'E') ? 'แบบฝึกหัด' : res.type === 'CAT' ? 'CAT' : '-'}`,`${res.student}`,`${res.teacher}`,`${res.superTeacher}`,`${res.admin}`,`${res.total}`,`${res.percent}%`,]) })
          ws2 = XLSX.utils.aoa_to_sheet(csvData2)

          csvData3 = [ [`สถิติผู้ทำข้อสอบ ประจำปี ${year} เดือน ${month}`],['วิชา','ประเภท','นักเรียน','ครู','ครูพิเศษ','ผู้ดูแลระบบ','รวมทั้งหมด','เปอร์เซ็น'] ]
          resCsv3Data.map(res => { csvData3.push([`${res.subject}`,`${res.type === 'G' ? 'ตามหลักสูตร' : res.type === 'C' ? 'แข่งขัน' : res.type === 'CUSTOM' ? 'กำหนดเอง' : (res.type === 'EX' || res.type === 'E') ? 'แบบฝึกหัด' : res.type === 'CAT' ? 'CAT' : '-'}`,`${res.student}`,`${res.teacher}`,`${res.superTeacher}`,`${res.admin}`,`${res.total}`,`${res.percent}%`,]) })
          ws3 = XLSX.utils.aoa_to_sheet(csvData3)

          csvData4 = [ ['ลำดับ','Username','email','ชื่อ','นามสกุล','โรงเรียน','สิทธิ์การใช้งาน (นักเรียน ครู ครูพิเศษ)','รหัสชุดข้อสอบ ','ชื่อชุดข้อสอบ','ประเภทการจัดชุด (ตามหลักสูตร, โครงการแข่งขัน (ให้แสดงเป็นชื่อโครงการแข่งขันเลย), CAT, กำหนดเอง)','ปีที่แข่งขัน (แสดงเฉพาะข้อสอบโครงการแข่งขัน ข้อประเภทประเภทอื่นให้แสดงเป็น “-” )','วิชา','ระดับชั้น','เกณฑ์การจัดชุด','จำนวนข้อ','วันที่ทำข้อสอบ','เวลาที่ทำข้อสอบ']]
          transactions.map(t => { csvData4.push([`${t.order}`,`${t.username ? t.username : ''}`,`${t.email}`,`${t.firstName}`,`${t.lastName}`,`${t.schoolName}`,`${t.role === 'teacher' ? 'ครู' : t.role === 'superTeacher' ? 'ครูพิเศษ' : 'นักเรียน'}`,`${t.code}`,`${t.name}`,`${t.type === 'G' ? 'ตามหลักสูตร' : t.type === 'C' ? 'แข่งขัน' : t.type === 'CAT' ? 'CAT' : t.type === 'CUSTOM' ? 'กำหนดเอง' : (t.type === 'EX' || t.type === 'E') ? 'แบบฝึกหัด' : '-'}`,`${t.competitionYears}`,`${t.subject}`,`${t.grade}`,`${t.criterion === 'none' ? 'ไม่ระบุ' : t.criterion === 'lesson' ? 'บทเรียน': 'ตัวชี้วัด'}`,`${t.quantity}` ,`${t.date}`,`${t.time}`])})
          ws4 = XLSX.utils.aoa_to_sheet(csvData4)
            
          wb = { Sheets: { 'sheet 1': ws, 'sheet 2': ws2, 'sheet 3': ws3, 'sheet 4': ws4 }, SheetNames: ['sheet 1','sheet 2','sheet 3','sheet 4'] } 
          break
        case 'finished':

          csvData2 = [ [`สถิติผู้ส่งข้อสอบ ประจำปี ${year} เดือน ${month}`],['ระดับชั้น','ประเภท','นักเรียน','ครู','ครูพิเศษ','ผู้ดูแลระบบ','รวมทั้งหมด','เปอร์เซ็น',] ]
          resCsv2Data.map(res => { csvData2.push([`${res.grade}`,`${res.type === 'G' ? 'ตามหลักสูตร' : res.type === 'C' ? 'แข่งขัน' : res.type === 'CUSTOM' ? 'กำหนดเอง' : (res.type === 'EX' || res.type === 'E') ? 'แบบฝึกหัด' : res.type === 'CAT' ? 'CAT' : '-'}`,`${res.student}`,`${res.teacher}`,`${res.superTeacher}`,`${res.admin}`,`${res.total}`,`${res.percent}%`,]) })
          ws2 = XLSX.utils.aoa_to_sheet(csvData2)

          csvData3 = [ [`สถิติผู้ส่งข้อสอบ ประจำปี ${year} เดือน ${month}`],['วิชา','ประเภท','นักเรียน','ครู','ครูพิเศษ','ผู้ดูแลระบบ','รวมทั้งหมด','เปอร์เซ็น'] ]
          resCsv3Data.map(res => { csvData3.push([`${res.subject}`,`${res.type === 'G' ? 'ตามหลักสูตร' : res.type === 'C' ? 'แข่งขัน' : res.type === 'CUSTOM' ? 'กำหนดเอง' : (res.type === 'EX' || res.type === 'E') ? 'แบบฝึกหัด' : res.type === 'CAT' ? 'CAT' : '-'}`,`${res.student}`,`${res.teacher}`,`${res.superTeacher}`,`${res.admin}`,`${res.total}`,`${res.percent}%`,]) })
          ws3 = XLSX.utils.aoa_to_sheet(csvData3)

          csvData4 = [ ['ลำดับ','Username','email','ชื่อ','นามสกุล','โรงเรียน','สิทธิ์การใช้งาน (นักเรียน ครู ครูพิเศษ)','รหัสชุดข้อสอบ ','ชื่อชุดข้อสอบ','ประเภทการจัดชุด (ตามหลักสูตร, โครงการแข่งขัน (ให้แสดงเป็นชื่อโครงการแข่งขันเลย), CAT, กำหนดเอง)','ปีที่แข่งขัน (แสดงเฉพาะข้อสอบโครงการแข่งขัน ข้อประเภทประเภทอื่นให้แสดงเป็น “-” )','วิชา','ระดับชั้น','เกณฑ์การจัดชุด','จำนวนข้อ','วันที่ทำข้อสอบ','เวลาที่ทำข้อสอบ']]
          transactions.map(t => { csvData4.push([`${t.order}`,`${t.username ? t.username : ''}`,`${t.email}`,`${t.firstName}`,`${t.lastName}`,`${t.schoolName}`,`${t.role === 'teacher' ? 'ครู' : t.role === 'superTeacher' ? 'ครูพิเศษ' : 'นักเรียน'}`,`${t.code}`,`${t.name}`,`${t.type === 'G' ? 'ตามหลักสูตร' : t.type === 'C' ? 'แข่งขัน' : t.type === 'CAT' ? 'CAT' : t.type === 'CUSTOM' ? 'กำหนดเอง' : (t.type === 'EX' || t.type === 'E') ? 'แบบฝึกหัด' : '-'}`,`${t.competitionYears}`,`${t.subject}`,`${t.grade}`,`${t.criterion === 'none' ? 'ไม่ระบุ' : t.criterion === 'lesson' ? 'บทเรียน': 'ตัวชี้วัด'}`,`${t.quantity}` ,`${t.date}`,`${t.time}`])})
          ws4 = XLSX.utils.aoa_to_sheet(csvData4)
            
          wb = { Sheets: { 'sheet 1': ws, 'sheet 2': ws2, 'sheet 3': ws3, 'sheet 4': ws4 }, SheetNames: ['sheet 1','sheet 2','sheet 3','sheet 4'] } 
          break
        default : return
      }  
          
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      // eslint-disable-next-line no-undef
      const dataExcel = new Blob([excelBuffer], {type: fileType})
      FileSaver.saveAs(dataExcel, filename)   
    }  
  }
  render(){ 
    const { headerName, match , url, month, year } = this.props     
    var monthLabel = '' 
    switch (month) {
      case 1: monthLabel = 'มกราคม'
        break
      case 2: monthLabel = 'กุมภาพันธ์'
        break
      case 3: monthLabel = 'มีนาคม'
        break
      case 4: monthLabel = 'เมษายน'
        break
      case 5: monthLabel = 'พฤษภาคม'
        break
      case 6: monthLabel = 'มิถุนายน'
        break
      case 7: monthLabel = 'กรกฎาคม'
        break
      case 8: monthLabel = 'สิงหาคม'
        break
      case 9: monthLabel = 'กันยายน'
        break
      case 10: monthLabel = 'ตุลาคม'
        break
      case 11: monthLabel = 'พฤศจิกายน'
        break
      case 12: monthLabel = 'ธันวาคม'
        break
      default: break
    } 
    const headerBar = (
      <div className='flex-space-between'>
        {
          match.url !== `/${url}/description` ?
            <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>{ headerName ? headerName : ''}</h1>
            :
            <Breadcrumb>
              <Breadcrumb.Item>
                <span onClick={e => {
                  e.stopPropagation()
                  this.goBack()
                }} style={{ cursor: 'pointer', textDecorationLine: 'underline', color: '#006FAD' }}>{headerName}</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{monthLabel} {year}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
        }

        <div style={{display: 'flex'}}>
          <Button type='primary' onClick={this.downloadStatReport}> 
            <img src={ExcelIcon} alt='icon-exel' />Export Excel
          </Button>
        </div>
      </div>
    )

    return (
      <div className={styles.report_admin_header}>
        { headerBar }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    statistic: state.statistic
  }
}

export default connect(mapStateToProps)(Header)
