import React, { Component } from 'react'
import {
  Modal,
  Button
} from 'antd'
import Download from 'downloadjs'
import styles from './Group.module.scss'
class ModalQRCode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false
    }
  }

  handleOk = async () => {
    await Download(this.props.imgQRCode, 'QRCode.JPEG', 'text/plain')
    this.props.onCancel()
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  render(){
    const { isVisible, imgQRCode } = this.props

    return(
      <Modal
        // title='Basic Modal'
        visible={isVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        cancelButtonProps={false}
        footer={[
          <Button 
            key='submit' 
            type='primary' 
            onClick={this.handleOk}
            className = {styles.btnsaveGroup}>
            บันทึกรูปภาพ
          </Button>,
        ]}
      >
        <div className = {styles.divQRModal}>
          <img src={imgQRCode} alt='QRcode' id='QRCodeImage' className = {styles.imgQRModal}/>
          <p className = {styles.txtQRModal}>นักเรียนสามารถเข้าร่วมกลุ่มได้โดยการสแกนคิวอาร์โค้ดนี้</p>
        </div>
      </Modal>
    )
  }
}

export default ModalQRCode