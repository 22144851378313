import React, { Component }  from 'react'
import styles from './Testing.module.scss'
import CorrectIcon from '../IconSVG/Correct.svg'
import IncorrectIcon from '../IconSVG/Incorrect.svg'
import { displayer } from './helper'

class OptionChoice extends Component {

  render(){
    const { onClickChoice, answer, choose, isSolution } = this.props 
    return (
      <div className='option-choice'>
        {
          isSolution ? (
            answer.key ?
              <img src={CorrectIcon} width={'60%'} height={'60%'} />
              : (answer.seq === choose ?
                <img src={IncorrectIcon} width={'60%'} height={'60%'} />
                : '')
          ) : ''
        }

        <div onClick={() => !isSolution && onClickChoice(answer.seq) }
          className={choose === answer.seq ? styles.choice_picked : styles.choice_test}
        >
          {displayer(answer.text)}
        </div>
      </div>
    )
  }
}

export default OptionChoice
