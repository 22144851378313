exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Departments_inputSearch__2jBK_ input {\n  width: 393px;\n  margin-right: 16px;\n  border-radius: 20px;\n  border-color: #015EA5; }\n\n.Departments_icon_row__1Uiy3 img {\n  cursor: pointer; }\n\n.Departments_button_save_modal__3kVK3 {\n  float: right;\n  width: 70%; }\n\n.Departments_header_modal__28J2a {\n  font-weight: 500;\n  font-size: 28px;\n  color: #05007A; }\n\n.Departments_header_delete_modal__2upA4 h1 {\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 29px; }\n\n@media (max-width: 1440px) {\n  .Departments_modal__zNxou {\n    width: 40%; }\n  .Departments_button_save_modal__3kVK3 {\n    height: 32px; } }\n", ""]);

// Exports
exports.locals = {
	"inputSearch": "Departments_inputSearch__2jBK_",
	"icon_row": "Departments_icon_row__1Uiy3",
	"button_save_modal": "Departments_button_save_modal__3kVK3",
	"header_modal": "Departments_header_modal__28J2a",
	"header_delete_modal": "Departments_header_delete_modal__2upA4",
	"modal": "Departments_modal__zNxou"
};