import React, { Component } from 'react'
import { 
  Col, 
} from 'antd'
import styles from './LayoutGuest.module.scss'
import DevicePicOnAboutUs from '../IconSVG/DevicePicOnAboutUs.svg'
class AboutUsComponent extends Component{

  render(){
    return(
      <div>
        <Col sm = {14} xs = {24}>
          <div style = {{zIndex : 10}}> 
            <p className = {styles.titleOnlineTestingSystem}>ระบบการสอบออนไลน์ (Online Testing System)</p>
            <p className = {styles.textOnlineTestingSystem}>
                เป็นระบบหลักระบบหนึ่งในแพลตฟอร์มดิจิทัลเพื่อการเรียนรู้และพัฒนาตนเอง
              <br/> ด้านวิทยาศาสตร์ คณิตศาสตร์ และเทคโนโลยี สสวท. ที่ให้บริการแก่ ครู นักเรียน และผู้สนใจ 
              <br/> ในการทำแบบทดสอบความรู้ด้านวิทยาศาสตร์ คณิตศาสตร์ และเทคโนโลยี
              <br/> ทั้งในส่วนของข้อสอบตามหลักสูตรแกนกลางฯ ที่สอดคล้องกับตัวชี้วัด 
              <br/> และข้อสอบแข่งขันโครงการต่าง ๆ เช่น โครงการพัฒนาอัจฉริยภาพทางวิทยาศาสตร์และคณิตศาสตร์ โครงการ TEDET เป็นต้น
              <br/>
              <br/>ผู้ใช้งานสามารถเลือกวิธีการจัดชุดข้อสอบแบบออนไลน์ทั่วไป (Computer-Based Test - CBT) 
              <br/>หรือจัดชุดข้อสอบแบบปรับเหมาะตามความสามารถ (Computerized Adaptive Test - CAT) ได้
              <br/>โดยระบบอำนวยความสะดวกในการตรวจข้อสอบ แสดงผลคะแนนสอบ และประเมินผลการทดสอบ 
              <br/>เบ็ดเสร็จทันที
              <br/>
              <br/>นอกจากนี้ระบบยังได้มีการเก็บข้อมูลข้อสอบแบบรวมศูนย์การเก็บสถิติข้อมูลการใช้ข้อสอบ
              <br/>รวมไปถึงการออกรายงานเกี่ยวกับข้อสอบด้วย</p>
          </div>
        </Col>
        <Col sm = {10} xs = {24}>
          <div className = {styles.divPictureAboutUs} >
            <img alt='device-pic-on-about-us' src = {DevicePicOnAboutUs} className = {styles.devicePicOnAboutUs} />
          </div>
        </Col>
      </div>

    )
  }
}
export default AboutUsComponent