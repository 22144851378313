import React, { Component, Fragment }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getCompetitionsList } from '../../../../redux/competitions/action'
import {
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Modal,
  Input,
  Tooltip
} from 'antd'
import { 
  IconExamBankLock,
  IconExamBankUnlock,
} from '../../../IconSVG'
import styles from '../../Exam.module.scss'
import '../CreateExamSet.scss'
const { Option } = Select

const labelCol = {
  sm: 10,
  md: 8,
}
const inputCol = {
  sm: 14,
  md: 16,
}
const levelOptions = ['ง่าย', 'ปานกลาง', 'ยาก']

const genSelect = ( label, options, onChange, value, nameAsValue = false ) => {
  const option = options.map( (p, index) => <Option className='optionSelect' key={index} value={nameAsValue ? p : index}><Tooltip placement='top' title={p}>{p}</Tooltip></Option> )
  const style = label === 'หนังสือเรียน' ? {width: '400px'} : null
  return (
    <Fragment>
      <Col className='textLabel' {...labelCol}>{label}</Col>
      <Col className='inputField' {...inputCol}>
        <Select
          placeholder='กรุณาเลือก'
          className='inputSelect'
          value={value}
          onChange={onChange}
          style={style}
        >
          {option}
        </Select>
      </Col>
    </Fragment>
  )
}

class StepLesson extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowCompetitionYearModal: false,
      // competitionNameList: [],
      isSetInitial: false,
      yearList: [],
      yearsSelected: [],
      yearFrom: [],
      yearTo: []
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.yearsSelected != prevProps.yearsSelected) {
      const yearFrom = this.props.yearsSelected[0]
      const yearTo = this.props.yearsSelected[1]
      this.setState({ yearFrom, yearTo })
    }
  }
   
  onExamBankChange = (examBank) => {
    this.props.onChangeLesson({ examBank: examBank})
  }

  onSubjectChange = (subject) => {
    this.props.onChangeLesson({ subject: subject})
  }

  onBookChange = (book) => {
    this.props.onChangeLesson({ book: book})
  }

  onGradeChange = (grade) => {
    this.props.onChangeLesson({ grade: grade})
  }

  onLevelChange = (level) => {
    this.props.onChangeLesson({ level: level})
  }

  onCompetitionNameChange = (competitionIndex) => { 
    // this.conditionIpstCompetition()
    // this.conditionIpstCompetition(competitionIndex)
    this.props.onChangeLesson({ competitionIndex, competitionYear: [] })
  }

  onCompetitionYearChange = (competitionYear) => {
    this.props.onChangeLesson({ competitionYear: competitionYear })
  }

  onChooseCompetitionYear = () => {
    this.setState({ isShowCompetitionYearModal: true })
  }

  // updateInitialYearSelected = async () => {
  //   const { competitionList, competitionIndex, subject, grade } = this.props
  //   if (competitionList.length === 0) return []
  //   if (competitionList[competitionIndex].name === 'โครงการอัจฉริยภาพ') {
  //     if (subject === 'คณิตศาสตร์' && grade === 'ป.3') await this.props.onChangeLesson({ yearsSelected: ['2544', '2545'] })
  //     if (subject === 'คณิตศาสตร์' && grade === 'ป.6') await this.props.onChangeLesson({ yearsSelected: ['2544', '2560'] })
  //     if (subject === 'วิทยาศาสตร์' && grade === 'ป.3') await this.props.onChangeLesson({ yearsSelected: ['2553', '2560'] })
  //     if (subject === 'วิทยาศาสตร์' && grade === 'ป.6') await this.props.onChangeLesson({ yearsSelected: ['2553', '2560'] })
  //   } else if (competitionList[competitionIndex].name === 'ข้อสอบ TIMSS') {
  //     if (subject === 'คณิตศาสตร์' && grade === 'ป.4') await this.props.onChangeLesson({ yearsSelected: ['2554', '2554'] })
  //     if (subject === 'คณิตศาสตร์' && grade === 'ม.2') await this.props.onChangeLesson({ yearsSelected: ['2550', '2554'] })
  //   } else if (competitionList[competitionIndex].name === 'โครงการ TEDET') {
  //     if (subject === 'คณิตศาสตร์' && grade === 'ป.3') await this.props.onChangeLesson({ yearsSelected: ['2553', '2554'] })
  //     if (subject === 'คณิตศาสตร์' && grade === 'ป.6') await this.props.onChangeLesson({ yearsSelected: ['2553', '2553'] })
  //     if (subject === 'คณิตศาสตร์' && grade === 'ม.3') await this.props.onChangeLesson({ yearsSelected: ['2553', '2553'] })
  //   } else {
  //     await this.props.onChangeLesson({ yearsSelected: [competitionList[competitionIndex].years[0], competitionList[competitionIndex].years[competitionList[competitionIndex].years.length-1]] })
  //   }
  // }

  setUpCompetitionYearsSelection = () => {
    const { competitionList, competitionIndex, subject, grade } = this.props 
    if (competitionList.length === 0) return []
    if (competitionList[competitionIndex].name === 'โครงการอัจฉริยภาพ') {
      if (subject === 'คณิตศาสตร์' && grade === 'ป.3') return ['2544', '2545', '2553', '2554', '2555', '2557', '2558', '2559', '2560', '2561', '2562']
      if (subject === 'คณิตศาสตร์' && grade === 'ป.6') return ['2544', '2545', '2553', '2554', '2555', '2556', '2557', '2558', '2559', '2560', '2561', '2562']
      if (subject === 'วิทยาศาสตร์' && grade === 'ป.3') return ['2545', '2546', '2547', '2548', '2549', '2550', '2551', '2552', '2553', '2554', '2555', '2556', '2557', '2558', '2559', '2560', '2561', '2562']
      if (subject === 'วิทยาศาสตร์' && grade === 'ป.6') return ['2545', '2546', '2547', '2548', '2549', '2550', '2551', '2552', '2553', '2554', '2555', '2556', '2557', '2558', '2559', '2560', '2561', '2562']
    } else if (competitionList[competitionIndex].name === 'ข้อสอบ TIMSS') {
      if (subject === 'คณิตศาสตร์' && grade === 'ป.4') return ['2554']
      if (subject === 'คณิตศาสตร์' && grade === 'ม.2') return ['2550', '2554']
    } else if (competitionList[competitionIndex].name === 'โครงการ TEDET') {
      if (subject === 'คณิตศาสตร์' && grade === 'ป.3') return ['2553', '2554']
      if (subject === 'คณิตศาสตร์' && grade === 'ป.6') return ['2553']
      if (subject === 'คณิตศาสตร์' && grade === 'ม.3') return ['2553']
    } else { 
      return competitionList[competitionIndex].years
    }
  }

  setValuesOfYears = (year, i) => {
    if (i == 0) {
      const yearFrom = year
      this.props.setValuesOfYears(year, i)
      this.setState({ yearFrom })
    }
    else if (i == 1) {
      const yearTo = year
      this.props.setValuesOfYears(year, i)
      this.setState({ yearTo })
    }
  }

  render() {
    const { name, examType, isShowExamBank, isCurriculum, examBank, subjectList, 
      bookList, subject, book, grade, grades, level, competitionIndex, competitionYear,
      currentRole, setValuesOfYears, yearsSelected, onChangeCondition, competitionList } = this.props
    const { isShowCompetitionYearModal, yearFrom, yearTo } = this.state
    // let { yearList } = this.state
    // yearList = this.setUpCompetitionYearsSelection()
    const yearList = competitionYear
    const inputExamBank = isShowExamBank ? (
      <Fragment>
        <Col className='textLabel' {...labelCol}>เกณฑ์การจัดข้อสอบ</Col>
        <Col className='inputField' {...inputCol}>
          <div className={styles.input_swich_btn}>
            <Button
              type={examBank === 'Private' ? 'primary' : 'secondary'}
              className={ styles.btn_bank_left } 
              onClick={() => this.onExamBankChange('Private')}
            >
              <IconExamBankLock/>
              คลังปิด
            </Button>
            <Button
              type={examBank === 'Public' ? 'primary' : 'secondary'}
              className={ styles.btn_bank_right } 
              onClick={() => this.onExamBankChange('Public')}
            >
              <IconExamBankUnlock/>
              คลังเปิด
            </Button>
          </div>
        </Col>
      </Fragment>
    ) : null
   
    // console.log("===== subjectList =====")
    // console.log(subjectList)
    // console.log(examType)
    // console.log(subject)
    // console.log("===== subjectList =====")
    const inputSubject = 
      examType === 'competition' ? 
        genSelect('วิชา', subjectList.filter(s => s.isCompetitionActive).map(s => s.name), this.onSubjectChange, subject , true ) : 
        (examType === 'curriculumbook' ? 
          genSelect('วิชา', subjectList.filter(s => s.isExerciseActive).map(s => s.name), this.onSubjectChange, subject , true ) : genSelect('วิชา', subjectList.filter(s => s.isActive).map(s => s.name), this.onSubjectChange, subject , true ))
 
    const inputBook = examType === 'curriculumbook' ? genSelect('หนังสือเรียน', bookList.map(s => s.name), this.onBookChange, book , true ) : []
    
    const inputName = (
      <div>
        <Col className='textLabel' {...labelCol} >ชื่อข้อสอบ</Col>
        <Col className='inputField' {...inputCol} >
          <Input className={ styles.input_name_cat_exam } placeholder={ name ? name : `${subject} ${grade}` } onChange={ e => onChangeCondition({'name': e.target.value})} maxLength={100}/> 
          {/* <Input style={{width: 180}} maxLength={100}/> */}
        </Col>
      </div>
    )
    const inputGrade = genSelect(examType !== 'cat' ? 'ระดับชั้น': 'ช่วงชั้น', ['custom', 'curriculumbook'].includes(examType) ? grades.concat(['ม.4','ม.5','ม.6']) : grades, this.onGradeChange, grade, true )
    const inputLevel = (
      <Fragment>
        <Col className='textLabel' {...labelCol}>ระดับของข้อสอบ</Col>
        <Col className='inputField' {...inputCol}>
          <Checkbox.Group className='inputCheckGroup' options={levelOptions} value={level} onChange={this.onLevelChange} />
        </Col>
      </Fragment>
    )  
    const inputCompetition = (
      <Fragment>
        {/* {genSelect('โครงการที่แข่ง', competitionList.filter(c => currentRole !== 'admin' ? c.isActive : true ).map(c => c.name), this.onCompetitionNameChange, competitionIndex, false )} */}
        {genSelect('โครงการที่แข่ง', competitionList.map(c => c.name), this.onCompetitionNameChange, competitionIndex, false )}
      </Fragment>
    ) 
    const inputYearsCompetition = (
      <div className={ styles.select_year_competition }>
        <Col className='textLabel' {...labelCol}>ปีที่แข่งขัน</Col>
        <Col className='inputField' {...inputCol}>
          <div className={ styles.select_year_competition_group } >
            <div className={ styles.start_year }>
              {
                <Select
                  onChange={value => { this.setValuesOfYears(value, 0) }}
                  value={yearFrom}
                  placeholder='เลือกปี'
                  className={styles.select_start}
                  key='firstYear'>
                  {
                    yearList ? yearList.map((years, i) => (<Option key={i} value={yearList[i]}>{years}</Option>)) : ''
                  }
                </Select>
              }
              {/* {
                yearList ? 
                  <Select 
                    onChange={value => { setValuesOfYears(value,0) }}  
                    // defaultValue={yearsDefault} 
                    defaultValue={ yearList[0] ? yearList[0] : 'เลือกปี' } 
                    className={ styles.select_start }
                    // style={{ width: '80px', color: '#828282' }} 
                    key='firstYear'>
                    {
                      yearList ? yearList.map((years,i) => (<Option key={i} value={yearList[i]}>{years}</Option>)) : ''
                    }
                  </Select>
                  : <Select 
                    onChange={value => { setValuesOfYears(value,0) }}  
                    // defaultValue={yearsDefault} 
                    defaultValue={ 'เลือกปี' } 
                    className={ styles.select_start }
                    // style={{ width: '80px', color: '#828282' }} 
                    key='firstYear'>
                    {
                      yearList ? yearList.map((years,i) => (<Option key={i} value={yearList[i]}>{years}</Option>)) : ''
                    }
                  </Select>
              } */}
              
            </div>
            <div className={ styles.year_to_year } ><span>ถึง</span></div>
            <div className={ styles.end_year } >
              {
                <Select
                  onChange={value => { this.setValuesOfYears(value, 1) }}
                  value={yearTo}
                  placeholder='เลือกปี'
                  className={styles.select_end}
                  key='secondYears'>
                    {
                      yearList ? yearList.map((years,i) =>  {
                        if(years >= yearFrom){
                          return (
                            <Option key={i} value={yearList[i]}>{years }</Option>
                          )
                        }
                        return
                      } ) : ''
                    }
                </Select>
              }
              {/* {
                yearList ? 
                  <Select 
                    onChange={value => { setValuesOfYears(value,1) }} 
                    // defaultValue={lastYearDefalut} 
                    defaultValue={yearList[0] ? yearList[yearList.length - 1] : 'เลือกปี'} 
                    className={ styles.select_end }
                    // style={{ width: '80px', color: '#828282' }} 
                    key='secondYears'>
                    {
                      yearList ? yearList.map((years,i) =>  {
                        if(years >= yearsSelected[1]){
                          return (
                            <Option key={i} value={yearList[i]}>{years }</Option>
                          )
                        }
                        return
                      } ) : ''
                    }
                  </Select>
                  : <Select 
                    onChange={value => { setValuesOfYears(value,1) }} 
                    // defaultValue={lastYearDefalut} 
                    defaultValue={ 'เลือกปี'} 
                    className={ styles.select_end }
                    // style={{ width: '80px', color: '#828282' }} 
                    key='secondYears'>
                    {
                      yearList ? yearList.map((years,i) =>  {
                        if(years >= yearsSelected[1]){
                          return (
                            <Option key={i} value={yearList[i]}>{years }</Option>
                          )
                        }
                        return
                      } ) : ''
                    }
                  </Select>
              } */}
              
            </div>
          </div>
          
          {/* <Button type='secondary' onClick={this.onChooseCompetitionYear}>
            <IconExamSetCompetition style={{fontSize: 24, verticalAlign: 'sub'}} /> ปีที่แข่งขัน
          </Button> */}
        </Col>
      </div>
    )
    const checkBoxYears = (yearList && yearList.map( y => (<Fragment key={y} ><Checkbox value={y} style={{marginBottom: 28}}>{y}</Checkbox><br/></Fragment>))) || null
    const customFill = (
      <div>
        {inputSubject}
        {inputGrade}
      </div>
    ) 
    return (
      <div className='create-examset-lesson'>
        <Row className={styles.createPanel}>
          {
            examType === 'custom' ?
              <div>
                {customFill}
              </div>
              :
              <div> 
                {(examType !== 'custom' && examType !== 'E') && (currentRole === 'admin' || currentRole === 'superTeacher') ? inputExamBank : null}{/* คลังข้อสอบ */}  {/* EDIT */}
                {examType === 'competition' ? inputCompetition : null} {/* โครงการที่แข่ง */}
                {customFill}
                {examType === 'curriculumbook' ? inputBook : null} {/* หนังสือ */}
                {examType === 'cat' ? inputName : null}
                {examType === 'competition' ? inputYearsCompetition : null} {/* ปีการแข่งขัน */}
                {isCurriculum ? (examType !== 'curriculumbook' ? inputLevel : null) : null} {/* ระดับความยาก */}

                <Modal
                  visible={isShowCompetitionYearModal}
                  closable={false}
                  width={420}
                  footer={<div className='flex-space-center'>
                    <Button
                      type='primary'
                      style={{maxWidth: '180px', width: '100%'}}
                      onClick={() => this.setState({ isShowCompetitionYearModal: false })}
                    >
                    เลือก
                    </Button>
                  </div>
                  }
                >
                  <div style={{textAlign: 'center'}}>
                    <h2 className='text-medium' style={{fontSize: 28, color: '#05007A'}}>เลือกปีที่แข่งขัน</h2>
                    <Checkbox.Group className='inputCheckGroup' value={competitionYear} onChange={this.onCompetitionYearChange} >
                      {checkBoxYears}
                    </Checkbox.Group>
                  </div>
                </Modal>
              </div>
          }
        </Row>
      </div>
    )
  }
}

StepLesson.propTypes = {
  isShowExamBank: PropTypes.bool.isRequired,
  isCurriculum: PropTypes.bool.isRequired,
  competitionList: PropTypes.array.isRequired,
  examBank: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  grade: PropTypes.string.isRequired,
  level: PropTypes.array.isRequired,
  competitionIndex: PropTypes.number.isRequired,
  competitionYear: PropTypes.array.isRequired,
  onChangeLesson: PropTypes.func.isRequired,
}
 
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCompetitionsList
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(StepLesson)
