/* eslint-disable no-undef */
import React , { Component } from 'react'
import { Row, Col , Select, Empty } from 'antd'
import Chart from 'react-google-charts' 

import styles from './ReportAdmin.module.scss'

const { Option } = Select

export default class Overview extends Component {
  static defaultProps = {
    year: '',
    data1: [],
    data2: [],
    legend: true, 
  } 
  handleChange = (value) => {
    let month = localStorage.getItem('month')  
    this.props.loadDetail(
      this.props.year,
      Object.entries(this.props.month).length !== 0 ? this.props.month : parseInt(month)+1,
      value
    )
  }

  render () {
    const { data1, data2 , menuItems, legend, type, colorCode, colorCodeDonutChart } = this.props     
    return (
      <div className={styles.report_admin_detail}>
        <Row gutter={20}>
          <Col span={12} className={styles.chart_detail_1}>
            {this.props.children}

            <Chart
              className={styles.DonutChart}
              width={500}
              height={300}
              chartType='PieChart'
              loader={<div>Loading Chart</div>}
              data={data1}
              options={{
                pieHole: 0.4,
                legend: 'none',
                colors: colorCodeDonutChart
              }}
            />
          </Col>

          <Col span={12} className={styles.chart_detail_1}>
            <h3>รายละเอียด</h3>

            { menuItems && (
              <Select defaultValue={menuItems[0].value}
                style={{ width: 250 }}
                onChange={this.handleChange}>
                {
                  menuItems.map((item, i) => {
                    return (
                      <Option value={item.value} key={i}>
                        {item.name}
                      </Option>
                    )
                  })
                }
              </Select>
            )}

            {
              data2.length > 1 ? (
                <div className='chart'>
                  <Chart
                    width={500}
                    height={500}
                    chartType='BarChart'
                    loader={<div>Loading Chart</div>}
                    data={data2}
                    options={{
                      isStacked: true,
                      hAxis: {
                        minValue: 0,
                      },
                      vAxis: {
                        baselineColor: 'white'
                      },
                      chartArea: { left: 100, top:50, width:'100%', height:'70%' },
                      bars: 'horizontal',
                      axes: {
                        y: {
                          0: { side: 'right' },
                        }
                      },
                      animation: {
                        duration: 500,
                        easing: 'linear',
                        startup: true,
                      },
                      legend: legend ? { position: 'top', maxLines: 4 } : 'none',
                      bar: { groupWidth: 16 },
                      colors: type === 'userAccount' ? [colorCode] : ['#F7941D', '#F7441D', '#1DA9F7', '#18C562']
                    }}
                  />
                </div>
              ) : (
                // <p style={{ marginTop: 20, fontSize: 12, textAlign: 'center'}}>No Data</p>
                <Empty/>
              )
            }
          </Col>
        </Row>
      </div>
    )
  }
}
