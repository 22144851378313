/* eslint-disable no-undef */
import React, { Component, Fragment }  from 'react'
import {
  Button,
  Input,
  Upload,
  Typography,
  message
} from 'antd'
import styles from './Testing.module.scss'
import IconAttachment from '../IconSVG/Attachments.svg'
import { getCookie } from '../../util/helper'
import { BASE_URL } from '../../redux/fetch'

const { TextArea } = Input
const { Text } = Typography


class NoteAnswer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileList: props.note && props.note.file ? [{ uid: '1', name: props.note.file, url: process.env.REACT_APP_API_URL+'/storage/testing/'+props.note.file, status: 'done'}] : [],
      token: getCookie('token')
    }
  }

  handleUploadFile = ({ fileList, file }) => {
    if (file.flag) {
      return
    } 
    // if (file.status !== 'uploading') { 
    // }
    // if (file.status === 'done') { 
    // } else if (file.status === 'error') { 
    // }
    if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`)
      file.flag = true
      fileList = []
    }
    
    if (file.status === 'done') {
      this.props.onUploadFile(file.response.fileName)
      fileList[0].name = file.response.fileName
    }
    this.setState({fileList})
  }

  handlePreview = (file) => {
    if (file.url) {
      window.open(file.url)
    } else {
      window.open(process.env.REACT_APP_API_URL+'/storage/testing/'+file.name)
    }
  }

  render() {
    const { note, onChangeNoteText, testingId, questionId, isSolution ,explanation } = this.props
    let { fileList } = this.state  

    const uploadFileProps = {
      name: 'noteFile',
      action: `${BASE_URL}/testing/${testingId}/question/${questionId}/upload`,
      headers: {
        authorization: `Bearer ${this.state.token}`,
      },
      onPreview: this.handlePreview,
      fileList,
      // fileList: [{uid: 'rc-upload-1569786383519-2', name: "5d9086a2203d4901e7f567e3_5d8dc48c71130001a973e089.png", url: "http://192.168.1.33:3100/storage/testing/5d9086a2203d4901e7f567e3_5d8dc48c71130001a973e089.png", status: "done"}],
      // defaultFileList: [{uid: 'rc-upload-1569786383519-2', name: "5d9086a2203d4901e7f567e3_5d8dc48c71130001a973e089.png", url: "http://192.168.1.33:3100/storage/testing/5d9086a2203d4901e7f567e3_5d8dc48c71130001a973e089.png", status: "done"}],
      defaultFileList: fileList,
      disabled: isSolution,
      beforeUpload: (file) => {
        const type = file.name.substring(file.name.length-4)
        if (!['.doc', 'docx', '.jpg', 'jpeg', '.png', '.pdf'].includes(type.toLowerCase())) {
          message.error('Invalid format file upload')
          file.flag = true
          return false
        }
        const isLt2M = file.size / 1024 / 1024 > 5
        if (isLt2M) {
          message.error('The size more than limit(5M)')
          file.flag = true
          return false
        }
        if (this.state.fileList.length > 0) {
          message.error('Single file upload')
          file.flag = true
          return false
        }
        return true
      },
      onChange: this.handleUploadFile,
      onRemove: () => {
        this.props.onUploadFile(null)
        this.setState({ fileList: []})
      }
    }  
    return (
      <div className={styles.note_testing}>
        {
          isSolution ? 
            <div className={ styles.explanation_solution } >
              <p>อธิบายวิธีคิด:</p>
              {
                explanation.text ?
                  <Fragment>
                    <span dangerouslySetInnerHTML={{ __html: explanation.text }}/><br></br>
                  </Fragment>
                  : ''
              } 
              {
                explanation.file ? 
                  <a style = {{ color: 'black', textDecoration: 'underline' }} href={`${process.env.REACT_APP_API_URL}/storage/testing/${explanation.file}`} rel='noopener noreferrer' target='_blank'>{explanation.file}</a>
                  : ''
              }
            </div> 
            :
            <Fragment>
              <p>คำอธิบาย</p>
              <TextArea defaultValue={note ? note.text : ''} placeholder='พิมพ์เพื่อแสดงคำอธิบายหรือแนบไฟล์' autoSize={{ minRows: 2, maxRows: 5 }} onChange={(e) => isSolution ? '' : onChangeNoteText(e.target.value)} />
      
              <Upload {...uploadFileProps}>
                <Button type='primary'>
                  <img alt='icon-attachment' src={IconAttachment} /> แนบไฟล์
                </Button>
              </Upload>
      
              <Text type='secondary'>(doc, docx, pdf, jpg, jpeg หรือ png ขนาดไม่เกิน 5 MB)</Text>
            </Fragment>
        }
      </div>
    )
  }
}

export default NoteAnswer
