/* eslint-disable no-undef */
import React,{ Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from './Report.module.scss'
// import Header from './Header'
// import { ExamDetailModal } from './ExamStudents'
import * as moment from 'moment'
import {
  Row,
  Col,
  Table,
  Button
} from 'antd'
import {
  getExamGroupDetail,
  getExamAllGroup,
  getExamGroupDetailStatsTests,
} from '../../redux/report/action'
import {
  getGroupData,
} from '../../redux/group/action'
import {
  getExamStaticScore
} from '../../redux/report/action'

// import {
//   getResultTesting
// } from '../../redux/testing/action'
// import { addMedalTier } from '../../util/helper'
import { getExamSetDetail, getExamSetDetailWithQuetion } from '../../redux/exam/action'
// import { ExamInfo  } from './ExamStudents'
// import ProfileDefault from '../IconSVG/ProfileDefault.svg'
import AverageIcon from '../IconSVG/AverageIcon.svg'
import HighIcon from '../IconSVG/HighIcon.svg'
import LowIcon from '../IconSVG/LowIcon.svg'


// import GoldMedalIcon from '../IconSVG/GoldMedalIcon.svg'
// import SilverMedalIcon from '../IconSVG/SilverMedalIcon.svg'
// import CopperMedalIcon from '../IconSVG/CopperMedalIcon.svg'
// import GroupDefaultIcon from '../IconSVG/GroupDefaultIcon.svg'

// import ListItems from '../Display/ListItems'
// import SearchModal from '../modal/SearchModal'
// import ModalStat  from './ModalStat'

import BigBookMathIcon    from '../IconSVG/BigBookMathIcon.svg'
import BigBookScienceIcon from '../IconSVG/BigBookScienceIcon.svg'
import BigMathMatchIcon from '../IconSVG/BigMathMatchIcon.svg'
import BigScienceMatchIcon from '../IconSVG/BigScienceMatchIcon.svg'
import Plot from 'react-plotly.js'


class GroupTeachersStats extends Component {
  constructor(props){
    super(props)
    this.state = {
      // isHover: false,
      // indexRow: false,
      // visibleModal: false,
      groupres: [],
      testingStats: {
        minScore: null,
        maxScore: null,
        avgScore: null
      },
      // dateLastStartAt : '',
      // isSearchModal: false,
      // searchResult: [],
      // checkSearch: false,
      // dataDetail: [],
      examDetail: {},
      // ,visibleModalStat: false,
      // rowdata: {}

pagetitle: '',
coltitle: ''
    }
  }


/*
  columnsStudentList = [
    {
      // title: 'logo',
      dataIndex: 'profileImage',
      key: 'profileImage',
      render: (profileImage,record ) => {
        let prize = null
        if (record.medal === 0) {
          prize = GoldMedalIcon
        } else if (record.medal === 1) {
          prize = SilverMedalIcon
        } else if (record.medal === 2) {
          prize = CopperMedalIcon
        } 
        return (
          <div>
            { prize ? <img alt='icon-prize' src={ prize } className = { styles.prizeIcon } /> : ''}
            <img alt='avatar' style = {{ borderRadius: '50%' }} className = { styles.img_logo } src = { profileImage ? profileImage : GroupDefaultIcon } /> 
          </div>
        )
      },
      width: 132
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'โรงเรียน',
      dataIndex: 'schoolName',
      key: 'schoolName'
    },
    {
      title: 'จำนวนครั้งที่ทำ',
      dataIndex: 'count',
      key: 'count',
      align: 'center'
    },
    {
      title: 'วันที่ทำล่าสุด',
      dataIndex: 'latestStartedAt',
      key: 'latestStartedAt',
      render: (latestStartedAt )=> {
        return (<span>{this.dateConverter(latestStartedAt)}</span>)
      },
    },
    {
      title: 'คะแนนที่ได้',
      dataIndex: 'latestScore',
      key: 'latestScore',
      align: 'center'
    },
    {
      // title: 'ดูคำตอบ',
      dataIndex: 'buttonAnswer',
      key: 'buttonAnswer',
      render: (buttonAnswer,row,index)=>{
        return (<div><Button  className={styles.button_hover} onClick={(e)=>{
          e.stopPropagation()
          this.onClickTesting(index)
        }}>ดูคำตอบ GT2</Button><Button  className={styles.button_hover} onClick={(e)=>{
          e.stopPropagation()
          // console.log(this.state.examGroupDetail)
          this.setState({
            visibleModalStat: true,      
            rowdata: {StaticType: 'ตัวชี้วัด', tid: this.state.examGroupDetail[index].testingId}
          })
        }}>ผลการทำสอบ (ตัวชี้วัด)</Button><Button  className={styles.button_hover} onClick={(e)=>{
          e.stopPropagation()
          // console.log(this.state.examGroupDetail)
          this.setState({
            visibleModalStat: true,      
            rowdata: {StaticType: 'สาระการเรียนรู้', tid: this.state.examGroupDetail[index].testingId}
          })
        }}>ผลการทำสอบ (สาระการเรียนรู้)</Button></div>)
      }
    }
  ]

*/



  componentDidMount = () => {
    this.fetchGroupDetail() 
    this.fetchExamDetail()

let pagetitle = ''  
let coltitle = ''  

//dateLastStartAt
if (this.props.match.params.statstype === 'indicators') {
  pagetitle = 'ผลสอบกลุ่ม (ตัวชี้วัด)'
  coltitle = 'ตัวชี้วัด'
} else if (this.props.match.params.statstype === 'strands') {
  pagetitle = 'ผลสอบกลุ่ม (สาระการเรียนรู้)'
  coltitle = 'สาระการเรียนรู้'
}
 
this.setState({ 
pagetitle,
coltitle
})

  }

  fetchExamDetail = async() => { 
    const res = await this.props.getExamSetDetail(this.props.match.params.examId)
    // console.log('============getExamSetDetail========')
    // console.log(res)
    
    this.setState({ examDetail: res }) 
  }


  fetchGroupDetail = async() => {
    const { match } = this.props
    // let {dateLastStartAt} = this.state
    let examId = match.params.examId
    let groupId = match.params.groupId
    const StaticType = this.props.match.params.statstype

    
    
    const groupres = await  this.props.getGroupData(groupId) 
    // const { testingStats, usersStats } = await this.props.getExamGroupDetail(examId,groupId)
    const tmpres = await this.props.getExamGroupDetail(examId,groupId)
    const { testingStats, usersStats } = tmpres
    // if (usersStats.length > 0) {
    //   dateLastStartAt = this.dateConverter(usersStats.reduce((latestStartedAt, item) => item.latestStartedAt > latestStartedAt ? item.latestStartedAt : latestStartedAt, 0))
    // }

    // console.log('============fetchGroupDetail========')
    // console.log(tmpres)
    // // console.log(usersStats)
    // console.log(groupres)

    const testlist = await this.props.getExamGroupDetailStatsTests(examId,groupId,StaticType)
    // console.log('============testlist========')
    // console.log(testlist)
    const counttesting = testlist.counttesting



    const resExamDetail = await this.props.getExamSetDetailWithQuetion(match.params.examId)
    // console.log('============resExamDetail========')
    // console.log(resExamDetail)

    // let codelist = []
    let tabledata = {}
    let tabletotal = 0
    let inc_in_que_total = 0
    // let itemcount = {}

    for (let i in resExamDetail.questions)
    {

    let xx = null

    if (StaticType === 'indicators')
    {
      xx = resExamDetail.questions[i].indicators.filter((r) => { return r.code.substring(0, 2) == '60'})
    }
    else if (StaticType === 'strands')
    {
      xx  = resExamDetail.questions[i].strands.filter((r) => { return r.code.substring(0, 2) == '60'})
    }



      for (let j in xx)
      {
        inc_in_que_total += 1
        if(!(xx[j].code in tabledata)) { //.indexOf(xx[j].code) === -1) {
            // itemcount[xx[j].code] = 1
            // codelist.push(xx[j].code);
            tabledata[xx[j].code] = xx[j];
            tabledata[xx[j].code].questionscount = 1

            if (xx[j].code in testlist.tableresult) {
              tabledata[xx[j].code].val_count = testlist.tableresult[xx[j].code].count
              tabledata[xx[j].code].val_avg = testlist.tableresult[xx[j].code].avg
              tabledata[xx[j].code].val_min = testlist.tableresult[xx[j].code].min
              tabledata[xx[j].code].val_max = testlist.tableresult[xx[j].code].max
            } else {
              tabledata[xx[j].code].val_count = '-'
              tabledata[xx[j].code].val_avg = '-'
              tabledata[xx[j].code].val_min = '-'
              tabledata[xx[j].code].val_max = '-'
            }

            tabledata[xx[j].code].questionsperind = 0
            tabletotal += 1
        }
        else
        {
            // itemcount[xx[j].code] += 1
            tabledata[xx[j].code].questionscount += 1
        }
      }
      // console.log(res.questions[tmp].indicators)
      // console.log(res.questions[tmp].strands)
    }

// console.log(itemcount)
// console.log(codelist)
// console.log(tabledata)
// console.log(inc_in_que_total)

if (inc_in_que_total > 0)
{
  for (let k in tabledata)
  {
      tabledata[k].questionsperind = tabledata[k].questionscount / inc_in_que_total
  }

  
}

const tableEntries = Object.entries(testlist.tableresult)
const plotlyData = tableEntries.map((row) => {
  return {
    name: row[0],
    type: 'box',
    y: row[1].raw.map(r => r*100),
  }
})


    const tmpdata = Object.values(tabledata)
    // console.log(tmpdata)

    this.setState({ 
      // examGroupDetail: addMedalTier(usersStats),
      testingStats,
      // dateLastStartAt
      groupres
      ,tabledata: tmpdata
      ,tabletotal
      ,counttesting
      ,inc_in_que_total
      ,plotlyData
    })
  }



  render() {
    localStorage.setItem('examId',this.props.match.params.examId.toString()) 
    const {
      // visibleModal, examGroupDetail,
      // dateLastStartAt, searchResult, checkSearch, 
      testingStats,
      examDetail
      // ,rowdata, visibleModalStat
      , tabledata
      , tabletotal
      ,counttesting
      ,inc_in_que_total
      ,coltitle
    } = this.state
    const { history, match, auth } = this.props

const columns = [
    {
      title: 'รหัส',
      dataIndex: 'code',
      key: 'code',
      width:'140px',
      // defaultSortOrder : 'ascend',
      // sortDirections: ['ascend' , 'descend'],
    },
    {
      title: coltitle,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: coltitle+'อยู่ในจำนวนข้อ',
      dataIndex: 'questionscount',
      key: 'questionscount',
      align: 'right',
      // render: (a,rec)=> { return (a) }
      // sortDirections: ['ascend' , 'descend'],
    },
    {
      title: 'จำนวนครั้งที่ทำ',
      dataIndex: 'val_count',
      key: 'val_count',
      align: 'right',
      // render: (a,rec)=> { return (a !== undefined ? ((a*100).toFixed(2) + '%') : '-') }
    },
    {
      title: 'ร้อยละต่ำสุด',
      dataIndex: 'val_min',
      key: 'val_min',
      align: 'right',
      render: (a,rec)=> { return (a !== undefined ? ((a*100).toFixed(2) + '') : '-') }
    },
    {
      title: 'ร้อยละสูงสุด',
      dataIndex: 'val_max',
      key: 'val_max',
      align: 'right',
      render: (a,rec)=> { return (a !== undefined ? ((a*100).toFixed(2) + '') : '-') }
    },
    {
      title: 'ร้อยละเฉลี่ย',
      dataIndex: 'val_avg',
      key: 'val_avg',
      align: 'right',
      render: (a,rec)=> { return (a !== undefined ? ((a*100).toFixed(2) + '') : '-') }
    },
]


    // let ExamDetail = { 
    //   subject  : localStorage.getItem('subject'),
    //   name     : localStorage.getItem('name'),
    //   code     : localStorage.getItem('code'),
    //   type     : localStorage.getItem('type'),
    //   quantity : localStorage.getItem('quantity')
    // } 


const ExamInfo = (props) => {
const { ExamDetail } = props  




  return (
    <div className={styles.exam_info}>
      <div className='detail'>
        <img src={ExamDetail.type === 'C' ? ( ExamDetail.subject === 'คณิตศาสตร์' ? BigMathMatchIcon : BigScienceMatchIcon ) : ( ExamDetail.subject === 'คณิตศาสตร์' ? BigBookMathIcon : BigBookScienceIcon )} alt='group_logo' className={styles.logoGroupLg}/>
        <div className='exam-title'>
          <h2 className='text-medium' style={{color: '#05007A', fontSize: 20, marginBottom: 0}}>{ExamDetail.name}</h2>
          <p className={ styles.text_regular_code } >{ExamDetail.code}</p>
          <p className={ styles.text_regular_type } >
            <span className='text-medium'>{ ExamDetail.type === 'G' ? 'ตามหลักสูตร' : ExamDetail.type === 'C' ? 'แข่งขัน' : ExamDetail.type === 'C' ? 'CAT' : 'กำหนดเอง' }</span>
            <span>{ExamDetail.quantity} ข้อ</span>
          </p>
        </div>
      </div>
    </div>
  )
}
    const GroupInfo= () => { return (
      <div className={styles.group_info}>
        <div className='mobile'>
          <div className='group-name'>
            <h1>{this.state.groupres !== undefined && this.state.groupres.name !== undefined ? this.state.groupres.name : '-'}</h1>
            <p>{
//dateLastStartAt
}</p>
          </div>

          <div className={styles.overall_score}>
            <div className={ styles.all_score }>
              <div>
                <h4>คะแนน</h4>
              </div>
              <div className='score'>
                <img src={LowIcon} alt='low-icon'/>
                {/* <h4>{localStorage.getItem('minScore')}</h4> */}
                <h4>{ testingStats.minScore !== null ? testingStats.minScore.toFixed(2) : '-' }</h4>
              </div>
              <div className='score'>
                <img src={HighIcon} alt='high-icon'/>
                {/* <h4>{localStorage.getItem('maxScore')}</h4> */}
                <h4>{ testingStats.maxScore !== null ? testingStats.maxScore.toFixed(2) : '-' }</h4>
              </div>
              <div className='score'>
                <img src={AverageIcon} alt='average-icon'/>
                {/* <h4>{parseFloat(localStorage.getItem('avgScore')).toFixed(2)}</h4> */}
                <h4>{ testingStats.avgScore !== null ? testingStats.avgScore.toFixed(2) : '-' }</h4>
              </div>
            </div>
          </div>
<div style={{display:'flex', marginTop:'15px'}}>
{
          auth.role !== 'student' && (
            <div style={{ paddingLeft: '0px' }}>
              <Button  className='mobile' onClick={(e) => {
e.stopPropagation()
history.goBack();
// const {  history } = this.props
// history.push(`report/${match.params.examId}/group/${match.params.groupId}`, { 
// cameFrom: 'group', groupName: `${this.state.groupres.name}`,groupId: `${this.state.groupres.id}`
// //reportExamId: ExamDetail._id, cameFrom: 'report', groupId: record.groupId, groupName: record.group
// })
}}>
                กลับไปหน้า กลุ่ม/ข้อสอบ
              </Button>
            </div>
          )
        }

</div>
        </div>

        <div className='desktop'>
          <Row span={24}>
            <Col span={12}>
              <h1 style={{ fontSize: '28px', color: '#05007A' }}> 
                {this.state.groupres !== undefined && this.state.groupres.name !== undefined ? this.state.groupres.name : '-'}
              </h1>
            </Col>
            <Col span={12}>
              <Col span={3} offset={3} align={'center'}><div><img src={LowIcon}/><span>ต่ำสุด</span></div></Col>
              <Col span={3} align={'center'}><div><img src={HighIcon}/><span>สูงสุด</span></div></Col>
              <Col span={3} align={'center'}><div><img src={AverageIcon}/><span>เฉลี่ย</span></div></Col>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <span style={{ color: '#4F4F4F', fontSize: '16px'}}>{this.state.pagetitle}</span>
            </Col>
            <Col span={12}>
              <Col span={3}><span className={styles.GroupInfo_text}>คะแนน</span></Col>
              {/* <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{localStorage.getItem('minScore')}</span></Col>
              <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{localStorage.getItem('maxScore')}</span></Col>
              <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{parseFloat(localStorage.getItem('avgScore')).toFixed(2)}</span></Col> */}
              <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{ testingStats.minScore !== null ? testingStats.minScore.toFixed(2) : '-' }</span></Col>
              <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{ testingStats.maxScore !== null ? testingStats.maxScore.toFixed(2)  : '-' }</span></Col>
              <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{ testingStats.avgScore !== null ? testingStats.avgScore.toFixed(2) : '-' }</span></Col>
            </Col>
          </Row>
<Row>
<Col>
<div style={{display:'flex', marginTop:'5px'}}>
        {
          auth.role !== 'student' && (
            <div style={{ paddingLeft: '0px' }}>
              <Button  className='desktop' onClick={(e) => {
// console.log(this.state.groupres)
// console.log(this.state.groupres.name)
// console.log(this.state.groupres.id)
// console.log(`report/${match.params.examId}/group/${match.params.groupId}`)
// console.log(history)
e.stopPropagation()
// window.location.href = (`/report/${match.params.examId}/group/${match.params.groupId}`);

history.goBack();

// // const {  history } = this.props
// history.push(`report/${match.params.examId}/group/${match.params.groupId}`, { 
// cameFrom: 'group', groupName: `${this.state.groupres.name}`,groupId: `${this.state.groupres.id}`
// //reportExamId: ExamDetail._id, cameFrom: 'report', groupId: record.groupId, groupName: record.group
// })

}}>
              กลับไปหน้า กลุ่ม/ข้อสอบ
              </Button>
            </div>
          )
        }


</div>
</Col>
</Row>
        </div>
      </div>
    )
    }

    // const srcData = examGroupDetail.map((item ) => {
    //   return {
    //     avatar: item.profileImage ? item.profileImage : ProfileDefault,
    //     title: item.name,
    //     medal: item.medal,
    //     description: moment.unix(item.latestStartedAt).format('DD/MM/YYYY'),
    //     status: `${item.latestScore}`,
    //     actions: [],
    //     path: ''
    //   }
    // })

    return(
      <div className={styles.report_info}>
        {/* <Header 
          dataSearch={examGroupDetail}
          setExamsFiltersBySearch={this.setExamsFiltersBySearch}
          history={history}
          match={match}
          showSearchModal={this.showSearchModal} 
          {...this.props}
        /> */}

        <ExamInfo ExamDetail={examDetail} />

        <GroupInfo/>

<div style={{margin: "5px auto"}}>
<Plot 
data={this.state.plotlyData}
layout={ {width: 1000, height: 500 } }
/>
</div>

<Table 
            scroll={{x:true}}
            rowKey={record => record.code}//{ (record,i) => i }
            columns={columns} 
            dataSource={tabledata}  
            // pagination={pagination} //{simple: true, current: AuditListFull.page ,pageSize: 10, total: AuditListFull.totalCount, position: 'both' }} 
            
            // onRow={(record, index) => ({ 
            //   onMouseEnter: ()=> this.setState({ indexRow: index })
            // })}


            //onChange={(pagination) => this.nextPage(searchValue, pagination.current)}
            // onChange={this.handleTableChange}
             
          />
<h1 style={{fontSize: '18px', color: '#05007A', margin: '0'}}>รหัส{coltitle}ทั้งหมด {tabletotal}<br/>รหัส{coltitle}ในทุกข้อรวมจำนวน {inc_in_que_total}<br/>จำนวนจำนวนครั้งที่ทำทั้งหมด {counttesting} ครั้ง</h1>

        {/* <div className={styles.report_teacher_info}>
          <div className='desktop'> 
            <Table
              rowKey = { record => record.userId }
              columns = { this.columnsStudentList }
              dataSource = { examGroupDetail }
              pagination = { false }
              onRow={(record,rowIndex) => {
                return {
                  onMouseEnter: () => this.onHover(rowIndex),
                  onMouseLeave: () => this.onNotHover(rowIndex)
                }
              }}
            />
          </div>
        </div> */}

        {/* <ExamDetailModal
          closeModal={this.closeModal}
          visibleModal={visibleModal}
          ExamDetail={examDetail}
          auth={auth}
        />

        <SearchModal
          isSearchModal={this.state.isSearchModal}
          showSearchModal={this.showSearchModal}
          title='ค้นหา'
          placeholder='ชื่อ'
          // action={this.setExamsFiltersBySearch}
          action = { this.handleSearch }
          checkSearch
        >
          {
            checkSearch ? 
              <ListItems
                srcData={searchData}
                avatar
                status
                report = { true } 
                history = { this.props.history }
                groupReport = { true }
              />
              : ''
          }
        </SearchModal>
        <ModalStat
          visibleModalStat={visibleModalStat}
          rowdata={rowdata}
          onCloseModalResultScore={() => {
            this.setState({
              visibleModalStat: false
            })
          }}
        /> */}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return{
    auth: state.auth.profile,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getExamGroupDetailStatsTests,
      getExamGroupDetail,
      getExamAllGroup,
      getExamSetDetail,
      //getGroupExamDeepStat,
      getGroupData,
      getExamSetDetailWithQuetion,
      // getResultTesting,
      getExamStaticScore,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(GroupTeachersStats)
