import { FetchCycle, AxiosRequest, AxiosRequestGroupDetail } from '../fetch'
import { dateFromTimeStamp } from '../../util/helper'

const extractItem = (arr) => {
  try {
    const data = arr.items.map( t => ({
      key: t._id,
      id: t._id,
      name: t.name,
      logo: t.logo,
      studentCount: t.studentCount,
      code: t.code,
      status: t.status || 'none',
      ownerName: t.owner,
      joinAt: t.joinAt ? dateFromTimeStamp(t.joinAt) : '-',
      createdAt: dateFromTimeStamp(t.createdAt),
      haveStudentRequest: t.haveStudentRequest,
      examCount: t.examCount,
      deletedAt : t.deletedAt
    }) )
    data['page'] = arr ? arr.page : ''
    data['lastPage'] = arr ? arr.lastPage : ''
    data['totalCount'] = arr ? arr.totalCount : ''
    return data 
  } catch (e) {
    return []
  }
}

// Reducer =====================================================================

export const SET_GROUP_LIST               = 'group/SET_GROUP_LIST'
export const SET_SEARCH_GROUP_LIST        = 'group/SET_SEARCH_GROUP_LIST'
export const SET_EXAMS_LIST               = 'group/SET_EXAM_LIST'
export const API_STUDENT_LEAVE_GROUP      = '/groups/student-left'
export const API_SET_STUDENT_LEAVE_GROUP  = '/groups/student-left/seen'

// API ========================================================================

const API_GROUP                           = '/groups'
const API_REMOVE_GROUP_STUDENT            = (groupId) => `/groups/${groupId}/students/remove`
const API_APPROVE_GROUP_STUDENT           = (groupId) => `/groups/${groupId}/students/approve`
const API_REJECT_GROUP_STUDENT            = (groupId) => `/groups/${groupId}/students/reject`
const API_REQUEST_TO_JOIN_GROUP           = (groupId) => `/groups/${groupId}/students/request`
const API_CANCEL_REQUEST_TO_JOIN_GROUP    = (groupId) => `/groups/${groupId}/students/cancel`
const API_LEAVE_GROUP                     = (groupId) => `/groups/${groupId}/students/leave`
const API_DISMISS_STUDENT                 = (groupId) => `/groups/${groupId}/students/dismiss`

const API_REQUEST_IMPORTJOIN           = (groupId) => `/groups/${groupId}/students/importjoin`
const API_REQUEST_EXTLINKJOIN           = (groupId) => `/groups/${groupId}/students/extlinkaddgroup`

// Fetch =====================================================================

const addGroup = data => () => AxiosRequest(API_GROUP, 'post', data)
const fetchGroup = (filter,page) => () => AxiosRequest(API_GROUP, 'get', {'filters[code]': filter, 'page': page ? page : 1, limit : 10} ).then( res => extractItem(res))
const groupList = () => () => AxiosRequest(API_GROUP,'get')

const getGroupDetail = id => () => AxiosRequestGroupDetail(`${API_GROUP}/${id}`, 'get', id)
const getGroupDetailCode = code => () => AxiosRequestGroupDetail(`${API_GROUP}/groupcode/${code}`, 'get', code)
const searchGroup = search => () => AxiosRequest(`${API_GROUP}/search`, 'get', {'q': search}).then( res => extractItem(res))
const patchGroup = (id, data) => () => AxiosRequest(`${API_GROUP}/${id}`, 'patch', data)
const removeGroup = id => () => AxiosRequest(`${API_GROUP}/${id}`, 'delete')

const removeGroupStudent = (id, data) => () => AxiosRequest(API_DISMISS_STUDENT(id), 'patch', data)
const approveStudentToGroup = (id, data) => () => AxiosRequest(API_APPROVE_GROUP_STUDENT(id), 'patch', data)
const rejectStudentToGroup = (id, data) => () => AxiosRequest(API_REJECT_GROUP_STUDENT(id), 'patch', data)
const requestToJoinGroup = id => () => AxiosRequest(API_REQUEST_TO_JOIN_GROUP(id), 'patch')
const cancelRequestToJoinGroup = id => () => AxiosRequest(API_CANCEL_REQUEST_TO_JOIN_GROUP(id), 'patch')
const leaveGroup = id => () => AxiosRequest(API_LEAVE_GROUP(id), 'patch')

const romoveStudentInGroup = (id,data) => () => AxiosRequest(API_REMOVE_GROUP_STUDENT(id), 'patch', data)

const examsInGroup = id => () => AxiosRequest(`${API_GROUP}/${id}/exams`, 'get')
const examsNotInGroup = id => () => AxiosRequest(`${API_GROUP}/${id}/!exams`, 'get').then(response => response.sort((a, b) => b.createdAt - a.createdAt))
const addExamsToGroup = (id, examIds) => () => AxiosRequest(`${API_GROUP}/${id}/exams`, 'put', { examIds })
const removeExamsFromGroup = (id, examId) => () => AxiosRequest(`${API_GROUP}/${id}/exams/${examId}`, 'delete')
const updateExamsFromGroup = (id, examId, data) => () => AxiosRequest(`${API_GROUP}/${id}/exams/${examId}`, 'patch', data)

const getListOfStudentsJustLetf = () => () => AxiosRequest(`${API_STUDENT_LEAVE_GROUP}`, 'get')
const setListOfStudentsJustLetf = () => () => AxiosRequest(`${API_SET_STUDENT_LEAVE_GROUP}`, 'patch')


const listOfStudents = (id) => () => AxiosRequest(`${API_GROUP}/${id}/students`, 'get')


const importjoinToJoinGroup = (id,data) => () => AxiosRequest(API_REQUEST_IMPORTJOIN(id), 'patch', data)
const ExtlinkJoinGroup = (id,data) => () => AxiosRequest(API_REQUEST_EXTLINKJOIN(id), 'patch', data)


// Action Callback =====================================================================

const setGroupList = (groupList) => {
  return {
    type: SET_GROUP_LIST,
    groupList, 
  }
}

const setSearchGroupList = (groupList) => {
  return {
    type: SET_SEARCH_GROUP_LIST,
    groupList,
  }
}

const setExamsInGroup = (exams) => {
  return {
    type: SET_EXAMS_LIST,
    exams,
  }
}
const setListStudentLeft = (listOfStudents) =>{
  return{
    type: API_STUDENT_LEAVE_GROUP,
    listOfStudents, 
  }
}
// Action =====================================================================

export const createGroup = data => FetchCycle(addGroup(data))
export const getGroupList = (filter,page) => FetchCycle(fetchGroup(filter,page), setGroupList)
export const getGroupData= id => FetchCycle(getGroupDetail(id))
export const getGroupDataCode= code => FetchCycle(getGroupDetailCode(code))
export const searchGroupList = search => FetchCycle(searchGroup(search), setSearchGroupList)
export const editGroup = (id, data) => FetchCycle(patchGroup(id, data))
export const deleteGroup = id => FetchCycle(removeGroup(id))
export const removeStudentFromGroup = (id, data) => FetchCycle(removeGroupStudent(id, data))
export const approveStudentGroup = (id, data) => FetchCycle(approveStudentToGroup(id, data))
export const rejectStudentGroup = (id, data) => FetchCycle(rejectStudentToGroup(id, data))
export const studentRequestToJoinGroup = (id) => FetchCycle(requestToJoinGroup(id))
export const studentCancelRequestToJoinGroup = (id) => FetchCycle(cancelRequestToJoinGroup(id))
export const studentLeaveGroup = (id) => FetchCycle(leaveGroup(id))
export const getExamsInGroup = (id) => FetchCycle(examsInGroup(id), setExamsInGroup)
export const getExamsNotInGroup = (id) => FetchCycle(examsNotInGroup(id))
export const addExams = (id, examIds) => FetchCycle(addExamsToGroup(id, examIds))
export const removeExam = (id, examId) => FetchCycle(removeExamsFromGroup(id, examId))
export const updateExam = (id, examId, data) => FetchCycle(updateExamsFromGroup(id, examId, data))
export const getListOfStudents = (id) => FetchCycle(listOfStudents(id))
export const GroupList = () => FetchCycle(groupList())
export const getListLetf = () => FetchCycle(getListOfStudentsJustLetf(), setListStudentLeft)
export const setListLetf = () => FetchCycle(setListOfStudentsJustLetf())
export const RemovedStudentInGroup = (id, data) => FetchCycle(romoveStudentInGroup(id, data))


export const studentimportjoinToJoinGroup = (id,data) => FetchCycle(importjoinToJoinGroup(id,data))
export const studentExtlinkJoinGroup = (id,data) => FetchCycle(ExtlinkJoinGroup(id,data))