import React, { Component } from 'react' 
import ImgEmail from '../../assets/ImgEmail.svg'
import { Button } from 'antd'

export default class ResetPasswordSuccess extends Component {

  handleNext = () => {
    this.props.onNext()
  }

  render() {
    const { history } = this.props
    return (
      <div style={{textAlign: 'center'}}>
        <div style={{marginTop: 36, marginBottom: 20}}>
          <img src={ImgEmail} alt='ImgEmail' width='45%'/>
        </div>
        {(
          <div>
            <p className='text-lg text-medium'>เปลี่ยนรหัสเสร็จสมบูรณ์</p>
            <p className='text-thin'>
              ระบบได้ทำการเปลี่ยนรหัสผ่านเรียบร้อยแล้ว <br/>
              กรุณาเข้าสู่ระบบด้วยรหัสผ่านใหม่
            </p>
          </div>
        )}
        <Button id='nextStepRegister' type='primary' onClick={() => history.push('/login')} style={{ width: '100%', marginTop: 10 }}>
          เข้าสู่ระบบ
        </Button>
      </div>
    )
  }
}

ResetPasswordSuccess.propTypes = {
  // haveEmail: PropTypes.bool.isRequired,
  // onNext: PropTypes.func.isRequired,
}