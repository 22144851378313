import React, { Component }  from 'react'
import {
  Button,
  Input
} from 'antd'
import styles from './ExamsAll.module.scss'
import IconSearch from '../IconSVG/Search.svg'
class Header extends Component {
  render(){
    const { setSearchKey, onSearchExamSet,searchKey } = this.props
    const SearchBar = (
      <div style={{display: 'flex'}}>
        <Input
          suffix={
            <Button type='primary' style={{paddingLeft: 5, paddingRight: 10}}
              onClick={(e) => {
                e.stopPropagation()
                onSearchExamSet(searchKey)
              }}>
              <img src={IconSearch} style={{verticalAlign: 'middle'}} />ค้นหา</Button>}
          placeholder='ค้นหา'
          maxLength={100}
          className={styles.inputSearchExamSet}
          onPressEnter={ e => onSearchExamSet(e.target.value) }
          onChange={setSearchKey}
        />
      </div>
    )
    
    return (
      <div>
        <div className='flex-space-between examsall-header'>
          <h1 style={{ fontSize: '28px',color: '#05007A' }}>ข้อสอบทั้งหมด</h1>
          {SearchBar}
        </div>
      </div>
    )
  }
}

export default  Header
