import React, { Component } from 'react'
import PropTypes from 'prop-types' 
import {
  Button,
  Form,
  Row,
  Col,
  Modal,
} from 'antd' 
import { generateSelectForm, generateInputForm } from '../../util/formGenerator'
import styles from './Register.module.scss'

const formItemLayout = {
  colon: false,
  labelAlign: 'left',
  hideRequiredMark: true,
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
}

class ModalEditAddress extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isFormDirty: false,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState({ isFormDirty: true })
      if (!err) { 
        const params = {
          ...values,
        }
        
        this.props.onSubmit(params)
      }
    })
  }
 

  render() {
    const { form, provinces, departments, isEditAddress, editType, initialSchoolDetail } = this.props 
    let modalChildren = null
    if (isEditAddress) {
      const isFormDirty = this.state.isFormDirty
      let addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department = null
       
      if (initialSchoolDetail) {
        addressNo     = initialSchoolDetail.addressNo
        villageNo     = initialSchoolDetail.villageNo
        lane          = initialSchoolDetail.lane
        road          = initialSchoolDetail.road
        subDistrict   = initialSchoolDetail.subDistrict
        district      = initialSchoolDetail.district
        province      = initialSchoolDetail.province
        postalCode    = initialSchoolDetail.postalCode
        department    = initialSchoolDetail.department
      } 
      if (editType === 'department') {
        const selectDepartment = generateSelectForm({ options: departments, fieldName: 'department', form, label: 'สังกัดของโรงเรียน/หน่วยงาน', isFormDirty, isRequire: true, initialValue: department, nameAsValue: true, freeInput: true, placeholder: 'กรุณาเลือก' })
        modalChildren = selectDepartment
      } else {
        const inputAddressNo = generateInputForm({ fieldName: 'addressNo', form, label: 'เลขที่', isFormDirty, isRequire: true, initialValue: addressNo, limit: 10 })
        const inputVillageNo = generateInputForm({ fieldName: 'villageNo', form, label: 'หมู่ที่', isFormDirty, isRequire: false, initialValue: villageNo, limit: 10 })
        const inputLane = generateInputForm({ fieldName: 'lane', form, label: 'ซอย', isFormDirty, isRequire: false, initialValue: lane})
        const inputRoad = generateInputForm({ fieldName: 'road', form, label: 'ถนน', isFormDirty, isRequire: false, initialValue: road })
        const inputSubDistrict = generateInputForm({ fieldName: 'subDistrict', form, label: 'เขต/อำเภอ', isFormDirty, isRequire: true, initialValue: subDistrict })
        const inputDistrict = generateInputForm({ fieldName: 'district', form, label: 'แขวง/ตำบล', isFormDirty, isRequire: true, initialValue: district })
        const selectProvince = generateSelectForm({ options: provinces, fieldName: 'province', form, label: 'จังหวัด', isFormDirty, isRequire: true, initialValue: province, nameAsValue: false, freeInput: false, placeholder: 'กรุณาเลือก', editInRegister: true })
        const inputPostalCode = generateInputForm({ fieldName: 'postalCode', form, label: 'รหัสไปรษณีย์', isFormDirty, initialValue: postalCode, customRules: [
          { required: true, pattern: /^[0-9]{5}$/ , message: 'กรุณาใส่รหัสไปรษณีย์ให้ครบ 5 หลัก' } 
        ] })

        const AddressFields = [
          (<Row gutter={10} key='inputAddressNo'>
            <Col span={12}>
              { inputAddressNo }
            </Col>
            <Col span={12} key='inputVillageNo'>
              { inputVillageNo }
            </Col>
          </Row>),
          inputLane,
          inputRoad,
          inputDistrict,
          inputSubDistrict,
          selectProvince,
          inputPostalCode,
        ]
        modalChildren = AddressFields
      }
    }

    return (
      <Modal
        visible={isEditAddress}
        wrapClassName={styles.modalEditAddressWrap}
        closable={false}
        width='630px'
        footer={<div className='flex-space-center'>
          <Button key='back' type='link' className={ `${styles.modalEditAddressCancelBtn} text_underline` } onClick={this.props.onCancel}>
            ยกเลิก
          </Button>
          <Button key='submit' type='primary' htmlType='submit' className={ styles.modalEditAddressOKBtn } onClick={this.handleSubmit}>
            ส่งข้อมูล
          </Button>
        </div>
        }
      >
        <Form {...formItemLayout} onSubmit={this.handleSubmit} >
          <h2 className='text-medium' style={{color: '#05007A', marginBottom: '30px'}}>แก้ไขที่อยู่</h2>
          <div className={ styles.modalEditAddress }>
            {modalChildren}
          </div>
        </Form>
      </Modal>
    )
  }
}

ModalEditAddress.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  provinces: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  isEditAddress: PropTypes.bool.isRequired,
  editType: PropTypes.string.isRequired,
  initialSchoolDetail: PropTypes.object,
}
 
const WrappedModalEditAddress = Form.create({ name: 'modal_edit_address' })(ModalEditAddress)
export default WrappedModalEditAddress