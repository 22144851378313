import { SET_TESTING } from './action'

const defaultState = {
  testing: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_TESTING:
      return {
        ...state,
        testing: action.testing
      }
    default: return state
  }
}  