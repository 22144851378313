import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getSchoolList ,
  getProvinceList
} from '../../redux/school/action'
import Header from './Header'
import {
  Table,
  Spin
} from 'antd'
import * as moment from 'moment'
import ModalSchool from './ModalSchool'
import styles from './School.module.scss'
import Edit from '../IconSVG/Edit.svg'
import EditGray from '../IconSVG/EditGray.svg'
import Delete from '../IconSVG/Delete.svg'
import DeleteGray from '../IconSVG/DeleteGray.svg'
class SchoolConfig extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      schoolListFull: [],
      schoolList: [],
      excelSchool: [],
      indexRow: false,
      checkMouseOverEdit: false,
      typeModal: false,
      modalVisible: false, 
      item: [],
      schoolId: null,
      provinceList: null,
      provinceItem: [],
      searchValue: null
    } 
  }
  componentDidMount = () => {
    this.fetchSchool()
  }
  fetchSchool = async(filter = null, page = 1) => {
    this.setState({ isLoading: true })
    const res = await this.props.getSchoolList(filter, page)
    const resProvince = await this.props.getProvinceList()
    this.setState({ 
      schoolListFull: res,
      schoolList: res.items,
      excelSchool: res.items,
      provinceList: resProvince, 
      isLoading: false ,
      checkMouserOverDelete: false
    })
  }
  columns = [
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
      sorter: (strA, strB) => { 
        let icmp = strA.name.toLowerCase().localeCompare(strB.name.toLowerCase())
        if (icmp !== 0) { 
          return icmp
        } 
        if (strA.name > strB.name) {
          return 1
        } else if (strA.name < strB.name) {
          return -1
        } else {
          return 0
        }
      }, 
      width:'250px',
      render: (name,record) => { 
        return (
          <span>{name}{ record.remark ? ` (${record.remark})` : ''}</span>
        )
      }
    },
    {
      title: 'วันที่เพิ่มข้อมูล',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render:(createdAt)=> { 
        return (
          <span>{moment.unix(createdAt).format('DD/MM/YYYY')}</span>
        )
      },
      width:'150px',
      sorter: (a, b) => a.createdAt - b.createdAt, 
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit', 
      render: (edit,record,index) => {
        const { checkMouseOverEdit, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkMouseOverEdit: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouseOverEdit: false})
        } 
        return( 
          <div className={styles.icon_row}>
            <img alt='edit' title='แก้ไข' onClick={e => {
              e.stopPropagation()
              this.onOpenModal(record,'edit')
            }} onMouseOver={mouserOver}  onMouseLeave={mouseLeave} src={checkMouseOverEdit && indexRow === index ? Edit : EditGray}/>
          </div> 
        )
      },
      width:'60px',
    },
    {
      title: '',
      dataIndex: '',
      key: 'delete', 
      render: (deleteRow,record,index) => {
        const { checkMouserOverDelete, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkMouserOverDelete: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouserOverDelete: false})
        }
        return(
          <div className={styles.icon_row}>
            <img alt='delete' title='ลบข้อมูล' onClick={e => {
              e.stopPropagation()
              this.onOpenModal(record,'delete')
            }} onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkMouserOverDelete && indexRow === index ? Delete : DeleteGray}/>
          </div> 
        )
      } 
    }
  ] 
  onOpenModal = (record, type) => {   
    if(type === 'edit' || type === 'delete'){
      this.setState({
        typeModal: type,
        item: record,
        schoolId: record._id,
        modalVisible: true
      })
    }else{
      this.setState({
        typeModal: type,
        modalVisible: true
      })
    }
  } 
  onCloseModal = () => {
    this.setState({
      modalVisible: false
    })
  } 
  setValueOfSearch = (value) => { this.setState({ searchValue: value }) }
  nextPage = async(filter = null, page = 1) => {
    this.setState({ isLoading: true })
    const res = await this.props.getSchoolList(filter, page)
    const resProvince = await this.props.getProvinceList()
    this.setState({ 
      schoolListFull: res,
      schoolList: res.items,
      excelSchool: res.items,
      provinceList: resProvince, 
      isLoading: false ,
      checkMouserOverDelete: false
    })
  }
  render(){
    const { excelSchool,isLoading,typeModal ,schoolList, modalVisible, item, 
      schoolId, indexRow, provinceList, schoolListFull, searchValue} = this.state   
    return (
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading }>
          <Header setValueOfSearch={this.setValueOfSearch} fetchSchool={this.fetchSchool} exportExcel={excelSchool} dataSearch={schoolList} setProvinceFiltersBySearch={this.setProvinceFiltersBySearch}  key='SchoolHeader' onOpenModal={this.onOpenModal}/> 
          <Table 
            scroll={{x:true}}
            rowKey={ (record,i) => i }
            columns={this.columns} 
            dataSource={schoolList}  
            pagination={{simple: true, current: schoolListFull.page ,pageSize: 10, total: schoolListFull.totalCount, position: 'both' }} 
            onRow={(record, index) => ({ 
              onMouseEnter: ()=> this.setState({ indexRow: index })
            })}
            onChange={(pagination) => this.nextPage(searchValue, pagination.current)}
             
          />
          <ModalSchool provinceList={provinceList} indexRow={indexRow}   fetch={this.fetchSchool} schoolId={schoolId} typeModal={typeModal} items={item} modalVisible={modalVisible} onCloseModal={this.onCloseModal}/>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
      getSchoolList ,
      getProvinceList
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(SchoolConfig)