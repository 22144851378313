import { FetchCycle, AxiosRequest } from '../fetch'

// API =====================================================================

const API_FAQ               = '/faq'

// Fetch ===================================================================

const sentFaqToUs       = (data)              => () => AxiosRequest(API_FAQ,'post',data)
// Action =====================================================================

export const onSentFaqToUs        = (data)              => FetchCycle(sentFaqToUs(data))
