import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  Spin,
} from 'antd'
import CardLightMap from '../Display/CardLightMap'
import FormForgetPassword from './FormForgetPassword'
import {
  UserRequestForgetPassword
} from '../../redux/auth/action'

class ForgetPassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errorForgetPassword: null,
      isSentEmailForgetPassword: false
    }
  }

  onSubmitForgetPassword = async (data) => {
    this.setState({ isLoading: true })
    const res = await this.props.UserRequestForgetPassword({email: data.email})
    if (res.error) {
      this.setState({ 
        isLoading: false,
        errorForgetPassword: 'อีเมลไม่ถูกต้อง',
      })
    } else {
      this.setState({ isLoading: false, isSentEmailForgetPassword: true })
    }
    
  }

  render() {
    const isAuthenticated = this.props.isAuthenticated
    const { isSentEmailForgetPassword } = this.state
    return ( !isAuthenticated ? 
      <CardLightMap>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <FormForgetPassword onSubmit={this.onSubmitForgetPassword} errorForgetPassword={this.state.errorForgetPassword} isSentEmailForgetPassword={isSentEmailForgetPassword} />
        </Spin>
      </CardLightMap> :
      <Redirect to='/examset' />
    )
  }
}

const mapStateToProps = state => ({
  // Redirect after login
  isAuthenticated: state.auth.token ? true : false,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserRequestForgetPassword,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)