import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CKEditor } from 'ckeditor4-react'
import {
  message,
  Spin,
  Button,
  Row,
  Col,
  Checkbox,
  Input,
  Form,
  Tabs
} from 'antd'
import {
  GetContactDetail,
  setTersmAndConditions,
  getTersmAndConditions,
  getDataPolicyInMaster,
  setDataPolicyInMaster
} from '../../redux/master/action'
import styles from './DataPolicy.module.scss'
const {TabPane} = Tabs
class EditDataPolicy extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      contactDetail:'',
      checkEdit : false,
      dataTermForUpdate : {},
      isNoticeTeacher : true,
      isNoticeStudent : false,
      data : [{ content: '' }, { content: '' }],
      dataPolicy : [],
      TabActiveKey : 2,
    }
    this.handleInstanceReady0 = this.handleInstanceReady0.bind(this)
    this.handleInstanceReady1 = this.handleInstanceReady1.bind(this)
  }

  componentDidMount = async() => {

    // if(typeof(localStorage.getItem('policyteacher')) === 'string' && typeof(localStorage.getItem('policystudent')) === 'string') {
    //   let { data } = this.state
    //   this.props.TabActiveKey === '2' ?
    //     data[0].content = localStorage.getItem('policyteacher')
    //     :
    //     data[0].content = localStorage.getItem('policystudent')
    //   await this.setState({ data })
    // }
    this.fetchDataPolicy()
  }
  fetchDataPolicy = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.getDataPolicyInMaster()  
    this.setState({ 
      isLoading: false,
      data : res,
      dataPolicy : res
    },()=>{
      this.setState({
        isNoticeTeacher : this.state.dataPolicy[0].isNotice ? this.state.dataPolicy[0].isNotice : false, 
        isNoticeStudent : this.state.dataPolicy[1].isNotice ? this.state.dataPolicy[1].isNotice : false 
      })
    })
    // localStorage.setItem('policyteacher',res[0].content)
    // localStorage.setItem('policystudent',res[1].content)
    if (res.error) message.error(res.error)
  }
  goToEditComponent = () =>{
    this.setState({
      checkEdit : !this.state.checkEdit
    })
  }
  changeTeacherText = (e,name) =>{
    const {dataPolicy} = this.state
    if(name === 'content'){
      dataPolicy[0][name] = e.editor.getData()
    }else{
      dataPolicy[0][name] = e.target.value  
    }
    this.setState({
      dataPolicy
    })
  }

  changeStudentText = (e,name) =>{
    const {dataPolicy} = this.state
    if(name === 'content'){
      dataPolicy[1][name] = e.editor.getData()
    }else{
      dataPolicy[1][name] = e.target.value  
    }
    this.setState({
      dataPolicy
    })
  }

  handleCheckboxTeacher = () =>{
    const { isNoticeTeacher } = this.state
    if(isNoticeTeacher === true){
      this.setState({
        isNoticeTeacher : !isNoticeTeacher
      })
    }else{
      this.setState({
        isNoticeTeacher : !isNoticeTeacher
      })
    }
  }
  handleCheckboxStudent = () =>{
    const { isNoticeStudent } = this.state
    if(isNoticeStudent === true){
      this.setState({
        isNoticeStudent : !isNoticeStudent
      })
    }else{
      this.setState({
        isNoticeStudent : !isNoticeStudent
      })
    }
  }
  onCheckTabActiveKey = (key) => { 
    this.setState({
      TabActiveKey: key
    })
  }
  
  onUpdateTerm = () =>{
    const {dataPolicy , isNoticeStudent, isNoticeTeacher, checkEdit} = this.state
    dataPolicy[0]['isNotice'] = isNoticeTeacher
    dataPolicy[1]['isNotice'] = isNoticeStudent
    this.setState({
      dataPolicy
    },async ()=>{
      if(this.props.TabActiveKey == '2'){
        const res = await this.props.setDataPolicyInMaster(dataPolicy[0])
        // const resstd = await this.props.setDataPolicyInMaster(dataPolicy[1])
        this.setState({ 
          isLoading: false,
        })
        if(!res.error)  {
          message.success('แก้ไขข้อมูลสำเร็จ')
        }
        await this.props.fetchContactUsDetail()
        // if (resfetch.error) 
        //   message.error(resfetch.error)
      }
      else if(this.props.TabActiveKey == '3'){
        // const res = await this.props.setDataPolicyInMaster(dataPolicy[0])
        const resstd = await this.props.setDataPolicyInMaster(dataPolicy[1])
        this.setState({ 
          isLoading: false
        })
        if(!resstd.error)  {
          message.success('แก้ไขข้อมูลสำเร็จ')
        }
        await this.props.fetchContactUsDetail()
        // if (resfetch.error) 
        //   message.error(resfetch.error)
      }
    }
    )
  }
  
  handleInstanceReady0({ editor }) {
    const data = this.state.dataPolicy[0] ? this.state.data[0].content : ''
    editor.setData(data);
  }

  handleInstanceReady1({ editor }) {
    const data = this.state.dataPolicy[1] ? this.state.data[1].content : ''
    editor.setData(data);
  }

  render() { 
    return ( 
      <div className={styles.examSetDiv}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <div className='flex-space-between' style={{marginBottom: '30px'}}>
            <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>แก้ไขข้อมูล</h1>
          </div>
          <div>
            <Form onSubmit = {this.onUpdateTerm}>
              <Row>
                <Tabs defaultActiveKey={this.props.TabActiveKey} onChange={this.onCheckTabActiveKey}>
                  {this.props.TabActiveKey === '2' ?
                    <TabPane tab={`ครู/ครูพิเศษ ${''}`} key = '2' onTabClick = {this.teacherListTable}>
                      <Row className = {styles.row_title}>
                        <Col span={2}>
                          <label>หัวข้อ</label>
                        </Col>
                        <Col span={14} >
                          <div>
                            <Input
                              value = {this.state.dataPolicy[0] ? this.state.dataPolicy[0].title : ''}
                              onChange = {e => this.changeTeacherText(e,'title')}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className = {styles.row_title}>
                        <Col span={2}><label>ข้อความ</label></Col>
                        <Col span={14}>
                          <div>
                            {
                              this.state.dataPolicy[0] && <CKEditor
                                initData =  {this.state.dataPolicy[0] ? this.state.data[0].content : ''}
                                onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                                onInstanceReady={this.handleInstanceReady0}
                                readOnly={false}
                                config={{
                                  toolbarGroups : [
                                    { name: 'clipboard', groups: [ 'undo', 'clipboard' ] },
                                    { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                                    { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                                    { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                                    { name: 'forms', groups: [ 'forms' ] },
                                    { name: 'links', groups: [ 'links' ] },
                                    { name: 'insert', groups: [ 'insert' ] },
                                    { name: 'styles', groups: [ 'styles' ] },
                                    { name: 'colors', groups: [ 'colors' ] },
                                    { name: 'tools', groups: [ 'tools' ] },
                                    { name: 'others', groups: [ 'others' ] },
                                    { name: 'about', groups: [ 'about' ] }
                                  ],
                                  removeButtons : 'Paste,PasteText,PasteFromWord,Source,Save,NewPage,Preview,Print,Templates,RemoveFormat,CopyFormatting,CreateDiv,Blockquote,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Anchor,Flash',
                                  imageUploadUrl: 'https://exam.sobtid.me/admin/questions/image', 
                                  uploadUrl: 'https://exam.sobtid.me/admin/questions/image?responseType=json', 
                                  filebrowserUploadUrl: 'https://exam.sobtid.me/admin/questions/imagefile?responseType=json',
                                  editorUrl: 'https://cdn.ckeditor.com/4.17.2/full/ckeditor.js',
                                  extraPlugins: 'justify, bidi, smiley, iframe, font, colorbutton, showblocks, find, selectall, pagebreak',
                                  language: 'th'
                                }}
                                onChange = {e => this.changeTeacherText(e,'content')}
                              />
                            }
                    
                          </div>
                          <div style = {{paddingTop : '3em'}}> 
                            <Checkbox
                              onChange = {this.handleCheckboxTeacher} value = 'isNotice' checked={this.state.isNoticeTeacher}
                            >แจ้งเตือนผู้ใช้</Checkbox>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    :
                    <TabPane tab={`นักเรียน ${''}`} key = '3' onTabClick = {this.studentListTable}>
                      <Row className = {styles.row_title}>
                        <Col span={2}>
                          <label>หัวข้อ</label>
                        </Col>
                        <Col span={14} >
                          <div>
                            <Input
                              value = {this.state.dataPolicy[1] ? this.state.dataPolicy[1].title : ''}
                              onChange = {e => this.changeStudentText(e,'title')}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className = {styles.row_title}>
                        <Col span={2}><label>ข้อความ</label></Col>
                        <Col span={14}>
                          <div>
                            {
                              this.state.dataPolicy[1] && <CKEditor
                                data =  {this.state.dataPolicy[1] ? this.state.dataPolicy[1].content : ''}
                                onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                                onInstanceReady={this.handleInstanceReady1}
                                readOnly={false}
                                config={{
                                  toolbarGroups : [
                                    { name: 'clipboard', groups: [ 'undo', 'clipboard' ] },
                                    { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                                    { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                                    { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                                    { name: 'forms', groups: [ 'forms' ] },
                                    { name: 'links', groups: [ 'links' ] },
                                    { name: 'insert', groups: [ 'insert' ] },
                                    { name: 'styles', groups: [ 'styles' ] },
                                    { name: 'colors', groups: [ 'colors' ] },
                                    { name: 'tools', groups: [ 'tools' ] },
                                    { name: 'others', groups: [ 'others' ] },
                                    { name: 'about', groups: [ 'about' ] }
                                  ],
                                  removeButtons : 'Paste,PasteText,PasteFromWord,Source,Save,NewPage,Preview,Print,Templates,RemoveFormat,CopyFormatting,CreateDiv,Blockquote,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Anchor,Flash',
                                  imageUploadUrl: 'https://exam.sobtid.me/admin/questions/image', 
                                  uploadUrl: 'https://exam.sobtid.me/admin/questions/image?responseType=json', 
                                  filebrowserUploadUrl: 'https://exam.sobtid.me/admin/questions/imagefile?responseType=json',
                                  editorUrl: 'https://cdn.ckeditor.com/4.17.2/full/ckeditor.js',
                                  extraPlugins: 'justify, bidi, smiley, iframe, font, colorbutton, showblocks, find, selectall, pagebreak',
                                  language: 'th'
                                }}
                                onChange = {e => this.changeStudentText(e,'content')}
                              />
                            }
                           
                          </div>
                          <div style = {{paddingTop : '3em'}}> 
                            <Checkbox
                              onChange = {this.handleCheckboxStudent} value = 'isNotice' checked={this.state.isNoticeStudent}
                            >แจ้งเตือนผู้ใช้</Checkbox>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  }
                </Tabs>
              </Row>
              <div className = {styles.div_for_divide}></div>
            </Form>
          </div>
          <div>
            <Row>
              <Col span={6}>
                <div>
                  <a onClick = {this.props.goToEditComponent} className = {styles.cancle_link}>ยกเลิก</a>
                </div>
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col span={6}>
                <div styles = {styles.devide_line}>
                </div> 
                <div>
                  <Button className = {styles.save_btn} type='primary' htmlType='submit' onClick = {this.onUpdateTerm}>บันทึก</Button>
                </div> 
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    contactDetail : state.master.contactDetail
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetContactDetail,
      setTersmAndConditions,
      getTersmAndConditions,
      getDataPolicyInMaster,
      setDataPolicyInMaster
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(EditDataPolicy)