/* eslint-disable no-undef */
import React,{ Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import Download from 'downloadjs'
// import * as htmlToImage from 'html-to-image'

import {
  openUserDetail,
} from '../../redux/user/action'


import {
  getExamStaticScore
} from '../../redux/report/action'

import {
  getResultTesting
} from '../../redux/testing/action'

import { getExamSetDetailWithQuetion } from '../../redux/exam/action'


// import { getStudentDetail } from '../../redux/report/action'
import { Chart } from 'react-google-charts'
import * as moment from 'moment'
// import IconDownload from '../IconSVG/IconDownload.svg'
import BigBookMathIcon    from '../IconSVG/BigBookMathIcon.svg'
import BigBookScienceIcon from '../IconSVG/BigBookScienceIcon.svg'
import BigMathMatchIcon from '../IconSVG/BigMathMatchIcon.svg'
import BigScienceMatchIcon from '../IconSVG/BigScienceMatchIcon.svg'
// import BackgroundImgTestResultFirst from '../IconSVG/BackgroundImgTestResultFirst.svg'
// import AGirlPic from '../IconSVG/AGirlPic.svg'
// import ABoyPic from '../IconSVG/ABoyPic.svg'
// import BlueStarIcon from '../IconSVG/BlueStarIcon.svg'
import styles from './Report.module.scss'
import {
  Modal,
  Row,
  Col,
  // Button,
  Divider, 
} from 'antd'
class ModalStat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // lesson: [],
      // score: [
      //   ['range', 'คะแนนที่ได้', 'คะแนนเต็ม' ],
      //   ['1', 0, 0 ],
      //   ['2', 0, 0 ],
      //   ['3', 0, 0 ], 
      // ]
      // examDetail: {},
      ExamDetail: {},
      testingDetail: [],
      // userrow: [],
      user: {},
      StaticType: '',
      codelist: [], 
      itemlist: [], 
      itemcount: {}, 
      chartdata: [['range', 'อัตราการตอบถูก', 'อัตราการตอบถูก'], ['', 0, 0]]
    }
  }


onOpenModalStat = async(rowdata) => { 
// console.log("rowdata")
// console.log(rowdata)    
    const testingId = rowdata.tid //examGroupDetail.testingsList[index].testingId 

    const resTestingDetail = await this.props.getResultTesting(testingId)
// console.log("resTestingDetail")
// console.log(resTestingDetail)

    const ExamStaticScore = await this.props.getExamStaticScore(testingId)
// console.log("ExamStaticScore")
// console.log(ExamStaticScore)


    const resExamDetail = await this.props.getExamSetDetailWithQuetion(ExamStaticScore.examid)

    let codelist = []
    let itemlist = []
    let itemcount = {}

    for (let i in resExamDetail.questions)
    {

    let xx = null

    if (rowdata.StaticType === 'ตัวชี้วัด')
    {
      xx = resExamDetail.questions[i].indicators
    }
    else if (rowdata.StaticType === 'สาระการเรียนรู้')
    {
      xx  = resExamDetail.questions[i].strands
    }

      for (let j in xx)
      {
        if (xx[j].code.substring(0, 2) != 60)
          continue
          
        if(codelist.indexOf(xx[j].code) === -1) {
            itemcount[xx[j].code] = 1
            codelist.push(xx[j].code);
            itemlist.push(xx[j]);
        }
        else
        {
            itemcount[xx[j].code] += 1
        }
      }
      // console.log(res.questions[tmp].indicators)
      // console.log(res.questions[tmp].strands)
    }

    

    // console.log(resExamDetail);
    // console.log(itemcount);
    // console.log(itemlist);
    // console.log();



    // await this.fetchExamDetail(rowdata,ExamStaticScore.examid)


    // const { ExamDetail, codelist, itemlist, itemcount } = this.state
    // console.log("ExamDetail")
    // console.log(ExamDetail)
    // console.log(ExamDetail['indicators'])
    // console.log(ExamDetail['strands'])
    // console.log(ExamDetail['lessons'])

    // console.log("codelist")
    // console.log(codelist)

    // console.log("itemlist")
    // console.log(itemlist)

    // console.log("itemcount")
    // console.log(itemcount)

    let user = {prefixName:'Guest', firstName:'', lastName:'', school:{name:{text:''}}}
    if (ExamStaticScore.userid !== null)
      user = await this.props.openUserDetail(ExamStaticScore.userid)

            // <h4 className={ 'black' } >{user.prefixName} {user.firstName} {user.lastName}</h4>
            // <p className={ 'black' } >{user.school ? user.school.name.text : ''}</p>

    // const ExamDetail = await this.props.getExamSetDetail(this.props.match.params.examId)
    // console.log("user")
    // console.log(user)
    
    

    // console.log(this.props.match.params)


    // ExamStaticScore
    let chartdata = [['range', 'อัตราการตอบถูก', 'อัตราการตอบถูก']]
    const StaticType = rowdata.StaticType

    let vals = null
    let vals2 = null
    let vals3 = null

    if (StaticType === 'ตัวชี้วัด')
    {
      vals = ExamStaticScore.itemindicatorscore
      vals2 = ExamStaticScore.itemindicatorscorefull
      vals3 = ExamStaticScore.itemindicatorscorefulltotal
    }
    else if (StaticType === 'สาระการเรียนรู้')
    {
      vals = ExamStaticScore.itemstrandsscore
      vals2 = ExamStaticScore.itemstrandsscorefull
      vals3 = ExamStaticScore.itemstrandsscorefulltotal
    }
    
    let scoretypeget = 0
    let scoretypetotal = vals3

        if (scoretypetotal > 0)
        {
        Object.keys(vals).sort().map(function(key, index) {
          scoretypeget += vals[key]

          chartdata.push([key,
// {v:(vals[key]/scoretypetotal), f:((vals[key]/scoretypetotal)*100).toFixed(2) + '%'},
// {v:(vals2[key]/scoretypetotal), f:((vals2[key]/scoretypetotal)*100).toFixed(2) + '%'}

{v:((vals[key]/vals2[key])), f:((vals[key]/vals2[key])*100).toFixed(2) + '% (' + vals[key]+'/'+vals2[key]+' ข้อ)'},
{v:(1-(vals[key]/vals2[key])), f:((vals[key]/vals2[key])*100).toFixed(2) + '% (' + vals[key]+'/'+vals2[key]+' ข้อ)'},

// {v:(vals2[key]/scoretypetotal), f:((vals2[key]/scoretypetotal)*100).toFixed(2) + '%'}


])
        })
        }
        else
        {
          chartdata.push(['',0,0])
        }

    // console.log('----------chartdata---------')
    // console.log(chartdata)
    // console.log('----------chartdata---------')


    if (codelist.length < 1)
    {
      chartdata = [['range', 'อัตราการตอบถูก', 'อัตราการตอบถูก'],['',0,0]]
    }

    this.setState({
      ExamDetail: resExamDetail, codelist, itemlist, itemcount,

      testingDetail: resTestingDetail,
      // visibleModalStat: true,
      //examStaticScore: ExamStaticScore,
      scoretypeget,
      scoretypetotal,
      
      
      // userrow: rowdata,
      user: user,
      chartdata,
      StaticType
    })
  


  }

// UNSAFE_
componentWillReceiveProps = async (nextProps) => {

// console.log(JSON.stringify(this.props) + "-----" + JSON.stringify(nextProps))

if(this.props.rowdata !== nextProps.rowdata){
  // console.log(nextProps.rowdata)
  await this.onOpenModalStat(nextProps.rowdata)
}

}

  // UNSAFE_componentWillReceiveProps = (nextProps) => { 
  //   let score = this.state
  //   if(this.props.examStaticScore !== nextProps.examStaticScore){
  //     score = nextProps.examDetailReport.score
  //     score.forEach((s,i) => {
  //       if(i === 0){
  //         s.push({ role: 'tooltip', type: 'string', p: { html: true } })
  //       }else{
  //         // s[i].push(`<p>${s[i]}</p>`)
  //         s[0] = `${i}`
  //         s[2] = s[2] - s[1]
  //         s.push(`${s[1]}/${s[1]+s[2]}`)
  //       }
  //     }) 
  //     this.setState({ 
  //       lesson: nextProps.examDetailReport.lesson, 
  //       score 
  //     })
  //   } 
  // }
  // onDownload = async() => {
  //   // eslint-disable-next-line no-undef
  //   const resultId = await document.getElementById('ModalStat')   
  //   await htmlToImage.toPng(resultId).then(async(dataUrl) => { 
  //     await Download(dataUrl, 'ScoreResult.png', 'image/png') 
  //   })
  // }
  render(){
    const { visibleModalStat, onCloseModalResultScore, 
      //  StaticType, chartdata, itemlist, codelist, itemcount, userrow, testingDetail, user 
} = this.props  //user, visibleModalStat,
    const {        testingDetail,  user, ExamDetail, 
codelist, itemlist, chartdata, StaticType,       scoretypeget,
      scoretypetotal,

 }  = this.state    
    // let DotPosition =  0
    // console.log(userrow)
    
    // const user = userrow
    // let user = {}
    // user.prefixName = ''
  
    // if(testingDetail){
    //   DotPosition = ((Math.round(((Number(testingDetail.theta) + 3.5 )* 100/7)*100)/100)*500/100) - 12
    // }  
    let checkGrade = ExamDetail.grade ? ExamDetail.grade.slice(0, 1) : null   
    const detail = (
      <div className={styles.modal_score_result} id='ModalStat' 
        style={ { backgroundColor: 'white' }
          // ExamDetail.type !== 'CAT' ?
          //   checkGrade === 'ป' ? 
          //     { backgroundColor: '#112AAE', paddingBottom: '200px' }   : { backgroundColor: 'white' } : 
          //   checkGrade === 'ป' ? 
          //     { backgroundColor: '#112AAE', paddingBottom: '200px' }   : { backgroundColor: 'white' } 
        } 
        ref={el => this.componentRef = el}>
          
        <div className='mobile'>
          <div className='header'>
            <h1>ข้อมูลข้อสอบ</h1>
            {/* <Button onClick={this.onDownload} type={'primary'}><img alt='icon-download' src={IconDownload}/></Button> */}
            {/* <Button onClick={this.onPrint} type={'primary'}><img src={PrintIcon}/></Button> */}
          </div>

          <div className='detail'>
            <img alt='icon-subject' src={ExamDetail.type === 'C' ? (
              ExamDetail.subject === 'คณิตศาสตร์' ?
                BigMathMatchIcon : BigScienceMatchIcon
            ) : (
              ExamDetail.subject === 'คณิตศาสตร์' ?
                BigBookMathIcon: BigBookScienceIcon
            )}
            />

            <div className='title'>
              <h3 className={ 'black' }>{ExamDetail.name}</h3>
              <p className={ 'black' } style={{ fontSize: '16px', fontWeight: '300' }}>{ExamDetail.code}</p>
            </div>
          </div>

          <div className='detail2'>
            <div className='first'>
              <h4 className={ 'black' }>สาระการเรียนรู้</h4>
              <p>{ExamDetail.subject}</p>

              <h4 className={ 'black' }>จำนวน</h4>
              <p>{ExamDetail.quantity} ข้อ</p>
            </div>

            <div className='second'>
              <h4 className={ 'black' }>ระดับชั้น</h4>
              <p>{ExamDetail.grade}</p>

              <h4 className={ 'black' }>จัดชุดโดย</h4>
              <p>{ExamDetail.owner ? ExamDetail.owner.firstName : ''} {ExamDetail.owner ? ExamDetail.owner.lastName : ''}</p>
            </div>
          </div>

          <div className='attend-detail'>
            <h1>ข้อมูลผู้เข้าสอบ</h1>
            <h4 className={ 'black' } >{user.prefixName} {user.firstName} {user.lastName}</h4>
            <p className={ 'black' } >{user.school ? user.school.name.text : ''}</p>

            <div className='date-time'>
              <div className='date'>
                <h4 className={ 'black' } >วันที่ทำข้อสอบ</h4>
                <p className={ 'black' } >{moment.unix(testingDetail.finishedAt).format('DD/MM/YYYY, HH:mm')} น.</p>
              </div>

              <div className='time'>
                <h4 className={ 'black' } >เวลาที่ใช้สอบ</h4>
                <p className={ 'black' } >{moment(testingDetail.testingTime*1000).format('mm') === '00' ? 0 : moment(testingDetail.testingTime*1000).format('mm')} นาที {moment(testingDetail.testingTime*1000).format('ss') === '00'? 0 : moment(testingDetail.testingTime*1000).format('ss')} วินาที</p>
              </div>
            </div>
          </div>

          <div className='result'>
            <h1>ผลการสอบ ({StaticType})</h1>
            <h4 className={ 'black' }>คะแนนรวม {testingDetail.score}/{ExamDetail.quantity} คะแนน</h4>
{/*<br/>คะแนนตาม{StaticType} {scoretypeget}/{scoretypetotal} คะแนน*/}

            
{/*
              ExamDetail.type === 'CAT' ? 
                <div className={ styles.cat_chart
//checkGrade === 'ป' ? styles.cat_chart_middle : styles.cat_chart 
} >
                   <div className={styles.lower_score} >
                    <div >อ่อน</div>
                  </div>
                  <div className={ styles.middle_score } >
                    <div style={{ paddingTop : '15px' }}>กลาง</div>
                  </div>
                  <div className={ styles.high_score } >
                    <div style={{ paddingTop : '15px' }}>เก่ง</div>
                  </div>
                  <div className={ styles.Popover_score } 
                    style={ window.screen.width > 991 ? { left: `${DotPosition - 65}px` } :
                      testingDetail.theta > 1.5 ? 
                        { left: `${window.screen.width - 163}px` } : 
                        testingDetail.theta > -1.5 ? 
                          { left: `${(window.screen.width/2) - 68}px` } :  
                          { left: '4px' }
                    }>
                    <div className={ styles.group_of_your_score } >
                      <p className={ styles.your_score } >คะแนนคุณอยู่ในกลุ่มนี้</p>
                      <p className={ styles.score } >{ testingDetail ? testingDetail.theta : 0}</p>
                    </div> 
                    <div className={ styles.arrow }></div>
                  </div>
                  <div className={ styles.dot } style={ 
                    window.screen.width > 991 ? { left: `${DotPosition}px` } : 
                      testingDetail.theta > 1.5 ? 
                        { left: `${window.screen.width - 163 + 63}px` } : 
                        testingDetail.theta > -1.5 ? 
                          { left: `${(window.screen.width/2) - 68 + 63}px` } :  
                          { left: '67px' }
                  }/>

                  <div className={ styles.score_range }>
                    <span className={ styles.score_one }>-3.5</span>
                    <span className={ styles.score_two}>-1.5</span>
                    <span className={ styles.score_three }>0</span>
                    <span className={ styles.score_four }>1.5</span>
                    <span className={ styles.score_five }>3.5</span>
                    <span className={ styles.theta } >ค่า Theta</span>
                  </div>  
                </div>
                : 
*/}
                <Chart
                  width={'100%'}
                  height={300}
                  chartType='ColumnChart'
                  loader={<div className='chart' style={{ color: 'black' }}>Loading Chart...</div>} 
                  data= { chartdata }
                  options={{ 
'chartArea': { top: '8%', left: '10%','width': '85%', }, //, 'width': '70%', 'height': '70%'
               'legend': {'position': 'bottom'},
                    // vAxes:[ { textStyle: { color: 'black'} } ], 
                    // hAxes:[ { textStyle: { color: 'black'} } ], 
                    tooltip: {
                      textStyle: {color: 'black'},
                      ignoreBounds: true,
                      trigger: 'focus'
                    },
                    bar: {
                      groupWidth: 16
                    },
                    backgroundColor:'transparent',
                    // colors: ['#2ED5B4', checkGrade === 'ป' ? '#14278D' : '#EFEFEF'],
                    colors: ['#2ED5B4', '#AAAAAA'],//'#026952'
//checkGrade === 'ป' ? '#14278D' : '#666'],
                    titleTextStyle: { color: 'black' },
                    hAxis: {
                      titleTextStyle: { color: 'black' },
                      title: {StaticType},
                      // minValue: 0,
                      textStyle: { color: 'black' }, 
                      gridlines: {
                        color: 'transparent'
                      },
                      baselineColor: 'transparent'
                    },
                    vAxis: {
                      format: 'percent',
                      title: 'อัตราการตอบถูก ('+StaticType+")",
                      titleTextStyle: { color: 'black' },
                      textStyle: { color: 'black' }, 
                      baselineColor: 'black',
                      gridlines: {
                        color: 'black', 
                      }, 
                    }, 
                    animation: {
                      duration: 1000,
                      easing: 'linear',
                      startup: true,
                    },
                    isStacked: true,
                    legend: 'none'
                  }}
                />
            
            
          </div>

          {/* <div className='course'>
            <p className={ 'black' }  style={{ fontSize: '16px', fontWeight: 'normal'}}>บทเรียน</p>
            <p className={ 'black' }  style={{ fontSize: '16px', fontWeight: '300'}}>1 - จำนวนนับไม่เกิน 100,000</p>
            <p className={ 'black' }  style={{ fontSize: '16px', fontWeight: '300'}}>2 - การบวกและการลบที่ผลลัพท์และ ตัวตั้งไม่เกิน 100,000</p>
            <p className={ 'black' }  style={{ fontSize: '16px', fontWeight: '300'}}>3 - การชั่ง การตวง</p>
          </div> */}

          {
            // checkGrade === 'ป' ?
            //   <div className='bg-attributes'>
            //     <div className={ checkGrade === 'ป' ? 'visible blue-star' : 'invisivle' }><img alt='icon-blue-start' src={BlueStarIcon}/></div>
            //     <div className={ checkGrade === 'ป' ? 'visible bg-alpha' : 'invisivle' }><img alt='backend-img-test-result' src={BackgroundImgTestResultFirst} /></div>
            //     <div className={ checkGrade === 'ป' ? 'visible girl' : 'invisivle' }><img alt='a-girl-pic' src={AGirlPic}/></div>
            //   </div>
            //   : ''
          }
        </div>

        <div className='desktop'>
          <Row >
            <Col span={12}>
              <div><h1 className={ 'black' }>ข้อมูลข้อสอบ</h1></div>
            </Col>
            {/* <Col span={12}> 
              <Button 
                type='primary'
                style={{ float: 'right' }} 
                onClick={e => {
                  e.stopPropagation()
                  this.onDownload()
                }}><img alt='icon-download' src={IconDownload}/><span style={{ color: '#05007A' }}>บันทึกใบผลสอบ</span></Button>
            </Col> */}
          </Row>
          <Row>
            <Col span={2}>
              <div className='subject-icon'>
                <img alt='icon-subject' src={ExamDetail.type === 'C' ? (ExamDetail.subject === 'คณิตศาสตร์' ? BigMathMatchIcon: BigScienceMatchIcon) : (ExamDetail.subject === 'คณิตศาสตร์'? BigBookMathIcon: BigBookScienceIcon)}/>
              </div>
            </Col>
            <Col span={3}>
              <div>
                <h3 className={ 'black' }>{ExamDetail.name}</h3>
                <span className={ 'black' } style={{ fontSize: '16px', fontWeight: '300' }}>{ExamDetail.code}</span>
              </div>
            </Col>
            <Col span={3} offset={2}>
              <div>
                <p className={ 'black' }>สาระการเรียนรู้</p>
                <p className={ 'black' }>จำนวน</p>
              </div>
            </Col>
            <Col span={3}>
              <div>
                <p  className={ 'black' } style={{ fontWeight: 300 }} >{ExamDetail.subject}</p>
                <p className={ 'black' } style={{ fontWeight: 300 }}>{ExamDetail.quantity} ข้อ</p>
              </div>
            </Col>
            <Col span={3}>
              <div>
                <p className={ 'black' }>ระดับชั้น</p>
                <p className={ 'black' }>จัดชุดโดย</p>
              </div>
            </Col>
            <Col span={3}>
              <div>
                <p className={ 'black' } style={{ fontWeight: 300 }}>{ExamDetail.grade}</p>
                <p className={ 'black' } style={{ fontWeight: 300 }}>{ExamDetail.owner ? ExamDetail.owner.firstName : ''} {ExamDetail.owner ? ExamDetail.owner.lastName : ''}</p>
              </div>
            </Col>
          </Row>
          <Divider/>
          <Row>
            <Col span={8} style={{ zIndex: 1 }}>
              <div>
                <h1 className={ 'black' } >ข้อมูลผู้เข้าสอบ</h1>
                <p className={ 'black' }  style={{ fontSize: '24px', fontWeight: '500' }}>{user.prefixName} {user.firstName} {user.lastName}</p>
                <p className={ 'black' }  style={{ fontSize: '18px', fontWeight: '300' }}>{user.school ? user.school.name.text : ''}</p>
                <p className={ 'black' }  style={{ fontSize: '16px', fontWeight: 'normal'}}>วันที่ทำข้อสอบ</p>
                <p className={ 'black' }  style={{ fontSize: '24px', fontWeight: 'normal' }}>{moment.unix(testingDetail.finishedAt).format('DD/MM/YYYY, HH:mm')} น.</p>
                <p className={ 'black' }  style={{ fontSize: '16px', fontWeight: 'normal'}}>เวลาที่ใช้สอบ</p>
                <p className={ 'black' }  style={{ fontSize: '24px', fontWeight: 'normal' }}>{moment(testingDetail.testingTime*1000).format('mm') === '00' ? 0 : moment(testingDetail.testingTime*1000).format('mm')} นาที {moment(testingDetail.testingTime*1000).format('ss') === '00'? 0 : moment(testingDetail.testingTime*1000).format('ss') } วินาที</p>
              </div>
            </Col>
            <Col span={16} style={{ zIndex: 1 }}>
              <div>
                <h1 className={ 'black' } >ผลการสอบ ({StaticType})</h1>
                
{/*
                  ExamDetail.type === 'CAT' ? 
                    <div className={ styles.cat_chart
//checkGrade === 'ป' ? styles.cat_chart_middle : styles.cat_chart 
} >
                       <div className={styles.lower_score} >
                        <div >อ่อน</div>
                      </div>
                      <div className={ styles.middle_score } >
                        <div style={{ paddingTop : '15px' }}>กลาง</div>
                      </div>
                      <div className={ styles.high_score } >
                        <div style={{ paddingTop : '15px' }}>เก่ง</div>
                      </div>
                      <div className={ styles.Popover_score } style={{ left: `${DotPosition - 65}px` }}>
                        <div className={ styles.group_of_your_score } >
                          <p className={ styles.your_score } >คะแนนคุณอยู่ในกลุ่มนี้</p>
                          <p className={ styles.score } >{ testingDetail ? testingDetail.theta : 0}</p>
                        </div> 
                        <div className={ styles.arrow }></div>
                      </div>
                      <div className={ styles.dot } style={{ left: `${DotPosition}px` }}></div> 
                      <div className={ styles.score_range }>
                        <span className={ styles.score_one }>-3.5</span>
                        <span className={ styles.score_two}>-1.5</span>
                        <span className={ styles.score_three }>0</span>
                        <span className={ styles.score_four }>1.5</span>
                        <span className={ styles.score_five }>3.5</span>
                        <span className={ styles.theta } >ค่า Theta</span>
                      </div>  
                    </div>
                    :
*/}

                    <Fragment>
                      <p className={ 'black' }  style={{ fontSize: '24px', fontWeight: '500' }}>คะแนนรวม {testingDetail.score}/{ExamDetail.quantity}</p> {/*<br/>คะแนนตาม{StaticType} {scoretypeget}/{scoretypetotal} คะแนน*/}
                      <Chart
                        width={800}
                        height={400}
                        chartType='ColumnChart'
                        loader={<div className='chart' style={{ color: 'black' }}>Loading Chart...</div>} 
                        data= { chartdata }
                        options={{ 
'chartArea': { top: '9%', left: '10%', 'width': '85%', }, //, 'width': '70%', 'height': '70%'
               'legend': {'position': 'bottom'},
                          // vAxes:[ { textStyle: { color: 'black'} } ], 
                          // hAxes:[ { textStyle: { color: 'black'} } ], 
                          tooltip: {
                            textStyle: {color: 'black'},
                            ignoreBounds: false,
                            trigger: 'focus'
                          },
                          bar: {
                            groupWidth: 16
                          },
                          backgroundColor:'transparent',
                          colors: ['#2ED5B4', '#AAAAAA'],//'#026952'
//                          colors: ['#2ED5B4', checkGrade === 'ป' ? '#000' : '#000'],
                          titleTextStyle: { color: 'black' },
                          hAxis: {
                      prefix: '$',

                            titleTextStyle: { color: 'black' },
                            title: {StaticType},
                            minValue: 0,
                            textStyle: { color: 'black' }, 
                            gridlines: {
                              color: 'transparent'
                            },
                            baselineColor: 'transparent'
                          },
                          vAxis: {
                            format: 'percent',
                            title: 'อัตราการตอบถูก ('+StaticType+")",
                            titleTextStyle: { color: 'black' },
                            textStyle: { color: 'black' }, 
                            baselineColor: 'black',
                            gridlines: {
                              color: 'black', 
                            }, 
                          }, 
                          animation: {
                            duration: 500,
                            easing: 'linear',
                            startup: true,
                          },
                          isStacked: true,
                          legend: 'none'
                        }}
                      />
                      <p className={ 'black' }  style={{ fontSize: '16px', fontWeight: 'normal'}}>{StaticType} ({codelist.length} จำนวน)</p>
                       { 
itemlist.sort((a, b) => (a.code > b.code) ? 1 : -1).map(function(i) { 
//console.log(i)
// return ''
return (<p key={i.code} className={ 'black' }  style={{ fontSize: '16px', fontWeight: '300'}}>{`${i.code} - ${i.name}`}</p>) 
}) 
}   
                    </Fragment>
                
              </div>
            </Col>
          </Row>

          {/* {
            checkGrade === 'ป' ?
              <div className='bg-attributes' style={ ExamDetail.type === 'CAT' ? { paddingTop: '179px' } : { paddingTop: '124px' }} > 
                <div className={ checkGrade === 'ป' ? 'visible blue-star' : 'invisivle' }><img alt='icon-blue-star' src={BlueStarIcon}/></div>
                <div className={ checkGrade === 'ป' ? 'visible bg-alpha' : 'invisivle' }><img alt='background-img-test' src={BackgroundImgTestResultFirst} /></div>
                {
                  user.prefixName === 'นาย' || user.prefixName === 'ด.ช.' || user.prefixName === 'นางสาว' || user.prefixName === 'ด.ญ.' ?
                    <div className={ checkGrade === 'ป' ? 'visible girl' : 'invisivle' }>
                      <img alt='icon-pic-gender' src={ user.prefixName === 'นาย' || user.prefixName === 'ด.ช.' ? ABoyPic : AGirlPic}/>
                    </div>
                    : null
                }
              </div>
              : ''
          } */}
        </div>
      </div>
    )
    return (
      <div >
        <Modal  
          destroyOnClose
          visible = { visibleModalStat }
          onCancel = { onCloseModalResultScore }
          footer = { false }
          centered
          bodyStyle = {{background: 'white'}}
          className= {styles.high_school}
        >
          { detail }
          <iframe title='ifmcontentstoprint' id='ifmcontentstoprint' style= {{ height: '0px', width: '0px', position: 'absolute', visibility: 'hidden' }} />
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return{
    auth: state.auth.profile
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // getStudentDetail,
      getExamSetDetailWithQuetion,
      getResultTesting,
      getExamStaticScore,
      openUserDetail
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ModalStat)
