import React, { Component } from 'react'
import { Spin } from 'antd'

import Header from '../Header.js'
import Overview from '../Overview.js'

class ReportUserAccountOverview extends Component {
  static defaultProps = {
    collection: [],
    isLoading: false
  }

  render(){
    const { collection, isLoading , year } = this.props
    return(
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading }>
          <Header headerName='รายงานชุดบัญชีผู้ใช้'
            closeSearch={this.closeSearch}
            match={this.props.match}
            data={collection}
            year={year} 
            isLogin={false}
            spinOnLoading={this.props.spinOnLoading}
            spinOffLoading={this.props.spinOffLoading}
            getTransaction={this.props.stateUserTransaction} 
          />

          <Overview data={collection}
            typeStatic={'account'}
            loadStats={this.props.loadStats}
            loadDetail={this.props.loadDetail}
            history={this.props.history}
            match={this.props.match}
            setYear={this.props.setYear}
            setMonth={this.props.setMonth}
            year={this.props.year}
            detail=''
          />
        </Spin>
      </div>)
  }
}

export default ReportUserAccountOverview
