import React, { Component, Fragment }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import * as _ from 'lodash'
import AvatarBase64 from '../Register/AvatarBase64'
import { generateSelectForm, generateSelectFormInitialValue, generateInputForm, generateAutoCompleteForm } from '../../util/formGenerator'
import { removeUndefinedProp, formatSchoolObject } from '../../util/helper'
import styles from '../Profile/Profile.module.scss'
import TopTitileComponent from '../Layout/TopTitleComponent'
import {
  getSchoolList, getSearchSchoolById
} from '../../redux/school/action'
import {
  message,
  Spin,
  Row,
  Col,
  Button,
  Divider,
  Form,
} from 'antd'
import {
  GetMasterData, onSchoolBySearch
} from '../../redux/master/action'
import { AxiosRequest } from '../../redux/fetch'
const ROLE_TEACHER = 'teacher'
const ROLE_STUDENT = 'student'
const SUPER_TEACHER = 'superTeacher' 
const API_SCHOOL_DETAIL = '/master/schools'
const formItemLayout = {
  colon: false,
  labelAlign: 'left',
  hideRequiredMark: true,
  labelCol: {
    md: { span: 10 },
    xs: { span: 24 },
  },
  wrapperCol: {
    md: { span: 14 },
    xs: { span: 24 },
  },
}
 
const regExpStrongPassword = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/
 
const AvatarResponsive = (props) => {
  // useMediaQuery can use in functional component only
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })
  return (
    <div className={`${styles.avatar}`} >
      <AvatarBase64 
        cropRadius={50}
        onChange={ props.onAvatarChange } 
        imageDefault={props.profileImage}
        size={ isTabletOrMobile ? 'small' : 'large' }
      />
    </div>)
}

class EditUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isFormDirty: false,
      profileImage: null,
      schoolAddress: null,
      newSchoolDetail: null,
      userDetailEdit: null,
      schoolsState: [],
      isSearchSchool: false,
      isNewSchool: false,
      showParentFields: false,
      birthYear: null,
    }
  }

  componentDidMount = async () => {
    await this.props.GetMasterData()
    const today = new Date()
    const thisBEYear = today.getFullYear() + 543
    const birthYear = this.props.userDataEdit ? this.props.userDataEdit.birthYear : -1
    const age = thisBEYear - birthYear
    const showParentFields = (age <= 10)
    this.setState({ 
      schoolsState: this.props.schools,
      birthYear: birthYear == -1 ? null : birthYear,
      showParentFields
    })
  }

  onAvatarChange = (imageBase64) => {
    this.setState({ profileImage: imageBase64 })
  }

  matchPassword = (rule, value, callback) => {
    if ( value && value !== this.props.form.getFieldValue('password') ) {
      callback('รหัสผ่านยืนยันไม่ตรงกัน')
    }
    callback()
  }

  onCancel = () => {
    this.props.onClickCloseEditUser()
  }
  onSelectSchool = async(schoolId) => { 
    await this.onUpdateSchoolDetail(schoolId) 
    this.setState({ 
      isFormDirty: false,
      isSearchSchool: false
    })
  }
  onSchoolChange = async (schoolName) => {   
    // const res = await this.props.onSchoolBySearch(schoolName) 
    const res = await this.props.getSchoolList(schoolName,1)    
    const existedSchool = res.items.find((school) => schoolName === school.name)   
    // await this.onUpdateSchoolDetail(existedSchool && existedSchool._id) 
    const isNewSchool = existedSchool ? false : true 
    this.setState({
      isNewSchool,
      isFormDirty: false, 
      currentSchoolDetail: existedSchool
    }) 
  }
  onUpdateSchoolDetail = async (schoolId) => { 
    // clear school detail on new school 
    if (!schoolId) {
      this.props.form.setFieldsValue({ addressNo: null, villageNo: null, lane: null, road: null, subDistrict: null, district: null, province: null, postalCode: null, department: null })
      return this.setState({ newSchoolDetail: null })
    }

    this.setState({ isLoading: true })
    const res = await AxiosRequest(API_SCHOOL_DETAIL, 'get', schoolId)
    this.setState({ isLoading: false }) 
    if (res.error) {
      this.setState({
        newSchoolDetail: null,
      })
      return message.error('ไม่สามารถรับข้อมูลโรงเรียนได้')
    }
    this.setState({
      newSchoolDetail: res,
    }) 
    const { name, addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department } = res
    this.props.form.setFieldsValue({ schoolName: name, addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department })
    // clear school detail on new school
    // if (!schoolId) return this.setState({schoolDetail: null}) 
    // this.setState({ isLoading: true })
    // const res = await AxiosRequest(API_SCHOOL_DETAIL, 'get', schoolId)
    // this.setState({ isLoading: false })
    // if (res.error) {
    //   this.setState({
    //     schoolDetail: null,
    //   })
    //   return message.error('ไม่สามารถรับข้อมูลโรงเรียนได้')

    // }
  }
  onSchoolChangeId = async(schoolId) => {  
    const existedSchool = await this.props.getSearchSchoolById(schoolId,1)   
    await this.onUpdateSchoolDetail(existedSchool && existedSchool._id) 
    const isNewSchool = existedSchool ? false : true
    this.setState({
      isNewSchool,
      isFormDirty: false, 
      currentSchoolDetail: existedSchool
    }) 
  }
  searchSchool = _.debounce(async(text) => { 
    const { onSchoolBySearch } = this.props
    const res = await onSchoolBySearch(text) 
    this.setState({ schoolsState: res.items, isSearchSchool: true })
  },500) 

  onSubmitEditProfile = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll( (err, values) => {
      this.setState({ isFormDirty: true }) 
      if (!err ) {
        let passObj = null
        if (values.confirm_password && values.password) {
         
          passObj = {
            old: values.old_password,
            new: values.password
          }
          delete values.old_password
          delete values.password
        }

        
        const { schoolName, addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department } = values
        const schoolData = {
          schoolName, addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department
        }
        
        //const publicIp = require('public-ip');

        // let ipadd = null
        // (async () => {
        //   ipadd = await publicIp.v4()
        // })();
        //req.headers['x-forwarded-for'] || req.socket.remoteAddress 

        const { showParentFields } = this.state
        const parentPrefix = values.parentPrefix
        const parentFirstName = values.parentFirstName
        const parentLastName = values.parentLastName
        const relation = values.relation
        const parentEmail = values.parentEmail
        const parentTelephone = values.parentTelephone

        const parent = showParentFields ? {
          parentPrefix: parentPrefix,
          parentFirstName: parentFirstName,
          parentLastName: parentLastName,
          parentEmail: parentEmail,
          parentTelephone: parentTelephone,
          relation: relation,
        } : null

        const params = {
          prefixName: values.prefixName,
          firstName: values.firstName,
          lastName: values.lastName,
          school: formatSchoolObject(schoolData, this.state.newSchoolDetail),
          profileImage: this.state.profileImage,
          email: values.email,
          gender: values.gender,
          password: passObj,
          role: values.role,
          TicketNumber: values.TicketNumber,
          TicketRemark: values.TicketRemark,
          // ipaddress: ipadd,
          id: this.props.userDataEdit._id,
          parent: parent,
          birthYear: values.birthYear,
        }
        this.props.onUpdateUser(params)
        
        removeUndefinedProp(params)
      } 
    })
  }

  getPrefixesSpecificRole = () => {
    const { prefixes } = this.props  
    const role = "teacher"
    return prefixes.filter(prefixes => prefixes.visible[role])
  }

  getRelations = () => {
    const { relations } = this.props
    return relations.filter(relation => relation.visible)
  }

  onSelectBirthYear = (value) => {
    const today = new Date()
    const thisBEYear = today.getFullYear() + 543
    const age = thisBEYear - value
    // console.log("*** AGE ***")
    // console.log(age)
    // console.log("*** AGE ***")
    const showParentFields = (age <= 10)
    this.setState({
      birthYear: value,
      showParentFields
    })
  }


  render() {
    const { form, profile, prefixes, provinces, schools, departments, userDataEdit } = this.props 
    const { isFormDirty , schoolsState, isSearchSchool } = this.state
    let { role, prefixName, firstName, lastName , school } = userDataEdit
    const isTeacher = role === ROLE_TEACHER 
    const isStudent = role === ROLE_STUDENT
    const isSuperTeacher = role === SUPER_TEACHER   
    const isNewSchool = this.state.isNewSchool 
    const prefixesByRole = prefixes.filter(prefix => {
      if(isTeacher || isSuperTeacher){
        return prefix.visible.teacher
      }else if(isStudent){
        return prefix.visible.student
      }
      return prefix
    }) 
    const selectPrefix = generateSelectFormInitialValue({ options: prefixesByRole, fieldName: 'prefixName', form, label: 'คำนำหน้า', isFormDirty, isRequire: true, initialValue: prefixName, nameAsValue: true })
    const inputFirstName = generateInputForm({ fieldName: 'firstName', form, label: 'ชื่อ', isFormDirty, isRequire: true, initialValue: firstName })
    const inputLastName = generateInputForm({ fieldName: 'lastName', form, label: 'นามสกุล', isFormDirty, isRequire: true, initialValue: lastName })
    
    const inputEmail = generateInputForm({ fieldName: 'email', form, label: 'อีเมล', isFormDirty, isRequire: true, initialValue: userDataEdit ? userDataEdit.email : null, disabled : true  
      , customRules: [
        { required: isTeacher, message: 'กรุณาใส่อีเมล' },
        { type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' }
      ]})

    const inputUsername = this.props.userDataEdit && this.props.userDataEdit.username ? generateInputForm({ fieldName: 'inputForEdit', form, label: 'ชื่อผู้ใช้งาน', isFormDirty, isRequire: false, initialValue: this.props.userDataEdit.username ? this.props.userDataEdit.username : '', disabled : role !== 'admin' ? true : false, role}) : null
    
    const inputPassword = generateInputForm({ fieldName: 'password', form, label: 'รหัสใหม่', isFormDirty, limit: 20, customRules: [
      { min: 6, message: 'มีอย่างน้อย 6 ตัว' },
      { pattern: regExpStrongPassword, message: 'ประกอบด้วยตัวอักษรภาษาอังกฤษและตัวเลข' },
    ], props: {
      type: 'password',
      autoComplete: 'new-password',
    } })
    const inputConfirmPassword = generateInputForm({ fieldName: 'confirm_password', form, label: 'ยืนยันรหัสผ่าน', isFormDirty, limit: 20, customRules: [
      { validator: this.matchPassword }
    ], props: {
      type: 'password',
      autoComplete: 'new-password',
    } })
    
    let initSchool = {}
    if (school) {
      // set initial school value from profile
      initSchool = {
        schoolName    : school.name.text,
        addressNo     : school.addressNo ? school.addressNo.text : '',
        villageNo     : school.villageNo ? school.villageNo.text : '',
        lane          : school.lane ? school.lane.text : '',
        road          : school.road ? school.road.text : '',
        subDistrict   : school.subDistrict ? school.subDistrict.text : '',
        district      : school.district ? school.district.text : '',
        province      : school.province ? school.province.id : '',
        postalCode    : school.postalCode ? school.postalCode.text : '',
        department    : school.department ? school.department.text : '',
      }
    } 
    const selectSchoolName = generateAutoCompleteForm({ options: isSearchSchool ? schoolsState : schools, fieldName: 'schoolName', form, label: isTeacher ? 'โรงเรียน/หน่วยงาน' : 'โรงเรียน', isFormDirty, isRequire: true, initialValue: initSchool.schoolName, nameAsValue: true, freeInput: true, placeholder: '', 
      handleSelectChange: this.onSchoolChange , searchSchool: this.searchSchool, onSelectSchool: this.onSelectSchool,onSchoolChangeId: this.onSchoolChangeId
    })
    const inputAddressNo = generateInputForm({ fieldName: 'addressNo', form, label: 'เลขที่', isFormDirty, isRequire: isNewSchool || isTeacher, initialValue: initSchool.addressNo, limit: 10 })
    const inputVillageNo = generateInputForm({ fieldName: 'villageNo', form, label: 'หมู่ที่', isFormDirty, isRequire: false, initialValue: initSchool.villageNo, limit: 10 })
    const inputLane = generateInputForm({ fieldName: 'lane', form, label: 'ซอย', isFormDirty, isRequire: false, initialValue: initSchool.lane})
    const inputRoad = generateInputForm({ fieldName: 'road', form, label: 'ถนน', isFormDirty, isRequire: false, initialValue: initSchool.road })
    const inputSubDistrict = generateInputForm({ fieldName: 'subDistrict', form, label: 'เขต/อำเภอ', isFormDirty, isRequire: isNewSchool || isTeacher, initialValue: initSchool.subDistrict })
    const inputDistrict = generateInputForm({ fieldName: 'district', form, label: 'แขวง/ตำบล', isFormDirty, isRequire: isNewSchool || isTeacher, initialValue: initSchool.district })
    const selectProvince = generateSelectFormInitialValue({ options: provinces, fieldName: 'province', form, label: 'จังหวัด', isFormDirty, isRequire: true, initialValue: initSchool, nameAsValue: false, freeInput: false, placeholder: 'กรุณาเลือก',editUser: true })
    const inputPostalCode = generateInputForm({ fieldName: 'postalCode', form, label: 'รหัสไปรษณีย์', isFormDirty, isRequire: isTeacher ? true : false, initialValue: initSchool.postalCode ,customRules: [
      { required: isTeacher ? true : false, pattern: /^[0-9]{5}$/ , message: 'กรุณาใส่รหัสไปรษณีย์ให้ครบ 5 หลัก' } ]})
    const selectDepartment = generateSelectFormInitialValue({ options: departments, fieldName: 'department', form, label: 'สังกัด', isFormDirty, isRequire: isTeacher ? true : false, initialValue: initSchool.department, nameAsValue: true, freeInput: true, placeholder: 'กรุณาเลือก' })
    
    const schoolFields = userDataEdit.role === 'student' ? [selectSchoolName,selectProvince] : [
      selectSchoolName,
      inputAddressNo,
      inputVillageNo,
      inputLane,
      inputRoad,
      inputDistrict,
      inputSubDistrict,
      selectProvince,
      inputPostalCode,
      selectDepartment,
    ]

    const inputTicketNumber = generateInputForm({ fieldName: 'TicketNumber', form, label: 'หมายเลข ticket', isFormDirty, isRequire: true }) //, initialValue: TicketNumber
    const inputTicketRemark = generateInputForm({ fieldName: 'TicketRemark', form, label: 'หมายเหตุ', isFormDirty, isRequire: false, limit: 200}) //, initialValue: TicketNumber

    // dev/ots2023
    const today = new Date()
    const thisBEYear = today.getFullYear() + 543
    const maxAge = 70
    const birthYearOptions = [...Array(maxAge).keys()]
      .map(y => (-1)*y + thisBEYear)
      .map(y => ({ id: y, name: y }))
    const { birthYear } = this.state
    const selectBirthYear = generateSelectForm({ options: birthYearOptions, 
      fieldName: 'birthYear', form, label: 'ปี พ.ศ. เกิด',
      isFormDirty, isRequire: false, initialValue: null,
      nameAsValue: false, freeInput: false, placeholder: 'กรุณาระบุปี พ.ศ. เกิด',
      isRegister: false, handleSelectChange: this.onSelectBirthYear,
      isEmph: false, initialValue: birthYear,
    })

    const parentPrefix = userDataEdit.parent ? userDataEdit.parent.parentPrefix : null
    const parentFirstName = userDataEdit.parent ? userDataEdit.parent.parentFirstName : null
    const parentLastName = userDataEdit.parent ? userDataEdit.parent.parentLastName : null
    const relation = userDataEdit.parent ? userDataEdit.parent.relation : null
    const parentEmail = userDataEdit.parent ? userDataEdit.parent.parentEmail : null
    const parentTelephone = userDataEdit.parent ? userDataEdit.parent.parentTelephone : null

    const { showParentFields } = this.state

    const selectParentPrefix = generateSelectForm({ options: this.getPrefixesSpecificRole(), fieldName: 'parentPrefix', form, label: 'คำนำหน้า', isFormDirty, isRequire: showParentFields, initialValue: null, nameAsValue: true, isRegister:false, placeholder:'เลือกคำนำหน้า', initialValue: parentPrefix })
    const inputParentFirstName = generateInputForm({ fieldName: 'parentFirstName', form, label: 'ชื่อ', isFormDirty, isRequire: showParentFields, isRegister: false, initialValue: parentFirstName })
    const inputParentLastName = generateInputForm({ fieldName: 'parentLastName', form, label: 'นามสกุล', isFormDirty, isRequire: showParentFields, isRegister: false, initialValue: parentLastName })
    const selectRelation = generateSelectForm({ options: this.getRelations(),
      fieldName: 'relation', form, label: 'ความสัมพันธ์', isFormDirty,
      isRequire: showParentFields, initialValue: relation, nameAsValue: true, isRegister: false,
      placeholder: 'เลือกความสัมพันธ์' })

    const inputParentEmail = generateInputForm({ fieldName: 'parentEmail', form, label: 
      'อีเมล',isRegister: false, isFormDirty, limit: 30, initialValue: parentEmail,
      customRules: [
        { required: showParentFields, message: 'กรุณาใส่อีเมล' },
        { type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' },
        // { validator: async (_, value) => {
        //   if (value != email) {
        //     return Promise.resolve()
        //   }
        //   else {
        //     return Promise.reject(new Error('อีเมลของผู้ปกครองต้องไม่ซ้ำกับอีเมลของนักเรียน')) 
        //   }
        // }}
      ]
    })

    const inputParentTelephone = generateInputForm({
      fieldName: 'parentTelephone', form, label: 'โทรศัพท์บ้าน/มือถือ', 
      isRegister: true, isFormDirty, limit: 10, isRequire: false, 
      initialValue: parentTelephone,
      customRules: [
        // { required: false, message: 'กรุณาใส่หมายเลขโทรศัพท์' },
        { regexp: /0\d+/, min: 9, max: 10, message: 'กรุณาระบุเฉพาะหมายเลขโทรศัพท์ โดยไม่ต้องใส่ขีด'}
      ],
      props: {
        type: 'number'
      }
    })


    return ( 
      <div className={styles.profileDiv}>
        <TopTitileComponent title={'แก้ไขบัญชีผู้ใช้'} searchBar={false}/>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading || !profile} >
          <Form {...formItemLayout} onSubmit={this.onSubmitEditProfile} >
            { profile 
              ? (
                <div>
                <Row gutter={30}>
                <Col sm={{span:24}}>
                  <div style= {{ borderRadius: '50px' }}>
                    <AvatarResponsive
                      borderStyle={{borderRadius: '50%'}}
                      onAvatarChange={ this.onAvatarChange }
                      profileImage={userDataEdit.profileImage}
                      style= {{ borderRadius: '50%' }}
                    />
                  </div>
                </Col>
                <Col xl={{span:9}} lg={{span:12}} sm={{span:24}}>
                  <p className={`${styles.formHead} text-medium`}>ทั่วไป</p>
                  { selectPrefix }
                  { inputFirstName }
                  { inputLastName }
                  { inputEmail } 
                  {
                    userDataEdit.username
                      ? inputUsername
                      : ''
                  }
                  {/* { selectGender } */}
                  { !isTeacher && selectBirthYear }
                  <p className={`${styles.formHead} text-medium`}>รหัสผ่าน</p>
                  { inputPassword }
                  { inputConfirmPassword }
                </Col>
                <Col xl={{span:9, offset:2}} lg={{span:12}} sm={{span:24}}>
                  <p className={`${styles.formHead} text-medium`}>โรงเรียนและหน่วยงานที่สังกัด</p>
                  { schoolFields }
                  {showParentFields &&
                    <Fragment>
                    <p className={`${styles.formHead} text-medium`}>ผู้ปกครอง</p>
                    { selectParentPrefix }
                    { inputParentFirstName }
                    { inputParentLastName }
                    { selectRelation }
                    { inputParentEmail }
                    { inputParentTelephone }
                    </Fragment>
                  }
                </Col>
              </Row>
              <div className={styles.flex_space_footer}>
                <Divider/>
              </div>
              <Row gutter={30}>  
                <Col xl={{span:9}} lg={{span:12}} sm={{span:24}}>
                  <p className={`${styles.formHead} text-medium`}>การแก้ไขข้อมูลผู้ใช้สำหรับผู้ดูแลระบบ</p>
                  { inputTicketNumber }
                  { inputTicketRemark }
                </Col>
              </Row>
              </div>
              ) 
              : null }
            <div className={styles.flex_space_footer}>
              <Divider/>
              <div className='flex-space-between '>
                <Button type='link' className={`${styles.btn_cancel} text_underline`} onClick={this.onCancel}>ยกเลิก</Button>
                <Button type='primary' htmlType='submit' className={styles.btn_ok}>บันทึก</Button>
              </div>
            </div>
          </Form>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    prefixes: state.master.prefixes,
    profile: state.auth.profile,
    provinces: state.master.provinces,
    schools: state.master.schools,
    departments: state.master.departments,
    relations: state.master.relations,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetMasterData,
      onSchoolBySearch,
      getSchoolList,
      getSearchSchoolById
    },
    dispatch
  )
const WrappedFormEditUser = Form.create({ name: 'form_edit_user' })(EditUser)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedFormEditUser))