import moment from 'moment'
import localization from 'moment/locale/th'
moment.updateLocale('th', localization) 

export const debounce = (func, wait, immediate) => {
  var timeout
  return function() {
    var context = this, args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const setCookie = (cname, value, expiredHour = 0) => {
  let expires = ''
  if (expiredHour) {
    const date = new Date()
    date.setTime(date.getTime() + (expiredHour * 60 * 60 * 1000))
    expires = `expires=${date.toUTCString()};`
  }
  document.cookie = `${cname}=${value};${expires}path=/` // eslint-disable-line
}

export const deleteCookie = (cname) => {
  document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/` // eslint-disable-line
}

export const getCookie = (cname) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie) // eslint-disable-line
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

export const isEmptyObj = (obj) => {
  return (Object.keys(obj).length === 0 && obj.constructor === Object)
}

export const removeUndefinedProp = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeUndefinedProp(obj[key])
    else if (obj[key] === undefined) delete obj[key]
  })
  return obj
}

export const dateFromTimeStamp = (ts) => {
  if (!ts) return '-'
  const dt = moment(ts * 1000)
  return dt.isValid() ? dt.add(543, 'years').format('DD/MM/YYYY') : '-'
}

export const isInArray = (array, finder) =>  -1 !== array.indexOf(finder)

export const formatSchoolObject = (data, oriData) => {
  let { name,schoolName, addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department } = data
  
  const isNewSchool = oriData === null
  if (!isNewSchool) {
    name         = name || oriData.name
    addressNo     = addressNo || oriData.addressNo
    villageNo     = villageNo || oriData.villageNo
    lane          = lane || oriData.lane
    road          = road || oriData.road
    subDistrict   = subDistrict || oriData.subDistrict
    district      = district || oriData.district
    province      = province || oriData.province
    postalCode    = postalCode || oriData.postalCode
    department    = department || oriData.department
  }
  const params = {
    name: { text: name || schoolName, isModified: isNewSchool || (oriData.name !== name) },
    addressNo: { text: addressNo, isModified: isNewSchool || (oriData.addressNo !== addressNo) },
    villageNo: { text: villageNo, isModified: isNewSchool || (oriData.villageNo !== villageNo) },
    lane: { text: lane, isModified: isNewSchool || (oriData.lane !== lane) },
    road: { text: road, isModified: isNewSchool || (oriData.road !== road) },
    subDistrict: { text: subDistrict, isModified: isNewSchool || (oriData.subDistrict !== subDistrict) },
    district: { text: district, isModified: isNewSchool || (oriData.district !== district) },
    province: { id: province, isModified: isNewSchool || (oriData.province !== province) }, // province use id ***
    postalCode: { text: postalCode, isModified: isNewSchool || (oriData.postalCode !== postalCode) },
    department: { text: department, isModified: isNewSchool || (oriData.department !== department) },
  }
  return params
}

export const cloneArray = (items) => items.map(item => Array.isArray(item) ? cloneArray(item) : item)

export const parseExamType = examType => {
  switch (examType) {
    case 'G': return 'ตามหลักสูตร'
    case 'C': return 'แข่งขัน'
    case 'CAT': return 'CAT'
    case 'CUSTOM': return 'กำหนดเอง'
    case 'EX': return 'แบบฝึกหัด'
    case 'E': return 'แบบฝึกหัด'
    default: return '-'
  }
}

export const snapLocationRedirect = (location) => {
  localStorage.setItem('redirectPath', location)
}

export const getLocationRedirect = () => localStorage.getItem('redirectPath')

export const addMedalTier = (studentList) => {
  let medal = 0, index = 0
  if (studentList.length > 0) {
    while (medal <= 2 && studentList.length > index) {
      if (index === 0) {
        studentList[0].medal = medal
      } else {
        if (studentList[index].latestScore == studentList[index-1].latestScore) {
          studentList[index].medal = medal
        } else {
          studentList[index].medal = ++medal
        }
      }
      index++
    }
  }
  return studentList
}