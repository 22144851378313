import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Upload,
  message, 
  Slider
} from 'antd'
import AvatarEditor from 'react-avatar-editor'
import ImgAvatar from '../../assets/ImgAvatar.svg'
import ImgCamera from '../../assets/ImgCamera.svg'
import IconRotate from '../../assets/IconRotate.svg'
import ZoomMin from '../../assets/zoomMin.svg'
import ZoomMax from '../../assets/zoomMax.svg' 
import IconCorrectSecondary from '../../assets/IconCorrectSecondary.svg'
import IconInCorrectSecondary from '../../assets/IconInCorrectSecondary.svg'
import styleRespons from './AvatarBase64.module.scss' 

// var EXIF = require('exif-js')
const styles = {
  uploadButton: {
    position: 'relative',
    marginBottom: 5,
    cursor: 'pointer',
  },
  circleImage: {
    borderRadius: '50%',
  },
  iconCamera: {
    position: 'absolute',
    bottom: 5,
    right: 7,
  },
  avatarWrapper: {
    display: 'inline-block',
  },
  avatarEditor: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  iconCorrect: {
    marginLeft: '10px',
    fontSize: 40,
    cursor: 'pointer',
  },
  iconIncorrect: {
    marginRight: '10px',
    fontSize: 40,
    cursor: 'pointer',
  },
  curSorPoint: {
    cursor: 'pointer',
  }
}

const imageType = ['image/jpeg', 'image/png', 'image/gif']

class AvatarBase64 extends Component {

  constructor(props) {
    super(props)
    this.state = {
      imageFile: null,
      imageBase64: null,
      allowZoomOut : true,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: null,
      width: 240,
      height: 256,
    }
  }
   
  beforeUpload = (file) => {
    const isImage = imageType.indexOf(file.type) >= 0
    if (!isImage) {
      message.error('สามารถเลือกรูปภาพ jpg, jpeg, png หรือ gif เท่านั้น')
    }
    const isLt2M = file.size / 1024 / 1024 < 5
    if (!isLt2M) {
      return message.error('รูปภาพขนาดไม่เกิน 5 MB')
    }

    this.setState({
      imageFile: file,
    })
    
    return false // no need to upload
  }
  handleScale = e => {
    const scale = parseFloat(e)
    this.setState({ scale })
  }

  onCancel = () => {
    this.props.onChange(null)
    this.setState({
      imageFile: null,
      imageBase64: null,
    })
  }
  onSave = () => { 
    if (this.avatarEditor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      // const imageBase64 = this.avatarEditor.getImage().toDataURL()

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const imageBase64 = this.avatarEditor.getImageScaledToCanvas().toDataURL()
      this.props.onChange(imageBase64)
      this.setState({
        imageFile: null,
        imageBase64,
      })
      return imageBase64
    }
    return null
  }

  setAvatarEditorRef = (ref) => this.avatarEditor = ref

  render() {
    const { imageDefault } = this.props 
    const imageFile = this.state.imageFile
    const imageBase64 = this.state.imageBase64
    let frameIconSize = 150
    let cameraIconSize = 35

    if (this.props.size === 'large') {
      frameIconSize = 170
      cameraIconSize = 53
    }
     
    const ImageAvatarProfile = (
      <Fragment>
        <img src={ imageDefault || ImgAvatar} alt='ImgAvatar' width= {frameIconSize} height = {frameIconSize} style={{ borderRadius: '50%'}} />
        <img src={ImgCamera} style={styles.iconCamera} alt='ImgCamera' width={cameraIconSize} height='auto'/>
      </Fragment>
    )  
    return (
      <div style={{minHeight: 130}}>
        { !imageFile ?
          <div state = {{width : 'auto'}}>
            <Upload
              name='avatar'
              accept={imageType.join(',')}
              showUploadList={false}
              beforeUpload={this.beforeUpload}
            >
              <div style={{...styles.uploadButton, width: frameIconSize, height: frameIconSize}}>
                { imageBase64 ?
                  <img src={imageBase64} style={styles.circleImage} alt='avatar' width ={ frameIconSize } height = {frameIconSize} /> :
                  ImageAvatarProfile
                }
              </div>
            </Upload>
            <div style={{paddingTop: '10px', textAlign: 'cenetr'}}>
              {
                this.props.isGroupDetail ? this.props.isGroupDetail === true ?
                  ''
                  :
                  <Fragment>
                    <p className='text-sm text-thin'>แนบไฟล์ (jpg, jpeg, png หรือ gif <br/><span style = {{textAlign : 'center', marginLeft : '3%'}}> ขนาดไม่เกิน 5 MB)</span></p>
                  </Fragment>
                  :
                  <Fragment>
                    <p className='text-sm text-thin'>แนบไฟล์ (jpg, jpeg, png หรือ gif <br/><span style = {{textAlign : 'center', marginLeft : '3%'}}> ขนาดไม่เกิน 5 MB)</span></p>
                  </Fragment>
              }
              
            </div>
          </div>
          :
          <Fragment>
            <div style={{...styles.avatarWrapper, width: frameIconSize, height: frameIconSize}}>
              <div style = {{textAlign : 'center'}}> 
                <AvatarEditor
                  ref={this.setAvatarEditorRef}
                  image={this.state.imageFile}
                  width={this.state.width}
                  height={this.state.height}
                  border={0}
                  color={[255, 255, 255, 0.6]}
                  scale={this.state.scale}
                  rotate={this.state.rotate}
                  style={styles.avatarEditor}
                />
                <div style= {{width: frameIconSize, display: 'flex'}}>
                  <img src = {ZoomMin} alt='ZoomMin'  style={styles.curSorPoint}
                    onClick = {()=>{this.setState({
                      scale : this.state.scale >= 0 ? this.state.scale - 0.1 : this.state.scale
                    })}}/>
                  <div style = {{width: '168px'}}>
                    <Slider 
                      onChange={(e)=>this.handleScale(e)}
                      step={0.01}
                      min={1}
                      max={3}
                      value={this.state.scale}
                      tooltipVisible = {false}
                    />
                  </div>
                  <img src = {ZoomMax} alt='ZoomMax' style={styles.curSorPoint}
                    onClick = {()=>{this.setState({
                      scale : this.state.scale <= 3 ? this.state.scale + 0.1 : this.state.scale
                    })}}/>
                </div>
                <div style = {{textAlign : 'center', marginBottom : 20}}>
                  <img src = {IconRotate} alt = 'IconRotate'
                    style={styles.curSorPoint,{width : 18, height : 18}}
                    type = 'link'
                    onClick = {()=> {
                      this.setState({
                        rotate : (this.state.rotate + 90)
                      })
                    }
                    }
                  />
                  <span className = {styleRespons.labelRotatePic}>
                    หมุนรูปภาพ
                  </span>
                </div>
                <div>
                  <img src = {IconInCorrectSecondary} alt = 'IconInCorrectSecondary' onClick={this.onCancel} style = {{marginRight : 18, width : 36}}></img>
                  <img src = {IconCorrectSecondary} alt = 'IconCorrectSecondary' onClick={this.onSave} style = {{ width : 36}}></img>
                </div>
              </div>
            </div>
            {/* <div style = {{width: '100%'}}>
              {
                window.location.pathname === '/register' ?
                  <div>
                    <img src = {IconInCorrectSecondary} alt = 'IconInCorrectSecondary' onClick={this.onCancel}></img>
                    <img src = {IconCorrectSecondary} alt = 'IconCorrectSecondary' onClick={this.onSave}></img>
                  </div>
                  :
                  <div style = {{}}>
                    <IconIncorrect style={styles.iconIncorrect} onClick={this.onCancel}/>
                    <IconCorrect style={styles.iconCorrect}  onClick={this.onSave}/>
                  </div>
              }
            </div> */}
          </Fragment>
        }
      </div>
    )
  }
}

AvatarBase64.propTypes = {
  onChange: PropTypes.func.isRequired,
  imageDefault: PropTypes.node,
  frameWidth: PropTypes.number,
  frameHeight: PropTypes.number,
}

const mapStateToProps = (state) => {
  return {   
    profile: state.auth.profile,
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
       
    },
    dispatch
  )
export default connect(mapStateToProps,mapDispatchToProps)(AvatarBase64)