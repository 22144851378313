import { ShowLoading } from './view/action'
import axios from 'axios'
import { deleteCookie } from '../util/helper'

export const BASE_URL = `${process.env.REACT_APP_API_URL}` || '/api'
axios.defaults.baseURL = BASE_URL

export const SetAxiosToken = (token) => {
  if (token) {
    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      Authorization: `Bearer ${token}`,
    }
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

export const AxiosRequest = async ( url, method, params ) => {
  method = method.toLowerCase()
  const config = { url: url, method: method }
  if (params) {
    if (method === 'post' ||
        method === 'put'  ||
        method === 'patch') {
      config.data = params
    } else {
      if (typeof(params) === 'string') {
        config.url = `${config.url}/${params}`
      } else {
        config.params = params
      }
    }
  }
  try {
    const res = await axios(config) 
    return (res && res.data) || { error: '' }
  } catch (e) {   
    // window.location.reload()
    return { error: GetAxiosErrorMessage(e), code: (e && e.response && e.response.status) || 500 }
  }
}

export const AxiosRequestGroupDetail = async ( url, method, params ) => {
  method = method.toLowerCase()
  const config = { url: url, method: method }
  if (params) {
    if (method === 'post' ||
        method === 'put'  ||
        method === 'patch'  ||
        method === 'delete') {
      config.data = params
    } else {
      if (typeof(params) === 'string') {
        config.url = `${config.url}`
      } else {
        config.params = params
      }
    }
  }
  try {
    const res = await axios(config) 
    return (res && res.data) || { error: '' }
  } catch (e) { 
    return { error: GetAxiosErrorMessage(e), code: (e && e.response && e.response.status) || 500 }
  }
}

const GetAxiosErrorMessage = (e) => { 
  const errorData = (e && e.response && e.response.data) || null
  if((e.response && e.response.statusText === 'Unauthorized') || (e.response && e.response.data && e.response.data.message === 'กรุณายืนยันตัวตน')) { 
    window.location.href = '/login'
  }
  return errorData ?
    (errorData.errors[0] && errorData.errors[0].message) ||
    errorData.message : 'Unknown'
}

export const FetchCycle = ( fetchData, successAction = null, errorAction = null, isLoadBackground = false ) => {
  return async (dispatch, getState) => {
    const state = getState()
    if ( !isLoadBackground && state.view.isLoading) return
    if (isLoadBackground) dispatch(ShowLoading(true))

    const res = await fetchData()
    if (isLoadBackground) dispatch(ShowLoading(false))
    if (res.error) {
      checkUnauthorized(res.code)
      if (errorAction) dispatch(errorAction(res.error))
    } else {
      if (successAction) dispatch(successAction(res))
    } 
    return res
  }
}

const checkUnauthorized = (code) => {
  if (code === 401) {
    deleteCookie('token')
    SetAxiosToken(null)
    // TODO : delete cookie
    // eslint-disable-next-line no-undef
    // window.location.reload()
  }
}
