const cookieBlocks = [
  {
    description:
      'คุณสามารถเลือกการตั้งค่าคุกกี้โดยเปิด/ปิด คุกกี้ในแต่ละประเภทได้ตามความต้องการ ยกเว้น คุกกี้ที่มีความจำเป็น',
  },
  {
    title: 'คุกกี้ที่มีความจำเป็น',
    description:
      'คุกกี้ประเภทนี้มีความจำเป็นต่อการให้บริการเว็บไซต์ของ สสวท. เพื่อให้ท่านสามารถเข้าใช้งานในส่วนต่าง ๆ ของเว็บไซต์ได้ รวมถึงช่วยจดจำข้อมูลที่ท่านเคยให้ไว้ผ่านเว็บไซต์ การปิดการใช้งานคุกกี้ประเภทนี้จะส่งผลให้ท่านไม่สามารถใช้บริการในสาระสำคัญของ สสวท. ซึ่งจำเป็นต้องเรียกใช้คุกกี้ได้',
    toggle: {
      value: 'necessary',
      enabled: true,
      readonly: true, // แก้ไขไม่ได้
    },
    cookie_table: [
      {
        col1: 'token'
      },
      {
        col1: 'cc_cookie'
      }
    ]
  },
  {
    title: 'คุกกี้เพื่อการวิเคราะห์และประเมินผลการใช้งาน',
    description:
      'คุกกี้ประเภทนี้ช่วยให้ สสวท. ทราบถึงการปฏิสัมพันธ์ของผู้ใช้งานในการใช้บริการเว็บไซต์ของ สสวท. รวมถึงหน้าเพจหรือพื้นที่ใดของเว็บไซต์ที่ได้รับความนิยม ตลอดจนการวิเคราะห์ข้อมูลด้านอื่น ๆ สสวท. ยังใช้ข้อมูลนี้เพื่อการปรับปรุงการทำงานของเว็บไซต์ และเพื่อเข้าใจพฤติกรรมของผู้ใช้งานมากขึ้น ถึงแม้ว่า ข้อมูลที่คุกกี้นี้เก็บรวบรวมจะเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ และนำมาใช้วิเคราะห์ทางสถิติเท่านั้น การปิดการใช้งานคุกกี้ประเภทนี้จะส่งผลให้ สสวท. ไม่สามารถทราบปริมาณผู้เข้าเยี่ยมชมเว็บไซต์ และไม่สามารถประเมินคุณภาพการให้บริการได้',
    toggle: {
      value: 'analytics', 
      enabled: true,
      readonly: false,
    },
    cookie_table: [
      // list of all expected cookies
      {
        col1: '^_ga', // match all cookies starting with "_ga"
        is_regex: true,
      },
      {
        col1: '^_fbp',
        is_regex: true,
      },
    ],
  },
  {
    title: 'คุกกี้เพื่อการใช้งานเว็บไซต์',
    description:
      'คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ของ สสวท. จดจำตัวเลือกต่าง ๆ ที่ท่านได้ตั้งค่าไว้และช่วยให้เว็บไซต์ส่งมอบคุณสมบัติและเนื้อหาเพิ่มเติมให้ตรงกับการใช้งานของท่านได้ เช่น ช่วยจดจำชื่อบัญชีผู้ใช้งานของท่าน หรือจดจำการเปลี่ยนแปลงการตั้งค่าขนาดฟอนต์หรือการตั้งค่าต่าง ๆ ของหน้าเพจซึ่งท่านสามารถปรับแต่งได้ การปิดการใช้งานคุกกี้ประเภทนี้อาจส่งผลให้เว็บไซต์ไม่สามารถทำงานได้อย่างสมบูรณ์',
    toggle: {
      value: 'functional', 
      enabled: false,
      readonly: false,
    },
    cookie_table: [
    ],
  },
  {
    title: 'คุกกี้เพื่อการโฆษณาไปยังกลุ่มเป้าหมาย',
    description:
      'คุกกี้ประเภทนี้เป็นคุกกี้ที่เกิดจากการเชื่อมโยงเว็บไซต์ของบุคคลที่สาม ซึ่งเก็บข้อมูลการเข้าใช้งานและเว็บไซต์ที่ท่านได้เข้าเยี่ยมชม เพื่อนำเสนอสินค้าหรือบริการบนเว็บไซต์อื่นที่ไม่ใช่เว็บไซต์ของ สสวท. ทั้งนี้ หากท่านปิดการใช้งานคุกกี้ประเภทนี้จะไม่ส่งผลต่อการใช้งานเว็บไซต์ของ สสวท. แต่จะส่งผลให้การนำเสนอสินค้าหรือบริการบนเว็บไซต์อื่น ๆ ไม่สอดคล้องกับความสนใจของท่าน',
    toggle: {
      value: 'advertise', 
      enabled: false,
      readonly: false,
    },
    cookie_table: [
    ],
  },
];

export default cookieBlocks;