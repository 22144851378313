import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
Spin,
  Modal,
  Button,  
  Table, 
Menu,
Dropdown,
Upload,
message,

} from 'antd'
import {
  studentimportjoinToJoinGroup,
} from '../../redux/group/action'
import {
  GetProfileDetail,
  // GetProfile
} from '../../redux/auth/action'

import CustomIconMath from '../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../IconSVG/CustomIconScience.svg'
import ExerciseIconMath from '../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../IconSVG/ExerciseIconScience.svg'
import CompetitionScienceIcon from '../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../IconSVG/CompetitionMathIcon.svg'
import IconExamMath from '../IconSVG/ExamMath.svg'
import IconExamScience from '../IconSVG/ExamScience.svg'

import ExamMath from '../IconSVG/ExamMath.svg'
import ExamScience from '../IconSVG/ExamScience.svg'
import SearchBar from '../Display/SearchBar'
import styles from './Group.module.scss' 
import * as moment from 'moment'
// let csvData = []
import Axios from 'axios'
import Download from 'downloadjs'
import * as XLSX from 'xlsx'
import validator from 'validator';

import IconButtonExcelUpload from '../IconSVG/IconButtonExcelUpload.svg'
import AddDataIcon from '../../assets/Icon/AddData.svg'
import AddUserIcon from '../../assets/Icon/AddUser.svg'
import UploadExcel from '../../assets/Icon/UploadExcel.svg'
import IconDownload from '../IconSVG/IconDownload.svg'
import FileIcon from '../IconSVG/FileIcon.svg'
import IconError from '../IconSVG/IconError.svg'


import { dateFromTimeStamp } from '../../util/helper'

class ModalAddStudent extends Component {

  columns = [

    {
      title: 'คำนำหน้า',
      dataIndex: 'prefixName',
      key: 'prefixName',
      align: 'left'
    },

    {
      title: 'ชื่อจริง',
      dataIndex: 'firstName',
      key: 'firstName',
      align: 'left'
    },

    {
      title: 'นามสกุล',
      dataIndex: 'lastName',
      key: 'lastName',
      align: 'left'
    },

    {
      title: 'อีเมล',
      dataIndex: 'email',
      key: 'email',
      align: 'left'
    },


        // prefixName: item['คำนำหน้า (ด.ช./ด.ญ./นาย/นางสาว)*'],
        // firstName: item['ชื่อจริง*'],
        // lastName: item['นามสกุล*'],
        // email: item['อีเมล*'],

    // {
    //   title: ()=>{
    //     return(
    //       <p style={{marginLeft : '30px'}}>ชื่อ</p>
    //     )
    //   },
    //   dataIndex: 'name',
    //   key: 'name', 
    //   render: (name, record) => {
    //     let logo = null
    //     if(record.type === 'G') {
    //       if(record.subject === 'คณิตศาสตร์') {
    //         logo = IconExamMath
    //       }else {
    //         logo = IconExamScience
    //       }
    //     } else if(record.type === 'C'){
    //       if(record.subject === 'คณิตศาสตร์') {
    //         logo = CompetitionMathIcon
    //       }else {
    //         logo = CompetitionScienceIcon
    //       }
    //     }else if(record.type === 'CUSTOM'){
    //       if(record.subject === 'คณิตศาสตร์') {
    //         logo = CustomIconMath
    //       }else {
    //         logo = CustomIconScience
    //       }
    //     }else {
    //       if(record.subject === 'คณิตศาสตร์') {
    //         logo = ExerciseIconMath
    //       }else {
    //         logo = ExerciseIconScience
    //       }
    //     }
    //     // const iconSubject = examSet.subject === Subject.MATH ? <img src={ExamMath} /> : <img src={ExamScience}/>
    //     return (
    //       <div>
    //         <div>
    //           <span><img alt='logo' src={logo} />{name}</span>
    //         </div>
    //         <div>
    //           <span style={{ position: 'absolute', fontSize: '10px', color: '#A3ABB1', borderRadius: '2px', paddingLeft: '31px' }}>{record.code}</span>
    //         </div>
    //       </div>
    //     )
    //   },
    // },
    // {
    //   title: 'ประเภท',
    //   dataIndex: 'type',
    //   key: 'type',
    //   render: (type) => {
    //     let text = type === 'G' ? 'ตามหลักสูตร' : type === 'C' ? 'แข่งขัน' : 'กำหนดเอง'
    //     return (<span>{text}</span>)
    //   }
    // },
    // {
    //   title: 'วันที่สร้าง',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   align: 'center',
    //   render: (createdAt) => {
    //     return (<span>{dateFromTimeStamp(createdAt)}</span>)
    //   }
    // },
    // {
    //   title: 'สถานะ',
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: (status) => {
    //     return (<span>{ status ? 'เปิด' : 'ปิด' }</span>)
    //   },
    //   align: 'center'
    // },
    // {
    //   title: 'จำนวนข้อ',
    //   dataIndex: 'questionCount',
    //   key: 'questionCount',
    //   align: 'center'
    // }
  ]

  paginationConfig = {
    position: 'bottom',
    defaultPageSize: 20
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedRowKeys: [],
      upload_users:[],
      isLoading:false,
      // searchKeyword: ''
    }
  }


successMainMessage = (info) => {
    message.success(info,5) //อัปโหลดสำเร็จเรียบร้อย
}
errorMainMessage = (info) => {
    message.error(info, 5)
}
handleSubmit =  (e) => {

const {  group  } = this.props 
const {  selectedUsers } = this.state 
const { GetProfileDetail,Closewindow, fetchGetListOfStudents } = this.props

// console.log(group.id !== undefined );
// console.log(typeof group.id === 'string');
// console.log(selectedUsers !== undefined );
// console.log(Array.isArray(selectedUsers) );
// console.log(selectedUsers.length > 0);
// console.log(selectedUsers);


if (group.id !== undefined 
&& typeof group.id === 'string'
&& selectedUsers !== undefined 
&& Array.isArray(selectedUsers) 
&& selectedUsers.length > 0)
    {
      // console.log("CALL BACKEND")
      this.setState({ isLoading: true })

      GetProfileDetail().then(userres => {

        this.props.studentimportjoinToJoinGroup(group.id,{selectedUsers:selectedUsers,editoruser:userres}).then(res => {

        this.setState({ isLoading: false })

            if (res.error) {
              // console.log("CALL BACKEND" + res.error)
                this.errorMainMessage(res.error)
              // this.setState({ 
              //   excelErrorMessage: res.error,
              //   excelPopupAlert: true,
              //   uploadingFile: false
              // })
              // return;
            } else {
              //this.fetchUsers()
              fetchGetListOfStudents();
              this.successMainMessage('เพิ่มนักเรียนเสร็จสิ้น')
              Closewindow();

              // return;
            }
        })
    })


      // this.errorMainMessage('ไม่สามารถปรับปรุงข้อมูล')
      return;
    }


  // e.preventDefault()
  //const { onSubmit } = this.props
  //const { selectedRowKeys } = this.state 
  //await onSubmit(selectedRowKeys)
  

  //this.clearSelected()
  // console.log(group);
  // console.log(selectedUsers);
  this.errorMainMessage('ข้อมูลไม่ครบถ้วน')
}

  clearSelected = () => {
    this.setState({ selectedRowKeys: [] })
  }

  // handleSearch = (value) => {
  //   this.setState({ searchKeyword: value })
  // }

  handleSelectChange = (values) => {
    const { upload_users } = this.state 
    // console.log("-------^^^----")
    // console.log(values)
    // console.log("-----------")
    
    let result = [];
    values.forEach(i => result.push(upload_users[i]));
    // console.log(result)

    // console.log("------VVV-----")
    this.setState({ selectedRowKeys: values, selectedUsers: result })
  }







onDownloadTemplate = () => {
    const fileName = 'student-to-group-template.xlsx' 
    let url = `${process.env.REACT_APP_API_URL}/storage/template/${fileName}`
    Axios.get(url, { responseType: 'arraybuffer' })
      .then((response) => {
        // eslint-disable-next-line no-undef
        let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })  
        Download(blob, 'เพิ่มนักเรียนเข้ากลุ่ม Template.xlsx')
      })  
  }


///////////////
openUploadExcelWindow = () => {
    this.setState({ excelPopup: true })
}
closeUploadExcelWindow = () => {
  this.setState({ excelPopup: false })
}
// closeExcelErrorAlart = () => {
//   this.setState({
//     excelPopupAlert: false
//   })
// }
// closeUploadAndAlert = () => {
//   this.setState({
//     excelPopupAlert: false,
//     excelPopup: false
//   })
// }
successExcelMessage = () => {
    message.success('แสดงข้อมูลจากไฟล์',5) //อัปโหลดสำเร็จเรียบร้อย
  }
  errorUpdateMessage = (info) => {
    message.error(info, 5)
  }




  // successDeleteMessage = () => {
  //   message.success('ลบผู้ใช้เรียบร้อย',5)
  // }
  // successUpdateMessage = () => {
  //   message.success('อัปเดตข้อมูลเรียบร้อย',5)
  // }
  // errorUpdateMessage = () => {
  //   message.error('อัปเดตข้อมูลไม่สำเร็จ', 5)
  // }

  //Setting upload only one file
  handleUploadChange = uploadfile => {

    // await console.log("handleUploadChange START----")

    // let fileList = [...info.fileList]
    // fileList = fileList.slice(-1)
    // fileList = fileList.map(file => {
    //   if (file.response) {
    //     file.status = 'uploading'
    //     file.status = 'done'
    //   }
    //   return file
    // })



    // let file = null

    // // if (info.file.status !== 'uploading') {
    // //   console.log(info.file, info.fileList);
    // // }

    
    // // if (info.file.status === 'done') {
    // //   // message.success(`${info.file.name} file uploaded successfully`);
    // //   file = info.file.originFileObj
    // // } else if (info.file.status === 'error') {
    // //   // message.error(`${info.file.name} file upload failed.`);
    // // }

    // console.log(uploadfile)

    // if(uploadfile.length === 1){
    // //   file = uploadfile.originFileObj
    //       file = uploadfile[0].originFileObj
    //       console.log(uploadfile[0])
    // }

    // // console.log(file.response)
    // // console.log(info.fileList)
    

    //await 
    this.setState({ 
      fileList : [uploadfile], 
      file: uploadfile,
      // fileContent: file
    })
    // this.onUpload()
    // await console.log(this.setState.fileList)
    // await console.log(uploadfile)

    // await 
  // console.log("handleUploadChange END 222 ----")


    // await 
    return false
  }

  onUpload = () => {
      this.setState({
        uploadingFile: true,
        upload_users: [],
      })


  // console.log(this.state.file)
  if(this.state.file === undefined || this.state.file === null){
    this.errorUpdateMessage('กรุณาเลือกไฟล์ให้ถูกต้อง')
    // this.setState({
    //   excelErrorMessage: 'กรุณาเลือกไฟล์',
    //   excelPopupAlert: true
    // })
       this.setState({
        uploadingFile: false,
        upload_users: [],
      })
      return
  }

else{
    // eslint-disable-next-line no-undef
    // console.log("READ FILE START")
    const reader = new FileReader(this.state.file)
    reader.onload = async (evt) => { // evt = on_file_select event

      // console.log(evt)

      /* Parse data */
      const { result } = evt.target
      const wb = XLSX.read(result, {type: 'binary'})
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws)

      const uploadusers = data.map((item,index) => ({
        newid: index,
        prefixName: typeof item['คำนำหน้า (ด.ช./ด.ญ./นาย/นางสาว)*'] === 'string' ? item['คำนำหน้า (ด.ช./ด.ญ./นาย/นางสาว)*'].trim() : '', //item['คำนำหน้า (ด.ช./ด.ญ./นาย/นางสาว)*'],
        firstName: typeof item['ชื่อจริง*'] === 'string' ? item['ชื่อจริง*'].trim() : '', //item['ชื่อจริง*'],
        lastName: typeof item['นามสกุล*'] === 'string' ? item['นามสกุล*'].trim() : '', //item['นามสกุล*'],
        email: typeof item['อีเมล*'] === 'string' ? item['อีเมล*'].trim().toLowerCase() : '',
      }))

      let errmsg = ""
      let valid_count = 0
      if (Array.isArray(uploadusers) && uploadusers.length > 0)
      {
        errmsg += "(Rows#: "
        for (let y in uploadusers) {
          let x = uploadusers[y]
          // console.log(x)
          if (typeof x['email'] === 'string' && validator.isEmail(x['email']) 
&& typeof x['prefixName'] === 'string' && x['prefixName'] !== '' &&  (x['prefixName'] === 'ด.ช.' || x['prefixName'] === 'ด.ญ.' || x['prefixName'] === 'นาย' || x['prefixName'] === 'นางสาว') 
&& typeof x['firstName'] === 'string' && x['firstName'] !== '' 
&& typeof x['lastName'] === 'string' && x['lastName'] !== '')
          {
            valid_count += 1;
          }
          else
          {
            let erow = parseInt(y) + 2
            
            errmsg += (erow).toString() + ", "
          }
        }
        errmsg = errmsg.trim()
        errmsg = errmsg.replace(/^,+|,+$/g, '');
        errmsg = " " + errmsg + ")"
      }
      

      // console.log(uploadusers);

      if ((Array.isArray(uploadusers) && uploadusers.length == valid_count)) {
        //this.fetchUsers()
              // console.log("MyCorrect")
        this.successExcelMessage()
        this.clearSelected()

    this.setState({ 
      fileList : [], 
      file: null,
      // fileContent: file
    })

        this.setState({ excelPopup: false, uploadingFile: false, upload_users: uploadusers })
         return
        
      } else {
              // console.log("MyError")
        
        // this.setState({ 
        //   excelErrorMessage: "ไม่มีข้อมูล หรือ รูปแบบข้อมูลไม่ถูกต้อง",
        //   excelPopupAlert: true,
        //   uploadingFile: false
        // })
    this.setState({ 
      fileList : [], 
      file: null,
      // fileContent: file
    })

      this.setState({
        uploadingFile: false,
        upload_users: [],
      })

      this.errorUpdateMessage('ตรวจสอบประเภทไฟล์ หรือ รูปแบบข้อมูลไม่ถูกต้อง' + errmsg)

       return

      }


    }

    reader.readAsBinaryString(this.state.file)
    // console.log("READ FILE END")
  }


      this.setState({
        uploadingFile: false,
        upload_users: [],
      })
       return
}
///////////////  

generateButtons() {
          const buttons=[
            {
              text: 'เลือกไฟล์รายชื่อ',
              icon: AddUserIcon,
              subMenu: [
                // {
                //   text: 'เพิ่มทีละรายการ',
                //   icon: AddUserIcon,
                //   // onClick: onClickAddUser
                // },
                {
                  text: 'เพิ่มโดยใช้ไฟล์ Excel',
                  icon: UploadExcel,
                  onClick: this.openUploadExcelWindow
                },
                {
                  text: 'Download Excel Template',
                  icon: IconDownload,
                  onClick: () => this.onDownloadTemplate()
                }
              ]
            }, 
            // {
            //   text: 'ดาวน์โหลด Excel',
            //   key: 'exportExcelButton',
            //   icon: UploadExcel
            // }
          ]; 

    // const { buttons } = this.props   
    let checkDownloadExcel = buttons ? buttons.find(b => b.key === 'exportExcelButton' )  : null 
    if (!buttons || buttons.length === 0) return []
    const btns = buttons.map(button => {
      if (button.subMenu) { 
        const menu = (<Menu>{button.subMenu.map((subMenu, index) => (
          <Menu.Item key={index} onClick={subMenu.onClick}>
            <img src={subMenu.icon} alt={subMenu.text} style={{ marginRight: '5px'}}/>
            {subMenu.text}
          </Menu.Item>)
        )}</Menu>)
        return (<Dropdown overlay={menu} placement='bottomRight' trigger={['click']} key={button.text} className={styles.btn}>
          <Button type='primary'>
            <img src={button.icon} alt={button.text} />
            <span className='responsive-btn'>{button.text}</span>
          </Button>
        </Dropdown>)
      } else { 
        return (
          <div key={button.text}>
            { 
              // checkDownloadExcel?  
              //   <ExportCSV button={button} csvData={csvData} fileName={`รายชื่อในระบบ ${moment().format('DD-MM-YYY')}.xlsx`} fetchUser={this.fetchUser}/>
              //   :
                <Button type='primary' key={button.text} className={styles.btn} onClick={button.onClick}>
                  <img src={button.icon} alt={button.text} />
                  <span className='responsive-btn'>{button.text}</span>
                </Button>  
            }
          </div>
        )
      }
    })

    return btns
  }




  render() {
    const { isVisible,  onCancel , group } = this.props 
    const { selectedRowKeys,isLoading } = this.state //, searchKeyword
    let NotSelectedisVisible = true;
    if (selectedRowKeys.length > 0)
        NotSelectedisVisible = false;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange
    } 
    // let examsFilters = exams

    // if (searchKeyword) {
    //   examsFilters = exams.filter(exam => exam.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)
    // }

    // const filters = [
    //   {
    //     title: 'Subject',
    //     dataIndex: 'subject',
    //     key: 'subject',
    //     // render: (name, exam) => {
    //     //   return (
    //     //     <div className='item'>
    //     //       <img src={exam.subject === 'คณิตศาสตร์' ? ExamMath : ExamScience} alt='icon-subject'/>
    //     //       <div className='detail'>
    //     //         <h4>{exam.name}</h4>
    //     //         <p>{exam.code}</p>
    //     //       </div>
    //     //     </div>
    //     //   )
    //     // }
    //   }
    // ]

    const uploadProps = {
      name: 'file_student_to_group',
      action: null,
      accept: '.xlsx',
      //onChange: this.handleUploadChange,
      multiple: false,
      // defaultFileList:[{
      //   status: 'done'
      // }]
    }


    return (

      <div >
        

        {/* <Modal 
          visible={this.state.excelPopupAlert} 
          onCancel={this.closeExcelErrorAlart}
          className={styles.modal_upload_file}
          footer={[
            <Button className={styles.upload_file_button} key='back' onClick={this.closeUploadAndAlert}>ยกเลิก</Button>,
            <Button className={styles.upload_file_button} key='submit' type='primary' onClick={this.closeExcelErrorAlart}>ลองอีกครั้ง</Button>
          ]}
          bodyStyle={{ textAlign:'center'}}
        >
          <div className={styles.upload_window_content}>
            <div className={styles.upload_Icon_file}> 
              <img src={IconError} alt='IconError' />
            </div>
            <div className={styles.upload_window_title}>Delete Error</div>
            <p>ลบผู้ใช้ผิดพลาด </p>
            <p style={{ color:'red'}}>{this.state.deleteErrorMessage}</p>
            <p>กรุณาลองใหม่อีกครั้ง </p>
          </div>
        </Modal> */}

        <Modal 
          visible={this.state.excelPopup} 
          onCancel={this.closeUploadExcelWindow}
          className={styles.modal_upload_file}
          footer={[
            <Button className={styles.upload_file_button} key='back' onClick={this.closeUploadExcelWindow}>ยกเลิก</Button>,
            <Button className={styles.upload_file_button} key='submit' type='primary' onClick={this.onUpload} loading={this.state.uploadingFile}>
              <img src={FileIcon} alt='FileIcon' />ใช้ไฟล์ที่เลือก
            </Button>
          ]}
          bodyStyle={{ textAlign:'center'}}
        >
          <div className={styles.upload_window_content}>
            <div className={styles.upload_Icon_file}> 
              <img src={IconButtonExcelUpload} alt='IconButtonExcelUpload' />
            </div>
            <div className={styles.upload_window_title}>ไฟล์ Excel</div>
            <div className={styles.clickable}>
              <Upload 
                {...uploadProps}
                fileList={this.state.fileList}
                beforeUpload={this.handleUploadChange}
                onRemove={ async () => {
this.setState({ 
      fileList : [], 
      file: null,
      // fileContent: file
    })
// await console.log("onRemove");

} }
                // onChange={ info => {
                //       console.log("onChange");
                //       console.log(info.fileList);
                //       console.log("onChange");
                //     }}
              >
                <img src={FileIcon} alt='FileIcon' />
                <span>เลือกไฟล์</span>
              </Upload>
            </div>
          </div>
        </Modal>
      <Modal 
        visible={isVisible}
        closable={true}
        className={styles.addExamModalContent}
        onCancel={onCancel}
        centered
        footer={
<div>
<Spin size='large' tip='Loading...' spinning={ isLoading } >
<div className={styles.infopadding + " flex-space-center"}>*ตรวจสอบรายชื่อก่อนนำนักเรียนเข้ากลุ่ม</div>
<div className='flex-space-center'>
          <Button
            disabled={NotSelectedisVisible}
            key='submit'
            type='primary'
            // htmlType='submit'
            htmlType='button'
            style={{maxWidth: '250px', width: '100%'}}
            onClick={this.handleSubmit}
          >
            เพิ่ม {selectedRowKeys.length} นักเรียนเข้ากลุ่ม
          </Button>
</div>
</Spin>
</div>
        }
      ><Spin size='large' tip='Loading...' spinning={ isLoading } >



        <div className='modal-container'>
          <div className='header' style={{ paddingBottom: '80px' }}>
            <h2 className='text-medium' style={{color: '#05007A'}}>
              {group ? group.name : ''}
{/* AAAA{JSON.stringify(group)} */}
            </h2>


            {/* <SearchBar placeholder={'ชุดข้อสอบXCXXX'} onSearch={this.handleSearch} /> */}
            {this.generateButtons()}
          </div>

          <div className='content'>
            {/* <div className='mobile'>
              <Table rowSelection={rowSelection}
                style = {{top : 30}}
                columns={filters}
                dataSource={this.state.upload_users}
                pagination={false}
                rowKey='_id'
                showHeader={false}
              />
            </div> */
//className='desktop'
}

            <div  > 
              <Table rowSelection={rowSelection}
                columns={this.columns}
                dataSource={this.state.upload_users}
                pagination={this.paginationConfig}
                rowKey='newid'
              />
            </div>
          </div>
        </div>
</Spin>
      </Modal>


      </div>
    )
  }
}

ModalAddStudent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
}


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      studentimportjoinToJoinGroup,
      GetProfileDetail,
    },
    dispatch
  )


export default connect(null,mapDispatchToProps)(ModalAddStudent)
