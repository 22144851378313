/* eslint-disable no-undef */
import React , { Component } from 'react'
import { Table  } from 'antd' 
import Header from '../Header.js'
import Detail from '../Detail'

import LineVerticalBlueColor from '../../IconSVG/LineVerticalBlueColor.svg' 
import LineVerticalRedColor from '../../IconSVG/LineVerticalRedColor.svg' 
import LineVerticalYellowColor from '../../IconSVG/LineVerticalYellowColor.svg' 
import LineVerticalPurpleColor from '../../IconSVG/LineVerticalPurpleColor.svg' 
import LineVerticalPinkColor from '../../IconSVG/LineVerticalPinkColor.svg' 
import LineVerticalGreenColor from '../../IconSVG/LineVerticalGreenColor.svg' 
import styles from '../ReportAdmin.module.scss'

export default class DescriptionTesting extends Component { 
  constructor(props){
    super(props)
    this.state = {
      rowIndex: -1,
      colorCode: '#1DA9F7',
      checkClickOldRow: false,
      colorCodeDonutChart: ['#00A0FA','#F7441D','#FFB800','#9B51E0','#F54BC5','#219653'], 
      indexCheck: null
    }
  }
  componentDidMount = () => { 
    const { year } = this.props
    let month = parseInt(localStorage.getItem('month')) 
    this.props.loadDetail(year, month + 1 ) 
  }
 
  setRowClassName = (record) => {
    return record.id === this.state.rowId ? 'clickRowStyle' : ''
  }
  render() { 
    const { detail, year, month } = this.props 
    const { rowIndex, colorCode, colorCodeDonutChart, indexCheck } = this.state 
    const columns = [
      {
        title: 'ภาค',
        dataIndex: 'region',
        key: 'region',
        render: (region) => {
          return (
            <div>
              <img alt='region-line' src={ 
                region  === 'เหนือ'  ? LineVerticalBlueColor : 
                  region === 'กลาง' ? LineVerticalRedColor : 
                    region === 'ตะวันออก' ?  LineVerticalPurpleColor:
                      region === 'ตะวันตก' ?  LineVerticalYellowColor:
                        region === 'ตะวันออกเฉียงเหนือ' ? LineVerticalPinkColor : 
                          LineVerticalGreenColor
              }/>
              <span style={{ paddingLeft: '10px' }}>ภาค{region}</span>
            </div>)
        }
      },
      {
        title: 'จำนวน',
        dataIndex: 'count',
        key: 'count',
        align: 'center'
      },
      {
        title: 'เปอร์เซ็น',
        dataIndex: 'percent',
        key: 'percent',
        align: 'center',
        render:(percent) => <span>{percent}%</span>
      }
    ]
    var total = detail.reduce((total, item) => total + item.count, 0)
 
    let data1 = [
      { region: 'เหนือ', percent: 0, count: 0 },
      { region: 'กลาง', percent: 0, count: 0 },
      { region: 'ตะวันตก', percent: 0, count: 0 },
      { region: 'ตะวันออก', percent: 0, count: 0 },
      { region: 'ตะวันออกเฉียงเหนือ', percent: 0, count: 0 },
      { region: 'ใต้', percent: 0, count: 0 },
    ]
    let numRegion = [ 
      { region: 'เหนือ', count: 0 },
      { region: 'กลาง', count: 0 },
      { region: 'ตะวันตก', count: 0 },
      { region: 'ตะวันออก', count: 0 },
      { region: 'ตะวันออกเฉียงเหนือ', count: 0 },
      { region: 'ใต้', count: 0 },
    ]

    numRegion = numRegion.map((n) => {
      detail.forEach(d => {
        if(n.region === d.region){ 
          n.count += d.count
        }
      })
      return n
    })
    detail.forEach(item => { 
      switch (item.region) {
        case 'เหนือ'            : data1[0] = { region: 'เหนือ',            percent: `${(( numRegion[0].count / total) * 100).toFixed(2)}` , count: numRegion[0].count }; break
        case 'กลาง'            : data1[1] = { region: 'กลาง',            percent: `${(( numRegion[1].count / total) * 100).toFixed(2)}` , count: numRegion[1].count }; break
        case 'ตะวันตก'          : data1[2] = { region: 'ตะวันตก',          percent: `${(( numRegion[2].count / total) * 100).toFixed(2)}` , count: numRegion[2].count }; break
        case 'ตะวันออก'         : data1[3] = { region: 'ตะวันออก',         percent: `${(( numRegion[3].count / total) * 100).toFixed(2)}` , count: numRegion[3].count }; break
        case 'ตะวันออกเฉียงเหนือ' : data1[4] = { region: 'ตะวันออกเฉียงเหนือ', percent: `${(( numRegion[4].count / total) * 100).toFixed(2)}` , count: numRegion[4].count }; break
        case 'ใต้'              : data1[5] = { region: 'ใต้',              percent: `${(( numRegion[5].count / total) * 100).toFixed(2)}` , count: numRegion[5].count }; break
        default:
      }
    })
    let data1Chart = [['type', 'percent']]
    let data2Chart = [['province', 'count']]
    let data2ChartAll = []
    let data2ChartFilter = []
    let uniqueArray = [] 
    data1.forEach(item => {
      data1Chart.push([
        item.region, 
        parseFloat(item.percent)
      ])
      detail.forEach((d ) => {
        if(rowIndex !== null && data1[rowIndex] && data1[rowIndex].region === d.region ){
          data2ChartFilter.push([
            d.province, d.count
          ])  
        }else {  
          data2ChartAll.push([
            d.province, d.count
          ])   
        }
      })
    }) 
    if(rowIndex === indexCheck){
      uniqueArray = data2ChartFilter.filter((data2, index) => {
        const _thing = JSON.stringify(data2)
        return index === data2ChartFilter.findIndex(obj => {
          return JSON.stringify(obj) === _thing
        })
      }) 
    }else{
      uniqueArray = data2ChartAll.filter((data2, index) => {
        const _thing = JSON.stringify(data2)
        return index === data2ChartAll.findIndex(obj => {
          return JSON.stringify(obj) === _thing
        })
      }) 
    } 
    uniqueArray.map(uniq => {
      data2Chart.push(uniq)
    }) 
    // const uniqueArray = data2ChartAll.filter((data2, index) => {
    //   const _thing = JSON.stringify(data2)
    //   return index === data2ChartAll.findIndex(obj => {
    //     return JSON.stringify(obj) === _thing
    //   })
    // }) 
    // uniqueArray.map(uniq => {
    //   data2Chart.push(uniq)
    // })   
    return (
      <div className={styles.report_admin_examset_detail}>
        <Header match={this.props.match}
          url='reportaccount'
          headerName='รายงานบัญชีผู้ใช้'
          history={this.props.history}
          year={year}
          // month={parseInt(localStorage.getItem('month'))}
          month={month}
          detail={detail}
          statType={'userAccount'}
          getTransaction={this.props.stateUserTransaction}
          spinOnLoading={this.props.spinOnLoading}
          spinOffLoading={this.props.spinOffLoading} 
        />
        <Detail
          data1={data1Chart}
          data2={data2Chart}
          year={year}
          month={month}
          legend={false}
          spinLoading={this.props.spinLoading}
          colorCode={colorCode}
          colorCodeDonutChart={colorCodeDonutChart}
          type={'userAccount'}
          isSelect={rowIndex === indexCheck}
        >
          <Table
            columns={columns}
            dataSource={data1}
            pagination={false}
            rowKey={(record, i) => i}
            onRow={(record, index) => {
              return {
                onClick: () => {  
                  let { colorCode, rowIndex, indexCheck } = this.state 
                  if(rowIndex === index){
                    rowIndex = null
                  }else{
                    rowIndex = index
                  }
                  indexCheck = index
                  if(rowIndex === 0){ 
                    colorCode = '#1DA9F7'
                  }else if(rowIndex === 1){ 
                    colorCode = '#F7441D' 
                  }else if(rowIndex === 2){ 
                    colorCode = '#FFB800' 
                  }else if(rowIndex === 3){ 
                    colorCode = '#9B51E0'
                  }else if(rowIndex === 4){ 
                    colorCode = '#F54BC5'
                  }else if(rowIndex === 5){ 
                    colorCode = '#219653'
                  }
                  this.setState({ rowIndex, colorCode, indexCheck }) 
                },  
                style: {
                  backgroundColor: rowIndex === index ? '#E0E0E0' : null, 
                  cursor: 'pointer'
                }
              }
            }} 
          />
        </Detail>
      </div>
    )
  }
}
