import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  Spin,
} from 'antd'
import CardLightMap from '../Display/CardLightMap'
import FormResetPassword from './FormResetPassword'
import {
  UserRequestResetPassword
} from '../../redux/auth/action'

class ResetPassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errorForgetPassword: null,
      isHasResetPassword: false
    }
  }

  onSubmitResetPassword = async (data) => {
    this.setState({ isLoading: true })
    const res = await this.props.UserRequestResetPassword({
      password: data.password,
      token: this.props.match.params.token
    })
    if (res.error) {
      this.setState({ 
        isLoading: false,
        errorForgetPassword: 'อีเมลไม่ถูกต้อง',
      })
    } else {
      this.setState({ isLoading: false, isHasResetPassword: true })
    }
    
  }

  render() {
    const isAuthenticated = this.props.isAuthenticated
    const { history } = this.props
    const { isHasResetPassword } = this.state
    return ( !isAuthenticated ? 
      <CardLightMap>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <FormResetPassword onSubmit={this.onSubmitResetPassword} errorForgetPassword={this.state.errorForgetPassword} isHasResetPassword={isHasResetPassword} history={history} />
        </Spin>
      </CardLightMap> :
      <Redirect to='/examset' />
    )
  }
}

const mapStateToProps = state => ({
  // Redirect after login
  isAuthenticated: state.auth.token ? true : false,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserRequestResetPassword,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)