exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DataPolicy_title_condition__2vk6a {\n  background: #E5E5E5;\n  border: 1px solid #E0E0E0;\n  box-sizing: border-box;\n  border-radius: 4px;\n  padding: 1em; }\n\n.DataPolicy_description_condition__19P8B {\n  background: #E5E5E5;\n  height: auto;\n  margin-bottom: 2em;\n  border: 1px solid #E0E0E0;\n  box-sizing: border-box;\n  border-radius: 4px;\n  padding: 1em; }\n\n.DataPolicy_row_title__zbyBS {\n  margin: 30px;\n  margin-left: 0; }\n\n.DataPolicy_cancle_link__3SXJx {\n  line-height: 26px;\n  letter-spacing: 0.192px;\n  text-decoration-line: underline;\n  /* Gray 3 */\n  color: #828282; }\n\n.DataPolicy_div_for_divide__Qx7B6 {\n  width: 85%;\n  height: 0px;\n  /* Gray 4 */\n  background: #BDBDBD;\n  /* Gray 5 */\n  border: 1px solid #E0E0E0;\n  margin-bottom: 3em; }\n\n.DataPolicy_save_btn__39E6S {\n  width: 178px; }\n", ""]);

// Exports
exports.locals = {
	"title_condition": "DataPolicy_title_condition__2vk6a",
	"description_condition": "DataPolicy_description_condition__19P8B",
	"row_title": "DataPolicy_row_title__zbyBS",
	"cancle_link": "DataPolicy_cancle_link__3SXJx",
	"div_for_divide": "DataPolicy_div_for_divide__Qx7B6",
	"save_btn": "DataPolicy_save_btn__39E6S"
};