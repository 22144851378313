import { FetchCycle, AxiosRequest } from '../fetch'
import * as $ from 'jquery'

// API =====================================================================

const API_AUDIT        = '/audittrail' 

// Fetch ===================================================================


const auditListExcel        = ()  => () => AxiosRequest(API_AUDIT,'get', {'limit':100000000})
const auditListAll        = (q)  => () => AxiosRequest(API_AUDIT,'get', q)


const provinceList      = ()              => () => AxiosRequest('/master/provinces?limit=100','get')


// Action =====================================================================

export const getAuditListExcel        = ()  => FetchCycle(auditListExcel())
export const getAuditListFull        = (q)  => FetchCycle(auditListAll(q))

export const getProvinceList      = ()              => FetchCycle(provinceList())
