import React, { Component }  from 'react' 
import {  
  Button,
  Input 
} from 'antd'   
import styles from './Competitions.module.scss'
import {
  IconSearch,
} from '../IconSVG'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getOTIMSCompetitionList } from '../../redux/competitions/action'

class Header extends Component {
  constructor(props){
    super(props)
    this.state = {
      subjectsFilters: [],
      searchKey: null,
      searchKeyword: null,
      checkEnter : false
    }
  }
  handleKeyPress = (value) => {
    const {checkEnter} = this.state
    setTimeout(()=>{
      this.setState({
        searchKey: value
      })
      if(value === '' && checkEnter === true){
        this.handleSearch()
        this.setState({
          checkEnter : false
        })
      }
    },50)
  } 
  handleSearch = async(value) => {
    const { dataSearch,setSubjectsFiltersBySearch } = this.props
    let { searchKeyword, subjectsFilters } = this.state
    let isEmptySearch = true
    searchKeyword = value  
    this.setState({
      checkEnter : true
    })
    if (searchKeyword) {  
      isEmptySearch = false
      subjectsFilters = dataSearch.filter(r => r.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)  
    }    
    setSubjectsFiltersBySearch(subjectsFilters, isEmptySearch) 
  }

  fetchCompetitionProject = async() => {
    const data = await this.props.getOTIMSCompetitionList()
    this.props.updateCompetition()
  }

  render(){  
    let { searchKey } = this.state
    const addFeatureHeader = (
      <div style={{display: 'flex'}}> 
        <Input
          suffix={ 
            <Button 
              type='primary' 
              style={{paddingLeft: 5, paddingRight: 10}} 
              onClick={() => this.handleSearch(searchKey)} 
            >
              <IconSearch style={{verticalAlign: 'middle'}}/>ค้นหา
            </Button>
          }
          placeholder='ค้นหา (รายการแข่งขัน)'
          maxLength={100}
          className={styles.inputSearch} 
          onChange = {(e)=>this.handleKeyPress(e.target.value)}
          onPressEnter={() => this.handleSearch(searchKey)} 
        />
        <Button type='primary'
          onClick={() => this.fetchCompetitionProject()}>
          ปรับปรุงข้อมูลโครงการแข่งขัน
        </Button>
      </div>
    ) 
    return (
      <div>
        <div className='flex-space-between' style={{marginBottom: '30px'}}>
          <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>รายการแข่งขัน</h1>
          {addFeatureHeader}
        </div>
      </div>
    )
  }
}
 
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOTIMSCompetitionList
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Header)