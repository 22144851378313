import React, { Component, Fragment }  from 'react'
import {
  Layout,
} from 'antd'

import styles from './Testing.module.scss'
import MatchOptionChoice from './MatchOptionChoice'
import NoteAnswer from './Note'
import { displayer } from './helper'

const { Content } = Layout

class MatchAnswerTesting extends Component {
  constructor(props){
    super(props)
    this.myRef = React.createRef()
    this.state = {
      typeOption: true,
      leftSelected: [],
      rightSelected: [],
      note: {
        text: '',
        file: null
      }, 
    }
  }

  componentDidMount = () => {
    const { progressTesting } = this.props
    const progressTestingAnswer = progressTesting ? progressTesting.answer : []
    this.setState({
      leftSelected: progressTestingAnswer != null ? progressTestingAnswer.map(ans => ans.seq) : [],
      rightSelected: progressTestingAnswer != null ? progressTestingAnswer.map(ans => ans.match) : []
    })
  }

  handleChangeSelect = (optionSeq, side) => {
    let { leftSelected, rightSelected } = this.state
    if (side === 'left') {
      const index = leftSelected.indexOf(optionSeq)
      if (index > -1) {
        leftSelected.splice(index, 1)
        rightSelected.splice(index, 1)
      } else {
        leftSelected.push(optionSeq)
      }
      this.setState({ leftSelected, rightSelected })
    } else {
      const index = rightSelected.indexOf(optionSeq)
      if (index > -1) {
        rightSelected.splice(index, 1)
        leftSelected.splice(index, 1)
      } else {
        rightSelected.push(optionSeq)
      }
      this.setState({ rightSelected, leftSelected })
    }

    const { question  } = this.props

    let answer = {
      type: question.type,
      order: question.seq,
      orgorder: question.orgseq,
      questionId: question._id,
      answer: []
    }

    for (let index in leftSelected) {
      if (leftSelected[index] !== undefined && rightSelected[index] !== undefined) {
        answer.answer.push({ seq: leftSelected[index], match: rightSelected[index] })
      }
    }

    this.props.onSetAnswer(answer)
  }

  setNote = (data, type) => {
    let { note } = this.state
    const { question, progressTesting } = this.props
    note =  progressTesting && progressTesting.note !== undefined ? progressTesting.note : note
    note[type] = data
    note.isChanged = true
    this.props.onSetNote(question.seq, note)
  }

  render(){ 
    // this.scrollToHead()
    const { progressTesting, question, isSolution, displayHowTo, testingId, isExample } = this.props 
   
    let { leftSelected, rightSelected, note } = this.state 
    if (progressTesting) {
      note =  progressTesting.note ? progressTesting.note : note
    }   
    // let arrRight = []
    // arrRight = question.answers.left.map((left,i) => {
    //   return question.answers.right.find((right,i) => { 
    //     if(parseInt(left.match) === right.seq) {
    //       return right
    //     } 
    //   })
    // })
    // arrRight = question.answers.right.filter(item => {if(item.seq === 1) return item })
    
    return (
      <Content className={styles.content_testing}>
        <div ref={this.myRef} className='match-answer-testing' id='bodyTesting'>
          <h2 id = 'myDIV' className = { styles.num_of_question }>ข้อที่ {question.seq}.</h2>

          <div className='question-content'>
            {displayer(question.text)}
          </div>

          <div className='match'>
            <h4>จับคู่</h4>
            <div className={styles.all_choice}>
              {question.answers.left.map((option, i) => <MatchOptionChoice option={option} key={i} side={'left'} selectedOptions={leftSelected} onClickOption={(optionSeq) => this.handleChangeSelect(optionSeq, 'left')} isSolution={isSolution} solutions={rightSelected} isExample={isExample} isMA={true}/>)}
            </div>
          </div>

          <div className='options'>
            <h4>ตัวเลือก</h4>
            <div className={styles.all_choice}>
              {question.answers.right.map((option, i) => <MatchOptionChoice option={option} key={i} side={'right'} selectedOptions={rightSelected} onClickOption={(optionSeq) => this.handleChangeSelect(optionSeq, 'right')} isSolution={isSolution} />)}
            </div>
          </div>
          {isSolution && (
            <Fragment>
              <span style={{ fontWeight: 500, fontSize: '18px',color: '#333333' }}>คำตอบที่ถูกต้อง</span>
              <div className={styles.all_choice}>
                {question.answers.left.map((option, i) => {
                  let arrRight = question.answers.right.find((right) => { 
                    if(parseInt(option.match) === right.seq) {
                      return right
                    } 
                  })
                  return (
                    <Fragment key={`${i}`}>
                      <MatchOptionChoice option={option} key={`left=${i}`} side={'left'} selectedOptions={leftSelected} onClickOption={(optionSeq) => this.handleChangeSelect(optionSeq, 'left')} isSolution={isSolution} solutions={rightSelected} correctAns={true} isExample={isExample}/>
                      <MatchOptionChoice option={arrRight} key={`right=${i}`} side={'right'} selectedOptions={rightSelected} onClickOption={(optionSeq) => this.handleChangeSelect(optionSeq, 'right')} isSolution={isSolution} correctAns={true} isExample={isExample}/>
                    </Fragment>
                  )
                })}
              </div>
              <div className='possible-answer'>  
                {/* <span dangerouslySetInnerHTML={{__html: question.explanation}} />  */}
	        <p style={{paddingTop: '4ex', paddingBottom: '0ex', marginBottom: '0ex', fontWeight: 'bold'}}>คำอธิบาย/แนวการตอบ</p>
                <span>{displayer(question.explanation)}</span>
              </div>
            </Fragment>
            
          )}
          { displayHowTo && (
            <div className='how-to'>
              <NoteAnswer explanation={note} onUploadFile={(data) => this.setNote(data, 'file')} onChangeNoteText={(data) => this.setNote(data, 'text')} note={note} testingId={testingId} questionId={question._id} isSolution={isSolution} />
            </div>
          )}
        </div>
      </Content>
    )
  }
}

export default MatchAnswerTesting
