import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Checkbox,
} from 'antd'
import styles from './Register.module.scss'

class ModalPrivacyPolicy extends Component {

  constructor(props) {
    super(props)
    this.state = {
      termConditionCheck: false,
      privacyPolicyChecked: false,
      dataPrivacyCheck: false,
      checkboxDisabled: true
    }
  }

  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    })
  }
  handleScroll = (e) => {
    const h = e.target.scrollHeight - e.target.scrollTop 
    const bottom = Math.abs(h - e.target.clientHeight) < 5;
    // const bottom = e.getBoundingClientRect().bottom <= e.target.clientHeight;
    // console.log(e.target.scrollHeight - e.target.scrollTop)
    // console.log(e.target.clientHeight)
    const { checkboxDisabled } = this.state 
    this.setState({
      checkboxDisabled: (checkboxDisabled && !bottom)
    })
  }

  render() {
    const { isVisible, onCancel, onOK, role, termAndCondition, dataPrivacy, fromaddgroup } = this.props 
    const { checkboxDisabled } = this.state
    let dataPrivactForRole = {}
    
    if (dataPrivacy.length > 0) {
      dataPrivactForRole = dataPrivacy.find(dp => dp.role === role)
    }
    

    return (
      <Modal
        visible={isVisible}
        closable={false}
        width={'100%'}
        wrapClassName={styles.modalTermWrap}
        destroyOnClose={true}
        centered
        footer={
          <div className='flex-space-between'>
            { fromaddgroup ? <div></div> : <Button
              disabled={fromaddgroup}
              key='back'
              type='link'
              className={`${
                styles.modalTermCancelBtn
              } text_underline`}
              onClick={() => {
                onCancel()
              }}
            >
              ยกเลิก
            </Button>}
            <Button
              disabled={!this.state.privacyPolicyChecked}
              key='submit'
              type='primary'
              htmlType='submit'
              className={styles.modalTermOKBtn}
              onClick={onOK}
            >
              {fromaddgroup ? 'เริ่มการใช้งาน' : 'บันทึก'}
            </Button>
          </div>
        }
      >
        <div onScroll={this.handleScroll} className={`${styles.modalTerm} text-thin`} style={{ overflow: 'auto', height: '600px' }}>
          <h2
            className='text-medium text-center text-lg'
            style={{
              color: '#05007A',
              marginTop: '10px',
              marginBottom: '30px',
              lineHeight: '44px'
            }}
          >
            {dataPrivactForRole ? dataPrivactForRole.title : ''}
          </h2>
          <div  dangerouslySetInnerHTML={{__html: dataPrivactForRole.content}} />
        </div>
        <div style={{ marginLeft: '50px' }}>
          <Checkbox disabled={checkboxDisabled} style={{marginTop: '1em'}} onChange={(e) => {this.setState({ privacyPolicyChecked: e.target.checked }); this.props.setPrivacyPolicy(e)}}>ยอมรับนโยบายคุ้มครองข้อมูลส่วนบุคคล</Checkbox>
        </div>
      </Modal>
    )
  }
}

ModalPrivacyPolicy.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onOK: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ModalPrivacyPolicy
