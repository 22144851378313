import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  message,
  Spin,
  Button,
  Table,
  Modal,
  Avatar
} from 'antd'
import {
  studentRequestToJoinGroup,
  studentCancelRequestToJoinGroup
} from '../../../redux/group/action'
import {
  GetProfileDetail
} from '../../../redux/auth/action'
import styles from '../Group.module.scss'
import GoupLeaveBigIcon from '../../IconSVG/CancelGoupLeaveBigIcon.svg'
import GoupJoinBigIcon from '../../IconSVG/JoinGroupImg.svg'
import DefaultGroup from '../../IconSVG/DefaultGroup.svg'
class GroupRequest extends Component {
  columns = [
    { title: '',
      dataIndex: 'logo',
      key: 'logo',
      align: 'center',
      width: 100,
      render: logo => <img src={logo ? logo : DefaultGroup} alt='group_logo' className={styles.logoGroup}/>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: 'รหัสกลุ่ม',
      dataIndex: 'code',
      key: 'code',
      width: 100,
    },
    {
      title: 'ผู้สร้างกลุ่ม', 
      dataIndex: 'owner',
      key: 'owner',
      render: (owner) => {
        return ( <span>{`อ.${owner.firstName} ${owner.lastName}`}</span> )
      },
      width: 100,
    },
    {
      title: 'วันที่เข้าร่วม',
      // dataIndex: 'createdAt',
      key:'createdAt',
      render: () =>  <div><p>-</p></div>,
      width: 100,
    },
    {
      title: '',
      dataIndex: 'status',
      key:'status',
      render: (status) => {
        const { groupId } = this.props 
        return(
          <div>
            {(()=> {
              switch (status) {
                case 'left':
                  return (<Button
                    type='primary'
                    // onClick={()=> this.onRequestToJoin(groupId)}
                    onClick={()=> this.openModalJoin(groupId)}
                  >ขอเข้าร่วมกลุ่ม</Button>)
                //not yet to join the group and not yet request to join the group
                case 'none':
                  return (<Button
                    type='primary'
                    // onClick={()=> this.onRequestToJoin(groupId)}
                    onClick={()=> this.openModalJoin(groupId)}
                  >ขอเข้าร่วมกลุ่ม</Button>)
                // Has requested to join the group
                case 'request':
                  return (<Button
                    type='default'
                    // onClick={()=> this.onCancelRequest(groupId)}
                    onClick={()=> this.openModal(groupId)}
                  >ยกเลิกคำขอ</Button>)
                // has been joined the group
                case 'join':
                  return (<Button
                    type='primary'
                  >เข้าร่วมแล้ว</Button>)
                default: return ''
              }
            })()}
          </div>
        )}
      ,
      width: 100,
    }
  ]

  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      createdAt: '-',
      groupId: this.props.match.params.id,
      profile: [],
      visibleModalLeave : false,
      visibleModalJoin : false
    }
  }
  componentDidMount = async () => {
    this.fetchGroups()
    this.fetchProfile()
  }

  fetchGroups = async (filter = null) => {
    this.setState({ isLoading: true })
    const res = await this.props.getGroupList(filter)
    this.setState({ isLoading: false })
    if (res.error) message.error(res.error)
  }
  fetchProfile = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.GetProfileDetail()
    this.setState({profile:res, isLoading: false })
  }
  onRequestToJoin = async () => {
    const {groupId} = this.state
    const res = await this.props.studentRequestToJoinGroup(groupId)
    res.error ? message.error(res.error) : message.success('ขอเข้าร่วมกลุ่มสำเร็จ')
    await this.props.fetchGroupsDetail()
    await this.props.fetchSearchGroup()
    this.setState({
      visibleModalJoin : false
    })
  }
  onCancelRequest = async () => {
    const {groupId} = this.state
    const res = await this.props.studentCancelRequestToJoinGroup(groupId)
    res.error ? message.error(res.error) : message.success('ยกเลิกขอเข้าร่วมกลุ่มเรียบร้อย')
    await this.props.fetchGroupsDetail()
    await this.props.fetchSearchGroup()
    this.setState({
      visibleModalLeave : false
    })
  }

  openModal = (id) => {
    this.setState({
      visibleModalLeave : true,
      groupId : id
    })
  }
  openModalJoin = (id) => {
    this.setState({
      visibleModalJoin : true,
      groupId : id
    })
  }

  handleCancel = ()=> {
    this.setState({
      visibleModalLeave : false,
      visibleModalJoin : false
    })
  }

  render(){
    const { auth,groupSearch } = this.props 
    return(
      <Fragment>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading}>
          <div className='flex-space-between' style={{marginBottom: '30px'}}>
            <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>กลุ่ม</h1>
          </div>
          {
            auth.role === 'student' ?
              <Table
                rowKey={ (record,i) => i}
                columns={this.columns}
                dataSource={[ groupSearch ]}
                pagination={false}
              />
              :''
          }
        </Spin>
        <Modal
          visible={this.state.visibleModalLeave}
          onCancel={this.handleCancel}
          footer={false}
        >
          <div style={{textAlign: 'center'}}>
            <Avatar src={GoupLeaveBigIcon} shape='square' size={150}/>
            <h2 className='text-medium' style={{fontSize: 20}}>ยกเลิกคำขอเข้าร่วม</h2>
            <p style={{ fontWeight: '500px', fontSize: '28px' }}>{  groupSearch ? groupSearch.name : '' }</p>
            <p style={{ fontWeight: 'normal', fontSize: '16px' , color : '#3D3D3D', opacity : '0.5'}}>{  groupSearch ? groupSearch.code : '' }</p>
            <p style={{color: '#4F4F4F', fontSize: 14}}>ต้องการยกเลิกคำขอเข้าร่วมกลุ่มนี้หรือไม่</p>
            <div className={styles.modalCreated} >
              <div className={styles.sub_modalCreated} ><Button onClick = {this.handleCancel}>ไม่ยกเลิก</Button></div>
              <div className={styles.sub_modalCreated_2} ><Button type = 'primary' onClick = {this.onCancelRequest}>ยกเลิกคำขอ</Button></div>
            </div>
          </div>
        </Modal>
        <Modal
          visible={this.state.visibleModalJoin}
          onCancel={this.handleCancel}
          footer={false}
        >
          <div style={{textAlign: 'center'}}>
            <Avatar src={GoupJoinBigIcon} shape='square' size={150}/>
            <h2 className='text-medium' style={{fontSize: 20}}>ขอเข้าร่วมกลุ่ม</h2>
            <p style={{ fontWeight: '500px', fontSize: '28px' }}>{  groupSearch ? groupSearch.name : '' }</p>
            <p style={{ fontWeight: 'normal', fontSize: '16px' , color : '#3D3D3D', opacity : '0.5'}}>{  groupSearch ? groupSearch.code : '' }</p>
            <p style={{color: '#4F4F4F', fontSize: 14}}>คุณต้องการเข้าร่วมกลุ่มนี้หรือไม่</p>
            <div className={styles.modalCreated} >
              <div className={styles.sub_modalCreated} ><Button onClick = {this.handleCancel}>ไม่ขอเข้ากลุ่ม</Button></div>
              <div className={styles.sub_modalCreated_2} ><Button type = 'primary' onClick = {this.onRequestToJoin}>ขอเข้ากลุ่ม</Button></div>
            </div>
          </div>
        </Modal>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      studentRequestToJoinGroup,
      studentCancelRequestToJoinGroup,
      GetProfileDetail
    },
    dispatch
  )

export default connect(null,mapDispatchToProps)(GroupRequest)
