/* eslint-disable no-undef */
import React,{ Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Download from 'downloadjs'
import * as htmlToImage from 'html-to-image'
 
import { getStudentDetail } from '../../redux/report/action'
import { Chart } from 'react-google-charts'
import * as moment from 'moment'
import IconDownload from '../IconSVG/IconDownload.svg'
import BigBookMathIcon    from '../IconSVG/BigBookMathIcon.svg'
import BigBookScienceIcon from '../IconSVG/BigBookScienceIcon.svg'
import BigMathMatchIcon from '../IconSVG/BigMathMatchIcon.svg'
import BigScienceMatchIcon from '../IconSVG/BigScienceMatchIcon.svg'
import BackgroundImgTestResultFirst from '../IconSVG/BackgroundImgTestResultFirst.svg'
import AGirlPic from '../IconSVG/AGirlPic.svg'
import ABoyPic from '../IconSVG/ABoyPic.svg'
import BlueStarIcon from '../IconSVG/BlueStarIcon.svg'
import styles from './Report.module.scss'
import {
  Modal,
  Row,
  Col,
  Button,
  Divider, 
} from 'antd'
class ModalScoreResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lesson: [],
      score: [
        ['range', 'คะแนนที่ได้', 'คะแนนเต็ม' ],
        ['1', 0, 0 ],
        ['2', 0, 0 ],
        ['3', 0, 0 ], 
      ]
    }
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => { 
    let score = this.state
    if(this.props.examDetailReport !== nextProps.examDetailReport){
      score = nextProps.examDetailReport.score
      score.forEach((s,i) => {
        if(i === 0){
          s.push({ role: 'tooltip', type: 'string', p: { html: true } })
        }else{
          // s[i].push(`<p>${s[i]}</p>`)
          s[0] = `${i}`
          s[2] = s[2] - s[1]
          s.push(`${s[1]}/${s[1]+s[2]}`)
        }
      }) 
      this.setState({ 
        lesson: nextProps.examDetailReport.lesson, score 
      })
    } 
  }
  onDownload = async() => {
    // eslint-disable-next-line no-undef
    const resultId = await document.getElementById('ModalScoreResult')   
    await htmlToImage.toPng(resultId).then(async(dataUrl) => { 
      await Download(dataUrl, 'ScoreResult.png', 'image/png') 
    })
  }
  render(){
    const { visibleModalScoreResult, onCloseModalResultScore, ExamDetail, 
      user, testingDetail } = this.props  
    const { lesson,score }  = this.state    
    let DotPosition =  0
    if(testingDetail){
      DotPosition = ((Math.round(((Number(testingDetail.theta) + 3.5 )* 100/7)*100)/100)*500/100) - 12
    }  
    let checkGrade = ExamDetail.grade ? ExamDetail.grade.slice(0, 1) : null   
    const detail = (
      <div className={styles.modal_score_result} id='ModalScoreResult' 
        style={ 
          ExamDetail.type !== 'CAT' ?
            checkGrade === 'ป' ? 
              { backgroundColor: '#112AAE', paddingBottom: '200px' }   : { backgroundColor: 'white' } : 
            checkGrade === 'ป' ? 
              { backgroundColor: '#112AAE', paddingBottom: '200px' }   : { backgroundColor: 'white' } 
        } 
        ref={el => this.componentRef = el}>
          
        <div className='mobile'>
          <div className='header'>
            <h1>ข้อมูลข้อสอบ</h1>
            <Button onClick={this.onDownload} type={'primary'}><img alt='icon-download' src={IconDownload}/></Button>
            {/* <Button onClick={this.onPrint} type={'primary'}><img src={PrintIcon}/></Button> */}
          </div>

          <div className='detail'>
            <img alt='icon-subject' src={ExamDetail.type === 'C' ? (
              ExamDetail.subject === 'คณิตศาสตร์' ?
                BigMathMatchIcon : BigScienceMatchIcon
            ) : (
              ExamDetail.subject === 'คณิตศาสตร์' ?
                BigBookMathIcon: BigBookScienceIcon
            )}
            />

            <div className='title'>
              <h3 className={ checkGrade === 'ป' ? 'white' : 'black' }>{ExamDetail.name}</h3>
              <p className={ checkGrade === 'ป' ? 'white' : 'black' } style={{ fontSize: '16px', fontWeight: '300' }}>{ExamDetail.code}</p>
            </div>
          </div>

          <div className='detail2'>
            <div className='first'>
              <h4 className={ checkGrade === 'ป' ? 'white' : 'black' }>สาระการเรียนรู้</h4>
              <p>คณิตศาสตร์</p>

              <h4 className={ checkGrade === 'ป' ? 'white' : 'black' }>จำนวน</h4>
              <p>คณิตศาสตร์</p>
            </div>

            <div className='second'>
              <h4 className={ checkGrade === 'ป' ? 'white' : 'black' }>ระดับชั้น</h4>
              <p>คณิตศาสตร์</p>

              <h4 className={ checkGrade === 'ป' ? 'white' : 'black' }>จัดชุดโดย</h4>
              <p>คณิตศาสตร์</p>
            </div>
          </div>

          <div className='attend-detail'>
            <h1>ข้อมูลผู้เข้าสอบ</h1>
            <h4 className={ checkGrade === 'ป' ? 'white' : 'black' } >{user.prefixName} {user.firstName} {user.lastName}</h4>
            <p className={ checkGrade === 'ป' ? 'white' : 'black' } >{user.school ? user.school.name.text : ''}</p>

            <div className='date-time'>
              <div className='date'>
                <h4 className={ checkGrade === 'ป' ? 'white' : 'black' } >วันที่ทำข้อสอบ</h4>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' } >{moment.unix(testingDetail.finishedAt).format('DD/MM/YYYY, HH:mm')} น.</p>
              </div>

              <div className='time'>
                <h4 className={ checkGrade === 'ป' ? 'white' : 'black' } >เวลาที่ใช้สอบ</h4>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' } >{moment(testingDetail.testingTime*1000).format('mm') === '00' ? 0 : moment(testingDetail.testingTime*1000).format('mm')} นาที {moment(testingDetail.testingTime*1000).format('ss') === '00'? 0 : moment(testingDetail.testingTime*1000).format('ss')} วินาที</p>
              </div>
            </div>
          </div>

          <div className='result'>
            <h1>ผลการสอบ</h1>
            <h4 className={ checkGrade === 'ป' ? 'white' : 'black' }>คะแนนรวม {testingDetail.score}/{ExamDetail.quantity} คะแนน</h4>
            {
              ExamDetail.type === 'CAT' ? 
                <div className={ checkGrade === 'ป' ? styles.cat_chart_middle : styles.cat_chart } >
                  <div className={styles.lower_score} >
                    <div >อ่อน</div>
                  </div>
                  <div className={ styles.middle_score } >
                    <div style={{ paddingTop : '15px' }}>กลาง</div>
                  </div>
                  <div className={ styles.high_score } >
                    <div style={{ paddingTop : '15px' }}>เก่ง</div>
                  </div>
                  <div className={ styles.Popover_score } 
                    style={ window.screen.width > 991 ? { left: `${DotPosition - 65}px` } :
                      testingDetail.theta > 1.5 ? 
                        { left: `${window.screen.width - 163}px` } : 
                        testingDetail.theta > -1.5 ? 
                          { left: `${(window.screen.width/2) - 68}px` } :  
                          { left: '4px' }
                    }>
                    <div className={ styles.group_of_your_score } >
                      <p className={ styles.your_score } >คะแนนคุณอยู่ในกลุ่มนี้</p>
                      <p className={ styles.score } >{ testingDetail ? testingDetail.theta : 0}</p>
                    </div> 
                    <div className={ styles.arrow }></div>
                  </div>
                  <div className={ styles.dot } style={ 
                    window.screen.width > 991 ? { left: `${DotPosition}px` } : 
                      testingDetail.theta > 1.5 ? 
                        { left: `${window.screen.width - 163 + 63}px` } : 
                        testingDetail.theta > -1.5 ? 
                          { left: `${(window.screen.width/2) - 68 + 63}px` } :  
                          { left: '67px' }
                  }/>

                  <div className={ styles.score_range }>
                    <span className={ styles.score_one }>-3.5</span>
                    <span className={ styles.score_two}>-1.5</span>
                    <span className={ styles.score_three }>0</span>
                    <span className={ styles.score_four }>1.5</span>
                    <span className={ styles.score_five }>3.5</span>
                    <span className={ styles.theta } >ค่า Theta</span>
                  </div> 
                </div>
                : 
                <Chart
                  width={'100%'}
                  height={300}
                  chartType='ColumnChart'
                  loader={<div className='chart' style={{ color: 'white' }}>Loading Chart...</div>} 
                  data= { score }
                  options={{ 
                    vAxes:[ { textStyle: { color: checkGrade === 'ป' ? 'white' : 'black'} } ], 
                    hAxes:[ { textStyle: { color: checkGrade === 'ป' ? 'white' : 'black'} } ], 
                    tooltip: {
                      textStyle: {color: checkGrade === 'ป' ? 'white' : 'black'},
                      ignoreBounds: true,
                      trigger: 'focus'
                    },
                    bar: {
                      groupWidth: 14
                    },
                    backgroundColor:'transparent',
                    colors: ['#2ED5B4', checkGrade === 'ป' ? '#14278D' : '#EFEFEF'],
                    titleTextStyle: { color: 'white' },
                    hAxis: {
                      titleTextStyle: { color: checkGrade === 'ป' ? 'white' : 'black' },
                      title: 'บทเรียน',
                      minValue: 0,
                      textStyle: checkGrade === 'ป' ? 'white' : 'black', 
                      gridlines: {
                        color: 'transparent'
                      },
                      baselineColor: 'transparent'
                    },
                    vAxis: {
                      title: 'คะแนน',
                      titleTextStyle: { color: checkGrade === 'ป' ? 'white' : 'black' },
                      textStyle: checkGrade === 'ป' ? 'white' : 'black', 
                      baselineColor: checkGrade === 'ป' ? 'white' : 'black',
                      gridlines: {
                        color: checkGrade === 'ป' ? 'white' : 'black', 
                      }, 
                    }, 
                    animation: {
                      duration: 1000,
                      easing: 'linear',
                      startup: true,
                    },
                    isStacked: true,
                    legend: 'none'
                  }}
                />
            }
            
          </div>

          {/* <div className='course'>
            <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '16px', fontWeight: 'normal'}}>บทเรียน</p>
            <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '16px', fontWeight: '300'}}>1 - จำนวนนับไม่เกิน 100,000</p>
            <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '16px', fontWeight: '300'}}>2 - การบวกและการลบที่ผลลัพท์และ ตัวตั้งไม่เกิน 100,000</p>
            <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '16px', fontWeight: '300'}}>3 - การชั่ง การตวง</p>
          </div> */}

          {
            checkGrade === 'ป' ?
              <div className='bg-attributes'>
                <div className={ checkGrade === 'ป' ? 'visible blue-star' : 'invisivle' }><img alt='icon-blue-start' src={BlueStarIcon}/></div>
                <div className={ checkGrade === 'ป' ? 'visible bg-alpha' : 'invisivle' }><img alt='backend-img-test-result' src={BackgroundImgTestResultFirst} /></div>
                <div className={ checkGrade === 'ป' ? 'visible girl' : 'invisivle' }><img alt='a-girl-pic' src={AGirlPic}/></div>
              </div>
              : ''
          }
        </div>

        <div className='desktop'>
          <Row >
            <Col span={12}>
              <div><h1 className={ checkGrade === 'ป' ? 'white' : 'black' }>ข้อมูลข้อสอบ</h1></div>
            </Col>
            <Col span={12}> 
              <Button 
                type='primary'
                style={{ float: 'right' }} 
                onClick={e => {
                  e.stopPropagation()
                  this.onDownload()
                }}><img alt='icon-download' src={IconDownload}/><span style={{ color: '#05007A' }}>บันทึกใบผลสอบ</span></Button>
            </Col>
          </Row>
          <Row>
            <Col span={2}>
              <div className='subject-icon'>
                <img alt='icon-subject' src={ExamDetail.type === 'C' ? (ExamDetail.subject === 'คณิตศาสตร์' ? BigMathMatchIcon: BigScienceMatchIcon) : (ExamDetail.subject === 'คณิตศาสตร์'? BigBookMathIcon: BigBookScienceIcon)}/>
              </div>
            </Col>
            <Col span={3}>
              <div>
                <h3 className={ checkGrade === 'ป' ? 'white' : 'black' }>{ExamDetail.name}</h3>
                <span className={ checkGrade === 'ป' ? 'white' : 'black' } style={{ fontSize: '16px', fontWeight: '300' }}>{ExamDetail.code}</span>
              </div>
            </Col>
            <Col span={3} offset={2}>
              <div>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' }>สาระการเรียนรู้</p>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' }>จำนวน</p>
              </div>
            </Col>
            <Col span={3}>
              <div>
                <p  className={ checkGrade === 'ป' ? 'white' : 'black' } style={{ fontWeight: 300 }} >{ExamDetail.subject}</p>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' } style={{ fontWeight: 300 }}>{ExamDetail.quantity} ข้อ</p>
              </div>
            </Col>
            <Col span={3}>
              <div>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' }>ระดับชั้น</p>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' }>จัดชุดโดย</p>
              </div>
            </Col>
            <Col span={3}>
              <div>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' } style={{ fontWeight: 300 }}>{ExamDetail.grade}</p>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' } style={{ fontWeight: 300 }}>{ExamDetail.owner ? ExamDetail.owner.firstName : ''} {ExamDetail.owner ? ExamDetail.owner.lastName : ''}</p>
              </div>
            </Col>
          </Row>
          <Divider/>
          <Row>
            <Col span={8} style={{ zIndex: 1 }}>
              <div>
                <h1 className={ checkGrade === 'ป' ? 'white' : 'black' } >ข้อมูลผู้เข้าสอบ</h1>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '24px', fontWeight: '500' }}>{user.prefixName} {user.firstName} {user.lastName}</p>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '18px', fontWeight: '300' }}>{user.school ? user.school.name.text : ''}</p>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '16px', fontWeight: 'normal'}}>วันที่ทำข้อสอบ</p>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '24px', fontWeight: 'normal' }}>{moment.unix(testingDetail.finishedAt).format('DD/MM/YYYY, HH:mm')} น.</p>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '16px', fontWeight: 'normal'}}>เวลาที่ใช้สอบ</p>
                <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '24px', fontWeight: 'normal' }}>{moment(testingDetail.testingTime*1000).format('mm') === '00' ? 0 : moment(testingDetail.testingTime*1000).format('mm')} นาที {moment(testingDetail.testingTime*1000).format('ss') === '00'? 0 : moment(testingDetail.testingTime*1000).format('ss') } วินาที</p>
              </div>
            </Col>
            <Col span={16} style={{ zIndex: 1 }}>
              <div>
                <h1 className={ checkGrade === 'ป' ? 'white' : 'black' } >ผลการสอบ</h1> 
                {
                  ExamDetail.type === 'CAT' ? 
                    <div className={ checkGrade === 'ป' ? styles.cat_chart_middle : styles.cat_chart } >
                      <div className={styles.lower_score} >
                        <div >อ่อน</div>
                      </div>
                      <div className={ styles.middle_score } >
                        <div style={{ paddingTop : '15px' }}>กลาง</div>
                      </div>
                      <div className={ styles.high_score } >
                        <div style={{ paddingTop : '15px' }}>เก่ง</div>
                      </div>
                      <div className={ styles.Popover_score } style={{ left: `${DotPosition - 65}px` }}>
                        <div className={ styles.group_of_your_score } >
                          <p className={ styles.your_score } >คะแนนคุณอยู่ในกลุ่มนี้</p>
                          <p className={ styles.score } >{ testingDetail ? testingDetail.theta : 0}</p>
                        </div> 
                        <div className={ styles.arrow }></div>
                      </div>
                      <div className={ styles.dot } style={{ left: `${DotPosition}px` }}></div> 
                      <div className={ styles.score_range }>
                        <span className={ styles.score_one }>-3.5</span>
                        <span className={ styles.score_two}>-1.5</span>
                        <span className={ styles.score_three }>0</span>
                        <span className={ styles.score_four }>1.5</span>
                        <span className={ styles.score_five }>3.5</span>
                        <span className={ styles.theta } >ค่า Theta</span>
                      </div> 
                    </div>
                    :
                    <Fragment>
                      <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '24px', fontWeight: '500' }}>คะแนนรวม {testingDetail.score}/{ExamDetail.quantity}</p>
                      <Chart
                        width={800}
                        height={400}
                        chartType='ColumnChart'
                        loader={<div className='chart' style={{ color: 'white' }}>Loading Chart...</div>} 
                        data= { score }
                        options={{ 
                          vAxes:[ { textStyle: { color: checkGrade === 'ป' ? 'white' : 'black'} } ], 
                          hAxes:[ { textStyle: { color: checkGrade === 'ป' ? 'white' : 'black'} } ], 
                          tooltip: {
                            textStyle: {color: checkGrade === 'ป' ? 'white' : 'black'},
                            ignoreBounds: true,
                            trigger: 'focus'
                          },
                          bar: {
                            groupWidth: 14
                          },
                          backgroundColor:'transparent',
                          colors: ['#2ED5B4', checkGrade === 'ป' ? '#14278D' : '#EFEFEF'],
                          titleTextStyle: { color: 'white' },
                          hAxis: {
                            titleTextStyle: { color: checkGrade === 'ป' ? 'white' : 'black' },
                            title: 'บทเรียน',
                            minValue: 0,
                            textStyle: checkGrade === 'ป' ? 'white' : 'black', 
                            gridlines: {
                              color: 'transparent'
                            },
                            baselineColor: 'transparent'
                          },
                          vAxis: {
                            title: 'คะแนน',
                            titleTextStyle: { color: checkGrade === 'ป' ? 'white' : 'black' },
                            textStyle: checkGrade === 'ป' ? 'white' : 'black', 
                            baselineColor: checkGrade === 'ป' ? 'white' : 'black',
                            gridlines: {
                              color: checkGrade === 'ป' ? 'white' : 'black', 
                            }, 
                          }, 
                          animation: {
                            duration: 1000,
                            easing: 'linear',
                            startup: true,
                          },
                          isStacked: true,
                          legend: 'none'
                        }}
                      />
                      <p className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '16px', fontWeight: 'normal'}}>บทเรียน</p>
                      { lesson.map((l,i) => { return (<p key={i} className={ checkGrade === 'ป' ? 'white' : 'black' }  style={{ fontSize: '16px', fontWeight: '300'}}>{`${i+1} - ${l}`}</p>) }) }  
                    </Fragment>
                } 
              </div>
            </Col>
          </Row>

          {
            checkGrade === 'ป' ?
              <div className='bg-attributes' style={ ExamDetail.type === 'CAT' ? { paddingTop: '179px' } : { paddingTop: '124px' }} > 
                <div className={ checkGrade === 'ป' ? 'visible blue-star' : 'invisivle' }><img alt='icon-blue-star' src={BlueStarIcon}/></div>
                <div className={ checkGrade === 'ป' ? 'visible bg-alpha' : 'invisivle' }><img alt='background-img-test' src={BackgroundImgTestResultFirst} /></div>
                {
                  user.prefixName === 'นาย' || user.prefixName === 'ด.ช.' || user.prefixName === 'นางสาว' || user.prefixName === 'ด.ญ.' ?
                    <div className={ checkGrade === 'ป' ? 'visible girl' : 'invisivle' }>
                      <img alt='icon-pic-gender' src={ user.prefixName === 'นาย' || user.prefixName === 'ด.ช.' ? ABoyPic : AGirlPic}/>
                    </div>
                    : null
                }
              </div>
              : ''
          }
        </div>
      </div>
    )
    return (
      <div >
        <Modal  
          destroyOnClose
          visible = { visibleModalScoreResult }
          onCancel = { onCloseModalResultScore }
          footer = { false }
          centered
          bodyStyle = { checkGrade === 'ป' ?  { background: '#112AAE' } : { background: 'white' }}
          className={ checkGrade === 'ป' ? styles.primary_school : styles.high_school}
        >
          { detail }
          <iframe title='ifmcontentstoprint' id='ifmcontentstoprint' style= {{ height: '0px', width: '0px', position: 'absolute', visibility: 'hidden' }} />
        </Modal>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return{
    auth: state.auth.profile
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getStudentDetail
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ModalScoreResult)
