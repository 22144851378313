import { SET_USER } from './action'

const defaultState = {
  profile: null,
  token: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        profile: action.profile,
        token: action.token,
      }
    default: return state
  }
}  