import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CKEditor } from 'ckeditor4-react'
import {
  message,
  Spin,
  Button,
  Input,
  Form,
  Row,
  Col,
  Checkbox,
  Select
} from 'antd'
import {
  GetContactDetail,
  FetchToEditContactDetail,
  AddFaq
} from '../../redux/master/action'
import styles from '../ContactUs/ContactUs.module.scss'
const { TextArea } = Input
const {Option} = Select
class AddFaqComponent extends Component {
 
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      contactDetail:'',
      faqDetail : {},
      visible : [],
      guestStatus : false,
      teacherStatus : false,
      studentStatus: false,
      ready: false,
      edited: false
    }
  }
  componentDidMount(){
    // this.fetchContactUsDetail()
  }

  fetchFaqDetail = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.AddFaq()
    this.setState({ 
      isLoading: false,  
      contactDetail : res
    })
    if (res.error) message.error(res.error)
  }
  handleCheckbox = (e)=>{
    let { visible, [`${e.target.value}Status`]: status } = this.state
    if (status) {
      const i = visible.findIndex(vis => vis.role === e.target.value)
      visible.splice(i, 1)
    } else {
      visible.push({ role: e.target.value })
    }
    
    this.setState({
      [`${e.target.value}Status`]: !status,
      visible
    })
  }
  changeText = (e,name) =>{
    const {faqDetail, visible} = this.state
    faqDetail['visible'] = visible
    faqDetail['isActive'] = true
    if(name === 'answer'){
      faqDetail[name] = e.editor.getData()
    }else{
      faqDetail[name] = e.target.value
    }
    this.setState({
      faqDetail
    })
  }
  changeOption = (e,name) =>{
    const {faqDetail} = this.state
    faqDetail[name] = e
    this.setState({
      faqDetail
    })
  }
  onAdFaq = async (e) => {
    e.preventDefault()
    if (!e.err) {
      const res = await this.props.AddFaq(this.state.faqDetail)
      if(!res.error){
        message.success('เพิ่มข้อมูลสำเร็จ')
        this.props.backToHome()
      }else{
        message.error(res.error)
      }
    }else{
      message.error(e.err)
    }
  }

  render() {
    return (
      <div className={styles.examSetDiv}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <div className='flex-space-between' style={{marginBottom: '30px'}}>
            <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>เพิ่มข้อมูล</h1>
          </div>
          <div>
            <Row>
              <Col span={12}>
                <div style = {{backgroundColor : ''}}>
                  <Row style = {{ marginBottom : '2em'}}>
                    <Row>
                      <label>สถานะ</label>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Checkbox onChange = {this.handleCheckbox} value = 'guest'>ไม่ใช่สมาชิก</Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox onChange = {this.handleCheckbox} value = 'teacher'>ครู/ครูพิเศษ</Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox onChange = {this.handleCheckbox} value = 'student'>นักเรียน</Checkbox>
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <Form onSubmit={this.onAdFaq}>
                      <Row>
                        <label style = {{color : '#3D3D3D'}}>หัวข้อ</label>
                        <Select placeholder= 'การเข้าสู่ระบบ' className ={styles.inputInContact} onChange = {e => this.changeOption(e,'title')}>
                          <Option value = 'การเข้าสู่ระบบ'>การเข้าสู่ระบบ</Option>
                          <Option value = 'การสมัครสมาชิก'>การสมัครสมาชิก</Option>
                        </Select>
                        <br/>
                        <label style = {{color : '#3D3D3D'}}>คำถาม</label>
                        <TextArea className ={styles.inputInContact}
                          placeholder= ''
                          onChange = {e => this.changeText(e,'question')}
                          autosize={{ minRows: 2, maxRows: 6 }}>
                        </TextArea>
                        <label style = {{color : '#3D3D3D'}}>คำตอบ</label>
                        <CKEditor
                          initialData=''
                          config={{
                            toolbarGroups : [
                              { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                              { name: 'links', groups: [ 'links' ] },
                              { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                              { name: 'paragraph', groups: [ 'align', 'list', 'indent', 'blocks', 'bidi', 'paragraph' ] },
                              { name: 'insert', groups: [ 'insert' ] },
                              { name: 'document', groups: [ 'document', 'mode', 'doctools' ] },
                              { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                              { name: 'forms', groups: [ 'forms' ] },
                              '/',
                              '/',
                              { name: 'styles', groups: [ 'styles','Format', 'Font', 'FontSize' ] },
                              { name: 'tools', groups: [ 'tools' ] },
                              { name: 'others', groups: [ 'others' ] },
                              { name: 'about', groups: [ 'about' ] }
                            ],
                            removeButtons : 'Source,Save,About,NewPage,Preview,Print,Templates,Maximize,ShowBlocks,Styles,Format,Font,FontSize,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Unlink,Anchor,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,CopyFormatting,RemoveFormat,Cut',
                            imageUploadUrl: 'https://exam.sobtid.me/admin/questions/image', 
                            uploadUrl: 'https://exam.sobtid.me/admin/questions/image?responseType=json', 
                            filebrowserUploadUrl: 'https://exam.sobtid.me/admin/questions/imagefile?responseType=json',
                            editorUrl: 'https://cdn.ckeditor.com/4.17.2/full/ckeditor.js',
                            extraPlugins: 'justify, bidi, smiley, iframe, font, colorbutton, showblocks, find, selectall, pagebreak',
                            language: 'th'
                          }} 
                          onChange = {e => this.changeText(e,'answer')}
                        />
                      </Row>
                    </Form>
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                <div> 
                </div> 
              </Col>
            </Row>
          </div>
          <div>
            <div>
              <div className = {styles.devide_line}>
              </div>
              <div>
                <Row>
                  <Col span={6}>
                    <div>
                      <a onClick = {this.props.backToHome} className = {styles.cancle_link}>ยกเลิก</a>
                    </div>
                  </Col>
                  <Col span={6}></Col>
                  <Col span={6}></Col>
                  <Col span={6}>
                    <div styles = {styles.devide_line}>
                    </div> 
                    <div>
                      <Button className = {styles.save_btn} type='primary' htmlType='submit' onClick = {this.onAdFaq}>เพิ่มข้อมูล</Button>
                    </div> 
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    
    )
  }
}

const mapStateToProps = state => {
  return {
    contactDetail : state.master.contactDetail
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      AddFaq,
      GetContactDetail,
      FetchToEditContactDetail
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AddFaqComponent)