exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ExamsAll_inputSearchExamSet__1rLrk input {\n  width: 447px;\n  margin-right: 16px;\n  border-radius: 20px;\n  border-color: #015EA5; }\n\n.ExamsAll_menuExamSetCreate__1jC3x .ant-dropdown-menu-item, .ExamsAll_menuExamSetShare__2kOkw .ant-dropdown-menu-item {\n  font-size: 14px;\n  font-weight: normal; }\n\n.ExamsAll_menuExamSetCreate__1jC3x .anticon, .ExamsAll_menuExamSetShare__2kOkw .anticon {\n  font-size: 24px;\n  vertical-align: middle; }\n\n.ExamsAll_inputSearchExamSet__1rLrk input {\n  width: 447px;\n  margin-right: 16px;\n  border-radius: 20px;\n  border-color: #015EA5; }\n\n.ExamsAll_examsall-header__FQFlG {\n  margin-bottom: 30px; }\n  @media screen and (max-width: 991px) {\n    .ExamsAll_examsall-header__FQFlG {\n      margin-bottom: 10px; } }\n  .ExamsAll_examsall-header__FQFlG h1 {\n    font-size: 28px;\n    color: #05007A;\n    margin: 0 20px 0 0; }\n    @media screen and (max-width: 991px) {\n      .ExamsAll_examsall-header__FQFlG h1 {\n        font-size: 18px;\n        width: 100%; } }\n", ""]);

// Exports
exports.locals = {
	"inputSearchExamSet": "ExamsAll_inputSearchExamSet__1rLrk",
	"menuExamSetCreate": "ExamsAll_menuExamSetCreate__1jC3x",
	"menuExamSetShare": "ExamsAll_menuExamSetShare__2kOkw",
	"examsall-header": "ExamsAll_examsall-header__FQFlG"
};