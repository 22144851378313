import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  message,
  Spin,
  Button,
  Row,
  Col,
  Tabs,
} from 'antd'
import {
  getDataPolicyInMaster
} from '../../redux/master/action'
import EditIcon from '../IconSVG/icon_edit.svg'
import EditDataPolicy from '../DataPolicy/EditDataPolicy'
import styles from '../DataPolicy/DataPolicy.module.scss'

const {TabPane} = Tabs
class DataPolicy extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      dataPolicy:'',
      checkEdit : false,
      TabActiveKey : '2'
    }
  }

  componentDidMount(){
    this.fetchContactUsDetail()
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.action === nextProps.action){
      this.setState({
        checkEdit : false,
      })
    }
  }

  fetchContactUsDetail = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.getDataPolicyInMaster()
    this.setState({ 
      isLoading: false,
      dataPolicy : res
    })
    if (res.error) message.error(res.error)
  }
  goToEditComponent = () =>{
    this.setState({
      checkEdit : !this.state.checkEdit
    })
  }
  onCheckTabActiveKey = (key) => {  
    this.setState({
      TabActiveKey: key
    })
  }
  render() {
    return ( 
      <div>
        {
          this.state.checkEdit ? 
            <div>
              <EditDataPolicy 
                goToEditComponent = {this.goToEditComponent} 
                fetchContactUsDetail = {this.fetchContactUsDetail} 
                TabActiveKey = {this.state.TabActiveKey}
              />
            </div>
            :
            <div>
              <div className={styles.examSetDiv}>
                <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
                  <div className='flex-space-between' style={{marginBottom: '30px'}}>
                    <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>Data Policy</h1>
                    <div style={{display: 'flex'}}>
                      <Button type='primary' onClick = {this.goToEditComponent}>
                        <img src = {EditIcon} style ={{marginRight : 5}}/>
                        <label>แก้ไขข้อมูล</label>
                      </Button>
                    </div>
                  </div>
                  <div>
                    <Row>
                      <Tabs defaultActiveKey = {2} activeKey = {this.state.TabActiveKey} onChange={this.onCheckTabActiveKey} >
                        <TabPane tab={`ครู/ครูพิเศษ ${''}`} key='2' onTabClick = {this.teacherListTable}>
                          <Row>
                            <div>
                              <Row className = {styles.row_title}>
                                <Col span={2}>
                                  <label style = {{size : 24}}>หัวข้อ</label>
                                </Col>
                                <Col span={14} >
                                  <div className = {styles.title_condition}>
                                    {
                                      this.state.dataPolicy ? this.state.dataPolicy[0].title : '' 
                                    }
                                  </div>
                                </Col>
                              </Row>
                              <Row className = {styles.row_title}>
                                <Col span={2}><label>ข้อความ</label></Col>
                                <Col span={14}>
                                  <div className = {styles.description_condition}>
                                    {
                                      this.state.dataPolicy ? <div dangerouslySetInnerHTML={{__html: this.state.dataPolicy[0].content }}/> : ''
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Row>
                        </TabPane>
                        <TabPane tab={`นักเรียน ${''}`} key='3' onTabClick = {this.studentListTable}>
                          <Row>
                            <div>
                              <Row className = {styles.row_title}>
                                <Col span={2}>
                                  <label style = {{size : 24}}>หัวข้อ</label>
                                </Col>
                                <Col span={14} >
                                  <div className = {styles.title_condition}>
                                    {
                                      this.state.dataPolicy ? this.state.dataPolicy[1].title : '' 
                                    }
                                  </div>
                                </Col>
                              </Row>
                              <Row className = {styles.row_title}>
                                <Col span={2}><label>ข้อความ</label></Col>
                                <Col span={14}>
                                  <div className = {styles.description_condition}>
                                    {
                                      this.state.dataPolicy ? <div dangerouslySetInnerHTML={{__html: this.state.dataPolicy[1].content }}/> : ''
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Row>
                        </TabPane>
                      </Tabs>
                    </Row>
                  </div>
                </Spin>
             
              </div>
            </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    dataPolicy : state.master.dataPolicy
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataPolicyInMaster,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DataPolicy)