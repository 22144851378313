import { FetchCycle, AxiosRequest } from '../fetch'

// API =====================================================================
const API_REPORT = '/report'

// Fetch ===================================================================

const reportList       = ()               => () => AxiosRequest(API_REPORT,'get')
const examAllGroup     = (examId)         => () => AxiosRequest(`${API_REPORT}/${examId}/groups`,'get')
const examGroupDetail  = (examId,groupId) => () => AxiosRequest(`${API_REPORT}/${examId}/group/${groupId}`,'get')
const examGroupDetailStatsTests  = (examId,groupId,dataType) => () => AxiosRequest(`${API_REPORT}/${examId}/group/${groupId}/StatsTests/${dataType}`,'get')
const ExamByStudents   = (examId)         => () => AxiosRequest(`${API_REPORT}/${examId}/students`,'get')
const studentDetail    = (examId)         => () => AxiosRequest(`${API_REPORT}/${examId}`,'get')
const ExamStaticDetail = (examId)         => () => AxiosRequest(`${API_REPORT}/${examId}/static/lesson`,'get')
const ExamStaticScore = (examId)         => () => AxiosRequest(`${API_REPORT}/${examId}/static/score`,'get')
const ExamDeepStat     = (examId)         => () => AxiosRequest(`${API_REPORT}/${examId}/deep-stats`,'get')
const ExamGroupStats   = (examId, groupId) => () => AxiosRequest(`${API_REPORT}/${examId}/group/${groupId}/deep-stats`,'get')

// Action =====================================================================

export const getReportList       = ()               => FetchCycle(reportList())
export const getExamAllGroup     = (examId)         => FetchCycle(examAllGroup(examId))
export const getExamGroupDetail  = (examId,groupId) => FetchCycle(examGroupDetail(examId,groupId))
export const getExamGroupDetailStatsTests  = (examId,groupId,dataType) => FetchCycle(examGroupDetailStatsTests(examId,groupId,dataType))
export const getExamByStudents   = (examId)         => FetchCycle(ExamByStudents(examId))
export const getStudentDetail    = (examId)         => FetchCycle(studentDetail(examId))
export const getExamStaticDetail = (examId)         => FetchCycle(ExamStaticDetail(examId))
export const getExamStaticScore = (examId)         => FetchCycle(ExamStaticScore(examId))
export const getExamDeepStat     = (examId)         => FetchCycle(ExamDeepStat(examId))
export const getGroupExamDeepStat = (examId, groupId) => FetchCycle(ExamGroupStats(examId,groupId))