import { FetchCycle, AxiosRequest } from '../fetch'

// API =====================================================================
const API_STATISTIC = '/stats'

// constant =====================================================================
export const FETCH_STATISTIC_COLLECTION = 'FETCH_STATISTIC_COLLECTION'
export const FETCH_STATISTIC_DETAIL = 'FETCH_STATISTIC_DETAIL'
export const FETCH_STATISTIC_TYPE = 'FETCH_STATISTIC_TYPE'
export const SELECT_YEAR = 'SELECT_YEAR'
export const SELECT_MONTH = 'SELECT_MONTH'

// Fetch ===================================================================

const stats = (type, year) => () => AxiosRequest(`${API_STATISTIC}/${type}?year=${year}`,'get')
const statsDetail = (type, year, month,detail = '') => () => AxiosRequest(
  `${API_STATISTIC}/${type}/detail/${year}/${month}/${detail}`,'get'
)
const statsType = (type, year, month) => () => AxiosRequest(
  `${API_STATISTIC}/${type}/detail/${year}/${month}/type`,'get'
) 
const getStatsLoginTransaction = (year,month) => () => AxiosRequest(`${API_STATISTIC}/login/transactions/${year}/${month}`,'get')
const getStatsLoginTransaction2 = (year,month) => () => AxiosRequest(`${API_STATISTIC}/login/transactions2/${year}/${month}`,'get')
const getStateUserTransaction = (year, month) => () => AxiosRequest(`${API_STATISTIC}/user/transactions/${year}/${month}`,'get')
const getStatExamTransaction = (year, month) => () => AxiosRequest(`${API_STATISTIC}/exam/transactions/${year}/${month}`,'get')
const getStatFinishedTestingTransaction = (year, month) => () => AxiosRequest(`${API_STATISTIC}/finished/transactions/${year}/${month}`,'get')
const getStatTestingTransaction = (year, month) => () => AxiosRequest(`${API_STATISTIC}/testing/transactions/${year}/${month}`,'get')

// Action Callback =====================================================================

const setStats = (collection) => {
  return {
    type: FETCH_STATISTIC_COLLECTION,
    collection,
  }
}

const setStatsDetail = (detail) => {
  return {
    type: FETCH_STATISTIC_DETAIL,
    detail,
  }
}

const setStatsType = (data) => {
  return {
    type: FETCH_STATISTIC_TYPE,
    data,
  }
}

const setYear = (year) => { 
  return {
    type: SELECT_YEAR,
    year,
  }
}

const setMonth = (month) => {
  return {
    type: SELECT_MONTH,
    month,
  }
}

// Action =====================================================================

export const getStats = (type,year) => FetchCycle(stats(type,year), setStats)
export const getStatsDetail = (type, year, month, detail) => FetchCycle(statsDetail(type, year, month, detail), setStatsDetail)
export const getStatsType = (type, year, month) => FetchCycle(statsType(type, year, month), setStatsType)

export const setSelectYear = (year) => (dispatch, getState) => {
  dispatch(setYear(year))
}
export const setSelectMonth = (month) => (dispatch, getState) => {
  dispatch(setMonth(month))
}

export const statsLoginTransaction = (year, month) => FetchCycle(getStatsLoginTransaction(year,month))
export const statsLoginTransaction2 = (year, month) => FetchCycle(getStatsLoginTransaction2(year,month))

export const stateUserTransaction = (year, month) => FetchCycle(getStateUserTransaction(year,month))
export const statExamTransaction = (year, month) => FetchCycle(getStatExamTransaction(year,month))
export const statFinishedTestingTransaction = (year, month) => FetchCycle(getStatFinishedTestingTransaction(year,month))
export const statTestingTransaction = (year, month) => FetchCycle(getStatTestingTransaction(year,month))