import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  message,
  Spin,
  Button,
  Row,
  Col
} from 'antd'
import {
  getTersmAndConditions
} from '../../redux/master/action'
import EditIcon from '../IconSVG/icon_edit.svg'
import styles from '../TermsAndConditions/TermsAndConditions.module.scss'
import EditTermsAndConditions from '../TermsAndConditions/EditTermsAndConditions'
class TermsAndConditions extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      termDetail:'',
      checkEdit : false
    }
  }

  componentDidMount(){
    this.fetTerms()
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.action === nextProps.action){
      this.setState({
        checkEdit : false
      },()=>{
        this.fetTerms()
      })
    }
  }

  fetTerms = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.getTersmAndConditions()
    this.setState({ 
      isLoading: false,
      termDetail : res
    })
    if (res.error) message.error(res.error)
  }
  goToEditComponent = () =>{
    this.setState({
      checkEdit : !this.state.checkEdit
    },()=>{
      this.fetTerms()
    })
  }

  render() {
    return ( 
      <div>
        {
          this.state.checkEdit ? 
            <div>
              <EditTermsAndConditions goToEditComponent = {this.goToEditComponent}/>
            </div>
            :
            <div>
              <div className={styles.examSetDiv}>
                <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
                  <div className='flex-space-between' style={{marginBottom: '30px'}}>
                    <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>ข้อกำหนด</h1>
                    <div style={{display: 'flex'}}>
                      <Button type='primary' onClick = {this.goToEditComponent}>
                        <img src = {EditIcon} style ={{marginRight : 5}}/>
                        <label>แก้ไขข้อมูล</label>
                      </Button>
                    </div>
                  </div>
                  <div>
                    <Row className = {styles.row_title}>
                      <Col span={2}>
                        <label style = {{size : 24}}>หัวข้อ</label>
                      </Col>
                      <Col span={14} >
                        <div className = {styles.title_condition}>
                          {
                            this.state.termDetail.title ? this.state.termDetail.title : '' 
                          }
                        </div>
                      </Col>
                    </Row>
                    <Row className = {styles.row_title}>
                      <Col span={2}><label>ข้อความ</label></Col>
                      <Col span={14}>
                        <div className = {styles.description_condition} >
                          {
                            this.state.termDetail.content ? <div dangerouslySetInnerHTML={{__html: this.state.termDetail.content }}/> : '-'
                          }
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Spin>
              </div>
            </div>
        }
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {
    // termDetail : state.master.termDetail
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTersmAndConditions,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions)