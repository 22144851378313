import React, { Component } from 'react'
import styles from './Group.module.scss' 
import GroupWarningIcon from '../IconSVG/GroupWarningIcon.svg'
import {
  Modal,
  Button,
  Avatar
} from 'antd' 

class ModalNotAllowedStudent extends Component {
  constructor(props){
    super(props) 
    this.state = {
      nameStudent: ''
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.dataStudent !== nextProps.dataStudent){
      this.setState({ nameStudent: nextProps.dataStudent[0].studentName })
    }
  }
  render () {
    const { visible,closeModalNotAllowed, dataStudent, onRejectStudentGroup } = this.props 
    const { nameStudent } = this.state 
    return (
      <Modal
        visible = {visible}
        onOk={this.handleOk}
        onCancel={ closeModalNotAllowed }
        cancelButtonProps={false}
        centered={true}
        footer={
          <div className={styles.modalCreated} >
            <div className={styles.sub_modalCreated}>
              <Button key='cancle'
                onClick={ closeModalNotAllowed}
                className = {styles.btnCancle}>
            ยกเลิก
              </Button>
            </div>
            <div className={styles.sub_modalCreated_2}>
              <Button key='submit' type='primary' 
                className = {styles.btnLeave}
                onClick={ e => {
                  e.stopPropagation()
                  onRejectStudentGroup(dataStudent)
                  closeModalNotAllowed()
                }}>
            ไม่อนุมัติ
              </Button>
            </div>
            <div className={styles.sub_modalCreatedMobile} >
              <Button key='cancle' 
                type = 'link'
                className = {styles.btnCancleMobile}
                onClick={ closeModalNotAllowed}>
            ยกเลิก
              </Button>
            </div>
           
         
            
          </div>
         
        }
      >
        <div style={{textAlign: 'center'}}>
          <Avatar size={170} shape={'square'} src={GroupWarningIcon}/>
          <p className='text-medium' style={{fontSize: 20}}>ไม่อนุมัติ</p> 
          <h1>{ nameStudent }</h1> 
          <p className={styles.textModalIsNotAllowed}>ไม่อนุมัติให้เข้าร่วมกลุ่มนี้หรือไม่</p>
        </div>
      </Modal>
    )
  }
}
export default ModalNotAllowedStudent