// eslint-disable-next-line jsx-a11y/anchor-is-valid
import React from 'react'
import MathJax from 'react-mathjax-preview'
import ReactHtmlParser from 'react-html-parser'

export const displayer = (text) => {
  // if (text.indexOf('\\displaystyle') > -1) {
  //   if (text.indexOf('<img') > -1) {
  //     text = text.replace(/<span class="math-tex">\\\((.*?)\\\)<\/span>/g, (match, latex) => <MathJax math={latex} msDelayDisplay={100} />)
  //     text = text.replace(/<span class="math-tex">\\\((.*?)\\\)<\/span>/g, (match, latex) => `<img src="https://math.now.sh/?from=${encodeURI(latex.trim()).replace('(', '%28').replace(')', '%29').replaceAll('+', '%2B').replaceAll('&amp;', '%26').replaceAll('=', '%3D')}" />`)
  //     return <div dangerouslySetInnerHTML={{ __html: text }}></div>
  //   }
  //   return <MathJax math={text} msDelayDisplay={100} />
  // }
  const transform = function(node) {
    if (node.type === 'tag' && node.name === 'span' && node.attribs.class === 'math-tex') {
      if (node.children.length > 0) {
        return <MathJax math={node.children[0].data} msDelayDisplay={100} />
      }
    }
  }
  const htmlEle = ReactHtmlParser(text, {transform: transform})
  return htmlEle
}
