import React, { Component, useState }  from 'react' 
import { 
  Button,
  Input, 
  Dropdown, 
} from 'antd'    
import IconWarningTestByCodeTesting from '../../IconSVG/IconWarningTestByCodeTesting.svg' 
import IconSearch from '../../IconSVG/Search.svg'
import IconExamSet from '../../IconSVG/ExamSet.svg' 
import './ExamHeader.scss'
import styles from '../Exam.module.scss'
class ExamHeader extends Component {
  static defaultProps = {
    search: false,
    code: false,
    manage: false,
    title: ''
  }
  search (text) {
    const { searchKey } = this.props

    if (text) {
      return (
        <div className='search'>
          <Input
            value={searchKey}
            suffix={
              <Button type='primary'
                style={{paddingLeft: 5, paddingRight: 10}}
                onClick={() => this.props.onSearchExamSet(searchKey)}>
                <img src={IconSearch} style={{verticalAlign: 'middle'}} />{text}
              </Button>
            }
            placeholder='ชื่อชุด รหัสชุด จัดโดย'
            maxLength={100}
            className={styles.inputSearchExamSet}
            onPressEnter={ e => this.props.onSearchExamSet(e.target.value) }
            onChange={e => this.props.setSearchKey(e.target.value)}
          />
        </div>
      )
    }
    else {
      return (
        <Button type='secondary'
          onClick={() => this.props.showSearchModal()}>
          <img src={IconSearch} style={{verticalAlign: 'middle'}} />
        </Button>
      )
    }
  }

  code (text) {
    const { role, onCodeTestingPopup } = this.props
    return role === 'student' && (
      <Button type='primary' 
        // data-tutu = {text === 'ทำข้อสอบจากรหัส' ? 'CreateExamsCodepOne' : null}
        data-tut = {text === 'ทำข้อสอบจากรหัส' ? 'stepTourMyExanStudentSecond' : null}
        onClick = {()=>{ onCodeTestingPopup() ; setTimeout(()=>{this.props.nextStepTour()},300)}}
      >
        <img src={IconWarningTestByCodeTesting}/>
        {text ? text : ''}
      </Button>
    )
  }

  goTo(step) {
    const [setCurrent] = useState(0)
    setCurrent(step)
  }

  set = (text,id) => {
    return (
      <Dropdown overlay={this.props.menuExamSetCreate} trigger={['click']} > 
        <Button type='primary' 
          onClick = {()=>{setTimeout(()=>{this.props.nextStepTour()},100)}}
          // data-tut = {text === 'จัดชุดข้อสอบ' ? 'StudentCreateExamstepOne'  : null}
          data-tut = {text === 'จัดชุดข้อสอบ' ? 'stepTourMyExanStudentFirst'  : null}
          // data-tut-teacher = {text === 'จัดชุดข้อสอบ' ? 'TeacherCreateExamstepFirst'  : null}stepTourMyExamTeacherFirst
          data-tut-teacher = {text === 'จัดชุดข้อสอบ' ? 'stepTourMyExamTeacherFirst'  : null}
          id={id ? id : null}
        >
          <img src={IconExamSet} />
          {text ?  text : ''}
        </Button >
      </Dropdown>
    ) 
  }

  render () {
    const { search, code, manage, title } = this.props
    return (
      <div className='flex-space-between exam-header'>
        <h1>{title}</h1>

        {
          (search || code || manage) && (
            <div className='actions'>
              <div className='mobile'>
                {search && this.search()}
                {code && this.code()}
                {manage && this.set()}
              </div>

              <div  className='desktop'>
                {search && this.search('ค้นหา')}
                {code && this.code('ทำข้อสอบจากรหัส')}
                {manage && this.set('จัดชุดข้อสอบ/แบบฝึกหัด','create_exam_button')}
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

export default ExamHeader
