exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Provinces_row_form__15RYI {\n  padding: 40px;\n  padding-bottom: 40px; }\n  .Provinces_row_form__15RYI h1 {\n    color: #05007A; }\n  .Provinces_row_form__15RYI .Provinces_col_form__xDPOq {\n    padding-bottom: 52px; }\n  .Provinces_row_form__15RYI p {\n    margin-bottom: 0px; }\n\n.Provinces_row_in_form__SFhw3 {\n  margin-bottom: 21px; }\n\n.Provinces_inputSearch__1iAe5 input {\n  width: 393px;\n  margin-right: 16px;\n  border-radius: 20px;\n  border-color: #015EA5; }\n\n.Provinces_header_modal__2ucWr {\n  font-weight: 500;\n  font-size: 28px;\n  color: #05007A; }\n\n.Provinces_group_of_upload_modal__xGgDH .Provinces_modal_upload_file__1LqT0 {\n  text-align: center; }\n\n.Provinces_header_delete_modal__u6ryc {\n  width: 35%;\n  height: 50%; }\n  .Provinces_header_delete_modal__u6ryc h1 {\n    font-weight: 500;\n    font-size: 20px;\n    line-height: 29px; }\n\n.Provinces_icon_row__3OiRZ img {\n  cursor: pointer; }\n\n@media (max-width: 1440px) {\n  .Provinces_modal__1YQUT {\n    width: 40%; } }\n", ""]);

// Exports
exports.locals = {
	"row_form": "Provinces_row_form__15RYI",
	"col_form": "Provinces_col_form__xDPOq",
	"row_in_form": "Provinces_row_in_form__SFhw3",
	"inputSearch": "Provinces_inputSearch__1iAe5",
	"header_modal": "Provinces_header_modal__2ucWr",
	"group_of_upload_modal": "Provinces_group_of_upload_modal__xGgDH",
	"modal_upload_file": "Provinces_modal_upload_file__1LqT0",
	"header_delete_modal": "Provinces_header_delete_modal__u6ryc",
	"icon_row": "Provinces_icon_row__3OiRZ",
	"modal": "Provinces_modal__1YQUT"
};