import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styles from './Testing.module.scss'
import {
  Layout,
  Spin
} from 'antd'
import {
  getSolution
} from '../../redux/testing/action'
import {
  getExamSetDetailWithQuetion
} from '../../redux/exam/action'

import HeaderTesting from './Header'
import FooterTesting from './Footer'
import SidebarTesting from './SideBar'
import ModalServerUnavailable from '../Server/ModalServerUnavailable'
import CompetitionScienceIcon from '../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../IconSVG/CompetitionMathIcon.svg'
import MathCATIcon from '../IconSVG/MathCATIcon.svg'
import ScienceCATIcon from '../IconSVG/ScienceCATIcon.svg'
import IconScienceExam from '../IconSVG/ExamScience.svg'
import IconMathExam from '../IconSVG/ExamMath.svg'
import CustomIconMath from '../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../IconSVG/CustomIconScience.svg'
import ExerciseIconMath from '../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../IconSVG/ExerciseIconScience.svg'

import MatchChoiceTesting from './MatchChoiceTesting'
import ShortAnswerTesting from './ShortAnswerTesting'
import TrueFalseTesting from './TrueFalseTesting'
import MatchAnswerTesting from './MatchAnswerTesting'

import GraphTesting from './GraphTesting'

class TestingSolution extends Component {
  constructor(props){
    super(props)
    this.state = {
      // New state
      exam: {},
      startedTestingAt: 0,
      testingId: null,
      isLoading: false,
      seqQuestion: 1,
      progressTestings: [],
      questions: [],
      visibleDrawer: false,
      answerChangeAtIndex: null
    }

    // if (props.serverStatus && props.serverStatus.isOffline) {
    //   props.history.push('/home') 
    // }
  }
  componentDidMount = () => {
    this.fetchTesting()
  }

  fetchTesting = async () => {
    let { questions, progressTestings, startedTestingAt, testingId, exam } = this.state
    const { examId } = this.props.match.params

    this.setState({ isLoading: true })
    exam = await this.props.getExamSetDetailWithQuetion(examId)
    questions = exam.questions

    this.setState({
      isLoading: false,
      exam,
      testingId,
      questions,
      progressTestings,
      startedTestingAt
    })
  }

  onNextQuestion = () => {
    const { questions,seqQuestions,getAnswer, idAnswer } = this.state
    const { sendTesting } = this.props
    this.setState({
      seqQuestions: seqQuestions+1,
      typeQuestion: questions[seqQuestions].type,
      nextQuestion: true
    })
    if(getAnswer){
      sendTesting(idAnswer,getAnswer)
    }
    this.setState({
      getAnswer: null
    })
  }
  onPreviousQuestion = () => {
    let { questions, seqQuestions } = this.state
    seqQuestions--
    const typeQuestion = questions[seqQuestions-1].type
    this.setState({
      seqQuestions,
      typeQuestion,
    })
  }

  showDrawer = () => {
    this.setState({
      visibleDrawer: true,
    })
  }
  onCloseDrawer = () => {
    this.setState({
      visibleDrawer: false,
    })
  }

  onChangeQuestion = async (seq) => {
    this.setState({
      seqQuestion: seq
    })
  }

  render () {

    const {testingId, progressTestings, questions, seqQuestion, exam, visibleDrawer, startedTestingAt } = this.state
    const { history,auth, location, serverStatus } = this.props  
    const { displayHowTo } = exam 
    // if (exam.error) return <Redirect to={{ pathname: '/', state: { from: this.props.location } }} />
    let subjectIcon = IconMathExam
    if(exam.type === 'G') {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = IconMathExam
      }else {
        subjectIcon = IconScienceExam
      }
    } else if(exam.type === 'C'){
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = CompetitionMathIcon
      }else {
        subjectIcon = CompetitionScienceIcon
      }
    }else if(exam.type === 'CUSTOM'){
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = CustomIconMath
      }else {
        subjectIcon = CustomIconScience
      }
    } else if(exam.type === 'CAT') {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = MathCATIcon
      }else {
        subjectIcon = ScienceCATIcon
      }
    } else {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = ExerciseIconMath
      }else {
        subjectIcon = ExerciseIconScience
      }
    }
    
    // console.log(location)
    // console.log(location.state)
    // console.log('-------state-------') 
    // console.log(this.state)
    // console.log('-------auth-------')
    // console.log(auth)

    const isExample = location.state !== undefined && location.state.isExample ? true : false  
    return (
      <div className={ styles.Testing } >
        <Spin size='large' tip='Loading...'  spinning={this.state.isLoading} style={{ height: '100%' }}>
          <Layout style={{ height: '100%'}}>
            <HeaderTesting exam={exam} subjectIcon={subjectIcon} onFinishTesting={this.handleUserFinishTesting} startedTestingAt={startedTestingAt} isSolution={true} history={history} seqQuestion={seqQuestion} role={auth.role}/>
            {
              questions.map((question, i) => {
                const progressTesting = progressTestings.find(pt => pt.order === question.seq)
                switch (question.type) {
                  case 'SA': return seqQuestion === question.seq ? (<ShortAnswerTesting testingId={testingId} progressTesting={progressTesting} question={question} onSetAnswer={() => ''} key={i} displayHowTo={displayHowTo} isSolution={true} />) : ''
                  case 'MC': return seqQuestion === question.seq ? (<MatchChoiceTesting testingId={testingId} progressTesting={progressTesting} question={question} onSetAnswer={() => ''} key={i} displayHowTo={displayHowTo} isSolution={true} />) : ''
                  case 'TF': return seqQuestion === question.seq ? (<TrueFalseTesting testingId={testingId} progressTesting={progressTesting} question={question} onSetAnswer={() => ''} key={i} displayHowTo={displayHowTo} isSolution={true} />) : ''
                  case 'MA': return seqQuestion === question.seq ? (<MatchAnswerTesting testingId={testingId} progressTesting={progressTesting} question={question} onSetAnswer={() => ''} key={i} displayHowTo={displayHowTo} isSolution={true} isExample={isExample}/>) : ''
                  case 'GR': return seqQuestion === question.seq ? (<GraphTesting testingId={testingId} progressTesting={progressTesting} question={question} onSetAnswer={() => ''} key={i} displayHowTo={displayHowTo} isSolution={true} isExample={isExample}/>) : ''
                  default: return <div></div>
                }
              })
            }
            <SidebarTesting exam={exam} questions={questions} visibleDrawer={visibleDrawer} onCloseDrawer={this.onCloseDrawer} onChangeQuestion={this.onChangeQuestion} progressTestings={progressTestings} isSolution={true} isExample={isExample}/>
            <FooterTesting exam={exam} progressTestings={progressTestings} seqQuestion={seqQuestion} questionCount={questions.length} onShowDrawer={this.showDrawer} onNextQuestion={() => this.onChangeQuestion(seqQuestion+1)} onPreviousQuestion={() => this.onChangeQuestion(seqQuestion-1)} onFinishTesting={this.handleUserFinishTesting} onClickBookMark={this.handleBookmark} isSolution={true} />
          </Layout>
        </Spin>
        <ModalServerUnavailable serverStatus={serverStatus}/>
      </div>)
  }
}

const mapStateToProps = state => {
  return {
    ...state.testing,
    auth: state.auth.profile,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSolution,
      getExamSetDetailWithQuetion
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TestingSolution)
