exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ServerComponent_td_table_in_contact_us__yLCt7 {\n  padding-bottom: 2em;\n  margin-right: 2em; }\n\n.ServerComponent_td_right_in_contact_us__CcuQm {\n  width: 480px;\n  box-sizing: border-box;\n  border-radius: 4px; }\n\n.ServerComponent_td_table_in_edit_contact_us__2K9oZ {\n  padding-left: 35px; }\n\n.ServerComponent_cancle_link__2r_xz {\n  font-size: 16px;\n  line-height: 26px;\n  letter-spacing: 0.192px;\n  text-decoration-line: underline;\n  /* Gray 3 */\n  color: #828282; }\n\n.ServerComponent_detail_name__3uZFx {\n  padding-left: 1em;\n  margin-left: 1em;\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 40px;\n  background: #E5E5E5;\n  border: 1px solid #E0E0E0;\n  box-sizing: border-box;\n  border-radius: 4px; }\n\n.ServerComponent_detail_address__qWba8 {\n  padding-left: 1em;\n  margin-left: 1em;\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 40px;\n  height: 116px;\n  background: #E5E5E5;\n  border: 1px solid #E0E0E0;\n  box-sizing: border-box;\n  border-radius: 4px; }\n\n.ServerComponent_devide_line__1RTiU {\n  margin-top: 10%;\n  margin-bottom: 2%;\n  width: 90%;\n  height: 0px;\n  left: 233px;\n  top: 716px;\n  background: #BDBDBD;\n  border: 1px solid #E0E0E0; }\n\n.ServerComponent_save_btn__YBOKF {\n  width: 178px; }\n\n.ServerComponent_inputInContact__1bPbP {\n  margin-bottom: 2em; }\n\n.ServerComponent_modalServerClose__3ZlbR {\n  width: 35% !important;\n  overflow: auto;\n  min-width: 325px; }\n  .ServerComponent_modalServerClose__3ZlbR .ant-modal-confirm-btns {\n    display: none !important; }\n", ""]);

// Exports
exports.locals = {
	"td_table_in_contact_us": "ServerComponent_td_table_in_contact_us__yLCt7",
	"td_right_in_contact_us": "ServerComponent_td_right_in_contact_us__CcuQm",
	"td_table_in_edit_contact_us": "ServerComponent_td_table_in_edit_contact_us__2K9oZ",
	"cancle_link": "ServerComponent_cancle_link__2r_xz",
	"detail_name": "ServerComponent_detail_name__3uZFx",
	"detail_address": "ServerComponent_detail_address__qWba8",
	"devide_line": "ServerComponent_devide_line__1RTiU",
	"save_btn": "ServerComponent_save_btn__YBOKF",
	"inputInContact": "ServerComponent_inputInContact__1bPbP",
	"modalServerClose": "ServerComponent_modalServerClose__3ZlbR"
};