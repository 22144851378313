import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import cookieBlocks from '../../config-cookieconsent';

/**
 * @type {UserConfig}
 */
const pluginConfig = {
  current_lang: 'en',
  autorun: true,
  autoclear_cookies: false, // default: false
  page_scripts: true, // default: false
  cookie_expiration: 1,

  gui_options: {
    consent_modal: {
      layout: 'cloud',
      position: 'bottom center',
      transition: 'slide',
    }
  },

  remove_cookie_tables: true,
 
  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0,                               // default: 0
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function (user_preferences, cookie) {
    // callback triggered only once
    // console.log(`analytics ${analyticsEnabled ? 'enabled' : 'disabled'}`);
    const analyticsEnabled = window.CC.allowedCategory('analytics');
    if (analyticsEnabled && process.env.REACT_APP_ENV === 'production') {
      ReactGA.initialize('UA-50962149-2')
      ReactGA.pageview(window.location.pathname + window.location.search)

      ReactGA4.initialize('G-YGJB03L31Q')
      //ReactGA4.initialize('G-EEFK9224XQ')
      ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
      //ReactGA4.send('pageview')
      ReactGA4.event('screen_view', { 'app_name': 'otds', 'screen_name': window.location.pathname })

      ReactPixel.init('1943749415868241', {}, {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      })
      ReactPixel.pageView()
    }
  },

  onAccept: function (cookie) {
    const analyticsEnabled = window.CC.allowedCategory('analytics');
    if (analyticsEnabled && process.env.REACT_APP_ENV === 'production') {
      ReactGA.initialize('UA-50962149-2')
      ReactGA.pageview(window.location.pathname + window.location.search)

      ReactGA4.initialize('G-YGJB03L31Q')
      //ReactGA4.initialize('G-EEFK9224XQ')
      ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
      //ReactGA4.send('pageview')
      ReactGA4.event('screen_view', { 'app_name': 'otds', 'screen_name': window.location.pathname })

      ReactPixel.init('1943749415868241', {}, {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      })
      ReactPixel.pageView()
    }
  },

  onChange: function (cookie, changed_preferences) {
    // ...
  },

  languages: {
    en: {
      consent_modal: {
        title: 'นโยบายการจัดเก็บคุกกี้',
        description:
          'เว็บไซต์ของเราใช้คุกกี้เพื่อจดจำความชอบและการเข้าชมเว็บไซต์ของคุณ ซึ่งจะช่วยให้คุณได้รับประสบการณ์ที่ดีจากการใช้งาน เลือกที่ "ยอมรับ" เพื่อยินยอมให้ใช้งานคุกกี้ในการใช้งานเว็บไซต์.',
        primary_btn: {
          text: 'ยอมรับทั้งหมด',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'ตั้งค่าคุ้กกี้',
          role: 'settings', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'ตั้งค่าความเป็นส่วนตัว',
        save_settings_btn: 'บันทึก & ยอมรับ',
        accept_all_btn: 'ยอมรับทั้งหมด',
        reject_all_btn: 'ปฏิเสธทั้งหมด',
        close_btn_label: 'ปิด',
        cookie_table_headers: [
          { col1: 'Name' },
        ],
        blocks: cookieBlocks,
      },
    },
  },
};

export default pluginConfig;
