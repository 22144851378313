exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Relation_text_h1__3XAJV {\n  font-size: 28px;\n  color: #05007A;\n  margin: 0;\n  padding-top: 15px;\n  padding-bottom: 15px; }\n\n.Relation_icon_row__lxbXW img {\n  cursor: pointer; }\n\n.Relation_header_modal__21A4L {\n  font-weight: 500;\n  font-size: 28px;\n  color: #05007A; }\n\n.Relation_title_module__3tCm9 {\n  font-size: 16px;\n  line-height: 24px; }\n\n.Relation_button_save_modal__3YFVp {\n  float: right;\n  width: 70%; }\n\n.Relation_header_delete_modal__36cmm h1 {\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 29px; }\n\n.Relation_button_delete_modal__25_wA {\n  width: 70%;\n  float: left;\n  margin-left: 15px; }\n\n@media (max-width: 1440px) {\n  .Relation_modal__245W1 {\n    width: 40%; }\n  .Relation_button_save_modal__3YFVp {\n    height: 32px; }\n  .Relation_button_delete_modal__25_wA {\n    width: 70%;\n    float: left;\n    margin-left: 15px; } }\n", ""]);

// Exports
exports.locals = {
	"text_h1": "Relation_text_h1__3XAJV",
	"icon_row": "Relation_icon_row__lxbXW",
	"header_modal": "Relation_header_modal__21A4L",
	"title_module": "Relation_title_module__3tCm9",
	"button_save_modal": "Relation_button_save_modal__3YFVp",
	"header_delete_modal": "Relation_header_delete_modal__36cmm",
	"button_delete_modal": "Relation_button_delete_modal__25_wA",
	"modal": "Relation_modal__245W1"
};