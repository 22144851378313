import { FetchCycle, AxiosRequest } from '../fetch' 

// API =====================================================================

const API_PROVINCES = '/master/provinces' 

// Fetch ===================================================================

const provincesList = () => () => AxiosRequest(API_PROVINCES+'?limit=100','get')
const createProvinces = (province) => () => AxiosRequest(API_PROVINCES,'post',province)
const importExcelProvinces = (provinceFile) => () => AxiosRequest(API_PROVINCES+'/import','post',provinceFile)
const updateProvince = (provinceId,province) => () => AxiosRequest(`${API_PROVINCES}/${provinceId}`,'patch',province)
const removeProvince = (provinceId) => () => AxiosRequest(`${API_PROVINCES}/${provinceId}`,'delete')

// Action =====================================================================

export const getProvincesList = () => FetchCycle(provincesList())
export const onCreateProvinces = (province) => FetchCycle(createProvinces(province))
export const onImportExcelProvinces = (provinceFile) => FetchCycle(importExcelProvinces(provinceFile))
export const onUpdateProvince = (provinceId,province) => FetchCycle(updateProvince(provinceId,province))
export const deleteProvince = (provinceId) => FetchCycle(removeProvince(provinceId))