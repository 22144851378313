import * as CONST from './action'
import moment from 'moment'

const defaultState = {
  collection: [],
  detail: [],
  type: [],
  year: parseInt(moment().format('YYYY'))
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONST.FETCH_STATISTIC_COLLECTION:
      return {
        ...state,
        collection: action.collection
      }

    case CONST.FETCH_STATISTIC_DETAIL:
      return {
        ...state,
        detail: action.detail
      }

    case CONST.FETCH_STATISTIC_TYPE:
      return {
        ...state,
        type: action.data
      }

    case CONST.SELECT_YEAR:
      return {
        ...state,
        year: action.year
      }

    case CONST.SELECT_MONTH:
      return {
        ...state,
        month: action.month
      }

    default: return state
  }
}
