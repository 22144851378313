import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Header from './Header'
import { 
  Table,
  Spin,
  Switch 
} from 'antd'
import {
  getAllRelationList,
  onUpdateRelation,
  onUpdateMoveRelation,
} from '../../redux/relation/action'
import { DndProvider, DragSource, DropTarget } from 'react-dnd-cjs'
import HTML5Backend from 'react-dnd-html5-backend-cjs'
import update from 'immutability-helper'
import ModalRelation from './ModalRelation'
import styles from './Relation.module.scss'
import Edit from '../IconSVG/Edit.svg'
import EditGray from '../IconSVG/EditGray.svg'
import Delete from '../IconSVG/Delete.svg'
import DeleteGray from '../IconSVG/DeleteGray.svg'
import IconMoveRow from '../IconSVG/IconMoveRow.svg' 

let dragingIndex = -1

class BodyRow extends React.Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget,  ...restProps } = this.props
    const style = { ...restProps.style, cursor: 'move' } 
    let { className } = restProps
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward'
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward'
      }
    }
    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    )
  }
} 
const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index
    return {
      index: props.index,
    }
  },
} 
const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index  
    if (dragIndex === hoverIndex) {
      return
    }  
    props.moveRow(dragIndex, hoverIndex)  
    monitor.getItem().index = hoverIndex
    
  },
}  
const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor ) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver() 
})) (
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
    
  }))(BodyRow)) 

class Relation extends Component {
  constructor(props){
    super(props)
    this.state = { 
      isLoading: false,
      relationData: [],
      indexRow: false,
      checkMouseOverEdit: false,
      checkMouserOverDelete: false,
      modalVisible: false, 
      item: {},
      typeModal: false,
      switchData: { 
        visible: true
      },
      relationItems: []
    }
  }

  columns = [
    {
      title: '',
      dataIndex: '',
      key: 'drag',
      render:()=> {
        return ( <img alt='icon-move-row' src={IconMoveRow}/>)
      },
      width:'30px'
    },
    {
      title: ()=> {
        return (<span>คำนำหน้าชื่อ</span>)
      },
      dataIndex: 'name',
      key: 'name',
      width: '150px'
    },
    {
      title: 'แสดง',
      dataIndex: 'visible',
      key: 'visible',
      render:(visible,record) => {
        return (<Switch 
          checkedChildren = 'เปิด'
          unCheckedChildren = 'ปิด'
          checked = {visible ? true : false }
          onChange={(checked,e) => {
            e.stopPropagation()
            this.onSwitch(record,checked,'visible') 
          }}/>)
      },
      width: '100px',
      align: 'center'
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      render:(edit,record,index)=> {
        const { checkMouseOverEdit, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkMouseOverEdit: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouseOverEdit: false})
        }
        return (
          <div className={styles.icon_row} >
            <img alt='edit' title='แก้ไข' onClick={e => {
              e.stopPropagation()
              this.onOpenModal(index,'edit')
            }} onMouseOver={mouserOver}  onMouseLeave={mouseLeave} src={checkMouseOverEdit && indexRow === index ? Edit : EditGray}/></div>
        )
      },
      width: '50px'
    },
    {
      title: '',
      dataIndex: '',
      key: 'delete',
      render:(deleteRow,record,index)=> {
        const { checkMouserOverDelete, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkMouserOverDelete: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouserOverDelete: false})
        }
        return (
          <div className={styles.icon_row}>
            <img alt='delete' title='ลบข้อมูล' onClick={e=>{
              e.stopPropagation()
              this.onOpenModal(index,'delete')
            }} onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkMouserOverDelete && indexRow === index ? Delete : DeleteGray}/></div>
        )
      }
    },
  ]

  components = {
    body: {
      row: DragableBodyRow,
    },
  }

  componentDidMount = () => {
    this.fetchRelationName()  
  }
  fetchRelationName = async() => {
    this.setState({ isLoading: true })
    const res = await this.props.getAllRelationList() 
    this.setState({ 
      relationData: res,
      relationItems: res.items,
      isLoading: false 
    })
  }
  
  moveRow = async(dragIndex, hoverIndex) => {
    const { relationItems } = this.state  
    const dragRow = relationItems[dragIndex] 
    let data = { seq: hoverIndex }
    await this.setState(
      update(this.state, {
        relationItems: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }), 
    ) 
    await this.props.onUpdateMoveRelation(relationItems[dragIndex]._id,data)
  }
  onSwitch = async(record,checked,role) => {
    const { switchData } = this.state
    this.setState({ isLoading: true}) 
    switchData.visible =  checked 
    await this.props.onUpdateRelation(record._id,switchData) 
    this.setState({ isLoading: false})
    this.fetchRelationName() 
  } 
  onOpenModal = (index, type) => {  
    const { relationData } = this.state 
    this.setState({
      typeModal: type,
      item: relationData.items[index],
      modalVisible: true
    })
  } 
  onCloseModal = () => {
    this.setState({
      modalVisible: false
    })
  }
  render(){
    const { isLoading, relationItems, modalVisible, item, typeModal } = this.state  
    return ( 
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading }>
          <Header onOpenModal={this.onOpenModal}/>
          <DndProvider backend={HTML5Backend}>
            <Table
              scroll={{x:true}}
              rowKey={ (record,i) => i }
              columns={this.columns}
              dataSource={relationItems} 
              components={this.components}
              pagination={false}
              onRow={(record, index) => ({
                index,
                moveRow: this.moveRow,
                onMouseEnter: ()=> this.setState({ indexRow: index })
              })}
            />
          </DndProvider> 
          <ModalRelation relationItems={relationItems} fetch={this.fetchRelationName} typeModal={typeModal} items={item} modalVisible={modalVisible} onCloseModal={this.onCloseModal}/>
        </Spin> 
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
      getAllRelationList,
      onUpdateRelation,
      onUpdateMoveRelation
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(Relation)