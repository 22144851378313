import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux' 
import { 
  Table,
  Spin,
  Switch 
} from 'antd'
import Header from './Header.js'
import { DndProvider, DragSource, DropTarget } from 'react-dnd-cjs'
import HTML5Backend from 'react-dnd-html5-backend-cjs'
import update from 'immutability-helper'
import IconMoveRow from '../IconSVG/IconMoveRow.svg' 

import Edit from '../IconSVG/Edit.svg'
import EditGray from '../IconSVG/EditGray.svg'
import Delete from '../IconSVG/Delete.svg'
import DeleteGray from '../IconSVG/DeleteGray.svg'

import {
  getSubjectsList,
  onSwitchSubject,
  onDragAndDropSubject
} from '../../redux/subjects/action'

import ModalSubject from './ModalSubject'

let dragingIndex = -1

class BodyRow extends React.Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget , ...restProps } = this.props
    const style = { ...restProps.style, cursor: 'move' }

    let { className } = restProps
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward'
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward'
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    )
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index
    return {
      index: props.index,
    }
  },
}

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}
const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
)

class Subjects extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      subjectList: [], 
      indexRow: null,
      checkEdit: false,
      checkDelete: false,
      modalEdit: false,
      modalDelete: false,
      rowId: null,
      oldValue: null
    }
  }
  columns = [
    {
      title: '',
      dataIndex: '',
      key: 'drag',
      render:()=> {
        return ( <img alt='icon-move-row' src={IconMoveRow}/>)
      },
      width:'30px'
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
      width: '150px'
    }, 
    {
      title: 'ข้อสอบตามหลักสูตร',
      dataIndex: 'isActive',
      key: 'isActive',
      render:(isActive,record) => {
        return (
          <Switch
            checkedChildren = 'เปิด'
            unCheckedChildren = 'ปิด'
            checked = { isActive ? true : false }
            onChange={(checked,e) => {
              e.stopPropagation()
              this.turnOnOffSwitchSubject(record.id, record, 'curriculum')
              // this.onGenerateSubject(record,checked,'teacher') 
            }}
          />
        )
      },
      width: '200px'
    },
    {
      title: 'ข้อสอบแข่งขัน',
      dataIndex: 'isCompetitionActive',
      key: 'isCompetitionActive',
      render:(isActive,record) => {
        return (
          <Switch
            checkedChildren = 'เปิด'
            unCheckedChildren = 'ปิด'
            checked = { isActive ? true : false }
            onChange={(checked,e) => {
              e.stopPropagation()
              this.turnOnOffSwitchSubject(record.id, record, 'competition')
              // this.onGenerateSubject(record,checked,'teacher') 
            }}
          />
        )
      },
      width: '150px'
    },
    {
      title: 'แบบฝึกหัด',
      dataIndex: 'isExerciseActive',
      key: 'isExerciseActive',
      render:(isActive,record) => {
        return (
          <Switch
            checkedChildren = 'เปิด'
            unCheckedChildren = 'ปิด'
            checked = { isActive ? true : false }
            onChange={(checked,e) => {
              e.stopPropagation()
              this.turnOnOffSwitchSubject(record.id, record, 'exercise')
              // this.onGenerateSubject(record,checked,'teacher') 
            }}
          />
        )
      },
      width: '150px'
    },
    {
      key: 'edit',
      render: (edit, record, index) => {
        const { indexRow,checkEdit } = this.state 
        const mouseOver = () => { this.setState({ checkEdit: true }) }
        const mouseLeave = () => { this.setState({ checkEdit: false }) } 
        return (
          <img alt='edit' title='แก้ไข' onClick={() => { this.setState({ modalEdit:true,rowId: record.id, oldValue: record.name }) }} onMouseLeave={mouseLeave} onMouseOver={mouseOver} src={checkEdit && indexRow === index ?  Edit : EditGray} style={{ cursor: 'pointer' }}/>
        )
      },
      width: '50px'
    },
    {
      key: 'delete',
      render: (deleteSubject, record, index) => {
        const { indexRow,checkDelete } = this.state
        const mouseOver = () => { this.setState({ checkDelete: true }) }
        const mouseLeave = () => { this.setState({ checkDelete: false }) } 
        return (
          <img alt='remove-data' title='ลบข้อมูล' onClick={() => { this.setState({ modalDelete:true,rowId: record.id, oldValue: record.name }) }} onMouseLeave={mouseLeave} onMouseOver={mouseOver} src={checkDelete && indexRow === index ?  Delete : DeleteGray} style={{ cursor: 'pointer' }}/>
        )
      }, 
    },
  ]
  components = {
    body: {
      row: DragableBodyRow,
    },
  }
  componentDidMount = () => {
    this.fetchSubjects()
  }
  fetchSubjects = async() => {
    const { getSubjectsList } = this.props
    this.setState({ isLoading: true })
    const res = await getSubjectsList()
    const data = res.map((s) => {  
      if (!s.hasOwnProperty('isCompetitionActive')) {
        s = {...s, isCompetitionActive: s.isActive}
      }
      if (!s.hasOwnProperty('isExerciseActive')) {
        s = {...s, isExerciseActive: s.isActive}
      }
      return s
    })
    this.setState({ subjectList: data, isLoading: false })
  }

  closeEditModal = () => { this.setState({ modalEdit: false }) }
  closeDeleteModal = () => { this.setState({ modalDelete: false }) }

  moveRow = async(dragIndex, hoverIndex) => {
    const { subjectList } = this.state
    const { onDragAndDropSubject } = this.props
    const dragRow = subjectList[dragIndex] 
    
    this.setState(
      update(this.state, {
        subjectList: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
    )
    let seq = { seq: hoverIndex} 
    await onDragAndDropSubject(subjectList[dragIndex].id,seq)
  }
  turnOnOffSwitchSubject = async (id, record, columnName) => {
    this.setState({ isLoading: true })
    const { onSwitchSubject } = this.props
    let data = { isActive: !record.isActive}
    if (columnName == 'competition') {
      data = { isCompetitionActive: !record.isCompetitionActive }
    }
    else if (columnName == 'exercise') {
      data = { isExerciseActive: !record.isExerciseActive }
    }
    await onSwitchSubject(id, data)
    await this.fetchSubjects()
    this.setState({ isLoading: false })
  }
   
  setSubjectsFiltersBySearch = (subjectsFilters,isEmptySearch) => { 
    if(!isEmptySearch) {
      this.setState({
        subjectList: subjectsFilters
      }) 
    }else{
      this.fetchSubjects()
    } 
  }
  render(){
    const { isLoading,subjectList,modalEdit,modalDelete,rowId,oldValue } = this.state   
    return (
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading }> 
          <Header fetch={this.fetchSubjects} dataSearch={subjectList} setSubjectsFiltersBySearch={this.setSubjectsFiltersBySearch}/>
          <DndProvider backend={HTML5Backend}>
            <Table
              scroll={{x:true}}
              rowKey={ (record,i) => i}
              columns={this.columns}
              dataSource={subjectList}
              components={this.components}
              pagination={false}
              onRow={(record, index) => ({
                index,
                moveRow: this.moveRow,
                onMouseEnter: () => { this.setState({ indexRow: index }) }
              })}
            />
          </DndProvider> 
          <ModalSubject value={oldValue} id={rowId} fetch={this.fetchSubjects} visible={modalEdit} formName={'Edit_Subject'} onCancel={this.closeEditModal} />
          <ModalSubject value={oldValue} id={rowId} fetch={this.fetchSubjects} visible={modalDelete} formName={'Delete_Subject'} onCancel={this.closeDeleteModal} />
        </Spin>
      </div>
    )
  }
}
 
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
      getSubjectsList,
      onSwitchSubject,
      onDragAndDropSubject
    },
    dispatch
  )

export default connect(null,mapDispatchToProps)(Subjects)