import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styles from './Testing.module.scss'
import {
  Layout,
  Spin
} from 'antd'
import {
  getSolution
} from '../../redux/testing/action'
import {
  getDetailMyExam
} from '../../redux/exam/action'

import HeaderTesting from './Header'
import FooterTesting from './Footer'
import SidebarTesting from './SideBar'

import CompetitionScienceIcon from '../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../IconSVG/CompetitionMathIcon.svg'
import MathCATIcon from '../IconSVG/MathCATIcon.svg'
import ScienceCATIcon from '../IconSVG/ScienceCATIcon.svg'
import IconScienceExam from '../IconSVG/ExamScience.svg'
import IconMathExam from '../IconSVG/ExamMath.svg'
import CustomIconMath from '../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../IconSVG/CustomIconScience.svg'
import ExerciseIconMath from '../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../IconSVG/ExerciseIconScience.svg'
import ModalServerUnavailable from '../Server/ModalServerUnavailable'

import MatchChoiceTesting from './MatchChoiceTesting'
import ShortAnswerTesting from './ShortAnswerTesting'
import TrueFalseTesting from './TrueFalseTesting'
import MatchAnswerTesting from './MatchAnswerTesting'
import GraphTesting from './GraphTesting'


class TestingSolution extends Component {
  constructor(props){
    super(props)
    this.state = {
      // New state
      exam: {},
      startedTestingAt: 0,
      testingId: null,
      isLoading: false,
      seqQuestion: 1,
      progressTestings: [],
      questions: [],
      visibleDrawer: false,
      answerChangeAtIndex: null
    }

    // if (props.serverStatus && props.serverStatus.isOffline) {
    //   props.history.push('/home') 
    // }
  }
  componentDidMount = () => {
    this.fetchTesting()
  }

  fetchTesting = async () => {
    let { questions, progressTestings, startedTestingAt, testingId, exam } = this.state
    const { id } = this.props.match.params

    this.setState({ isLoading: true })
    const testing = await this.props.getSolution(id) 
    if (!testing.error) {
      testingId = testing._id

      // for (let x in testing.questions)
      // {
      //   testing.questions[x].orgseq = testing.questions[x].seq
      // }
      console.log("===AAA Solution===============")
      console.log(testing)
      console.log("===AAAA Solution===============")
      // console.log(this.props)
      // console.log("===AAAA Solution===============")
      if (this.props !== null && this.props.profile !== null && this.props.profile.role !== null && this.props.profile.role === 'student')
      {
        // console.log("STUDENT")
        let shuffledlist = testing.mcRandomOrder
        let reindex_questions = []
        //if (shuffledlist !== null) Fix 2022_05_26
        if (shuffledlist !== undefined && shuffledlist !== null && shuffledlist.length > 0)
        {
          for (let x in shuffledlist)
          {
            let tmpq = Object.assign({}, testing.questions[shuffledlist[x]]);
            tmpq.seq = +x + +1
            reindex_questions.push(tmpq)
          }
        }
        else
        {
          reindex_questions = testing.questions
        }

        testing.questions = reindex_questions
        // console.log(reindex_questions)
      }

      questions = testing.questions
      progressTestings = testing.progressTestings
      exam = await this.props.getDetailMyExam(testing.examId)
    }

    this.setState({
      isLoading: false,
      exam,
      testingId,
      questions,
      progressTestings,
      startedTestingAt
    })
  }

  onNextQuestion = () => {
    const { questions,seqQuestions,getAnswer, idAnswer } = this.state
    const { sendTesting } = this.props
    this.setState({
      seqQuestions: seqQuestions+1,
      typeQuestion: questions[seqQuestions].type,
      nextQuestion: true
    })
    if(getAnswer){
      sendTesting(idAnswer,getAnswer)
    }
    this.setState({
      getAnswer: null
    })
  }
  onPreviousQuestion = () => {
    let { questions, seqQuestions } = this.state
    seqQuestions--
    const typeQuestion = questions[seqQuestions-1].type
    this.setState({
      seqQuestions,
      typeQuestion,
    })
  }

  showDrawer = () => {
    this.setState({
      visibleDrawer: true,
    })
  }
  onCloseDrawer = () => {
    this.setState({
      visibleDrawer: false,
    })
  }

  onChangeQuestion = async (seq) => {
    this.setState({
      seqQuestion: seq
    })
  }

  render () {
    const { serverStatus } = this.props
    const {testingId, progressTestings, questions, seqQuestion, exam, visibleDrawer, startedTestingAt } = this.state
    const { displayHowTo, displaySolution } = exam 
    // if (exam.error) return <Redirect to={{ pathname: '/', state: { from: this.props.location } }} />
    let subjectIcon = IconMathExam
    if(exam.type === 'G') {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = IconMathExam
      }else {
        subjectIcon = IconScienceExam
      }
    } else if(exam.type === 'C'){
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = CompetitionMathIcon
      }else {
        subjectIcon = CompetitionScienceIcon
      }
    }else if(exam.type === 'CUSTOM'){
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = CustomIconMath
      }else {
        subjectIcon = CustomIconScience
      }
    } else if(exam.type === 'CAT') {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = MathCATIcon
      }else {
        subjectIcon = ScienceCATIcon
      }
    } else {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = ExerciseIconMath
      }else {
        subjectIcon = ExerciseIconScience
      }
    }
    
    return (
      <div className={ styles.Testing } >
        <Spin size='large' tip='Loading...'  spinning={this.state.isLoading} style={{ height: '100%' }}>
          <Layout style={{ height: '100%'}}>
            <HeaderTesting exam={exam} subjectIcon={subjectIcon} onFinishTesting={this.handleUserFinishTesting} startedTestingAt={startedTestingAt} isSolution={true} history={this.props.history} seqQuestion={seqQuestion} />
            {

              questions.map((question, i) => {
                // let tmpseq = question.orgseq
                // if (this.props.profile.role === 'student')
                // {
                //     tmpseq = question.seq
                // }


                const progressTesting = progressTestings.find(pt => pt.questionId === question._id) //(pt => pt.order === tmpseq)

              // console.log('=======progressTestings')
              // console.log(question)
              // console.log(progressTestings)
              // console.log(progressTesting)
              // console.log('=======progressTestings')

                switch (question.type) {
                  case 'SA': return seqQuestion === question.seq ? (<ShortAnswerTesting testingId={testingId} progressTesting={progressTesting} question={question} onSetAnswer={() => ''} key={i} displayHowTo={displayHowTo} isSolution={displaySolution} />) : ''
                  case 'MC': return seqQuestion === question.seq ? (<MatchChoiceTesting testingId={testingId} progressTesting={progressTesting} question={question} onSetAnswer={() => ''} key={i} displayHowTo={displayHowTo} isSolution={displaySolution} />) : ''
                  case 'TF': return seqQuestion === question.seq ? (<TrueFalseTesting testingId={testingId} progressTesting={progressTesting} question={question} onSetAnswer={() => ''} key={i} displayHowTo={displayHowTo} isSolution={displaySolution} />) : ''
                  case 'MA': return seqQuestion === question.seq ? (<MatchAnswerTesting testingId={testingId} progressTesting={progressTesting} question={question} onSetAnswer={() => ''} key={i} displayHowTo={displayHowTo} isSolution={displaySolution} />) : ''
                  case 'GR': return seqQuestion === question.seq ? (<GraphTesting testingId={testingId} progressTesting={progressTesting} question={question} onSetAnswer={() => ''} key={i} displayHowTo={displayHowTo} isSolution={displaySolution} />) : ''
                  default: return <div></div>
                }
              })
            }
            <SidebarTesting questions={questions} visibleDrawer={visibleDrawer} onCloseDrawer={this.onCloseDrawer} onChangeQuestion={this.onChangeQuestion} progressTestings={progressTestings} isSolution={true} />
            <FooterTesting exam={exam} progressTestings={progressTestings} seqQuestion={seqQuestion} questionCount={questions.length} onShowDrawer={this.showDrawer} onNextQuestion={() => this.onChangeQuestion(seqQuestion+1)} onPreviousQuestion={() => this.onChangeQuestion(seqQuestion-1)} onFinishTesting={this.handleUserFinishTesting} onClickBookMark={this.handleBookmark} isSolution={true} />
          </Layout>
        </Spin>
        <ModalServerUnavailable serverStatus={serverStatus}/>
      </div>)
  }
}

const mapStateToProps = state => {
  return {
    ...state.testing,
    profile: state.auth.profile
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSolution,
      getDetailMyExam
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TestingSolution)
