/* eslint-disable no-undef */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styles from './TestingResult.module.scss'
import ResultScore from './ResultScore'
import * as htmlToImage from 'html-to-image'
import FacebookScoreShareIcon from '../IconSVG/FacebookScoreShareIcon.svg'
import SaveScoreIcon from '../IconSVG/SaveScoreIcon.svg'
import TwitterScoreShareIcon from '../IconSVG/TwitterScoreShareIcon.svg'
import Download from 'downloadjs'
import {
  Typography,
  Col,
  Row,
  Button
} from 'antd'
import {
  FacebookShareButton,
  TwitterShareButton
} from 'react-share'
import ModalServerUnavailable from '../Server/ModalServerUnavailable'
import { GetProfile } from '../../redux/auth/action'
import { getResultTesting } from '../../redux/testing/action'
import { getDetailMyExam } from '../../redux/exam/action'

const { Text } = Typography

class TestResult extends Component {
  constructor(props){
    super(props)
    this.state = {
      visibleShare: false,
      exam: {},
      testingResult: {},
      user: {},
    }
    // if (props.serverStatus && props.serverStatus.isOffline) {
    //   props.history.push('/home') 
    // }
  }
  componentDidMount() {
    this.fetchingData()
  }
  fetchingData = async () => { 
    const { location } = this.props.history
    
    const [exam, testingResult] = await Promise.all([
      location.state.groupId ? this.props.getDetailMyExam(this.props.match.params.examId,location.state.groupId) : this.props.getDetailMyExam(this.props.match.params.examId),
      this.props.getResultTesting(this.props.match.params.testingId) 
    ])
    let user = []
    if(this.props.history.location.state.cameFrom !== 'homeSuggest') { 
      user = this.props.GetProfile()
    }
    this.setState({
      exam,
      testingResult,
      user,
      logo: false
    }) 
  }
  onOpenShare = async() => {
    this.setState({
      visibleShare: true,
      logo: true
    })
  }
  onCloseShare = () => {
    this.setState({
      visibleShare: false,
      logo: false
    })
  }
  onDownload = async () => {
    const resultId = await document.getElementById('result')
    await htmlToImage.toPng(resultId).then(async(dataUrl) => {
      await Download(dataUrl, 'Score.png', 'image/png')
    })
  }
  backToHomePage = () => {
    const { cameFrom, groupId } = this.props.history.location.state  
    if(cameFrom === 'group') {
      this.props.history.push(`/group/${groupId}`)
    }else{
      this.props.history.push('/')
    }  
  }

  render(){
    let { visibleShare, downloadScore, exam, testingResult, user } = this.state 
    const { serverStatus } = this.props
    
    const back = (
      <Text onClick={this.backToHomePage}
        className={styles.text_back_to_home_page}
        underline>
        กลับสู่หน้าหลัก
      </Text>
    )

    const showAnswers = (
      <Button type={'primary'}
        className={styles.button_content}
        onClick={() => this.props.history.push(`/testing/${this.props.match.params.testingId}/solution`, {isExample: false, ...this.props.history.location.state })}>
        { exam.displaySolution ? 'ดูเฉลย' : 'ดูคำตอบ' }
      </Button>
    )

    const saveImage = (
      <div className='save-imge' style={{ zIndex: 2 }} onClick={this.onDownload}>
        <img alt='icon-save-score' src={SaveScoreIcon} style = {{ cursor: 'pointer' }}/>
        <p>บันทึกรูปภาพ</p>
      </div>
    )

    const facebook = (
      // eslint-disable-next-line no-undef
      <FacebookShareButton url={`${process.env.REACT_APP_API_URL}/share/fb/${this.props.match.params.testingId}/testing`}
        className={styles.point_cursor_share_icon}>
        <img alt='icon-facebook-share-score' src={FacebookScoreShareIcon}/>
      </FacebookShareButton>
      // <FacebookProvider appId='430899564505204'>
      //   <Share href={`${process.env.REACT_APP_WEB_URL}/examset/${exam._id}`}>
      //     {({ handleClick, loading }) => (
      //       <button type='button' disabled={loading} onClick={handleClick}><img src={FacebookScoreShareIcon}/></button>
      //     )}
      //   </Share>
      // </FacebookProvider>
    )

    const twitter = (
      <TwitterShareButton url={`${process.env.REACT_APP_WEB_URL}`}
        className={styles.point_cursor_share_icon}>
        <img alt='twitter-score-share-icon' src={TwitterScoreShareIcon}/>
      </TwitterShareButton>
    ) 
    return (
      <div className={styles.Row_main}>
        <ResultScore profileImage={this.props.location.state.profileImage} exam={exam} testingResult={testingResult} user={user} visibleShare ={visibleShare} downloadScore={downloadScore}/>

        <div className={styles.actions}  >
          <div className='mobile'>
            <div className='back'>
              {/* {exam.displaySolution ? showAnswers : ''} */}
              {showAnswers}
              {back}
            </div>

            <div className='sub-actions'>
              <div className='save' style={{ zIndex: 2 }}>
                {saveImage}
              </div>

              <div className='social'>
                <div className={styles.group_social_share_right}>
                  {twitter}
                </div>

                <div className={styles.group_social_share_right}>
                  {facebook}
                </div>
              </div>
            </div>
          </div>

          <div className='desktop'>
            <Row className={styles.row_button}>
              <Col xl={20} lg={20} md={18} offset={2}> 
                <Row>
                  <Col xl={8} lg={8}>
                    <div className={styles.group_social_share}>
                      <Col xl={6} lg={6}>
                        {saveImage}
                      </Col>
                    </div>
                  </Col>
                  <Col xl={8} lg={8}>
                    <div className={styles.button_content_div}>
                      {/* {exam.displaySolution ? showAnswers : ''} */}
                      { exam.type !== 'CAT' ? showAnswers : ''}
                    </div>
                    <div className={styles.back_to_home_page}>
                      {back}
                    </div>
                  </Col>
                  <Col xl={8} lg={8}>
                    <div className={styles.group_social_share_right}>
                      <Col xl={8} lg={8} style={{ display: 'flex' }}>
                        <div style={{ paddingTop:'10px'}}>
                          <h3 className={styles.row_result_text_content}>แชร์</h3>
                        </div>
                        <div className={styles.group_social_share_right}>
                          {facebook}
                        </div>
                        <div className={styles.group_social_share_right}>
                          {twitter}
                        </div>
                      </Col> 
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <ModalServerUnavailable serverStatus={serverStatus}/>
      </div>
    )
  }
}
 
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getResultTesting,
      getDetailMyExam,
      GetProfile
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(TestResult)
