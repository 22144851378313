/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
import React, { Component, Fragment }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Link,
} from 'react-router-dom'
import QRCode from 'qrcode'
import {
  message,
  Spin,
  Button,
  Tabs,
  Modal,
  Empty,
  Input,
  Dropdown,
  Menu,
  Avatar,
Icon
} from 'antd'
import {
  getGroupList,
  removeStudentFromGroup,
  approveStudentGroup,
  rejectStudentGroup,
  getGroupData,
  RemovedStudentInGroup,
  getExamsInGroup,
  getExamsNotInGroup,
  addExams,
  editGroup,
  removeExam,
  updateExam,
  getListOfStudents,
  studentRequestToJoinGroup, 
} from '../../../redux/group/action'
import {
  IconDelete,
  IconCorrect,
} from '../../IconSVG'
import GroupRequest from './groupRequest'
import { AxiosRequest } from '../../../redux/fetch'
import { dateFromTimeStamp } from '../../../util/helper'
import DetailStudentInGroup from './detailStudentInGroup'
import DetailStudentRequest from './detailStudentRequest'
import DetailExam from './detailExam'
import IconCopyLink from '../../IconSVG/IconCopyLink.svg'
import IconQRCode from '../../IconSVG/IconQRCode.svg'
import IconShare from '../../IconSVG/Share.svg'
import IconExamAdd from '../../IconSVG/ExamAdd.svg'
import IconSearch from '../../IconSVG/Search.svg'
import IconGroup from '../../IconSVG/Group.svg'
import AddUserIcon from '../../../assets/Icon/AddUser.svg'
import GroupAlert from '../../IconSVG/Group_alert.svg'
import StudentGroup from './StudentGroup'
import styles from '../Group.module.scss'
import ModalAddExam from '../ModalAddExam'
import ModalAddStudent from '../ModalAddStudent'
import ModalQRCode from '../ModalQRCode'
import moment from 'moment'
import SearchModal from '../../modal/SearchModal'
import DefaultGroup from '../../IconSVG/DefaultGroup.svg'
import ExamAdd from '../../IconSVG/ExamAdd.svg'
import Share from '../../IconSVG/Share.svg'
import Edit from '../../IconSVG/Edit.svg'
import Delete from '../../IconSVG/Delete.svg'
import NumStudentInGroup from '../../IconSVG/NumStudentInGroup.svg'
import ListItems from '../../Display/ListItems'
import ModalAddGroup from '../ModalAddGroup'
const { TabPane } = Tabs
const API_STUDENT_IN_GROUP = groupId => `/groups/${groupId}/students`
// const API_STUDENT_REQUEST = groupId => `/groups/${groupId}/students`

const extractJoinItem = (arr) => {
  try {
    return arr.items.map( t => ({
      key: t.studentId,
      id: t.studentId,
      name: t.studentName,
      profileImage: t.profileImage,
      schoolName: t.schoolName,
      ownerName: t.ownerName,
      createdAt: dateFromTimeStamp(t.jointDate),
      leftAt: dateFromTimeStamp(t.leftDate),
      status: t.status || '-',
      studentCount: t.studentCount
    }) )
  } catch (e) {
    return []
  }
}

const extractRequestorItem = (arr) => {
  try {
    return arr.items.map( t => ({
      key: t.requestorId,
      id: t.requestorId,
      name: t.requestorName,
      profileImage: t.profileImage,
      schoolName: t.schoolName,
      ownerName: t.ownerName,
      createdAt: dateFromTimeStamp(t.requestedDate),
      studentCount: t.studentCount
    }) )
  } catch (e) {
    return []
  }
}

const extractGroupDetail = (arr) => {
  try {
    return arr.map( t => ({
      key: '1',
      logo: t.logo,
      code: t.code,
      name: t.name,
      status: t.status,
      studentCount: t.studentCount,
      createdAt: dateFromTimeStamp(t.createdAt),
    }) )
  } catch (e) {
    return []
  }
}

class GroupDetail extends Component {
  menu = (group) => {
    return(
      <Menu onClick={(e) => e.stopPropagation()}>
        <Menu.Item key='0'>
          <CopyToClipboard
            text={`${window.location.host}/group/${group.id}`}
            onCopy={this.onCopyLinkSuccess}
          >
            <span
              onClick ={e=> {
                e.stopPropagation()
                this.setState({
                  visibleDropdown: false
                })
              }}
            >
              <img src={IconCopyLink}/>
            คัดลอกลิงก์</span>
          </CopyToClipboard>
        </Menu.Item>
        <Menu.Item key='1'>
          <span
            onClick ={e=> {
              e.stopPropagation()
              this.onQrCodeGenerate(group)
              this.onOpenQRCodeModal()
              this.setState({
                visibleDropdown: false
              })
            }}
          ><img src={IconQRCode}/>แชร์คิวอาร์โค้ด</span>
        </Menu.Item>
      </Menu>
    )}
  columns = [
    { title: '',
      dataIndex: 'logo',
      key: 'logo',
      align: 'center',
      render: logo => <img src={logo ? logo : DefaultGroup} alt='group_logo' className={styles.logoGroup}/>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'รหัสกลุ่ม',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'ผู้สร้างกลุ่ม',
      dataIndex: 'owner',
      render: (owner) => {
        return <span>{`${owner.prefixName ? owner.prefixName : ''} ${owner.firstName} ${owner.lastName}`}</span>
      },
      key: 'owner'
    },
    {
      title: 'วันที่เข้าร่วม',
      dataIndex: 'createdAt',
      key:'createdAt'
    },
    {
      title: '',
      dataIndex: 'status',
      key:'status'
    }
  ] 
  static defaultProps = {
    group: []
  }
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      studentJoinList: [],
      studentRequestList: [],
      studentGroupRequest: [],
      examsInGroup: [],
      filterExamsInGroup: [],
      examsNotInGroup: [],
      groupId: null,
      isOpenAddExamModal: false,
      listOfStudents: null,
      studentJoinGroup: [],
      filterStudentJoinGroup : [],
      studentRequestGroup: [],
      studentRejected:[],
      filterStudentRequestGroup: [],
      value: '',
      tabActiveKey : 1,
      placeholderInGroupDetail : 'ค้นหา (วิชา, รหัสข้อสอบ)',
      examsStudent : null,
      searchGroup: null,
      isShowQrCodeModal: false,
      isSearchModal: false,
      searchData: [],
      isShowAddGroupModal : false,
      studentJoin: 0,
      countAlert: 0,
      groupDetail: [],
      mygroup: {},


      isOpenAddStudentModal: false,

    }
    if (props.auth.role === 'student'){
      this.setState({
        placeholderInGroupDetail : 'ค้นหา (ชื่อ, โรงเรียน)'
      })
      this.columns = this.columns.concat(
        [
          {
            title: 'สถานะ',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, group) => (
              <div>
                {(() => {
                  switch (group.status) {
                    case 'request':
                      return (<span>รอการอนุมัติ</span>)
                    default: return ''
                  }
                })()}
              </div>
            )
          },
          {
            title: '',
            key: 'action',
            render: (text, group) => (
              <div>
                {(() => {
                  switch (group.status) {
                    case 'request':
                      return (<Button
                        className={styles.btnCancel}
                        onClick={(e) => {
                          e.stopPropagation()
                          this.onCancelRequest(group)
                        }}
                      >ยกเลิกคำขอ</Button>)
                    case 'join':
                      return (
                        <span style={{ cursor: 'pointer' }} onClick={ e => {
                          e.stopPropagation()
                          this.onLeaveGroup(group)
                        }}>
                          <IconDelete style={{fontSize: 24, verticalAlign: 'text-bottom', marginRight: 5}}/>
                          <span style={{textDecoration: 'underline'}}>คัดออกจากกลุ่ม</span>
                        </span>)
                    default: return ''
                  }
                })()}
              </div>
            ),
          },
        ]
      )
    }
  }
  onAvatarChange = (imageBase64) => {
    this.setState({ logo: imageBase64 })
  }
  componentDidMount = async () => { 
    let { isLoading } = this.state 
    isLoading = true 
    if (this.props.auth.role !== 'student') { 
      await this.fetchStudent()
      await this.fetchGetListOfStudents()    
    }

    await this.fetchGroupsDetail() 
    
    await this.fetchExams()
    this.fetchSearchGroup()
    isLoading = false 
    this.setState({ isLoading }) 
  }

  fetchSearchGroup = async () => {
    let { searchGroup } = this.state
    // this.setState({ isLoading: true })
    const res = await  this.props.getGroupData(this.props.match.params.id) 
    if(res.deletedAt){
      message.error(`กลุ่ม ${res.name} ถูกลบแล้ว`)
      this.props.history.push('/group')
    }else{
      searchGroup = res
      this.setState({ searchGroup })
    }
    // this.setState({ searchGroup , isLoading: false})
  }

  fetchGroupsDetail = async () => {
    // this.setState({ isLoading: true})
    // eslint-disable-next-line no-undef 
    let str = this.props.history.location.pathname
    let response = str.slice(-24)
    const res = await this.props.getGroupData(response)
    const arrRes = [res]
    this.setState({
      groupId: response,
      studentGroupRequest: extractGroupDetail(arrRes),
      groupDetail: res,
      mygroup:res
      // isLoading: false
    })
    // console.log(this.state.mygroup)
    if (res.error) message.error(res.error)
  }

  fetchStudent = async () => {
    const groupId = this.props.match.params.id
    // this.setState({ isLoading: true })
    const res = await Promise.all([
      AxiosRequest(API_STUDENT_IN_GROUP(groupId), 'get'), 
      this.props.getExamsNotInGroup(groupId)
    ])  
    // this.setState({ isLoading: false })
    if (res[0].error || res[1].error) return message.error('ไม่สามารถดึงข้อมูลรายชื่อได้ในขณะนี้')
    this.setState({ studentJoinList: extractJoinItem(res[0]), studentRequestList: extractRequestorItem(res[1]), examsInGroup: res[2] })
  }

  fetchGetListOfStudents = async () => {
  
    let { studentJoinGroup, studentRequestGroup,studentLeftGroup,studentRejected, studentJoin } = this.state
    //Fix bug -----
    studentJoin = 0;
    studentRejected=[];
    studentRequestGroup=[];
    studentJoinGroup=[];

    // not seen, it is using
    studentLeftGroup = [];
    //Fix bug -----


    const groupId = this.props.match.params.id
    // this.setState({ isLoading: true })
    const res = await this.props.getListOfStudents(groupId) 
    await res.items.map(s =>  {
      // s.status === 'join' || s.status === 'left' ? studentJoinGroup.push(s) : s.status === 'request' ? studentRequestGroup.push(s) : studentRejected.push(s) 
      if(s.status === 'join' || s.status === 'left') {
        studentJoinGroup.push(s)
        if(s.status === 'join') { 
          studentJoin++
        }
      }else if(s.status === 'request'){
        studentRequestGroup.push(s)
      }else{
        studentRejected.push(s) 
      } 
    })
    await this.setState({
      listOfStudents: res,
      studentJoinGroup,
      studentRequestGroup,
      studentLeftGroup,
      studentRejected,
      studentJoin
    })
    this.setState({
      filterStudentJoinGroup : studentJoinGroup,
      filterStudentRequestGroup : studentRequestGroup,
      // isLoading: false
    })
  }

  fetchExams = async () => {
    const groupId = this.props.match.params.id
    const { getExamsInGroup } = this.props

    await getExamsInGroup(groupId)
  }

  onCopyLinkSuccess = () => {
    const modal = Modal.success({
      closable: true,
      centered: true,
      icon: null,
      maskClosable: true,
      okButtonProps: {hidden: true},
      width: 220,
      content: (
        <div style={{textAlign: 'center'}}>
          <IconCorrect style={{fontSize: 120}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>คัดลอกลิงก์สำเร็จ</h2>
        </div>
      )
    })
    setTimeout(() => {
      modal.destroy()
    }, 2000)
  }
  onQrCodeGenerate = async ( ) => {
    // eslint-disable-next-line no-undef
    let url = window.location.href
    const generateQRCode = await QRCode.toDataURL(url)
    this.setState({
      imgQRCode: generateQRCode
    })
  }
  onOpenQRCodeModal = () => { this.setState({ isShowQrCodeModal: true})}
  onCloseQRCodeModal = () => { this.setState({ isShowQrCodeModal: false })}

  onRemoveStudentFromGroup = async(studentList) => {
    if (!studentList || studentList.length < 1) return

    const studentIds = studentList.map( student => student.studentId )
    const groupId = this.props.match.params.id
    await this.props.removeStudentFromGroup(groupId, {studentIds})
    await this.setState({ studentJoinGroup:[], studentRequestGroup:[] })
    await this.fetchGetListOfStudents()
  }

  removedStudent = async (id) => { 
    const data = { 'studentIds' : [id.studentId] }
    
    await this.props.RemovedStudentInGroup(this.props.match.params.id, data)
    await this.setState({ studentJoinGroup:[], studentRequestGroup:[] })
    await this.fetchGetListOfStudents()
  }

  onApproveStudentGroup = async (studentList) => { 
    if (!studentList || studentList.length < 1) return
    const studentIds = studentList.map( student => student.studentId )
    const groupId = this.props.match.params.id 
    await this.props.approveStudentGroup(groupId, {studentIds})
    await this.setState({ studentJoinGroup:[], studentRequestGroup:[] })
    this.fetchGetListOfStudents()
  }

  onRejectStudentGroup = async (studentList) => {
    if (!studentList || studentList.length < 1) return
    const studentIds = studentList.map( student => student.studentId )
    const groupId = this.props.match.params.id
    await this.props.rejectStudentGroup(groupId, {studentIds})
    await this.setState({
      studentRequestGroup:[],
      studentJoinGroup:[]
    })
    this.fetchGetListOfStudents()
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  toggleAddStudentModal = async (isOpen) => {
    const groupId = this.props.match.params.id

    // let mygroup = {}

    // console.log("groupId:",groupId)
    if (isOpen) {
      // examsInNotGroup = await this.props.getExamsNotInGroup(groupId)
      // mygroup = await  this.props.getGroupData(this.props.match.params.id) 

    }
    this.setState({ isOpenAddStudentModal: isOpen }) //, examsInNotGroup
  }

  ////////////////////////////////////////////////////////////////////////////////////////

  toggleAddExamModal = async (isOpen) => {
    const groupId = this.props.match.params.id
    let examsInNotGroup = []
    if (isOpen) {
      examsInNotGroup = await this.props.getExamsNotInGroup(groupId)
    }
    this.setState({ isOpenAddExamModal: isOpen , examsInNotGroup })
  }

  getTitleRequestStudent = () => {
    return `${this.state.studentRequestList.length > 0 ? '' : ''}`
  }

  handleAddExam = async (examIds) => {
    const { group, addExams, getExamsInGroup } = this.props
    await addExams(group.id, examIds)
    await getExamsInGroup(group.id)
    this.setState({ isOpenAddExamModal: false })
  }

  handleRemoveExam = async (examId) => {
    const { removeExam, getExamsInGroup } = this.props
    await removeExam(this.state.groupDetail.id, examId)
    await getExamsInGroup(this.state.groupDetail.id)
  }

  handleUpdateExam = async (examId, data) => {
    const { updateExam, getExamsInGroup } = this.props
    await updateExam(this.state.groupDetail.id, examId, data)
    await getExamsInGroup(this.state.groupDetail.id)
  }
  setSearchKey = (e) => {
    this.setState({ searchKey: e })
    if(e === ''){
      this.setState({
        studentJoinGroup  : this.state.filterStudentJoinGroup,
        studentRequestGroup : this.state.filterStudentRequestGroup,
        examsInGroup : this.props.exams,
        examsStudent : this.props.exams
      })
    }
  }
  searchStudentJoinGroup = (value) => {
    this.setState({ value })

    const { studentJoinGroup,filterStudentJoinGroup,tabActiveKey,filterStudentRequestGroup,studentRequestGroup} = this.state
    if(tabActiveKey === '2'){
      let data = studentJoinGroup
      if(data){
        data = studentJoinGroup.filter(n => n.studentName.toLowerCase().indexOf(value.toLowerCase()) > -1 || n.schoolName.toLowerCase().indexOf(value.toLowerCase()) > -1)
      }
      this.setState({
        studentJoinGroup: data,
        searchData: data
      })
      if(value === ''){
        this.setState({
          studentJoinGroup  : filterStudentJoinGroup,
          searchData: []
        })
      }
    }
    if(tabActiveKey === '3') {
      let data = studentRequestGroup
      if(data){
        data = studentRequestGroup.filter(n => n.studentName.toLowerCase().includes(value.toLowerCase()) == true || n.schoolName.toLowerCase().includes(value.toLowerCase()) == true)
      }
      this.setState({
        studentRequestGroup : data,
        searchData: data
      })
      if(value === ''){
        this.setState({
          studentRequestGroup  : filterStudentRequestGroup,
          searchData: []
        })
      }
    }
    if(tabActiveKey === '1'){
      let data = this.props.exams
      if(data){
        data = this.props.exams.filter(n => n.name.toLowerCase().includes(value.toLowerCase()) == true || n.code.toLowerCase().includes(value.toLowerCase()) == true)
      }
      this.setState({
        examsInGroup : data,
        searchData: data
      })
      if(value === ''){
        this.setState({
          examsInGroup  : this.props.exams,
          searchData: []
        })
      }
    }
    if(this.props.auth.role === 'student'){
      let data = this.props.exams
      if(data){
        data = this.props.exams.filter(n => n.name.toLowerCase().includes(value.toLowerCase()) == true || n.code.toLowerCase().includes(value.toLowerCase()) == true)
      }
      this.setState({
        examsStudent : data,
        searchData: data
      })
    }
  }
  onCheckTabActiveKey = (key) => {
    this.setState({
      tabActiveKey: key
    },()=>{
      if(key === '2'){
        this.setState({
          placeholderInGroupDetail : 'ค้นหา (ชื่อ, โรงเรียน)'
        })
      }
      if(key === '3'){
        this.setState({
          placeholderInGroupDetail : 'ค้นหา (ชื่อ, โรงเรียน)'
        })
      }
      if(key === '1'){
        this.setState({
          placeholderInGroupDetail : 'ค้นหา (วิชา, รหัสข้อสอบ)'
        })
      }
    })
  }

  showSearchModal = () => {
    this.setState({ isSearchModal: !this.state.isSearchModal})

    if (this.state.isSearchModal) {
      if (this.props.auth.role !== 'student') {
        this.fetchStudent()
        this.fetchGetListOfStudents()
        if (!this.props.group) this.fetchGroups()
        this.fetchStudent()
      } else {
        this.fetchGroupsDetail()
      }
      
      this.fetchExams()
      this.fetchSearchGroup()
    }
  }
  onRequestToJoin = async (groupId) => {  
    const { history } = this.props
    const res = await this.props.studentRequestToJoinGroup(groupId)
    res.error ? message.error(res.error) : message.success('ขอเข้าร่วมกลุ่มสำเร็จ') 
    return history.push('/group')
  }
  listItems = (array) => {
    const groupId = this.props.history.location.pathname.slice(7,31) 
    const arr = array !== null ? array : []
    const srcData = arr.map((item) => { 
      const menuItems = this.props.auth.role === 'student' ? [
        {
          attribute: (
            <span>
              {(() => {
                switch (item.status) {
                  case 'request':
                    return (<Button
                      className={styles.btnCancel}
                      style={{ marginRight: 0 }}
                      onClick={(e) => {
                        e.stopPropagation()
                        this.onCancelRequest(item)
                      }}
                    >ยกเลิกคำขอ</Button>)
                  case 'join':
                    return (
                      <span style={{ cursor: 'pointer' }} onClick={ e => {
                        e.stopPropagation()
                        this.setState({
                          groupForModal : item,
                          visibleModalDelete : true
                        })
                      }}>
                        <span style={{textDecoration: 'underline'}}>ออกจากกลุ่ม</span>
                      </span>)
                  case 'reject':
                    return (
                      <span style={{ cursor: 'pointer' }} onClick={ e => {
                        e.stopPropagation()
                        this.removedStudent(item)
                      }}>
                        <span style={{textDecoration: 'underline'}}>ตกลง</span>
                      </span>)
                  case 'none': 
                    return (
                      <Button
                        onClick = { () => { this.onRequestToJoin(groupId) }}
                        style = {{ height: '37px',width: '124px' }}>
                        ขอเข้าร่วมกลุ่ม
                      </Button>
                    )
                  case 'left': 
                    return (
                      <Button
                        onClick = { () => { this.onRequestToJoin(groupId) }}
                        style = {{ height: '37px',width: '124px' }}>
                        ขอเข้าร่วมกลุ่ม
                      </Button>
                    )
                  default: return ''
                }
              })()}
            </span>
          )
        }
      ] : [
        {
          attribute: (
            <span onClick={ (e) => {e.stopPropagation()}} className='actionIcon'>
              <img src={ExamAdd} alt='exam-add'/> เพิ่มข้อสอบ
            </span>
          ),
        },
        {
          attribute: (
            <Dropdown overlay={this.menu(item)} trigger={['click']}
              onClick={e => e.stopPropagation()}>
              <span className='ant-dropdown-link actionIcon'>
                <img src={Share} alt='share'/> แชร์
              </span>
            </Dropdown>
          )
        },
        {
          attribute: (
            <span onClick={ e => {
              e.stopPropagation()
              this.onEditGroup(item)
            }} className='actionIcon'>
              <img src={Edit} alt='edit'/> แก้ไข
            </span>
          )
        },
        {
          attribute: (
            <span onClick={ e => {
              e.stopPropagation()
              this.onDeleteGroup(item)
            }}
            className='actionIcon'>
              <img src={Delete} alt='delete'/> ลบ
            </span>
          )
        }
      ]

      return {
        avatar: item.logo ? item.logo : DefaultGroup,
        title: item.name,
        description: item.code,
        status: `วันที่สร้าง: ${item.createdAt}`,
        actions: menuItems,
        path: `/group/${item.id}`
      }
    })

    return srcData
  }
  setAvatarBase64Ref = (ref) => this.avatarRef = ref
  onCloseModalAddGroup = () => this.setState({isShowAddGroupModal: false, editGroup: null})
  onSubmitGroup = async (data) => {
    const { logo, name } = data
    if (data.id) {
      await this.props.editGroup(data.id, {
        logo,
        name,
      })
    }
    this.onCloseModalAddGroup()
    this.fetchGroups()
  }

  alertDisabledGoToGroup = () => {
    let { countAlert } = this.state
    if(countAlert < 1) {
      Modal.info({
        title:(<div style={{ textAlign: 'center' }}><Avatar size={175} src={GroupAlert} shape={'square'}/></div>),
        centered: true,
        icon: false,
        content: (
          <div style={{ textAlign: 'center' }}> 
            <p>เข้าร่วมกลุ่มได้เฉพาะสิทธิ์การใช้งานนักเรียน</p> 
          </div>
        ),
        onOk: () => {
          this.props.history.push('/')
        },
      })
      countAlert++
      this.setState({ countAlert })
    }
     
    return null
  }

  render() {
    const { group, auth, exams } = this.props
    const {
      searchGroup, studentGroupRequest, examsInNotGroup, 
      studentJoinGroup, studentRequestGroup, 
      isLoading, searchData, tabActiveKey, studentJoin
      ,groupDetail, mygroup
    } = this.state
    if ( !isLoading && auth.role !== 'student' && auth.role !== 'admin' && groupDetail.owner._id !== this.props.auth.id) {
      return this.alertDisabledGoToGroup()
    }
    
    const searchKey = this.state.value
    let header,groupInfo 
    if (searchGroup ) {
      if (auth.role === 'student' && (searchGroup.status === 'request' || searchGroup.status === 'none' || searchGroup.status === 'left')) return (
        <div className='divDetailGroup'>
          <div className='mobile'>
            <h1 style = {{ fontWeight: 500,fontSize: '18px', color: '#05007A' }}>กลุ่ม</h1>
            <ListItems
              srcData={this.listItems([searchGroup])}
              avatar
              status
              history = { this.props.history }
              dropdownDivider = { true }
              dropdownWidth = { '200px' } 
            />
          </div>
          <div className='desktop'>
            <GroupRequest
              {...this.props}
              groupSearch={searchGroup}
              fetchStudent={this.fetchStudent}
              fetchSearchGroup={this.fetchSearchGroup}
              fetchGroupsDetail={this.fetchGroupsDetail}
              groupId={this.props.match.params.id}
            />
          </div>
        </div>
        
      )

      header = (
        <div className='flex-space-between group-detail'>
          <div className='pagination'>
            <Link style={{color: '#006FAD', margin: 0}} to='/group'>กลุ่ม</Link>
            <span style={{color: '#828282'}}> &gt; รายละเอียด</span>
          </div>

          <div className='actions'>
            <div className='mobile'>
              {
                auth.role !== 'student' ? (
                  <Fragment>
                    <Button
                      type='secondary'
                      onClick={(e) => {
                        e.stopPropagation()
                        this.showSearchModal()
                      }}>
                      <img src={IconSearch} alt='icon-search'/>
                      {/* <IconSearch style={{verticalAlign: 'middle'}}/> */}
                    </Button>
                    <Dropdown
                      overlay={this.menu(this.props.group)}
                      trigger={['click']}  onClick={e => e.stopPropagation()}>
                      <Button type='primary'>
                        <img src={IconShare} alt='icon-share'/>
                        {/* <IconShare style={{ fontSize: '24px', lineHeight: '0', verticalAlign: 'text-bottom' }}/> */}
                      </Button>
                    </Dropdown>
                    <Button
                      type='primary'
                      onClick={() => this.toggleAddExamModal(true)} >
                      <img src={IconExamAdd} alt='icon-exam-add'/>
                      {/* <IconExamAdd style={{ fontSize: '24px', lineHeight: '0', verticalAlign: 'text-bottom' }}/> */}
                    </Button>
                    <Button
                      type='primary'
                      onClick={() => this.toggleAddStudentModal(true)} >
                      <img src={AddUserIcon} alt='icon-user-add'/>
                      {/* <Icon type="usergroup-add" />นักเรียน */}
                      {/* <IconExamAdd style={{ fontSize: '24px', lineHeight: '0', verticalAlign: 'text-bottom' }}/> */}
                    </Button>
                  </Fragment>
                ) : (
                  <Button
                    type='primary'
                    onClick={(e) => {
                      e.stopPropagation()
                      this.showSearchModal()
                    }}>
                    <img src={IconSearch} alt='icon-search'/>
                    {/* <IconSearch style={{verticalAlign: 'middle'}}/> */}
                  </Button>
                )
              }
            </div>

            <div className='desktop'>
              {
                auth.role !==  'student'?
                  <div style = {{display : 'flex'}}>
                    <Input
                      suffix={
                        <Button
                          type='primary'
                          style={{paddingLeft: 5, paddingRight: 10}}
                          onClick={(e) => {
                            e.stopPropagation()
                            this.searchStudentJoinGroup(searchKey)
                          }}>
                          <img src={IconSearch} alt='icon-search'/>ค้นหา
                          {/* <IconSearch style={{verticalAlign: 'middle'}}/>ค้นหา */}
                        </Button>}
                      placeholder={this.state.placeholderInGroupDetail}
                      maxLength={100}
                      className={styles.inputSearch}
                      onPressEnter={e => {
                        e.stopPropagation()
                        this.searchStudentJoinGroup(e.target.value)
                      }}
                      onChange={(e)=>this.setSearchKey(e.target.value)}
                    />
                    <Dropdown
                      overlay={this.menu(this.props.group)}
                      trigger={['click']}  onClick={e => e.stopPropagation()}>
                      <Button
                        data-tut-teacher-group='TeacherCreateGroupFifth'
                        type='primary'
                        style={{marginRight: 16}}
                      >
                        <img src={IconShare} alt='icon-share'/>แชร์กลุ่ม
                        {/* <IconShare style={{ fontSize: '24px', lineHeight: '0', verticalAlign: 'text-bottom' }}/>แชร์กลุ่ม */}
                      </Button>
                    </Dropdown>

                    <Button
                      type='primary'
                      data-tut-teacher-group='TeacherCreateGroupFourth'
                      onClick={() => this.toggleAddExamModal(true)} >
                      <img src={IconExamAdd} alt='icon-exam-add'/>เพิ่มข้อสอบที่สร้าง
                      {/* <IconExamAdd style={{ fontSize: '24px', lineHeight: '0', verticalAlign: 'text-bottom' }}/>เพิ่มข้อสอบที่สร้าง */}
                    </Button>

                    <Button
                      type='primary'
                      onClick={() => this.toggleAddStudentModal(true)} >
                      <img src={AddUserIcon} alt='icon-user-add'/>เพิ่มนักเรียนจากไฟล์
                      {/* <Icon type="usergroup-add" />เพิ่มนักเรียนจากไฟล์ */}
                      {/* <IconExamAdd style={{ fontSize: '24px', lineHeight: '0', verticalAlign: 'text-bottom' }}/> */}
                    </Button>


                  </div>
                  : <div>
                    <Input
                      suffix={
                        <Button
                          type='primary'
                          style={{paddingLeft: 5, paddingRight: 10}}
                          onClick={(e) => {
                            e.stopPropagation()
                            this.searchStudentJoinGroup(searchKey)
                          }}>
                          <img src={IconSearch} alt='icon-search'/>ค้นหา
                          {/* <IconSearch style={{verticalAlign: 'middle'}}/>ค้นหา */}
                        </Button>}
                      placeholder={this.state.placeholderInGroupDetail}
                      maxLength={100}
                      className={styles.inputSearch}
                      onPressEnter={e => {
                        e.stopPropagation()
                        this.searchStudentJoinGroup(e.target.value)
                      }}
                      onChange={(e)=>this.setSearchKey(e.target.value)}
                    />
                  </div>
              }
            </div>
          </div>
        </div>
      )      
      groupInfo = (
        <div className='group-info'>
 
          <div className='mobile'> 
            <div >
              {
                this.props.auth.role === 'student' ?
                  <Avatar src={ groupDetail.logo ? groupDetail.logo : DefaultGroup } size = {80} alt='icon-group'/>
                  :
                  <Avatar 
                    src={ groupDetail.logo ? groupDetail.logo : DefaultGroup } 
                    size = {80} 
                    alt='icon-group'
                    onClick = {()=>{
                      this.setState({
                        isShowAddGroupModal : !this.state.isShowAddGroupModal
                      })
                    }
                    }
                  />
              }
            </div> 
            <div>
              <h2 className='text-medium'>{groupDetail ? groupDetail.name : '-' }</h2> 
            </div>  
            <div className='group-info-name'>     
              <div style = {{ paddingRight: '5px' }}>
                <img src = { NumStudentInGroup }/>
              </div>
              <span style = {{ paddingRight: '5px' }}>{ auth.role === 'student' ? studentGroupRequest[0].studentCount :  studentJoin  ? studentJoin : 0 }</span>
              <span style={{ fontWeight: 'normal', fontSize: '16px',color: '#4F4F4F', paddingRight: '5px' }}>วันที่สร้าง : </span>
              <span >{groupDetail ? moment.unix(groupDetail.createdAt).format('DD/MM/YYYY') : ''}</span>
            </div>
          </div>

          <div className='flex-start desktop'>
            {/* <img src={group ? group.logo ? DefaultGroup : DefaultGroup : DefaultGroup} alt='group_logo' className={styles.logoGroupLg}/> */}
            <div style = {{ paddingRight: '10px' }}>
              {
                this.props.auth.role === 'student' ?
                  <Avatar 
                    src={ groupDetail.logo ? groupDetail.logo : DefaultGroup } 
                    size = {80} 
                  />
                  :
                  <Avatar 
                    className = {styles.imgGroup} 
                    src={ groupDetail.logo ? groupDetail.logo : DefaultGroup } 
                    size = {80} 
                    onClick = {()=>{
                      this.setState({
                        isShowAddGroupModal : !this.state.isShowAddGroupModal
                      })
                    }
                    }/>
              }
             
            </div>
            <div>
              <h2 className='text-medium' style={{color: '#05007A', fontSize: 28, marginBottom: 0}}> {groupDetail ? groupDetail.name : ''} </h2>
              <span className='text-regular' style={{marginRight: 35, color: '#4F4F4F'}}><span className='text-medium'>รหัสกลุ่ม:</span> {groupDetail ? groupDetail.code : ''}</span>
              <span style={{marginRight: 35}}><img  src={IconGroup} style={{ paddingRight: '10px', fontSize: 28, verticalAlign: 'text-bottom'}}/>{ auth.role === 'student' ? studentGroupRequest[0].studentCount :  studentJoin  ? studentJoin : 0 }</span>
              <span className='text-regular' style={{color: '#4F4F4F'}}><span className='text-medium'>วันที่สร้าง:</span> {groupDetail ? moment.unix(groupDetail.createdAt).format('DD/MM/YYYY') : ''}</span>
            </div>
          </div>
        </div>
      )
    } 
    return (
      <Spin size='large' tip='Loading...' spinning={ isLoading } >
        {
          !searchGroup ?
            <div>{!isLoading ? (<Empty/>) : null }</div>
            :
            <div>
              { header }
              { groupInfo }
              {
                auth.role === 'student' ?
                  <StudentGroup auth = { this.props.auth } exams={this.state.examsStudent !== null   ? this.state.examsStudent : exams} groupId={group ? group.id : ''} history={this.props.history} group={group}/>
                  :
                  <div>
                    <Tabs defaultActiveKey='1' onChange={this.onCheckTabActiveKey}>
                      <TabPane tab='ข้อสอบ' key='1'>
                        <DetailExam auth={auth} exams={this.state.examsInGroup ? this.state.examsInGroup : exams} group={groupDetail} onRemoveExam={this.handleRemoveExam} onUpdateExam={this.handleUpdateExam} history={ this.props.history } match={this.props.match}/>
                      </TabPane>
                      <TabPane tab='รายชื่อเข้าร่วมแล้ว' key='2'>
                        <DetailStudentInGroup
                          fetchGetListOfStudents={this.fetchGetListOfStudents}
                          data={studentJoinGroup}
                          onRemoveFromGroup = {this.onRemoveStudentFromGroup}
                          removedStudent = {this.removedStudent}
                        />
                      </TabPane>
                      <TabPane tab={
                        <span className={studentRequestGroup.length > 0 ? styles.required : ''}>
                            รายชื่อที่ขอเข้าร่วม
                        </span>
                      } key='3'>
                        <DetailStudentRequest
                          fetchGetListOfStudents={this.fetchGetListOfStudents}
                          data={studentRequestGroup}
                          onApproveStudentGroup={this.onApproveStudentGroup}
                          onRejectStudentGroup={this.onRejectStudentGroup}
                        />
                      </TabPane>
                    </Tabs>
                    <ModalAddExam isVisible={this.state.isOpenAddExamModal} onSubmit={this.handleAddExam} onCancel={() => { this.toggleAddExamModal(false) }} exams={examsInNotGroup} group = {group}/>
                    <ModalAddStudent isVisible={this.state.isOpenAddStudentModal} onCancel={() => { this.toggleAddStudentModal(false) }}  Closewindow={() => { this.toggleAddStudentModal(false) }} fetchGetListOfStudents={this.fetchGetListOfStudents} group = {mygroup}/>
                  </div>
              }
            </div>
        }
        <ModalQRCode
          isVisible={this.state.isShowQrCodeModal}
          onCancel={this.onCloseQRCodeModal}
          onQrCodeGenerate={this.onQrCodeGenerate}
          imgQRCode={this.state.imgQRCode}
        />

        <SearchModal
          isSearchModal={this.state.isSearchModal}
          showSearchModal={this.showSearchModal}
          title='ค้นหา'
          placeholder={this.state.placeholderInGroupDetail}
          action={this.searchStudentJoinGroup}
        >
          {
            tabActiveKey === '1' ? (
              <DetailStudentInGroup
                fetchGetListOfStudents={this.fetchGetListOfStudents}
                data={searchData}
                onRemoveFromGroup={this.onRemoveStudentFromGroup}
                removedStudent = {this.removedStudent}
              />
            ) : tabActiveKey === '2' ? (
              <DetailStudentRequest
                fetchGetListOfStudents={this.fetchGetListOfStudents}
                data={searchData}
                onApproveStudentGroup={this.onApproveStudentGroup}
                onRejectStudentGroup={this.onRejectStudentGroup}
              />
            ) : (
              <DetailExam auth={auth} exams={searchData} group={group} onRemoveExam={this.handleRemoveExam} onUpdateExam={this.handleUpdateExam} history={ this.props.history } match={this.props.match}/>
            )
          }
        </SearchModal>
        <ModalAddGroup
          isVisible={this.state.isShowAddGroupModal}
          onSubmit={this.onSubmitGroup}
          onCancel={this.onCloseModalAddGroup}
          group={this.props.group} 
          isSeenTutorial={auth}
          nextStepTour={null}
        />
      </Spin>
    )
  }
}

const mapStateToProps = (state, props) => {  
  return {
    auth: state.auth.profile,
    group: state.group.groupList && state.group.groupList.find(group => group.id === props.match.params.id),
    exams: state.group.examsInGroup
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getGroupList,
      removeStudentFromGroup,
      approveStudentGroup,
      rejectStudentGroup,
      getGroupData,
      RemovedStudentInGroup,
      getExamsInGroup,
      getExamsNotInGroup,
      addExams,
      editGroup,
      removeExam,
      updateExam,
      getListOfStudents,
      studentRequestToJoinGroup, 
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetail)
