import { FetchCycle, AxiosRequest } from '../fetch'
import { dateFromTimeStamp } from '../../util/helper'
import * as $ from 'jquery'
export const extractExamSetItem = t => ({
  key: t._id,
  _id: t._id,
  code: t.code,
  name: t.name,
  type: t.type === 'G' ? 'ตามหลักสูตร' : 'แข่งขัน',
  owner: t.owner ? `${t.owner.role === 'student' ? t.owner.prefixName : 'อ.'}${t.owner.firstName} ${t.owner.lastName}` : '',
  startButtonText: t.latestTesting ? 'ทำต่อ' : 'เริ่มทำ',
  subject: t.subject,
  status: t.status,
  createdAt: dateFromTimeStamp(t.createdAt),
})

const extractExamSetList = (arr) => {
  try {
    return arr.items.map(extractExamSetItem)
  } catch (e) {
    return []
  }
  
}
export const extractExamDetailItem = detail => ({
  project: detail.competition.year,
  key: detail._id,
  id: detail._id,
  code: detail.code,
  name: detail.name,
  grade: detail.grade,
  type: detail.type === 'G' ? 'ตามหลักสูตร' : 'แข่งขัน',
  subject: detail.subject,
  status: detail.status,
  createdAt: dateFromTimeStamp(extractExamDetailItem.createdAt),
})

// // Reducer =====================================================================

export const SET_EXAMSET_LIST               = 'exam/SET_EXAMSET_LIST'
export const UPDATE_EXAMSET_IN_LIST        = 'exam/UPDATE_EXAMSET_IN_LIST'
export const SET_EXAMSET_DETAIL               = 'exam/SET_EXAMSET_DETAIL'

// // API =====================================================================

const API_EXAMSET                           = '/exams'

// // Fetch =====================================================================


const addExamSet = data => () => AxiosRequest(API_EXAMSET, 'post', data)

// TODO : Mock wait API
// const fetchExamSet = filter => () => extractExamSetList({ items: [
//   { _id: '500000001', code: 'MF00000001', name: 'คณิตศาสตร์ ป.4', subject: 'คณิตศาสตร์', type: 'G', createdAt: '1563679343', status: true },
//   { _id: '500000002', code: 'MF00000001', name: 'วิทยาศาสตร์ ป.3', subject: 'วิทยาศาสตร์', type: 'G', createdAt: '1563779343', status: false },
//   { _id: '500000003', code: 'MF00000001', name: 'คณิตศาสตร์ ป.2', subject: 'คณิตศาสตร์', type: 'C', createdAt: '1563879343', status: true },
// ]})
// const patchExamSet = (code, data) => () => ({code, data})

const fetchExamSet         = filter          => () => AxiosRequest(API_EXAMSET, 'get', {'filters[name]': filter}).then( res => extractExamSetList(res))
const fetchExamSetList     = (filter,page)   => () => AxiosRequest(API_EXAMSET,'get',{'limit':100000, 'search[name]' : filter, 'search[code]' : filter , 'page': page ? page : 1}) 
const fetchExamSuggest     = ()        => () => AxiosRequest(API_EXAMSET+'/suggestions','get')

const fetchExamSetDetail   = id              => () => AxiosRequest(`${API_EXAMSET}/${id}`, 'get')
const fetchExamSetDetailFromGroup = (examId,groupId) => () => AxiosRequest(`${API_EXAMSET}/${examId}`, 'get', { groupId })

const fetchExamSetDetailWithQuetion   = id              => () => AxiosRequest(`${API_EXAMSET}/${id}?includeQuestion=true`, 'get')
const patchExamSet         = (id, data)      => () => AxiosRequest(`${API_EXAMSET}/${id}`, 'patch', data)
const patchExamSetlink         = (id, data)      => () => AxiosRequest(`${API_EXAMSET}/link/${id}`, 'patch', data)
const removeExamSet        = (id,groupId)    => () => AxiosRequest( `${API_EXAMSET}/${id}`, 'delete',{groupId}) 
const shareExamSetToGroup  = (id, groupList) => () => AxiosRequest(`${API_EXAMSET}/${id}/group`,'put',groupList)
const searchExamByCode     = code            => () => AxiosRequest(`${API_EXAMSET}/${code}?searchType=code`, 'get') 
const detailMyExam         = (idMyExam,groupId) => () => AxiosRequest(`${API_EXAMSET}/${idMyExam}`, 'get',{groupId})
const checkQuestionByCode  = (codeExam)      => () => AxiosRequest(`${API_EXAMSET}/check/q/${codeExam}`,'post')
const checkExamSuggestion  = (examId)        => () => AxiosRequest(`${API_EXAMSET}/${examId}/suggestion`,'post')
const DeleteExamSuggestion = (examId)        => () => AxiosRequest(`${API_EXAMSET}/${examId}/suggestion`,'delete')

const fetchExamsAll        = ()              => () => AxiosRequest(`${API_EXAMSET}/all`,'get')
const fetchExamWithRole = paginate => () => AxiosRequest(`${API_EXAMSET}/all?${$.param(paginate)}&limit=10`, 'get')

const fetchExamSetInSearch = paginate => () => AxiosRequest(`${API_EXAMSET}/${$.param(paginate)}`, 'get')
const fetchCountExam = (searchKey) => () => AxiosRequest(`${API_EXAMSET}/count?search=${searchKey}`, 'get')

const sequenceSuggestExam  = (examId,data)   => () => AxiosRequest(`${API_EXAMSET}/${examId}/suggestion/seq`,'patch',data)
const groupNotHaveExam     = (examId)        => () => AxiosRequest(`${API_EXAMSET}/${examId}/!group`,'get')
const suggestExam          = ()              => () => AxiosRequest(API_EXAMSET+'/suggestions','get')

// // Action Callback =====================================================================

const setExamSetList = (examSetList) => {   
  return {
    type: SET_EXAMSET_LIST,
    examSetList,
  }
}

const setDetailMyExam = (examDetail) => {
  return {
    type: SET_EXAMSET_DETAIL,
    examDetail,
  }
}

const updateExamSetInList = (data) => {
  return {
    type: UPDATE_EXAMSET_IN_LIST,
    ...data
  }
}

// // Action =====================================================================

export const createExamSet             = (data)          => FetchCycle(addExamSet(data))
export const getExamSet                = (filter)        => FetchCycle(fetchExamSet(filter), setExamSetList)
export const getExamSetList            = (filter,page)   => FetchCycle(fetchExamSetList(filter,page))
export const getExamSetDetail          = (id)            => FetchCycle(fetchExamSetDetail(id))
export const getExamSetDetailWithQuetion          = (id)            => FetchCycle(fetchExamSetDetailWithQuetion(id))
export const editExamSet               = (id, data)      => FetchCycle(patchExamSet(id, data), updateExamSetInList)
export const editExamSetlink               = (id, data)      => FetchCycle(patchExamSetlink(id, data))
export const deleteExamSet             = (id,groupId)            => FetchCycle(removeExamSet(id,groupId))
export const shareExamToGroup          = (id, groupList) => FetchCycle(shareExamSetToGroup(id, groupList))
export const searchExam                = (code)          => FetchCycle(searchExamByCode(code))
export const getDetailMyExam           = (idMyExam,groupId = null)      => FetchCycle(detailMyExam(idMyExam,groupId) ,setDetailMyExam)
export const onCheckQuestionByCode     = (codeExam)      => FetchCycle(checkQuestionByCode(codeExam))
export const onCheckExamSuggestion     = (examId)        => FetchCycle(checkExamSuggestion(examId))
export const removeExamSuggestion      = (examId)        => FetchCycle(DeleteExamSuggestion(examId))
export const getExamsAll               = ()              => FetchCycle(fetchExamsAll())
export const getFetchExamSuggest       = ()              => FetchCycle(fetchExamSuggest())
export const updateSequenceSuggestExam = (examId,data)   => FetchCycle(sequenceSuggestExam(examId,data))
export const getGroupNotHaveExam       = (examId)        => FetchCycle(groupNotHaveExam(examId))
export const getFetchExamSetDetailFromGroup = (examId,groupId) => FetchCycle(fetchExamSetDetailFromGroup(examId,groupId), setDetailMyExam)
export const getSuggestExam            = ()              => FetchCycle(suggestExam())


export const getExamInTabAll = (role, page, search = '') => {
  return FetchCycle(fetchExamWithRole({ page, filters : { 'owner.role': role},'search[code]' : search, 'search[name]' : search }))
}


export const getTeacherExamInTabAll = (role,value) => {
  return FetchCycle(fetchExamWithRole({page : 1, filters : { 'owner.role': role}, 'search[code]' : value, 'search[name]' : value, 'search[owner.firstName]' : value, 'search[owner.lastName]' : value }))
}
export const getStudentExamInTabAll = (role,value) => {
  return FetchCycle(fetchExamWithRole({page : 1, filters : { 'owner.role': role}, 'search[code]' : value, 'search[name]' : value, 'search[owner.firstName]' : value, 'search[owner.lastName]' : value}))
}
export const getSuperTeacherExamInTabAll = (role,value) => {
  return FetchCycle(fetchExamWithRole({page : 1, filters : { 'owner.role': role}, 'search[code]' : value, 'search[name]' : value, 'search[owner.firstName]' : value, 'search[owner.lastName]' : value}))
}
export const getAdminExamInTabAll = (role,value) => {
  return FetchCycle(fetchExamWithRole({page : 1, filters : { 'owner.role': role}, 'search[code]' : value, 'search[name]' : value, 'search[owner.firstName]' : value, 'search[owner.lastName]' : value}))
}

export const getStudentExamInExamSet = (role,value) => { 
  return FetchCycle(fetchExamSetInSearch({page : 1, filters : { 'owner.role': role}, 'search[code]' : value, 'search[name]' : value, 'search[owner.firstName]' : value, 'search[owner.lastName]' : value}))
}

// export const getExamInTabMyExam = (role,value) => {
//   return FetchCycle(fetchExamWithRole({page : 1, filters : { 'owner.role': role}, search : value}))
// }

export const changeCreateStep = (step) => ({
  type: 'CHANGE_STEP_CREATE_EXAM',
  step,
})

export const getCountExam = (searchKey) => FetchCycle(fetchCountExam(searchKey))
// export default {changeCreateStep}