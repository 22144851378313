import { connect } from 'react-redux'
import { getStats, getStatsDetail, setSelectYear, setSelectMonth, stateUserTransaction } from '../../../redux/statistic/action'

import UserAccount from './UserAccount'

const mapStateToProps = (state) => ({
  collection: state.statistic.collection || [],
  detail: state.statistic.detail || [],
  year: state.statistic.year || {},
  month: state.statistic.month || {}
})

const mapDispatchToProps = {
  loadStats: (type,year) => getStats(type,year),
  loadStatsDetail: (type, year, month) =>
    getStatsDetail(type, year, month),
  setYear: (year) => setSelectYear(year),
  setMonth: (month) => setSelectMonth(month),
  getStateUserTransaction: (year, month) => stateUserTransaction(year,month)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccount)
