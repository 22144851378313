import { FetchCycle, AxiosRequest } from '../fetch'

// API =====================================================================

const API_RELATION = '/master/relations'

// Fetch ===================================================================

const getRelationList  = ()              => () => AxiosRequest(API_RELATION,'get')
const createRelation   = (data)          => () => AxiosRequest(API_RELATION,'post',data)
const updateRelation   = (relationId,data) => () => AxiosRequest(`${API_RELATION}/${relationId}`,'patch',data)
const removeRelation   = (relationId)      => () => AxiosRequest(`${API_RELATION}/${relationId}`,'delete')
const updateMoveRelation  = (relationId,data) => () => AxiosRequest(`${API_RELATION}/${relationId}/seq`,'patch',data)

// Action =====================================================================

export const getAllRelationList = ()              => FetchCycle(getRelationList())
export const onUpdateRelation   = (relationId,data) => FetchCycle(updateRelation(relationId,data))
export const onCreateRelation   = (data)          => FetchCycle(createRelation(data))
export const onRemoveRelation   = (relationId)      => FetchCycle(removeRelation(relationId))
export const onUpdateMoveRelation  = (relationId,data) => FetchCycle(updateMoveRelation(relationId,data))