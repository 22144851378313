import { SHOW_LOADING } from './action'

const initialState = {
  isLoading: false,
}

export default (state = initialState, action) => {

  // Note : for merge preloadedState from server with our initialState
  if (!state.hydrated) {
    state = { ...initialState, ...state, hydrated: true }
  }

  switch (action.type) {

    case SHOW_LOADING:
      return {
        ...state,
        isLoading: action.loading ? true : false
      }

    default:
      return state
  }
}