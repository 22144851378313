import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Row,
	Col
} from 'antd'
import { 
  Link
} from 'react-router-dom'
import { generateSelectForm, generateInputForm, generateAutoCompleteForm } from '../../util/formGenerator'
import { removeUndefinedProp } from '../../util/helper'
import styles from './Register.module.scss'

const formItemLayout = {
  colon: false,
  labelAlign: 'left',
  hideRequiredMark: true,
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
}

class FormParent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isFormDirty: false
		}
	}

  componentDidMount() {
    document.getElementById('parentHeader').scrollIntoView({ behavior: 'smooth' });
  }  

  getPrefixesSpecificRole = () => {
    const { prefixes } = this.props  
    const role = "teacher"
    return prefixes.filter(prefixes => prefixes.visible[role])
  }

  getRelations = () => {
    const { relations } = this.props
    return relations.filter(relation => relation.visible)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {  
      this.setState({ isFormDirty: true })
      if (!err) {
        const params = { ...values }
        removeUndefinedProp(params)
        if (params) {
          this.props.onSubmit(params)
        }
      }
    })
  }


	render() {
		const { form, email } = this.props
		const { isFormDirty } = this.state
    const studentCol = [24, 24, 24]
    const customPrefixColWidth = studentCol 

    const selectPrefix = generateSelectForm({ options: this.getPrefixesSpecificRole(), fieldName: 'parentPrefix', form, label: 'คำนำหน้า', isFormDirty, isRequire: true, initialValue: null, nameAsValue: true, isRegister:true, placeholder:'เลือกคำนำหน้า' })
    const inputFirstName = generateInputForm({ fieldName: 'parentFirstName', form, label: 'ชื่อ', isFormDirty, isRequire: true, isRegister:true })
    const inputLastName = generateInputForm({ fieldName: 'parentLastName', form, label: 'นามสกุล', isFormDirty, isRequire: true, isRegister:true })

    const selectRelation = generateSelectForm({ options: this.getRelations(),
      fieldName: 'relation', form, label: 'ความสัมพันธ์', isFormDirty,
      isRequire: true, initialValue: null, nameAsValue: true, isRegister: true,
      placeholder: 'เลือกความสัมพันธ์' })

    const inputEmail = generateInputForm({ fieldName: 'parentEmail', form, label: 
      'อีเมล', isRegister: true, isFormDirty, limit: 254, 
      customRules: [
        { required: true, message: 'กรุณาใส่อีเมล' },
        { type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' },
        // { validator: async (_, value) => {
        //   if (value != email) {
        //     return Promise.resolve()
        //   }
        //   else {
        //     return Promise.reject(new Error('อีเมลของผู้ปกครองต้องไม่ซ้ำกับอีเมลของนักเรียน')) 
        //   }
        // }}
      ]
    })

    const inputTel = generateInputForm({
      fieldName: 'parentTelephone', form, label: 'โทรศัพท์บ้าน/มือถือ', 
      isRegister: true, isFormDirty, limit: 10, isRequire: false, 
      customRules: [
        // { required: false, message: 'กรุณาใส่หมายเลขโทรศัพท์' },
        { regexp: /0\d+/, min: 9, max: 10, message: 'กรุณาระบุเฉพาะหมายเลขโทรศัพท์ โดยไม่ต้องใส่ขีด'}
      ],
      props: {
        type: 'number'
      }
    })

    return (
      <Fragment>
        <div id='parentHeader' className='flex-space-between login-register' style={{ marginBottom: 15 }}>
          <h2 className={`${styles.formHead} text-medium mb-0`}>ข้อมูลผู้ปกครอง</h2>
        </div>
        <div style={{ marginBottom: 15 }}>
            เนื่องจากท่านมีอายุไม่เกิน 10 ปี จึงจำเป็นต้องได้รับความยินยอมจากผู้ปกครอง กรุณากรอกข้อมูลเพิ่มเติมด้านล่าง
        </div>
				<Form {...formItemLayout} onSubmit={this.handleSubmit} >
          <Row gutter={10}>
            <Col lg={customPrefixColWidth[0]} md={24}>
              { selectPrefix }
            </Col>
            <Col lg={customPrefixColWidth[1]} md={24}>
              { inputFirstName }
            </Col>
            <Col lg={customPrefixColWidth[2]} md={24}>
              { inputLastName }
            </Col>
          </Row>
          <Row gutter={10}>
            { selectRelation }
          </Row>
          <Row gutter={10}>
            { inputEmail }
          </Row>
          <Row gutter={10}>
            { inputTel }
          </Row>
          <span style = {{color: 'red' , fontSize: '16px'}}>* จำเป็นต้องกรอก</span>
          <Button type='primary' htmlType='submit' style={{ width: '100%', marginTop: 10 }}>
            ถัดไป
          </Button>
				</Form>
			</Fragment>
		)
	}
}

FormParent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

const WrappedFormParent = Form.create({ name: 'form_parent' })(FormParent)
export default WrappedFormParent
