/* eslint-disable no-undef */
import React, { Component, Suspense }  from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import styles from './Testing.module.scss'
import {
  Layout,
  Spin,
  Modal,
  Icon, 
  Button,
  BackTop
} from 'antd'
import {
  GetProfileDetail,
} from '../../redux/auth/action'
import {
  startTesting,
  sendAnswer,
  snapTime,
  doneTesting,
  markQuestion
} from '../../redux/testing/action'
import {
  getDetailMyExam
} from '../../redux/exam/action'
import { 
  getGroupDataCode,
  studentExtlinkJoinGroup,
} from '../../redux/group/action'
import {
  SetSeenTour
} from '../../redux/auth/action'
// import Loadable from 'react-loadable'
import Tour from 'reactour'
import * as moment from 'moment'
import HeaderTesting from './Header'
import FooterTesting from './Footer'
import SidebarTesting from './SideBar'

import CompetitionScienceIcon from '../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../IconSVG/CompetitionMathIcon.svg'
import MathCATIcon from '../IconSVG/MathCATIcon.svg'
import ScienceCATIcon from '../IconSVG/ScienceCATIcon.svg'
import IconScienceExam from '../IconSVG/ExamScience.svg'
import IconMathExam from '../IconSVG/ExamMath.svg'
import CustomIconMath from '../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../IconSVG/CustomIconScience.svg'
import ExerciseIconMath from '../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../IconSVG/ExerciseIconScience.svg'

import BreakTestingIcon from '../IconSVG/BreakTesting.svg'
import FinishTestingWarningIcon from '../IconSVG/FinishTestingWarning.svg'
import MatchChoiceTesting from './MatchChoiceTesting'
import ShortAnswerTesting from './ShortAnswerTesting'
import TrueFalseTesting from './TrueFalseTesting'
import MatchAnswerTesting from './MatchAnswerTesting'
import $ from 'jquery'
import { stepTesting } from '../OnBoarding/index'
import { getCookie } from '../../util/helper'

import GraphTesting from './GraphTesting'

// const Tour = Loadable({
//   loader: () => import('reactour'),
//   loading: () => null,
// })

class Test extends Component {
  timeCountInterval = null
  constructor(props) {
    super(props)
    this.state = {
      // New state
      exam: {},
      startedTestingAt: 0,
      testingId: null,
      isLoading: false,
      seqQuestion: 1,
      progressTestings: [],
      questions: [],
      visibleDrawer: false,
      isBreakTest: false,
      answerChangeAtIndex: null,
      testing : true,
      time: 0,
      token: getCookie('token')
    }
    
    // if (props.serverStatus && props.serverStatus.isOffline) {
    //   props.history.push('/home') 
    // }
  }
  componentDidMount = async () => { 
    await this.fetchTesting()
    // eslint-disable-next-line no-undef
    document.addEventListener('keydown', this.handleOnKeyDown, false)
    this.timeCountInterval = setInterval(async() => {
      // console.log("TIME>" + this.state.time)
      await this.setState({ time: this.state.time+ 1000})
      const {exam, questions} = this.state

      if (exam.endTime !== undefined && exam.endTime !== null && questions.length > 0 && ((this.props.profile != null && this.props.profile.role === 'student') || this.props.profile == null))
      {
        // console.log("exam.endTime" + moment(exam.endTime).format('YYYY-MM-DD HH:mm:ss'))
        // console.log("moment()" + moment().format('YYYY-MM-DD HH:mm:ss'))
        // console.log("diff" + moment().diff(exam.endTime))
        if (moment().diff(exam.endTime) > 0)
        {
          clearInterval(this.timeCountInterval)
          //this.setState({ exam, isLoading: false })
          this.handleUserFinishTesting(true);
          // this.cannotstartTesting(exam,"เลยเวลาที่กำหนด",moment(exam.endTime).format('YYYY-MM-DD HH:mm:ss'));
          
          // return
        }
      } 


    }, 1000) 
  }
  
  componentWillUnmount = async () => {
    // eslint-disable-next-line no-undef
    document.removeEventListener('keydown', this.handleOnKeyDown, false)
    clearInterval(this.timeCountInterval)
  }

  handleOnKeyDown = (e) => {
    const { seqQuestion, questions, exam } = this.state
    if (e.key === 'ArrowRight') {
      e.preventDefault()
      if (seqQuestion < questions.length) {
        this.onChangeQuestion(seqQuestion+1)
      }
    } else if (e.key === 'ArrowLeft' && exam.type !== 'CAT') {
      e.preventDefault()
      if (seqQuestion > 1) {
        this.onChangeQuestion(seqQuestion-1)
      }
    }
  };



  fetchTesting = async () => {
    let { questions, progressTestings, startedTestingAt, testingId, seqQuestion, time } = this.state
    const { id, groupId, extgroup } = this.props.match.params

    this.setState({ isLoading: true })

    const exam = await this.props.getDetailMyExam(id,groupId)  


// console.log('--------exam------')
// console.log(groupId)
// console.log(exam)
// console.log(extgroup)
// console.log('--------exam------')

    // console.log(extgroup)
    let extgroupres = null
    if (extgroup !== undefined && extgroup !== null)
    {
      extgroupres = await this.props.getGroupDataCode(extgroup) 
      // console.log('----extgroupres----')
      // console.log(extgroup)
      // console.log(extgroupres)
      if ((extgroupres !== null && extgroupres.error === undefined && extgroupres.code !== 404))
        this.setState({ extgroupres })
      else
      {
        this.props.history.push('/')
      }
    }




      // console.log(exam.endTime)
      // console.log(exam.startTime)
      if (exam.startTime !== undefined && exam.startTime !== null && ((this.props.profile != null && this.props.profile.role === 'student') || this.props.profile == null))
      {
        // console.log("exam.startTime" + moment(exam.startTime).format('YYYY-MM-DD HH:mm:ss'))
        // console.log("moment()" + moment().format('YYYY-MM-DD HH:mm:ss'))
        // console.log("diff" + moment().diff(exam.startTime))
        if (moment().diff(exam.startTime) < 0)
        {

          this.closeTour('testing')
          let datestring = moment(exam.startTime).add(543,'years').format('DD MMMM YYYY','th')  // Fix bug 27_JAN_2022

          this.setState({ exam, isLoading: false })
          this.cannotstartTesting(exam,"ยังไม่ถึงเวลาที่กำหนด",datestring + ' ' + moment(exam.startTime).format('HH:mm:ss'));
          return
        }
      } 

      if (exam.endTime !== undefined && exam.endTime !== null && ((this.props.profile != null && this.props.profile.role === 'student') || this.props.profile == null))
      {
        // console.log("exam.endTime" + moment(exam.endTime).format('YYYY-MM-DD HH:mm:ss'))
        // console.log("moment()" + moment().format('YYYY-MM-DD HH:mm:ss'))
        // console.log("diff" + moment().diff(exam.endTime))
        if (moment().diff(exam.endTime) > 0)
        {
          this.closeTour('testing')
          let datestring = moment(exam.endTime).add(543,'years').format('DD MMMM YYYY','th') 

          this.setState({ exam, isLoading: false })
          this.cannotstartTesting(exam,"เลยเวลาที่กำหนด",datestring + ' ' + moment(exam.endTime).format('HH:mm:ss'));
          return
        }
      } 

      // console.log('--------TESTtime------')
      // console.log(moment())
      // console.log(moment().add(543,'years').format('DD MMMM YYYY HH:mm:ss','th') )
      // console.log('--------TESTtime START------')
      // console.log(moment(exam.startTime))
      // console.log(moment(exam.startTime).add(543,'years').format('DD MMMM YYYY HH:mm:ss','th') )
      // console.log(moment().diff(exam.startTime))
      // console.log('--------TESTtime END------')
      // console.log(moment(exam.endTime))
      // console.log(moment(exam.endTime).add(543,'years').format('DD MMMM YYYY HH:mm:ss','th') )
      // console.log(moment().diff(exam.endTime))

      

      // this.handleUserFinishTesting(true);

    // console.log('--------extgroupres------')
    // console.log(extgroupres)
    // console.log('--------extgroupres------')


    if (exam.code !== 404) {
      let testing = null


      if ((extgroupres !== null && extgroupres.error === undefined && extgroupres.code !== 404))
      {
      

        testing = await this.props.startTesting({ examId: id, groupId: extgroupres.id })
  
      }
      else
      {
        // console.log('------groupId------')
        // console.log(groupId)
        if (groupId !== undefined && groupId !== null)
        {
              const currentuser = await this.props.GetProfileDetail()
              if (currentuser)
              {
                if (currentuser.role === 'student')
                {
                      // console.log('------currentuser------')
                      // console.log(currentuser)
                      

                      const resaddgroup = await this.props.studentExtlinkJoinGroup(groupId,{userid:currentuser._id})
                      // console.log(resaddgroup)


                }

              }
          
        }
        testing = await this.props.startTesting({ examId: id, groupId: groupId })
        // console.log(testing)
      }

      if (testing.code === 403) {
        // console.log(testing)
        this.props.history.push('/')
        this.setState({
          isLoading: false,
          visibleDrawer: false,
        })
        return
      }

      // console.log(testing)
      for (let x in testing.questions)
      {
        testing.questions[x].orgseq = testing.questions[x].seq
      }

      // console.log("===AAA index===============")
      // console.log(testing)

      // console.log("===AAAA index===============")
      // console.log(this.props.profile)
      // console.log("===AAAA index===============")
      if ((this.props.profile != null && this.props.profile.role === 'student') || this.props.profile == null)
      {
        // console.log("STUDENT")

        // console.log(testing.mcRandomOrder)

        let shuffledlist = testing.mcRandomOrder
        let reindex_questions = []
        if (shuffledlist !== undefined && shuffledlist !== null && shuffledlist.length > 0)
        {
          for (let x in shuffledlist)
          {
            let tmpq = Object.assign({}, testing.questions[shuffledlist[x]]);
            tmpq.seq = +x + +1
            reindex_questions.push(tmpq)
          }
        }
        else
        {
          reindex_questions = testing.questions
        }

        testing.questions = reindex_questions
        // console.log(reindex_questions)
      }

      

      testingId = testing._id
      questions = testing.questions
      progressTestings = testing.progressTestings
      startedTestingAt = testing.startedAt
      time = testing.time
      
    } 
    if (progressTestings.length > 0) {
      seqQuestion = progressTestings[progressTestings.length - 1].order + (exam.type === 'CAT' ? 1 : 0)
      
    }

    this.setState({
      isLoading: false,
      exam,
      testingId,
      questions,
      progressTestings,
      startedTestingAt,
      seqQuestion,
      time
    })
  }

  showDrawer = () => {
    this.setState({
      visibleDrawer: true,
    })
  }
  onCloseDrawer = () => {
    this.setState({
      visibleDrawer: false,
    })
  }

  handleUserAnswer = async (answer) => {
    // console.log('=====handleUserAnswer=====')
    // console.log(answer)
    // console.log('=====handleUserAnswer=====')
    let { progressTestings, answerChangeAtIndex } = this.state 
    const indexProgressTesting = progressTestings.findIndex(pt => pt.order === answer.order)

    if (indexProgressTesting !== -1) {
      let oldAnswer = progressTestings[indexProgressTesting].answer
      const newAnswer = answer.answer

      if (!oldAnswer) {
        answerChangeAtIndex = indexProgressTesting
      } else {
        switch (answer.type) {
          case 'MC':
            if (oldAnswer !== newAnswer) {
              answerChangeAtIndex = indexProgressTesting
            }
            break
          case 'TF':
            for (let i in  newAnswer) { 
              oldAnswer = oldAnswer.map((q) => {
                if(q) return q
                return { key: null }
              }) 
              if (oldAnswer[i].key !== newAnswer[i].key) {
                answerChangeAtIndex = indexProgressTesting
                break
              }
            }
            break
          case 'SA':
            if (oldAnswer !== newAnswer) {
              answerChangeAtIndex = indexProgressTesting
            }
            break
          case 'MA':
            if (oldAnswer.length !== newAnswer.length) {
              answerChangeAtIndex = indexProgressTesting
            } else {
              for (let index in newAnswer.length) {
                if (oldAnswer[index].seq !== newAnswer[index].seq || oldAnswer[index].match !== newAnswer[index].match) {
                  answerChangeAtIndex = indexProgressTesting
                }
              }
            }
            break
          case 'GR':
            if (oldAnswer !== newAnswer) {
              answerChangeAtIndex = indexProgressTesting
            }
          default:
        }
      }

      answer.isMark = progressTestings[indexProgressTesting].isMark ? progressTestings[indexProgressTesting].isMark : false

      progressTestings[indexProgressTesting] = answer
    } else {
      progressTestings.push(answer)
      answerChangeAtIndex = progressTestings.length - 1
    }

    this.setState({
      progressTestings,
      answerChangeAtIndex
    })
  }

  onChangeQuestion = async (seq) => {
    $('.options').animate({ scrollTop: 0 }, 'fast')
    $('.match-answer-testing').animate({ scrollTop: 0 }, 'fast')
    $('.match-choice-testing').animate({ scrollTop: 0 }, 'fast')
    $('.true-false').animate({ scrollTop: 0 }, 'fast')
    $('.short-answer-testing').animate({ scrollTop: 0 }, 'fast')
    var myDiv = $('#containerDiv')
    myDiv.scrollTop = 0 
    let { progressTestings, answerChangeAtIndex, testingId, questions, exam, time } = this.state
    if (answerChangeAtIndex !== null) {
      if (exam.type === 'CAT') { 
        this.setState({ isLoading: true })
        const response = await this.props.sendAnswer(testingId, { ...progressTestings[answerChangeAtIndex], time })
        if (response.error && response.error === '007,สิ้นสุดการทดสอบในชุด CAT') {
          this.handleUserFinishTesting()
        } else {
          questions.push(response.newQuestion)
        }
        this.setState({ questions, isLoading: false })
      } else {
        await this.props.sendAnswer(testingId, { ...progressTestings[answerChangeAtIndex], time })
      }
    }
    this.setState({
      answerChangeAtIndex: null,
      seqQuestion: seq,
      visibleDrawer: false
    })
  }

  handleUserFinishTesting = async (passTrought = false) => {  
    const { testingId, exam, answerChangeAtIndex, progressTestings, questions } = this.state  
    let { time } = this.state
    let cameFrom = this.props.history.location && this.props.history.location.state && this.props.history.location.state.cameFrom === 'homeSuggest' ?  'homeSuggest' : this.props.history.location.state.cameFrom === 'group' ? 'group' : 'exam'
    const { groupId } = this.props.match.params 
    let isAllAnswer = true    
    progressTestings.map(p => {
      if(!p.answer) isAllAnswer = false
    })      
    if(exam.type === 'CAT') {
      await this.props.doneTesting(testingId)
      return this.props.history.push(`/result/${testingId}/${exam._id}`, { cameFrom: cameFrom, profileImage: this.props.profile.profileImage }) 
    }
    if (time/1000 > exam.duration) {
      time = exam.duration * 1000
    }
    if (answerChangeAtIndex !== null) { 
      await this.props.sendAnswer(testingId, { ...progressTestings[answerChangeAtIndex], time })
    } else {
      await this.props.snapTime(testingId, { time })
    }
    if(passTrought) {  
      await this.props.doneTesting(testingId)
      if(groupId) return this.props.history.push(`/result/${testingId}/${exam._id}`, { cameFrom: cameFrom, groupId: groupId,profileImage: this.props.profile.profileImage }) 
      return this.props.history.push(`/result/${testingId}/${exam._id}`, { cameFrom: cameFrom,profileImage: this.props.profile.profileImage })
    } 
    if (progressTestings.length === questions.length && passTrought == false && isAllAnswer === true && progressTestings.length) { 
      const modal = Modal.confirm({
        className: styles.modalFinishExam,
        centered: true,
        footer: null,
        cancelText: 'ทำข้อสอบต่อ',
        width: 580,
        icon: null,
        title: (
          <button aria-label='Close' className='ant-modal-close' style={{display: 'block'}} onClick={()=> modal.destroy()}>
            <Icon type='close' className='ant-modal-close-x'/>
          </button>
        ),
        content: (
          <div style={{textAlign: 'center'}}>
            <img src={FinishTestingWarningIcon} alt='logo' style={{borderRadius: '50%', width: 220, height: 220 }}/>
            <h2 className='text-medium' style={{fontSize: 20,paddingBottom: '12px'}}>คุณต้องการส่งข้อสอบหรือไม่</h2>
            {/* <p>คุณต้องการส่งข้อสอบหรือไม่</p> */}
            <div className={styles.modalPauseExam} >
              <div className={styles.sub_modalPauseExam} ><Button onClick = {e => {
                e.stopPropagation()
                Modal.destroyAll()
              }}>ทำข้อสอบต่อ</Button></div>
              <div className={styles.sub_modalPauseExam_2} ><Button type = 'primary' onClick = { async() => {
                await this.setState({ isLoading: true })
                Modal.destroyAll()
                await this.props.doneTesting(testingId)
                await this.setState({ isLoading: false })
                if(groupId) return this.props.history.push(`/result/${testingId}/${exam._id}`, { cameFrom: cameFrom, groupId: groupId,profileImage: this.props.profile.profileImage }) 
                this.props.history.push(`/result/${testingId}/${exam._id}`, { cameFrom: cameFrom,profileImage: this.props.profile && this.props.profile.profileImage })
              }}>ส่งข้อสอบ</Button></div>
            </div>
          </div>
        ),
        // onOk: async () => {
        //   await this.props.doneTesting(testingId)
        //   this.props.history.push(`/result/${testingId}/${exam._id}`)
        // }, 
      })
    } else {   
      const modal = Modal.confirm({
        className: styles.modalFinishExam,
        centered: true,
        footer: null,
        cancelText: 'ทำข้อสอบต่อ',
        width: 580,
        icon: null,
        title: (
          <button aria-label='Close' className='ant-modal-close' style={{display: 'block'}} onClick={()=> modal.destroy()}>
            <Icon type='close' className='ant-modal-close-x'/>
          </button>
        ),
        content: (
          <div style={{textAlign: 'center'}}>
            <img src={FinishTestingWarningIcon} alt='logo' style={{borderRadius: '50%', width: 220, height: 220 }}/>
            <h2 className='text-medium' style={{fontSize: 20}}>ทำข้อสอบไม่ครบ</h2>
            <p>คุณยังทำข้อสอบไม่ครบ<br/>ต้องการส่งข้อสอบหรือไม่</p>
            <div className={styles.modalPauseExam} >
              <div className={styles.sub_modalPauseExam} ><Button onClick = {e => {
                e.stopPropagation()
                Modal.destroyAll()
              }}>ทำข้อสอบต่อ</Button></div>
              <div className={styles.sub_modalPauseExam_2} ><Button type = 'primary' onClick = { async() => {
                Modal.destroyAll()
                await this.props.doneTesting(testingId)
                if(groupId) return this.props.history.push(`/result/${testingId}/${exam._id}`, { cameFrom: cameFrom, groupId: groupId,profileImage: this.props.profile.profileImage }) 
                this.props.history.push(`/result/${testingId}/${exam._id}`, { cameFrom: cameFrom,profileImage: this.props.profile && this.props.profile.profileImage })
              }}>ส่งข้อสอบ</Button></div>
            </div>
          </div>
        ),
        // onOk: async () => {
        //   await this.props.doneTesting(testingId)
        //   this.props.history.push(`/result/${testingId}/${exam._id}`)
        // }, 
      })
    }
  }

  handleBookmark = async (seq, oldMarked) => {
    const { testingId, questions, progressTestings } = this.state

    let questionMarkerIndex = progressTestings.findIndex(pt => pt.order === seq)

    let questionMarker = progressTestings[questionMarkerIndex]

    if (questionMarkerIndex === -1) {
      const question = questions.find(q => q.seq === seq)
      questionMarker = {
        questionId: question._id,
        order: question.seq,
        type: question.type,
        isMark: true
      }
      progressTestings.push(questionMarker)
    } else {
      questionMarker.isMark = oldMarked === false ? true : false
      progressTestings[questionMarkerIndex] = questionMarker
    }
    this.setState({ progressTestings })

    await this.props.markQuestion(testingId, {
      ...questionMarker,
      isMark: oldMarked === false ? true : false
    })
  }

  handleNote = async (seq, note) => {
    let { questions, progressTestings, answerChangeAtIndex } = this.state

    let indexProgressTesting = progressTestings.findIndex(pt => pt.order === seq)

    let progressTesting = progressTestings[indexProgressTesting]

    if (indexProgressTesting === -1) {
      const question = questions.find(q => q.seq === seq)
      progressTesting = {
        questionId: question._id,
        order: question.seq,
        type: question.type,
        isMark: true,
        note
      }
      progressTestings.push(progressTesting)
      answerChangeAtIndex = progressTestings.length - 1
    } else {
      progressTesting.note = note
      progressTestings[indexProgressTesting] = progressTesting
      answerChangeAtIndex = indexProgressTesting
    }

    this.setState({ progressTestings, answerChangeAtIndex })
  }

  handleBreakTesting = async () => {
    const { history, location } = this.props
    const { testingId,  answerChangeAtIndex, progressTestings } = this.state 
    this.setState({
      isBreakTest: true
    })  
    
    const modal = Modal.confirm({
      centered: true, 
      width: 580,
      icon: null,
      className: styles.modal_pause_testing,
      title: (
        <button aria-label='Close' className='ant-modal-close' style={{display: 'block'}} onClick={()=> { this.setState({ isBreakTest: false }, () => modal.destroy())  }}>
          <Icon type='close' className='ant-modal-close-x'/>
        </button>
      ),
      content: (
        <div style={{textAlign: 'center'}}>
          <img src={BreakTestingIcon} alt='logo' />
          <h2 className='text-medium' style={{fontSize: 20, paddingBottom: '12px'}}>คุณต้องการหยุดพักการสอบหรือไม่</h2>
          {/* <p>คุณต้องการหยุดพักการสอบหรือไม่</p> */}
          <div className={styles.modalPauseExam} >
            <div className={styles.sub_modalPauseExam} ><Button onClick = {e => {
              e.stopPropagation()
              Modal.destroyAll()
            }}>ยกเลิก</Button></div>
            <div className={styles.sub_modalPauseExam_2} ><Button type = 'primary' onClick = {async() => {  
              
              if (answerChangeAtIndex !== null) { 
                await this.props.sendAnswer(testingId, { ...progressTestings[answerChangeAtIndex || progressTestings.length - 1], time: this.state.time })  ///Fixbug
              } else {
                await this.props.snapTime(testingId, { time: this.state.time })
              }
              history.push( history.location.state ?  history.location.state.cameFrom === 'exam' ? '/' : history.location.state.cameFrom === 'group' ? `/group/${location.state.groupId}` : '/suggest' : '/' ,{cameFrom: 'exam'} )
              Modal.destroyAll()
            }}>ต้องการ</Button></div>
          </div>
        </div>
      ), 
    })
  }

  cannotstartTesting = async (c,a,b) => {
    const { history, location } = this.props
    const { testingId,  answerChangeAtIndex, progressTestings } = this.state 
    this.setState({
      isBreakTest: true
    })  
    
    const modal = Modal.confirm({
      centered: true, 
      width: 580,
      icon: null,
      className: styles.modal_pause_testing,
      // title: (
      //   <button aria-label='Close' className='ant-modal-close' style={{display: 'block'}} onClick={()=> { this.setState({ isBreakTest: false }, () => modal.destroy())  }}>
      //     <Icon type='close' className='ant-modal-close-x'/>
      //   </button>
      // ),
      content: (
        <div style={{textAlign: 'center'}}>
          <img src={BreakTestingIcon} alt='logo' />
          <h2 className='text-medium' style={{fontSize: 20, paddingBottom: '12px'}}>{c.name}</h2>
          <h2 className='text-medium' style={{fontSize: 17, fontWeight: 'normal', paddingBottom: '12px'}}>ไม่สามารถเริ่มได้ อยู่นอกช่วงเวลาที่กำหนด</h2>
          <h2 className='text-medium' style={{fontSize: 17, fontWeight: 'normal', paddingBottom: '20px'}}>{a + ' (' + b + ')'}</h2>
          {/* <p>คุณต้องการหยุดพักการสอบหรือไม่</p> */}
          {/* <div style={{textAlign: 'center'}} className={styles.modalPauseExam} > */}
            {/* <div className={styles.sub_modalPauseExam} ><Button onClick = {e => {
              e.stopPropagation()
              Modal.destroyAll()
            }}>ยกเลิก</Button></div> */}
            <div style={{marginBottom: '20px'}} className={styles.sub_modalPauseExam_2} ><Button type = 'primary' onClick = {async() => {  
              
              // if (answerChangeAtIndex !== null) { 
              //   await this.props.sendAnswer(testingId, { ...progressTestings[answerChangeAtIndex || progressTestings.length - 1], time: this.state.time })  ///Fixbug
              // } else {
              //   await this.props.snapTime(testingId, { time: this.state.time })
              // }

              history.push( history.location.state ?  history.location.state.cameFrom === 'exam' ? '/' : history.location.state.cameFrom === 'group' ? `/group/${location.state.groupId}` : '/suggest' : '/' ,{cameFrom: 'exam'} )
              Modal.destroyAll()
            }}>กลับไปก่อนหน้า</Button></div>
          </div>
        // </div>
      ), 
    })
  }

  closeTour = (stepName)=>{
    const data = {}
    if(stepName ==='testing'){
      data.isSeenTutorial = {'testing': true}
      this.setState({
        testing : false
      },async()=>{
        await this.props.SetSeenTour(data)
      })
    }
  }
  showOnBoarding = () => {
    const isSeenTutorial = this.props.profile.isSeenTutorial
    const testing = isSeenTutorial.testing
    if(testing === false && window.screen.width > 991 && this.props.profile.role === 'student'){
      return(
        <Suspense fallback={<React.Fragment />}>
          <Tour
            steps = {stepTesting}
            isOpen = {this.state.testing}
            onRequestClose={()=>{this.closeTour('testing')}} 
            showNavigation={false}
            showButtons = {false}
            showNumber = {false}
            closeWithMask = {false}
            disableKeyboardNavigation={true}
            rounded = {10}
            maskSpace = {10}
            disableInteraction = {true}
            className = {styles.tourTest}
          />
        </Suspense>)
    } 
  }
  scrollToHead() { 
    window.scrollTo(0,0)
  }
  // onCloseTab = () => {
  //   const { testing,location } = this.props
  //   let { progressTestings, answerChangeAtIndex, time, testingId } = this.state
  //   let isTesting = location.pathname.slice(0, 8)   
  //   if(isTesting === '/testing') {  
  //     window.addEventListener('beforeunload', (e) => 
  //     {  
  //       e.preventDefault()   
  //       testing.questions.map((t,i) => {
  //         if(!progressTestings[i]) {
  //           progressTestings[i] = { 
  //             questionId: t._id,
  //             type: t.type,
  //             order: t.seq,
  //             answer: null,
  //             isMark: false 
  //           }
  //         }
  //       }) 
  //       this.props.sendAnswer(testingId,{...progressTestings[answerChangeAtIndex ? answerChangeAtIndex : progressTestings.length - 1],time})
  //       return e.returnValue = '555' 
  //     })
  //   }
  // }
  render () {
    const {testingId,  questions, seqQuestion, exam, visibleDrawer, startedTestingAt, isBreakTest, time } = this.state   
    let { progressTestings } = this.state 
    if (exam.error) return <Redirect to={{ pathname: '/', state: { from: this.props.location } }} /> 
    let subjectIcon = IconMathExam
    if(exam.type === 'G') {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = IconMathExam
      }else {
        subjectIcon = IconScienceExam
      }
    } else if(exam.type === 'C'){
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = CompetitionMathIcon
      }else {
        subjectIcon = CompetitionScienceIcon
      }
    }else if(exam.type === 'CUSTOM'){
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = CustomIconMath
      }else {
        subjectIcon = CustomIconScience
      }
    } else if(exam.type === 'CAT') {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = MathCATIcon
      }else {
        subjectIcon = ScienceCATIcon
      }
    } else {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = ExerciseIconMath
      }else {
        subjectIcon = ExerciseIconScience
      }
    }
    const { displayHowTo } = exam      
    return (
      <div 
      // onbeforeunload={this.onCloseTab()} 
        className={ styles.Testing } id = 'containerDiv'>
        <Spin size='large' tip='Loading...'  spinning={this.state.isLoading} style={{ height: '100%' }}>
          <Layout style={{ height: '101%', width: visibleDrawer || isBreakTest ? '100%' : '100%' }}>
            <HeaderTesting
              seqQuestion={seqQuestion}
              exam={exam}
              subjectIcon={subjectIcon}
              onFinishTesting={this.handleUserFinishTesting}
              startedTestingAt={startedTestingAt}
              onBreakTesting={this.handleBreakTesting} 
              time={time}
              cameFrom={this.props.history.location.state  ? this.props.history.location.state.cameFrom : 'exam'}
              history={this.props.history}
              profile={this.props.profile}
            /> 
            <BackTop visibilityHeight	= {1} />
            {
              questions.map((question, i) => {
                const progressTesting = progressTestings.find(pt => pt.order === question.seq)   
                switch (question.type) {
                  case 'SA': return parseInt(seqQuestion) === parseInt(question.seq) ? (<ShortAnswerTesting testingId={testingId} progressTesting={progressTesting} question={question} displayHowTo={displayHowTo} onSetAnswer={this.handleUserAnswer} onSetNote={this.handleNote} key={i} />) : ''
                  case 'MC': return parseInt(seqQuestion) === parseInt(question.seq) ? (<MatchChoiceTesting testingId={testingId} progressTesting={progressTesting} question={question} displayHowTo={displayHowTo} onSetAnswer={this.handleUserAnswer} onSetNote={this.handleNote} key={i} />) : ''
                  case 'TF': return parseInt(seqQuestion) === parseInt(question.seq) ? (<TrueFalseTesting testingId={testingId} progressTesting={progressTesting} question={question} displayHowTo={displayHowTo} onSetAnswer={this.handleUserAnswer} onSetNote={this.handleNote} key={i} />) : ''
                  case 'MA': return parseInt(seqQuestion) === parseInt(question.seq) ? (<MatchAnswerTesting testingId={testingId} progressTesting={progressTesting} question={question} displayHowTo={displayHowTo} onSetAnswer={this.handleUserAnswer} onSetNote={this.handleNote} key={i} />) : ''
                  case 'GR': return parseInt(seqQuestion) === parseInt(question.seq) ? (<GraphTesting testingId={testingId} progressTesting={progressTesting} question={question} displayHowTo={displayHowTo} onSetAnswer={this.handleUserAnswer} onSetNote={this.handleNote} key={i} />) : ''
                  default: return <div></div>
                } 
              })
            }

            <SidebarTesting questions={questions} visibleDrawer={visibleDrawer} onCloseDrawer={this.onCloseDrawer} onChangeQuestion={this.onChangeQuestion} progressTestings={progressTestings} onClickBookMark={this.handleBookmark}/>

            <FooterTesting
              progressTestings={progressTestings}
              seqQuestion={seqQuestion}
              questionCount={questions.length}
              onShowDrawer={this.showDrawer}
              onNextQuestion={() => this.onChangeQuestion(parseInt(seqQuestion)+1)}
              onPreviousQuestion={() => this.onChangeQuestion(parseInt(seqQuestion)-1)}
              onFinishTesting={this.handleUserFinishTesting}
              onClickBookMark={this.handleBookmark}
              exam={exam}
              scrollToHead = {this.scrollToHead}
            />
            {
              this.props.profile ? this.showOnBoarding() : ''
            }
          </Layout> 
        </Spin> 
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.testing,
    profile: state.auth.profile
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetProfileDetail,
      studentExtlinkJoinGroup,
      startTesting,
      sendAnswer,
      snapTime,
      getDetailMyExam,
      doneTesting,
      markQuestion,
      SetSeenTour,
      getGroupDataCode
    },
    dispatch
  )
export default connect(mapStateToProps, mapDispatchToProps)(Test)
