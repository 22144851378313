import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getCompetitionsList,
  switchCompetition,
  onDragToChangePositionCompetition
} from '../../redux/competitions/action'
import { 
  Table,
  Spin,
  Switch 
} from 'antd'
import Header from './Header.js'
import { DndProvider, DragSource, DropTarget } from 'react-dnd-cjs'
import HTML5Backend from 'react-dnd-html5-backend-cjs'
import update from 'immutability-helper'
import IconMoveRow from '../IconSVG/IconMoveRow.svg' 

let dragingIndex = -1

class BodyRow extends React.Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget,  ...restProps } = this.props
    const style = { ...restProps.style, cursor: 'move' }

    let { className } = restProps
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward'
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward'
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    )
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index
    return {
      index: props.index,
    }
  },
}

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}
const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
)

class Competitions extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      competitionsList: [], 
    }
  }
  columns = [
    {
      title: '',
      dataIndex: '',
      key: 'drag',
      render:()=> {
        return ( <img alt='ลาก สลับตำแหน่ง' src={IconMoveRow}/>)
      },
      width:'30px'
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
      width: '250px'
    }, 
    {
      title: 'ใช้งาน',
      dataIndex: 'isActive',
      key: 'isActive',
      render:(isActive,record) => {
        return (
          <Switch
            checkedChildren = 'เปิด'
            unCheckedChildren = 'ปิด'
            checked = { isActive ? true : false }
            onChange={() => { 
              this.onSwitchCompetition(record.id,record) 
            }}
          />
        )
      }
    },
  ]
  components = {
    body: {
      row: DragableBodyRow,
    },
  }
  componentDidMount = () => {
    this.fetchCompetitions()
  }
  fetchCompetitions = async() => {
    const { getCompetitionsList } = this.props
    this.setState({ isLoading: true })
    const res = await getCompetitionsList()
    this.setState({ competitionsList: res,isLoading: false })
  }
  onSwitchCompetition = async(id,record) => {
    const { switchCompetition } = this.props
    let data = { isActive: !record.isActive}
    this.setState({ isLoading: true })
    await switchCompetition(id,data)
    await this.fetchCompetitions()
    this.setState({ isLoading: false })
  }
  moveRow = async(dragIndex, hoverIndex) => {
    const { competitionsList } = this.state
    const { onDragToChangePositionCompetition } = this.props
    const dragRow = competitionsList[dragIndex] 
    this.setState(
      update(this.state, {
        competitionsList: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
    )
    let seq = { seq: hoverIndex}
    await onDragToChangePositionCompetition(competitionsList[dragIndex].id,seq)
  }
  onGenerateSubject = async() => {
    const {onCreateSubject} = this.props
    await onCreateSubject()
    await this.fetchCompetitions()
  }
  setSubjectsFiltersBySearch = (subjectsFilters,isEmptySearch) => { 
    if(!isEmptySearch) {
      this.setState({
        competitionsList: subjectsFilters
      }) 
    }else{
      this.fetchCompetitions()
    } 
  }
  render(){
    const { isLoading,competitionsList } = this.state  
    return (
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading }> 
          <Header dataSearch={competitionsList} 
            setSubjectsFiltersBySearch={this.setSubjectsFiltersBySearch}
            updateCompetition={this.fetchCompetitions}
          />
          <DndProvider backend={HTML5Backend}>
            <Table
              scroll={{x:true}}
              rowKey={ (record,i) => i}
              columns={this.columns}
              dataSource={competitionsList}
              components={this.components}
              pagination={false}
              onRow={(record, index) => ({
                index,
                moveRow: this.moveRow,
              })}
            />
          </DndProvider> 
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
      getCompetitionsList, 
      switchCompetition,
      onDragToChangePositionCompetition
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(Competitions)