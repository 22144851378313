import React, { Component } from 'react'
import { Modal } from 'antd'
import styles from './ServerComponent.module.scss'
class ModalServerUnavailable extends Component { 
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false
    }
  }
  componentDidMount = () => {
    const { isLayoutGuest = false, serverStatus } = this.props 
    if(isLayoutGuest && serverStatus && serverStatus.isOffline) {
      this.setState({ isModalOpen: true })
    } 
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => { 
    const { isLayoutGuest } = this.props
    let { isModalOpen } = this.state 
    if(nextProps.isModalOffline === true ) {
      isModalOpen = nextProps.isModalOffline
      this.setState({ isModalOpen: isModalOpen })
    } 
  }
  render() {
    
    const { serverStatus, setIsModalOffline } = this.props
    const { isModalOpen } = this.state 
    return (
      <Modal
        visible={isModalOpen}
        footer={null}
        destroyOnClose
        className={styles.modalServerClose}
        maskClosable={true}
        closable={true}
        keyboard={false}
        onCancel={() => { 
          this.setState({ isModalOpen : false })  
          if(setIsModalOffline) {
            setIsModalOffline(false)
          }
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: serverStatus && serverStatus.maintainanceMessage }}/> 
      </Modal>
    )
  }
}
export default ModalServerUnavailable