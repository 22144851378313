import React, { Component }  from 'react'
import {
  Layout,
} from 'antd'
import styles from './Testing.module.scss'
import OptionChoice from './OptionChoice'
import NoteAnswer from './Note'
import { displayer } from './helper' 
const { Content } = Layout

class TrueFalseTesting extends Component {
  constructor(props){
    super(props)
    this.myRef = React.createRef()
    this.state = {
      chooses: [],
      note: {
        text: '',
        file: null,
      }
    }
  }

  onClickChoice = (key, index) => {
    const { question,progressTesting } = this.props
    let { chooses } = this.state  
    if(!progressTesting && index > 0) {
      for(let i = 0; i < question.subQuestions.length; i++) {
        chooses[i] = { key: null }
      }
    }
    chooses[index] = { key } 
    const answer = {
      questionId: question._id,
      type: 'TF',
      order: question.seq,
      orgorder: question.orgseq,
      answer: chooses
    }
    this.props.onSetAnswer(answer)
  }

  setNote = (data, type) => {
    let { note } = this.state
    const { question, progressTesting } = this.props
    note =  progressTesting && progressTesting.note !== undefined ? progressTesting.note : note
    note[type] = data
    note.isChanged = true
    this.props.onSetNote(question.seq, note)
  }
 
  render(){  
    const { progressTesting, question, isSolution, displayHowTo, testingId } = this.props
    let { chooses, note } = this.state 
    if (progressTesting) {   
      chooses = progressTesting.answer ? progressTesting.answer.map(answer => answer && answer.key) : []
      note = progressTesting.note ? progressTesting.note : note
    } else {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
      chooses = question.subQuestions.map(() => null)
    }

    return(
      <Content className={styles.content_testing}>
        <div className='true-false'>
          <h2 id = 'myDIV' ref={this.myRef}  className = { styles.num_of_question }>ข้อที่ {question.seq}.</h2>

          <div className='question-content'>
            {displayer(question.text)}
          </div>

          {
            question.subQuestions.map((subQuestion, subQuestionIndex) => {
              return (
                <div key={subQuestionIndex}>
                  <div className='question-content'>
                    {displayer(subQuestion.text)}
                  </div>

                  <div className={styles.all_choice}>
                    {
                      subQuestion.answers.map((answer, answerIndex) => {
                        return (
                          <OptionChoice
                            key={answerIndex}
                            answer={answer}
                            choose={chooses[subQuestionIndex]}
                            optionSeq={answer.seq}
                            onClickChoice={(select) => this.onClickChoice(select, subQuestionIndex)}
                            isSolution={isSolution}
                          />
                        )
                      })
                    }
                  </div>
                </div>)
            }
            )
          }
          {isSolution && (
            <div className='possible-answer'>  
              {/* <span dangerouslySetInnerHTML={{__html: question.explanation}} />  */}
	      <p style={{paddingTop: '4ex', paddingBottom: '0ex', marginBottom: '0ex', fontWeight: 'bold'}}>คำอธิบาย/แนวการตอบ</p>
              <span>{displayer(question.explanation)}</span>
            </div>
          )}
          { displayHowTo && (
            <div className='how-to'>
              <NoteAnswer explanation={note} onUploadFile={(data) => this.setNote(data, 'file')} onChangeNoteText={(data) => this.setNote(data, 'text')} note={note} testingId={testingId} questionId={question._id} isSolution={isSolution} />
            </div>
          )}
        </div>
      </Content>
    )
  }
}
export default TrueFalseTesting
