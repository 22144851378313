import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CKEditor } from 'ckeditor4-react'
import {
  message,
  Spin,
  Button,
  Row,
  Col,
  Form,
  Switch
} from 'antd'
import {
  GetServerConfig,
  UpdateServerConfig
} from '../../redux/master/action'
import styles from './ServerComponent.module.scss'

// const { TextArea } = Input

class ServerComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      serverConfig: {}, 
      ready: false,
      edited: false,
    }
  }

  componentDidMount(){
    this.fetchServerConfig()
  }
  
  fetchServerConfig = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.GetServerConfig()
    this.setState({ 
      isLoading: false,
      serverConfig: res
    })
    if (res.error) message.error(res.error)
  }

  onSubmitEdited = async (e) => {
    e.preventDefault()
    if (!e.err) {
      await this.props.UpdateServerConfig(this.state.serverConfig)
      message.success('บันทึกข้อมูลสำเร็จ')
    }else{
      message.error(e.err)
    }
  }

  render() {
    const { serverConfig } = this.state
    return ( 
      <div className={styles.examSetDiv}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <div className='flex-space-between' style={{marginBottom: '30px'}}>
            <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>ปรับปรุงระบบ</h1>
          </div>
          <div>
            <Form onSubmit={this.onSubmitEdited} >
              <Row className = {styles.table_in_contact_us}>
                <Row className = {styles.row_table_contact_us}>
                  <Col span = {2} className = {styles.td_table_in_contact_us}><label>Mode ปิดปรับปรุงระบบ</label></Col>
                  <Col span = {6} className = {styles.td_table_in_edit_contact_us}>
                    {
                      serverConfig.isOffline !== undefined ? <Switch
                        checkedChildren = 'เปิด'
                        unCheckedChildren = 'ปิด'
                        checked = { serverConfig.isOffline }
                        onChange={(checked, e) => {
                          e.stopPropagation()
                          serverConfig.isOffline = checked
                          this.setState({ serverConfig })
                        }}
                      /> : ''
                    }
                  </Col>
                </Row>
                <Row className = {styles.row_table_contact_us}>
                  <Col span = {2} className = {styles.td_table_in_contact_us}><label>ข้อความแจ้งประกาศ</label></Col>
                  <Col span = {16} className = {styles.td_table_in_edit_contact_us}>
                    {/* <TextArea className ={styles.inputInContact}
                      value = {serverConfig.maintainanceMessage !== undefined ? serverConfig.maintainanceMessage : '' }
                      onChange = {e => {
                        serverConfig.maintainanceMessage = e.target.value
                        this.setState({ serverConfig })
                      }}
                      autoSize={{ minRows: 4, maxRows: 6 }}>
                    </TextArea> */}
                    {
                      (serverConfig.maintainanceMessage === '' || serverConfig.maintainanceMessage) && <CKEditor
                        // data =  {serverConfig.maintainanceMessage ? serverConfig.maintainanceMessage : '<p></p>'}
                        initialData = {serverConfig.maintainanceMessage}
                        name = "servermaintenance"
                        onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                        onInstanceReady={(e) => { e.editor.setData(serverConfig.maintainanceMessage); this.setState({ready: true})}}
                        readOnly={false}
                        config={{
                          toolbarGroups : [
                            { name: 'clipboard', groups: ['undo', 'clipboard'] },
                            { name: 'document', groups: ['mode', 'document', 'doctools'] },
                            { name: 'forms', groups: ['forms'] },
                            { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                            { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                            { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
                            { name: 'links', groups: ['links'] },
                            '/',
                            { name: 'insert', groups: ['insert'] },
                            { name: 'styles', groups: ['styles'] },
                            { name: 'colors', groups: ['colors'] },
                            { name: 'tools', groups: ['tools'] },
                            { name: 'others', groups: ['others'] },
                            { name: 'about', groups: ['about'] }
                          ],
                          removeButtons: 'PasteText,PasteFromWord,Source,Save,Templates,NewPage,ExportPdf,Preview,Print,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Anchor,Blockquote,CreateDiv',
                          imageUploadUrl: 'https://exam.sobtid.me/admin/questions/image', 
                          uploadUrl: 'https://exam.sobtid.me/admin/questions/image?responseType=json', 
                          filebrowserUploadUrl: 'https://exam.sobtid.me/admin/questions/imagefile?responseType=json',
                          editorUrl: 'https://cdn.ckeditor.com/4.17.2/full/ckeditor.js',
                          extraPlugins: 'justify, bidi, smiley, iframe, font, colorbutton, showblocks, find, selectall, pagebreak',
                          language: 'th'
                        }}
                        onChange = {e => {  
                          serverConfig.maintainanceMessage = e.editor.getData()
                          this.setState({ serverConfig })
                        }} />
                    }
                  </Col>
                </Row>
                <Row className = {styles.row_table_contact_us}>
                  <Col span={12}>
                    <div style={{ textAlign: 'right', marginTop: '10px' }}>
                      <Button className = {styles.save_btn} type='primary' htmlType='submit' onClick = {this.onSubmitEdited}>บันทึก</Button>
                    </div> 
                  </Col>
                </Row>
              </Row>
            </Form>
          </div>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetServerConfig,
      UpdateServerConfig
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ServerComponent)