import React, { Component }  from 'react' 
import {  
  Button, 
} from 'antd'  
import AddDataIcon from '../../assets/Icon/AddData.svg'  
class Header extends Component {
  render() { 
    const { onOpenModal } = this.props 
    const addRelationButton = (
      <div style={{display: 'flex'}}> 
        <Button onClick={e => {
          e.stopPropagation()
          onOpenModal(-1,'add')
        }}
        type='primary' 
        >
          <img alt='adddata' src={AddDataIcon}/>เพิ่มข้อมูล
        </Button> 
      </div>
    ) 
    return (
      <div>
        <div className='flex-space-between' style={{marginBottom: '30px'}}>
          <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>ความสัมพันธ์กับนักเรียน</h1>
          {addRelationButton}
        </div>
      </div>
    )
  }
}
 
export default  Header 