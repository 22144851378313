import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { 
  Row, 
  message,
  Tabs,
  Spin
} from 'antd'
import {
  GetDataPrivacy
} from '../../redux/master/action'
const {TabPane} = Tabs

class DataPolicyGuest extends Component{
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      dataPolicyDetail:'',
      checkEdit : false
    }
  }

  componentDidMount(){
    this.fetchContactUsDetail()
  }

  fetchContactUsDetail = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.GetDataPrivacy()
    this.setState({ 
      isLoading: false,
      dataPolicyDetail : res
    })
    if (res.error) message.error(res.error)
  }
  render(){
    return(
      <div>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <Row>
            <Tabs defaultActiveKey='2' onChange={this.onCheckTabActiveKey}>
              <TabPane tab={`นักเรียน ${''}`} key='2' onTabClick = {this.studentListTable}>
                {
                  this.state.dataPolicyDetail ? <div dangerouslySetInnerHTML={{__html: this.state.dataPolicyDetail[1].content }}/> : ''
                }
              </TabPane>
              <TabPane tab={`ครู/อาจารย์ ${''}`} key='3' onTabClick = {this.teacherListTable}>
                <Row>
                  {
                    this.state.dataPolicyDetail ? <div dangerouslySetInnerHTML={{__html: this.state.dataPolicyDetail[0].content }}/> : ''
                  }
                </Row>
              </TabPane>
            </Tabs>
          </Row>
        </Spin>
      </div>)
  }
}

const mapStateToProps = state => {
  return {
    dataPolicyDetail : state.master.dataPolicyDetail
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetDataPrivacy,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DataPolicyGuest)