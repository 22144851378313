import React, { Component }  from 'react'
import {
  Row,
  Col,
  Drawer,
  List,
  Typography
} from 'antd'

import BookmarkIcon from '../IconSVG/BookmarkIcon.svg'
import UnBookmarkIcon from '../IconSVG/UnBookmarkIcon.svg'
import CloseIcon from '../IconSVG/CloseIcon.svg'
import DonePointIcon from '../IconSVG/DonePointIcon.svg'
import CorrectIcon from '../IconSVG/Correct.svg'
import IncorrectIcon from '../IconSVG/Incorrect.svg'
import { displayer } from './helper'

import styles from './Testing.module.scss'

const { Paragraph } = Typography

class SidebarTesting extends Component {
  constructor(props){
    super(props)
  }

  markQ = (seq) => { 
    const { onClickBookMark,progressTestings } = this.props
    const progressTesting = progressTestings.find(pt => pt.order === seq)
    let isMark = progressTesting ? progressTesting.isMark : false
    onClickBookMark(seq, isMark)
  }

  render() {
    const { questions, progressTestings, visibleDrawer, onCloseDrawer, onChangeQuestion, isSolution, isExample = false } = this.props 
    return (
      <Drawer
        // width={365}
        closable={false}
        onClose={onCloseDrawer}
        visible={visibleDrawer}
        placement={'left'}
        className={styles.drawer_header}
      >
        <Row className={styles.header_close_icon}>
          <img  onClick={onCloseDrawer} src={CloseIcon}/>
        </Row>
        <Row>
          <p>รายการข้อสอบ</p>
          <List
            dataSource={questions}
            renderItem={(item) => {
              const progressTesting = progressTestings.find(pt => pt.order === item.seq)
              return (
                <List.Item className={styles.content_drawer} >
                  {/* <hr/> */}
                  <Row style={{ width: '100%' }}>
                    <Col span={18} onClick={() => onChangeQuestion(item.seq) }>
                      <Paragraph ellipsis>
                        ({item.seq}) {displayer(item.text.replace(/(<.*?>|&nbsp;)/gi, '').replace(/\\\((.*?)\\\)/gi,'...'))}
                      </Paragraph>
                    </Col>
                    { isSolution ?
                      isExample ? 
                        '' : <Col span={6}>
                          <img className={styles.list_icon} src={progressTesting && progressTesting.isCorrect ? CorrectIcon : IncorrectIcon}/>
                        </Col>
                      :
                      <Col span={6} className={styles.list_icons}>
                        <span className={styles.list_icon} style={{ width: '24px' }} >{progressTesting && progressTesting.answer ? <img onClick={() => this.markQ(item.seq) } src={DonePointIcon}/> : ''}</span>
                        <span className={styles.list_icon} ><img onClick={() => this.markQ(item.seq) } src={progressTesting && progressTesting.isMark ? BookmarkIcon : UnBookmarkIcon}/></span>
                      </Col>
                    }

                  </Row>
                </List.Item>
              )
            }}
          />
        </Row>
      </Drawer>
    )
  }
}
export default SidebarTesting
