export const Subject = {
  MATH: 'คณิตศาสตร์',
  SCIENCE: 'วิทยาศาสตร์',
}

export const USER_ROLE = {
  STUDENT: 'student',
  TEACHER: 'teacher',
  SUPER_TEACHER: 'superTeacher',
  ADMIN: 'admin'
}