import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Modal,
  Row,
  Col,
  Button,
  message,
  Upload 
} from 'antd' 
import {
  deleteProvince,
  onImportExcelProvinces
} from '../../redux/provinces/action' 
import AddForm from './AddForm'
import EditForm from './EditForm'
import styles from './Provinces.module.scss'  

import BigWarningIcon from '../IconSVG/BigWarningIcon.svg'
import IconButtonExcelUpload from '../IconSVG/IconButtonExcelUpload.svg'
import FileIcon from '../IconSVG/FileIcon.svg'

class ModalProvinces extends Component {
  constructor(props){
    super(props) 
    this.state = {
      fileList: null,
      file: null
    }
  }  
  onDeleteProvince = async(provinceId) => { 
    const { onCloseModal,deleteProvince,fetch } = this.props
    await deleteProvince(provinceId) 
    await fetch()
    message.success('ลบจังหวัดสำเร็จ')
    onCloseModal()
  } 
  onUpload = () => {
    const { onCloseModal,fetch,onImportExcelProvinces } = this.props  
    if (this.state.file === undefined || this.state.file === null) { 
      message.error('กรุณาเลือกไฟล์')
    } else {  
      // eslint-disable-next-line no-undef
      const params  = new FormData()
      params.set( 'provincesImportFile', this.state.file ) 
      onImportExcelProvinces(params).then(res => { 
        if (res.error) {  
          message.error(res.error)  
        } else { 
          fetch()
          message.success('เพิ่มข้อมูลสำเร็จ')
          onCloseModal()
        }
      })
    }
  }
  handleUploadChange = info => {
    let fileList = [...info.fileList]
    fileList = fileList.slice(-1)
    fileList = fileList.map(file => {
      if (file.response) {
        file.status = 'uploading'
        file.status = 'done'
      }
      return file
    })
    let file = null
    if(fileList.length !== 0){
      file = fileList[0].originFileObj
    }
    this.setState({ 
      fileList, 
      file: file,
      fileContent: file
    })
  }
  render(){
    const { modalVisible,typeModal,onCloseModal,item, fetch } = this.props  
    let { fileList } = this.state
    const uploadProps = {
      name: 'file',
      action: '',
      accept: '.xlsx',
      onChange: this.handleUploadChange,
      multiple: false,  
    }
    const deleteModal = (
      <Modal  
        closable={true}
        visible={modalVisible}
        onCancel={onCloseModal}
        footer={false}
        destroyOnClose={true}
        className={styles.header_delete_modal}
        centered={true}
      > 
        <Row>
          <Col span={24} align='center'>
            <div>
              <img alt='big-warning-icon' src={BigWarningIcon}/>
            </div>
            <h1>ลบจังหวัด</h1>
            <h3>{item ? item.name : ''}</h3>
            <span>คุณต้องการลบจังหวัดนี้หรือไม่</span>
            <Row style={{ paddingTop: '25px' }} >
              <Col span={12} >
                <Button onClick={e=>{
                  e.stopPropagation()
                  onCloseModal()
                }} style={{width: '70%', float: 'right', marginRight: '15px'}}>ไม่ลบ</Button>
              </Col>
              <Col span={12}>
                <Button onClick={e => {
                  e.stopPropagation()
                  this.onDeleteProvince(item._id)
                }} style={{width: '70%', float: 'left',marginLeft: '15px' }} type='primary' >ลบจังหวัด</Button>
              </Col>
            </Row>
          </Col>
        </Row> 
      </Modal>
    ) 
    const importExcel = (
      <div style={{textAlign: 'center'}}>
        <div > 
          <img src={IconButtonExcelUpload} alt='IconButtonExcelUpload' />
        </div>
        <div className={styles.upload_window_title}>Upload Excel</div>
        <div className={styles.clickable}>
          <Upload 
            {...uploadProps}
            fileList={fileList}
            onChange={this.handleUploadChange}
            style={{ cursor:'pointer' }}
          >
            <img src={FileIcon} alt='FileIcon' />
            <span>แนบไฟล์</span>
          </Upload>
        </div>
      </div>
    )
    return (
      <div>
        {
          typeModal === 'delete' ?
            <div>{deleteModal}</div>
            : 
            <Modal 
              key={`${typeModal}`} 
              className= {styles.modal}
              centered={true}
              closable={true}
              visible={modalVisible}
              onCancel={onCloseModal} 
              destroyOnClose={true}
              footer={ typeModal === 'importData' ? [
                <Button className={styles.upload_file_button} key='back' onClick={e => {
                  e.stopPropagation()
                  onCloseModal()
                }}
                style={{width: '30%'}}
                >ยกเลิก</Button>,
                <Button className={styles.upload_file_button} key='submit' type='primary' 
                  onClick={e => {
                    e.stopPropagation()
                    this.onUpload()
                  }}
                  style={{width: '30%'}}
                >
                  {/* <img src={FileIcon} alt='FileIcon' /> */}
                  อัปโหลด
                </Button>
              ] : false}
            > 
              {(()=> {
                switch(typeModal){
                  case 'add'       : return <AddForm onCloseModal={onCloseModal} fetch={fetch}/>  
                  case 'edit'      : return <EditForm item={item} name={item.name} region={item.region} onCloseModal={onCloseModal} fetch={fetch}/>
                  case 'importData': return <div >{importExcel}</div>
                  default          : return ''
                }
              })()} 
            </Modal>
        } 
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {   
      deleteProvince,
      onImportExcelProvinces
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(ModalProvinces)