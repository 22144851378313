import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Checkbox,
} from 'antd'

import styles from './ModalUpdatePrivacy.module.scss'

class ModalUpdatePrivacy extends Component {
  render() {
    const { isVisible, onOK, isStudent, isImportJoin } = this.props
    const updateProfile = isStudent ? 'กรุณาปรับปรุงข้อมูลสมาชิก ' : 'กรุณา'
    return (
      <Modal
        visible={isVisible}
        closable={false}
        width={'630px'}
        wrapClassName={styles.modelWrap}
        destroyOnClose={true}
        centered
        footer={<div className='flex-space-center'>
          <Button key='submit' type='primary' htmlType='submit' className={ styles.modalTermOKBtn } onClick={onOK}>
            ตกลง
          </Button>
        </div>
        }
      >
          {isImportJoin ? 
          <div>
            ยินดีต้อนรับสู่ระบบการสอบออนไลน์ ก่อนเริ่มต้นใช้งาน
            <ul>
              <li>กรุณาปรับปรุงข้อมูลสมาชิก อ่านเงื่อนไขและข้อกำหนด และนโยบายคุ้มครองข้อมูลส่วนบุคคลโดยละเอียด และยอมรับข้อกำหนดนี้</li>
              <li>หากผู้ใช้ไม่ยอมรับเงื่อนไขและข้อกำหนด และนโยบายคุ้มครองข้อมูลส่วนบุคคล ระบบจะไม่สามารถให้บริการได้</li>
            </ul>
          </div>
          :
          <div>
            ระบบการสอบออนไลน์ได้รับการปรับปรุง เพื่อรองรับนโยบายคุ้มครองข้อมูลส่วนบุคคล<br />
            ระบบจะแสดง<span style={{ fontWeight: 'bold' }}>นโยบายคุ้มครองข้อมูลส่วนบุคคลของ สสวท.</span> ในหน้าถัดไป
            <ul>
              <li>{updateProfile}อ่านนโยบายคุ้มครองข้อมูลส่วนบุคคลโดยละเอียดและยอมรับนโยบายฯ นี้</li>
              <li>หากผู้ใช้ไม่ยอมรับนโยบายคุ้มครองข้อมูลส่วนบุคคล ระบบจะไม่สามารถให้บริการได้</li>
            </ul>
          </div>
          }
      </Modal>
    )
  }
}

ModalUpdatePrivacy.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onOK: PropTypes.func.isRequired,
  isStudent: PropTypes.bool.isRequired,
  isImportJoin: PropTypes.bool.isRequired,
}

export default ModalUpdatePrivacy