import { FetchCycle, AxiosRequest } from '../fetch'

// API =====================================================================

const API_NOTICE        = '/master/notice' 

// Fetch ===================================================================

const noticeList = ()              => () => AxiosRequest(API_NOTICE,'get')
const updateList = (noticeId,data) => () => AxiosRequest(`${API_NOTICE}/${noticeId}`,'patch',data)

// Action =====================================================================

export const getNoticeList = ()              => FetchCycle(noticeList())
export const onUpdateList  = (noticeId,data) => FetchCycle(updateList(noticeId,data))
 