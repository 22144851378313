import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux' 
import {  
  Button,
  Input, 
} from 'antd' 
import * as moment from 'moment'
import styles from './Departments.module.scss'
import {
  IconSearch,
} from '../IconSVG' 
import AddDataIcon from '../../assets/Icon/AddData.svg'  
import ExcelIcon from '../IconSVG/ExcelIcon.svg'  
import ReactExport from 'react-export-excel' 
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
 
class Header extends Component {
  constructor(props){
    super(props)
    this.state ={
      isLoading: false,
      searchKey: null,
      searchKeyword: null,
      dataFilter: [],
      checkEnter : false
    }
  } 
  handleKeyPress = (e) => {
    setTimeout(()=>{
      this.setState({
        searchKey: e
      })
      if(e === '' && this.state.checkEnter === true){
        this.handleSearch()
        this.setState({
          checkEnter : false
        })
      }
    },50)
  } 
  handleSearch = async(value) => {
    const { dataSearch,setFiltersBySearch } = this.props
    let { searchKeyword, dataFilter } = this.state
    let isEmptySearch = true
    searchKeyword = value  
    this.setState({
      checkEnter : true
    })
    if (searchKeyword) {  
      isEmptySearch = false
      dataFilter = dataSearch.filter(r => r.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)  
    }    
    setFiltersBySearch(dataFilter, isEmptySearch) 
  }
  render(){ 
    const { onOpenModal,departmentItems } = this.props 
    const { searchKey } = this.state
    const addButton = (
      <div style={{display: 'flex'}}> 
        <Input
          suffix={ 
            <Button 
              type='primary' 
              style={{paddingLeft: 5, paddingRight: 10}} 
              onClick={() => this.handleSearch(searchKey)}
            >
              <IconSearch style={{verticalAlign: 'middle'}}/>ค้นหา
            </Button>
          }
          placeholder='ค้นหา (สังกัดของโรงเรียน/หน่วยงาน)'
          maxLength={100}
          className={styles.inputSearch} 
          onChange = {(e)=>this.handleKeyPress(e.target.value)}
          onPressEnter={() => this.handleSearch(searchKey)} 
        />
        <div style={{ paddingRight: '16px'}}> 
          <Button onClick={e => {
            e.stopPropagation()
            onOpenModal(-1,'add')
          }} type='primary'>
            <img alt='เพิ่มข้อมูล' src={AddDataIcon}/>เพิ่มข้อมูล
          </Button>  
        </div>
        <div style={{ paddingRight: '16px'}}> 
          <ExcelFile
            filename = {`สังกัดของโรงเรียน/หน่วยงาน ${moment().format('DD-MM-YYYY')}`}
            element={(
              <Button  type='primary' >
                <img alt='ดาวน์โหลด Excel' src={ExcelIcon}/>ดาวน์โหลด Excel  
              </Button> 
            )}
          > 
            <ExcelSheet data={ departmentItems } name='Employees'>
              <ExcelColumn label='ชื่อ*' value='name'/> 
            </ExcelSheet> 
          </ExcelFile> 
        </div> 
      </div>
    ) 
    return (
      <div>
        <div className='flex-space-between' style={{marginBottom: '30px'}}>
          <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>สังกัดของโรงเรียน/หน่วยงาน</h1>
          {addButton}
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 

    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(Header)