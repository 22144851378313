/* eslint-disable react/no-unescaped-entities */
import React, { Component }  from 'react'
import {
  Button,
  Row,
  Col
} from 'antd'
// import Loadable from 'react-loadable'
import Tour from 'reactour'

import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import 'focus-outline-manager'
import styles from './OnBoarding.module.scss'
import IconExamSet from '../IconSVG/ExamSet.svg'
import BoyHairShort from '../../assets/onboard/BoyHairShort.svg' 
import Girl from '../../assets/onboard/Girl.svg'
import GirlSecondary from '../../assets/onboard/GirlSecondary.svg'
import StarLeft from '../../assets/onboard/StarLeft.svg'
import StarLeftSecondary from '../../assets/onboard/StarLeftSecondary.svg'
import StarRight from '../../assets/onboard/StarRight.svg'
import StarRightSecondary from '../../assets/onboard/StarRightSecondary.svg'
import GirlWithStar from '../../assets/onboard/GirlWithStar.svg'
import TeacherMen from '../../assets/onboard/TeacherMen.svg'
import TeacherWoman from '../../assets/onboard/TeacherWoman.svg' 
import {changeCreateStep} from '../../redux/exam/action' 

// const Tour = Loadable({
//   loader: () => import('reactour'),
//   loading: () => null,
// })

// const create = new ExamSetCreate
export const onBoardCOmponent = (
  <div className = {styles.divCenter}>
    <p className = {styles.titlePrimary}>เริ่มต้นสร้างข้อสอบ</p>
    <Button className = {styles.buttonCreateExam}>
      <img src = {IconExamSet} className = {styles.iconinButton}></img>
      <span className = {styles.textInButton}>จัดชุดข้อสอบ</span>
    </Button>
  </div>
)
class OnBoarding extends Component {
  constructor(props){
    super(props)
    this.state = {
      isTourOpen : false,
      goto: 0
    }
  }

  gotoStep = () => {
    this.setState({
      goto : 1
    })
  }
  

  closeTour = () => {
    this.setState({
      isTourOpen : false
    })
  }
  // ...
  render  (){
    return(
      <div>
        <Tour
          steps={steps}
          isOpen={this.state.isTourOpen}
          onRequestClose={this.closeTour} 
          closeTour = {this.props.closeTour}
        />
        <div>
          {onBoardCOmponent}
        </div>
      </div>
    )
  }
}
export const steps = [
  {
    selector: '[data-tut="reactour__logo"]',
    content: '123',
  },
  {
    selector: '[data-tut="stepTwo"]',
    content: '456',
  },
]

export const stepTourMyExanStudent = [
  {
    selector: '[data-tut="stepTourMyExanStudentFirst"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {14} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  จัดชุดข้อสอบใหม่ได้ที่นี่
                  </label>
                </Row>
              </Col>
              <Col span = {3} style = {{marginRight : 24}}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {Girl}/>
              </Col>
            </Row>
            <Row span = {12}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26}} 
                onClick={() => goTo(1)}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      // padding : 16,
      maxWidth : 1600,
      width: 368,
      transitionDelay: 3000,
    },
    position: 'bottom',
    updateDelay: 10,
  },
  {
    selector: '[data-tut="stepTourMyExanStudentSecond"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {14} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  หรือเลือกทำข้อสอบจากรหัส <br/>
                  ที่ได้มาจากคุณครูหรือเพื่อน
                  </label>
                </Row>
              </Col>
              <Col span = {3} style = {{marginRight : 24}}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {Girl}/>
              </Col>
            </Row>
            <Row span = {12}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26}} 
                onClick={() => goTo(2)}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      maxWidth : 1600,
      width: 428,
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut="stepTourMyExanStudentThird"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {14} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  เข้าร่วมกลุ่มและสนุกไปกับการทำข้อสอบกับเพื่อน ๆ
                  </label>
                </Row>
              </Col>
              <Col span = {2} style = {{}}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {Girl}/>
              </Col>
            </Row>
            <Row span = {12} style ={{textAlign : 'center'}}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26}} 
                onClick={close}
              >ตกลง</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      maxWidth : 1600,
      width: 530,
      paddingLeft: 16,
      paddingTop: 16,
      paddingRight: 18, 
      paddingBottom: 18
    },
    position: 'right',
  },
]

export const stepTourMyExanStudentOldUser = [
  {
    selector: '[data-tut="stepTourMyExanStudentFirst"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {14} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  จัดชุดข้อสอบใหม่ได้ที่นี่
                  </label>
                </Row>
              </Col>
              <Col span = {3} style = {{marginRight : 24}}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {Girl}/>
              </Col>
            </Row>
            <Row span = {12}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26}} 
                onClick={() => goTo(1)}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      // padding : 16,
      maxWidth : 1600,
      width: 368,
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut="stepTourMyExanStudentSecond"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {14} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  หรือเลือกทำข้อสอบจากรหัส <br/>
                  ที่ได้มาจากคุณครูหรือเพื่อน
                  </label>
                </Row>
              </Col>
              <Col span = {3} style = {{marginRight : 24}}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {Girl}/>
              </Col>
            </Row>
            <Row span = {12}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26}} 
                onClick={() => goTo(2)}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      maxWidth : 1600,
      width: 428,
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut="stepTourMyExanStudentThird"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {14} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  เข้าร่วมกลุ่มและสนุกไปกับการทำข้อสอบกับเพื่อน ๆ
                  </label>
                </Row>
              </Col>
              <Col span = {2} style = {{}}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {Girl}/>
              </Col>
            </Row>
            <Row span = {12} style ={{textAlign : 'center'}}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26}} 
                onClick={()=>{goTo(3)}}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      maxWidth : 1600,
      width: 530,
      paddingLeft: 16,
      paddingTop: 16,
      paddingRight: 18, 
      paddingBottom: 18
    },
    position: 'right',
  },
  {
    selector: '[data-tut="step-report-first"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label>
                เมื่อทำข้อสอบเสร็จแล้ว<br/>
                สามารถดูรายงานการสอบได้ที่นี่
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, marginTop : 14 }} 
                  onClick = {()=>{goTo(4)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {Girl}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 414
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut="step-report-second"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label>
                นอกจากนั้นยังสามารถแชร์ข้อสอบให้เพื่อน ๆ ได้อีกด้วย<br/>
                โดยการแชร์ Facebook หรือ คัดลอกลิงก์
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 , marginTop : 14}} 
                  onClick = {()=>{
                    close()
                  }}
                >ตกลง</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {Girl}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 573
    },
    position: 'bottom',
  }
]

export const stepTourStudentCreateExam = [
  {
    selector: '[data-tut="stepTourStudentCreateExamFirst"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {14} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  เลือกวิชา ระดับชั้น ระบุจำนวนข้อ <br/>
                  เพียงเท่านี้ก็สามารถสร้างชุดข้อสอบอย่างง่าย <br/>
                  พร้อมทำข้อสอบได้ทันที
                  </label>
                </Row>
              </Col>
              <Col span = {2} style = {{marginRight : 10}}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {Girl}/>
              </Col>
            </Row>
            <Row span = {12}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26, marginTop : 10}} 
                onClick={() => goTo(1)}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 10,
      maxWidth : 1600,
      width: 500,
    },
    position: 'right',
  },
  {
    selector: '[data-tut="stepTourStudentCreateExamSecond"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {14} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  หรือจะเลือกจัดอย่างละเอียด กำหนดเงื่อนไขเพิ่มเติม <br/>
                  เพื่อสร้างชุดข้อสอบที่ต้องการ
                  </label>
                </Row>
              </Col>
              {/* <Col span = {4} style = {{paddingTop : 20}}>
                <img src = {ImgDropDown}/>
              </Col> */}
              <Col span = {2}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {Girl}/>
              </Col>
            </Row>
            <Row span = {12} style ={{textAlign : 'center'}}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26}} 
                onClick={close}
              >ตกลง</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      maxWidth : 1600,
      width: 580,
      paddingLeft: 16,
      paddingTop: 16,
      paddingRight: 18, 
      paddingBottom: 18
    },
    position: 'left',
  },
]

export const stepStudentCreateExam = [
  {
    selector: '[data-tut="StudentCreateExamstepOne"]',
    content: function DemoHelperComponent() {
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label className = {styles.labelSearchGroup}>จัดชุดข้อสอบใหม่ได้ที่นี่</label>
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {BoyHairShort}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 320,
    },
  },
  {
    selector: '[data-tut="StudentCreateExamstepTwo"]',
    content: function DemoHelperComponent() {
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label className = {styles.labelSearchGroup}>เลือกประเภทของข้อสอบที่ต้องการ</label>
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {Girl}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 400
    },
  },
  {
    selector: '[data-tut="StudentCreateExamstepThree"]',
    content:  function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div style = {{textAlign : 'center'}}>
          <Row>
            <p>
              เลือกรูปแบบการจัดชุดข้อสอบที่ต้องการ<br/>
              <span style = {{fontWeight : 'bold'}}>จัดอย่างง่าย</span> สามารถจัดชุดข้อสอบได้เพียงไม่กี่ขั้นตอน<br/>
              ก็พร้อมตะลุยทำข้อสอบได้ทันที หรือ <span style = {{fontWeight : 'bold'}}>จัดอย่างละเอียด </span> <br/>
              ระบุเงื่อนไขให้กับชุดข้อสอบ เพื่อฝึกฝนตนเองให้ตรงจุด<br/>
            </p>
          </Row>
          <Row>
            <img src = {GirlWithStar} />
          </Row>
          <Row>
            <Button 
              type = 'primary' 
              style = {{width : 100, height : 26, marginTop : 22 }} 
              onClick={() => goTo(3)}
            >ถัดไป</Button> 
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 24,
      maxWidth : 1600,
      width: 400
    },
    // action: node => node.focus()
  },
  {
    selector: '[data-tut="StudentCreateExamstepFour"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {12} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  เลือกวิชา ระดับชั้น <br/>
                  และระบุจำนวนข้อที่ต้องการ</label>
                </Row>
              </Col>
              <Col span = {3}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {Girl}/>
              </Col>
            </Row>
            <Row span = {12}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26, marginTop: 36}} 
                onClick={() => goTo(4)}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 375
    },
    action: node => node.focus()
  },
  {
    selector: '[data-tut="StudentCreateExamstepFive"]',
    content: function DemoHelperComponent() {
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label className = {styles.labelSearchGroup} style = {{lineHeight : '24px'}}>
                ยืนยันการจัดชุด <br/>
                เพิ่อสร้างข้อสอบที่ต้องการ
                </label>
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {GirlSecondary}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 371,
    }
  }
]

export const stepTesting = [
  {
    selector : '[data-tut="step-testing-first"]',
    content:  function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {4}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label className = {styles.labelSearchGroup}>ดูลิสต์ข้อสอบทั้งหมด</label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, fontSize : 14 }} 
                  onClick={() => goTo(1)}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {4}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {Girl}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 358,
    },
    position : 'top',
  },
  {
    selector : '[data-tut="step-testing-second"]',
    content:  function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label className = {styles.labelSearchGroup}>ทำเครื่องหมายเพื่อกลับมาตรวจทาน</label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 }} 
                  onClick={() => goTo(2)}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {Girl}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 428,
    },
    position : 'top',
  },
  {
    selector : '[data-tut="step-testing-fifth"]',
    content:  function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {4}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {14} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label className = {styles.labelSearchGroup}>กดเพื่อทำข้อถัดไป</label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 }} 
                  onClick = {()=>{goTo(3)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {4}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {Girl}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 358,
    },
    position : 'top',
  },
  {
    selector : '[data-tut="step-testing-third"]',
    content:  function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label className = {styles.labelSearchGroup}>พักการสอบและกลับมาทำต่อเมื่อต้องการ</label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 }} 
                  onClick={()=>{goTo(4)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {Girl}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 460,
    },
    position : 'bottom',
  },
  {
    selector : '[data-tut="step-testing-fourth"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label className = {styles.labelSearchGroup}>กดส่งข้อสอบได้แม้ว่ายังทำข้อสอบไม่ครบ</label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 }} 
                  onClick={close}
                >ตกลง</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {Girl}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 460,
    },
    position : 'bottom',
  },
]

export const stepCreateExamFromCode = [
  {
    selector: '[data-tutu="CreateExamsCodepOne"]',
    content: function DemoHelperComponent() {
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeftSecondary}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label className = {styles.labelSearchGroup}>ทำข้อสอบจากรหัสได้ที่นี่</label>
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRightSecondary} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {BoyHairShort}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 320,
    }
  },
  {
    selector: '[data-tutu="CreateExamsCodepTwo"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeftSecondary}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label className = {styles.labelSearchGroup}>กรอกรหัสข้อสอบให้ครบ ก็สามารถทำข้อสอบได้ทันที</label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 'auto', height : 26 }} 
                  onClick = {close}
                >จบการแนะนำ</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRightSecondary} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {BoyHairShort}/>
            </Col>
          </Row>
        </div>
      )
    },
    action: node => node.focus(),
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 510,
    },
    position: 'bottom',
  },

]

export const stepsSearchGroup = [
  {
    selector: '[data-tut="step-first"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label>
                ค้นหากลุ่มจากรหัสกลุ่มและขอเข้าร่วมกลุ่ม<br/>
                  โดยกดปุ่ม “ขอเข้าร่วมกลุ่ม”</label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, marginTop: 14}} 
                  onClick = {close}
                >ตกลง</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {Girl}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 480
    },
    position : 'bottom',
    color: '#000003'
  }
]

export const stepStudentReport = [
  {
    selector: '[data-tut="step-report-first"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label>
                เมื่อทำข้อสอบเสร็จแล้ว<br/>
                สามารถดูรายงานการสอบได้ที่นี่
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, marginTop : 14 }} 
                  onClick = {()=>{goTo(1)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {Girl}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 414
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut="step-report-second"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label>
                นอกจากนั้นยังสามารถแชร์ข้อสอบให้เพื่อน ๆ ได้อีกด้วย<br/>
                โดยการแชร์ Facebook หรือ คัดลอกลิงก์
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 , marginTop : 14}} 
                  onClick = {()=>{
                    close()
                  }}
                >ตกลง</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {Girl}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 573
    },
    position: 'bottom',
  }
]

export const stepTourMyExamTeacher = [
  {
    selector: '[data-tut-teacher="stepTourMyExamTeacherFirst"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row style = {{textAlign : 'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {12} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>จัดชุดข้อสอบใหม่ได้ที่นี่</label>
                </Row>
              </Col>
              <Col span = {3}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {TeacherMen}/>
              </Col>
            </Row>
            <Row span = {12}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26}} 
                onClick={() => goTo(1)}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 320,
      position: 'bottom',
    },
  },
  {
    selector: '[data-tut-teacher="stepTourMyExamTeacherSecond"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row style = {{textAlign : 'center'}}>  
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {12} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>สร้างกลุ่มให้นักเรียนเข้าร่วม</label>
                </Row>
              </Col>
              <Col span = {3}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {TeacherMen}/>
              </Col>
            </Row>
            <Row span = {12} style ={{textAlign : 'center'}}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26}} 
                onClick={close}
              >ตกลง</Button> 
            </Row>
          </Row>
         
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 320,
      position: 'bottom',
    },
    // action: node => node.focus()
  },
]
export const stepTourMyExamTeacherOldUser = [
  {
    selector: '[data-tut-teacher="stepTourMyExamTeacherFirst"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row style = {{textAlign : 'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {12} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>จัดชุดข้อสอบใหม่ได้ที่นี่</label>
                </Row>
              </Col>
              <Col span = {3}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {TeacherMen}/>
              </Col>
            </Row>
            <Row span = {12}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26}} 
                onClick={() => goTo(1)}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 320,
      position: 'bottom',
    },
  },
  {
    selector: '[data-tut-teacher="stepTourMyExamTeacherSecond"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row style = {{textAlign : 'center'}}>  
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {12} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>สร้างกลุ่มให้นักเรียนเข้าร่วม</label>
                </Row>
              </Col>
              <Col span = {3}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {TeacherMen}/>
              </Col>
            </Row>
            <Row span = {12} style ={{textAlign : 'center'}}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26}} 
                onClick={()=>{goTo(2)}}
              >ถัดไป</Button> 
            </Row>
          </Row>
         
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 320,
      position: 'bottom',
    },
    // action: node => node.focus()
  },
  {
    selector: '[data-tut-teacher-report="step-report-first"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label >
                สามารถนำรหัสข้อสอบแชร์ให้นักเรียนได้
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, marginTop : 14 }} 
                  onClick = {()=>{goTo(3)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 430
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut-teacher-report="step-report-second"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label>
                นอกจากนั้นยังสามารถแชร์ข้อสอบโดยการคัดลอกลิงก์<br/>
                หรือแชร์เข้ากลุ่มได้อีกด้วย
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, marginTop : 14 }} 
                  onClick = {()=>{goTo(4)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 570
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut-teacher-report="step-report-third"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label>
                ดูรายงานสถิติของชุดข้อสอบนี้ได้ที่นี่
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 , marginTop : 14}} 
                  onClick = {close}
                >ตกลง</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 420
    },
    position: 'bottom',
  }
]

export const stepTourTeachertCreateExam = [
  {
    selector: '[data-tut-teacher="stepTourTeachertCreateExam"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row style = {{textAlign : 'center'}}>  
            <Row className = {styles.rowStepFirst}>
              <Col span = {3}>
                <img src = {StarLeft}/>
              </Col>
              <Col span = {12} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label>
                  เลือกวิชา ระดับชั้น ระบุจำนวนข้อ <br/>
                  และเงื่อนไขชุดข้อสอบให้ครบถ้วน<br/>
                  เพียงเท่านี้ก็สามารถสร้างชุดข้อสอบได้ทันที
                  </label>
                </Row>
              </Col>
              <Col span = {3}>
                <img src = {StarRight} className = {styles.starRight}/>
              </Col>
              <Col span = {4}>
                <img src = {TeacherMen}/>
              </Col>
            </Row>
            <Row span = {12} style ={{textAlign : 'center'}}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26, marginTop : 14}} 
                onClick={close}
              >ตกลง</Button> 
            </Row>
          </Row>
         
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 485,
    },
    position: 'bottom',
  }
]

export const stepTeacherTourReport = [
  {
    selector: '[data-tut-teacher-report="step-report-first"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label >
                สามารถนำรหัสข้อสอบแชร์ให้นักเรียนได้
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, marginTop : 14 }} 
                  onClick = {()=>{goTo(1)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 430
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut-teacher-report="step-report-second"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label>
                นอกจากนั้นยังสามารถแชร์ข้อสอบโดยการคัดลอกลิงก์<br/>
                หรือแชร์เข้ากลุ่มได้อีกด้วย
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, marginTop : 14 }} 
                  onClick = {()=>{goTo(2)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 570
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut-teacher-report="step-report-third"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label>
                ดูรายงานสถิติของชุดข้อสอบนี้ได้ที่นี่
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 , marginTop : 14}} 
                  onClick = {close}
                >ตกลง</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 420
    },
    position: 'bottom',
  }
]

export const stepTeacherCreateExam  = [
  {
    selector: '[data-tut-teacher="TeacherCreateExamstepFirst"]',
    content: function DemoHelperComponent() {
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label className = {styles.labelSearchGroup}>จัดชุดข้อสอบใหม่ได้ที่นี่</label>
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherWoman}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 320,
      position: 'bottom',
    },
    // action: node => node.focus()
  },
  {
    selector: '[data-tut-teacher="TeacherCreateExamstepSecond"]',
    content: function DemoHelperComponent() {
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {24} style = {{textAlign : 'center'}}>
              <label>เลือกประเภทของข้อสอบที่ต้องการ</label>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 300
    },
    // action: node => node.focus()
  },
  {
    selector: '[data-tut-teacher="TeacherCreateExamstepThird"]',
    content:  function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {24} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  เลือกวิชา ระดับชั้น <br/>
                  และระบุจำนวนข้อที่ต้องการแล้วกดปุ่ม "ถัดไป"
                  </label>
                </Row>
              </Col>
            </Row>
            <Row span = {12}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26, marginTop: 36}} 
                onClick = {()=>{
                  setTimeout(()=>{
                    goTo(3)
                  })
                }}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 'auto'
    },
    action: node => node.focus()
  },
  // {
  //   selector: '[data-tut-teacher="TeacherCreateExamstepThirdPointOne"]',
  //   content:  function DemoHelperComponent({ goTo }) {
  //     DemoHelperComponent.propTypes = {
  //       goTo: PropTypes.func.isRequired,
  //     }
  //     return (
  //       <div className = {styles.stepFourStudentCreateExam}>
  //         <Row style = {{textAlign:'center'}}>
  //           <Row className = {styles.rowStepFirst}>
  //             <Col span = {24} className = {styles.colTxtBtn}>
  //               <Row span = {12}>
  //                 <label className = {styles.labelSearchGroup}>
  //                 กดที่ปุ่ม "ต่อไป"
  //                 </label>
  //               </Row>
  //             </Col>
  //           </Row>
  //           <Row span = {12}>
  //           </Row>
  //         </Row>
  //       </div>
  //     )
  //   },
  //   style: {
  //     backgroundColor: 'white',
  //     color: '#3D3D3D',
  //     padding : 16,
  //     maxWidth : 1600,
  //     width: 'auto'
  //   }
  // },
  {
    selector: '[data-tut-teacher-fourth="TeacherCreateExamstepFourth"]',
    content:  function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {24} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  เลือกเกณฑ์การจัดข้อสอบ<br/>
                  และระบุจำนวนข้อที่ต้องการ
                  </label>
                </Row>
              </Col>
            </Row>
            <Row span = {12}>
              <Button 
                type = 'primary' 
                style = {{width : 100, height : 26, marginTop: 36}} 
                onClick = {()=>{goTo(4)}}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 'auto'
    },
    action: node => node.focus()
  },
  // {
  //   selector: '[data-tut-teacher-Two="TeacherCreateExamstepThirdPointTwo"]',
  //   content:  function DemoHelperComponent({ goTo }) {
  //     DemoHelperComponent.propTypes = {
  //       goTo: PropTypes.func.isRequired,
  //     }
  //     return (
  //       <div className = {styles.stepFourStudentCreateExam}>
  //         <Row style = {{textAlign:'center'}}>
  //           <Row className = {styles.rowStepFirst}>
  //             <Col span = {24} className = {styles.colTxtBtn}>
  //               <Row span = {12}>
  //                 <label className = {styles.labelSearchGroup}>
  //                 กดที่ปุ่ม "ต่อไป"
  //                 </label>
  //               </Row>
  //             </Col>
  //           </Row>
  //           <Row span = {12}>
  //           </Row>
  //         </Row>
  //       </div>
  //     )
  //   },
  //   style: {
  //     backgroundColor: 'white',
  //     color: '#3D3D3D',
  //     padding : 16,
  //     maxWidth : 1600,
  //     width: 'auto'
  //   }
  // },
  {
    selector: '[data-tut-teacher-fifth="TeacherCreateExamstepFifth"]',
    content:  function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepFourStudentCreateExam}>
          <Row style = {{textAlign:'center'}}>
            <Row className = {styles.rowStepFirst}>
              <Col span = {12} className = {styles.colTxtBtn}>
                <Row span = {12}>
                  <label className = {styles.labelSearchGroup}>
                  กำหนดเงื่อนไขเพิ่มเติม เช่น เวลา การแสดงวิธีทำ <br/>
                  การเฉลยละเอียด หรือการทำได้เพียงครั้งเดียว
                  </label>
                </Row>
              </Col>
            </Row>
            <Row span = {12}>
              <Button 
                type = 'primary' 
                style = {{width : 80, height : 26}} 
                onClick = {()=>{
                  setTimeout(()=>{
                    goTo(5)
                  })
                }}
              >ถัดไป</Button> 
            </Row>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 'auto'
    },
    action: node => node.focus()
  },
  // {
  //   selector: '[data-tut-teacher-Three="TeacherCreateExamstepThirdPointThree"]',
  //   content:  function DemoHelperComponent({ goTo }) {
  //     DemoHelperComponent.propTypes = {
  //       goTo: PropTypes.func.isRequired,
  //     }
  //     return (
  //       <div className = {styles.stepFourStudentCreateExam}>
  //         <Row style = {{textAlign:'center'}}>
  //           <Row className = {styles.rowStepFirst}>
  //             <Col span = {24} className = {styles.colTxtBtn}>
  //               <Row span = {12}>
  //                 <label className = {styles.labelSearchGroup}>
  //                 กดที่ปุ่ม "ต่อไป" 
  //                 </label>
  //               </Row>
  //             </Col>
  //           </Row>
  //           <Row span = {12}>
  //           </Row>
  //         </Row>
  //       </div>
  //     )
  //   },
  //   style: {
  //     backgroundColor: 'white',
  //     color: '#3D3D3D',
  //     padding : 16,
  //     maxWidth : 1600,
  //     width: 'auto'
  //   }
  // },
  {
    selector: '[data-tut-teacher="TeacherCreateExamstepSixth"]',
    content: function DemoHelperComponent() {
      return (
        <div className = {styles.stepFirstStudentCreateExam}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12}>
                <label>
                ตรวจสอบความถูกต้องและยืนยันการจัดชุด <br/>
                เพื่อสร้างข้อสอบที่ต้องการ
                </label>
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherWoman}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 450,
      position: 'bottom',
    }
  }
]

export const stepTeacherCreateGroup = [
  {
    selector: '[data-tut-teacher-group="TeacherCreateGroupFirst"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div style = {{textAlign : 'center'}}>
          <Row>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} >
              <Row span = {12} style = {{marginTop : 20}}>
                <label>
                สร้างกลุ่มใหม่ได้ที่นี่
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 , marginTop : 14}} 
                  onClick = {close}
                >ตกลง</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 357,
      borderRadius: 10,
      margin: 5
    },
    position: 'bottom',
  },
  // {
  //   selector: '[data-tut-teacher-group="TeacherCreateGroupSecond"]',
  //   content: function DemoHelperComponent() {
  //     return (
  //       <div className = {styles.stepFirstStudentCreateExam}>
  //         <Row className = {styles.rowStepFirst}>
  //           <Col span = {3}>
  //             <img src = {StarLeft}/>
  //           </Col>
  //           <Col span = {12} className = {styles.colTxtBtn}>
  //             <Row span = {12}>
  //               <label className = {styles.labelSearchGroup}>กดบันทึกเพื่อสร้างกลุ่ม></label>
  //             </Row>
  //           </Col>
  //           <Col span = {3}>
  //             <img src = {StarRight} className = {styles.starRight}/>
  //           </Col>
  //           <Col span = {4}>
  //             <img src = {TeacherMen}/>
  //           </Col>
  //         </Row>
  //       </div>
  //     )
  //   },
  //   style: {
  //     backgroundColor: 'white',
  //     color: '#3D3D3D',
  //     padding : 16,
  //     maxWidth : 1600,
  //     width: 320,
  //     position: 'bottom',
  //   },
  //   action: node => node.focus()
  // },
  // {
  //   selector: '[data-tut-teacher-group="TeacherCreateGroupThird"]',
  //   content: function DemoHelperComponent() {
  //     return (
  //       <div className = {styles.stepFirstStudentCreateExam}>
  //         <Row className = {styles.rowStepFirst}>
  //           <Col span = {3}>
  //             <img src = {StarLeft}/>
  //           </Col>
  //           <Col span = {12} className = {styles.colTxtBtn}>
  //             <Row span = {12}>
  //               <label className = {styles.labelSearchGroup}>กดเพื่อดูรายละเอียด</label>
  //             </Row>
  //           </Col>
  //           <Col span = {3}>
  //             <img src = {StarRight} className = {styles.starRight}/>
  //           </Col>
  //           <Col span = {4}>
  //             <img src = {TeacherMen}/>
  //           </Col>
  //         </Row>
  //       </div>
  //     )
  //   },
  //   style: {
  //     backgroundColor: 'white',
  //     color: '#3D3D3D',
  //     padding : 16,
  //     maxWidth : 1600,
  //     width: 320,
  //     position: 'bottom',
  //   },
  //   action: node => node.focus()
  // },
  // {
  //   selector: '[data-tut-teacher-group="TeacherCreateGroupFourth"]',
  //   content:  function DemoHelperComponent({ goTo }) {
  //     DemoHelperComponent.propTypes = {
  //       goTo: PropTypes.func.isRequired,
  //     }
  //     return (
  //       <div className = {styles.stepFirstStudentCreateExam}>
  //         <Row className = {styles.rowStepFirst}>
  //           <Col span = {3}>
  //             <img src = {StarLeft}/>
  //           </Col>
  //           <Col span = {12} className = {styles.colTxtBtn}>
  //             <Row span = {12}>
  //               <label className = {styles.labelSearchGroup}>เพิ่มข้อสอบเข้ากลุ่มได้ที่นี่</label>
  //             </Row>
  //             <Row>
  //               <Button 
  //                 type = 'primary' 
  //                 style = {{width : 'auto', height : 26}} 
  //                 onClick = {()=>{goTo(4)}}
  //               >ถัดไป</Button> 
  //             </Row>
  //           </Col>
  //           <Col span = {3}>
  //             <img src = {StarRight} className = {styles.starRight}/>
  //           </Col>
  //           <Col span = {4}>
  //             <img src = {TeacherMen}/>
  //           </Col>
  //         </Row>
  //       </div>
  //     )
  //   },
  //   style: {
  //     backgroundColor: 'white',
  //     color: '#3D3D3D',
  //     padding : 16,
  //     maxWidth : 1600,
  //     width: 320,
  //     position: 'bottom',
  //   },
  //   disableInteraction: true
  // },
  // {
  //   selector: '[data-tut-teacher-group="TeacherCreateGroupFifth"]',
  //   content: function DemoHelperComponent({ close }) {
  //     DemoHelperComponent.propTypes = {
  //       close: PropTypes.func.isRequired,
  //     }
  //     return (
  //       <div className = {styles.stepFirstStudentCreateExam}>
  //         <Row className = {styles.rowStepFirst}>
  //           <Col span = {3}>
  //             <img src = {StarLeft}/>
  //           </Col>
  //           <Col span = {12} className = {styles.colTxtBtn}>
  //             <Row span = {12}>
  //               <label className = {styles.labelSearchGroup}>กดเพื่อแชร์กลุ่มให้นักเรียน</label>
  //             </Row>
  //             <Row>
  //               <Button 
  //                 type = 'primary' 
  //                 style = {{width : 'auto', height : 26}} 
  //                 onClick = {close}
  //               >จบการแนะนำ</Button> 
  //             </Row>
  //           </Col>
  //           <Col span = {3}>
  //             <img src = {StarRight} className = {styles.starRight}/>
  //           </Col>
  //           <Col span = {4}>
  //             <img src = {TeacherMen}/>
  //           </Col>
  //         </Row>
  //       </div>
  //     )
  //   },
  //   style: {
  //     backgroundColor: 'white',
  //     color: '#3D3D3D',
  //     padding : 16,
  //     maxWidth : 1600,
  //     width: 320,
  //     position: 'bottom',
  //   }
  // }
]
export const stepTeacherCreateGroupOldUser = [
  {
    selector: '[data-tut-teacher-group="TeacherCreateGroupFirst"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div style = {{textAlign : 'center'}}>
          <Row>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} >
              <Row span = {12} style = {{marginTop : 20}}>
                <label>
                สร้างกลุ่มใหม่ได้ที่นี่
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 , marginTop : 14}} 
                  onClick = {()=>{goTo(1)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 357,
      borderRadius: 10,
      margin: 5
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut-teacher-group-tour="stepTeacherTourGroup-first"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label >
                สามารถนำรหัสกลุ่มแชร์ให้นักเรียนขอเข้ากลุ่มได้
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, marginTop : 14 }} 
                  onClick = {()=>{goTo(2)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 510
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut-teacher-group-tour="stepTeacherTourGroup-second"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label>
                นอกจากนั้นยังสามารถแชร์กลุ่มได้โดยการคัดลอกลิงก์<br/>
                หรือแชร์คิวอาร์โค้ดได้อีกด้วย
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, marginTop : 14 }} 
                  onClick = {()=>{goTo(3)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 570
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut-teacher-group-tour="stepTeacherTourGroup-third"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label>
                เพิ่มชุดข้อสอบเข้ากลุ่มได้ที่นี่
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 , marginTop : 14}} 
                  onClick = {close}
                >ตกลง</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 383
    },
    position: 'bottom',
  }
]

export const stepTeacherTourGroup = [
  {
    selector: '[data-tut-teacher-group-tour="stepTeacherTourGroup-first"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label >
                สามารถนำรหัสกลุ่มแชร์ให้นักเรียนขอเข้ากลุ่มได้
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, marginTop : 14 }} 
                  onClick = {()=>{goTo(1)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 510
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut-teacher-group-tour="stepTeacherTourGroup-second"]',
    content: function DemoHelperComponent({ goTo }) {
      DemoHelperComponent.propTypes = {
        goTo: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label>
                นอกจากนั้นยังสามารถแชร์กลุ่มได้โดยการคัดลอกลิงก์<br/>
                หรือแชร์คิวอาร์โค้ดได้อีกด้วย
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26, marginTop : 14 }} 
                  onClick = {()=>{goTo(2)}}
                >ถัดไป</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 570
    },
    position: 'bottom',
  },
  {
    selector: '[data-tut-teacher-group-tour="stepTeacherTourGroup-third"]',
    content: function DemoHelperComponent({ close }) {
      DemoHelperComponent.propTypes = {
        close: PropTypes.func.isRequired,
      }
      return (
        <div className = {styles.stepsFirstSearchGroup}>
          <Row className = {styles.rowStepFirst}>
            <Col span = {3}>
              <img src = {StarLeft}/>
            </Col>
            <Col span = {12} className = {styles.colTxtBtn}>
              <Row span = {12} style = {{marginTop : 20}}>
                <label>
                เพิ่มชุดข้อสอบเข้ากลุ่มได้ที่นี่
                </label>
              </Row>
              <Row span = {12}>
                <Button 
                  type = 'primary' 
                  style = {{width : 100, height : 26 , marginTop : 14}} 
                  onClick = {close}
                >ตกลง</Button> 
              </Row>
            </Col>
            <Col span = {3}>
              <img src = {StarRight} className = {styles.starRight}/>
            </Col>
            <Col span = {4}>
              <img src = {TeacherMen}/>
            </Col>
          </Row>
          
        </div>
      )
    },
    style: {
      backgroundColor: 'white',
      color: '#3D3D3D',
      padding : 16,
      maxWidth : 1600,
      width: 383
    },
    position: 'bottom',
  }
]
OnBoarding.propTypes = { 
  isShowingMore: PropTypes.bool.isRequired,
  toggleShowMore: PropTypes.func.isRequired,
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.profile,
    createStep: state.exam.createStep
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeCreateStep
    },
    dispatch
  )
  
export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding)