import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  onUpdateProvince
} from '../../redux/provinces/action'  
import styles from './Provinces.module.scss'
import { 
  Row,
  Col,
  Divider,
  Button,
  Input,
  Form,
  message,
  Select
} from 'antd'
const { Option } = Select
class EditForm extends Component {
  onGenerateDepartment = (e) => {
    const { onCloseModal,item,onUpdateProvince,fetch  } = this.props
    e.preventDefault() 
    this.props.form.validateFieldsAndScroll( async(err, values) => {   
      if(!err){ 
        await onUpdateProvince(item._id,values)
        await fetch() 
        message.success('แก้ไขจังหวัดสำเร็จ')
        onCloseModal() 
      }
    })
  }
  render(){ 
    const { onCloseModal,form: { getFieldDecorator },name,region } = this.props      
    return (
      <Form onSubmit={this.onGenerateDepartment} hideRequiredMark={true}>
        <h1 className={styles.header_modal}>แก้ไขข้อมูล</h1>
        <Row >
          <Col offset={4} span={16}  > 
            <Form.Item label='จังหวัด'>
              {
                getFieldDecorator('name',{
                  rules: [
                    {
                      required: true,
                      min: 1,
                      message:'กรุณากรอกอย่างน้อย 1 ตัวอักษร'
                    }
                  ],
                  initialValue: name
                })(<Input />)
              }
            </Form.Item> 
            <Form.Item label='ภาค'>
              {
                getFieldDecorator('region',{
                  rules: [ 
                    {
                      required: true,
                      min: 1,
                      message: 'กรุณาเลือกภูมิภาค',
                    }, 
                  ],
                  initialValue: region
                })(<Select   
                  style={{ width: '100%' }} 
                > 
                  <Option key='เหนือ'>เหนือ</Option> 
                  <Option key='ตะวันออกเฉียงเหนือ'>ตะวันออกเฉียงเหนือ</Option> 
                  <Option key='ตะวันตก'>ตะวันตก</Option> 
                  <Option key='กลาง'>กลาง</Option> 
                  <Option key='ตะวันออก'>ตะวันออก</Option> 
                  <Option key='ใต้'>ใต้</Option> 
                </Select>)
              }
            </Form.Item> 
          </Col> 
        </Row>
        <Divider/>
        <Row>
          <Col span={12}>
            <span onClick={e => {e.stopPropagation(); onCloseModal()}} style={{ cursor:'pointer', color:'#828282', textDecorationLine: 'underline'}}>ยกเลิก</span>
          </Col>
          <Col span={12}>
            <Button htmlType='submit' type='primary' style={{ float: 'right' }}>บันทึก</Button>
          </Col>
        </Row> 
      </Form>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {   
      onUpdateProvince
    },
    dispatch
  )
 
const WrappedFormDataSchool = Form.create({ name: 'Edit_Form_Province' })(EditForm)
export default connect(mapStateToProps,mapDispatchToProps)(WrappedFormDataSchool)
