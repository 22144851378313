import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Table,
  Modal,
  Avatar,
  Checkbox
} from 'antd' 
import styles from '../Group.module.scss'
import Delete from '../../IconSVG/Delete.svg'
import DeleteGray from '../../IconSVG/DeleteGray.svg'
import * as moment from 'moment' 
import ListItems from '../../Display/ListItems'
import GoupMoveMemberBigIcon from '../../IconSVG/GoupMoveMemberBigIcon.svg'
import DefaultProfile from '../../IconSVG/DefaultProfile.svg'

const LEFT_STATUS = 'left' 

class DetailStudentInGroup extends Component {
  static defaultProps = {
    data: []
  }
  constructor(props) {
    super(props)
    this.state = {
      selectedRows: [],
      indexRow: null,
      checkDelete: null,
      visibleModalDelete : false,
      studentDetail : {},
      visibleModalRemove: false,
      checkSiftOut: [],
      isCheckAll: false, 
      isMobile: false
    }
  }

  columns = [
    {
      title: '',
      dataIndex: 'profileImage',
      align: 'center',
      render: profileImage => <img src={profileImage ? profileImage : DefaultProfile} alt='profileImage' className={styles.logoGroup}/>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'studentName',
    },
    {
      title: 'โรงเรียน',
      dataIndex: 'schoolName',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      align: 'center',
      render: status => (<span>{status === LEFT_STATUS ? 'ออกจากลุ่ม' : 'เข้าร่วม'}</span>),
    },
    {
      title: 'วันที่เข้าร่วม',
      align: 'center',
      dataIndex: 'jointDate',
      render: jointDate => jointDate ? <span>{moment.unix(jointDate).format('DD/MM/YYYY')}</span> : <span>-</span>
    },
    {
      title: 'วันที่ออกจากกลุ่ม',
      align: 'center',
      dataIndex: 'leftDate',
      render: leftDate => leftDate ? <span>{moment.unix(leftDate).format('DD/MM/YYYY')}</span> : <span>-</span>
    },
    {
      title: '',
      key: 'action',
      render: (text, student,index) => {
        const { indexRow,checkDelete } = this.state
        const mouseOver = () => { this.setState({ checkDelete: true }) }
        const mouseLeave = () => { this.setState({ checkDelete: false })}
        return (
          <span className={styles.tableAction}>
            <span className={styles.clickable} onClick={ e => {
              e.stopPropagation()
              student.status === LEFT_STATUS ?
                this.props.removedStudent(student)
                :
                this.setState({
                  visibleModalDelete : true,
                  studentDetail : student
                })
            }}
            onMouseOver = {mouseOver}
            onMouseLeave = {mouseLeave}
            >
              {/* <IconDelete style={{fontSize: 24, verticalAlign: 'text-bottom', marginRight: 5}}/>  */}
              <img alt='ลบรายการ' src={checkDelete && indexRow === index ? Delete : DeleteGray}/>
              <span style={{textDecoration: 'underline'}}>{student.status === LEFT_STATUS ? 'ลบรายการ' : 'คัดออกจากกลุ่ม'}</span>
            </span>
          </span>
        )
      },
    },
  ]

  componentDidMount = () => {
    this.setDefaultRemoveStudentArray() 
  }
  
  UNSAFE_componentWillReceiveProps = (nextProps) => { 
    let { checkSiftOut } = this.state  
    nextProps.data.forEach((item, i) => {
      checkSiftOut[i] = { studentId: null, isCheck: false }
    })
    this.setState({ checkSiftOut })
  }

  setDefaultRemoveStudentArray = async() => {
    const { data } = this.props
    let { checkSiftOut } = this.state
    data.forEach((item, i) => {
      checkSiftOut[i] = { studentId: null, isCheck: false }
    })
    this.setState({ checkSiftOut })
  }

  onRemoveSelectedFromGroup = () => { 
    let { selectedRows } = this.state
    const { checkSiftOut, isMobile } = this.state 
    if (isMobile) {
      selectedRows = checkSiftOut.filter((item ) => item.isCheck)
    } 
    this.props.onRemoveFromGroup(selectedRows)
    this.setState({ selectedRows: [], isMobile: false })
    this.setDefaultRemoveStudentArray()
    this.handleCancel()
  }

  rowClassName = record => record.status === LEFT_STATUS ? 'rowDisabled' : null

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ selectedRows: selectedRows })
    },
  }
  handleCancel = ()=> {
    this.setState({
      visibleModalDelete : false,
      visibleModalRemove: false
    })
  }
  deleteStudent = () =>{
    this.props.onRemoveFromGroup([this.state.studentDetail])
    this.setState({
      visibleModalDelete : false
    })
  }
  openModalRemoveStudent = () => {
    this.setState({ visibleModalRemove: true })
  }
  mouseOver = () => { this.setState({ checkDelete: true }) }
  mouseLeave = () => { this.setState({ checkDelete: false })}

  onChangeCheckBox = ( isCheck ,index, studentId ) => {
    let { checkSiftOut, isCheckAll } = this.state  
    if (isCheck) { 
      checkSiftOut[index] = { studentId: studentId, isCheck: isCheck }
    }else if (isCheckAll) { 
      isCheckAll = false
      checkSiftOut[index] = { studentId: studentId, isCheck: isCheck }
      checkSiftOut = checkSiftOut.map((item, i) => {
        if (i !== index) {
          item.isCheck = true
        }
        return item
      })
    } else { 
      checkSiftOut[index] = { studentId: studentId, isCheck: isCheck }
    }
    let arr = true
    checkSiftOut.map((item ) => { 
      if(!item.isCheck) arr = false 
    })
    this.setState( arr ? { checkSiftOut, isCheckAll: arr } : { checkSiftOut, isCheckAll }) 
  }
  onCheckAll = () => {
    let { isCheckAll, checkSiftOut } = this.state
    const { data } = this.props
    isCheckAll = !isCheckAll
    if ( !isCheckAll ) {
      checkSiftOut.map(( item ) => {
        item.isCheck = false
      })
    } else {
      data.map(( item,i ) => {
        checkSiftOut[i].isCheck = true
        checkSiftOut[i].studentId = data[i].studentId
      })
    }
    this.setState({ isCheckAll, checkSiftOut })
  }

  render() {
    const { selectedRows, studentDetail, isCheckAll , isMobile } = this.state
    const { data } = this.props
    let { checkSiftOut } = this.state
    let isButtonRemove = false
    let countSelectStudent = 0
    
    checkSiftOut.map((item) => {
      if(item.isCheck) {
        isButtonRemove = true 
        countSelectStudent++
      }
    })
    
    const srcData = data.map((item ) => {
      const menu = [
        {
          attribute: (
            <span className={styles.tableAction}
              hidden = { countSelectStudent > 0 ? true : false }
            >
              <span className={styles.clickable} onClick={ e => {
                e.stopPropagation()
                this.setState({
                  visibleModalDelete : true,
                  studentDetail : item
                })
              }}
              onMouseOver = {this.mouseOver}
              onMouseLeave = {this.mouseLeave}
              >
                <img src={Delete}/>
                <span style={{textDecoration: 'underline'}}>{item.status === LEFT_STATUS ? 'ลบรายการ' : ''}</span>
              </span>
            </span>
          )
        }
      ]
      
      return {
        avatar: item.profileImage,
        title: item.studentName,
        description: item.schoolName,
        status: item.status === 'join' ? 'เข้าร่วม' : '',
        actions: menu,
        studentId: item.studentId ? item.studentId : null,
        path: false
      }
    })   
    return (
      <div className='student-in-group-table'>
        <div className='mobile'>
          <div> 
            <Checkbox  
              onChange = { e => { e.stopPropagation(); this.onCheckAll() } }
              style = {{ paddingRight: '7px' }}
              checked = { countSelectStudent === checkSiftOut.length && data.length > 0 }
            />
            {
              isButtonRemove && data.length > 0 ? 
                <Button onClick = { e => {
                  e.stopPropagation()
                  this.setState({ isMobile: true })
                  this.openModalRemoveStudent() 
                }} style = {{ height: '35px' }}>คัดออกจากกลุ่ม</Button>
                : 'เลือกทั้งหมด'
            }
          </div>
          <ListItems
            isCheckAll = { isCheckAll }
            isCheckbox = { true }
            onChangeCheckBox = { this.onChangeCheckBox }
            checkSiftOut = { checkSiftOut } 

            srcData = { srcData }
            avatar
            status
            history={this.props.history}
          />
        </div>

        <div className='desktop'>
          { selectedRows.length > 0 ? 
            <div style={{ paddingBottom: '10px' }}>
              <Button onClick={ 
                this.openModalRemoveStudent 
                // this.onRemoveSelectedFromGroup
              } size='small' style={{minWidth: 144}}>คัดออกจากกลุ่ม</Button> 
            </div>
            : '' 
          }

          <Table
            rowKey={ (record,i) => i}
            rowSelection={this.rowSelection}
            rowClassName={this.rowClassName}
            columns={this.columns}
            dataSource={data}
            pagination={false}
            onRow={(record, rowIndex) =>{
              return {
                onMouseEnter: () => this.setState({ indexRow: rowIndex })
              }
            }}
          />
        </div>
        <Modal
          visible={this.state.visibleModalRemove}
          onCancel={this.handleCancel}
          footer={false}
          centered
        >
          <div style={{textAlign: 'center'}}>
            <Avatar src={GoupMoveMemberBigIcon} shape='square' size={150}/>
            <h2 className='text-medium' style={{fontSize: 20}}>คัดออกจากกลุ่ม</h2>
            <p style={{ fontWeight: '500px', fontSize: '28px' }}>นักเรียนจำนวน {isMobile ? countSelectStudent : selectedRows.length} คน</p>
            <p style={{color: '#4F4F4F', fontSize: 14}}>คัดออกจากกลุ่มนี้หรือไม่</p>
            <div className={styles.modalCreated} >
              <div className={styles.sub_modalCreated} ><Button onClick = {this.handleCancel} className = {styles.btnCancle}>ไม่คัดออก</Button></div>
              <div className={styles.sub_modalCreated_2} ><Button type = 'primary' onClick = {this.onRemoveSelectedFromGroup} className = {styles.btnLeave}>คัดออกจากกลุ่ม</Button></div>
              <div className={styles.sub_modalCreatedMobile} ><Button type = 'link' onClick = {this.handleCancel} className = {styles.btnCancleMobile}>ไม่คัดออก</Button></div>
            </div>
          </div> 
        </Modal>

        <Modal
          visible={this.state.visibleModalDelete}
          onCancel={this.handleCancel}
          footer={false}
          centered
        >
          <div style={{textAlign: 'center'}}>
            <Avatar src={GoupMoveMemberBigIcon} shape='square' size={150}/>
            <h2 className='text-medium' style={{fontSize: 20}}>คัดออกจากกลุ่ม</h2>
            <p style={{ fontWeight: '500px', fontSize: '28px' }}>{  studentDetail ? studentDetail.studentName : '' }</p>
            <p style={{color: '#4F4F4F', fontSize: 14}}>คัดออกจากกลุ่มนี้หรือไม่</p>
            <div className={styles.modalCreated} >
              <div className={styles.sub_modalCreated} ><Button onClick = {this.handleCancel} className = {styles.btnCancle}>ไม่คัดออก</Button></div>
              <div className={styles.sub_modalCreated_2} ><Button type = 'primary' onClick = {this.deleteStudent} className = {styles.btnLeave}>คัดออกจากกลุ่ม</Button></div>
              <div className={styles.sub_modalCreatedMobile} ><Button type = 'link' onClick = {this.handleCancel} className = {styles.btnCancleMobile}>ไม่คัดออก</Button></div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

DetailStudentInGroup.propTypes = {
  data: PropTypes.array.isRequired,
  onRemoveFromGroup: PropTypes.func.isRequired,
  removedStudent: PropTypes.func.isRequired,
}

export default DetailStudentInGroup
