exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ContactUs_td_table_in_contact_us__8iDcM {\n  padding-bottom: 2em;\n  margin-right: 2em; }\n\n.ContactUs_td_right_in_contact_us__2Ybss {\n  width: 480px;\n  box-sizing: border-box;\n  border-radius: 4px; }\n\n.ContactUs_td_table_in_edit_contact_us__3Xyzm {\n  padding-left: 35px;\n  width: 480px; }\n\n.ContactUs_cancle_link__2wFvV {\n  font-size: 16px;\n  line-height: 26px;\n  letter-spacing: 0.192px;\n  text-decoration-line: underline;\n  /* Gray 3 */\n  color: #828282; }\n\n.ContactUs_detail_name__2SFfB {\n  padding-left: 1em;\n  margin-left: 1em;\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 40px;\n  background: #E5E5E5;\n  border: 1px solid #E0E0E0;\n  box-sizing: border-box;\n  border-radius: 4px; }\n\n.ContactUs_detail_address__3ka9J {\n  padding-left: 1em;\n  margin-left: 1em;\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 40px;\n  height: 116px;\n  background: #E5E5E5;\n  border: 1px solid #E0E0E0;\n  box-sizing: border-box;\n  border-radius: 4px; }\n\n.ContactUs_devide_line__1qKAK {\n  margin-top: 10%;\n  margin-bottom: 2%;\n  width: 90%;\n  height: 0px;\n  left: 233px;\n  top: 716px;\n  background: #BDBDBD;\n  border: 1px solid #E0E0E0; }\n\n.ContactUs_save_btn__l9xRO {\n  width: 178px; }\n\n.ContactUs_inputInContact__3dyZ1 {\n  margin-bottom: 2em; }\n", ""]);

// Exports
exports.locals = {
	"td_table_in_contact_us": "ContactUs_td_table_in_contact_us__8iDcM",
	"td_right_in_contact_us": "ContactUs_td_right_in_contact_us__2Ybss",
	"td_table_in_edit_contact_us": "ContactUs_td_table_in_edit_contact_us__3Xyzm",
	"cancle_link": "ContactUs_cancle_link__2wFvV",
	"detail_name": "ContactUs_detail_name__2SFfB",
	"detail_address": "ContactUs_detail_address__3ka9J",
	"devide_line": "ContactUs_devide_line__1qKAK",
	"save_btn": "ContactUs_save_btn__l9xRO",
	"inputInContact": "ContactUs_inputInContact__3dyZ1"
};