import React, { Component } from 'react'
import * as FileSaver from 'file-saver' 
import * as XLSX from 'xlsx' 
import PropTypes from 'prop-types'
import {
  Menu,
  Button,
  Dropdown,
  Input
} from 'antd'
import IconSearchData from '../IconSVG/icon_faq_search.svg' 
import styles from './TopTitleComponent.module.scss'
import * as moment from 'moment'
import { dateFromTimeStamp } from '../../util/helper'

let csvData = []

export const ExportCSV = ({button, fileName, fetchUser}) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' 
  const fileExtension = '.xlsx'

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData,{ header: [], skipHeader: true })
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    // eslint-disable-next-line no-undef
    const data = new Blob([excelBuffer], {type: fileType})
    FileSaver.saveAs(data, fileName + fileExtension)
  }
  const filterData = (csvData) => {  
    csvData.unshift({ 
      name: 'ชื่อ', 
      profileImage: 'รูปโปรไฟล์', 
      schoolName: 'โรงเรียน', 
      createdAt: 'วันที่สร้าง',
      email:'อีเมล',
      lastLoggedInAt:'ใช้งานล่าสุด',
      isBanned:'การใช้งาน',
      isConfirmationEmail:'การยืนยันตัวตน',
      isPolicy:'นโยบายส่วนตัว',
      termAndCondition: 'ยอมรับเงื่อนไขและข้อกำหนด',
      ConfirmationEmail:'การยืนยันอีเมล',
      parentFullName: 'ชื่อผู้ปกครอง',
      parentEmail: 'อีเมลผู้ปกครอง',
      parentTelephone: 'โทรศัพท์ผู้ปกครอง',
      parentRelation: 'ความสัมพันธ์',
      })
    csvData = csvData.map((c,i) => {  
      if(i) c.isBanned =   c.isBanned ? 'ปิด' : 'เปิด'
      if(i) c.isConfirmationEmail =  c.isConfirmationEmail ? 'ยืนยันตัวตนแล้ว' : 'ยังไม่ยืนยันตัวตัว' 
      delete c.id
      delete c.key
      delete c.updatedAt 
      delete c.Banned
      delete c.isSeenModified
      delete c.privacyPolicy
      delete c.hasSeenPrivacyPolicy
      delete c.isOfflineLoginable
      delete c.username
      delete c.oldSystemId
      return c
    }) 
  }

  return (
    <Button type='primary' className={styles.btn} variant='warning' onClick={async() => { 
      await fetchUser()
      await filterData(csvData) 
      csvData = csvData.map((c,i) => {
        if(i !== 0) {
          let yearTH = c.createdAt.slice(0,6).concat(parseInt(c.createdAt.slice(-4)) + 543) 
          c.createdAt = yearTH
          c.lastLoggedInAt = dateFromTimeStamp(c.lastLoggedInAt)
        }
        return c
      }) 
      exportToCSV(csvData,fileName)
    }}>
      <img src={button.icon} alt={button.text} />
      <span className='responsive-btn'>{button.text}</span>
    </Button>
  )
}
class TopTitleComponent extends Component { 
  static defaultProps = {
    searchBar: true
  }
  constructor(props) {
    super(props)
    this.state = { 
      usersAll: [],
      searchKey: ''
    }
  }
  componentDidMount = () => {
    // this.fetchUser()
  }

  fetchUser = async () => {
    if(this.props.fetchUsersAll){
      const res = await this.props.fetchUsersAll()    
    
      csvData = res.items ? await res.items.map(d => {
        d.schoolName = d.school ? d.school.name.text : ''
        d.isPolicy = d.isSeenDataPrivacy ? 'ยอมรับ' : 'ไม่ยอมรับ' 
        d.termAndCondition = d.isSeenTermAndCondition ? 'ยอมรับ' : 'ไม่ยอมรับ'
        d.ConfirmationEmail = d.isConfirmationEmail ? 'ยืนยันแล้ว' : 'รอการยืนยัน'
        d.Banned = d.isBanned ? 'ปิด' : 'เปิด'
        d.createdAt = moment.unix(d.createdAt).format('DD/MM/YYYY')
        d.parentFullName = d.parent ? (d.parent.parentPrefix + " " + d.parent.parentFirstName + " " + d.parent.parentLastName) : ""
        d.parentEmail = d.parent ? d.parent.parentEmail : ""
        d.parentTelephone = d.parent ? d.parent.parentTelephone : ""
        d.parentRelation = d.parent ? d.parent.relation : ""
        delete d._id
        delete d.key
        delete d.updatedAt 
        delete d.Banned
        delete d.isSeenModified
        delete d.policy
        delete d.role
        delete d.hasSeenPrivacyPolicy
        delete d.privacyPolicy
        delete d.isSeenTermAndCondition
        delete d.isSeenDataPrivacy
        return d
      }) : []   
    }
  }

  generateButtons() {
    const { buttons } = this.props   
    let checkDownloadExcel = buttons ? buttons.find(b => b.key === 'exportExcelButton' )  : null 
    if (!buttons || buttons.length === 0) return []
    const btns = buttons.map(button => {
      if (button.subMenu) { 
        const menu = (<Menu>{button.subMenu.map((subMenu, index) => (
          <Menu.Item key={index} onClick={subMenu.onClick}>
            <img src={subMenu.icon} alt={subMenu.text} style={{ marginRight: '5px'}}/>
            {subMenu.text}
          </Menu.Item>)
        )}</Menu>)
        return (<Dropdown overlay={menu} placement='bottomLeft' key={button.text} className={styles.btn}>
          <Button type='primary'>
            <img src={button.icon} alt={button.text} />
            <span className='responsive-btn'>{button.text}</span>
          </Button>
        </Dropdown>)
      } else { 
        return (
          <div key={button.text}>
            { 
              checkDownloadExcel?  
                <ExportCSV button={button} csvData={csvData} fileName={`รายชื่อในระบบ ${moment().format('DD-MM-YYY')}.xlsx`} fetchUser={this.fetchUser}/>
                :
                <Button type='primary' key={button.text} className={styles.btn} onClick={button.onClick}>
                  <img src={button.icon} alt={button.text} />
                  <span className='responsive-btn'>{button.text}</span>
                </Button>  
            }
          </div>
        )
      }
    })

    return btns
  }

  render() {
    const { title , searchBar } = this.props  
    return ( 
      <div className='flex-space-between' style={{marginBottom: '30px'}}>
        <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>{title}</h1>
        <div style={{display: 'flex'}}>
          {
            searchBar ? 
              <Input
                suffix={
                  <Button type='primary' 
                    style={{paddingLeft: 5, paddingRight: 10}} 
                    onClick={() => this.props.onSearchUser(this.state.searchKey)}
                  >
                    <img alt='icon-search-data' src ={IconSearchData} style={{verticalAlign: 'middle'}}/>
                ค้นหา
                  </Button>}
                placeholder='ค้นหา'
                // value = {this.state.searchKey}
                maxLength={100}
                className={styles.inputSearch}
                onChange = {(e) => {
                  this.props.setSerchKey(e.target.value)
                  this.setState({
                    searchKey : e.target.value
                  })
                }}
                onPressEnter = {(e) => this.props.onSearchUser(e.target.value)}
              /> : ''
          }
          {this.generateButtons()}
        </div>
      </div>
    )
  }
}

TopTitleComponent.propTypes = {
  title: PropTypes.string.isRequired,
}


export default TopTitleComponent
