import React from 'react'
import Lottie from 'react-lottie'

import contentThree from '../srcAnimationJson/content3.json'

import './HomeContentThree.module.scss'

class HomeContentThree extends React.Component {
  static defaultProps = {
    height: 270,
    width: 270
  }

  render () {
    const { width, height } = this.props
    const headerEffect = {
      loop: true,
      autoplay: true,
      animationData: contentThree,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <div className='atom-animation' style={{ height }}>
        <Lottie
          options={headerEffect}
          height={height}
          width={width}
        />
      </div>
    )
  }
}

export default HomeContentThree

