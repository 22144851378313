/* eslint-disable no-undef */
import React, { Component , Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Table,
  Button,
  message
} from 'antd'
// import {
//   startTesting
// } from '../../../redux/testing/action'
import moment from 'moment'
import CustomIconMath from '../../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../../IconSVG/CustomIconScience.svg'
import ExerciseIconScience from '../../IconSVG/ExerciseIconScience.svg'
import ExerciseIconMath from '../../IconSVG/ExerciseIconMath.svg'
import CompetitionScienceIcon from '../../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../../IconSVG/CompetitionMathIcon.svg'
import ReportIcon from '../../IconSVG/Report.svg'
import ReportGrayIcon from '../../IconSVG/ReportGray.svg'
import IconExamMath from '../../IconSVG/ExamMath.svg'
import IconExamScience from '../../IconSVG/ExamScience.svg' 
import ListItems from '../../Display/ListItems'
import styles from '../Group.module.scss'
import './GroupDetail.scss'

class StudentGroup extends Component {
  constructor(props){
    super(props)
    this.state = {
      isHover: false,
      indexRow: false,
      isHoverReportButton: false,
    }
  }

  columns = [
    {
      title: 'รหัสข้อสอบ',
      dataIndex: 'code',
      width: '135px'
    },
    {
      title: () => {
        return (
          <span style={{ paddingLeft:'20px' }}>ชื่อ</span>
        )
      },
      dataIndex: 'name',
      render: (name,record ) => {
        let logo = null
        if(record.type === 'G') {
          if(record.subject === 'คณิตศาสตร์') {
            logo = IconExamMath
          }else {
            logo = IconExamScience
          }
        } else if(record.type === 'C'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CompetitionMathIcon
          }else {
            logo = CompetitionScienceIcon
          }
        }else if(record.type === 'CUSTOM'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CustomIconMath
          }else {
            logo = CustomIconScience
          }
        }else {
          if(record.subject === 'คณิตศาสตร์') {
            logo = ExerciseIconMath
          }else {
            logo = ExerciseIconScience
          }
        }
        return (
          <div>
            <img alt='subject' src={logo}/>
            {name}
          </div>
        )
      },
      width: '250px'
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      render: (type) => { 
        switch (type) {
          case 'G': return <span>ตามหลักสูตร</span>
          case 'C': return <span>ตามหลักสูตร</span>
          case 'CAT': return <span>CAT</span>
          case 'CUSTOM': return <span>กำหนดเอง</span>
          case 'EX': return <span>แบบฝึกหัด</span>
          case 'E': return <span>แบบฝึกหัด</span>
          default : return <span>-</span>
        }
         
      },
      width: '130px'
    },
    {
      title: 'วันที่แชร์',
      dataIndex: 'addedAt',
      render:(addedAt)=>{
        return(
          <span>{moment.unix(addedAt).format('DD/MM/YYYY')}</span>
        )
      },
      width:'125px'
    },
    {
      dataIndex: 'testing',
      width: '50px',
      align:'center',
      render: (testing,record, index) => {
        const { exams } = this.props
        let textButton = 'เริ่มทำ'
        let checkOneTimeDone = true 
        if(exams[index].testing[0]){
          if(exams[index].testing[0].finishedAt){
            textButton = 'ทำซ้ำ'
            checkOneTimeDone = exams[index].oneTimeDone ? false : true
          }else{
            textButton = 'ทำต่อ'
          }
        } 
        return (
          <Fragment >
            {
              checkOneTimeDone && record.statusInGroup ?
                <Button onClick={e=> {
                  e.stopPropagation()
                  this.onStartExam(index)
                }}
                style={{ height: '38px' }}
                type={'primary'}
                className={styles.row_button_report}
                >{textButton}</Button> : ''
            }
          </Fragment>
        )
      }
    },
    {
      // title: 'report',
      render: (testing,record, index) => {
        const { exams,groupId,group } = this.props
        const { indexRow,isHoverReportButton } = this.state 
        return (
          <Fragment >
            <img alt='report' title={'รายงาน'} onClick={e => {
              e.stopPropagation();this.onReport()
              localStorage.setItem('groupName', group.name)
              this.props.history.push(`/report/exam/${exams[index]._id}/group/${groupId}`,{ cameFrom: 'group', groupName: `${group.name}`,groupId: `${group.id}`})
            }}
            onMouseOver={ e => { e.stopPropagation();this.onHoverReportButton() }}
            onMouseLeave={ e => { e.stopPropagation();this.onNotMouserReportButton() }}
            src={isHoverReportButton && indexRow === index ? ReportIcon : ReportGrayIcon}
            className={styles.row_button_report}
            />
          </Fragment>
        )
      }
    }
  ]

  onNotMouserReportButton = () => { this.setState({ isHoverReportButton: false })}

  onHoverReportButton = () => { this.setState({ isHoverReportButton: true })}

  onHover = (index) => {
    this.setState({
      indexRow: index,
      isHover: true
    })
  }
  onNotHover = (index) => {
    this.setState({
      indexRow: index,
      isHover: false
    })
  }
  onStartExam = async(index) => {
    const { groupId,history,exams,group } = this.props
    history.push(`/group/${groupId}/examset/${exams[index]._id}`
      ,{ cameFrom: 'group', groupId: groupId,groupName: group.name })
  }
  onReport = () => {
    this.props.history.push()
  }
  render(){
    const {exams,groupId, group , auth, history} = this.props   
    if((!group || group.length < 1) && auth.role === 'student') { 
      history.push('/')
      return message.error('เกิดข้อผิดพลาด โปรดตรวจสอบว่าอยู่ในกลุ่มนี้หรือไม่', [4])
    } 

    const srcData = exams.map((item, i) => {
      const menu = [
        {
          attribute: (
            <Button onClick={e=> {
              e.stopPropagation()
              this.onStartExam(i)
            }}
            type={'primary'}
            className={styles.row_button_report}
            style={{ marginRight: 0, width: '100%' }}
            >เริ่มทำ</Button>
          )
        },
        {
          attribute: (
            <div className={styles.start_report_button} 
              onClick={e => {
                e.stopPropagation();this.onReport()
                localStorage.setItem('groupName', group.name )
                this.props.history.push(`/report/exam/${exams[i]._id}/group/${groupId}`, { cameFrom: 'group', groupName: group.name, groupId: group.id } )
              }}
              onMouseOver={ e => { e.stopPropagation(); this.onHoverReportButton() }}
              onMouseLeave={ e => { e.stopPropagation(); this.onNotMouserReportButton() }}
            >
              <img
                alt='report'
                src = { ReportIcon }
                className = { styles.row_button_report }
              /> รายงาน 
            </div>
          )
        }
      ]

      return {
        avatar: item.type === 'G' ? (
          item.subject === 'คณิตศาสตร์' ?
            CompetitionMathIcon : CompetitionScienceIcon
        ) : (
          item.subject === 'คณิตศาสตร์' ?
            IconExamMath : IconExamScience
        ),
        title: item.name,
        description: item.code,
        status: `วันที่สร้าง: ${moment.unix(item.createdAt).format('DD/MM/YYYY')}`,
        actions: menu,
        path: ''
      }
    })

    return(
      <div className='studen-group'>
        <h3>ข้อสอบ</h3>

        <hr style={{ border: '0.5px solid #E0E0E0' }}/>

        <div className='mobile'>
          <ListItems
            srcData={srcData}
            avatar
            status
            history={this.props.history}
          />
        </div>

        <div className='desktop'>
          
          <Table
            rowKey = { (record,i) => i }
            columns = { this.columns }
            dataSource = { exams }
            pagination = { false }
            rowClassName={styles.studentGroupExamTable}
            onRow={(record,rowIndex) => { 
              return {
                onMouseEnter: () => this.onHover(rowIndex),
                onMouseLeave: () => this.onNotHover(rowIndex)
              }
            }}
          />
        </div>
      </div>

    )
  }
}
const mapStateToProps = ( ) => {
  return {
    // exams: state.group.examsInGroup

  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // startTesting
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(StudentGroup)
