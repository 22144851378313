import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  message,
  Spin,
  Tabs,
  Table,
  Button,
  Input, 
  Typography
} from 'antd'
import {
  GetFaqList,
  onEditFaq,
  onUpdateFaq
} from '../../redux/master/action'
import styles from './Faq.module.scss'
import IconAddData from '../IconSVG/icon_faq_add.svg'
import IconSearchData from '../IconSVG/icon_faq_search.svg'
import IconMoveRow from '../IconSVG/IconMoveRow.svg' 
import DeleteGray from '../IconSVG/DeleteGray.svg'
import EditGray from '../IconSVG/EditGray.svg'
import Edit from '../IconSVG/Edit.svg'
import Delete from '../IconSVG/Delete.svg'
import AddFaqComponent from '../FAQ/AddFaqComponent'
import EditFaqComponent from '../FAQ/EditFaqComponent'
import update from 'immutability-helper'
import ModalPrefix from '../FAQ/ModalFaq'
import { DndProvider, DragSource, DropTarget } from 'react-dnd-cjs'
import HTML5Backend from 'react-dnd-html5-backend-cjs'

const { TabPane } = Tabs
const { Paragraph } = Typography

let dragingIndex = -1
class BodyRow extends Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget , ...restProps } = this.props
    const style = { ...restProps.style, cursor: 'move' }

    let { className } = restProps
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward'
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward'
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    )
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index
    return {
      index: props.index,
    }
  },
}

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
)

class ListOfFaq extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      faqDetail:'',
      checkCurrentComponent : 'list',
      value : '',
      indexRow: false,
      checkMouseOverEdit: false,
      checkMouserOverDelete: false,
      modalVisible: false, 
      item: {},
      typeModal: false,
      switchData: { 
        visible: {}
      },
      prefixItems: [],
      dataForEdit : '',
      dataForDelete : '',
      dataFiltered : '',
      typeCurrenUser :'',
      guestList : [],
      teacherList : [],
      studentList : [],
      dataForSearch : [],
      TabActiveKey : 1,
      dataSwitch : [],
      checkEnter : false
    }
  }

  columns = [
    {
      title: '',
      dataIndex: '',
      key: 'drag',
      render:()=> {
        return ( <img alt='icon-move-row' src={IconMoveRow}/>)
      },
      width:'30px'
    },
    {
      title: 'หัวข้อ',
      key : 'title',
      render: data => 
      {
        return(
          <div className={styles.title_list_all} style={{ whiteSpace : 'nowrap', overflow : 'hidden', textOverflow : 'ellipsis'}}>
            <span className={styles.clickable} 
            >
              {data.title ? data.title : '-'}
            </span>
          </div>
        ) 
      },
      width : '10%'
    },
    {
      title: 'คำถาม',
      key : 'question',
      render: data => {
        return(
          <div style={{ whiteSpace : 'nowrap', overflow : 'hidden', textOverflow : 'ellipsis'}}>
            <span className={styles.clickable} 
            >
              {data.question ? data.question : '-'}
            </span>
          </div>
        )
      },
      width : '30%'
    },
    {
      title: 'คำตอบ',
      key: 'answer',
      render: data => {
        return(
          <div style={{ width : '450px', whiteSpace : 'nowrap', overflow : 'hidden', textOverflow : 'ellipsis'}}>
            <span className={styles.clickable} 
            >
              {data.answer ? 
                <Paragraph ellipsis>
                  {data.answer.replace(/(<.*?>|&nbsp;)/gi, '')}
                </Paragraph>
                : '-'}
            </span>
          </div>
        )
      },
      width : '50%'
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      render:(edit,record,index)=> {
        const { checkMouseOverEdit, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkMouseOverEdit: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouseOverEdit: false})
        }
        return (
          <div className={styles.icon_row} onClick={e => {
            e.stopPropagation()
            this.goToEditComponent(record,index+1,'edit')
          }}>
            <img title={'แก้ไข'} onMouseOver={mouserOver}  onMouseLeave={mouseLeave} src={checkMouseOverEdit && indexRow === index ? Edit : EditGray}/></div>
        )
      },
      width: '5%'
    },
    {
      title: '',
      dataIndex: '',
      key: 'delete',
      render:(deleteIcon,record,index)=> {
        const { checkMouserOverDelete, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkMouserOverDelete: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouserOverDelete: false})
        }
        return (
          <div  className={styles.icon_row}
            onClick={e=>{
              e.stopPropagation()
              this.onOpenModal(index,'delete')
            }}
          ><img title={'ลบคำถาม'} onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkMouserOverDelete && indexRow === index ? Delete : DeleteGray}/></div>
        )
      }
    }
  ]

  components = {
    body: {
      row: DragableBodyRow,
    },
  }
  
  componentDidMount = async() =>{
    await this.fetchFaqList()
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.action === nextProps.action){
      this.setState({
        checkCurrentComponent : 'list',
        searchKey : ''
      })
    }
  }

  fetchFaqList = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.GetFaqList()
    this.setState({ 
      isLoading: false,
      guestList : res.guest,
      teacherList: res.teacher,
      studentList: res.student
    })
    if (res.error) message.error(res.error)
  }

  goToEditComponent = () =>{
    this.setState({
      checkCurrentComponent : 'edit',
      dataForEdit : this.state.value
    })
    return(this.state.value)
  }

  setSerchKey = (value) =>{
    const {checkEnter} = this.state
    setTimeout(()=>{
      this.setState({
        searchKey : value
      })
      if(this.state.searchKey === '' && checkEnter === true){
        this.fetchFaqDetail()
        this.setState({
          checkEnter : false
        })
      }
    },50)
  }

  onSearchFaq =(searchKey)=>{
    const {dataForSearch, TabActiveKey} = this.state
    let data = dataForSearch
    if(data){
      data = dataForSearch.filter(user => user.question.toLowerCase().includes(this.state.searchKey.toLowerCase()) == true 
      || user.title.toLowerCase().includes(this.state.searchKey.toLowerCase()) == true
      || user.answer.toLowerCase().includes(this.state.searchKey.toLowerCase()) == true)
    }
    this.setState({
      checkEnter : true
    })
    if(TabActiveKey == 1){
      this.setState({
        faqDetail : data
      },()=>{
        this.guestListTable()
      })
    }else if(TabActiveKey == 2){
      this.setState({
        faqDetail : data
      },()=>{
        this.teacherListTable()
      })
    }else if(TabActiveKey == 3){
      this.setState({
        faqDetail : data
      },()=>{
        this.studentListTable()
      })
    }
    if(searchKey === ''){
      this.fetchFaqDetail()
    }
  }
  
  onOpenAddFaq = () => {
    this.setState({
      checkCurrentComponent : 'add'
    })
  }
  backToHome = async () => {
    this.setState({
      checkCurrentComponent : 'list'
    })
    this.fetchFaqList()
  }
  onOpenModal = (index, type) => {  
    this.setState({
      typeModal: type,
      item: this.state.value,
      modalVisible: true
    })
  } 
  onCloseModal = () => {
    this.setState({
      modalVisible: false
    })
  }

  moveRow = (role) => async(dragIndex, hoverIndex) => {

    let { [`${role}List`]: itemList } = this.state

    const dragRow = itemList[dragIndex]

    itemList = update(itemList, {
      $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
    }).map((item, i) => {
      const index = item.visible.findIndex(vis => vis.role === role)
      item.visible[index].order = i+1
      return item
    })

    this.setState({
      [`${role}List`]: itemList
    })

    await this.props.onUpdateFaq(itemList)
  }

  onCheckTabActiveKey = (key) => { 
    this.setState({
      TabActiveKey: key
    })
  }
  render() {
    const {  modalVisible, item, typeModal, guestList, teacherList, studentList } = this.state  
    return ( 
      <div className={styles.examSetDiv}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          {
            (() =>  {
              switch(this.state.checkCurrentComponent){
                case 'list' :
                  return(
                    <div className='flex-space-between' style={{marginBottom: '30px'}}>
                      <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>FAQ</h1>
                      <div style={{display: 'flex'}}>
                        <Input
                          suffix={
                            <Button type='primary' 
                              style={{paddingLeft: 5, paddingRight: 10}} 
                              onClick={(e) => { this.onSearchFaq(e.target.value) }}
                            >
                              <img src ={IconSearchData} style={{verticalAlign: 'middle'}}/>
                              ค้นหา
                            </Button>}
                          placeholder='ค้นหา'
                          value = {this.state.searchKey}
                          maxLength={100}
                          className={styles.inputSearch}
                          onChange = {(e) => this.setSerchKey(e.target.value)}
                          onPressEnter = {(e) => this.onSearchFaq(e.target.value)}
                        />
                        <Button type='primary' onClick={this.onOpenAddFaq}  >
                          <img src ={IconAddData} style={{ fontSize: '24px', lineHeight: '0', verticalAlign: 'text-bottom' }} /><label>เพิ่มข้อมูล</label>
                        </Button>
                      </div>
                    </div>
                  )
                case 'add' :
                  return(
                    <AddFaqComponent backToHome = {this.backToHome}/>
                  )
                case 'edit' :
                  return(
                    <EditFaqComponent goToEditComponent = {this.goToEditComponent} backToHome = {this.backToHome}/>
                  )
                default : 
                  return(
                    <div></div> 
                  )
              }
            })()
          }
          {
            this.state.checkCurrentComponent === 'list' ?
              <div>
                <Tabs defaultActiveKey='1' onChange={this.onCheckTabActiveKey}>
                  <TabPane tab={`ไม่ใช่สมาชิก ${''}`} key='1' onTabClick = {this.guestListTable}>
                    <DndProvider backend={HTML5Backend}>
                      <Table 
                        rowKey={(record,i) => i}
                        columns={this.columns}
                        dataSource={guestList}
                        pagination={false}
                        components={this.components}
                        scroll={{x:true}}
                        onRow={(record, index) => ({
                          index,
                          moveRow: this.moveRow('guest'),
                          onMouseEnter: ()=> this.setState({ value : record, indexRow: index ,typeCurrenUser : 'guest'})
                        })}
                      /> 
                    </DndProvider> 
                  </TabPane>
                  <TabPane tab={`ครู/ครูพิเศษ ${''}`} key='2' onTabClick = {this.teacherListTable}>
                    <DndProvider backend={HTML5Backend}>
                      <Table 
                        rowKey={(record,i) => i}
                        columns={this.columns}
                        dataSource={teacherList}
                        components={this.components}
                        pagination={false}
                        scroll={{x:true}}
                        onRow={(record, index) => ({
                          index,
                          moveRow: this.moveRow('teacher'),
                          onMouseEnter: ()=> this.setState({value : record, indexRow: index ,typeCurrenUser : 'teacher'})
                        })}
                      />
                    </DndProvider> 
                  </TabPane>
                  <TabPane tab={`นักเรียน ${''}`} key='3' onTabClick = {this.studentListTable}>
                    <DndProvider backend={HTML5Backend}>
                      <Table 
                        rowKey={(record,i) => i}
                        columns={this.columns}
                        dataSource={studentList}
                        components={this.components}
                        pagination={{ simple: true, defaultCurrent: 1, total: studentList.length, position: 'both' }}
                        scroll={{x:true}}
                        onRow={(record, index) => ({
                          index,
                          moveRow: this.moveRow('student'),
                          onMouseEnter: ()=> this.setState({value : record, indexRow: index ,typeCurrenUser : 'student'})
                        })}
                      />
                    </DndProvider> 
                  </TabPane>
                </Tabs>
              </div>   
              :
              ''
          }
          <ModalPrefix fetchFaqList={this.fetchFaqList} typeModal={typeModal} items={item} modalVisible={modalVisible} onCloseModal={this.onCloseModal} backToHome = {this.backToHome}/>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    faqDetail : state.master.faqDetail
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetFaqList,
      onUpdateFaq,
      onEditFaq
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ListOfFaq)