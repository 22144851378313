import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Button,  
  Table, 
} from 'antd'
import CustomIconMath from '../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../IconSVG/CustomIconScience.svg'
import ExerciseIconMath from '../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../IconSVG/ExerciseIconScience.svg'
import CompetitionScienceIcon from '../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../IconSVG/CompetitionMathIcon.svg'
import IconExamMath from '../IconSVG/ExamMath.svg'
import IconExamScience from '../IconSVG/ExamScience.svg'

import ExamMath from '../IconSVG/ExamMath.svg'
import ExamScience from '../IconSVG/ExamScience.svg'
import SearchBar from '../Display/SearchBar'
import styles from './Group.module.scss' 
import { dateFromTimeStamp } from '../../util/helper'

class ModalAddExam extends Component {

  columns = [
    {
      title: ()=>{
        return(
          <p style={{marginLeft : '30px'}}>ชื่อ</p>
        )
      },
      dataIndex: 'name',
      key: 'name', 
      render: (name, record) => {
        let logo = null
        if(record.type === 'G') {
          if(record.subject === 'คณิตศาสตร์') {
            logo = IconExamMath
          }else {
            logo = IconExamScience
          }
        } else if(record.type === 'C'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CompetitionMathIcon
          }else {
            logo = CompetitionScienceIcon
          }
        }else if(record.type === 'CUSTOM'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CustomIconMath
          }else {
            logo = CustomIconScience
          }
        }else {
          if(record.subject === 'คณิตศาสตร์') {
            logo = ExerciseIconMath
          }else {
            logo = ExerciseIconScience
          }
        }
        // const iconSubject = examSet.subject === Subject.MATH ? <img src={ExamMath} /> : <img src={ExamScience}/>
        return (
          <div>
            <div>
              <span><img alt='logo' src={logo} />{name}</span>
            </div>
            <div>
              <span style={{ position: 'absolute', fontSize: '10px', color: '#A3ABB1', borderRadius: '2px', paddingLeft: '31px' }}>{record.code}</span>
            </div>
          </div>
        )
      },
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        let text = type === 'G' ? 'ตามหลักสูตร' : type === 'C' ? 'แข่งขัน' : 'กำหนดเอง'
        return (<span>{text}</span>)
      }
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (createdAt) => {
        return (<span>{dateFromTimeStamp(createdAt)}</span>)
      }
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return (<span>{ status ? 'เปิด' : 'ปิด' }</span>)
      },
      align: 'center'
    },
    {
      title: 'จำนวนข้อ',
      dataIndex: 'questionCount',
      key: 'questionCount',
      align: 'center'
    }
  ]

  paginationConfig = {
    position: 'bottom',
    defaultPageSize: 20
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedRowKeys: [],
      searchKeyword: ''
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    const { onSubmit } = this.props
    const { selectedRowKeys } = this.state 
    await onSubmit(selectedRowKeys)
    this.clearSelected()
  }

  clearSelected = () => {
    this.setState({ selectedRowKeys: [] })
  }

  handleSearch = (value) => {
    this.setState({ searchKeyword: value })
  }

  handleSelectChange = (values) => {
    this.setState({ selectedRowKeys: values })
  }

  render() {
    const { isVisible, exams, onCancel , group } = this.props 
    const { selectedRowKeys, searchKeyword } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange
    } 
    let examsFilters = exams

    if (searchKeyword) {
      examsFilters = exams.filter(exam => exam.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)
    }

    const filters = [
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        render: (name, exam) => {
          return (
            <div className='item'>
              <img src={exam.subject === 'คณิตศาสตร์' ? ExamMath : ExamScience} alt='icon-subject'/>
              <div className='detail'>
                <h4>{exam.name}</h4>
                <p>{exam.code}</p>
              </div>
            </div>
          )
        }
      }
    ]

    return (
      <Modal 
        visible={isVisible}
        closable={true}
        className={styles.addExamModalContent}
        onCancel={onCancel}
        centered
        footer={<div className='flex-space-center'>
          <Button
            key='submit'
            type='primary'
            htmlType='submit'
            style={{maxWidth: '180px', width: '100%'}}
            onClick={this.handleSubmit}
          >
            เพิ่ม {selectedRowKeys.length} ชุดเข้ากลุ่ม
          </Button>
        </div>
        }
      >
        <div className='modal-container'>
          <div className='header' style={{ paddingBottom: '80px' }}>
            <h2 className='text-medium' style={{color: '#05007A'}}>
              {group ? group.name : ''}
            </h2>

            <SearchBar placeholder={'ชุดข้อสอบ'} onSearch={this.handleSearch} />
          </div>

          <div className='content'>
            <div className='mobile'>
              <Table rowSelection={rowSelection}
                style = {{top : 30}}
                columns={filters}
                dataSource={examsFilters}
                pagination={false}
                rowKey='_id'
                showHeader={false}
              />
            </div>

            <div className='desktop' >
              <Table rowSelection={rowSelection}
                columns={this.columns}
                dataSource={examsFilters}
                pagination={this.paginationConfig}
                rowKey='_id'
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

ModalAddExam.propTypes = {
  isVisible: PropTypes.bool.isRequired,
}

export default ModalAddExam
