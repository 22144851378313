import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom' 
import LoginOverview from './LoginOverview'
import LoginDescription from './LoginDescription'

class ReportLogin extends Component {
  state = {
    isLoading: false,
  }

  static defaultProps = {
    collection: []
  }

  componentDidMount () {
    this.loadStats() 
  }
  spinOnLoading = () => { this.setState({ isLoading: true }) }
  spinOffLoading = () => { this.setState({ isLoading: false }) }
  loadStats = async() => { 
    const { year } = this.props  
    this.spinOnLoading()
    await this.props.loadStats('login',year)   
    this.spinOffLoading()
  }

  loadDetail = async(year, month ) => {
    this.spinOnLoading()  
    await this.props.loadStatsDetail('login', year, month )   
    this.spinOffLoading()
  }
  getStatsLoginTransaction = async(year, month) => {
    this.spinOnLoading()
    const res = await this.props.getStatsLoginTransaction(year, month)  
    this.spinOffLoading() 
    return res
  }
  getStatsLoginTransaction2 = async(year, month) => {
    this.spinOnLoading()
    const res2 = await this.props.getStatsLoginTransaction2(year, month)
    this.spinOffLoading() 
    return res2
  }

  render () { 
    return (
      <div>
        <Switch>
          <Route exact
            path='/reportlogin'
            render={() => <LoginOverview  
              loadDetail={this.loadDetail}
              spinOnLoading={this.spinOnLoading}
              spinOffLoading={this.spinOffLoading}
              getTransaction={this.getStatsLoginTransaction}
              getTransaction2={this.getStatsLoginTransaction2}
              {...this.props} />
            }
          />

          <Route exact
            path='/reportlogin/description'
            render={() => <LoginDescription 
              loadDetail={this.loadDetail}
              getTransaction={this.getStatsLoginTransaction}
              getTransaction2={this.getStatsLoginTransaction2}
              spinOnLoading={this.spinOnLoading}
              spinOffLoading={this.spinOffLoading}
              {...this.props} />
            }
          />
        </Switch>
      </div>
    )
  }
}

export default ReportLogin
