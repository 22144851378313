import { FetchCycle, AxiosRequest } from '../fetch'
import Axios from 'axios'

// API =====================================================================
const API_SUBJECTS = '/master/subjects'

// Fetch ===================================================================

const subjectsList       = ()                    => () => AxiosRequest(API_SUBJECTS,'get') 
const switchSubject      = (subjectId,data)      => () => AxiosRequest(`${API_SUBJECTS}/${subjectId}`,'patch',data)
const dragAndDropSubject = (subjectId,seq)       => () => AxiosRequest(`${API_SUBJECTS}/${subjectId}/seq`,'patch',seq)
const createSubject      = (data)                => () => AxiosRequest(API_SUBJECTS,'post',data)
const updateSubject      = (data,subjectId)      => () => AxiosRequest(`${API_SUBJECTS}/${subjectId}`,'patch',data)
const removeSubject      = (subjectId)           => () => AxiosRequest(`${API_SUBJECTS}/${subjectId}`,'delete')
const otimsSubjectList   = () => () => AxiosRequest(`${API_SUBJECTS}/otims`, 'get')

// Action =====================================================================

export const getSubjectsList      = ()               => FetchCycle(subjectsList()) 
export const onSwitchSubject      = (subjectId,data) => FetchCycle(switchSubject(subjectId,data))
export const onDragAndDropSubject = (subjectId,seq)  => FetchCycle(dragAndDropSubject(subjectId,seq))
export const onCreateSubject      = (data)           => FetchCycle(createSubject(data))
export const editSubject          = (data,subjectId) => FetchCycle(updateSubject(data,subjectId))
export const deleteSubject        = (subjectId)      => FetchCycle(removeSubject(subjectId))
export const getOTIMSSubjectList = () => FetchCycle(otimsSubjectList())