import React, { Fragment, useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import './d3plot.css'
import { svg } from 'd3'
// import { RadioButton } from 'primereact/radiobutton'
import { Radio } from 'antd'
import { Button } from 'antd'
import { initial } from 'lodash'


const generateFormat = (f) => {
  let s = f.toString()
  if (s.length == 0) {
    return ".0f"
  }
  else {
    return "." + s + "f"
  }
}

function generatePointLine(answers) {
  const points = []
  const solids = []
  const dotteds = []
  let lastPoint = null
  let lastStyle = null
  
  for(const v of answers) {
    if (Array.isArray(v)) {
      // Found a point
      if (lastPoint) {
        if (lastStyle == 'solid') {
          solids.push({from: lastPoint, to: v})
        }
        else if (lastStyle == 'dotted') {
          dotteds.push({from: lastPoint, to: v})
        }
      }
      points.push(v)
      lastPoint = v
    }
    else {
      lastStyle = v
    }
  }

  return {points, solids, dotteds}
}

function drawPointLine(xScale, yScale, margin, points, solids, dotteds) {
  d3.select('#plot').selectAll('.pointplot').remove()
  d3.select('#plot').selectAll('.solidplot').remove()
  d3.select('#plot').selectAll('.dottedplot').remove()

  for (let i = 0; i < solids.length; i++) {
    d3.select('#plot').append('line')
      .attr('class', 'solidplot')
      .attr('stroke', 'blue')
      .attr('stroke-width', 3)
      .attr('x1', xScale(solids[i].from[0]) + margin.left)
      .attr('y1', yScale(solids[i].from[1]) + margin.top)
      .attr('x2', xScale(solids[i].to[0]) + margin.left)
      .attr('y2', yScale(solids[i].to[1]) + margin.top)
  }

  for (let i = 0; i < dotteds.length; i++) {
    d3.select('#plot').append('line')
      .attr('class', 'dottedplot')
      .attr('stroke', 'blue')
      .attr('stroke-width', 3)
      .attr('stroke-dasharray', ('3, 3'))
      .attr('x1', xScale(dotteds[i].from[0]) + margin.left)
      .attr('y1', yScale(dotteds[i].from[1]) + margin.top)
      .attr('x2', xScale(dotteds[i].to[0]) + margin.left)
      .attr('y2', yScale(dotteds[i].to[1]) + margin.top)
  }

  d3.select('#plot').selectAll('circle').data(points).enter()
    .append('circle')
    .attr('class', 'pointplot')
    .attr('fill', 'blue')
    .attr('cx', function (d) { return xScale(d[0]) + margin.left })
    .attr('cy', function (d) { return yScale(d[1]) + margin.top })
    .attr('r', 5)
}

function drawSolution(xScale, yScale, margin, points, solids, dotteds) {
  for (let i = 0; i < solids.length; i++) {
    d3.select('#plot').append('line')
      .attr('class', 'solidplot')
      .attr('stroke', '#32CD32')
      .attr('stroke-width', 3)
      .attr('x1', xScale(solids[i].from[0]) + margin.left)
      .attr('y1', yScale(solids[i].from[1]) + margin.top)
      .attr('x2', xScale(solids[i].to[0]) + margin.left)
      .attr('y2', yScale(solids[i].to[1]) + margin.top)
  }

  for (let i = 0; i < dotteds.length; i++) {
    d3.select('#plot').append('line')
      .attr('class', 'dottedplot')
      .attr('stroke', '#32CD32')
      .attr('stroke-width', 3)
      .attr('stroke-dasharray', ('3, 3'))
      .attr('x1', xScale(dotteds[i].from[0]) + margin.left)
      .attr('y1', yScale(dotteds[i].from[1]) + margin.top)
      .attr('x2', xScale(dotteds[i].to[0]) + margin.left)
      .attr('y2', yScale(dotteds[i].to[1]) + margin.top)
  }

  for (let i = 0; i < points.length; i++) {
    d3.select('#plot').append('circle')
      .attr('class', 'pointplot')
      .attr('fill', '#32CD32')
      .attr('cx', xScale(points[i][0]) + margin.left)
      .attr('cy', yScale(points[i][1]) + margin.top)
      .attr('r', 5)
  }
}
function formatPower(x) {
  const e = Math.log10(x);
  if (e !== Math.floor(e)) return; // Ignore non-exact power of ten.
  return `10${(e + "").replace(/./g, c => "⁰¹²³⁴⁵⁶⁷⁸⁹"[c] || "⁻")}`;
}

const D3Plot = (props) => {
  const svgRef = useRef(null)
  const [lineStyle, setLineStyle] = useState('solid')
  const [initialStep, setInitialStep] = useState(true)
  const width = parseInt(props.data.x_axis.width)
  const height = parseInt(props.data.y_axis.height)
  let data = []
  const numXTicks = parseInt(props.data.x_axis.grid_count)
  const numYTicks = parseInt(props.data.y_axis.grid_count)
  const xMin = parseFloat(props.data.x_axis.min_value)
  const xMax = parseFloat(props.data.x_axis.max_value)
  const yMin = parseFloat(props.data.y_axis.min_value)
  const yMax = parseFloat(props.data.y_axis.max_value)
  const xFixedDigits = parseInt(props.data.x_axis.fixed_digits)
  const yFixedDigits = parseInt(props.data.y_axis.fixed_digits)
  const xScaleType = props.data.x_axis.scale_type
  const yScaleType = props.data.y_axis.scale_type
  const xTitle = props.data.x_axis.title
  const yTitle = props.data.y_axis.title
  // const answers = props.showSolution ? props.data.answers : props.answer
  // const answers = props.answer
  // console.log(props.data)
  console.log(props.answers)
  const qSolution = props.data.answers ? props.data.answers : []
  const qAnswer = props.answer ? props.answer : []
  const displayState = typeof props.isSolution == 'undefined' ? 0 : (props.isSolution ? 1 : 2)
  // displayState == 0 --> Doing exam
  // displayState == 1 --> Display both answer and solution
  // displayState == 2 --> Display only answer
  const answers = qAnswer
  const disabled = displayState == 0 ? false : true

  var marginRight = 50;
  if (xTitle && xTitle.length > 3) {
    marginRight = 20 + xTitle.length*8
  }
  const margin = { top: 50, right: marginRight, left: 50, bottom: 50 }

  useEffect(() => {
    let chartRef = '#' + props.chartName + " > *"
    d3.selectAll(chartRef).remove()

    // d3.selectAll("#chart > *").remove()
    d3.select(svgRef.current).select('svg').remove()

    const _svg = d3.select(svgRef.current)
      .append('svg')
      .attr('id', 'plot')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .on("contextmenu", function(e) {
        e.preventDefault()
      })

    _svg.append("defs").append("marker")
      .attr("id", "arrowhead")
      .attr("viewBox", "0 0 6 6")
      .attr("refX", 0)
      .attr("refY", 3)
      .attr("markerUnits", "strokeWidth")
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 L 6 3 L 0 6 z")
      .attr("fill", "#000")

    _svg.append("defs").append("marker")
      .attr("id", "arrowheadl")
      .attr("viewBox", "0 0 6 6")
      .attr("refX", 6)
      .attr("refY", 3)
      .attr("markerUnits", "strokeWidth")
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 6 0 L 6 6 L 0 3 z")
      .attr("fill", "#000")
    
    let xScale = null, yScale = null
    var xa, ya
    var xt, yt
    var xAxis, yAxis
    // Draw axes
    if (yScaleType == 'linear') {
      yScale = d3.scaleLinear().domain([yMin, yMax]).range([height, 0])
      const yTickValues = []
      const yStepSize = (yMax - yMin) / numYTicks
      let yVal = parseFloat(yMin)
      for (let i = 0; i <= numYTicks; i++) {
        yTickValues.push(yVal)
        yVal += yStepSize
      }
      yAxis = d3.axisLeft().scale(yScale).tickValues(yTickValues)
        .tickFormat(d3.format(generateFormat(yFixedDigits)))

      yt = (0 - xMin) / (xMax - xMin)
      if (yt < 0) {
        yt = 0
      }
      yt = (width * yt)
      ya = _svg.append('g').attr('transform', 'translate(' + yt + ', 0)').call(yAxis)
    }
    else {
      yScale = d3.scaleLog().domain([yMin, yMax]).range([height, 0])
      yAxis = d3.axisLeft().scale(yScale).ticks(numYTicks, formatPower)
      if (xScaleType == 'linear') {
        yt = (0 - xMin) / (xMax - xMin)
        if (yt < 0) {
          yt = 0
        }
        yt = (width * yt)
        ya = _svg.append('g').attr('transform', 'translate(' + yt + ', 0)').call(yAxis)
      }
      else {
        ya = _svg.append('g').call(yAxis)
      }
    }

    if (xScaleType == 'linear') {
      xScale = d3.scaleLinear().domain([xMin, xMax]).range([0, width])
      const xTickValues = []
      const stepSize = (xMax - xMin) / numXTicks
      let val = xMin
      for (let i = 0; i <= numXTicks; i++) {
        xTickValues.push(val)
        val += stepSize
      }
      xAxis = d3.axisBottom().scale(xScale).tickValues(xTickValues)
        .tickFormat(d3.format(generateFormat(xFixedDigits)))

      xt = (0 - yMin) / (yMax - yMin)
      if (xt < 0) {
        xt = 0
      }
      xt = height - (height * xt)
    }
    else {
      xScale = d3.scaleLog().domain([xMin, xMax]).range([0, width])
      xAxis = d3.axisBottom().scale(xScale).ticks(numXTicks, formatPower)
      if (yScaleType == 'linear') {
        xt = (0 - yMin) / (yMax - yMin)
        if (xt < 0) {
          xt = 0
        }
        xt = height - (height * xt)
      }
      else {
        xt = height 
      }
    }
    _svg.append('g').attr('transform', 'translate(0, ' + xt + ')').call(xAxis)

    const arrowPad = 5
    var startArrow = 'none'
    var x1 = 0
    if (xMin < 0) {
      startArrow = 'url(#arrowheadl)'
      x1 = -arrowPad
    }

    xa = _svg.append('line')
      .style('stroke-width', 2)
      .style('stroke', 'black')
      .attr('x1', x1)
      .attr('y1', xt)
      .attr('x2', width+arrowPad)
      .attr('y2', xt)
      .attr('marker-end', 'url(#arrowhead)')
      .attr('marker-start', startArrow)

    startArrow = 'none'
    var y1 = height
    if (yMin < 0) {
      startArrow = 'url(#arrowheadl)'
      y1 += arrowPad
    }

    ya = _svg.append('line')
      .style('stroke-width', 2)
      .style('stroke', 'black')
      .attr('x1', yt)
      .attr('y1', y1)
      .attr('x2', yt)
      .attr('y2', -arrowPad)
      .attr('marker-end', 'url(#arrowhead)')
      .attr('marker-start', startArrow)

    // Draw grids
    if (xScaleType == 'linear') {
      const xGridScale = d3.scaleLinear().domain([0, numXTicks]).range([0, width])
      const xGrid = d3.axisBottom().scale(xGridScale).ticks(numXTicks).tickSize(-height).tickFormat('')
      _svg.append('g').attr('class', 'grid')
        .attr('transform', 'translate(0, ' + height + ')')
        .call(xGrid)
    }
    else {
      const xGridScale = d3.scaleLog().domain([xMin, xMax]).range([0, width])
      const xGrid = d3.axisBottom().scale(xGridScale).ticks(numXTicks).tickSize(-height).tickFormat('')
      _svg.append('g').attr('class', 'grid')
        .attr('transform', 'translate(0, ' + height + ')')
        .call(xGrid)
    }

    if (yScaleType == 'linear') {
      const yGridScale = d3.scaleLinear().domain([0, numYTicks]).range([height, 0])
      const yGrid = d3.axisLeft().scale(yGridScale).ticks(numYTicks)
        .tickSize(-width).tickFormat('')
      _svg.append('g').attr('class', 'grid')
        .call(yGrid)
    }
    else {
      const yGridScale = d3.scaleLog().domain([yMin, yMax]).range([height, 0])
      const yGrid = d3.axisLeft().scale(yGridScale).ticks(numYTicks)
        .tickSize(-width).tickFormat('')
      _svg.append('g').attr('class', 'grid')
        .call(yGrid)
    }

    // Title
    const titlePad = 15
    
    _svg.append('foreignObject')
      .attr('class', 'node')
      .attr('x', width+arrowPad+titlePad)
      .attr('y', xt-titlePad)
      .attr('width', 500)
      .attr('height', 500)
      .html(xTitle)
      
    _svg.append('foreignObject')
      .attr('class', 'node')
      .attr('x', yt)
      .attr('y', -arrowPad-2*titlePad)
      .attr('width', 500)
      .attr('height', 500)
      .html(yTitle)
  
    if (!disabled) {
      d3.select('#' + props.chartName + '> #plot').on('mousedown touchstart', function (event) {
        event.preventDefault()
        const t = d3.pointers(event, this)
        // console.log(event)
        const button = event.button == 0 ? 'left' : (event.button == 2 ? 'right' : 'unknown')

        const pos = [d3.mean(t, d => d[0]), d3.mean(t, d => d[1])]
        const rx = xScale.invert(pos[0] - margin.left)
        const ry = yScale.invert(pos[1] - margin.top)

        // console.log(rx, ry)
        var ax, ay
        var xTicks, yTicks

        if (xScaleType == 'linear') {
          xTicks = xAxis.tickValues()
        }
        else {
          xTicks = xScale.ticks()
        }

        if (rx <= xTicks[0]) {
          ax = xTicks[0]
        }
        else if (rx >= xTicks[xTicks.length - 1]) {
          ax = xTicks[xTicks.length - 1]
        }
        else {
          for (let i = 0; i < xTicks.length - 1; i++) {
            if (xTicks[i] <= rx && rx <= xTicks[i + 1]) {
              var d1 = Math.abs(xTicks[i] - rx)
              var d2 = Math.abs(xTicks[i + 1] - rx)
              if (d1 < d2) {
                ax = xTicks[i]
              }
              else {
                ax = xTicks[i + 1]
              }
              break
            }
          }
        }

        if (yScaleType == 'linear') {
          yTicks = yAxis.tickValues()
        }
        else {
          yTicks = yScale.ticks()
        }

        if (ry <= yTicks[0]) {
          ay = yTicks[0]
        }
        else if (ry >= yTicks[yTicks.length - 1]) {
          ay = yTicks[yTicks.length - 1]
        }
        else {
          for (let i = 0; i < yTicks.length - 1; i++) {
            if (yTicks[i] <= ry && ry <= yTicks[i + 1]) {
              var d1 = Math.abs(yTicks[i] - ry)
              var d2 = Math.abs(yTicks[i + 1] - ry)
              if (d1 < d2) {
                ay = yTicks[i]
              }
              else {
                ay = yTicks[i + 1]
              }
              break
            }
          }
        }

        if (button == 'left') {
          // data.push([ax, ay])
          var addable = true
          if (answers.length > 0) {
            const p = answers[answers.length-1]
            addable = (ax >= p[0])
            if (addable)
              answers.push(lineStyle)
          }
          if (addable)
            answers.push([ax, ay])
        }
        else if (button == 'right') {
          var i
          for (i = 0; i < answers.length; i++) {
            if (Array.isArray(answers[i])) {
              let dx = answers[i][0]
              let dy = answers[i][1]
              if (Math.abs(dx - ax) < 1e-10 && Math.abs(dy - ay) < 1e-10) {
                break
              }
            }
          }
          if (i == 0) {
            answers.splice(i, 2)
          }
          else if (i == answers.length - 1) {
            answers.splice(i - 1, 2)
          }
          else if (i < answers.length) {
            answers.splice(i, 2)
          }
        }
        props.onChange(answers)

        var { points, solids, dotteds } = generatePointLine(answers)
        drawPointLine(xScale, yScale, margin, points, solids, dotteds)

        // d3.select('#plot').selectAll('.pointplot').remove()
        // d3.select('#plot').selectAll('circle').data(data).enter()
        //   .append('circle')
        //   .attr('class', 'pointplot')
        //   .attr('cx', function (d) { return xScale(d[0]) + margin.left })
        //   .attr('cy', function (d) { return yScale(d[1]) + margin.top })
        //   .attr('r', 4)

      })
    }

    if (!disabled) {
      var { points, solids, dotteds } = generatePointLine(answers)
      drawPointLine(xScale, yScale, margin, points, solids, dotteds)
    }
    else {
      var { points, solids, dotteds } = generatePointLine(qAnswer)
      drawPointLine(xScale, yScale, margin, points, solids, dotteds)
      if (displayState == 1) {
        let { points, solids, dotteds } = generatePointLine(qSolution)
        drawSolution(xScale, yScale, margin, points, solids, dotteds)
      }
    }
  })
  // const w = width + margin.left + margin.right
  // const h = height + margin.top + margin.bottom

  return (
    <Fragment>
      <div id="chartControl" style={{border: "solid 1px black", margin: "5px auto", 
        width: "600px", padding: "5px", lineHeight: 1.7}}>
        <div>
          คลิกซ้ายเมื่อต้องการเพิ่มจุด และคลิกขวาเมื่อต้องการลบจุด 
          หรือ <Button type="danger" 
            style={{fontSize: "0.8rem"}}
            onClick={e => {
              answers.splice(0, answers.length)
              d3.select('#plot').selectAll('.pointplot').remove()
              d3.select('#plot').selectAll('.solidplot').remove()
              d3.select('#plot').selectAll('.dottedplot').remove()
            }}
            disabled={disabled}
          >ลบทั้งหมด</Button>
        </div>
        <div className=''>
          เลือกรูปแบบเส้นสำหรับเชื่อมต่อกับจุดก่อนหน้า
          <span style={{ marginLeft: "5px" }}>
            <Radio.Group onChange={(e) => setLineStyle(e.target.value)} 
              disabled={disabled}
              value={lineStyle}>
              <Radio value={'none'}>ไม่ลากเส้น</Radio>
              <Radio value={'solid'}>เส้นทึบ</Radio>
              <Radio value={'dotted'}>เส้นประ</Radio>
            </Radio.Group>
          </span>
        </div>
        <div className=''>
          กำหนดจุดเพื่อลากเส้นจากซ้ายไปขวาเท่านั้น
        </div>
      </div>
      {displayState == 1 && (
      <div style={{border: "solid 1px black", margin: "5px auto", 
        width: "600px", padding: "5px", lineHeight: 1.7}}>
        <div className=''>
          เส้น<span style={{color: 'blue'}}>สีน้ำเงิน</span>แสดงคำตอบของนักเรียน
          เส้น<span style={{color: '#32CD32'}}>สีเขียว</span>แสดงเฉลย
        </div>
      </div>
      )}
      <div id={props.chartName} ref={svgRef} />
    </Fragment>
  )

}

export default D3Plot