import React, { Component }  from 'react'
import styles from './Testing.module.scss'
import { 
  Badge
}from 'antd'
import CorrectIcon from '../IconSVG/Correct.svg'
import IncorrectIcon from '../IconSVG/Incorrect.svg'
import { displayer } from './helper'

class MatchOptionChoice extends Component {
  constructor(props){
    super(props)
    this.state = {
      // selectedOption: -1
    }
  }

  onClickOption = () => {
    const { onClickThisChoice, questionIndex, side } = this.props
    const { selectedOption } = this.state
    onClickThisChoice(questionIndex, side)
    if(selectedOption === '#E0E0E0'){
      this.setState({
        selectedOption: 'white'
      })
    }else{
      this.setState({
        selectedOption: '#E0E0E0'
      })
    }
  }

  render(){
    const { option, selectedOptions, side, solutions, isSolution, correctAns = false, isExample=false, isMA=false } = this.props
    const selectedOptionIndex = selectedOptions.indexOf(option.seq)
    const selectedSeq = selectedOptionIndex+1  
    return (
      <div className='match-option-choice'>
        { isSolution && side === 'left' && (
          (
            (selectedOptionIndex > -1 && parseInt(option.match) === solutions[selectedOptionIndex]) || correctAns ?
              <img alt='correct-icon' src={CorrectIcon} width={'60%'} height={'60%'} />
              : isExample && isMA ? '' : <img alt='incorrect-icon' src={IncorrectIcon} width={'60%'} height={'60%'} />
          )
        )}

        <p onClick={()=> isSolution ? '' : this.props.onClickOption(option.seq) } 
          style={ selectedOptionIndex > -1 && !correctAns ? { border:'2px solid #F7941D', background: '#FFFAF4' } : {border:'2px solid #BDBDBD' } } 
          className={styles.choice_test}>
          <span className='badge' style={{ opacity: selectedOptionIndex > -1 && !correctAns ? 1 : 0 }}>
            <Badge count={selectedSeq} style={{ backgroundColor: '#BDBDBD',zIndex: 1}}/>
          </span>
          {displayer(option.text)}
        </p> 
      </div>
    )
  }
}
export default MatchOptionChoice
