import React, { Component } from 'react'
import {
  Table,
  Switch,
  Modal,
  Icon,
  message
} from 'antd'
import CustomIconMath from '../../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../../IconSVG/CustomIconScience.svg'
import ExerciseIconMath from '../../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../../IconSVG/ExerciseIconScience.svg'
import CompetitionScienceIcon from '../../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../../IconSVG/CompetitionMathIcon.svg'
import IconExamMath from '../../IconSVG/ExamMath.svg'
import IconExamScience from '../../IconSVG/ExamScience.svg'
 
import ExamWarning from '../../IconSVG/ExamWarning.svg'
import IconDelete from '../../IconSVG/Delete.svg'
import IconDeleteGray from '../../IconSVG/DeleteGray.svg'
import IconReport from '../../IconSVG/Report.svg'
import IconReportGray from '../../IconSVG/ReportGray.svg'
import IconMathExam from '../../IconSVG/ExamMath.svg'
import IconScienceExam from '../../IconSVG/ExamScience.svg'
import { dateFromTimeStamp } from '../../../util/helper'
import ListItems from '../../Display/ListItems'
import styles from '../Group.module.scss'
import './GroupDetail.scss'

class DetailExam extends Component {

  constructor(props) {
    super(props)
    this.state = {
      exams: [],
      exam: {},
      checkReportIcon: false,
      checkDeleteIcon: false,
      indexRow: null
    }
  }

  columns = [
    {
      title: 'รหัสข้อสอบ',
      dataIndex: 'code',
      align: 'center'
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        let logo = null
        if(record.type === 'G') {
          if(record.subject === 'คณิตศาสตร์') {
            logo = IconExamMath
          }else {
            logo = IconExamScience
          }
        } else if(record.type === 'C'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CompetitionMathIcon
          }else {
            logo = CompetitionScienceIcon
          }
        }else if(record.type === 'CUSTOM'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CustomIconMath
          }else {
            logo = CustomIconScience
          }
        }else {
          if(record.subject === 'คณิตศาสตร์') {
            logo = ExerciseIconMath
          }else {
            logo = ExerciseIconScience
          }
        }
        const iconSubject = <img alt='วิชา' src={logo}/>
        return (<span>{iconSubject} {name}</span>)
      },
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      render: (type) => {
        switch (type) {
          case 'G' : return 'ตามหลักสูตร'
          case 'C' : return 'แข่งขัน'
          case 'CUSTOM' : return 'กำหนดเอง'
          case 'EX': return 'แบบฝึกหัด'
          case 'E' : return 'แบบฝึกหัด'
          case 'CAT': return 'CAT'
          default : return '-'
        } 
      },
    },
    {
      title: 'วันที่แชร์',
      dataIndex: 'addedAt',
      render: (addedAt) => dateFromTimeStamp(addedAt),
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      render: (status) => {
        return status ? 'เปิดใช้งาน' : 'ปิดการใช้งาน'
      },
    },
    {
      title: 'การใช้งานภายในกลุ่ม',
      dataIndex: 'statusInGroup',
      align: 'center',
      render: (statusInGroup, exam) => { 
        return (
          <Switch  checkedChildren='เปิด' unCheckedChildren='ปิด' checked={statusInGroup} className={styles.switch} 
            onChange={(value,e) => { 
              e.stopPropagation()
              if(!exam.status && !statusInGroup) {
                message.error('ไม่สามารถเปิดการใช้งานได้ เนื่องจากข้อสอบถูกปิดการใช้งานจากหน้าข้อสอบของฉัน')
              } else { 
                this.props.onUpdateExam(exam._id, { status: value})
              }
            }} />
        )
      },
    },
    {
      title: '',
      key: 'action',
      render: (text, record,index) => {
        const { auth, group } = this.props
        const { indexRow,checkReportIcon } = this.state
        const mouseHover = () => { this.setState({ checkReportIcon: true })}
        const mouseLeave = () => { this.setState({ checkReportIcon: false })} 
        return (
          <img alt='รายงาน' title={'รายงาน'} onClick={e => { e.stopPropagation()
            auth.role === 'student' ? this.props.history.push(`/report/${record._id}`) : this.props.history.push(`/report/${record._id}/group/${group.id}`, { cameFrom: 'group', groupName: `${group.name}`,groupId: `${group.id}`})
          }} onMouseEnter={mouseHover} onMouseLeave={mouseLeave} src={checkReportIcon && indexRow === index ? IconReport : IconReportGray} style={{ cursor: 'pointer' }}/>
        )
      }, 
    },
    {
      key: 'deleteExam',
      render: (deleteExam,record,index) => {
        const { indexRow,checkDeleteIcon } = this.state
        const mouseHover = () => { this.setState({ checkDeleteIcon: true })}
        const mouseLeave = () => { this.setState({ checkDeleteIcon: false })}
        return (
          <img alt='ลบข้อสอบ' title={'ลบข้อสอบ'} onClick={e => {
            e.stopPropagation(); this.onRemoveExam(record)
          }} onMouseEnter={mouseHover} onMouseLeave={mouseLeave} src={checkDeleteIcon && indexRow === index ? IconDelete : IconDeleteGray} style={{ cursor: 'pointer' }}/>
        ) 
      }
    }
  ]

  onRemoveExam = (exam) => {
    const modal = Modal.confirm({
      centered: true,
      okText: 'ลบข้อสอบ',
      cancelText: 'ยกเลิก',
      width: 580,
      icon: null,
      title: (
        <button aria-label='Close' className='ant-modal-close' style={{display: 'block'}} onClick={()=> modal.destroy()}>
          <Icon type='close' className='ant-modal-close-x'/>
        </button>
      ),
      content: (
        <div style={{textAlign: 'center'}}>
          <img src={ExamWarning} alt='logo' style={{borderRadius: '50%', width: 170, height: 170, marginBottom: 30}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>ลบข้อสอบ</h2>
          <p className='text-medium' style={{ marginBottom: '0px' }}>{exam.name}</p>
          <p className='text-medium' style={{ marginBottpm: '16px', fontSize: '14px', color: '#BDBBBB' }}>{exam.code}</p>
          <p className='text-medium' style={{ marginBottom: '0px' }}>คุณต้องการลบชุดข้อสอบนี้หรือไม่</p>
        </div>
      ),
      onOk: async () => {
        await this.props.onRemoveExam(exam._id)
        modal.destroy()
      },
    })
  }

  render() {
    const { exams , auth, group } = this.props 
    const srcData = exams.map((item) => {
      const status = item.status
      const subject = item.subject
      const menu = [
        {
          attribute: (
            <span onClick={ e => {
              e.stopPropagation()
              auth.role === 'student' ? this.props.history.push(`/report/${item._id}`) : this.props.history.push(`/report/${item._id}/group/${group.id}` ,{ cameFrom: 'group', groupName: `${group.name}`,groupId: `${group.id}`})
            }} className='actionIcon'>
              <img alt='รายงาน' src={IconReport} className='anticon' /> รายงานผลสอบ
            </span>
          )
        },
        {
          attribute: (
            <span onClick={e => {
              e.stopPropagation()
              this.onRemoveExam(item)
            }} className='actionIcon'>
              <img alt='ลบ' src={IconDelete} className='anticon' /> ลบ
            </span>
          )
        },
        {
          attribute: (
            <span className={styles.tableAction}>
              สถานะ
              <Switch checkedChildren='เปิด'
                unCheckedChildren='ปิด'
                defaultChecked={item.status}
                className={styles.switch}
                style={{ float: 'right' }}
                onChange={(value) => this.props.onUpdateExam(item._id, { status: value})}
              />
            </span>
          )
        }
      ]

      return {
        avatar: subject === 'คณิตศาสตร์' ? IconMathExam : IconScienceExam  ,
        title: item.name,
        description: item.code,
        status: status ? 'เปิดใช้งาน' : 'ยังไม่เปิดใช้งาน',
        actions: menu,
        path: false
      }
    })

    return (
      <div className='group-exam'>
        <div className='mobile'>
          <ListItems
            srcData={srcData}
            avatar
            status
            history={this.props.history}
          />
        </div>

        <div className='desktop'>
          <Table
            rowSelection={this.rowSelection}
            columns={this.columns}
            dataSource={exams}
            pagination={false}
            rowKey= { (record,i) => i }
            onRow = {(record,index) => {  
              return {
                onMouseEnter: () => { this.setState({ indexRow: index })},
                onClick: () => { this.props.history.push(`/group/${group.id}/examset/${record._id}`) }
              }
            }}
          />
        </div>
      </div>
    )
  }
}

export default DetailExam
