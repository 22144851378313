import { SET_TEACHER_LIST, SET_STUDENT_LIST, SET_SUPER_TEACHER_LIST, SET_ADMIN_LIST } from './action'

const defaultListPaginateData = {
  page: 1,
  lastPage: 1,
  totalCount: 0,
  items: []
}

const defaultState = {
  teacherList: defaultListPaginateData,
  studentList: defaultListPaginateData,
  superTeacherList: defaultListPaginateData,
  adminList: defaultListPaginateData, 
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_TEACHER_LIST:
      return {
        ...state,
        teacherList: action.teacherList,
      }
    case SET_STUDENT_LIST:
      return {
        ...state,
        studentList: action.studentList,
      }
    case SET_SUPER_TEACHER_LIST:
      return {
        ...state,
        superTeacherList: action.superTeacherList,
      }
    case SET_ADMIN_LIST:
      return {
        ...state,
        adminList: action.adminList,
      } 
    default: return state
  }
}  