/* eslint-disable no-irregular-whitespace */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import {
  onSchoolBySearch
} from '../../redux/master/action'
import {
  getSchoolList,
  getSearchSchoolById
} from '../../redux/school/action'
import {
  Button,
  Form,
  Row,
  Col,
} from 'antd'
import { generateSelectForm, generateInputForm, generateAutoCompleteForm } from '../../util/formGenerator'
import styles from './Register.module.scss'
import AvatarBase64 from './AvatarBase64'
import { IconEdit } from '../../components/IconSVG'
import { removeUndefinedProp } from '../../util/helper'
import { USER_ROLE } from '../../util/config'
import ModalEditAddress from './ModalEditAddress'

const FormItem = Form.Item

const ROLE_STUDENT = USER_ROLE.STUDENT
const ROLE_TEACHER = USER_ROLE.TEACHER

const addressFields = ['addressNo','villageNo','lane','road','subDistrict','district','province','postalCode','department']

const formItemLayout = {
  colon: false,
  labelAlign: 'left',
  hideRequiredMark: true,
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
}

class FormNewProfile extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isFormDirty: false,
      profileImage: null,
      isNewSchool: false,
      schoolAddress: null,
      schoolDepartment: null,
      isEditAddress: false,
      editType: 'address', 
      searchProvince: [],
      isShowInputDepartment: false,
      schoolsState: [],  
      typing:'',
      currentSchoolDetail: {
        addressNo: null,
        villageNo: null,
        lane:null,
        road: null,
        subDistrict:null,
        district: null,
        province: null,
        postalCode: null,
        department: null
      },
      selectedSchool: ''
    }
  }
  componentDidMount = () => {
    this.setState({ searchProvince: this.props.provinces, schoolsState: this.props.schools })
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if(this.props.provinces !== nextProps.provinces) {
      this.setState({ searchProvince: nextProps.provinces })
    }
  }
  handleSubmit = (e) => {   
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {  
      this.setState({ isFormDirty: true })  
      if(values.other) {
        values.department = values.other 
      }
      if(values.province === 'กรุงเทพมหานคร') {
        values.province = '5d1a294eccac38a7a2c2fb3d'
      }
      delete values.other  
      if (!err || (values.department && values.department !== 'อื่นๆ') ) {  
        const params = {
          ...values,
          profileImage: this.state.profileImage,
        }
        removeUndefinedProp(params)  
        if(params && params.firstName !== null && params.lastName !== null  ){ 
          this.props.onSubmit(params)
        } 
      } 
    })
  }

  onAvatarChange = (imageBase64) => {
    this.setState({ profileImage: imageBase64 })
  } 

  onSchoolChange =  async(schoolName) => {       
    const res = await this.props.getSchoolList(schoolName,1)    
    const existedSchool = res.items.find((school) => schoolName === school.name)   
    await this.props.onUpdateSchoolDetail(existedSchool && existedSchool._id) 
    const isNewSchool = existedSchool ? false : true
    this.setState({
      isNewSchool,
      isFormDirty: false, 
      currentSchoolDetail: existedSchool
    })  
  }

  onSchoolChangeId = async(schoolId) => {  
    const existedSchool = await this.props.getSearchSchoolById(schoolId,1)   
    await this.props.onUpdateSchoolDetail(existedSchool && existedSchool._id) 
    const isNewSchool = existedSchool ? false : true
    this.setState({
      isNewSchool,
      isFormDirty: false, 
      currentSchoolDetail: existedSchool
    }) 
  }

  onTyping = (value) => { 
    this.setState({ typing: value })
  }

  searchSchool = _.debounce(async(text) => { 
    const { onSchoolBySearch } = this.props 
    const res = await onSchoolBySearch(text)    
    this.setState({ 
      schoolsState: res.items, 
    }) 
  },1000)
    
  updateSchoolAddressField = async(schoolDetail) => {   
    if (schoolDetail) {   
      const { addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department } = schoolDetail 
      const matchProvince = this.props.provinces.find( p => province === p.id )
      this.setState({ 
        schoolAddress: `${addressNo ? addressNo : ''} ${villageNo ? ` หมู่ ${villageNo}` : ''} ${lane ? `ซ. ${lane}` : ''} ${road ? `ถ​. ${road}` : ''} 
        ${subDistrict ? matchProvince.name === 'กรุงเทพมหานคร' ? `เขต ${subDistrict}` : `อำเภอ ${subDistrict}` : ''} ${district ? matchProvince.name === 'กรุงเทพมหานคร' ? `แขวง ${district}` : `ตำบล ${district}` : ''} ${matchProvince && matchProvince.name ? `จังหวัด ${matchProvince.name}` : ''} ${postalCode ? postalCode : ''}`,
        schoolDepartment: department,
        currentSchoolDetail: schoolDetail
      }) 
      
      this.props.form.setFieldsValue({ addressNo, villageNo, lane, road, subDistrict, district, province: matchProvince.id, postalCode, department })
      
    } else { 
      this.setState({
        schoolAddress: null,
        schoolDepartment: null,
      })
      this.props.form.resetFields(addressFields)
    }
  }

  componentDidUpdate(prevProps) {
    // watch schoolDetail change
    if (this.props.schoolDetail !== prevProps.schoolDetail) {
      this.updateSchoolAddressField(this.props.schoolDetail) 
    } 
  }

  onOpenEditAddress = (editType) => { 
    // const currentSchoolDetail = this.props.form.getFieldsValue(addressFields)
    const { currentSchoolDetail }  = this.state   
    this.setState({isEditAddress: true, editType, currentSchoolDetail})
  }

  onCloseEditAddress = () => this.setState({isEditAddress: false})

  onSubmitNewAddress = (newData) => { 
    // const currentSchoolDetail = this.props.form.getFieldsValue(addressFields)
    const {currentSchoolDetail} = this.state  
    const data = {
      ...currentSchoolDetail,
      ...newData,
    } 
    this.updateSchoolAddressField(data)
    this.onCloseEditAddress()
  }
  
  getPrefixesSpecificRole = () => {
    const { prefixes, role } = this.props  
    return prefixes.filter(prefixes => prefixes.visible[role])
  }

  onSelectDepartment = (value) => { 
    if(value === 'อื่นๆ') {
      this.setState({ isShowInputDepartment : true })
    }else {
      this.setState({ isShowInputDepartment : false })
    }
  } 

  onSelectBirthYear = (value) => {
    // console.log(value)
    this.setState({ birthYear: value })
    this.props.onUpdateBirthYear(value)
  }
  render() {
    const { form , departments, schoolDetail, role } = this.props     
    const { isShowInputDepartment, schoolsState, selectedSchool } = this.state  
    let departmentInput = departments.slice(0) || []
    departmentInput.push({ id: 'other', name: 'อื่นๆ', visible: null}) 
    const { searchProvince } = this.state
    const isFormDirty = this.state.isFormDirty 
    const isTeacher = role === ROLE_TEACHER
    const isRequiredForTeacher = isTeacher 
    const isNewSchool = this.state.isNewSchool 
    const selectPrefix = generateSelectForm({ options: this.getPrefixesSpecificRole(), fieldName: 'prefixName', form, label: 'คำนำหน้า', isFormDirty, isRequire: true, initialValue: null, nameAsValue: true, isRegister:true, placeholder:'เลือกคำนำหน้า' })
    // const selectGender = isTeacher ? generateSelectForm({ options: genders, fieldName: 'gender', form, label: 'เพศ', isFormDirty, isRequire: isRequiredForTeacher, initialValue: null, nameAsValue: true, freeInput: false, placeholder: 'กรุณาระบุ' }) : null

    const inputFirstName = generateInputForm({ fieldName: 'firstName', form, label: 'ชื่อ', isFormDirty, isRequire: true, isRegister:true })
    const inputLastName = generateInputForm({ fieldName: 'lastName', form, label: 'นามสกุล', isFormDirty, isRequire: true, isRegister:true })
    
    // const selectSchoolName = generateSelectForm({ options: schools, fieldName: 'schoolName', form, label: isTeacher ? 'โรงเรียน/หน่วยงานที่สังกัด' : 'โรงเรียน', isFormDirty, isRequire: true, initialValue: null, nameAsValue: true, freeInput: true, placeholder: 'กรุณาเลือกโรงเรียน', handleSelectChange: this.onSchoolChange, isRegister:true, searchSchool: this.searchSchool  })
    
    const selectSchoolName = generateAutoCompleteForm({ options: schoolsState, fieldName: 'schoolName', form, label: isTeacher ? 'โรงเรียน/หน่วยงานที่สังกัด' : 'โรงเรียน', isFormDirty, isRequire: true, initialValue: null, nameAsValue: false, freeInput: true, placeholder: 'กรุณาเลือกโรงเรียน', handleSelectChange: this.onSchoolChange, isRegister:true, searchSchool: this.searchSchool, onTyping: this.onTyping, typing: this.state.typing, onSchoolChangeId: this.onSchoolChangeId, value: selectedSchool})
     
    const inputAddressNo = generateInputForm({ fieldName: 'addressNo', form, label: 'เลขที่', isFormDirty, isRequire: isNewSchool && isRequiredForTeacher, initialValue: null, limit: 10,isRegister:true })
    const inputVillageNo = generateInputForm({ fieldName: 'villageNo', form, label: 'หมู่ที่ (ไม่บังคับ)', isFormDirty, isRequire: false, initialValue: null, limit: 10,isRegister:true })
    const inputLane = generateInputForm({ fieldName: 'lane', form, label: 'ซอย (ไม่บังคับ)', isFormDirty, isRequire: false,isRegister:true })
    const inputRoad = generateInputForm({ fieldName: 'road', form, label: 'ถนน (ไม่บังคับ)', isFormDirty, isRequire: false,isRegister:true })
    const inputSubDistrict = generateInputForm({ fieldName: 'subDistrict', form, label: 'เขต/อำเภอ', isFormDirty, isRequire: isNewSchool && isRequiredForTeacher,isRegister:true })
    const inputDistrict = generateInputForm({ fieldName: 'district', form, label: 'แขวง/ตำบล', isFormDirty, isRequire: isNewSchool && isRequiredForTeacher,isRegister:true })
    
    const selectProvince = generateSelectForm({ options: searchProvince, fieldName: 'province', form, label: 'จังหวัด', isFormDirty, isRequire: isNewSchool || !isRequiredForTeacher, initialValue: null, nameAsValue: false, freeInput: false, placeholder: 'กรุณาเลือก',isRegister:true, handleSelectChange: this.onSelectProvince })
  
    // dev/ots2023
    const today = new Date()
    const thisBEYear = today.getFullYear() + 543
    const maxAge = 70
    const birthYearOptions = [...Array(maxAge).keys()]
      .map(y => (-1)*y + thisBEYear)
      .map(y => ({ id: y, name: y }))
    const selectBirthYear = generateSelectForm({ options: birthYearOptions, 
      fieldName: 'birthYear', form, label: 'ปี พ.ศ. เกิด',
      isFormDirty, isRequire: true, initialValue: null,
      nameAsValue: false, freeInput: false, placeholder: 'กรุณาระบุปี พ.ศ. เกิด',
      isRegister: true, handleSelectChange: this.onSelectBirthYear
    })
    
    const inputPostalCode = generateInputForm({ fieldName: 'postalCode', form, label: 'รหัสไปรษณีย์', isFormDirty, 
      // isRequire: isStudent ? false : isNewSchool && isRequiredForTeacher 
      isRequire: false
      ,isRegister:true, customRules: [
        { required: false, pattern: /^[0-9]{5}$/ , message: 'กรุณาใส่รหัสไปรษณีย์ให้ครบ 5 หลัก' } ]})
    
    const selectDepartment = generateSelectForm({ options: departmentInput, fieldName: 'department', form, label: 'สังกัดของโรงเรียน/หน่วยงาน', isFormDirty, isRequire: isNewSchool && isRequiredForTeacher, initialValue: null, nameAsValue: true, freeInput: true, placeholder: 'กรุณาเลือก',isRegister:true, handleSelectChange: this.onSelectDepartment  })

    const inputDepartment = generateInputForm({ fieldName: 'other', form, label: 'สังกัดของโรงเรียน/หน่วยงาน อื่นๆ', isFormDirty , isRequire: isNewSchool && isRequiredForTeacher, initialValue: null, nameAsValue: true, isRegister: true})

    const AddressFields = [
      (<Row gutter={10} key='inputAddressNo'>
        <Col span={12}>
          { inputAddressNo }
        </Col>
        <Col span={12} key='inputVillageNo'>
          { inputVillageNo }
        </Col>
      </Row>),
      inputLane,
      inputRoad,
      inputDistrict,
      inputSubDistrict,
      selectProvince,
      inputPostalCode,
      selectDepartment,
      isShowInputDepartment ? inputDepartment : null
    ]

    // manage different col
    // const teacherCol = [5, 9, 10]
    const studentCol = [24, 24, 24]
    const customPrefixColWidth = studentCol // isTeacher ? teacherCol : studentCol

    const schoolDetailField = schoolDetail ? [
      <FormItem key='school_address'>
        <label className='ant-form-item-required ant-form-item-no-colon text-regular' title='ที่อยู่'>ที่อยู่</label>
        <p className='text-link text-thin' style={{ float: 'right' }} onClick={()=>this.onOpenEditAddress('address')} ><IconEdit style={{ marginRight: 3 }}/>แจ้งแก้ไข</p>
        <p style={{ lineHeight: '26px', color: '#4F4F4F' }}>{this.state.schoolAddress || '-'}</p>
      </FormItem>,
      <FormItem key='school_department'>
        <label className='ant-form-item-required ant-form-item-no-colon text-regular' title='ที่อยู่'>สังกัดของโรงเรียน/หน่วยงาน</label>
        <p className='text-link text-thin' style={{ float: 'right' }} onClick={()=>this.onOpenEditAddress('department')} ><IconEdit style={{ marginRight: 3 }}/>แจ้งแก้ไข</p>
        <p style={{ lineHeight: '26px', color: '#4F4F4F' }}>{this.state.schoolDepartment || '-'}</p>
      </FormItem>
    ] : null
    
    return (
      <Fragment>

        <div className='flex-space-between' style={{ marginBottom: 15 }}>
          <h2 className={`${styles.formHead} text-medium mb-0`}>ข้อมูลผู้ใช้</h2>
        </div>
        <div style={{textAlign: 'center', marginBottom: 20}}>
          <AvatarBase64 onChange={ this.onAvatarChange }/>
        </div>

        <Form {...formItemLayout} onSubmit={this.handleSubmit} >
          <Row gutter={10}>
            <Col lg={customPrefixColWidth[0]} md={24}>
              { selectPrefix }
            </Col>
            <Col lg={customPrefixColWidth[1]} md={24}>
              { inputFirstName }
            </Col>
            <Col lg={customPrefixColWidth[2]} md={24}>
              { inputLastName }
            </Col>
          </Row>
          {/* { selectGender } */}
          { !isTeacher && selectBirthYear }
          { selectSchoolName }
          { isTeacher ? ( this.state.isNewSchool ? AddressFields : schoolDetailField ) : selectProvince }
          <span style = {{color: 'red' , fontSize: '16px'}}>* จำเป็นต้องกรอก</span>
          <Button type='primary' htmlType='submit' style={{ width: '100%', marginTop: 10 }}>
            ถัดไป
          </Button>
        </Form>

        <ModalEditAddress
          provinces={this.props.provinces}
          departments={this.props.departments}
          onSubmit={this.onSubmitNewAddress}
          onCancel={this.onCloseEditAddress}
          isEditAddress={this.state.isEditAddress}
          editType={this.state.editType}
          initialSchoolDetail={this.state.currentSchoolDetail}
        />
      </Fragment>
    )
  }
}

FormNewProfile.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onUpdateSchoolDetail: PropTypes.func.isRequired, // request fetch school detail
  prefixes: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  schools: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  schoolDetail: PropTypes.object, // school detail
  role: PropTypes.oneOf([ROLE_STUDENT, ROLE_TEACHER]).isRequired,
}
 
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onSchoolBySearch,
      getSchoolList,
      getSearchSchoolById, 
    },
    dispatch
  )

const WrappedFormNewProfile = Form.create({ name: 'form_new_profile' })(FormNewProfile)
export default connect(null,mapDispatchToProps)(WrappedFormNewProfile)
