import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Radar } from 'react-chartjs-2'
import styles from './Performance.module.scss'
import {
  GetPerformance
} from '../../redux/auth/action'
import * as _ from 'lodash'
import { Select, Form } from 'antd'

const { Option } = Select

class Performance extends Component {

  constructor(props) {
    super(props)
    this.state = {
      performance: null,
      learningAreas: null,
      keyStages: null,
      dataTypes: null,
      selectedLearningArea: [],
      selectedKeyStage: [],
      selectedDataType: [],
      chartLabels: [],
      chartData: [],
    }
  }

  componentDidMount = () => {
    this.fetchPerformance()
  }

  fetchPerformance = async() => {
    const { GetPerformance } = this.props
    const performance = await GetPerformance()
    const learningAreas = _.keys(performance.data)
    this.setState({performance, learningAreas})
  }

  onLearningAreaChange = (e) => {
    const { performance } = this.state
    const selectedLearningArea = e
    const keyStages = _.keys(performance.data[e])
    const selectedKeyStage = []
    const dataTypes = null
    const selectedDataType = []
    const chartLabels = []
    const chartData = []
    this.setState({ keyStages, selectedLearningArea, selectedKeyStage, dataTypes, selectedDataType, chartLabels, chartData })
  }

  onKeyStageChange = (e) => {
    const { performance, selectedLearningArea } = this.state
    const selectedKeyStage = e
    const s = performance.data[selectedLearningArea][selectedKeyStage]
    const dataTypes = [... new Set(s.map((obj) => (obj.topictype)))]
    const selectedDataType = []
    const chartLabels = []
    const chartData = []
    this.setState({ selectedKeyStage, dataTypes, selectedDataType, chartLabels, chartData })
  }

  onDataTypeChange = (e) => {
    const { performance, selectedLearningArea, selectedKeyStage } = this.state
    const selectedDataType = e
    const s = performance.data[selectedLearningArea][selectedKeyStage]
    const records = (selectedDataType !== 'lesson') ? 
      s.filter(obj => obj.topictype === e && obj.topiccode.substring(0, 2) === '60') :
      s.filter(obj => obj.topictype === e)
    const chartLabels = selectedDataType !== 'lesson' ?
      records.map(obj => obj.topiccode) :
      records.map(obj => obj.topicname)
    const chartData = records.map(obj => obj.correctrate*100)
    this.setState({ selectedDataType, chartLabels, chartData })
  }

  render() {
    const breadcumb = (
      <div className='flex-space-between'>
        <div>
          {/* <Link style={{color: '#006FAD', margin: '0'}} to={'/profile'}>{'ตั้งค่า >'}</Link> */}
          <span className={ styles.header_text }>ผลการประเมินโดยภาพรวม</span>
        </div>
      </div>
    )

    const data = {
      labels: this.state.chartLabels,
      datasets: [
        {
          label: 'อัตราการตอบถูก',
          data: this.state.chartData,
          borderColor: '#F46227',
          backgroundColor: 'rgba(244, 98, 39, 0.3)',
        }
      ],
    }

    const options = {
      scales: {
        r: {
          max: 100,
          min: 0,
          pointLabels: {
            display: true,
            font: {
              size: 20
            }
          },
          angleLines: {
            display: true,
          },
          ticks: {
            display: true,
            font: {
              size: 14,
            },
            stepSize: 10,
          },
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              var label = context.dataset.label || ''
              if (label) {
                label += ': '
              }
              if (context.raw !== null) {
                label += context.raw.toFixed(2) + '%'
              }
              return label
            },
          }
        }
      }

    }

    const possibleTypes = {indicator: 'ตัวชี้วัด', strand: 'สาระการเรียนรู้', lesson: 'บทเรียน'}
    const { learningAreas, keyStages, dataTypes } = this.state
    const learningAreaOptions = learningAreas ? learningAreas.map(function(la, i) {
      return <Option key={i} value={la}>{la}</Option>
    }) : null
    const keyStageOptions = keyStages ? keyStages.map(function(ks, i) {
      return <Option key={i} value={ks}>{ks}</Option>
    }) : null
    const dataTypeOptions = dataTypes ? dataTypes.map(function(dt, i) {
      return <Option key={i} value={dt}>{possibleTypes[dt]}</Option>
    }) : null

    return (
      <div>
        {breadcumb}
        <Form layout='inline'
          className={styles.selform}
        >
          <Form.Item>
            <Select
              placeholder="กลุ่มสาระการเรียนรู้/สาระวิชา"
              style={{ width: '250px' }}
              onChange={this.onLearningAreaChange}
              value={this.state.selectedLearningArea}
              disabled={learningAreas==null}
            >
              {learningAreaOptions}
            </Select>
          </Form.Item>
          <Form.Item>
            <Select
              placeholder="ระดับชั้น/ช่วงชั้น"
              style={{ width: '250px' }}
              value={this.state.selectedKeyStage}
              onChange={this.onKeyStageChange}
              disabled={keyStages==null}
            >
              {keyStageOptions}
            </Select>
          </Form.Item>
          <Form.Item>
            <Select
              placeholder="ประเภทข้อมูล"
              style={{ width: '250px' }}
              onChange={this.onDataTypeChange}
              value={this.state.selectedDataType}
              disabled={dataTypes==null}
            >
              {dataTypeOptions}
            </Select>
          </Form.Item>
        </Form>
        {this.state.chartLabels.length > 0 &&
          (<div style={{ width: '70%' }}>
            <Radar data={data} options={options} />
          </div>)
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.profile,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetPerformance,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Performance)