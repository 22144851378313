import React, { Component, Fragment }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { 
  Button, 
  Modal,
Row,
Col,

} from 'antd'
import {
  Link
} from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
  editExamSetlink,
} from 'redux/exam/action'
import {
  getGroupData,
} from 'redux/group/action'
import {
  IconCorrect,
  // Copy,
  //IconExamWarning,
  //IconGroup, 
} from '../../IconSVG' 

import CopyIcon from '../../IconSVG/Copy.svg'
// import Copy from '../../IconSVG/Copy.svg'


import styles from '../Exam.module.scss'

import CompetitionScienceIcon from '../../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../../IconSVG/CompetitionMathIcon.svg'
import MathCATIcon from '../../IconSVG/MathCATIcon.svg'
import ScienceCATIcon from '../../IconSVG/ScienceCATIcon.svg'
import IconScienceExam from '../../IconSVG/ExamScience.svg'
import IconMathExam from '../../IconSVG/ExamMath.svg'
import CustomIconMath from '../../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../../IconSVG/CustomIconScience.svg'
import ExerciseIconMath from '../../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../../IconSVG/ExerciseIconScience.svg'

class ExamSetInfo extends Component {
  constructor(props) {
      super(props)


      this.state = {
          visibleModalLink: false,
          withoutRegistered: false, //examDetail.withoutRegistered
      }

   

      // this.setState({
      //     visibleModalLink: false,
      // })
  }
  componentDidMount = async () => {
    let { groupId } = this.props 

if (groupId === undefined)
{
  // console.log('-------groupId-----')
  // console.log(this.props )
  // console.log(groupId)
  // console.log('-------groupId-----')
  groupId = this.props.match.params.groupId
}

    if (groupId !== undefined)
    {
      const groupres = await  this.props.getGroupData(groupId) 
      // console.log(groupres)
      this.setState({ groupres })
    }

  }

  static defaultProps = {
    example: false,
    doTest: false
  }

  title (iconSubject, examDetail) {
    return (
      <div className='title'>
        {iconSubject}
        <div className = {styles.divExamTitle}>
          <p className='text-medium' style={{color: '#05007A', fontSize: 18, marginBottom: 0}}>{examDetail.name}</p>
          <p className='text-regular' style={{color: '#2F2F2F', fontSize: 10, opacity: 0.5, marginBottom: 0 }}>{examDetail.code}</p>
          <p className='text-regular' style={{color: '#2F2F2F', fontSize: 10, marginBottom: 0 }}>{examDetail.type === 'C' ? 'แข่งขัน' : examDetail.type === 'G' ? 'ตามหลักสูตร' : (examDetail.type === 'EX' || examDetail.type === 'E') ? 'แบบฝึกหัด' : examDetail.type === 'CAT' ? 'CAT' : examDetail.type == 'CUSTOM' ? 'กำหนดเอง' : '-'}</p>
        </div>
      </div>
    )
  }


  actions (examDetail ) {
    const { example, doTest, groupId } = this.props 

    // console.log('-------------')
    // console.log(groupId)
    // console.log('-------------')

    return (
      <Fragment>
        {
          example && (
            <Button
              type='secondary'
              onClick={e => {
                e.stopPropagation()
                return this.props.history.push(`/testing/${examDetail._id}/example`, { cameFrom: groupId ? 'group': 'exam', examDetail: examDetail._id, groupId, isExample: true })
              }}
            >
              ดูตัวอย่าง
            </Button>
          )
        }
        { groupId !== undefined && 
          (
            <Button
              type='secondary'
              onClick={e => {
                e.stopPropagation()
      this.setState({
          visibleModalLink: true,
      // const {  } = props 

          withoutRegistered: examDetail.withoutRegistered


      })
      // console.log(examDetail)

                // return this.props.history.push(`/testing/${examDetail._id}/example`, { cameFrom: groupId ? 'group': 'exam', examDetail: examDetail._id, groupId, isExample: true })
              }}
            >
              ลิงก์ภายนอก
            </Button>
          )
        }
        {
          doTest && (
            <Button
              type='primary'
              onClick={e => {
                e.stopPropagation()
                return this.props.history.push(`/testing${groupId ? `/group/${groupId}/examset`: ''}/${examDetail._id}`, { cameFrom: groupId ? 'group': 'exam',  groupId })
              }}
            >
              ทดลองทำ
            </Button>
          )
        }
      </Fragment>
    )

  }



// linkinfo() { 
// const { examDetail, withoutRegistered } = this.props 

// const tmp = <div></div>

// return tmp}

  onChangeExamStatus = async (examId, status) => { 
    // console.log("========onChangeExamStatus=======")
    // console.log(examId)
    // console.log(status)
    // console.log("========onChangeExamStatus=======")

    const res = await this.props.editExamSetlink(examId, status);
    // console.log(res);
  }


  render() {
    const { examDetail,groupId } = this.props 
    const { groupres } = this.state 

    // console.log('=================examSetinfo')
    // console.log(this.props)
    // console.log(this.state)
    // console.log(groupres)
    // console.log(examDetail !== null && groupres !== null && examDetail !== undefined && groupres !== undefined)
    // console.log('=================examSetinfo')
    let logo = null 
    if(examDetail.type === 'G') {
      if(examDetail.subject === 'คณิตศาสตร์') {
        logo = IconMathExam
      }else {
        logo = IconScienceExam
      }
    } else if(examDetail.type === 'C'){
      if(examDetail.subject === 'คณิตศาสตร์') {
        logo = CompetitionMathIcon
      }else {
        logo = CompetitionScienceIcon
      }
    }else if(examDetail.type === 'CUSTOM'){
      if(examDetail.subject === 'คณิตศาสตร์') {
        logo = CustomIconMath
      }else {
        logo = CustomIconScience
      }
    } else if(examDetail.type === 'CAT') {
      if(examDetail.subject === 'คณิตศาสตร์') {
        logo = MathCATIcon
      }else {
        logo = ScienceCATIcon
      }
    } else {
      if(examDetail.subject === 'คณิตศาสตร์') {
        logo = ExerciseIconMath
      }else {
        logo = ExerciseIconScience
      }
    }
    const iconSubject = <img alt='icon-science-exam' src={logo} className={styles.iconScienceExam} style={{marginLeft: -14}}/>
    // const iconSubject = examDetail.subject == 'คณิตศาสตร์'
    //   ? <img src={IconMathExam}   className={styles.iconScienceExam} style={{marginLeft: -14}}/>
    //   : <img src={IconScienceExam}   className={styles.iconScienceExam} style={{marginLeft: -14}}/>

  // const handleOk = () => {
  //   this.setState({
  //         visibleModalLink: false,
  //     })
  // };

  const handleCancel = () => {
    this.setState({
          visibleModalLink: false,
      })
  };

const labelCol = {
  sm: 12,
  md: 12,
}
const inputCol = {
  sm: 12,
  md: 12,
}


const setlinkvalue = (data,eid) => {
    this.onChangeExamStatus(eid, data)

    this.setState(data)
}

    return (
      <div className='flex-space-between examset-info'>
        <div className='mobile'>
          {this.title(iconSubject, examDetail)}

          <div className='actions'>
            {this.actions(examDetail )}
          </div>
        </div>

        <div className='desktop'>
          {this.title(iconSubject, examDetail)}

          <div className='actions'>
            {this.actions(examDetail )}
          </div>
        </div>




{groupId !== undefined && <Modal
            title="ลิงก์ภายนอก"
            visible = { this.state.visibleModalLink }
            centered = { true }
            width = '640px'
            destroyOnClose= { true }
            bodyStyle = {{paddingTop: '10px'}}
            // maskClosable={ false }
            closable={ true }
            footer={ false }
            // onOk={handleOk} 
onCancel={handleCancel}

          >

<Row  style={{ marginBottom: '30px'}}>
        <Col style={{textAlign: 'left', fontSize: '16px', marginTop: '7px'}} {...labelCol} >ทำได้โดยไม่ต้องสมัครสมาชิก</Col>
        <Col style={{textAlign: 'left', fontSize: '16px'}} {...inputCol} >
          <div className='conditoin-switch-btn'>
            <Button style={{marginRight: '10px'}}
              type={this.state.withoutRegistered ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => {setlinkvalue({'withoutRegistered': true},examDetail._id)}} //)
            >อนุญาต</Button>
            <Button 
              type={!this.state.withoutRegistered ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => {setlinkvalue({'withoutRegistered': false},examDetail._id)}}
            >ไม่อนุญาต</Button>
          </div>
        </Col>
</Row>
{ (examDetail !== null && groupres !== null && examDetail !== undefined && groupres !== undefined) && <Row><Col><CopyToClipboard text={`${process.env.REACT_APP_WEB_URL}/ext/${examDetail.code}/${groupres.code}`}
            onCopy={() => {
    Modal.success({
      closable: true,
      centered: true,
      icon: null,
      maskClosable: true,
      okButtonProps: {hidden: true},
      width: 220,
      content: (
        <div style={{textAlign: 'center'}}>
          <IconCorrect style={{fontSize: 120}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>คัดลอกลิงก์สำเร็จ</h2>
        </div>
      )
    })
  }}
          >
<div style={{textAlign: 'left', fontSize: '16px', marginTop: '7px', cursor:'pointer'}} >
ลิงก์สำหรับทำข้อสอบ คลิกเพื่อคัดลอก <img src={CopyIcon} />
<br/>
<p  style={{marginTop: '10px'}}>{`${process.env.REACT_APP_WEB_URL}/ext/${examDetail.code}/${groupres.code}`}</p>
</div>
</CopyToClipboard></Col></Row> 
}
</Modal>}
      </div>
    )
  }
}



const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editExamSetlink,
      getGroupData
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(ExamSetInfo)

