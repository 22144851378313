import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  onUpdateDepartment
} from '../../redux/department/action'  
import styles from './Departments.module.scss'
import { 
  Row,
  Col,
  Divider,
  Button,
  Input,
  Form,
  message
} from 'antd'

class EditForm extends Component {
  constructor(props){
    super(props)   
  }
  onGenerateDepartment = (e) => {
    const { onCloseModal,item,onUpdateDepartment,fetch  } = this.props 
    e.preventDefault() 
    this.props.form.validateFieldsAndScroll( async(err, values) => {    
      if(!err){  
        await onUpdateDepartment(item._id,values)
        await fetch() 
        message.success('เพิ่มสังกัดโรงเรียนสำเร็จ')
        onCloseModal() 
      }
    })
    
  }
  render(){ 
    const { onCloseModal,form: { getFieldDecorator },name } = this.props      
    return (
      <Form onSubmit={this.onGenerateDepartment}   hideRequiredMark = {true}>
        <h1 className={styles.header_modal}>แก้ไขข้อมูล</h1>
        <Row >
          <Col offset={4} span={16}  > 
            <Form.Item label='สังกัดของโรงเรียน/หน่วยงาน'>
              {
                getFieldDecorator('name',{
                  rules: [
                    {
                      required: true,
                      message:'กรุณากรอกอย่างน้อย 1 ตัวอักษร'
                    }
                  ],
                  initialValue: name
                })(<Input />)
              }
            </Form.Item> 
          </Col> 
        </Row>
        <Divider/>
        <Row>
          <Col span={12}>
            <span onClick={e => {e.stopPropagation(); onCloseModal()}} style={{ cursor:'pointer', color:'#828282', textDecorationLine: 'underline'}}>ยกเลิก</span>
          </Col>
          <Col span={12}>
            <Button htmlType='submit' type='primary' className={ styles.button_save_modal }>บันทึก</Button>
          </Col>
        </Row> 
      </Form>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {   
      onUpdateDepartment
    },
    dispatch
  )
 
const WrappedFormDataSchool = Form.create({ name: 'Edit_Form_Department' })(EditForm)
export default connect(mapStateToProps,mapDispatchToProps)(WrappedFormDataSchool)
