import { FetchCycle, AxiosRequest } from '../fetch'
import * as $ from 'jquery'
import { dateFromTimeStamp } from '../../util/helper'

const extractItem = (arr) => {
  try {
    let { items } = arr
    arr.items = items.map( t => ({
      key: t._id,
      id: t._id,
      name: t.name,
      profileImage: t.profileImage,
      schoolName: t.school.name.text,
      createdAt: dateFromTimeStamp(t.createdAt),
      updatedAt: dateFromTimeStamp(t.createdAt),
      email: t.email,
      username:t.username,
      policy: t.isSeenDataPrivacy,
      isOfflineLoginable: t.isOfflineLoginable || false,
      lastLoggedInAt: dateFromTimeStamp(t.lastLoggedInAt),
      isSeenModified: t.isSeenModified,
      isBanned: t.isBanned,
      isConfirmationEmail: t.isConfirmationEmail,
      role: t.role
    }))
    return arr
  } catch (e) {
    return []
  }
}

// Reducer =====================================================================

export const SET_TEACHER_LIST               = 'user/SET_TEACHER_LIST'
export const SET_STUDENT_LIST               = 'user/SET_STUDENT_LIST'
export const SET_SUPER_TEACHER_LIST         = 'user/SET_SUPER_TEACHER_LIST'
export const SET_ADMIN_LIST                 = 'user/SET_ADMIN_LIST' 

// API =====================================================================

const API_USER                           = '/users'
const API_USER_CSV                       = '/users/import'

// Fetch =====================================================================

const fetchUser = paginate => () => AxiosRequest(`${API_USER}?${$.param(paginate)}&limit=10`, 'get').then( res => extractItem(res))
const fetchUserAll = () => () => AxiosRequest(`${API_USER}?limit=100000000`,'get') 
const addUser = data => () => AxiosRequest(API_USER, 'post', data)
const uploadExcel = data => () => AxiosRequest(API_USER_CSV, 'post', data)
const editUser = (id, data) => () => AxiosRequest(`${API_USER}/${id}`, 'patch', data)
const deleteUser = (id, data) => () => AxiosRequest(`${API_USER}/${id}`, 'delete', data)
const viewUser = userId => () => AxiosRequest(`${API_USER}/${userId}`, 'get')
const checkAddress = userId => () => AxiosRequest(`${API_USER}/${userId}/check-address`,'patch')

// Action Callback =====================================================================
 
const setTeacherList = (teacherList) => {
  return {
    type: SET_TEACHER_LIST,
    teacherList,
  }
}
const setStudentList = (studentList) => {
  return {
    type: SET_STUDENT_LIST,
    studentList,
  }
}
const setSuperTeacherList = (superTeacherList) => {
  return {
    type: SET_SUPER_TEACHER_LIST,
    superTeacherList,
  }
}
const setAdminList = (adminList) => {
  return {
    type: SET_ADMIN_LIST,
    adminList,
  }
}

// Action =====================================================================




export const getTeacherList = (paginate) => {
  const { searchKeyword, page } = paginate 
  return FetchCycle(fetchUser({ searchKeyword, page: page['teacher'], filters: { role: 'teacher' } }), setTeacherList)
}
export const getStudentList = paginate => {
  // Object.assign(paginate, { filters: { role: 'student' }})
  // return FetchCycle(fetchUser(paginate), setStudentList)
  const { searchKeyword, page } = paginate
  return FetchCycle(fetchUser({ searchKeyword, page: page['student'], filters: { role: 'student' } }), setStudentList)
}
export const getSuperTeacherList = paginate => {
  // Object.assign(paginate, { filters: { role: 'superTeacher' }})
  // return FetchCycle(fetchUser(paginate), setSuperTeacherList)
  const { searchKeyword, page } = paginate
  return FetchCycle(fetchUser({ searchKeyword, page: page['superTeacher'], filters: { role: 'superTeacher' } }), setSuperTeacherList)
}
export const getAdminList = paginate => {
  // Object.assign(paginate, { filters: { role: 'admin' }})
  // return FetchCycle(fetchUser(paginate), setAdminList)
  const { searchKeyword, page } = paginate
  return FetchCycle(fetchUser({ searchKeyword, page: page['admin'], filters: { role: 'admin' } }), setAdminList)
}

export const createUser = data => FetchCycle(addUser(data))
export const updateUser = (id, data) => FetchCycle(editUser(id,data))
export const importExcel = data => FetchCycle(uploadExcel(data))
export const openUserDetail = userId => FetchCycle(viewUser(userId))
export const removeUser = (id,data) =>  FetchCycle(deleteUser(id,data))
export const getFetchUserAll = () => FetchCycle(fetchUserAll())
export const onCheckAddress = (id) => FetchCycle(checkAddress(id))