/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-quotes */
import React, { Component, Fragment }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { message, Spin } from 'antd'
import scrollToElement from 'scroll-to-element'
import { 
getGroupDataCode,
} from '../../../redux/group/action'

import { extractExamSetItem, getFetchExamSetDetailFromGroup, getDetailMyExam,searchExam } from '../../../redux/exam/action'
import { getGroupData } from '../../../redux/group/action'
import { GetProfile } from '../../../redux/auth/action'

import DetailExamSetStudentComponent from './detailExamSetStudent'
import DetailExamSetTeacherComponent from './detailExamSetTeacher'
import './DetailExamSet.scss'

const extractItem = (item) => {
  try {
    return extractExamSetItem( item )
  } catch (e) {
    return []
  }
}
class detailExamSet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      code : '',
      subject: '',
      groupId: null,
      group: {},
      checkUrl :null,
      examInGroup : [],
      isAlertDelete: false
    }
  }

  componentDidMount = async () => {
    // console.log(this.props)
    // console.log(this.state)

    const { match } = this.props
    let { checkUrl } = this.state 
    let matchUrl = match.url
    checkUrl = matchUrl.slice(1,6)  
    // console.log('-------checkUrl---------')
    // console.log(checkUrl)
    // console.log(matchUrl)
    // console.log(this.props.match)
    // console.log(this.props.match.params)
    // console.log('--------checkUrl--------')
    if (this.props.match.params.code && this.props.match.params.gcode) {

      const groupres = await  this.props.getGroupDataCode(this.props.match.params.gcode) 
      // console.log('----groupres----')
      // console.log(groupres)
      // console.log(this.props.match.params.gcode)
      // console.log('----groupres----')

      const examDetail = await this.props.searchExam(this.props.match.params.code)
      // console.log('----examDetail----')
      // console.log(examDetail)
      // console.log('----examDetail----')

      if (examDetail.error === undefined && examDetail.code !== 500 && groupres.error === undefined && groupres.code !== 404)
      {
        // console.log(examDetail)
        // console.log(groupres)
        // console.log('FOUND BOTH --- ' + '/group/'+groupres.id+'/examset/'+examDetail._id)
        // this.setState({ groupres })
        // this.openModal(examDetail)
        this.props.history.push('/group/'+groupres.id+'/examset/'+examDetail._id+'/extlink')
      }
      else
      {
        this.props.history.push('/')
      }

      // localStorage.setItem('pathDirect', this.props.history.location.pathname)

    }
    else
    {


      await this.setState({ groupId: this.props.match.params.groupId , checkUrl })
      this.fetchExamSetDetail() 
    }
  }

  fetchExamSetDetail = async () => { 
    const examSetID = this.props.match.params.id

    this.setState({ isLoading: true })

    this.fetchDetail(examSetID)
    this.fetchUser()

    const res = { _id: '500000001', code: 'MF00000001', name: 'คณิตศาสตร์ ป.4', subject: 'คณิตศาสตร์', type: 'G', createdAt: '1563679343', status: true }
    this.setState({
      isLoading : false,
      subject : examSetID,
    })

    if (res.error) return message.error('ไม่สามารถดึงข้อมูลชุดข้อสอบได้ในขณะนี้')
    this.setState({
      examSetDetail: extractItem(res)
    })
  }

  fetchDetail = async (idMyExam = null) => { 
    let { examInGroup, group } = this.state
    this.setState({ isLoading: true })
    let res = ''    
    // if(checkUrl === 'group' ) {   
    if(this.state.groupId) {
      res = await this.props.getFetchExamSetDetailFromGroup(idMyExam, this.state.groupId)
      group = await this.props.getGroupData(this.state.groupId)
    }else{    
      res = await this.props.getDetailMyExam(idMyExam)
    }  

    if (this.props.match.path === "/group/:groupId/examset/:id/extlink")
    {
      res.status = true
    }

    // console.log('-------fetchDetail------')
    // console.log(res)
    
    // console.log(this.props.match) //.code
    // console.log('-------fetchDetail------')

    examInGroup = res
    this.setState({ examInGroup, isLoading: false, group })
    if (res.error) message.error(res.error)
  }

  fetchUser = async (getRoleOfUser = null) => {
    this.setState({ isLoading: true })
    await this.props.GetProfile(getRoleOfUser)
    this.setState({ isLoading: false })
  }

  onTabs = (key) => {
    scrollToElement(`#${key}`)
  }

  toExamDetail = async(exam) => {
    const { groupId,checkUrl } = this.state 
    
    if(checkUrl === 'group') {   
      const res = await this.props.getFetchExamSetDetailFromGroup(exam,groupId)
      // if (this.props.match.path === "/group/:groupId/examset/:id/extlink")
      // {
      //   res.status = true
      // }

      // console.log('-------fetchDetail------')
      // console.log(res)
      res.status = true
      // console.log(this.props.match.params) //.code
      // console.log('-------fetchDetail------')
      this.setState({ examInGroup: res })
    }else{   
      await this.props.getDetailMyExam(exam)
    } 
    
  }

  toRoleOfUser = (auth) => {
    this.props.GetProfile(auth)
  }

  render() {
    const { examDetail, user } = this.props 
    const { checkUrl, examInGroup, isAlertDelete } = this.state  
    const currentPath  = this.props.location.pathname.match(/^\/[^/]*/)[0]
    let data
    
    if(checkUrl === 'group') { 
      data = examInGroup
    }else { 
      data = examDetail
    }   
    if (!examDetail) return null   
    if (!isAlertDelete && (examDetail.deletedAt || examDetail.status === 'close')) {
      message.error('ชุดข้อสอบยังไม่เผยแพร่หรือถูกลบแล้ว')
      this.setState({ isAlertDelete: true })
    }  
    return (
      <div className='examset-detail'>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <div className="pagination">
            {
              this.state.groupId ? 
                <Fragment>
                  <Link to={'/group'}>
                    <span style={{color: '#006FAD', margin: 0, textDecoration: 'underline', paddingRight: '5px'}}>กลุ่ม</span>
                    <span style={{color: '#828282', margin: 0 , paddingRight: '5px'}}>{'>'}</span>
                  </Link> 
                  <Link to={`/group/${this.state.groupId}`}>
                    <span style={{color: '#006FAD', margin: 0, textDecoration: 'underline' ,paddingRight: '5px'}}>{ this.state.group.name } </span>
                    <span style={{color: '#828282', margin: 0 , paddingRight: '5px'}}>{'>'}</span>                    
                  </Link>
                </Fragment>
                :
                <Link style={{color: '#006FAD', margin: 0}} to={currentPath}>
                  {currentPath === '/examset' ? ' ข้อสอบของฉัน >' : currentPath === '/suggest' ? 'ข้อสอบแนะนำ >' : 'ข้อสอบท้งหมด >'}
                </Link>
            } 
            <span style={{color: '#828282'}}> รายละเอียด</span>
          </div> 
          <h1 style = {{ fontWeight: 500, fontSize:  '28px', color: '#05007A' }}>ข้อสอบ</h1> 
          {
            // user.role === 'student' ?
            user.role === 'student' ?
              <DetailExamSetStudentComponent examDetail={data} {...this.props} /> :
              <DetailExamSetTeacherComponent {...this.props} /> 
              
          }
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    examDetail: state.exam.examDetail,
    user: state.auth.profile, 
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // eslint-disable-next-line camelcase
      searchExam,
      getGroupDataCode,
      getFetchExamSetDetailFromGroup,
      getDetailMyExam,
      getGroupData,
      GetProfile
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(detailExamSet)
