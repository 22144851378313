/* eslint-disable no-undef */
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {  Card, Row, Col , Input, Collapse, Spin, Avatar, Button, Modal } from 'antd'

import { getSuggestExam, getDetailMyExam,searchExam } from '../../redux/exam/action'
// import { startTesting } from '../../redux/testing/action'
import { 
getGroupDataCode,
} from '../../redux/group/action'

import IconSearch from '../IconSVG/Search.svg'

import CustomIconMath from '../IconSVG/CustomIconMath.svg'
import CustomIconMathBig from '../IconSVG/CustomIconMathBig.svg'
import CustomIconScience from '../IconSVG/CustomIconScience.svg'
import CustomIconScienceBig from '../IconSVG/CustomIconScienceBig.svg'
import ExerciseIconMath from '../IconSVG/ExerciseIconMath.svg'
import ExerciseIconMathBig from '../IconSVG/ExerciseIconMathBig.svg'
import ExerciseIconScience from '../IconSVG/ExerciseIconScience.svg'
import ExerciseIconScienceBig from '../IconSVG/ExerciseIconScienceBig.svg'


import CompetitionMathExamBigIcon from '../IconSVG/CompetitionMathExamBigIcon.svg'
import CurriculumMathExamBigIcon from '../IconSVG/CurriculumMathExamBigIcon.svg'
import CurriculumSciExamBigIcon from '../IconSVG/CurriculumSciExamBigIcon.svg'
import CompetitionSciExamBigIcon from '../IconSVG/CompetitionSciExamBigIcon.svg'

import GroupTopLeftSuggestExamHomePage from '../IconSVG/GroupTopLeftSuggestExamHomePage.svg'
import GroupMidLeftSuggestExamHomePage from '../IconSVG/GroupMidLeftSuggestExamHomePage.svg'
import GroupRightSideExamSuggestHomePage from '../IconSVG/GroupRightSideExamSuggestHomePage.svg'

import BigBookMath    from '../IconSVG/BigBookMath.svg'
import BigBookSci from '../IconSVG/BigBookSci.svg'
import BigMathMatchIcon from '../IconSVG/BigMathMatchIcon.svg'
import BigScienceMatchIcon from '../IconSVG/BigScienceMatchIcon.svg'

import styles from './HomeExamSuggest.module.scss'

const { Panel } = Collapse

class HomeExamSuggest extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      suggestMathExam: [],
      suggestSciExam: [],
      searchKey: null,
      checkEnter : false,
      mathFilters: [],
      sciFilters: [],
      visibleModal: false,
      linkerrorModal: false,
      examDetail: []
    }
  }
  componentDidMount = async () => {
    this.fetchSuggestExam()
    if (this.props.match.params.code && this.props.match.params.gcode) {

      const groupres = await  this.props.getGroupDataCode(this.props.match.params.gcode) 
      // console.log('----groupres----')
      // console.log(groupres)
      // console.log(this.props.match.params.gcode)
      // console.log('----groupres----')
      
      // // 


      const examDetail = await this.props.searchExam(this.props.match.params.code)
      // console.log('----examDetail----')
      // console.log(examDetail)
      // console.log('----examDetail----')

      if (examDetail.error === undefined && examDetail.code !== 500 && groupres.error === undefined && groupres.code !== 404)
      {
        this.setState({ groupres })
        this.openModal(examDetail)
      }
      else
        this.setState({ linkerrorModal: true })

      // localStorage.setItem('pathDirect', this.props.history.location.pathname)
    }
    else if (this.props.match.params.id) {
      const examDetail = await this.props.getDetailMyExam(this.props.match.params.id)
      if (examDetail.error === undefined && examDetail.code !== 500)
        this.openModal(examDetail)
      else
        this.setState({ linkerrorModal: true })
      // localStorage.setItem('pathDirect', this.props.history.location.pathname)
    }
  }
  fetchSuggestExam = async () => {
    const { getSuggestExam } = this.props
    let { suggestMathExam,suggestSciExam  } = this.state
    this.setState({ isLoading: true })
    const res = await getSuggestExam()
    suggestMathExam = res.filter( exam => exam.subject === 'คณิตศาสตร์' )
    suggestSciExam = res.filter( exam => exam.subject === 'วิทยาศาสตร์' )
    this.setState({ suggestMathExam, suggestSciExam, isLoading: false })
  }

  startExam = async(examId) => {    
const { history } = this.props

if (this.props.match.path === "/code/:code/:gcode" && this.state.groupres !== null)
{
// console.log(this.props.match)
history.push(`/testing/${examId}/ext/${this.state.groupres.code}`,{ cameFrom: 'homeSuggest' } )
}
else
{
// console.log(this.props.match)
history.push(`/testing/${examId}`,{ cameFrom: 'homeSuggest' } )
}

  }
  goToLogin = () => {
    const { examDetail } = this.state
    const { history } = this.props

if (this.props.match.path === "/code/:code/:gcode" && this.state.groupres !== null)
{
// console.log(this.props.match)
localStorage.setItem('pathDirect',`${this.props.match.url}`)
// history.push(`/testing/${examId}/ext/${this.state.groupres.code}`,{ cameFrom: 'homeSuggest' } )
}
else
{
// console.log(this.props.match)
localStorage.setItem('pathDirect',`/examset/${examDetail._id}`)
  // history.push(`/testing/${examId}`,{ cameFrom: 'homeSuggest' } )
}


    // /localStorage.setItem('pathDirect',`/examset/${examDetail._id}`)
    history.push('/login')
  }
  handleKeyPress = (value) => {
    const {checkEnter} = this.state
    setTimeout(()=>{
      this.setState({
        searchKey: value
      })
      if(value === '' && checkEnter === true){
        this.handleSearch()
        this.setState({
          checkEnter : false
        })
      }
    },50)
  }
  handleSearch = async(value) => {
    let { searchKeyword, suggestMathExam, suggestSciExam, mathFilters, sciFilters } = this.state
    let isEmptySearch = true
    searchKeyword = value
    this.setState({ checkEnter : true })
    if (searchKeyword) {
      isEmptySearch = false
      mathFilters = suggestMathExam.filter(r => r.code.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)
      sciFilters = suggestSciExam.filter(r => r.code.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)
    }
    if(!isEmptySearch) {
      this.setState({
        suggestMathExam: mathFilters,
        suggestSciExam: sciFilters
      })
    }else{ this.fetchSuggestExam() }
  }
  openModal = (exam) => {
    let { examDetail } = this.state
    examDetail = exam 
    this.setState({ examDetail, visibleModal: true }) }
  closeModal = () => { this.setState({  visibleModal: false }) }
  render() {
    const { isLoading, suggestMathExam, suggestSciExam, searchKey, visibleModal, linkerrorModal, examDetail } = this.state  
    const header = (
      <div className={ styles.header }>
        <span className={ styles.header_text } >ข้อสอบแนะนำ</span>
        <span className={styles.search_bar}>
          <div className='mobile'>
            <Button type='secondary'>
              <img src={IconSearch} alt='icon-search' />
            </Button>
          </div>

          <div className='desktop'>
            <Input
              allowClear
              prefix={
                <img src={IconSearch} alt='icon-search' style={{verticalAlign: 'middle'}} />
              }
              placeholder='ค้นหา'
              maxLength={100}
              className={styles.inputSearch}
              onChange={(e)=>this.handleKeyPress(e.target.value)}
              onPressEnter={() => this.handleSearch(searchKey)}
            />
          </div>
        </span>
      </div>
    ) 
    const MathExam = (
      <div className={ styles.ExamSuggest} >
        <Collapse bordered={false} defaultActiveKey={['1']} className={styles.collapse_style} expandIconPosition={'right'}>
          <Panel header={(<span className={ styles.text_header_subject }>คณิตศาสตร์</span>)} key='1' className={styles.panel_style}>
            <Row gutter={16} className={ styles.Row_style }>
              {
                suggestMathExam.map((exam,i) => {
                  let iconExam = null 
                  if(exam.type === 'G') {
                    iconExam = CurriculumMathExamBigIcon
                  }else if(exam.type === 'C') {
                    iconExam = CompetitionMathExamBigIcon
                  }else if(exam.type === 'CUSTOM') {
                    iconExam = CustomIconMath
                  }else {
                    iconExam = ExerciseIconMath
                  }
                
                  return (
                    <Col key={i} span={8} xs={24} md={8} className={ styles.Columns_style }>
                      <Card className={ styles.Card_style }>
                        <div style={{ padding: '12px' }}>
                          <Avatar  size={48} src={ iconExam } shape={'square'}/>
                        </div>
                        <p className = { styles.text_description_card }>{ exam.name }</p>
                        <p className = { styles.text_description_card }>{ exam.code }</p>
                        <div className={ styles.footer_of_card }>
                          <p className = { styles.text_description_card }>{ exam.type === 'G' ? 'ตามหลักสูตร' : (exam.type === 'C' ? 'แข่งขัน' : ( exam.type === 'E' ? 'แบบฝึกหัด' : 'กำหนดเอง')) }</p>
                          <Button onClick={e => {
                            e.stopPropagation()
                            this.openModal(exam) 
                            if(!exam.withoutRegistered) localStorage.setItem('pathDirect',`/suggest/${exam._id}`) 
                          }} type={exam.withoutRegistered ? null :'primary'} >{ exam.withoutRegistered ? 'เริ่มทำ' : 'เข้าสู่ระบบ'}</Button>
                        </div>
                      </Card>
                    </Col>
                  )
                })
              }
            </Row>
          </Panel>
        </Collapse>
      </div>
    )
    const SciExam = (
      <div className={ styles.ExamSuggest} >
        <Collapse bordered={false} defaultActiveKey={['1']} className={styles.collapse_style} expandIconPosition={'right'}>
          <Panel header={(<span className={ styles.text_header_subject }>วิทยาศาสตร์</span>)} key='1' className={styles.panel_style}>
            <Row gutter={16} className={ styles.Row_style }>
              {
                suggestSciExam.map((exam,i) => {
                  let iconExam = null 
                  if(exam.type === 'G') {
                    iconExam = CurriculumSciExamBigIcon
                  }else if(exam.type === 'C') {
                    iconExam = CompetitionSciExamBigIcon
                  }else if(exam.type === 'CUSTOM') {
                    iconExam = CustomIconScience
                  }else {
                    iconExam = ExerciseIconScience
                  }
                  return (
                    <Col key={i} span={8}  xs={24} md={8} className={ styles.Columns_style }>
                      <Card className={ styles.Card_style }>
                        <div style={{ padding: '12px' }}>
                          <Avatar  size={48} src={iconExam} shape={'square'}/>
                        </div>
                        <p className = { styles.text_description_card }>{ exam.name }</p>
                        <p className = { styles.text_description_card }>{ exam.code }</p>
                        <div className={ styles.footer_of_card }>
                          <p className = { styles.text_description_card }>{ exam.type === 'G' ? 'ตามหลักสูตร' : (exam.type === 'C' ? 'แข่งขัน' : ( exam.type === 'E' ? 'แบบฝึกหัด' : 'กำหนดเอง')) }</p>
                          <Button onClick={e => {
                            e.stopPropagation()
                            this.openModal(exam)
                            if(!exam.withoutRegistered) localStorage.setItem('pathDirect',`/suggest/${exam._id}`) 
                          }} type={exam.withoutRegistered ? null :'primary'} >{ exam.withoutRegistered ? 'เริ่มทำ' : 'เข้าสู่ระบบ'}</Button>
                        </div>
                      </Card>
                    </Col>
                  )
                })
              }
            </Row>
          </Panel>
        </Collapse>
      </div>
    )
    const background = (
      <Fragment>
        <div className={ styles.GroupTopLeftSuggestExamHomePage } >
          <img alt='group-top-left-suggest-exam-home-page' src={GroupTopLeftSuggestExamHomePage} />
        </div>
        <div className={ styles.GroupMidLeftSuggestExamHomePage }>
          <img alt='group-mid-left-suggest-exam-home-page' src={GroupMidLeftSuggestExamHomePage}/>
        </div>
        <div className={ styles.GroupRightSideExamSuggestHomePage }>
          <img alt='group-right-side-suggest-exam-home-page' src={GroupRightSideExamSuggestHomePage}/>
        </div>
      </Fragment>
    )
    const ExamDetail = (
      <Modal
        width={'85%'}
        visible={visibleModal}
        onCancel={this.closeModal}
        footer={false}
        className={styles.modal_exam_detail}
        centered
        destroyOnClose
      >
        <div className={ styles.content_modal }>
          <p className={ styles.text_header }>ข้อสอบ</p>
          <Row>
            <Col span={2}>
              {/* <img src={ examDetail ? examDetail.type === 'G' ? examDetail.subject === 'คณิตศาสตร์' ? BigBookMath :  BigBookSci : examDetail.subject === 'คณิตศาสตร์' ? BigMathMatchIcon : BigScienceMatchIcon : null }/> */}
              <img alt='subject' src={
                examDetail ? examDetail.subject === 'คณิตศาสตร์' ? 
                  examDetail.type === 'G' ? BigBookMath : examDetail.type === 'C' ? BigMathMatchIcon : examDetail.type === 'CUSTOM' ? CustomIconMathBig : ExerciseIconMathBig :
                  examDetail.type === 'G' ? BigBookSci : examDetail.type === 'C' ? BigScienceMatchIcon : examDetail.type === 'CUSTOM' ? CustomIconScienceBig : ExerciseIconScienceBig : null
              }/>
            </Col>
            <Col span={21} offset={1}>
              <div className={ styles.text_header_modal }>
                <p style={{ fontWeight: 500, fontSize: '20px', color: '#05007A' }}>{examDetail ? examDetail.name : '-'}</p>
                <p style={{ fontWeight: 300, fontSize: '16px', color: '#2F2F2F', opacity: 0.5 }}>{examDetail ? examDetail.code : ''}</p>
                <p style={{ fontWeight: 300, fontSize: '16px', color: '#2F2F2F' }}>
                  {examDetail ? 
                  (examDetail.type === 'G' ? 'ตามหลักสูตร' : 
                   examDetail.type === 'C' ? 'แข่งขัน' :  
                   examDetail.type === 'CUSTOM' ? 'กำหนดเอง' : 
                   examDetail.type === 'CAT' ? 'CAT' : 
                   (examDetail.type === 'EX' || examDetail.type === 'E') ? 'แบบฝึกหัด' : '-') 
                   : ''}</p>
              </div>
            </Col>
          </Row>
          <div style={{ paddingTop: '30px' }}>
            <Row>
              <Col span={8} offset={3}>
                <span style={{ fontSize: '16px',color: '#828282' }}>วิชา</span>
              </Col>
              <Col span={11}>
                <span>{examDetail ? examDetail.subject : '-'}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8} offset={3}>
                <span style={{ fontSize: '16px',color: '#828282' }}>ระดับชั้น</span>
              </Col>
              <Col span={11}>
                <span>{examDetail ? examDetail.grade : '-'}</span>
              </Col>
            </Row> 
            {
              examDetail.type === 'C' ?
                <Fragment>
                <Row>
                  <Col span={8} offset={3}>
                    <span style={{ fontSize: '16px',color: '#828282' }}>โครงการที่แข่ง</span>
                  </Col>
                  <Col span={11}>
                    <span>{examDetail.competition ? examDetail.competition.project : '-'}</span>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} offset={3}>
                    <span style={{ fontSize: '16px',color: '#828282' }}>ปีที่แข่งขัน</span>
                  </Col>
                  <Col span={11}>
                    <span>{examDetail.competition ? examDetail.competition.years.join() : '-'}</span>
                  </Col>
                </Row></Fragment>
                : ''
            } 
            
            <Row>
              <Col span={8} offset={3}>
                <span style={{ fontSize: '16px',color: '#828282' }}>จำนวนข้อสอบ</span>
              </Col>
              <Col span={11}>
                <span>{examDetail ? `${examDetail.quantity} ข้อ` : '-'}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8} offset={3}>
                <span style={{ fontSize: '16px',color: '#828282' }}>กำหนดเวลาในการทำข้อสอบ</span>
              </Col>
              <Col span={11}>
                <span>{examDetail ? examDetail.duration === 0 ? 'ไม่ระบุ' :  examDetail.duration / 60 + ' นาที' : '-'}</span>
              </Col>
            </Row>
            <Row>
              <Col span={8} offset={3}>
                <span style={{ fontSize: '16px',color: '#828282' }}>คำชี้แจงในการสอบ</span>
              </Col>
              <Col span={11}>
                <span>{ examDetail ? examDetail.description === '' ? '-' : examDetail.description : '-'}</span>
              </Col>
            </Row>
          </div>
        </div>
{
examDetail.withoutRegistered ? null : <div style={{ paddingTop: '30px', textAlign: 'right' }}>*ข้อสอบไม่สามารถทำได้โดยไม่เข้าสู่ระบบ ต้องทำการเข้าสู่ระบบก่อนทำข้อสอบ</div>
}
        <div style={{ paddingTop: '30px', textAlign: 'right' }}>
          <Button onClick = { e => {
            e.stopPropagation()
            examDetail.withoutRegistered ? this.startExam(examDetail._id) : this.goToLogin()
          }}  type='primary' style={{ width: '100px' }}>
            { examDetail.withoutRegistered ? 'เริ่มทำ' : 'เข้าสู่ระบบ'}
          </Button>
        </div> 
      </Modal>
    )
    return (
      <div >
        <Spin size='large' tip='Loading...' spinning={isLoading} className={styles.div_spinner}>
          { header }
          { MathExam }
          { SciExam }
        </Spin>
        { background }
        { ExamDetail }
<Modal
        title='ลิงก์ไม่ถูกต้อง'
        width={'85%'}
        visible={linkerrorModal}
        onCancel={() => { this.setState({  linkerrorModal: false }) }}
        footer={false}
        className={styles.modal_exam_detail}
        centered
        destroyOnClose
      ><p>ลิงก์ไม่ถูกต้อง ไม่พบข้อมูลสอบที่ระบุ</p>
<ul><li>{window.location.origin.toString()+this.props.match.url}</li></ul>
{
//<p>{`${process.env.REACT_APP_API_URL}/storage/template/${this.props.match.url}`
//JSON.stringify(this.props.match.url)
}
</Modal> 
      </div>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getGroupDataCode,
      getDetailMyExam,
      getSuggestExam,
      searchExam
      // startTesting
    },
    dispatch
  )
export default connect(null, mapDispatchToProps)(HomeExamSuggest)
