import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Modal,
  Button,
} from 'antd'
import styles from './Group.module.scss'
import AvatarBase64 from '../Register/AvatarBase64'
import ImgGroupUpload from '../../assets/ImgGroupUpload.svg'
import { generateInputForm } from '../../util/formGenerator'

const formItemLayout = {
  colon: false,
  labelAlign: 'left',
  hideRequiredMark: true,
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
}

class ModalAddGroup extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isFormDirty: false,
      logo: null,
      isLoading: false
    }
  }

  onAvatarChange = (imageBase64) => {
    this.setState({ logo: imageBase64 })
  }

  handleCancel = () => {
    this.props.onCancel()
    // this.avatarRef.onCancel()
  }

  handleSubmit = (e) => {
    e.preventDefault() 
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState({ isFormDirty: true })
      let { isLoading } = this.state
      if (!err) {
        isLoading = true
        const params = {
          ...values,
          logo: this.state.logo,
        }
        if (this.props.group) params.id = this.props.group.id

        this.props.onSubmit(params) 
        this.avatarRef.onCancel()
        isLoading = false
      }
      this.setState({ isLoading })
    })
  }

  setAvatarBase64Ref = (ref) => this.avatarRef = ref

  render() {
    const { isLoading } = this.state 
    const { form, isVisible, group } = this.props
    const checkTour = this.props.isSeenTutorial.isSeenTutorial.group
    const isEditMode = group ? true : false
    let modalChildren = null
    if (isVisible) {
      const isFormDirty = this.state.isFormDirty
      modalChildren = generateInputForm({ fieldName: 'name', form, label: 'ชื่อกลุ่ม', isFormDirty, isRequire: true, initialValue: (group && group.name) || null, limit: 50, value : !checkTour ? 'ป. 4/1' : ''  })
    }
    const groupLogo = (group && group.logo) || null

    return (
      <Modal
        visible={isVisible}
        closable={true}
        width='580px'
        centered
        onCancel={this.handleCancel}
        destroyOnClose={true}
        footer={
          <div className='flex-space-center'>
         
          </div>
        }
      >
        <div data-tut-teacher-group='TeacherCreateGroupSecond'>
          <h2 className='text-medium' style={{color: '#05007A'}}>{isEditMode ? 'แก้ไขกลุ่ม' : 'สร้างกลุ่ม'}</h2>
          <div style={{textAlign: 'center', marginTop: -30, marginBottom: 20}}>
            <AvatarBase64 
              ref={this.setAvatarBase64Ref}
              onChange={ this.onAvatarChange } 
              imageDefault={groupLogo || ImgGroupUpload}
              size='large'
            />
          </div>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <div>
              {modalChildren}
            </div>
          </Form>
          <div style = {{width : '100%', textAlign : 'center', marginTop: 24}}>
            <Button 
              key='submit' 
              type='primary' 
              htmlType='submit' 
              style={{maxWidth: '180px', width: '100%'}}
              className = {styles.btnsaveGroup}
              onClick={(e)=>{
                this.handleSubmit(e)
                if(this.props.nextStepTour){
                  setTimeout(()=>{this.props.nextStepTour()},100)
                }
              }}
              loading={isLoading}           
            >
            บันทึก
            </Button>
          </div>
          
        </div>
       
      </Modal>
    )
  }
}

ModalAddGroup.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  group: PropTypes.object,
}

const WrappedModalAddGroup = Form.create({ name: 'modal_add_group' })(ModalAddGroup)
export default WrappedModalAddGroup