/* eslint-disable no-undef */
import React,{ Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Header from './Header'
import styles from './Report.module.scss'
import * as _ from 'lodash'
import {
  getExamSetDetail,
  getDetailMyExam
} from '../../redux/exam/action'
import {
  getStudentDetail,
  getExamStaticDetail,
  getGroupExamDeepStat
} from '../../redux/report/action'
import {
  getResultTesting
} from '../../redux/testing/action'
import {
  GetProfile
} from '../../redux/auth/action'
import ModalScore from './ModalScore'
import ModalScoreResult  from './ModalScoreResult'

import BigBookMathIcon    from '../IconSVG/BigBookMathIcon.svg'
import BigBookScienceIcon from '../IconSVG/BigBookScienceIcon.svg'
import BigMathMatchIcon from '../IconSVG/BigMathMatchIcon.svg'
import BigScienceMatchIcon from '../IconSVG/BigScienceMatchIcon.svg'

import IconReportResult from '../IconSVG/IconReportResult.svg'
import IconReportScore from '../IconSVG/IconReportScore.svg'
import IconReportAnswers from '../IconSVG/IconReportAnswers.svg'
import ListItems from '../Display/ListItems'
import ExcelIcon from '../IconSVG/ExcelIcon.svg'
import * as moment from 'moment' 
import {
  Button,
  Table,
  Spin,
  Row,
  Col,
  Modal,
  Tabs,
  Typography,
  Divider,
  message
} from 'antd' 
import SearchModal from '../modal/SearchModal'
import * as FileSaver from 'file-saver' 
import * as XLSX from 'xlsx' 
import { snapLocationRedirect } from '../../util/helper'

const roleMapper = {
  student: 'นักเรียน',
  teacher: 'ครู',
  superTeacher: 'ครูพิเศษ',
  admin: 'ผู้ดูแลระบบ'
} 
const { TabPane } = Tabs
const { Paragraph } = Typography

export const StudentTableReportExam = (props) => {
  const {columnsStudent,dataStudent ,onHover,onNotHover,history } = props

  const srcData = dataStudent.map((item, i) => {
    const actions = [
      {
        attribute: (
          <span onClick={(e) => {
            e.stopPropagation()
            props.onOpenModalScore(i)
          }}>
            <img src={IconReportScore} alt='score' /> ใบคะแนน
          </span>
        )
      },
      {
        attribute: (
          <span onClick={(e) => {
            e.stopPropagation()
            props.onOpenModalResultScore(i)
          }}>
            <img src={IconReportResult} alt='result' /> ใบผลสอบ
          </span>
        )
      },
      {
        attribute: (
          <span onClick={(e) => {
            e.stopPropagation()
            props.onClickTesting(i)
          }}>
            <img src={IconReportAnswers} alt='answers' /> ดูคำตอบ
          </span>
        )
      }
    ]
   
    return {
      avatar: '',
      title: `#${dataStudent.length - i} ${item.score} คะแนน`,
      description: `${moment.unix(item.finishedAt).format('DD/MM/YYYY, HH:mm')}`,
      status: '',
      actions: actions,
      path: ''
    }
  }) 
  return (
    <div className={styles.student_list}>
      <div className='mobile'>
        <ListItems
          srcData={srcData}
          status
          history={history}
        />
      </div>

      <div className='desktop'>
        <Table
          rowKey={ (record,i) => i }
          columns = { columnsStudent }
          dataSource = { dataStudent }
          pagination=  { false }
          onRow = {(exam,rowIndex) => {
            return {
              onMouseEnter: () => onHover(rowIndex),
              onMouseLeave: () => onNotHover(rowIndex)
            }
          }}
        />
      </div>
    </div>
  )
}
export const ExamInfo = (props) => {
  const { groupId, ExamDetail, openModal, auth , noExportExcel = null, excelData, getExamDeepStat, 
    getGroupExamDeepStat, spinLoading, studentList = null, examGroupDetail = null, getGroupData } = props  
  let studentExcel = []
  if(auth.role === 'student') {
    excelData.map((d,i) => {
      studentExcel[i] = { range : excelData.length - i,
        finishedAt : moment.unix(d.finishedAt).format('DD/MM/YYYY'),
        score : d.score,
        startedAt : moment.unix(d.startedAt).format('mm:ss'),
        testingId : d.testingId
      }
    })
  }  
  const downloadStatReport = async () => {
    spinLoading(true)

    if((auth.role === 'teacher' || auth.role === 'superTeacher') && !studentList && examGroupDetail === null) { 
      await spinLoading(false)
      return message.warning('ไม่สามารถดาวน์โหลดไฟล์ Excel ได้เนื่องจากยังไม่มีนักเรียนทำข้อสอบ')
    }
    if((auth.role === 'teacher' || auth.role === 'superTeacher') && !examGroupDetail && studentList === null) { 
      await spinLoading(false)
      return message.warning('ไม่สามารถดาวน์โหลดไฟล์ Excel ได้เนื่องจากยังไม่มีนักเรียนทำข้อสอบ')
    }
    const filename = `${ExamDetail.code} ${moment().format('DD-MM-YYYY')}.xlsx`
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const { testings, questions } = await (groupId ? getGroupExamDeepStat(ExamDetail._id, groupId) : getExamDeepStat(ExamDetail._id))
    // console.log("===== groupData =====")
    // console.log(props.groupData)
    // console.log("===== groupData =====")
    let csvData = [
      [`รายงานข้อมูลการสอบ ${ExamDetail.name}`],
      ['รหัสชุดข้อสอบ :', ExamDetail.code],
      ['ชื่อชุดข้อสอบ :', ExamDetail.name],
      ['ระดับชั้น :', ExamDetail.grade],
      ['กลุ่มสาระ :', ExamDetail.subject],
      ['จำนวนข้อสอบ :', `${ExamDetail.quantity} ข้อ`],
      ['เวลาที่ใช้สอบ :', ExamDetail.duration > 0 ? `${ExamDetail.duration/60} นาที` : 'ไม่จำกัดเวลา'],
      ['วัน/เวลาที่สร้างข้อสอบ :', moment.unix(ExamDetail.createdAt).format('DD/MM/YYYY HH:mm')],
    ]

    if (ExamDetail.type === 'C') {
      csvData.splice(3, 0, ['ประเภทข้อสอบ :', ExamDetail.competition.project, ExamDetail.competition.years.join(',')])
    } else if (ExamDetail.type === 'G') {
      csvData.splice(3, 0, ['ประเภทข้อสอบ :', 'ตามหลักสูตร'])
    }

    let headers = ['ลำดับ', 'คำนำหน้า', 'ชื่อ', 'นามสกุล', 'โรงเรียน/หน่วยงาน', 'สิทธิ์การใช้งาน']
    
    headers.push('กลุ่ม')

    let tmpq = [];
    questions.forEach((q, index) => {
      if(auth.role === 'teacher') {
        headers.push(`ข้อที่ ${index+1}`)
      } else {
        headers.push(`ข้อที่ ${index+1} (รหัสข้อสอบ:${q.id})`)
      } 
    tmpq.push(q)
    })
    // console.log(tmpq)  
    // console.log('=======tmpq=======')
    // console.log(tmpq)
    // console.log('=======tmpq=======')

    // console.log("==== testings ====")
    // console.log(testings)
    // console.log("==== testings ====")

    headers = headers.concat(['รวมคะแนน', 'เวลาที่ใช้ทำข้อสอบ', 'เวลาที่ส่งข้อสอบ', 'วันที่ส่งข้อสอบ'])
    //korn  
    csvData.push(headers) 
    let correctAnswer = ['คำตอบที่ถูกต้อง','','','','','','']
    let numCorrectAnswer = ['จำนวนที่ตอบถูก','','','','','','']
    let numIncorrectAnswer = ['จำนวนที่ตอบผิด','','','','','','']   
    testings.forEach((t, index) => {
      let temp = [
        index+1,
        t.user.prefixName,
        t.user.firstName,
        t.user.lastName,
        t.user.school.name.text,
        roleMapper[t.user.role], 
        // props.groupData ? props.groupData.name : '',
        t.group ? t.group.name : ''
      ]
      // temp.push(props.groupData.name)

    // console.log('=======t.progressTestings=======')
    // console.log(t.progressTestings)
    // console.log('=======t.progressTestings=======')
    // console.log(t)

      let tmpprogressTestings = [];
    tmpq.forEach((q, index) => {
      tmpprogressTestings.push(t.progressTestings.find(xx => xx.questionId === q._id));
    })
// console.log(tmpprogressTestings)  

    // const progressTestings = t.progressTestings.sort((a, b) => a.orgorder - b.orgorder).map(pt => {
        const progressTestings = tmpprogressTestings.map((pt, idx) => {
        // console.log(pt)
        let answer = (typeof(pt.answer) !== undefined && pt.answer) ? pt.answer : ''
        if (Array.isArray(answer) && answer.length > 0) {
          if (Array.isArray(answer[0])) {
            const xDigits = questions[idx].answers.x_axis ? questions[idx].answers.x_axis.fixed_digits : 2
            const yDigits = questions[idx].answers.y_axis ? questions[idx].answers.y_axis.fixed_digits : 2
            answer = answer.map(x => typeof (x) === 'string' ? x : `(${[x[0].toFixed(xDigits), x[1].toFixed(yDigits)].join(',')})`).join(';')
          }
          else if (answer[0] && answer[0].seq) {
            answer = answer.map(a => `{${a.seq}-${a.match}}`).join(',')
          } else {
            answer = `[${answer.map(a => a ? a.key : '-').join(',')}]`
          }
        }
        return answer
      })
      temp = temp.concat(progressTestings)
      temp.push(t.score)  
      let timeOfTesting = '' 
      if(moment.duration(t.time) && moment.duration(t.time)._data.hours < 10) {
        timeOfTesting = `0${moment.duration(t.time)._data.hours}`
      }else {
        timeOfTesting = `${moment.duration(t.time)._data.hours}`
      }
      if(moment.duration(t.time) && moment.duration(t.time)._data.minutes < 10) {
        timeOfTesting = timeOfTesting.concat(`:0${moment.duration(t.time)._data.minutes}`)
      }else { 
        timeOfTesting = timeOfTesting.concat(`:${moment.duration(t.time)._data.minutes}`)
        
      }
      if(moment.duration(t.time) && moment.duration(t.time)._data.seconds < 10) {
        timeOfTesting = timeOfTesting.concat(`:0${moment.duration(t.time)._data.seconds}`)
      }else {
        timeOfTesting = timeOfTesting.concat(`:${moment.duration(t.time)._data.seconds}`)
      } 
      temp.push(timeOfTesting)
      temp.push(moment.unix(t.finishedAt).format('HH:mm:ss'))
      temp.push(moment.unix(t.startedAt).add(543,'years').format('DD/MM/YYYY'))
   
      csvData.push(temp)   
    })
    questions.forEach((q) => {  
      let ans = null 
      switch (q.type){
        case 'MC' :  
          ans = q.answers.filter(a => { if(a.key) return a })[0].seq 
          correctAnswer.push(ans)
          break 
        case 'MA' :             
          ans = q.answers.left.map(l => `{${l.seq}-${l.match}}`).join(',')
          correctAnswer.push(ans) 
          break
        case 'SA' :  
          correctAnswer.push(q.answers[0].key)  
          break 
        case 'TF' :  
          ans = q.subQuestions.map(sub => `{${sub.no}-${sub.answers.filter(s => { if(s.key) return s })[0].text}}`).join(',')    
          correctAnswer.push(ans)
          break
        case 'GR' :
          const xDigits = q.answers.x_axis ? q.answers.x_axis.fixed_digits : 2
          const yDigits = q.answers.y_axis ? q.answers.y_axis.fixed_digits : 2
          ans = q.answers.answers.map(x => typeof(x) === 'string' ? x : `(${[x[0].toFixed(xDigits), x[1].toFixed(yDigits)].join(',')})`).join(';')
          correctAnswer.push(ans)
          break
        default : break 
      }  
    })
    let ansNumCorrect = questions.map(() => 0)
    let ansNumIncorrect = questions.map(() => 0) 
    testings.forEach(testing => {
      testing.progressTestings.map((testingPt,i) => {
        if(testingPt.isCorrect) {
          ansNumCorrect[i] += 1
        }else{
          ansNumIncorrect[i] += 1
        }
      })
    })
    ansNumCorrect.map(ans => numCorrectAnswer.push(ans))
    ansNumIncorrect.map(ans => numIncorrectAnswer.push(ans)) 
      
    csvData.push(correctAnswer)
    csvData.push(numCorrectAnswer)
    csvData.push(numIncorrectAnswer)   
    const merge1 = {
      s: { r: 7 + csvData.length + 1 - 11 , c:0 }, 
      e: { r: 7 + csvData.length + 1 - 11, c: 4}
    }
    const merge2 = {
      s: { r: 8 + csvData.length + 1 - 11 , c:0 }, 
      e: { r: 8 + csvData.length + 1 - 11, c: 4}
    }
    const merge3 = {
      s: { r: 9 + csvData.length + 1 - 11 , c:0 }, 
      e: { r: 9 + csvData.length + 1 - 11, c: 4}
    } 
    const ws = XLSX.utils.aoa_to_sheet(csvData)
    
    // ws.A6.s = { fill: { bgColor: '#000000' } }
    // ws.A6.s = { fill: { bgColor: { rgb: '000000' } } } 
    let countCols = 0
    csvData.map(csv => {
      if(csv.length > countCols) {
        countCols = csv.length
      }
    })
    let lengthOfStr = []
    for(let i = 0; i < countCols; i++){ lengthOfStr[i] = 0 }
    let k = 0
    csvData.map((csv,i) => { 
      csvData.map((c,j) => { 
        if(csvData[j][i]){  
          if(csvData[j][i].toString().length > lengthOfStr[k]) { 
            lengthOfStr[k] = csvData[j][i].toString().length  
          } 
        }  
      })
      if(k < lengthOfStr.length - 1) k++ 
    })   
    let wscols = lengthOfStr.map((length,i) => { 
      if(i > 5) {
        return auth.role === 'teacher' ? { wch: 11 } : { wch: 30 }
      }
      return { wch: length }
    })  
    ws['!cols'] = wscols
    if(!ws['!merges']) ws['!merges'] = []
    ws['!merges'].push(merge1)
    ws['!merges'].push(merge2)
    ws['!merges'].push(merge3)
    
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    // eslint-disable-next-line no-undef
    const data = new Blob([excelBuffer], {type: fileType})
    FileSaver.saveAs(data, filename)
    spinLoading(false)
  }

  return (
    <div className={styles.exam_info}>
      <div className='detail'>
        <img src={ExamDetail.type === 'C' ? ( ExamDetail.subject === 'คณิตศาสตร์' ? BigMathMatchIcon : BigScienceMatchIcon ) : ( ExamDetail.subject === 'คณิตศาสตร์' ? BigBookMathIcon : BigBookScienceIcon )} alt='group_logo' className={styles.logoGroupLg}/>
        <div className='exam-title'>
          <h2 className='text-medium' style={{color: '#05007A', fontSize: 20, marginBottom: 0}}>{ExamDetail.name}</h2>
          <p className={ styles.text_regular_code } >{ExamDetail.code}</p>
          <p className={ styles.text_regular_type } >
            <span className='text-medium'>{ ExamDetail.type === 'G' ? 'ตามหลักสูตร' : ExamDetail.type === 'C' ? 'แข่งขัน' : ExamDetail.type === 'C' ? 'CAT' : 'กำหนดเอง' }</span>
            <span>{ExamDetail.quantity} ข้อ</span>
          </p>
        </div>

        <Button onClick={(e) => {
          e.stopPropagation()
          openModal()
        }} className='desktop' > รายละเอียด </Button>

        {
          !noExportExcel && auth.role !== 'student' && (
            <div style={{ paddingLeft: '20px' }}>
              <Button  className='desktop' onClick={downloadStatReport}>
                <img src={ ExcelIcon } alt='icon-excel' />ดาวน์โหลด Excel
              </Button>
            </div>
          )
        }
      </div>

      <div className='detail2'>
        <Button onClick={(e) => {
          e.stopPropagation()
          openModal()
        }}
        className='mobile'
        >
          รายละเอียด
        </Button>
        {
          !noExportExcel && auth.role !== 'student' && (
            <Button  className='mobile' onClick={downloadStatReport}>
              <img src={ ExcelIcon } alt='icon-excel' />ดาวน์โหลด Excel
            </Button>
          )
        }
      </div>
    </div>
  )
}

export const ExamDetailModal = (props) => {
  const { visibleModal,closeModal, ExamDetail, auth } = props
  const columnsExam = [
    {
      title: '#',
      dataIndex: 'num',
      key: 'num',
      render: (num,row,index)=>{
        return (<span>{index+1}</span>)
      }
    },
    {
      title: 'ปีการแข่งขัน',
      dataIndex: 'year',
      key: 'year'
    },
    {
      title: 'สาระการเรียนรู้',
      dataIndex: 'lessons',
      key: 'lessons'
    },
  ]

  const onTabs = (key) => {
    let element = document.getElementById(key)
    element.scrollIntoView({behavior: 'smooth' })
  }

  const getCriteriaDetail = (exam) => {
    switch (exam.criterion) {
      case 'lesson': return ExamDetail.lessons.map((e,i) => ((
        <Row key={i} className={styles.row_detail_exam_modal}>
          <Col span={12}><Paragraph ellipsis={{ rows: 2, expandable: false }} title={e.name}>{e.name}</Paragraph></Col>
          <Col span={5} offset={1}  >{e.quantity}</Col>
          <Col span={6}>ข้อ</Col>
        </Row>
      )))
      case 'indicator': return ExamDetail.indicators.map((e,i) => ((
        <Row key={i} className={styles.row_detail_exam_modal}>
          <Col span={12}><Paragraph ellipsis={{ rows: 2, expandable: false }} title={e.name}>{e.name}</Paragraph></Col>
          <Col span={5} offset={1}>{e.quantity}</Col>
          <Col span={6}>ข้อ</Col>
        </Row>
      )))
      case 'strand': return ExamDetail.indicators.map((e,i) => ((
        <Row key={i} className={styles.row_detail_exam_modal}>
          <Col span={12}><Paragraph ellipsis={{ rows: 2, expandable: false }} title={e.name}>{e.name}</Paragraph></Col>
          <Col span={5} offset={1}>{e.quantity}</Col>
          <Col span={6}>ข้อ</Col>
        </Row>
      )))
      default: return ''
    }
  }

  return (
    <Modal
      visible = {visibleModal}
      onCancel= {closeModal}
      footer={false}
      className={styles.modal_exam_detail}
    >
      <div className={styles.modal_exam_detail}>
        <div className='exam-detail'>
          <p style={{ color: '#05007A', fontSize:'28px' }}>ข้อสอบ</p>

          <Row className={styles.row_detail_exam_modal}>
            <div className='exam-name'>
              <img src={ExamDetail.type === 'C' ? (ExamDetail.subject === 'คณิตศาสตร์' ? BigMathMatchIcon: BigScienceMatchIcon) : (ExamDetail.subject === 'คณิตศาสตร์'? BigBookMathIcon: BigBookScienceIcon)}/>

              <div className='detail'>
                <h3>{ExamDetail.subject}</h3>
                <p style={{ color: '#2F2F2F'}}>{ExamDetail.code}</p>
                <p>{ExamDetail.type === 'CAT' ? 'CAT' : ExamDetail.type === 'G'? 'ตามหลักสูตร' : 'แข่งขัน' }</p>
              </div>
            </div>

            <Col span={20} xs={24}>
              <Row>
                <Tabs
                  defaultActiveKey='anchorSubjectLevel'
                  animated={false}
                  onChange={ onTabs }
                >
                  <TabPane tab='วิชาและระดับ'       key='anchorSubjectLevel'/>
                  <TabPane tab='จำนวนข้อสอบ'       key='anchorExamNumber'/>
                  <TabPane tab='เงื่อนไขของชุดข้อสอบ' key='anchorExamCondition'/>
                  {
                    auth.role === 'teacher' ?
                      <TabPane tab='สรุป'              key='anchorSynopsis'/>
                      :''
                  }
                </Tabs>
                <div id='anchorSubjectLevel'>
                  <Row>
                    <Col span={12}>
                      <Row className={styles.row_detail_exam_modal}>คลังที่เลือกข้อสอบ</Row>
                      <Row className={styles.row_detail_exam_modal}>วิชา</Row>
                      <Row className={styles.row_detail_exam_modal}>ระดับชั้น</Row>
                      <Row className={styles.row_detail_exam_modal}>ระดับของข้อสอบ</Row>
                    </Col>
                    <Col span={12}>
                      <Row className={styles.row_detail_exam_modal}>คลังเปิด</Row>
                      <Row className={styles.row_detail_exam_modal}>{ExamDetail.subject}</Row>
                      <Row className={styles.row_detail_exam_modal}>{ExamDetail.grade}</Row>
                      <Row className={styles.row_detail_exam_modal}>{ExamDetail.level ? ExamDetail.level.join() : ''}</Row>
                    </Col>
                  </Row>
                </div>

                <Divider style={{display: 'inline-block'}}/>

                <div id='anchorExamNumber'>
                  <Row >
                    <Row>
                      <Col span={12}>
                        <h3>จำนวนข้อสอบ</h3>
                      </Col>
                      {
                        auth.role === 'student' ?
                          <Col span={12}>
                            <span>{ExamDetail.quantity}  ข้อ</span>
                          </Col>
                          : ''
                      }
                    </Row>
                    {
                      auth.role === 'teacher' ?
                        <div>
                          <Col span={12}>
                            <Row className={styles.row_detail_exam_modal}><span>รายละเอียด</span></Row>
                          </Col>
                          <Col span={12}>
                            <Row className={styles.row_detail_exam_modal}>
                              <Col span={12}><span>{ ExamDetail.criterion === 'lesson' ? 'บทเรียน' : ExamDetail.criterion === 'indicator'? 'ตัวชี้วัด' : 'สาระการเรียนรู้'}</span></Col>
                              <Col span={5} offset={1}>{ ExamDetail.quantity }</Col>
                              <Col span={6}>ข้อ</Col>
                            </Row>
                            {getCriteriaDetail(ExamDetail)}
                          </Col>
                        </div>
                        : ''
                    }
                  </Row>
                </div>

                <Divider style={{display: 'inline-block'}}/>

                <div id='anchorExamCondition'>
                  <Row >
                    <Row className={styles.row_detail_exam_modal}><h3>เงื่อนไขของชุดข้อสอบ</h3></Row>
                    <Col span={12}>
                      <Row className={styles.row_detail_exam_modal}><span>กำหนดเวลาในการทำข้อสอบ</span></Row>
                      {
                        auth.role === 'teacher' ?
                          <div>
                            <Row className={styles.row_detail_exam_modal}><span>จำนวน</span></Row>
                            <Row className={styles.row_detail_exam_modal}><span>ให้นักเรียนแสดงวิธีทำหลังทำเสร็จ</span></Row>
                            <Row className={styles.row_detail_exam_modal} ><span>เฉลยข้อสอบหลังทำเสร็จ</span></Row>
                            <Row className={styles.row_detail_exam_modal}><span>จำนวนครั้งในการทำข้อสอบ</span></Row>
                            <Row className={styles.row_detail_exam_modal}><span>ให้คนที่ไม่ใช่สมาชิกทำได้</span></Row>
                          </div>
                          :''
                      }
                      <Row className={styles.row_detail_exam_modal}><span>คำชี้แจงในการทำข้อสอบ</span></Row>
                    </Col>
                    <Col span={12}> 
                      {/* <Row className={styles.row_detail_exam_modal}><span>{ 0 > 1 ? {ExamDetail.duration} นาที ({moment.utc(moment.duration(ExamDetail.duration,'minutes').asMilliseconds()).format('H:mm')} ชม.) : ''}</span></Row> */}
                      <Row className={styles.row_detail_exam_modal}><span>{ ExamDetail.duration ? `${moment.duration(ExamDetail.duration,'seconds').hours()} ชม. ${moment.duration(ExamDetail.duration,'seconds').minutes()} นาที` : 'ไม่ระบุ'}</span></Row>
                      {
                        auth.role === 'teacher' ?
                          <div>
                            <Row className={styles.row_detail_exam_modal}><span>{ExamDetail.examSetTotal} ชุด</span></Row>
                            <Row className={styles.row_detail_exam_modal}><span>{ExamDetail.displaySolution ? 'แนบ' : 'ไม่แนบ'}</span></Row>
                            <Row className={styles.row_detail_exam_modal}><span>{ExamDetail.displayHowTo ? 'เฉลย' : 'ไม่เฉลย'}</span></Row>
                            <Row className={styles.row_detail_exam_modal}><span>{ExamDetail.oneTimeDone ? '1 ครั้ง' : 'หลายครั้ง'}</span></Row>
                            <Row className={styles.row_detail_exam_modal}><span>{ExamDetail.withoutRegistered ? 'อนุญาต' : 'ไม่อนุญาต'}</span></Row>
                          </div>
                          : ''
                      }
                      <Row className={styles.row_detail_exam_modal}><span>{ExamDetail.description}</span></Row>
                    </Col>
                  </Row>
                </div>

                <div id='anchorSynopsis'/>
                {
                  auth.role === 'teacher' && ExamDetail.type === 'C' ?
                    <div>
                      <Divider style={{display: 'inline-block'}}/>
                      <div id='anchorSynopsis'>
                        <Row >
                          <Row><h3>ข้อสอบ</h3></Row>
                          <Table
                            rowKey={(record,i) => i}
                            pagination={false}
                            columns={columnsExam}
                            dataSource={ ExamDetail.strand }
                          />
                        </Row>
                      </div>
                    </div>
                    : ''
                }
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}
class ExamStudents extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      isHover: false,
      indexRow: false,
      visibleModal: false,
      ExamDetail: [],
      studentListDetail: [],
      visibleModalScore: false,
      visibleModalScoreResult: false,
      testingId: null,
      examDetail:{},
      user: {},
      testingDetail: [],
      searchResult: [],
      isSearchModal: false,
      examDetailReport: [],
      examTime: 0,
    }
  }
  columnsStudent = [
    {
      title: '#',
      dataIndex: 'range',
      key: 'range',
      render: (range,row,index)=>{
        const { studentListDetail } = this.state
        return (<span>{studentListDetail.length - index}</span>)
      }
    },
    {
      title: 'วันที่',
      dataIndex: 'finishedAt',
      key: 'finishedAt',
      align: 'center',
      render: (finishedAt) => {
        return (<div>{moment.unix(finishedAt).format('DD/MM/YYYY')}</div>)
      }
    },
    {
      title: 'เวลา',
      dataIndex: 'finishedAt',
      key: 'time',
      render: (finishedAt) => {
        return (<div>{moment.unix(finishedAt).format('HH:mm')}</div>)
      }
    },
    {
      title: 'คะแนนที่ได้',
      dataIndex: 'score',
      key: 'score',
      align: 'center'
    },
    {
      title: '',
      dataIndex: '',
      key: 'button',
      render: (exam,row,index) => {   
        const { ExamDetail, studentListDetail } = this.state  
        return (
          <div >
            <Button
              onClick={(e) => {
                e.stopPropagation()
                this.onOpenModalScore(index, studentListDetail.length - index)
              }}
              className={styles.button_hover}>ใบคะแนน</Button>
            <Button
              onClick = {(e) => {
                e.stopPropagation()
                this.onOpenModalResultScore(index,row)
              }}
              className={styles.button_hover}>ใบผลสอบ</Button>
            {
              ExamDetail.type !== 'CAT' ?
                <Button
                  onClick={(e)=>{
                    e.stopPropagation()
                    this.onClickTesting(index)
                  }}
                  className={styles.button_hover}>ดูคำตอบ</Button> : ''
            }
          </div>)
      }
    },
  ]
  componentDidMount = () => {
    this.fetchGetExamGroup()
    snapLocationRedirect()
  }
  fetchGetExamGroup = async() => {
    this.setState({ isLoading: true })
    const { match } = this.props
    const [ resExam, resStudentList, examDetail, user ] = await Promise.all([
      this.props.getExamSetDetail(match.params.examId),
      this.props.getStudentDetail(match.params.examId),

      this.props.getDetailMyExam( match.params.examId),
      this.props.GetProfile()
    ])
    this.setState({
      ExamDetail: resExam,
      studentListDetail: resStudentList,
      examDetail,
      user,
      isLoading: false
    })
  } 
  spinLoading = (value = undefined) => {
    let isLoading = this.state 
    if(value !== undefined) {
      return this.setState({ isLoading: value })
    }else {
      this.setState({ isLoading: !isLoading }) 
    } 
  }

  onHover = (index) => {
    this.setState({
      indexRow: index,
      isHover: true
    })
  }
  onNotHover = (index) => {
    this.setState({
      indexRow: index,
      isHover: false
    })
  }
  openModal = () => {
    this.setState({
      visibleModal: true
    })
  }
  closeModal = () => {
    this.setState({
      visibleModal: false
    })
  }
  onClickTesting = (index) => {
    const {  history } = this.props
    const { studentListDetail } = this.state
    const orderedStudentListDetail = _.orderBy(studentListDetail, ['finishedAt'], ['desc'])
    history.push(`/testing/${orderedStudentListDetail[index].testingId}/solution`, { cameFrom: 'report', examId: this.state.examDetail._id })
  }
  onOpenModalScore = async(index, indexRange) => { 
    const { studentListDetail } = this.state  
    let arr = _.orderBy(studentListDetail,['finishedAt'],['desc']) 
    let testingId = arr[index].testingId
    const resTestingDetail = await this.props.getResultTesting(testingId)
    resTestingDetail.id = testingId
    this.setState({
      testingDetail: resTestingDetail,
      examTime: indexRange,
      visibleModalScore: true
    })
  }
  onCloseModalScore = () => {
    this.setState({
      visibleModalScore: false
    })
  }
  onOpenModalResultScore = async(index,record) => {  
    const { studentListDetail } = this.state   
    let testingId = studentListDetail[studentListDetail.length - 1  - index].testingId 
    const resTestingDetail = await this.props.getResultTesting(testingId)
    const resReportExam = await this.props.getExamStaticDetail(record.testingId) 
    this.setState({
      testingDetail: resTestingDetail,
      examDetailReport: resReportExam,
      visibleModalScoreResult: true
    })
  }
  onCloseModalResultScore = () => {
    this.setState({
      visibleModalScoreResult: false
    })
  }

  showSearchModal = () => {
    this.setState({ isSearchModal: !this.state.isSearchModal})
    this.state.isSearchModal && this.fetchGetExamGroup()
  }

  render(){
    const { isLoading, visibleModal, ExamDetail ,studentListDetail, 
      visibleModalScoreResult, visibleModalScore, testingDetail ,examDetail,
      user, examDetailReport, examTime } = this.state 
    const { auth, match } = this.props 
    const dtStudent = _.orderBy(studentListDetail,['finishedAt'], ['desc']) || []
    const srcData = dtStudent.map((item, i) => {
      
      const actions = ExamDetail.type !== 'CAT' ? [
        {
          attribute: (
            <span onClick={(e) => {
              e.stopPropagation()
              this.onOpenModalScore(i)
            }}>
              <img src={IconReportScore} alt='score' /> ใบคะแนน
            </span>
          )
        },
        {
          attribute: (
            <span onClick={(e) => {
              e.stopPropagation()
              this.onOpenModalResultScore(i,item)
            }}>
              <img src={IconReportResult} alt='result' /> ใบผลสอบ
            </span>
          )
        },
        {
          attribute: (
            <span onClick={(e) => {
              e.stopPropagation()
              this.onClickTesting(i)
            }}>
              <img src={IconReportAnswers} alt='answers' /> ดูคำตอบ
            </span>
          )
        }
      ] : [
        {
          attribute: (
            <span onClick={(e) => {
              e.stopPropagation()
              this.onOpenModalScore(i)
            }}>
              <img src={IconReportScore} alt='score' /> ใบคะแนน
            </span>
          )
        },
        {
          attribute: (
            <span onClick={(e) => {
              e.stopPropagation()
              this.onOpenModalResultScore(i,item)
            }}>
              <img src={IconReportResult} alt='result' /> ใบผลสอบ
            </span>
          )
        } 
      ]

      return {
        avatar: '',
        // title: `#${i + 1} ${item.score !== undefined ? item.score : ' '} คะแนน`,
        title: `#${dtStudent.length - i} ${item.score !== undefined ? item.score : ' '} คะแนน`,
        description: `${moment.unix(item.finishedAt).format('DD/MM/YYYY, HH:mm')}`,
        status: '',
        actions: actions,
        path: ''
      }
    }) 
    // console.log('==== studentListDetail ====')
    // console.log(studentListDetail)
    // console.log(_.orderBy(studentListDetail, ['finishedAt'], ['desc']))
    // console.log('==== studentListDetail ====')
    const orderedStudentListDetail = _.orderBy(studentListDetail, ['finishedAt'], ['desc'])
    return (
      <div className={styles.group_score}>
        <Spin size='large' top='Loading...' spinning={ isLoading }>
          <Header noSearch={ true } match={match} {...this.props}/>
          <ExamInfo openModal={this.openModal} ExamDetail={ExamDetail} auth={auth} excelData={_.orderBy(studentListDetail,['finishedAt'], ['desc'])} spinLoading={this.spinLoading}/>

          <div className='mobile'>
            <ListItems
              srcData={srcData}
              status
              history={this.props.history}
            />
          </div>
          <div className='desktop'>
            <StudentTableReportExam
              ExamDetail={ExamDetail}
              columnsStudent={this.columnsStudent}
              dataStudent={orderedStudentListDetail}
              onHover={this.onHover}
              onNotHover={this.onNotHover}
              onOpenModalScore={this.onOpenModalScore}
              onOpenModalResultScore={this.onOpenModalResultScore}
              onClickTesting={this.onClickTesting}
              history={this.props.history}
            />
          </div>

          <ExamDetailModal closeModal={this.closeModal}
            visibleModal={visibleModal}
            auth={auth}
            ExamDetail={ExamDetail}
          />
        </Spin>

        <ModalScoreResult
          visibleModalScoreResult={visibleModalScoreResult}
          onCloseModalResultScore={this.onCloseModalResultScore}
          match={match}
          ExamDetail={ExamDetail}
          user={user}
          testingDetail={testingDetail}
          examDetailReport={examDetailReport}
        />

        <ModalScore
          visibleModalScore={visibleModalScore}
          onCloseModalScore={this.onCloseModalScore}
          match={match}
          testingDetail={testingDetail}
          examDetail={examDetail}
          user={user}
          examTime={examTime}
          auth={this.props.auth}
        />

        <SearchModal
          isSearchModal={this.state.isSearchModal}
          showSearchModal={this.showSearchModal}
          title='ค้นหา'
          placeholder='ชื่อ'
          action={this.setExamsFiltersBySearch}
        >
          <ListItems
            srcData={srcData}
            status
            history={this.props.history}
          />
        </SearchModal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    auth: state.auth.profile
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getExamSetDetail,
      getStudentDetail,
      getResultTesting,
      getDetailMyExam,
      GetProfile,
      getExamStaticDetail,
      getGroupExamDeepStat
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ExamStudents)
