import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
} from 'antd'
import styles from '../../Register/Register.module.scss'
class ModalTermDataMember extends Component {

  constructor(props) {
    super(props)
    this.state = {
      termConditionCheck: false,
      dataPrivacyCheck: false
    }
  }

  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    })
  }

  render() {
    const { isVisible, onOK, termAndCondition, dataPrivacy, role } = this.props

    let dataPrivactForRole = {}
    
    if (dataPrivacy.length > 0) {
      dataPrivactForRole = dataPrivacy.find(dp => dp.role === role)
    }
    return (
      <Modal
        visible={isVisible}
        closable={false}
        width={'100%'}
        wrapClassName={styles.modalTermWrap}
        destroyOnClose={true}
        footer={
          <div>
            <Button type = 'primary' onClick={()=>{
              onOK()
              window.location.reload()
            }} >ยอมรับเงื่อนไข ข้อกำหนด และนโยบายคุ้มครองข้อมูลส่วนบุคคล</Button>
          </div>
        }
      >
        <div className={`${styles.modalTerm} text-thin`} style={{ overflow: 'auto', height: '600px' }}>
          <h2
            className='text-medium text-center text-lg'
            style={{
              color: '#05007A',
              marginTop: '10px',
              marginBottom: '30px',
              lineHeight: '44px'
            }}
          >
            {termAndCondition ? termAndCondition.title : ''}
          </h2>
          
          <div  dangerouslySetInnerHTML={{__html: termAndCondition.content}} />
          <h2
            className='text-medium text-center text-lg'
            style={{
              color: '#05007A',
              marginTop: '10px',
              marginBottom: '30px',
              lineHeight: '44px'
            }}
          >
            {dataPrivactForRole ? dataPrivactForRole.title : ''}
          </h2>
          <div  dangerouslySetInnerHTML={{__html: dataPrivactForRole ? dataPrivactForRole.content : ''}} />
        </div>
      </Modal>
    )
  }
}

ModalTermDataMember.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onOK: PropTypes.func.isRequired,
  // onCancel: PropTypes.func.isRequired,
}

export default ModalTermDataMember