exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "@media screen and (max-width: 991px) {\n  .StepperCircle_inputStepCircle__NHlei {\n    display: flex;\n    justify-content: space-between;\n    align-items: center; } }\n\n.StepperCircle_circle__1WaQW {\n  display: inline-block;\n  width: 40px;\n  height: 40px;\n  color: #BDBDBD;\n  border: 2px solid #BDBDBD;\n  border-radius: 50%;\n  text-align: center;\n  line-height: 36px;\n  margin-right: 14px;\n  font-size: 14px;\n  -webkit-user-select: none;\n          user-select: none;\n  cursor: pointer; }\n  @media screen and (max-width: 991px) {\n    .StepperCircle_circle__1WaQW {\n      margin-right: 0; } }\n  .StepperCircle_circle__1WaQW:hover {\n    color: #05007A;\n    background-color: #F7941D;\n    border-color: #F7941D; }\n\n.StepperCircle_circleSmall__iJx8B {\n  width: 30px;\n  height: 30px;\n  line-height: 26px; }\n\n.StepperCircle_inputStepper__8ZgKM {\n  display: inline-block;\n  width: 80px;\n  height: 40px;\n  border: 2px solid #F7941D;\n  border-radius: initial;\n  margin-right: 14px;\n  text-align: center; }\n  @media screen and (max-width: 991px) {\n    .StepperCircle_inputStepper__8ZgKM {\n      margin-right: 0; } }\n", ""]);

// Exports
exports.locals = {
	"inputStepCircle": "StepperCircle_inputStepCircle__NHlei",
	"circle": "StepperCircle_circle__1WaQW",
	"circleSmall": "StepperCircle_circleSmall__iJx8B",
	"inputStepper": "StepperCircle_inputStepper__8ZgKM"
};