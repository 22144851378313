import { SET_GROUP_LIST, SET_SEARCH_GROUP_LIST, SET_EXAMS_LIST, API_STUDENT_LEAVE_GROUP } from './action'

const defaultState = {
  groupList: [],
  examsInGroup: [], 
  searchedGroupList: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_GROUP_LIST:
      return {
        ...state,
        groupList: action.groupList, 
      }
    case SET_SEARCH_GROUP_LIST:
      return {
        ...state,
        searchedGroupList: action.groupList,
      }
    case SET_EXAMS_LIST:
      return {
        ...state,
        examsInGroup: action.exams
      }
    case API_STUDENT_LEAVE_GROUP:
      return {
        ...state,
        studentLeftList: action.listOfStudents
      }
    default: return state
  }
}  