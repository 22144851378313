exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CardLightMap_card__1NSno {\n  width: 430px;\n  margin-left: auto;\n  margin-right: auto; }\n\n.CardLightMap_lightMap__22UGH {\n  width: 116px; }\n\n@media (max-width: 768px) {\n  .CardLightMap_card__1NSno {\n    width: auto;\n    max-width: 380px; }\n  .CardLightMap_lightMap__22UGH {\n    width: 70px; } }\n\n@media (max-width: 320px) {\n  .CardLightMap_card__1NSno {\n    width: 280px; }\n  .CardLightMap_lightMap__22UGH {\n    width: 58px; } }\n\n.CardLightMap_cardLightMap__2qNvl {\n  margin-top: 20px; }\n\n.CardLightMap_divLightMap__1JnJd .CardLightMap_animation_content1__eYl_R {\n  left: 40%;\n  bottom: 0px; }\n  @media screen and (max-width: 991px) {\n    .CardLightMap_divLightMap__1JnJd .CardLightMap_animation_content1__eYl_R {\n      bottom: 165px;\n      top: auto;\n      left: 0;\n      right: 0;\n      margin: auto; } }\n  .CardLightMap_divLightMap__1JnJd .CardLightMap_animation_content1__eYl_R .mobile {\n    display: none; }\n    @media screen and (max-width: 991px) {\n      .CardLightMap_divLightMap__1JnJd .CardLightMap_animation_content1__eYl_R .mobile {\n        display: block; } }\n  .CardLightMap_divLightMap__1JnJd .CardLightMap_animation_content1__eYl_R .desktop {\n    display: block; }\n    @media screen and (max-width: 991px) {\n      .CardLightMap_divLightMap__1JnJd .CardLightMap_animation_content1__eYl_R .desktop {\n        display: none; } }\n", ""]);

// Exports
exports.locals = {
	"card": "CardLightMap_card__1NSno",
	"lightMap": "CardLightMap_lightMap__22UGH",
	"cardLightMap": "CardLightMap_cardLightMap__2qNvl",
	"divLightMap": "CardLightMap_divLightMap__1JnJd",
	"animation_content1": "CardLightMap_animation_content1__eYl_R"
};