import React, { Component, Fragment } from 'react'
import { List,  Checkbox, Avatar } from 'antd' 

import GoldenMedalIcon from '../../IconSVG/Golden_Medal_Icon.svg'
import SilverMedalIcon from '../../IconSVG/Silver_Medal_Icon.svg'
import CopperMedalIcon from '../../IconSVG/Copper_Medal_Icon.svg'
import AvatarGroupNoti from '../../Group/GroupDetail/AvatarGroupNoti'
import DefaultGroup from '../../IconSVG/DefaultGroup.svg'  
import DefaultProfile from '../../IconSVG/DefaultProfile.svg'
import DropdownItems from '../../Display/DropdownItems'
import Group from '../../IconSVG/Group.svg'
import './ListItems.scss'

class ListItems extends Component {
  static defaultProps = {
    srcData: [],
    avatar: false,
    status: false,
    dropdownDivider: false,
    dropdownWidth: null
  }

  handleClick (e, path, checkReport = false, item, status, i) { 
    const { checkReportTabGroup = false } = this.props  
    e.stopPropagation()  
    if ((path && checkReport) || (path && checkReportTabGroup)) {  
      this.props.history.push(path, { exam: 'group' }) 
    }else if(path && Array.isArray(status) && status.length > 0 && (item.actions[0].attribute.props.children.props ?  //fix bug
      // item.actions[0].attribute.props.children.props.status === 'join' ? true : false 
      status[i].status === 'join' && status[i].deletedAt === null ? true : false 
      : true)) {
      this.props.history.push( path )
    } else if (item.to) { 
      this.props.history.push( item.to.path, {groupId: item.to.groupId , groupName: item.to.groupName} )
    }
  }

  render () {
    const { srcData, avatar, status = [], dropdownDivider, dropdownWidth,
      report = false, groupReport = false, isMedal = false , groupList,
      isCheckbox = false, isCheckAll = false, checkSiftOut = [], onChangeCheckBox = null,
      isGroupIndex = false, role = null, pagination
    } = this.props      
    return (
      <div className='list-items'>
        <List
          itemLayout='horizontal'
          dataSource={srcData}
          pagination={pagination}
          renderItem={(item,i) => {
            return (
              (
                <List.Item>
                  {
                    avatar ? (
                      <Fragment>
                        {
                          isCheckbox ?
                            <Checkbox 
                              options = { [ item ] }
                              style = {{ paddingRight: '7px' }}
                              checked = { isCheckAll ? isCheckAll : checkSiftOut && checkSiftOut.length > 0 ?  checkSiftOut[i].isCheck  : false}
                              onChange = { (e) => onChangeCheckBox(e.target.checked , i , item.studentId ) }
                            /> : ''
                        }
                        <List.Item.Meta
                          avatar={<div>
                            <div>

                              {groupList && groupList[i].haveStudentRequest === true ?
                            
                                <AvatarGroupNoti
                                  size = 'small'
                                  imageDefault={  item.avatar ? item.avatar : DefaultGroup }
                                />
                                :
                                <img alt='avatar' src={ item.avatar ? item.avatar : DefaultProfile } />
                              }
                            </div>
                            {
                              isMedal && (item.medal > -1 && item.medal < 3) ?
                                <div style = {{ position: 'absolute', left: '12px', top: '32px' }}>
                                  <img alt='medal' style = {{ width: '19px' }} src={
                                    item.medal === 0 ? GoldenMedalIcon :
                                      item.medal === 1 ? SilverMedalIcon : 
                                        item.medal === 2 ? CopperMedalIcon : null
                                  }/>
                                </div>
                                : ''
                            }
                          </div>}
                          title={ item.title }
                          description={ isGroupIndex && role !== 'student' ? 
                            (<div style = {{ display: 'flex', alignItems: 'flex-end' }}>
                              <Avatar src = {Group} size = {16} shape='square'/>
                              <div style = {{ paddingLeft: '5px' }}>
                                <span>{item.description}</span>
                              </div>
                            </div>)
                            : item.description }
                          onClick={(e) => this.handleClick(e, item.path, item.checkReport ,item, status, i)}
                        />
                      </Fragment>
                    ) : (
                      <List.Item.Meta
                        title={item.title}
                        description={item.description}
                        onClick={(e) => this.handleClick(e, item.path, item.checkReport,item, status, i)}
                      />
                    )
                  }

                  {
                    item.actions.length > 1 && (
                      <div className='more'>
                        <DropdownItems menu={item.actions} dropdownDivider={dropdownDivider} dropdownWidth={dropdownWidth}/>
                      </div>
                    )
                  }
                  {
                    // item.actions.length > 1 && status && (
                    (item.actions.length > 1 || status) && (
                      <div className='status'>
                        {
                          report ? 
                            <span style={{ fontSize: '14px' }}>{item.status}&nbsp;&nbsp;</span> :
                            <p>{item.status}</p> 
                        } 
                        {
                          groupReport && report ?
                            <span>คะแนน</span>
                            : ''
                        }
                      </div>
                    )
                  }
                  {
                    item.actions.length === 1 && (
                      <div className='more'>
                        {item.actions[0].attribute}
                      </div>
                    )
                  } 
                </List.Item>
              )
            )
          }}
        />
      </div>
    )
  }
}

export default ListItems
