import React, { Component } from 'react'
import { Spin } from 'antd'

import Header from '../Header.js'
import Overview from '../Overview.js'

class ReportTestingOverview extends Component {
  static defaultProps = {
    collection: [],
    isLoading: false
  }

  render(){
    const { collection, isLoading } = this.props 
    return(
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading }>
          <Header headerName='รายงานการทำข้อสอบ'
            closeSearch={this.closeSearch}
            match={this.props.match}
            data={collection}
            year={this.props.year}
          />

          <Overview data={collection}
            typeStatic={'testing'}
            loadStats={this.props.loadStats}
            loadDetail={this.props.loadDetail}
            loadType={this.props.loadType}
            history={this.props.history}
            match={this.props.match}
            setYear={this.props.setYear}
            setMonth={this.props.setMonth}
            year={this.props.year}
            type
            detail='grade'
          />
        </Spin>
      </div>)
  }
}

export default ReportTestingOverview
