import { FetchCycle, AxiosRequest } from '../fetch'
// Reducer =====================================================================

export const SET_TESTING              = 'testing/SET_TESTING'

// API =====================================================================

const API_START_TESTING                     = '/testing/start'
const API_TESTING                           = '/testing'

// Fetch ===================================================================

const beginTesting  = (data)    => () => AxiosRequest(API_START_TESTING, 'post', data)
const answerTesting = (id,data) => () => AxiosRequest(`${API_TESTING}/${id}/answer`,'post',data)
const snapTimeTesting = (id,data) => () => AxiosRequest(`${API_TESTING}/${id}/snap-time`,'post', data)
const finishTesting = (id)      => () => AxiosRequest(`${API_TESTING}/${id}/finish`,'post', {})
const resultTesting = (id)      => () => AxiosRequest(`${API_TESTING}/${id}/result`,'get', {})
const markTesting   = (id,data) => () => AxiosRequest(`${API_TESTING}/${id}/mark`,'post', data)
const getSolutionTesting   = (id) => () => AxiosRequest(`${API_TESTING}/${id}/solution`,'get')

// Action Callback =====================================================================

const setTesting = (data) => {
  const testing =  {
    _id: data._id,
    userId: data.userId,
    examId: data.examId,
    progressTestings: data.progressTestings,
    questions: data.questions.map(q => { 
      return q
    })
  }
  
  return {
    type: SET_TESTING,
    testing
  }
}

// Action =====================================================================

export const startTesting     = (data)    => FetchCycle(beginTesting(data), setTesting)
export const sendAnswer       = (id,data) => FetchCycle(answerTesting(id, data))
export const snapTime         = (id,data) => FetchCycle(snapTimeTesting(id, data))
export const doneTesting      = (id)      => FetchCycle(finishTesting(id))
export const getResultTesting = (id)      => FetchCycle(resultTesting(id))
export const markQuestion     = (id,data) => FetchCycle(markTesting(id,data))
export const getSolution     = (id) => FetchCycle(getSolutionTesting(id))
