import React, { Component }  from 'react'
import {
  Layout,
  BackTop
} from 'antd'
import styles from './Testing.module.scss'
import OptionChoice from './OptionChoice'
import NoteAnswer from './Note'
import { displayer } from './helper' 
const { Content } = Layout

class MatchChoiceTesting extends Component {
  constructor(props){
    super(props)
    this.myRef = React.createRef()
    this.state = {
      clickOption: false,
      borderGray: '2px solid #BDBDBD',
      borderOrange: '2px solid #F7941D',
      choose: null,
      note: {
        text: '',
        file: null,
      },
      progress: props.progress
    }
  }

  onClickChoice = (choose) => {
    const { question } = this.props
    const { note } = this.state

    const answer = {
      questionId: question._id,
      type: 'MC',
      order: question.seq,
      orgorder: question.orgseq,
      answer: choose,
      note
    }

    this.props.onSetAnswer(answer)
  }

  setNote = (data, type) => {
    let { note } = this.state
    const { question, progressTesting } = this.props
    note =  progressTesting && progressTesting.note !== undefined ? progressTesting.note : note
    note[type] = data
    note.isChanged = true
    this.props.onSetNote(question.seq, note)
  } 
  render() {  
    const { progressTesting, question, isSolution, displayHowTo, testingId } = this.props
    let { choose, note } = this.state
    
    if (progressTesting) {
      choose = progressTesting.answer
      note =  progressTesting.note !== undefined ? progressTesting.note : note
    }  
    return (
      <Content className={styles.content_testing}>
        <BackTop visibilityHeight	= {10}/>
        <div className='match-choice-testing'>
          <h2 id = 'myDIV' ref={this.myRef} className = { styles.num_of_question }>ข้อที่ {question.seq}.</h2>

          <div className='question-content'>

            {displayer(question.text)}
          </div>

          <div className={styles.all_choice}>
            {
              question.answers.map((answer, i) =>
                <OptionChoice
                  key={i}
                  answer={answer}
                  choose={choose}
                  onClickChoice={this.onClickChoice}
                  isSolution={isSolution}
                />
              )
            }
          </div>
          {isSolution && (
            <div className='possible-answer'>  
              {/* <span dangerouslySetInnerHTML={{__html: question.explanation}} />  */}
              {/* <span>{displayer(question.explanation)}</span> */}
	      <p style={{paddingTop: '4ex', paddingBottom: '0ex', marginBottom: '0ex', fontWeight: 'bold'}}>คำอธิบาย/แนวการตอบ</p>
              {displayer(question.explanation)}
            </div>
          )}
          { displayHowTo && (
            <div className='how-to'>
              <NoteAnswer explanation={note} onUploadFile={(data) => this.setNote(data, 'file')} onChangeNoteText={(data) => this.setNote(data, 'text')} note={note} testingId={testingId} questionId={question._id} isSolution={isSolution} />
            </div>
          )}
        </div>
      </Content>
    )
  }
}
export default MatchChoiceTesting
