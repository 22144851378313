/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React,{ Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from './Report.module.scss'
import {
  Link,
} from 'react-router-dom'
import {
  Button,
  Input
} from 'antd'
import ReactExport from 'react-export-excel'
import { searchGroupList } from '../../redux/group/action' 
import IconSearch from '../IconSVG/Search.svg' 
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class Header extends Component { 
  constructor(props){
    super(props)
    this.state = {
      value: '',
      searchKey: null,
      searchKeyword: null,
      examsFilters: [],
      checkEdit : false
    }
  }
  
  handleKeyPress = (value) => { 
    setTimeout(()=>{
      this.setState({
        searchKey: value
      })
      if(value === '' && this.state.checkEdit === true){
        this.handleSearch()
        this.setState({
          checkEdit : false
        })
      }
    },50)
  }
  
  handleSearch = async(value) => {
    const { dataSearch,setExamsFiltersBySearch } = this.props
    let { searchKeyword, examsFilters } = this.state
    let isEmptySearch = true
    searchKeyword = value  
    this.setState({
      checkEdit : true
    })
    if (searchKeyword && this.props.match.path === '/report') {
      isEmptySearch = false
      examsFilters = dataSearch ? dataSearch.filter(r => r.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 || r.code.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)  : dataSearch
    } else if (searchKeyword) {
      isEmptySearch = false
      examsFilters = dataSearch ? dataSearch.filter(r => r.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)  : dataSearch
    }
    setExamsFiltersBySearch(examsFilters, isEmptySearch)
  }
  
  render(){
    const { searchKey, 
    } = this.state
    const {
      // examsFilters, searchKey,
      noSearch, match , dataSearch,
      auth, TabActiveKey
    } = this.props
     
    let getExamId = null
    getExamId = localStorage.getItem('examId')
    let excelData = dataSearch

    const excel = auth.role === 'teacher' ?
      match.path === '/report/:examId/groups' ?
        TabActiveKey === 1 ?
          <ExcelSheet data={ excelData } name='Employees'>
            <ExcelColumn label='ชื่อ' value='name'/>
            <ExcelColumn label='สมาชิก' value='totalStudent'/>
            <ExcelColumn label='จำนวนผู้ทำ' value='totalStudentTestings'/>
            <ExcelColumn label='วันที่ทำล่าสุด' value='latestStartedAt'/>
            <ExcelColumn label='ต่ำสุด' value='minScore'/>
            <ExcelColumn label='สูงสุด' value='maxScore'/>
            <ExcelColumn label='เฉลี่ย' value='avgScore'/>
          </ExcelSheet>
          :
          <ExcelSheet data={ excelData } name='Employees'>
            <ExcelColumn label='ชื่อ' value='name'/>
            <ExcelColumn label='โรงเรียน' value='schoolName'/>
            <ExcelColumn label='ชื่อกลุ่ม' value='group'/>
            <ExcelColumn label='จำนวนครั้งที่ทำ' value='count'/>
            <ExcelColumn label='วันที่ทำล่าสุด' value='latestStartedAt'/>
            <ExcelColumn label='คะแนนที่ได้' value='latestScore'/>
          </ExcelSheet>
        :
        <ExcelSheet data={ excelData } name='Employees'>
          <ExcelColumn label='ชื่อ' value='name'/>
          <ExcelColumn label='โรงเรียน' value='schoolName'/>
          <ExcelColumn label='จำนวนครั้งที่ทำ' value='totalStudentTestings'/>
          <ExcelColumn label='วันที่ทำล่าสุด' value='latestStartedAt'/>
          <ExcelColumn label='คะแนนที่ได้' value='latestScore'/>
        </ExcelSheet> :
      ''

    const searchReportList = (
      <div className={styles.search_report}>
        <div className='mobile'>
          <Button
            type='secondary'
            onClick={() => this.props.showSearchModal()}>
            <img src={IconSearch} alt='icon-search'/>
          </Button>
        </div>

        <div className='desktop'>
          <Input
            suffix={
              <Button
                type='primary'
                style={{paddingLeft: 5, paddingRight: 10}}
                onClick={() => this.props.handleSearch(searchKey)}>
                <img src={IconSearch} alt='icon-search'/>ค้นหา
              </Button>
            }
            placeholder='ค้นหา (ชื่อ)'
            maxLength={100}
            className={styles.inputSearch}
            // onChange = {(e) => this.props.handleKeyPress(e.target.value)}
            // onPressEnter={() => this.props.handleSearch(searchKey)}
            onChange = {(e) => this.handleKeyPress(e.target.value)}
            onPressEnter={() => this.handleSearch(searchKey)}
          />
        </div>
      </div>
    )
    
    return(
      <div className={styles.report_header}>
        {
          match.path === '/report/:examId/groups' ?
            <Fragment>
              <div className='flex-space-between top-header'> 
                {
                  this.props.location.state.cameFrom === 'allExam' ?
                    <div>
                      <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={'/examsAll'}>ข้อสอบทั้งหมด</Link>
                      <span style={{color: '#828282'}}>{' > '}</span>
                      <span style={{color: '#828282'}}>{'รายงาน'}</span>
                    </div> 
                    : this.props.location.state.cameFrom === 'suggestExam' ?
                      <div>
                        <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={'/suggest'}>ข้อสอบแนะนำ</Link>
                        <span style={{color: '#828282'}}>{' > '}</span>
                        <span style={{color: '#828282'}}>{'รายงาน'}</span>
                      </div>
                      : this.props.location.state.cameFrom === 'exam' ? 
                        <div>
                          <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={'/examset'}>ข้อสอบของฉัน</Link>
                          <span style={{color: '#828282'}}>{' > '}</span>
                          <span style={{color: '#828282'}}>{'รายงาน'}</span>
                        </div>
                        : <div>
                          <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={'/group'}>กลุ่ม</Link>
                          <span style={{color: '#828282'}}>{' > '}</span>
                          <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={`/group/${this.props.location.state.groupId}`}>{`${this.props.location.state.groupName}`}</Link>
                          <span style={{color: '#828282'}}>{' > '}</span>
                          <span style={{color: '#828282'}}>{'รายงาน'}</span>
                        </div>
                }
                { !noSearch && searchReportList }
              </div>

              <h1>รายงาน</h1>
            </Fragment>
            :
            match.path === '/report/:examId/group/:groupId' ?
              <div className='flex-space-between top-header'>
                {
                  this.props.location !== undefined && this.props.location.state !== undefined && this.props.location.state.cameFrom !== undefined && this.props.location.state.cameFrom === 'exam' ?  
                    <div>
                      <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={'/examset'}>ข้อสอบของฉัน</Link>
                      <span style={{color: '#828282'}}>{' > '}</span>
                      <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={{ pathname: `/report/${getExamId}/groups`, state: { cameFrom : 'exam' } } }>รายงาน</Link>
                      <span style={{color: '#828282'}}>{' > รายละเอียดกลุ่ม'}</span>
                    </div>
                    :
this.props.location !== undefined && this.props.location.groupId !== undefined ?
                    <div>
                      <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={'/group'}>กลุ่ม</Link>
                      <span style={{color: '#828282'}}>{' > '}</span>
                      <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={`/group/${this.props.location.state.groupId}`}>รายละเอียด</Link>
                      <span style={{color: '#828282'}}>{' > รายงาน'}</span>
                    </div>
                    :
''
                } 
                { !noSearch && searchReportList }
              </div>
              :
              match.path === '/report/:examId' || match.path === '/report/exam/:examId/group/:groupId' ?
                <Fragment>
                  {
                    this.props.location.state.cameFrom === 'exam' || !this.props.location.state.groupId || !this.props.location.state.groupName ? 
                      <div className='flex-space-between top-header'>
                        <div>
                          <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={'/examset'}>ข้อสอบของฉัน</Link>
                          <span style={{color: '#828282'}}>{' > รายงาน'}</span>
                        </div>
                      </div>
                      : 
                      <div className='flex-space-between top-header'>
                        <div>
                          <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={'/group'}>กลุ่ม</Link>
                          <span style={{color: '#828282'}}>{' > '}</span>
                          <Link style={{color: '#006FAD', margin: '0', textDecoration: 'underline'}} to={`/group/${this.props.location.state.groupId}`}>รายละเอียด</Link>
                          <span style={{color: '#828282'}}>{' > รายงาน'}</span>
                        </div>
                      </div>
                  } 
                  <h1>รายงาน</h1>
                </Fragment>
                :
                <div className='flex-space-between top-header'>
                  <h1>รายงาน</h1>

                  {!noSearch && searchReportList}
                </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    auth: state.auth.profile
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchGroupList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Header)
