import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  deleteSchool,
  onUpdateSchool,
  onCreateSchool,
  getDepartmentsDetail,
  getProvinceList,
  onImportExcelSchool
} from '../../redux/school/action'  
import EditForm from './EditForm'
import AddForm from './AddForm'
import {
  Modal,
  Button, 
  Row,
  Col,  
  Upload,
  message
} from 'antd'
import BigWarningIcon from '../IconSVG/BigWarningIcon.svg'
import FileIcon from '../IconSVG/FileIcon.svg'
import IconButtonExcelUpload from '../IconSVG/IconButtonExcelUpload.svg'
import styles from './School.module.scss'  

class ModalSchool extends Component {
  constructor(props){ 
    super(props)
    this.state = {
      createPrefix: {name: null},
      dataSchool: {
        name: null,
        addressNo: null,
        villageNo: null,
        lane: null,
        road:null,
        subDistrict:null,
        district: null,
        province: null,
        postalCode: null,
        department: null,
        remark: null, 
      },
      departmentFull: null, 
      provinceList: null,   
      excelErrorMessage: '',
      excelPopupAlert: false,
    } 
  } 
  componentDidMount = () => {
    this.fetchGetDepartments()
  }
  fetchGetDepartments = async() => {
    const { getDepartmentsDetail    } = this.props
    const res = await getDepartmentsDetail() 
    this.setState({
      departmentFull: res 
    })
  }  
  setStateData = () => {
    this.setState({ 
      dataSchool: {
        name: null,
        addressNo: null,
        villageNo: null,
        lane: null,
        road:null,
        subDistrict:null,
        district: null,
        province: null,
        postalCode: null,
        department: null,
        remark: null, 
      }, })
  }
  onAddData = (e,typeOfSubmit) => { 
    const { fetch, onCloseModal, schoolId, provinceList } = this.props 
    // e.preventDefault()  
    
    this.props.form.validateFieldsAndScroll((err, values) => {   
      if (!err ) { 
        let data = {
          addressNo: values.addressNo,
          district: values.district,
          lane: values.lane,
          name: values.name,
          postalCode: values.postalCode, 
          province:   provinceList.items.find(p => p.name === values.province)._id , 
          remark: values.remark,
          road: values.road,
          subDistrict: values.subDistrict,
          villageNo: values.villageNo
        } 
        if(typeOfSubmit === 'edit'){ 
          this.props.onUpdateSchool(schoolId,data)
        }else {  
          this.props.onCreateSchool(data)
        } 
        fetch()
        this.setStateData()
        onCloseModal()
        this.setState({ isEdit: false})  
      }
    }) 
  }
  onDeleteSchool = async(schoolId) => { 
    const { onCloseModal,deleteSchool,fetch } = this.props
    await deleteSchool(schoolId) 
    message.success('ลบโรงเรียนสำเร็จ')
    await fetch()
    onCloseModal()
  }
  closeExcelErrorAlart = () => {
    this.setState({
      excelPopupAlert: false
    })
  }
  onUpload = async() => {
    const { onCloseModal,fetch,onImportExcelSchool } = this.props  
    if(this.state.file === undefined || this.state.file === null){ 
      message.error('กรุณาเลือกไฟล์')
      onCloseModal()
    }else{  
      // eslint-disable-next-line no-undef
      const params  = new FormData()
      params.set( 'schoolsImportFile', this.state.file )  
      try{
        const res = await onImportExcelSchool(params)  
        if(res.error) return message.error(`เกิดข้อผิดพลาด : ${res.error}`)
        message.success('เพิ่มข้อมูลโรงเรียนสำเร็จ')
        fetch()
        onCloseModal()
      } catch (error) {
        message.error('เกิดข้อผิดพลาด')
      } 
    }
  }
  handleUploadChange = info => {
    let fileList = [...info.fileList]
    fileList = fileList.slice(-1)
    fileList = fileList.map(file => {
      if (file.response) {
        file.status = 'uploading'
        file.status = 'done'
      }
      return file
    })
    let file = null
    if(fileList.length !== 0){
      file = fileList[0].originFileObj
    }
    this.setState({ 
      fileList, 
      file: file,
      fileContent: file
    })
  }
  render(){
    const { modalVisible, onCloseModal,schoolId , items, typeModal , provinceList,indexRow,onUpdateSchool, onCreateSchool ,fetch  } = this.props
    const { departmentFull   } = this.state     
    const uploadProps = {
      name: 'file',
      action: '',
      accept: '.xlsx',
      onChange: this.handleUploadChange,
      multiple: false,
      defaultFileList:[{
        status: 'done'
      }]
    } 
    const deleteModal = (
      <div>
        <Row>
          <Col span={24} align='center'>
            <div>
              <img src={BigWarningIcon}/>
            </div>
            <h1>ลบโรงเรียน</h1>
            <h3>{items ? items.name : ''}</h3>
            <span>คุณต้องการลบโรงเรียนนี้หรือไม่</span>
            <Row style={{ paddingTop: '25px' }} >
              <Col span={12} >
                <Button onClick={e=>{
                  e.stopPropagation()
                  onCloseModal()
                }} style={{width: '70%', float: 'right', marginRight: '15px'}}>ไม่ลบ</Button>
              </Col>
              <Col span={12}>
                <Button onClick={e => {
                  e.stopPropagation()
                  this.onDeleteSchool(items._id)
                }} style={{width: '70%', float: 'left',marginLeft: '15px' }} type='primary' >ลบโรงเรียน</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    ) 
    const importExcel = (
      <div className={styles.upload_window_content}>
        <div className={styles.upload_Icon_file}> 
          <img src={IconButtonExcelUpload} alt='IconButtonExcelUpload' />
        </div>
        <div className={styles.upload_window_title}>Upload Excel</div>
        <div className={styles.clickable}>
          <Upload 
            {...uploadProps}
            fileList={this.state.fileList}
            onChange={this.handleUploadChange}
            style={{ cursor:'pointer' }}
          >
            <img src={FileIcon} alt='FileIcon' />
            <span>แนบไฟล์</span>
          </Upload>
        </div>
      </div>
    )
    return ( 
      <div>
        <Modal 
          width= {typeModal === 'delete' || typeModal === 'importData' ? '30%' : '50%'}
          closable={true}
          visible={modalVisible}
          destroyOnClose={true}
          onCancel={onCloseModal} 
          bodyStyle={ typeModal === 'importData' ? { textAlign: 'center' } : null} 
          footer={ typeModal === 'importData' ? [
            <Button className={styles.upload_file_button} key='back' onClick={e => {
              e.stopPropagation()
              onCloseModal()
            }}
            style={{width: '30%'}}
            >ยกเลิก</Button>,
            <Button className={styles.upload_file_button} key='submit' type='primary' 
              onClick={e => {
                e.stopPropagation()
                this.onUpload()
              }}
              style={{width: '30%'}}
            > 
              อัปโหลด
            </Button>
          ] : false}
        >  
          {(() => {
            switch(typeModal){
              case 'edit'       : return <EditForm schoolId={schoolId} onUpdateSchool={onUpdateSchool} fetch={fetch} onAddData={this.onAddData} departmentFull={departmentFull} items={items} onCloseModal={onCloseModal} provinceList={provinceList} indexRow={indexRow}/>
              case 'delete'     : return <div>{deleteModal}</div>
              case 'add'        : return <div><AddForm fetch={fetch} onCreateSchool={onCreateSchool} onAddData={this.onAddData} onCloseModal={onCloseModal} departmentFull={departmentFull} provinceList={provinceList}/></div>
              case 'importData' : return <div>{importExcel}</div>
              default           : return ''
            }
          })()}
        </Modal>
      </div>
    )
  }
}
 
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
      deleteSchool,
      onUpdateSchool,
      getDepartmentsDetail,
      getProvinceList,
      onCreateSchool,
      onImportExcelSchool
    },
    dispatch
  ) 
export default connect(null,mapDispatchToProps)(ModalSchool)