import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'  
import styles from './School.module.scss'
import { 
  Button,
  Input,
  Row,
  Col,
  Divider, 
  Select,
  Form,
  message  
} from 'antd' 
const { Option } = Select

class AddForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      dataSchool: {
        name: null,
        addressNo: null,
        villageNo: null,
        lane: null,
        road:null,
        subDistrict:null,
        district: null,
        province: null,
        postalCode: null,
        department: null,
        remark: null, 
      },
    }
  }
  setStateData = () => {
    this.setState({ 
      dataSchool: {
        name: null,
        addressNo: null,
        villageNo: null,
        lane: null,
        road:null,
        subDistrict:null,
        district: null,
        province: null,
        postalCode: null,
        department: null,
        remark: null, 
      }, })
  }
  onAddData = (e) => { 
    const { fetch, onCloseModal, provinceList } = this.props 
    e.preventDefault()   
    this.props.form.validateFieldsAndScroll( async(err, values) => {    
      if (!err ) { 
        let data = {
          addressNo: values.addressNo,
          district: values.district,
          lane: values.lane,
          name: values.name,
          postalCode: values.postalCode, 
          province:   provinceList.items.find(p => p.name === values.province)._id , 
          remark: values.remark,
          road: values.road,
          subDistrict: values.subDistrict,
          villageNo: values.villageNo,
          department: values.department
        } 
        const res = await this.props.onCreateSchool(data) 
        if(res.error){ 
          message.error(res.error)
        }else{ 
          message.success('เพิ่มโรงเรียนสำเร็จ')
        }
        await fetch()
        this.setStateData()
        onCloseModal()
        this.setState({ isEdit: false})  
      } 
    }) 
  }

  render(){
    const {  departmentFull,form: { getFieldDecorator }, provinceList, onCloseModal } = this.props
    
    return (
      <Form onSubmit={this.onAddData} hideRequiredMark={true}>   
        <Row className={styles.row_form}>
          <h1 >เพิ่มข้อมูล</h1> 
          <Col offset={4} span={16} className={styles.col_form}> 
            <Row className={styles.row_in_form}> 
              <Form.Item label='โรงเรียน/หน่วยงาน'>
                {
                  getFieldDecorator('name', {
                    rules: [ 
                      {
                        required: true,
                        min: 1,
                        message: 'กรุณากรอกชื่อโรงเรียน',
                      }, 
                    ],
                    initialValue: ''  
                  }
                  )(<Input/>)} 
              </Form.Item> 
            </Row>  
            <Row className={styles.row_in_form}>
              <Col span={11}> 
                <Form.Item label='เลขที่'>
                  {
                    getFieldDecorator('addressNo', {
                      rules: [ 
                        {
                          required: true,
                          min: 1,
                          message: 'กรุณากรอกเลขที่',
                        }, 
                      ],
                      initialValue: ''  
                    }
                    )(<Input/>)} 
                </Form.Item> 
              </Col>
              <Col offset={2} span={11}> 
                <Form.Item label='หมู่ที่'>
                  { 
                    getFieldDecorator('villageNo',
                      {
                        rules: [
                          {
                            pattern: /^[0-9/]*$/,
                            message: 'กรุณาใส่ตัวเลข'
                          } 
                        ]
                      },  
                      { initialValue: '' } )(<Input/>)
                  } 
                </Form.Item>  
              </Col>
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='ซอย'>
                {
                  getFieldDecorator('lane', {
                    rules: [ 
                      {
                        min: 1,
                        message: 'กรุณากรอกซอย',
                      }, 
                    ],
                    initialValue: ''  
                  }
                  )(<Input/>)} 
              </Form.Item>   
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='ถนน'>
                {
                  getFieldDecorator('road', {
                    rules: [ 
                      {
                        required: false,
                        min: 1,
                        message: 'กรุณากรอกถนน',
                      }, 
                    ],  
                    initialValue: '' 
                  }
                  )(<Input/>)} 
              </Form.Item>  
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='ตำบล/แขวง'>
                {
                  getFieldDecorator('subDistrict', {
                    rules: [ 
                      {
                        required: true,
                        min: 1,
                        message: 'กรุณากรอกตำบล/แขวง',
                      }, 
                    ],
                    initialValue: ''  
                  }
                  )(<Input/>)} 
              </Form.Item> 
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='อำเภอ/เขต'>
                {
                  getFieldDecorator('district', {
                    rules: [ 
                      {
                        required: true,
                        min: 1,
                        message: 'กรุณากรอกอำเภอ/เขต',
                      }, 
                    ],
                    initialValue: ''  
                  }
                  )(<Input/>)} 
              </Form.Item>  
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='จังหวัด'>
                {
                  getFieldDecorator('province', {
                    rules: [ 
                      { 
                        required: true,
                        min: 1,
                        message: 'กรุณากรอกจังหวัด',
                      }
                    ], 
                    initialValue: ''
                  }
                  )(<Select  
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='กรุณาระบุ'  
                  >
                    { 
                      provinceList ?
                        provinceList.items.map((p,i) => <Option key={i} value={p.name}>{p.name}</Option>)
                        :''
                    } 
                  </Select>)} 
              </Form.Item>    
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='รหัสไปรษณีย์'>
                {
                  getFieldDecorator('postalCode', {
                    rules: [ 
                      {
                        required: true,
                        pattern: /^[0-9]{5}$/,
                        message: 'กรุณาใส่รหัสไปรษณีย์ให้ครบ 5 หลัก'
                      }, 
                    ], 
                    initialValue: '' 
                  }
                  )(<Input/>)} 
              </Form.Item> 
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='สังกัดของโรงเรียน/หน่วยงาน'>
                {
                  getFieldDecorator('department', {
                    rules: [ 
                      {
                        required: true,
                        min: 1,
                        message: 'กรุณาระบุสังกัดของโรงเรียน/หน่วยงาน',
                      }, 
                    ],
                    initialValue: ''  
                  }
                  )(<Select  
                    showSearch
                    style={{ width: '100%' }} 
                  >
                    { 
                      departmentFull ?
                        departmentFull.items.map((d,i) => <Option key={i} value={d.name}>{d.name}</Option>)
                        :''
                    } 
                  </Select>)}  
              </Form.Item>
            </Row>
            <Row className={styles.row_in_form}>
              <Form.Item label='หมายเหตุ'>
                { getFieldDecorator('remark')(<Input/>) } 
              </Form.Item>  
            </Row>
          </Col>
          <Divider/>
          <Row> 
            <Col span={12}>
              <Form.Item>
                <p className={styles.cancel_button} onClick={onCloseModal}>ยกเลิก</p>
              </Form.Item>            
            </Col> 
            <Col span={12}>
              <Form.Item >
                <Button className={styles.button_save} htmlType='submit' type='primary' >
                  เพิ่มข้อมูล
                </Button>
              </Form.Item>
            </Col>  
          </Row>
        </Row> 
      </Form>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
       
    },
    dispatch
  )
const WrappedFormDataSchool = Form.create({ name: 'Add_form_school' })(AddForm)
export default connect(mapStateToProps,mapDispatchToProps)(WrappedFormDataSchool)