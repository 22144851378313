import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form, 
} from 'antd' 
import { generateInputForm } from '../../util/formGenerator'
import ResetPasswordSuccess from './ResetPasswordSuccess'
import { IconValidate } from '../IconSVG'
import styles from '../Register/Register.module.scss'
 

const regExpContainAlphabet = /[a-zA-Z]+/
const regExpContainNumeric = /[0-9]+/
const regExpStrongPassword = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/

const formItemLayout = {
  colon: false,
  labelAlign: 'left',
  hideRequiredMark: true,
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
}

class FormResetPassword extends Component {
  
  state = {
    isFormDirty: false,
    isPasswordMin6: false,
    isPasswordHaveAlphabet: false,
    isPasswordHaveNumberic: false,
  }
  
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onSubmit(values)
        // this.setState({ email: values.email })
      }
    })
  }

  matchPassword = (rule, value, callback) => {
    if ( value && value !== this.props.form.getFieldValue('password') ) {
      callback('รหัสผ่านยืนยันไม่ตรงกัน')
    }
    callback()
  }

  onChangePassword = (e) => {
    const value = e.target.value
    this.setState({
      isPasswordMin6: (value && value.length >= 6),
      isPasswordHaveAlphabet: value && regExpContainAlphabet.test(value),
      isPasswordHaveNumberic: value && regExpContainNumeric.test(value),
    })
    this.props.form.isFieldsTouched = false
  }

  render() {
    const { form, isHasResetPassword, history } = this.props
    const { isFormDirty, isPasswordMin6, isPasswordHaveAlphabet, isPasswordHaveNumberic } = this.state
    const inputPassword = generateInputForm({ fieldName: 'password', form, label: 'รหัสผ่าน', isFormDirty, limit: 20, customRules: [
      { required: true, message: 'กรุณาใส่รหัสผ่าน' },
      { min: 6, message: 'มีอย่างน้อย 6 ตัว' },
      { pattern: regExpStrongPassword, message: 'ประกอบด้วยตัวอักษรภาษาอังกฤษและตัวเลข' },
    ], props: {
      type: 'password',
      autoComplete: 'new-password',
      onChange: this.onChangePassword
    } })
    const inputConfirmPassword = generateInputForm({ fieldName: 'confirm_password', form, label: 'ยืนยันรหัสผ่าน', isFormDirty, limit: 20, customRules: [
      { required: true, message: 'กรุณายืนยันรหัสผ่าน' },
      { validator: this.matchPassword}
    ], props: {
      type: 'password',
      autoComplete: 'new-password',
    } })

    return !isHasResetPassword ?
      (<Fragment>
        <div className='flex-space-between' style={{ marginBottom: 0 }}>
          <h2 className='text-medium mb-0' style={{ color: '#05007A' }}>รหัสผ่านใหม่</h2>
        </div>
        <Form {...formItemLayout} onSubmit={this.handleSubmit} >

          {inputPassword}
          
          <p className={`${styles.textValidate} text-thin text-sm`} ><IconValidate correct={ isPasswordHaveAlphabet ? 1 : undefined } className={styles.iconValidate} /> ประกอบด้วยตัวอักษรภาษาอังกฤษ</p>
          <p className={`${styles.textValidate} text-thin text-sm`} ><IconValidate correct={ isPasswordHaveNumberic ? 1 : undefined } className={styles.iconValidate} /> ประกอบด้วยตัวเลข</p>
          <p className={`${styles.textValidate} text-thin text-sm`} ><IconValidate correct={ isPasswordMin6 ? 1 : undefined } className={styles.iconValidate} /> มีอย่างน้อย 6 ตัว</p>
          
          {inputConfirmPassword}

          <Button id='nextStepRegister' type='primary' htmlType='submit' style={{ width: '100%', marginTop: 10 }}>
            ตั้งรหัสผ่านใหม่
          </Button>
          
        </Form>
      </Fragment>) : (<ResetPasswordSuccess history={history} />)
  }
}


FormResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

const WrappedFormResetPassword = Form.create({ name: 'form_login' })(FormResetPassword)
export default WrappedFormResetPassword