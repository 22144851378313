import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Table,
  Checkbox,
  Modal,
  Avatar
} from 'antd'
import {
  IconCorrect,
  IconIncorrect,
} from '../../IconSVG'
import GoupMoveMemberBigIcon from '../../IconSVG/GoupMoveMemberBigIcon.svg' 
import DefaultProfile from '../../IconSVG/DefaultProfile.svg'
import styles from '../Group.module.scss'
import * as moment from 'moment'
import ModalNotAllowedStudent from '../ModalNotAllowedStudent'
import ListItems from '../../Display/ListItems'

class DetailStudentRequest extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedRows: [],
      visibleModalNotAllowed: false,
      dataStudent: [],

      checkSiftOut: [],
      isCheckAll: false, 
      isMobile: false,
      visibleModalDelete: false
    }
  }

  columns = [
    { title: '', dataIndex: 'profileImage',
      align: 'center',
      render: profileImage => <img src={profileImage ? profileImage : DefaultProfile} alt='profileImage' className={styles.logoGroup}/>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'studentName',
    },
    {
      title: 'โรงเรียน',
      dataIndex: 'schoolName',
    },
    {
      title: 'วันที่ขอเข้ากลุ่ม',
      dataIndex: 'requestedDate',
      render: requestedDate => requestedDate ? <span>{moment.unix(requestedDate).format('DD/MM/YYYY')}</span> : <span>-</span>
    },
    {
      title: '',
      key: 'action',
      render: (text, student) => {
        const { selectedRows } = this.state 
        return selectedRows.length > 0 ? null : (
          <span className={styles.tableAction}>
            <span onClick={ e => {
              e.stopPropagation()
              this.openModalNotAllowed([student])
              // this.props.onRejectStudentGroup([student])
            }}>
              <IconIncorrect title={'ปฏิเสธ'} className={styles.clickable} style={{fontSize: 24, verticalAlign: 'text-bottom', marginRight: 5}}/>
            </span>
            <span onClick={ e => {
              e.stopPropagation()
              this.props.onApproveStudentGroup([student])
            }}>
              <IconCorrect title={'รับเข้ากลุ่ม'} className={styles.clickable} style={{fontSize: 24, verticalAlign: 'text-bottom', marginRight: 5}}/>
            </span>
          </span>
        )
      },
    },
  ]
  componentDidMount = () => {
    this.setDefaultRemoveStudentArray() 
  }
  
  UNSAFE_componentWillReceiveProps = (nextProps) => { 
    let { checkSiftOut } = this.state  
    nextProps.data.forEach((item, i) => {
      checkSiftOut[i] = { studentId: null, isCheck: false }
    })
    this.setState({ checkSiftOut })
  }

  setDefaultRemoveStudentArray = async() => {
    const { data } = this.props
    let { checkSiftOut } = this.state
    data.forEach((item, i) => {
      checkSiftOut[i] = { studentId: null, isCheck: false }
    })
    this.setState({ checkSiftOut })
  }

  onApproveSelectedGroup = async() => {
    let { selectedRows } = this.state
    
    const { checkSiftOut, isMobile } = this.state 
    if (isMobile) {
      selectedRows = checkSiftOut.filter((item) => item.isCheck) 
    } 
    await this.props.onApproveStudentGroup(selectedRows)
    this.setState({ selectedRows: [], isMobile: false  })
  }

  onRejectSelectedGroup = () => {
    let { selectedRows } = this.state
    const { checkSiftOut, isMobile } = this.state 
    if (isMobile) {
      selectedRows = checkSiftOut.filter(item => item.isCheck)
    }
    this.props.onRejectStudentGroup(selectedRows)
    this.setState({ selectedRows: [], isMobile: false })
    this.handleCancel()
  }
  openModalNotAllowed = (dataStudent) => { this.setState({ dataStudent, visibleModalNotAllowed : true }) }
  closeModalNotAllowed = () => { this.setState({ visibleModalNotAllowed : false }) }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ selectedRows: selectedRows })
    },
  }
  handleCancel = ()=> {
    this.setState({
      visibleModalDelete : false,
      visibleModalRemove: false
    })
  }
  onChangeCheckBox = ( isCheck ,index, studentId ) => {
    let { checkSiftOut, isCheckAll } = this.state   
    if (isCheck) { 
      checkSiftOut[index] = { studentId: studentId, isCheck: isCheck }
    }else if (isCheckAll) { 
      isCheckAll = false
      checkSiftOut[index] = { studentId: studentId, isCheck: isCheck }
      checkSiftOut.map((item,i) => {
        if (i !== index) {
          item.isCheck = true
        }
      })
    } else { 
      checkSiftOut[index] = { studentId: studentId, isCheck: isCheck }
    }
    let arr = true
    checkSiftOut.map((item ) => { 
      if(!item.isCheck) arr = false 
    })
    this.setState( arr ? { checkSiftOut, isCheckAll: arr } : { checkSiftOut, isCheckAll }) 
  }
  onCheckAll = () => {
    let { isCheckAll, checkSiftOut } = this.state
    const { data } = this.props
    isCheckAll = !isCheckAll
    if ( !isCheckAll ) {
      checkSiftOut.map(( item ) => {
        item.isCheck = false
      })
    } else {
      data.map(( item,i ) => {
        checkSiftOut[i].isCheck = true
        checkSiftOut[i].studentId = data[i].studentId
      })
    }
    this.setState({ isCheckAll, checkSiftOut })
  }
  render() {
    const { selectedRows, visibleModalNotAllowed, dataStudent, isCheckAll , isMobile } = this.state
    const { data } = this.props 
    let { checkSiftOut } = this.state
    let isButtonRemove = false
    let countSelectStudent = 0  
    checkSiftOut.map((item) => {
      if(item.isCheck) {
        isButtonRemove = true 
        countSelectStudent++
      }
    })
    const srcData = data.map(item => {
      const status = item.status
      const menu = [
        {
          attribute: (
            <span className={styles.tableAction}
              hidden = { countSelectStudent > 0 ? true : false }
            >
              <span onClick={ e => {
                e.stopPropagation()
                this.openModalNotAllowed([item])
              }}>
                <IconIncorrect className={styles.clickable} style={{fontSize: 24, verticalAlign: 'text-bottom', marginRight: 5}}/>
              </span>
              <span onClick={ e => {
                e.stopPropagation()
                this.props.onApproveStudentGroup([item])
              }}>
                <IconCorrect className={styles.clickable} style={{fontSize: 24, verticalAlign: 'text-bottom', marginRight: 5}}/>
              </span>
            </span>
          )
        },
      ]

      return {
        avatar: item.profileImage ? item.profileImage : DefaultProfile,
        title: item.studentName,
        description: item.schoolName,
        status: status === 'join' ?
          'เข้าร่วม' : status === 'request' ?
            'รอการเข้าร่วม' : '',
        actions: menu,
        studentId: item.studentId ? item.studentId : null,
        path: false
      }
    })

    return (
      <div className='student-request'>
        <div className='mobile'>
          <div  style = {{ display: 'flex' }}>
            <Checkbox  
              onChange = { e => { e.stopPropagation(); this.onCheckAll() } }
              style = {{ paddingRight: '7px' }} 
              checked = { countSelectStudent === checkSiftOut.length && data.length > 0 }
            /> 
            {
              isButtonRemove && data.length > 0 ? 
                <div style = {{ display: 'flex' }}>
                  <div style = {{ paddingRight: '5px' }}>
                    <Button onClick = { e => {
                      e.stopPropagation()
                      this.setState({ isMobile: true, visibleModalDelete: true }) 
                    }} style = {{ height: '35px', width: '90px' }}>ไม่อนุมัติ</Button>
                  </div>
                  <div>
                    <Button onClick = { async(e) => {
                      e.stopPropagation() 
                      await this.setState({ isMobile: true }) 
                      this.onApproveSelectedGroup()
                    }} style = {{ height: '35px', width: '90px' }}>อนุมัติ</Button>
                  </div>
                </div>
                : 'เลือกทั้งหมด'
            }
          </div>
          
          <ListItems
            isCheckAll = { isCheckAll }
            isCheckbox = { true }
            onChangeCheckBox = { this.onChangeCheckBox }
            checkSiftOut = { checkSiftOut } 
          
            srcData={srcData}
            avatar
            status
            history={this.props.history}
          />
        </div>

        <div className='desktop'>
          { selectedRows.length > 0 ?
            <div style = {{ paddingBottom: '10px' }}>
              <Button onClick={this.onRejectSelectedGroup} size='small' style={{marginRight: 16, minWidth: 100}}>ไม่อนุมัติ</Button>
              <Button onClick={this.onApproveSelectedGroup} size='small' style={{minWidth: 100}} type='primary'>อนุมัติ</Button>
            </div> : ''}
          <Table
            rowKey={ (record,i) => i}
            rowSelection={ this.rowSelection }
            columns={ this.columns }
            dataSource={ data }
            pagination={ false } 
          />
          <Modal
            visible={this.state.visibleModalDelete}
            onCancel={this.handleCancel}
            footer={false}
            centered
          >
            <div style={{textAlign: 'center'}}>
              <Avatar src={GoupMoveMemberBigIcon} shape='square' size={150}/>
              <h2 className='text-medium' style={{fontSize: 20}}>คัดออกจากกลุ่ม</h2>
              <p style={{ fontWeight: '500px', fontSize: '28px' }}>นักเรียนจำนวน {isMobile ? countSelectStudent : selectedRows.length} คน</p>
              <p style={{color: '#4F4F4F', fontSize: 14}}>คัดออกจากกลุ่มนี้หรือไม่</p>
              <div className={styles.modalCreated} >
                <div className={styles.sub_modalCreated} ><Button onClick = {this.handleCancel} className = {styles.btnCancle}>ยกเลิก</Button></div>
                <div className={styles.sub_modalCreated_2} ><Button type = 'primary' onClick = {this.onRejectSelectedGroup} className = {styles.btnLeave}>ไม่อนุมัติ</Button></div>
                <div ><Button type = 'link' onClick = {this.handleCancel} className = {styles.btnCancleMobile}>ยกเลิก</Button></div>
              </div>
            </div>
          </Modal>
          <ModalNotAllowedStudent onRejectStudentGroup={ this.props.onRejectStudentGroup } dataStudent={dataStudent} closeModalNotAllowed={this.closeModalNotAllowed} visible={visibleModalNotAllowed}/>
        </div>
      </div>
    )
  }
}

DetailStudentRequest.propTypes = {
  data: PropTypes.array.isRequired,
  onApproveStudentGroup: PropTypes.func.isRequired,
  onRejectStudentGroup: PropTypes.func.isRequired,
}

export default DetailStudentRequest
