import React, { Component }  from 'react'
import { Button, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import {  
  CGHeader,
  LightAnimation,
  HomeContentOne,
  HomeContentTwo,
  HomeContentThree
} from '../../components/animations'
import ModalServerUnavailable from '../../components/Server/ModalServerUnavailable'
import imgLogo from '../../assets/LogoOnlineTesting.svg' 
import imgTeacher from '../../assets/home/teacher.svg'
import MapOff from '../../assets/home/MapOff.svg'
import imgStudent from '../../assets/home/studentBelow.svg'
import styles from './Home.module.scss' 
  
class Home extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoadPage : false,
      onMouseOverStd : false,
      onMouseOverTchr : false,
      backgroundBtn : '#F7941D',
      backgroundTeachrBtn : '#F7941D',
      isModalServerClose: false,
      isModalOffline: false
    }
  }

  UNSAFE_componentWillReceiveProps({ serverStatus }) {
    if (serverStatus.isOffline) this.setState({ isModalOffline: true })
  }

  goToTesting = () => {  
    const { serverStatus, history } = this.props  
    if (serverStatus && serverStatus.isOffline) { 
      this.onSetIsModalOffline(true)
    } else {
      history.push('/homesuggest')
    }
  }

  onSetIsModalOffline = (value) => this.setState({ isModalOffline: value })
  
  render() { 
    const { serverStatus } = this.props
    return (
      <div className = {styles.HomeContent}>
        <div className = {styles.desktopDisplay}>
          <div className = {styles.divLogo}>
            <Row className = {styles.rowLogo}> 
              <Col xs={24} md={12}>

                <div className={styles.homelogo}><img src={imgLogo}/></div>
                <div className={styles.homeheadtitle}>ระบบการสอบออนไลน์</div>
                <div className={styles.homeheaddetail}>มิติใหม่ของการฝึกฝนและวัดผลสอบ</div>

                <Col xs={24} md={9} className={styles.homecenterxs}>
                  <Button type='primary' onClick ={ e => {
                    e.stopPropagation()
                    this.goToTesting() 
                  }}>ทดลองทำข้อสอบ</Button>
                </Col>
                <Col xs={24} md={12} className={styles.homeusage}>
                </Col>
              </Col>
            </Row>
            <div className = {styles.animation_cgHome}>
              <div className='mobile'>
                <CGHeader width={'100%'} height={'100%'}/>
              </div>
              <div className='desktop'>
                <CGHeader width={'85%'} height={'90%'}/>
              </div>
            </div>
            <Row className = {styles.rowContent}>
              <div className = {styles.detailContainer}>
                {/* -------------------1------------------------- */}
                <div className = {styles.rowOne}>
                  <Row style = {{height : 'auto'}} className = {styles.rowOneDesktop}>
                    <Col>
                      <div className = {styles.divLabelTitle}>
                        <label className = {styles.labelTitle}>ตะลุยทุกข้อจำกัด ด้วยเครื่องมือที่เหมือนผู้ช่วย <br/> พาเราผ่านด่านด้วยความง่ายดาย</label>
                      </div>
                    </Col>
                    <Row>
                      <Col md  = {12} sm = {24}>
                        <div style = {{ height : '390'}}>
                          <div className = {styles.contentOne}>
                            <div className='mobile' width={175} height={175}>
                              <HomeContentOne/>
                            </div>
                            <div className='desktop'> 
                              <HomeContentOne/>
                            </div>
                          </div>
                          <div className={styles.animation_content1}>
                            <div className='mobile'>
                              <LightAnimation width={58} height={81} />
                            </div>

                            <div className='desktop'> 
                              <LightAnimation width={86} height={109} />
                            </div>
                          </div>
                        </div>
                          
                      </Col>
                      <Col md = {12} sm = {24} className = {styles.colDetailOneDesktop}>
                        <div  style = {{height : 'auto'}}>
                          <span className = {styles.detailTitleOne}>ระบบใช้งานง่าย</span>
                          <p className = {styles.detailOne}>
                              เพียงไม่กี่คลิก ก็สามารถทำข้อสอบ <br/> ที่ต้องการได้อย่างง่ายดาย พร้อมดูคะแนน <br/> และเฉลยคำตอบทันทีหลังทำข้อสอบเสร็จ
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                  {/* --------------- Mobile 1 -------------------------------------- */}
                  <Row style = {{height : 'auto'}} className = {styles.rowOneMobile}>
                    <Row className={styles.titleFirstBlock}>
                      <div className = {styles.divLabelTitle}>
                        <label className = {styles.labelTitleMobile} style = {{zIndex : 1000}}>
                          ตะลุยทุกข้อจำกัด <br/> 
                          ด้วยเครื่องมือที่เหมือนผู้ช่วย <br/>
                          พาเราผ่านด่านด้วยความง่ายดาย
                        </label>
                      </div>
                    </Row>
                    <Row> 
                      <Row span = {24} className = {styles.AnimateContentOne}>
                        <div style = {{ height : '390'}}>
                          <div className = {styles.contentOne}>
                            <div className='mobile'>
                              <HomeContentOne width={'100%'} height={'100%'}/>
                            </div>
                          </div>
                          <div className={styles.animation_content1} style = {{}}>
                            <div className='mobile'>
                              <LightAnimation width={58} height={81} />
                            </div>
                          </div>
                        </div>
                      </Row>
                      <Row span = {24} className = {styles.detailContentOne}>
                        <div  style = {{height : 'auto', textAlign : 'center'}}>
                          <span className = {styles.titleDetailMobile}>ระบบใช้งานง่าย</span>
                          <p className = {styles.detailMobile}>
                            เพียงไม่กี่คลิก ก็สามารถทำข้อสอบ <br/> 
                            ที่ต้องการได้อย่างง่ายดาย พร้อมดูคะแนน <br/> 
                            และเฉลยคำตอบทันทีหลังทำข้อสอบเสร็จ
                          </p>
                        </div>
                      </Row>
                    </Row>
                  </Row>
                
                </div>
                {/* -------------------2------------------------- */}
                <div className = {styles.rowTwo}>
                  <Row className = {styles.rowTwoDesktop}>
                    <Col md = {12} className = {styles.colDetailTwoDesktop}>
                      <div className = {styles.divDetailTwo}>
                        <label className = {styles.detailTitleTwoT}>ข้อสอบได้มาตรฐาน</label>
                        <p className = {styles.detailTwo}>
                  คลังข้อสอบที่มีคุณภาพ ตรงตามมาตรฐาน <br/> หลักสูตรแกนกลางการศึกษาขั้นพื้นฐาน <br/> และข้อสอบโครงการต่าง ๆ
                        </p>
                        <a 
                          // href={'/homesuggest'} 
                          onClick={() => this.goToTesting()}
                          className = {styles.linkToSuggestionOne}>ทดลองทำข้อสอบ</a>
                      </div>
                    </Col>
                    <Col md = {12} style = {{height : '100%'}}>
                      <div className = {styles.divImgTwo} >
                        {/* <img className = {styles.contentTwo} src = {imgContent2} ></img> */}
                        <div className = {styles.contentTwo}>
                          <HomeContentTwo />
                        </div>
                        <div className={styles.animation_content2}>
                          <div className='mobile'>
                            <LightAnimation width={58} height={81} />
                          </div>

                          <div className='desktop'>
                            <LightAnimation width={86} height={109} />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* --------------  Mobile 2 ------------------------------- */}
                  <Row style = {{height : 'auto'}} className = {styles.rowTwoMobile}>
                    <Row span = {24} style = {{height : 360}}>
                      <div className = {styles.contentTwo}>
                        <div width={155} height={155}>
                          <HomeContentTwo width={'100%'} height={'100%'}/> 
                        </div> 
                      </div>
                          
                      <div className={styles.animation_content2}>
                        <div className='mobile'>
                          <LightAnimation width={58} height={81} />
                        </div>
                      </div>
                    </Row>
                    <Row span = {24} className = {styles.divDetailTwoMobile}>
                      <div style = {{textAlign : 'center'}}>
                        <label className = {styles.titledetailMobileTwo}>ข้อสอบได้มาตรฐาน</label>
                        <p className = {styles.detailMobileTwo}>
                      คลังข้อสอบที่มีคุณภาพ ตรงตามมาตรฐาน <br/> 
                      หลักสูตรแกนกลางการศึกษาขั้นพื้นฐาน <br/> 
                      และข้อสอบโครงการต่าง ๆ
                        </p>
                        <a 
                          // href={'/homesuggest'} 
                          onClick={() => this.goToTesting() }
                          className = {styles.linkToSuggestionOne}>ทดลองทำข้อสอบ</a>
                      </div>
                    </Row>
                  </Row>
                </div>
             
                {/* -------------------3------------------------- */}
                <div className = {styles.rowThree}>
                  <Row style = {{height : 500}} className = {styles.rowThreeDesktop}>
                    <Col md = {12} style = {{height : '100%'}}>
                      <div className = {styles.divImgThree}>
                        {/* <img className = {styles.contentThree} src = {imgContent3}></img> */}
                        <div className = {styles.contentThree}>
                          <HomeContentThree/>
                        </div>
                        <div className={styles.animation_content3}>
                          <div className='mobile'>
                            <LightAnimation width={58} height={81} />
                          </div>

                          <div className='desktop'>
                            <LightAnimation width={86} height={109} />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md = {12} style = {{height : '100%'}}>
                      <div className = {styles.divDetailThree}>
                        <label className = {styles.detailTitleThree}>รายงานผลการทำข้อสอบ</label>
                        <div className ={styles.detailBRThree}>
                          <p className = {styles.detailThree}>
                            แสดงผลคะแนนการทำข้อสอบ <br/> 
                            ที่สามารถวิเคราะห์ความรู้ ความเข้าใจ <br/> 
                            ในตัวชี้วัด และบทเรียนวิชาคณิตศาสตร์ วิทยาศาสตร์
                          </p>
                        </div>
                        <a 
                          onClick={() => this.goToTesting() }
                          // href={'/homesuggest'} 
                          className = {styles.linkToSuggestion}>ทดลองทำข้อสอบ</a>
                      </div>
                    </Col>
                  </Row>
                  {/* ----------------------- Mobile 3 ----------------------------- */}
                  <Row className = {styles.rowThreeMobile}>
                    <Row span = {24} className = {styles.animationMobileTree}>
                      <div width={135} height={135}>
                        <HomeContentThree width={'85%'} height={'85%'}/> 
                      </div>   
                      <div className={styles.animation_content3}>
                        <div className='mobile' >
                          <LightAnimation width={58} height={81} />
                        </div>
                      </div>
                    </Row>
                    <Row span = {24} style = {{height : 150}}>
                      <div className = {styles.divDetailThreeMobile}>
                        <label className = {styles.detailTitleThreeMobile}>รายงานผลการทำข้อสอบ</label>
                        <div className ={styles.detailBRThreeMobile}>
                          <p className = {styles.detailThreeMobile}>
                            แสดงผลคะแนนการทำข้อสอบ <br/> 
                            ที่สามารถวิเคราะห์ความรู้ ความเข้าใจ <br/> 
                            ในตัวชี้วัด และบทเรียนวิชาคณิตศาสตร์ วิทยาศาสตร์
                          </p>
                          <a 
                            onClick={() => this.goToTesting() }
                            // href={'/homesuggest'} 
                            className = {styles.linkToSuggestion} style = {{paddingLeft : '35%'}}>ทดลองทำข้อสอบ</a>
                        </div>
                      </div>
                    </Row>
                  </Row>
                </div>
              
                <div className = {styles.titleBelow}>
                  <div className = {styles.titleBelowDesktop}>
                    <div className = {styles.divDetailOne}>
                      <label className = {styles.labelTitleTwo}>ไม่ว่าจะเป็นใคร แค่พกความมั่นใจ ก็ตะลุยไปพร้อมกับเราได้เลย</label>
                    </div>
                  </div>
                  <div className = {styles.titleBelowMobile}>
                    <label className = {styles.labelTitleTwoMobile}>ไม่ว่าจะเป็นใคร แค่พกความมั่นใจ <br/> ก็ตะลุยไปพร้อมกับเราได้เลย</label>
                  </div>
                </div>
               
              </div> 
            </Row>
            {/* <HomeHeadContent data={HomeHeadData[0]} />
          <HomeContent data={HomeData[0]} />
          <HomeContent data={HomeData[1]} />
          <HomeContent data={HomeData[2]} />
          <HomeHeadContent data={HomeHeadData[1]} />  */}

            <div className = {styles.contentBelow}>
              <Row>
                <Col xs={12} className={styles.hometypestudent}>
                  <div>
                    <img src={imgStudent} />
                    <div className={styles.animation_content4}>
                      <div className='mobile'>
                        {/* <LightAnimation width={58} height={81} /> */}
                      </div>

                      <div className='desktop'>
                        {
                          this.state.onMouseOverStd === true ?
                            <LightAnimation width={78} height={109} />
                            :
                            <img src = {MapOff} alt = 'MapOff' style = {{width : 78, height : 120 , position: 'absolute' , bottom : -6}}>
                            </img>
                        }
                       
                      </div>
                    </div>
                  </div>
                  <Link to = '/student'>
                    <Button 
                      type='primary' 
                      onMouseEnter = {()=>{this.setState({onMouseOverStd : true, backgroundBtn : '#F5B53F'})}}
                      onMouseLeave = {()=>{this.setState({onMouseOverStd : false, backgroundBtn : '#F7941D'})}}
                      style = {{minWidth :'40%' , maxWidth :'100%', background : this.state.backgroundBtn}}>
                        นักเรียน
                    </Button>
                  </Link>
                </Col>
                <Col xs={12} className={styles.hometypeteacher}>
                  <div>
                    <img src={imgTeacher} />
                    <div className={styles.animation_content4}>
                      <div className='mobile'>
                        {/* <LightAnimation width={58} height={81} /> */}
                      </div>

                      <div className='desktop'>
                        {
                          this.state.onMouseOverTchr === true ?
                            <LightAnimation width={78} height={109} />
                            :
                            <img src = {MapOff} alt = 'MapOff' style = {{width : 78, height : 120 , position: 'absolute' , bottom : -6}}>
                            </img>
                        }
                      </div>
                    </div>
                  </div>
                  <Link to = '/teacher'>
                    <Button 
                      type='primary' 
                      onMouseEnter = {()=>{this.setState({onMouseOverTchr : true, backgroundTeachrBtn : '#F5B53F'})}}
                      onMouseLeave = {()=>{this.setState({onMouseOverTchr : false, backgroundTeachrBtn : '#F7941D'})}}
                      style = {{minWidth : '40%', maxWidth : '80%', background : this.state.backgroundTeachrBtn}}>
                      ครู/อาจารย์
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </div> 
        <ModalServerUnavailable serverStatus={serverStatus} isModalOffline={this.state.isModalOffline} setIsModalOffline={this.onSetIsModalOffline} />
      </div> 
    )
  }
}

const mapStateToProps = (state) => {
  return {
    serverStatus: state.master.serverStatus,
  }
}

const mapDispatchToProps = (dispatch) => ({
  
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)