import React, { Component }  from 'react' 
import {  
  Button,
  Input,
  Dropdown,
  Menu 
} from 'antd'  
import Axios from 'axios'
import Download from 'downloadjs'
import * as moment from 'moment'
import ReactExport from 'react-export-excel' 
import AddDataIcon from '../../assets/Icon/AddData.svg'  
import ExcelIcon from '../IconSVG/ExcelIcon.svg'
import IconDownload from '../IconSVG/IconDownload.svg'
import IconAddFileOneByOne from '../IconSVG/IconAddFileOneByOne.svg'
import UploadExcel from '../../assets/Icon/UploadExcel.svg'
import {
  IconSearch,
} from '../IconSVG' 
import styles from './Provinces.module.scss'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class Header extends Component {
  constructor(props){
    super(props)
    this.state = {
      searchKey: null,
      searchKeyword: null,
      dataFilter: []
    }
  }
  handleKeyPress = (value) => {
    const { fetch } = this.props 
    setTimeout(()=>{
      this.setState({
        searchKey: value,
      }) 
      if(this.state.searchKey === ''){ 
        fetch()
        this.setSearch()
      }
    },50)
  } 
  handleSearch = async(value) => {
    const { dataSearch,setFiltersBySearch } = this.props
    let { searchKeyword, dataFilter } = this.state
    let isEmptySearch = true
    searchKeyword = value  
    if (searchKeyword) {  
      isEmptySearch = false
      dataFilter = dataSearch.filter(r => r.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)  
    }    
    setFiltersBySearch(dataFilter, isEmptySearch) 
  }
  setSearch = () => {
    this.setState({
      searchKey: null
    })
  }
  render(){
    const { provincesList,onOpenModal } = this.props
    const { searchKey } = this.state
    const menu = (
      <Menu>
        <Menu.Item>
          <div onClick={e => {
            e.stopPropagation()
            onOpenModal(-1,'add')
          }}>
            <img alt='icon-add-file-one-by-one' src={IconAddFileOneByOne}/>
            เพิ่มทีละรายการ
          </div> 
        </Menu.Item>
        <Menu.Item>
          <div
            onClick={e => {
              e.stopPropagation()
              onOpenModal(-1,'importData')
            }}>
            <img alt='upload-excel' src={UploadExcel}/>
            อัปโหลด Excel
          </div> 
        </Menu.Item> 
        <Menu.Item>
          <div
            onClick={e => {
              e.stopPropagation()
              // eslint-disable-next-line no-undef
              let fileName = 'province-importing-template.xlsx'
              // window.location.href = `${process.env.REACT_APP_API_URL}/storage/template/${fileName}`
              // window.location.href = 'http://52.74.3.6:3100/storage/template/province-importing-template.xlsx'
              
              let url = `${process.env.REACT_APP_API_URL}/storage/template/${fileName}`
              Axios.get(url, { responseType: 'arraybuffer' })
                .then((response) => {
                  // eslint-disable-next-line no-undef
                  let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })  
                  Download(blob, 'จังหวัด DownloadTemplate.xlsx')
                })
            }}
          >
            <img alt='icon-download' src={IconDownload}/>Download Template
          </div>
        </Menu.Item> 
      </Menu>
    )
    const addButton = (
      <div style={{display: 'flex'}}> 
        <Input
          suffix={ 
            <Button 
              type='primary' 
              style={{paddingLeft: 5, paddingRight: 10}} 
              onClick={() => this.handleSearch(searchKey)}
            >
              <IconSearch style={{verticalAlign: 'middle'}}/>ค้นหา
            </Button>
          }
          placeholder='ค้นหา'
          maxLength={100}
          className={styles.inputSearch} 
          onChange={(e)=>this.handleKeyPress(e.target.value)}
          onPressEnter={() => this.handleSearch(searchKey)} 
        />
        <div style={{ paddingRight: '16px'}}>
          <Dropdown overlay={menu}>
            <Button type='primary' >
              <img alt='add-data-icon' src={AddDataIcon}/>เพิ่มข้อมูล
            </Button> 
          </Dropdown> 
        </div>
        <div style={{ paddingRight: '16px'}}> 
          <ExcelFile
            filename = {`จังหวัด ${moment().format('DD-MM-YYYY')}`}
            element={(
              <Button type='primary' >
                <img alt='excel-icon' src={ExcelIcon}/>ดาวน์โหลด Excel  
              </Button> 
            )}
          > 
            <ExcelSheet data={ provincesList } name='Employees'>
              <ExcelColumn label='จังหวัด' value='name'/>
              <ExcelColumn label='ภูมิภาค' value='region'/>  
            </ExcelSheet> 
          </ExcelFile> 
        </div>
      </div>
    ) 
    return(
      <div>
        <div className='flex-space-between' style={{marginBottom: '30px'}}>
          <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>จังหวัด</h1>
          {addButton}
        </div>
      </div>
    )
  }
}
 
export default Header