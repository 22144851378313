import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux' 
import {
  Spin,
  Row,
  Col, 
} from 'antd'
import {
  getNoticeList 
} from '../../redux/notice/action'
import FirstNotice from './FirstNotice'
import SecondNotice from './SecondNotice'
import ThirdNotice from './ThirdNotice'

class Notice extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      noticeList: [],
      alert: [false, false, false],

    }
  }  
  componentDidMount = () => {
    this.fetchNotice()
  }
  fetchNotice = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.getNoticeList() 
    this.setState({ noticeList:res, isLoading: false })
  }
  onLoading = () => { this.setState({ isLoading: true })}
  offLoading = () => { this.setState({ isLoading: false })}
  firstClick = async() => {
    let data = [true,false,false]
    await this.setState({ alert: data })
  }
  secondClick = async() => {
    let data = [false,true,false]
    await this.setState({ alert: data })
  }
  thirdClick = async() => {
    let data = [false,false,true]
    await this.setState({ alert: data })
  }
  render(){
    const { isLoading, alert, noticeList } = this.state 
    
    return (
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading } > 
          <div className='flex-space-between' style={{marginBottom: '30px'}}>
            <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>ประกาศ</h1> 
          </div>
          <Row style={{ paddingBottom: '21px'}}> 
            <Col span={1} offset={1}>
              <span>แสดง</span> 
            </Col>
            <Col span={12}>
              <span>ข้อความ</span>   
            </Col>
            <Col span={2} offset={1} align='middle'>
              <span>จำนวนครั้งที่แสดง</span>
            </Col> 
          </Row> 
          <FirstNotice fetchNotice={ this.fetchNotice } noticeItem={noticeList[0]} firstClick={this.firstClick} alert={alert} onLoading={this.onLoading} offLoading={this.offLoading} />
          <SecondNotice fetchNotice={ this.fetchNotice } noticeItem={ noticeList[1] } secondClick={this.secondClick} alert={alert} onLoading={this.onLoading} offLoading={this.offLoading} />
          <ThirdNotice fetchNotice={ this.fetchNotice } noticeItem={ noticeList[2] }  thirdClick={this.thirdClick} alert={alert} onLoading={this.onLoading} offLoading={this.offLoading} />
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {   
      getNoticeList
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(Notice)