import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CKEditor } from 'ckeditor4-react'
import {
  message,
  Spin,
  Button,
  Row,
  Col,
  Checkbox,
  Input,
  Form,
} from 'antd'
import {
  GetContactDetail,
  setTersmAndConditions,
  getTersmAndConditions
} from '../../redux/master/action'
import styles from '../TermsAndConditions/TermsAndConditions.module.scss' 

class EditTermsAndConditions extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      contactDetail:'',
      checkEdit : false,
      dataTermForUpdate : {},
      isNotice : true,
      data : { content: '' }, 
      title : '',
      content : ''
    }
    this.handleInstanceReady = this.handleInstanceReady.bind(this)
  }

  componentDidMount = async () => {
    this.fetchContactUsDetail() 
    // if(typeof(localStorage.getItem('conditions')) === 'string' ) {
    //   data.content = localStorage.getItem('conditions')
    //   await this.setState({ data })
    // }
  }

  fetchContactUsDetail = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.getTersmAndConditions()
    await this.setState({ 
      isLoading: false,
      data : res,
      isNotice: res.isNotice ? true : false
    })
    // localStorage.setItem('conditions',res.content) 
    if (res.error) message.error(res.error)
  }
  goToEditComponent = () =>{
    this.setState({
      checkEdit : !this.state.checkEdit
    })
  }
  changeText = (e,name) =>{
    const {data} = this.state
    if(name === 'content'){
      data[name] = e.editor.getData()
    }else{
      data[name] = e.target.value
    }
    this.setState({
      data
    })
  }
  handleCheckbox = () =>{
    const { isNotice } = this.state
    if(isNotice === true){
      this.setState({
        isNotice : !isNotice
      })
    }else{
      this.setState({
        isNotice : !isNotice
      })
    }

  }
  onUpdateTerm = () =>{
    const {isNotice,data } = this.state
    data['isNotice'] = isNotice
    this.setState({
      data
    },async ()=>{
      const res = await this.props.setTersmAndConditions(this.state.data)
      this.setState({ 
        isLoading: false,
      })
      if(!res.error) message.success('แก้ไขข้อมูลสำเร็จ')
      if (res.error) message.error(res.error)
    })
  }

  handleInstanceReady({ editor }) {
    const data = this.state.data ? this.state.data.content : ''
    editor.setData(data);
  }

  render() {
    return ( 
      <div className={styles.examSetDiv}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <div className='flex-space-between' style={{marginBottom: '30px'}}>
            <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>แก้ไขข้อมูล</h1>
          </div>
          <div>
            <Form onSubmit = {this.onUpdateTerm}>
              <Row className = {styles.row_title}>
                <Col span={2}>
                  <label>หัวข้อ</label>
                </Col>
                <Col span={14} >
                  <div>
                    <Input
                      value = {this.state.data.title ? this.state.data.title : ''}
                      onChange = {e => this.changeText(e,'title')}
                    />
                  </div>
                </Col>
              </Row>
              <Row className = {styles.row_title}>
                <Col span={2}><label>ข้อความ</label></Col>
                <Col span={14}>
                  <div>
                    {
                      this.state.data.content && <CKEditor
                        initData =  {this.state.data.content ? this.state.data.content : ''}
                        onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
                        onInstanceReady={this.handleInstanceReady}
                        config={{
                          toolbarGroups : [
                            { name: 'clipboard', groups: [ 'undo', 'clipboard' ] },
                            { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                            { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                            { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                            { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                            { name: 'forms', groups: [ 'forms' ] },
                            { name: 'links', groups: [ 'links' ] },
                            { name: 'insert', groups: [ 'insert' ] },
                            { name: 'styles', groups: [ 'styles' ] },
                            { name: 'colors', groups: [ 'colors' ] },
                            { name: 'tools', groups: [ 'tools' ] },
                            { name: 'others', groups: [ 'others' ] },
                            { name: 'about', groups: [ 'about' ] }
                          ],
                          removeButtons : 'Paste,PasteText,PasteFromWord,Source,Save,NewPage,Preview,Print,Templates,RemoveFormat,CopyFormatting,CreateDiv,Blockquote,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Anchor,Flash',
                          imageUploadUrl: 'https://exam.sobtid.me/admin/questions/image', 
                          uploadUrl: 'https://exam.sobtid.me/admin/questions/image?responseType=json', 
                          filebrowserUploadUrl: 'https://exam.sobtid.me/admin/questions/imagefile?responseType=json',
                          editorUrl: 'https://cdn.ckeditor.com/4.17.2/full/ckeditor.js',
                          extraPlugins: 'justify, bidi, smiley, iframe, font, colorbutton, showblocks, find, selectall, pagebreak',
                          language: 'th'
                        }}
                        onChange = {e => this.changeText(e,'content')}
                      />
                    }
                    
                  </div>
                  <div style = {{paddingTop : '3em'}}> 
                    <Checkbox
                      onChange = {this.handleCheckbox} value = 'isNotice' checked={this.state.isNotice}
                    >แจ้งเตือนผู้ใช้</Checkbox>
                  </div>
                </Col>
              </Row>
              <div className = {styles.div_for_divide}></div>
            </Form>
          </div>
          <div>
            <Row>
              <Col span={6}>
                <div>
                  <a href='#' onClick={this.props.goToEditComponent} className={styles.cancle_link}>ยกเลิก</a>
                </div>
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col span={6}>
                <div styles = {styles.devide_line}>
                </div> 
                <div>
                  <Button className = {styles.save_btn} type='primary' htmlType='submit' onClick = {this.onUpdateTerm}>บันทึก</Button>
                </div> 
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    contactDetail : state.master.contactDetail
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetContactDetail,
      setTersmAndConditions,
      getTersmAndConditions
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(EditTermsAndConditions)