/* eslint-disable no-undef */
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import styles from '../LayoutGuest.module.scss'
import styles from './TeacherComponent.module.scss'
import { Button, Row, Col } from 'antd'
import TeacherContent1 from '../../IconSVG/TeacherContent1.svg'
import TeacherContent2 from '../../IconSVG/TeacherContent2.svg'
import TeacherContent3 from '../../IconSVG/TeacherContent3.svg'
import Women from '../../IconSVG/Women.svg' 
import Men from '../../IconSVG/Men.svg'
import ArrowLeft from '../../IconSVG/ArrowLeftIcon.svg'
import ArrowRight from '../../IconSVG/ArrowRightIcon.svg'
import { LightAnimation } from '../../animations'
import Slider from 'react-slick'
import ModalServerUnavailable from '../../Server/ModalServerUnavailable'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import '../LayoutGuest.module.scss'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <img src = {ArrowRight} onClick={onClick}  style={{ ...style, display: 'block' }} className={className}></img>
  )
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <img src = {ArrowLeft} onClick={onClick}  style={{ ...style, display: 'block' }} className={className}></img>
  )
}

class TeacherDetailComponent extends Component{
  constructor(props) {
    super(props)
    this.state = {
      widthInner : 0,
      isModalOffline: false
    }
  }

  componentDidMount = ()=>{
    this.setState({
      widthInner : window.innerWidth
    })
  }
  goToSuggestExam = () => {
    const { serverStatus } = this.props
    if(serverStatus.isOffline) {
      this.setState({ isModalOffline: true })
    }else {
      this.props.history.push('/homesuggest')
    }
  }
  goToRegister = () => {
    const { serverStatus } = this.props 
    if(serverStatus.isOffline) {
      this.setState({ isModalOffline: true })
    }else {
      this.props.history.push('/register')
    } 
  }
  render(){
    const { serverStatus } = this.props
    const { isModalOffline } = this.state
    const settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      centerPadding: '30px',
      slidesToShow: this.state.widthInner > 414 ? 3 : 1,
      slidesToScroll: 3,
      speed: 500,
      prevArrow : <SamplePrevArrow/> ,
      nextArrow: <SampleNextArrow/>,
      
    }
    return(
      <div className = {styles.divStudentDetail} style = {{textAlign:'center'}}>
        <div className = {styles.slickShow}> 
          <div style ={{width : '80%',alignItems : 'center', marginLeft : '10%'}}>
            <Slider  {...settings} >
              <div>
                <label className = {styles.titleStudentContentFirst}>จัดชุดข้อสอบได้ตามตัวชี้วัด ตรงตามหลักสูตรแกนกลางฯ</label>
                <label className = {styles.titleStudentContentFirstMobile}>จัดชุดข้อสอบได้ตามตัวชี้วัด <br/> ตรงตามหลักสูตรแกนกลางฯ</label>
                <img src = {TeacherContent1} className = {styles.StudentContent}></img>
              </div>
              <div>
                <label className = {styles.titleStudentContentSecond}>จัดกลุ่ม แชร์ข้อสอบ ทำได้พร้อมกัน</label>
                <img src = {TeacherContent2} className = {styles.StudentContent}></img>
              </div>
              <div>
                <label className = {styles.titleStudentContentthird}>รู้รายงานผลการสอบทั้งรายกลุ่มและรายบุคคล</label>
                <label className = {styles.titleStudentContentthirdMobile}>รู้รายงานผลการสอบทั้งรายกลุ่ม <br/> และรายบุคคล</label>
                <img src = {TeacherContent3} className = {styles.StudentContent}></img>
              </div>
              <div>
                <label className = {styles.titleStudentContentFirst}>จัดชุดข้อสอบได้ตามตัวชี้วัด ตรงตามหลักสูตรแกนกลางฯ</label>
                <label className = {styles.titleStudentContentFirstMobile}>จัดชุดข้อสอบได้ตามตัวชี้วัด <br/> ตรงตามหลักสูตรแกนกลางฯ</label>
                <img src = {TeacherContent1} className = {styles.StudentContent}></img>
              </div>
              <div>
                <label className = {styles.titleStudentContentSecond}>จัดกลุ่ม แชร์ข้อสอบ ทำได้พร้อมกัน</label>
                <img src = {TeacherContent2} className = {styles.StudentContent}></img>
              </div>
              <div>
                <label className = {styles.titleStudentContentthird}>รู้รายงานผลการสอบทั้งรายกลุ่มและรายบุคคล</label>
                <label className = {styles.titleStudentContentthirdMobile}>รู้รายงานผลการสอบทั้งรายกลุ่ม <br/> และรายบุคคล</label>
                <img src = {TeacherContent3} className = {styles.StudentContent}></img>
              </div>
            </Slider>
          </div> 
        </div>

        <div className = {styles.divStudentDetail} style = {{marginTop : '0', textAlign : 'center'}}>
          {/* <img src = {Women} className = {styles.Boy}></img>
          <img src = {MaskStudent} className = {styles.LightMap}></img>
          <img src = {Men} className = {styles.Girl}></img> */}
          <Row className = {styles.rowObject}>
            <Col md = {8} xs = {6}>
              <img src = {Women} className = {styles.Boy}></img>
            </Col>
            <Col md = {8} xs = {10}>
              <div className={styles.animation_content1}>
                <div className='mobile'>
                  <LightAnimation width={47} height={59} />
                </div>

                <div className='desktop'>
                  <LightAnimation width={88} height={123} />
                </div>
              </div>
            </Col>
            <Col md = {8} xs = {6}>
              <img src = {Men} className = {styles.Girl}></img>
            </Col>
          </Row>  
        </div>
        <div className = {styles.divStudentDetail} style = {{marginTop : 58, textAlign : 'center'}}>
          <Button onClick={this.goToSuggestExam} style={{ width: 200,height: '35' , margin: 10 }}>ทดลองทำข้อสอบ</Button>
          <Button onClick={this.goToRegister} type='primary' style={{ width: 200, height: '35' , margin: 10}}>
            สมัครสมาชิก
          </Button>
        </div>
        <ModalServerUnavailable serverStatus={serverStatus} isModalOffline={isModalOffline}/>
      </div>)
  }
}

const mapStateToProps = (state) => {
  return {
    serverStatus: state.master.serverStatus,
  }
}

export default connect(mapStateToProps)(TeacherDetailComponent)