import React, { Component } from 'react'
import { Modal } from 'antd'

import SearchBar from '../../Display/SearchBar'

import './SearchModal.scss'

class SearchModal extends Component {
  render () {
    const {
      isSearchModal, showSearchModal, title,
      action, placeholder, children, value
    } = this.props 
    return (
      <div className='search-modal'>
        <Modal
          closable
          visible={isSearchModal}
          footer={null}
          onCancel={() => showSearchModal(!isSearchModal)}
        >
          <h4>{title}</h4>

          <SearchBar
            onSearch={action}
            placeholder={placeholder}
            input
            handleKeyPress
            value={value}
          />

          {
            children && (
              <div className='modal-content'>
                { children }
              </div>
            )
          }
        </Modal>
      </div>
    )
  }
}

export default SearchModal
