import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as _ from 'lodash'
import {
  Form,
  Row,
  Col,
  Divider,
  Button,
  Alert,
  message
} from 'antd'
import {
  onSchoolBySearch
} from '../../redux/master/action'
import { generateSelectForm, generateInputForm, generateSelectFormSchool } from '../../util/formGenerator'
import styles from './User.module.scss'
import { AxiosRequest } from '../../redux/fetch'
import TopTitileComponent from '../Layout/TopTitleComponent'
import {
  GetMasterData
} from '../../redux/master/action'

const API_SCHOOL_DETAIL = '/master/schools'

class AddUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFormDirty: false,
      roleSelected: 'student',
      isNewSchool: false,
      isSearchSchool: false,
      schoolsState: [],
      valueSearchSchool: null,
      school: null
    }
  }

  componentDidMount = async () => {
    await this.props.GetMasterData()
    this.setState({ schoolsState: this.props.schools })
  }

  onRoleChange = (id) => {
    this.setState({ roleSelected: id })
  }
  
  onSubmit = (e) => {
    const { school } = this.state
    e.preventDefault()
    this.props.form.validateFieldsAndScroll( async(err, values) => {
      this.setState({ isFormDirty: true }) 
      if (!err ) {
        if(!values.email) delete values.email     
        values.school = {
          addressNo: { text: school.addressNo,isModified: false },
          createdAt: { text: school.createdAt,isModified: false },
          department: { text: school.department,isModified: false },
          district: { text: school.district,isModified: false },
          lane: { text: school.lane,isModified: false },
          name: { text: school.name,isModified: false },
          postalCode: { text: school.postalCode,isModified: false },
          province: { id: school.province,isModified: false },
          remark: { text: school.remark,isModified: false },
          road: { text: school.road,isModified: false },
          subDistrict: { text: school.subDistrict,isModified: false },
          updatedAt: { text: school.updatedAt,isModified: false },
          villageNo: { text: school.villageNo,isModified: false }
        }
        await this.props.onSubmitAddUser(values) 
      }
    })
  
  }

  onSchoolChange = (schoolId) => {
    const { schoolsState } = this.state   
    const existedSchool = schoolsState.find( (school) => schoolId === school._id )  
    this.onUpdateSchoolDetail(existedSchool._id)
    const isNewSchool = existedSchool ? false : true
    this.setState({
      isNewSchool,
      isFormDirty: false,
      isSearchSchool: false,
      valueSearchSchool: existedSchool.name
    })
  }

  searchSchool = _.debounce(async(text) => { 
    const { onSchoolBySearch } = this.props 
    const res = await onSchoolBySearch(text)  
    this.setState({ schoolsState: res.items, isSearchSchool: true })
    return res.items
  },400)
  
  onUpdateSchoolDetail = async (schoolId) => { 
    if (!schoolId) return this.setState({ school: null }) 
    this.setState({ isLoading: true })
    const res = await AxiosRequest(API_SCHOOL_DETAIL, 'get', schoolId) 
    this.setState({ isLoading: false })
    if (res.error) {
      this.setState({ school: null })
      return message.error('ไม่สามารถรับข้อมูลโรงเรียนได้')
    }
    this.setState({ school: res })
    const { name, addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department } = res
    this.props.form.setFieldsValue({ schoolName: name, addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department })
  }

  render() {
    const { form, prefixes, schools, provinces, gotoListUser } = this.props
    const { isFormDirty, roleSelected, schoolsState, isSearchSchool } = this.state
    const prefixesByRole = prefixes.filter(prefix => {
      if(roleSelected === 'superTeacher'|| roleSelected === 'admin' ){
        return prefix.visible['teacher']
      }
      return prefix.visible[roleSelected]
    })
    const formItemLayout = {
      colon: false,
      labelAlign: 'top',
      hideRequiredMark: true
    } 
    const roles = [{id: 'student', name: 'นักเรียน'}, {id: 'teacher', name: 'ครู'},{id: 'superTeacher',name: 'ครูพิเศษ'},{id: 'admin',name: 'ผู้ดูแลระบบ'}]
    const regExpStrongPassword = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/

    const selectRole = generateSelectForm({ options: roles, fieldName: 'role', form, label: 'ประเภทผู้ใช้งาน', isFormDirty, isRequire: true, initialValue: 'นักเรียน', handleSelectChange: this.onRoleChange, freeInput:false , placeholder: 'เลือกคำนำหน้า'})
    const selectPrefix = generateSelectForm({ options: prefixesByRole, fieldName: 'prefixName', form, label: 'คำนำหน้า', isFormDirty, isRequire: true, nameAsValue: true, initialValue: prefixesByRole.length > 0 ? prefixesByRole[0].name : '', placeholder: 'เลือกคำนำหน้า' })
    const inputFirstName = generateInputForm({ fieldName: 'firstName', form, label: 'ชื่อ', isFormDirty, isRequire: true, })
    const inputLastName = generateInputForm({ fieldName: 'lastName', form, label: 'นามสกุล', isFormDirty, isRequire: true })
    //const inputUserName = roleSelected == 'student' ? generateInputForm({ fieldName: 'username', form, label: 'Username', isFormDirty, isRequire: true, }) : null

    const inputEmail = generateInputForm({ fieldName: 'email', form, label: 'อีเมล', isFormDirty, customRules: [
      // {  required: true, message: 'กรุณาใส่อีเมล' },
      {  required: true  , message: 'กรุณาใส่อีเมล' },
      { type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' }
    ]}) 
    const inputPassword = generateInputForm({ fieldName: 'password', form, label: 'รหัสผ่าน', isFormDirty, limit: 20, customRules: [
      { required: true, message: 'กรุณาใส่รหัสผ่าน' },
      { min: 6, message: 'มีอย่างน้อย 6 ตัว' },
      { pattern: regExpStrongPassword, message: 'ประกอบด้วยตัวอักษรภาษาอังกฤษและตัวเลข' },
    ], props: {
      type: 'password',
      autoComplete: 'new-password',
    } })
 
    const selectSchoolName = generateSelectFormSchool({ options: isSearchSchool ? schoolsState : schools, fieldName: 'schoolName', form, label: 'โรงเรียน/หน่วยงาน', isFormDirty, isRequire: true, initialValue: null, nameAsValue: true, freeInput: true, placeholder: 'เลือกโรงเรียน', handleSelectChange: this.onSchoolChange, searchSchool: this.searchSchool,editInRegister: false, value: this.state.valueSearchSchool  })
     
    const selectProvince = generateSelectForm({ options: provinces, fieldName: 'province', form, label: 'จังหวัด', isFormDirty, isRequire: true, initialValue: '', nameAsValue: false, freeInput: false, placeholder: 'กรุณาเลือก' })
     
    const schoolFields = [selectSchoolName, selectProvince]
 
    const errorNoti = this.props.resErr === true ? (<Alert message={this.props.resErrorMessage} type='error' closable/>) : ''
    return (
      <div>
        <TopTitileComponent title={'สร้างบัญชีผู้ใช้'} searchBar={false}/>
        { errorNoti }
        <Form {...formItemLayout} onSubmit={this.onSubmit} >
          <Row gutter={30}>
            <Col xl={{span:9}} lg={{span:12}} sm={{span:24}}>
              { selectRole }
              { selectPrefix }
              { inputFirstName }
              { inputLastName } 
              {/* { inputUserName } */}
            </Col> 
            <Col xl={{span:9, offset:2}} lg={{span:12}} sm={{span:24}}>
              { inputEmail }
              { inputPassword }
              { schoolFields }
            </Col>
              
          </Row>
          <Divider/>
          <div className='flex-space-between'>
            <Button type='link' className={`${styles.btn_cancel} text_underline`} onClick={gotoListUser}>ยกเลิก</Button>
            <Button type='primary' htmlType='submit' className={styles.btn_ok} style={{'width': 300, 'maxWidth': '80%'}}>สร้างบัญชี</Button>
          </div>
        </Form>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    prefixes: state.master.prefixes,
    provinces: state.master.provinces,
    schools: state.master.schools,
    departments: state.master.departments,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetMasterData,
      onSchoolBySearch
    },
    dispatch
  )


const WrappedFormAddUser = Form.create({ name: 'form_add_user' })(AddUser)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedFormAddUser))