/* eslint-disable no-undef */
import React,{ Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Header  from './Header'
import { StudentTableReportExam, ExamInfo  } from './ExamStudents'
import styles from './Report.module.scss'
import { ExamDetailModal } from './ExamStudents'
import * as moment from 'moment'
import * as _ from 'lodash'
import  ModalScoreResult  from './ModalScoreResult'
import ModalScore from './ModalScore'
import {
  Row,
  Col,
  Button
}from 'antd'
import {
  getExamSetDetail,
  getDetailMyExam
} from '../../redux/exam/action'
import { getGroupData } from '../../redux/group/action'
import {
  getResultTesting
} from '../../redux/testing/action'
import {
  getStudentDetail,
  getExamGroupDetail,
  getExamStaticDetail
} from '../../redux/report/action'
import {
  GetProfile
} from '../../redux/auth/action'
import AverageIcon from '../IconSVG/AverageIcon.svg'
import HighIcon from '../IconSVG/HighIcon.svg'
import LowIcon from '../IconSVG/LowIcon.svg' 
import SearchModal from '../modal/SearchModal'

class GroupStudents extends Component {
  constructor(props){
    super(props)
    this.state = {
      isHover: false,
      indexRow: false,
      ExamDetail: [],
      studentListDetail:[],
      visibleModal: false,
      visibleModalScore: false,
      visibleModalScoreResult: false,
      examDetail: [],
      user: {},
      testingDetail: [],
      searchResult: [],
      isSearchModal: false,
      examGroupDetail: {
        testingsList: []
      },
      groupDetail: [],
      examDetailReport: []
    }
  }
  columnsStudent = [
    {
      title: '#',
      dataIndex: 'range',
      key: 'range',
      render: (range,row,index)=>{
        const { testingsList } = this.state.examGroupDetail
        return (<span>{testingsList.length - index}</span>)
      },
      align: 'center',
    },
    {
      title: 'วันที่',
      dataIndex: 'finishedAt',
      key: 'finishedAt',
      align: 'center',
      render: (finishedAt) => {
        return (<div>{moment.unix(finishedAt).format('DD/MM/YYYY')}</div>)
      }
    },
    {
      title: 'เวลา',
      dataIndex: 'finishedAt',
      key: 'time',
      render: (finishedAt) => {
        return (<div>{moment.unix(finishedAt).format('HH:mm')}</div>)
      },
      align: 'center',
    },
    {
      title: 'คะแนนที่ได้',
      dataIndex: 'score',
      key: 'score',
      align: 'center'
    },
    {
      title: '',
      dataIndex: '',
      key: 'button',
      render: (exam,row,index) => { 
        return (
          <div >
            <Button
              onClick = {(e) => {
                e.stopPropagation()
                this.onOpenModalScore(index)
              }}
              className={styles.button_hover}>ใบคะแนน</Button>
            <Button
              onClick = {(e) => {
                e.stopPropagation()
                this.onOpenModalResultScore(index)
              }}
              className={styles.button_hover}>ใบผลสอบ</Button>
            <Button onClick={(e)=>{
              e.stopPropagation()
              this.onClickTesting(index)
            }} className={styles.button_hover}>ดูคำตอบ</Button>
          </div>)
      },
      align: 'center',
    },
  ]

  componentDidMount = () => {
    this.fetchGetExamGroup()
  }
  fetchGetExamGroup = async() => {
    const { match } = this.props 
    const [ resExam , resStudentDetail, examDetail, user,resExamGroupDetail,resGroupDetail ] = await Promise.all([
      this.props.getExamSetDetail(match.params.examId),
      this.props.getStudentDetail(match.params.examId),
      this.props.getDetailMyExam( match.params.examId),
      this.props.GetProfile(),
      this.props.getExamGroupDetail(match.params.examId,match.params.groupId),
      this.props.getGroupData(match.params.groupId)
    ])
    this.setState({
      ExamDetail: resExam,
      examDetail,
      user,
      studentListDetail: resStudentDetail,
      examGroupDetail: resExamGroupDetail,
      groupDetail: resGroupDetail
    })
  }
  onHover = (index) => {
    this.setState({
      indexRow: index,
      isHover: true
    })
  }
  onNotHover = (index) => {
    this.setState({
      indexRow: index,
      isHover: false
    })
  }
  openModal = () => {
    this.setState({
      visibleModal: true
    })
  }
  closeModal = () => {
    this.setState({
      visibleModal: false
    })
  }
  onClickTesting = async(index) => {
    const {  history } = this.props
    const { examGroupDetail } = this.state
    history.push(`/testing/${examGroupDetail.testingsList[index].testingId}/solution`, {
      cameFrom: 'report',
      examId: this.state.examDetail._id,
      groupId: this.state.groupDetail.id,
      groupName: history.location.state.groupName
    })
  }
  onOpenModalResultScore = async(index) => { 
    const { examGroupDetail } = this.state
    let testingId = examGroupDetail.testingsList[index].testingId 
    const resTestingDetail = await this.props.getResultTesting(testingId)

    const resExamStaticDetail = await this.props.getExamStaticDetail(testingId)
    this.setState({
      testingDetail: resTestingDetail,
      visibleModalScoreResult: true,
      examDetailReport: resExamStaticDetail
    })
  }
  onCloseModalResultScore = () => {
    this.setState({
      visibleModalScoreResult: false
    })
  }
  onOpenModalScore = async(index) => {
    const { examGroupDetail } = this.state
    let testingId = examGroupDetail.testingsList[index].testingId
    const resTestingDetail = await this.props.getResultTesting(testingId)
    this.setState({
      testingDetail: resTestingDetail,
      visibleModalScore: true
    })
  }
  onCloseModalScore = () => {
    this.setState({
      visibleModalScore: false
    })
  }

  showSearchModal = () => {
    this.setState({ isSearchModal: !this.state.isSearchModal})
    this.state.isSearchModal && this.fetchGetExamGroup()
  }
  
  render(){
    const { ExamDetail, visibleModal, studentListDetail, visibleModalScoreResult,visibleModalScore, examDetail, testingDetail, user, examGroupDetail, groupDetail, examDetailReport } = this.state
    const { match, auth } = this.props    
    const scoreRank = examGroupDetail.testingsList.sort((a, b) => b.score - a.score)
    const highScore = scoreRank.map((item, i) => {
      return {
        ...item,
        rank: i + 1
      }
    })[0] || {
      finishedAt: '',
      rank: 0,
      score: 0,
    }    
    const GroupInfo = (
      <div className={styles.group_info}>
        <div className='mobile'>
          <div className='group-name'>
            <h1>{ localStorage.getItem('groupName') }</h1>
          </div>

          <div className='content'>
            {/* <div className='rank'>
              <p>อันดับล่าสุดในกลุ่ม</p>
              <h1>{ highScore.rank ? highScore.rank : 0}</h1>
            </div> */}

            <div className='statistic'>
              <div className='label'>
                <p>สถิติวันที่</p>
                <p>คะแนนที่สูงที่สุด</p>
              </div>
              <div className='text'>
                <p>{moment.unix(highScore.finishedAt).format('DD/MM/YYYY')}</p>
                <p>{highScore.score}</p>
              </div>
            </div>
          </div>

          <div className={styles.overall_score}>
            <h4>คะแนน</h4>

            <div className='all-acore'>
              <div className='score'>
                <img src={LowIcon} alt='low-icon'/>
                <h4>{examGroupDetail.testingStats && examGroupDetail.testingStats.minScore ? examGroupDetail.testingStats.minScore.toFixed(2) : 0}</h4>
              </div>
              <div className='score'>
                <img src={HighIcon} alt='high-icon'/>
                <h4>{examGroupDetail.testingStats && examGroupDetail.testingStats.maxScore ? examGroupDetail.testingStats.maxScore.toFixed(2) : 0}</h4>
              </div>
              <div className='score'>
                <img src={AverageIcon} alt='average-icon'/>
                <h4>{examGroupDetail.testingStats && examGroupDetail.testingStats.avgScore ? examGroupDetail.testingStats.avgScore.toFixed(2) : 0}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className='desktop'>
          <div className={ styles.header_style_student_group}>
            <div className={styles.item_report_group_header}>
              <div className={styles.text}>
                <p className={ styles.name_group } >{ groupDetail ? `${groupDetail.name}` : ''}</p>
              </div>
            </div>
            <div style={{ paddingLeft: '30px' }}>
              <Row type='flex' align={'bottom'}>
                {/* <Col span={3} >
                  <div style={{ textAlign: 'center', paddingBottom: '0px'}}>
                    <span>อันดับล่าสุดในกลุ่ม</span>
                    <p className={ styles.range } >{examGroupDetail.testingStats && examGroupDetail.testingStats.rankingNo ? examGroupDetail.testingStats.rankingNo : 0}</p>
                  </div>
                </Col> */}
              <Col span={3} className={styles.columns_and_row}>
                <Row ><span className={ styles.date_statistic }>สถิติวันที่</span></Row>
                <Row ><span className={ styles.highScore }>คะแนนที่สูงที่สุด</span></Row>
              </Col>
              <Col span={3} className={styles.columns_and_row}>
                <Row><span>{moment.unix(highScore.finishedAt).format('DD/MM/YYYY')}</span></Row>
                <Row><span>{highScore.score}</span></Row>
              </Col>
              <Col span={2} offset={6} className={styles.columns_and_row}>
                <Row gutter={3}/>
                <Row><h3>คะแนน</h3></Row>
              </Col>
              <Col span={2} className={styles.columns_and_row} align={'center'}>
                <Row><div><img src={LowIcon}/><span>ต่ำสุด</span></div></Row>
                <Row><h3>{examGroupDetail.testingStats && examGroupDetail.testingStats.minScore  ? examGroupDetail.testingStats.minScore.toFixed(2) : 0 }</h3></Row>
              </Col>
              <Col span={2} className={styles.columns_and_row} align={'center'}>
                <Row><div><img src={HighIcon}/><span>สูงสุด</span></div></Row>
                <Row><h3>{examGroupDetail.testingStats && examGroupDetail.testingStats.maxScore  ? examGroupDetail.testingStats.maxScore.toFixed(2) : 0 }</h3></Row>
              </Col>
              <Col span={2} className={styles.columns_and_row} align={'center'}>
                <Row><div><img src={AverageIcon}/><span>เฉลี่ย</span></div></Row>
                <Row><h3>{examGroupDetail.testingStats && examGroupDetail.testingStats.avgScore  ? examGroupDetail.testingStats.avgScore.toFixed(2) : 0 }</h3></Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      </div>
    ) 
    return(
      <div className={styles.report_info}>
        <Header noSearch={true}
          match={match}
          showSearchModal={this.showSearchModal}
          {...this.props}
        />
        <ExamInfo match={this.props.match} openModal={this.openModal} ExamDetail={ExamDetail} auth={auth} excelData={_.orderBy(studentListDetail,['finishedAt'], ['desc'])}/> 
        {GroupInfo} 
        <StudentTableReportExam
          columnsStudent={this.columnsStudent}
          dataStudent={_.orderBy(examGroupDetail.testingsList,['finishedAt'], ['desc'])}
          onHover={this.onHover}
          onNotHover={this.onNotHover}
          onOpenModalScore={this.onOpenModalScore}
          onOpenModalResultScore={this.onOpenModalResultScore}
          onClickTesting={this.onClickTesting}
        />

        <ExamDetailModal
          ExamDetail={ExamDetail}
          closeModal={this.closeModal}
          visibleModal={visibleModal} auth={auth}
        />

        <ModalScoreResult
          testingDetail={testingDetail}
          ExamDetail={ExamDetail}
          visibleModalScoreResult={visibleModalScoreResult}
          onCloseModalResultScore={this.onCloseModalResultScore}
          match={match}
          user={user}
          examDetailReport={examDetailReport}
        />

        <ModalScore
          visibleModalScore={visibleModalScore}
          onCloseModalScore={this.onCloseModalScore}
          match={match}
          testingDetail={testingDetail}
          examDetail={examDetail} user={user}
        />

        <SearchModal
          isSearchModal={this.state.isSearchModal}
          showSearchModal={this.showSearchModal}
          title='ค้นหา'
          placeholder='ชื่อ'
          action={this.setExamsFiltersBySearch}
        >
          <StudentTableReportExam
            columnsStudent={this.columnsStudent}
            dataStudent={_.orderBy(examGroupDetail.testingsList,['finishedAt'], ['desc'])}
            onHover={this.onHover}
            onNotHover={this.onNotHover}
            onOpenModalScore={this.onOpenModalScore}
            onOpenModalResultScore={this.onOpenModalResultScore}
            onClickTesting={this.onClickTesting}
          />
        </SearchModal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    auth: state.auth.profile,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getExamSetDetail,
      getStudentDetail,
      getDetailMyExam,
      GetProfile,
      getResultTesting,
      getExamGroupDetail,
      getGroupData,
      getExamStaticDetail
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(GroupStudents)
