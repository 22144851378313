import React from 'react'
import Lottie from 'react-lottie'

import json1 from '../srcAnimationJson/Map.json'
import json2 from '../srcJson/Map_effect.json'

import './LightAnimation.scss'

class LightAnimation extends React.Component {
  static defaultProps = {
    height: 161,
    width: 116
  }

  render () {
    const { width, height } = this.props
    
    const light = {
      loop: true,
      autoplay: true,
      animationData: json1,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    const lightEffect = {
      loop: true,
      autoplay: true,
      animationData: json2,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <div className='light-animation' style={{ height }}>
        <Lottie
          options={light}
          height={height}
          width={width}
        />

        <Lottie
          options={lightEffect}
          height={height}
          width={width}
        />
      </div>
    )
  }
}

export default LightAnimation
