import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as moment from 'moment'


import {
  Modal,
  Button, 
  Row,
  Col,  
  Upload,
  message
} from 'antd'
import BigWarningIcon from '../IconSVG/BigWarningIcon.svg'
import FileIcon from '../IconSVG/FileIcon.svg'
import IconButtonExcelUpload from '../IconSVG/IconButtonExcelUpload.svg'
import styles from './AuditTrail.module.scss'  

class ModalAuditTrail extends Component {
  constructor(props){ 
    super(props)
    this.state = {

    } 
  } 
  

  render(){
    const { modalVisible, onCloseModal, typeModal , rowdata, Detailsfield, provinceList  } = this.props //,schoolId , items, provinceList,indexRow,onUpdateSchool, onCreateSchool ,fetch
    
    return ( 
      <div>
        <Modal 
          width = '50%'
          // width= {typeModal === 'delete' || typeModal === 'importData' ? '30%' : '50%'}
          closable={true}
          visible={modalVisible}
          destroyOnClose={true}
          onCancel={onCloseModal} 

          onOk={onCloseModal} 

          cancelButtonProps={{ style: { display: 'none' } }}
        >  
          {(() => {
            // switch(typeModal){
            //   // case 'edit'       : return <EditForm schoolId={schoolId} onUpdateSchool={onUpdateSchool} fetch={fetch} onAddData={this.onAddData} departmentFull={departmentFull} items={items} onCloseModal={onCloseModal} provinceList={provinceList} indexRow={indexRow}/>
            //   //case 'delete'     : return <div>{deleteModal}</div>
            //   // case 'add'        : return <div><AddForm fetch={fetch} onCreateSchool={onCreateSchool} onAddData={this.onAddData} onCloseModal={onCloseModal} departmentFull={departmentFull} provinceList={provinceList}/></div>
            //   //case 'importData' : return <div>{importExcel}</div>
            //   default           : return <>{JSON.stringify(rowdata)}</>
            // }
if (modalVisible)
            return (

     <div>
<Row className={styles.auditdetail} >
  <Col span={12} align='left'>
    <h1 style={{fontSize: '22px', color: '#05007A'}}>รายละเอียดการแก้ไขข้อมูล</h1>
  </Col>
</Row>
<Row className={styles.auditdetail} >
  <Col span={6} align='left'><b>แก้ไขข้อมูลเมื่อ</b></Col>
  <Col span={18} align='left'>{moment.unix(rowdata.editDateTime).format('DD/MM/YYYY HH:mm:ss')}</Col>
</Row>
<Row className={styles.auditdetail} >
  <Col span={6} align='left'><b>หมายเลข Ticket</b></Col>
  <Col span={18} align='left'>{rowdata.ticket}</Col>
</Row>
<Row className={styles.auditdetail} >
  <Col span={6} align='left'><b>หมายเหตุ Ticket</b></Col>
  <Col span={18} align='left'>{rowdata.note}</Col>
</Row>
<Row className={styles.auditdetail} >
  <Col span={6} align='left'><b>ผู้แก้ไข</b></Col>
  <Col span={18} align='left'>{rowdata.editor_detail_shortName}</Col>
</Row>
<Row className={styles.auditdetail} >
  <Col span={6} align='left'><b>ผู้ใช้</b></Col>
  <Col span={18} align='left'>{rowdata.username_detail_shortName}</Col>
</Row>
<Row className={styles.auditdetail} >
  <Col span={6} align='left'><b>IP Address</b></Col>
  <Col span={18} align='left'>{rowdata.ipaddress}</Col>
</Row>
<Row className={styles.auditdetail} >
  <Col span={6} align='left'><b>รายละเอียด</b></Col>
  <Col span={18} align='left'><Detailsfield details={rowdata.details} myprovinceList={provinceList} /></Col>
</Row>
{/* <Row>
<Col span={24} align='left'>
{JSON.stringify(rowdata)}
</Col>
</Row> */}
</div>
)
else
return ''
          })()}
        </Modal>
      </div>
    )
  }
}
 
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
      //getProvinceList
      // deleteSchool,
      // onUpdateSchool,
      // getDepartmentsDetail,
      // getProvinceList,
      // onCreateSchool,
      // onImportExcelSchool
    },
    dispatch
  ) 
export default connect(null,mapDispatchToProps)(ModalAuditTrail)