import { FetchCycle, AxiosRequest } from '../fetch'

// API =====================================================================

const API_SCHOOL        = '/master/schools' 

// Fetch ===================================================================

const schoolList        = (filter, page)  => () => AxiosRequest(`${API_SCHOOL}?page=${page}&limit=10`,'get', filter ? {'search[name]': filter} : null)
const schoolDetail      = (schoolId)      => () => AxiosRequest(`${API_SCHOOL}/${schoolId}`,'get')
const departmentsDetail = ()              => () => AxiosRequest('master/departments','get')
const createSchool      = (data)          => () => AxiosRequest(`${API_SCHOOL}`,'post',data)
const importExcelSchool = (excelData)     => () => AxiosRequest('/master/schools/import','post',excelData)
const updateSchool      = (schoolId,data) => () => AxiosRequest(`${API_SCHOOL}/${schoolId}`,'patch',data)
const removeSchool      = (schoolId)      => () => AxiosRequest(`${API_SCHOOL}/${schoolId}`,'delete')
const provinceList      = ()              => () => AxiosRequest('/master/provinces?limit=100','get')
const searchSchoolById  = (schoolId)  => () => AxiosRequest(`${API_SCHOOL}/${schoolId}?`,'get' )

// Action =====================================================================

export const getSchoolList        = (filter, page)  => FetchCycle(schoolList(filter, page))
export const getSchoolDetail      = (schoolId)      => FetchCycle(schoolDetail(schoolId))
export const getDepartmentsDetail = ()              => FetchCycle(departmentsDetail())
export const onCreateSchool       = (data)          => FetchCycle(createSchool(data))
export const onImportExcelSchool  = (excelData)     => FetchCycle(importExcelSchool(excelData))
export const onUpdateSchool       = (schoolId,data) => FetchCycle(updateSchool(schoolId,data))
export const deleteSchool         = (schoolId)      => FetchCycle(removeSchool(schoolId))
export const getProvinceList      = ()              => FetchCycle(provinceList())
export const getSearchSchoolById  = (schoolId)      => FetchCycle(searchSchoolById(schoolId))