/* eslint-disable no-undef */
import React, { Component }  from 'react'  
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { CKEditor } from 'ckeditor4-react'
import styles from './Notice.module.scss' 
import { toast } from 'react-toastify'  
import {
  getNoticeList,
  onUpdateList
} from '../../redux/notice/action'
import {
  Row,
  Col,
  Checkbox,
  Button, 
  Input,
  message
} from 'antd'
 
class ThirdNotice extends Component {
  constructor(props){
    super(props)
    this.state = {
      isBroadcast: false,
      text: '',
      times: '', 
      noticeItem: {},
      edited: false,
      ready: false,
    }
  }
  componentDidMount = async() => {
    if(typeof(localStorage.getItem('isBroadcast3')) === 'string' || typeof(localStorage.getItem('text3')) === 'string' || typeof(localStorage.getItem('times3')) === 'string') { 
      this.setState({
        isBroadcast: localStorage.getItem('isBroadcast3') === 'true' ? true : false,
        text: localStorage.getItem('text3'),
        times: localStorage.getItem('times3'), 
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.noticeItem && this.state.ready && !this.state.edited) {
      CKEDITOR.instances['thirdnotice'].setData(this.props.noticeItem.text)
      this.setState({edited: true})
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProp) => {
    if(this.props.noticeItem !== nextProp.noticeItem){
      localStorage.setItem('isBroadcast3',nextProp.noticeItem.isBroadcast)
      localStorage.setItem('text3',nextProp.noticeItem.text)
      localStorage.setItem('times3',nextProp.noticeItem.times)
      this.setState({
        isBroadcast: nextProp.noticeItem.isBroadcast,
        text: nextProp.noticeItem.text,
        times: nextProp.noticeItem.times, 
      })
    }
  } 
  textNotice = (e) => { 
    this.setState({
      text: e.editor.getData()
    })  
    localStorage.setItem('text3',e.editor.getData())
  }
  timesNotice = (e) => { 
    this.setState({
      times: e.target.value
    })
  }
  checkNotice = (e) => {  
    this.setState({
      isBroadcast: e.target.checked
    })
  }
  saveNotice = async() => {
    const { text,isBroadcast,times } = this.state
    const { onUpdateList,onLoading,offLoading, noticeItem } = this.props
    let data = {
      isBroadcast: isBroadcast,
      text: text,
      times: times, 
    }
    onLoading()
    await onUpdateList(noticeItem.id,data)
    // await fetchNotice() 
    // await this.fetch()
    offLoading()
    message.success('บันทึกสำเร็จ')
  }
  options = {
    position: 'top-center',
    autoClose: false,
    hideProgressBar: false, 
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false, 
    className:'notice_alert notice_alert_third',
    toastClassName : 'notice_alert_toast',
    bodyClassName: 'notice_alert_body',
  }
  onClickTestAlert = () => {  
    // const { noticeItem } = this.props  
    const { text } = this.state
    this.setState({ checkClick: true})
    toast( <div style={{paddingTop: '7px'}} dangerouslySetInnerHTML={{ __html: text }}/>,this.options )
  } 
  render(){
    const  { isBroadcast, text, times } = this.state 
    const { noticeItem } = this.props 
    return (
      <div style={{ paddingBottom: '42px'}}> 
        <Row  justify='center'>
          <Col span={1}  align='middle'>
            <span>{noticeItem ? noticeItem.id : ''}.</span>
          </Col>
          <Col span={1} >
            <Checkbox checked={ isBroadcast } onChange={ e => { e.stopPropagation(); this.checkNotice(e)} }/>
          </Col>
          <Col span={12} align='middle'> 
            <Row>
              {
                (text === '' || text) && <CKEditor  
                  initialData = {text}
                  name = "thirdnotice"
                  onBeforeLoad={(CKEDITOR )=>(CKEDITOR.disableAutoInline = true)} 
                  onInstanceReady={(e) => {this.setState({ready: true})}}
                  config={{
                    height: '134px',
                    toolbarGroups : [
                      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                      { name: 'links', groups: [ 'links' ] },
                      { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                      { name: 'paragraph', groups: [ 'align', 'list', 'indent', 'blocks', 'bidi', 'paragraph' ] },
                      { name: 'insert', groups: [ 'insert' ] },
                      { name: 'document', groups: [ 'document', 'mode', 'doctools' ] },
                      { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                      { name: 'forms', groups: [ 'forms' ] },
                      '/',
                      '/',
                      { name: 'styles', groups: [ 'styles' ] },
                      { name: 'colors', groups: [ 'colors' ] },
                      { name: 'tools', groups: [ 'tools' ] },
                      { name: 'others', groups: [ 'others' ] },
                      { name: 'about', groups: [ 'about' ] }
                    ],
                    removeButtons : 'Source,Save,About,NewPage,Preview,Print,Templates,Maximize,ShowBlocks,BGColor,TextColor,Styles,Format,Font,FontSize,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Unlink,Anchor,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,CopyFormatting,RemoveFormat,Cut',
                    imageUploadUrl: 'https://exam.sobtid.me/admin/questions/image', 
                    uploadUrl: 'https://exam.sobtid.me/admin/questions/image?responseType=json', 
                    filebrowserUploadUrl: 'https://exam.sobtid.me/admin/questions/imagefile?responseType=json',
                    editorUrl: 'https://cdn.ckeditor.com/4.17.2/full/ckeditor.js',
                    extraPlugins: 'justify'
                  }} 
                  onChange = {e => {
                    this.textNotice(e)
                  }}
                />  
              }
              
            </Row> 
            {/* <Row className={styles.textNotice_input}>
              <div onClick={e => {e.stopPropagation();this.onClickTestAlert()}} style={{background: '#F7941D', cursor: 'pointer'}}>
                <p >ตัวอย่างข้อความประกาศ</p>
              </div>
            </Row>  */}
          </Col>
          <Col span={2} offset={1} align='middle'>
            <Input value={ times } onChange={ this.timesNotice } style={{ width: '80px'}} type='number'/>
          </Col>
          <Col span={4} align='middle'>
            <Button className={styles.button_save_notice} onClick={e => { e.stopPropagation();this.saveNotice() }  } type='primary' >บันทึก</Button>
            <div style={{ paddingTop: '10px' }}>
              <Button className={styles.button_save_notice} onClick={e => {e.stopPropagation();this.onClickTestAlert()}}>ดูตัวอย่าง</Button>
            </div> 
          </Col> 
        </Row> 
        {/* <NoticeAlert type = 'third'/> */}
      </div>
    )
  }
} 
 
const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {  
      getNoticeList,
      onUpdateList
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(ThirdNotice)   