import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  Spin,
  Input,
  Collapse,
  message, 
} from 'antd'
import{
  IconSearch,
} from '../../IconSVG' 
import styles from './FAQGuest.module.scss' 
import IconAdd from '../../../assets/home/icon_add.svg'
import {
  onSentFaqToUs
} from '../../../redux/contact/action'
import {
  GetFaqDetail,
} from '../../../redux/master/action' 
const { Panel } = Collapse
class FaqComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errorLogin: null,
      faqDetail:'',
      dataForSearch : {},
      faqData : {},
      name : '',
      email : '',
      title : '',
      message : '',
      optionTilte : [{value : 'สมัครสมาชิก', text : 'สมัครสมาชิก', key:'1'},{value : 'เข้าสู่ระบบ', text : 'เข้าสู่ระบบ', key : '2'}]
    }
  }
  componentDidMount = async() =>{
    await this.fetchFaqDetail()
  }

  fetchFaqDetail = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.GetFaqDetail()
    this.setState({
      isLoading: false,
      faqDetail : res,
      dataForSearch : res
    })
    if (res.error) message.error(res.error)
  }
  setName = (value)=>{
    this.setState({
      name : value,
    })
  }
  setEmail = (value) =>{
    this.setState({
      email : value,
    })
  }
  setTitle = (value) => {
    this.setState({
      title : value
    })
  }
  setText = (value,name)=> {
    const {faqData} = this.state
    faqData[name] = value
    this.setState({
      faqData
    })
  }
  searchFaq=(value)=>{
    const {faqDetail,dataForSearch} = this.state
    let data = faqDetail
    if(data){
      data = dataForSearch.filter(faq => faq.question.toLowerCase().indexOf(value.toLowerCase()) > -1)
      this.setState({
        faqDetail : data
      })
    }
  }
  onAdFaq = async (e) => {
    e.preventDefault()
    if (!e.err) {
      const res = await this.props.onSentFaqToUs(this.state.faqData)
      if(!res.error){
        message.success('ส่งคำถามสำเร็จ')
        // this.props.backToHome()
      }else{
        message.error(res.error)
      }
    }else{
      message.error(e.err)
    }
  }
  render() {
    const isAuthenticated = this.props.isAuthenticated
    const { faqDetail } = this.state
    return ( !isAuthenticated ?
      <div style = {{maxWidth : 900, margin : 'auto'}}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <div className = {styles.div_container}>
            <div className = {styles.title}>
              <label>FAQ - คำถามที่พบบ่อย</label>
            </div>
            <div className = {styles.div_search_bar}>
              <Input
                allowClear
                prefix={
                  <IconSearch style={{verticalAlign: 'middle'}}/>
                }
                placeholder='ค้นหาหัวข้อเพิ่มเติม'
                maxLength={100}
                className={styles.inputSearch}
                onChange = {e => this.searchFaq(e.target.value)}
                // onPressEnter={() => this.handleSearch(searchKey)}
              />
            </div>
            <div className = {styles.div_title_type_question}
              suffix = {<IconAdd></IconAdd>}
            >
              <p className = {styles.title_type_question}>คำถามเกี่ยวกับสมาชิก</p>
            </div>
            <div className = {styles.div_collapse_style}>
              {
                faqDetail ?
                  faqDetail.map((faq,index) => {
                    if(faq.title === 'การสมัครสมาชิก' || faq.title === 'สมัครสมาชิก'){
                      return(
                        <div key = {index}>
                          <Collapse bordered={false} defaultActiveKey={['0']} expandIconPosition={'right'} className = {styles.collapse_style}>
                            <Panel header={(<span className={ styles.text_title_faq }>{faq.question}</span>)} key ={index} className = {styles.collapse_panel_style}>
                              {
                                <div className = {styles.answer} dangerouslySetInnerHTML = {{__html: faq.answer }} style ={{color : 'white'}}/>
                              }
                            </Panel>
                          </Collapse>
                          {/* <Divider/> */}
                        </div>
                      )
                    }
                  })
                  :
                  ''
              }
            </div>
            <div className = {styles.div_title_type_question}
              suffix = {<IconAdd></IconAdd>}
            >
              <p className = {styles.title_type_question}>คำถามเกี่ยวกับการเข้าสู่ระบบ</p>
            </div>
            <div className = {styles.div_collapse_style}>
              {
                faqDetail ?
                  faqDetail.map((faq,index) => {
                    if(faq.title === 'การเข้าสู่ระบบ' || faq.title === 'เข้าสู่ระบบ'){
                      return(
                        <div key = {index}>
                          <Collapse bordered={false} defaultActiveKey={['0']} expandIconPosition={'right'} className = {styles.collapse_style}>
                            <Panel header={(<span className={ styles.text_title_faq }>{faq.question}</span>)} key ={index} className = {styles.collapse_panel_style}>
                              {
                                <div className = {styles.answer} dangerouslySetInnerHTML = {{__html: faq.answer }} style ={{color : 'white'}}/>
                              }
                            </Panel>
                          </Collapse>
                          {/* <Divider/> */}
                        </div>
                      )
                    }
                  })
                  :
                  ''
              }
            </div>
          </div>

        </Spin>
        {/*
        <div className = {styles.div_form} >
          <CardLightMap className = {styles.div_cardLightMap} >
            <div className = {styles.contactForm}>
              <label className = {styles.titleSendMsgToUs}>คำถามเพิ่มเติม</label>
              <Form>
                <label style = {{color : '#3D3D3D'}}>ชื่อ</label>
                <Input className ={styles.inputInContact}
                  type=''
                  placeholder= 'สมศักดิ์ ชูจิต'
                  maxLength={50}
                  autoComplete = ''
                  onChange = {(e) => this.setText(e.target.value,'name')}
                />
                <br/>
                <label style = {{color : '#3D3D3D'}}>อีเมล</label>
                <Input className ={styles.inputInContact}
                  type='email'
                  placeholder= 'example@gmail.com'
                  maxLength={50}
                  autoComplete='email'
                  // value = {email}
                  onChange = {(e)=>{this.setText(e.target.value,'email')}}
                />
                <label style = {{color : '#3D3D3D'}}>หัวข้อคำถาม</label>
                <Select placeholder= 'กรุณาเลือก'
                  className ={styles.inputInContact}
                  onChange = {(e)=> this.setText(e,'title')}
                  // value = {optionTilte}
                >
                  {
                    optionTilte.map((v, i) => {
                      return(
                        <Option value = {v.value} key = {i} >{v.text}</Option>
                      )
                    })
                  }
                </Select>
                <label style = {{color : '#3D3D3D'}}>ข้อความ</label>
                <CKEditor
                  data=''
                  config={{
                    toolbarGroups : [
                      { name: 'paragraph', groups: [ 'align', 'list', 'indent', 'blocks', 'bidi', 'paragraph' ] },
                      { name: 'insert', groups: [ 'insert' ] },
                      { name: 'document', groups: [ 'document', 'mode', 'doctools' ] },
                      { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                      { name: 'forms', groups: [ 'forms' ] },
                      { name: 'styles', groups: [ 'styles','Format', 'Font', 'FontSize' ] },
                      { name: 'tools', groups: [ 'tools' ] },
                      { name: 'others', groups: [ 'others' ] },
                      { name: 'about', groups: [ 'about' ] }
                    ],
                    removeButtons : 'Bold,Link,Source,Save,About,NewPage,Preview,Print,Templates,Maximize,ShowBlocks,Styles,Format,Font,FontSize,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Unlink,Anchor,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,CopyFormatting,RemoveFormat,Cut',
                    imageUploadUrl: 'https://exam.sobtid.me/admin/questions/image',
                    uploadUrl: 'https://exam.sobtid.me/admin/questions/image?responseType=json',
                    filebrowserUploadUrl: 'https://exam.sobtid.me/admin/questions/imagefile?responseType=json'
                  }}
                  onChange = {e => this.setText(e.editor.getData(),'message')}
                />
                <Button type='primary' onClick = {this.onAdFaq} id='sendData' style={{ width: '100%', marginTop: 20 }}>
                ส่งคำถามเพิ่มเติม
                </Button>
              </Form>
            </div>
          </CardLightMap>
        </div>
       */}
      </div>
      :
      <Redirect to='/examset' />
    )
  }
}

const mapStateToProps = state => ({
  // Redirect after login
  isAuthenticated: state.auth.token ? true : false,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetFaqDetail,
      onSentFaqToUs
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(FaqComponent)
