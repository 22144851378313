/* eslint-disable no-undef */
import React, { Component }  from 'react'
import {
  Input,
  Layout 
} from 'antd'
import NoteAnswer from './Note'
import styles from './Testing.module.scss'
import CorrectIcon from '../IconSVG/Correct.svg'
import IncorrectIcon from '../IconSVG/Incorrect.svg'
import { displayer } from './helper'
import D3Plot from './d3plot.js'

const { Content } = Layout

class GraphTesting extends Component {

  constructor(props){
    super(props)
    this.myRef = React.createRef()
    this.onChangeAnswer = this.onChangeAnswer.bind(this)
  }  
  
  updateAnswers(answers) {
    let d = this.state.data
    d.answers = answers
    this.setState({data: d})
    this.props.onChange(d)
  }

  onChangeAnswer(e) {
    const { question } = this.props    
    let answer = {
      type: question.type,
      order: question.seq,
      orgorder: question.orgseq,
      answer: e,
      questionId: question._id,
    } 
    this.props.onSetAnswer(answer)
  }

  render() {
    const { progressTesting, question, isSolution, displayHowTo, testingId, isExample } = this.props 
    let answer = []

    if (progressTesting) {
      answer = progressTesting.answer
      // note =  progressTesting.note !== undefined ? progressTesting.note : note
    }  

    return (
      <Content ref={this.myRef} className={styles.content_testing}>
        <div className='short-answer-testing'>
          <h2 className = { styles.num_of_question }>ข้อที่ {question.seq}.</h2>
          { displayer(question.text) } 
          <div className='question-content'>
            <D3Plot 
              chartName="item_form"
              data={question.answers}
              answer={answer}
              isSolution={isSolution}
              isExample={isExample}
              disabled={false}
              onChange={this.onChangeAnswer}
            />
          </div>
            {isSolution && (
              <div className='possible-answer'>
	      <p style={{paddingTop: '4ex', paddingBottom: '0ex', marginBottom: '0ex', fontWeight: 'bold'}}>คำอธิบาย/แนวการตอบ</p>
                <span>{displayer(question.explanation)}</span>
              </div>
            )}
        </div>
      </Content>
    )
  }
}

export default GraphTesting
