import React from 'react'
import {
  Breadcrumb,
  Row,
  Col,
  Button,
  message
} from 'antd'
import styles from './User.module.scss'
import DefaultProfile from '../IconSVG/DefaultProfile.svg'
export const ViewUser = (props) => { 
  const toListUser = () => {
    props.back()
  }  
  const checkAddress = async(userId) => {
    const { onCheckAddress,fetchUsers,openUserDetail,user }  = props
    const res = await onCheckAddress(userId)
    let userData = user 
    userData.id = user._id
    if(!res.error){
      await fetchUsers(userId)
      await openUserDetail(userData)
      message.success('ตรวจสอบข้อมูลสำเร็จ') 
    } 
  }
  return (
    <div>
      <div className={ styles.header_profile_user }>
        <Breadcrumb>
          <Breadcrumb.Item>
            <span className={styles.link} onClick={toListUser}>รายชื่อในระบบ</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>รายละเอียด</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        { !props.user.isSeenModified ? <Button onClick={e => { e.stopPropagation(); checkAddress(props.user._id) } } type='primary'>ตรวจสอบแล้ว</Button>: null}
      </div> 
      <Row>
        <Col sm={24} lg={8}>
          <div className={styles.profileImageSection}>
            <img src={props.user.profileImage ? props.user.profileImage : DefaultProfile} alt='profileImage' className={styles.userImageProfile} />
          </div>
        </Col>
        <Col sm={24} lg={16}>
          <ProfileContent user={props.user} province={props.province}/>
        </Col>
      </Row>
    </div>
  )
}

const ProfileContent = (props) => {    
  return (
    <div className={styles.profileContentSection}>
      <h1 className={styles.title}>{props.user.prefixName} {props.user.firstName} {props.user.lastName}</h1>
      <section>
        <b className={styles.row}>โรงเรียน</b>
        <ProfileContentRow title='ขื่อ' content={props.user.school && props.user.school.name.text} />
        <ProfileContentRow title='หมายเหตุ' content='-' />
        <hr/>
        <b className={styles.row}>ที่อยู่</b>
        <ProfileContentRowAddressNo title='เลขที่' content={props.user.school.addressNo ? props.user.school.addressNo.text : '-'} user={props.user}/>
        <ProfileContentRowVillageNo title='หมู่ที่' content={props.user.school.villageNo ? props.user.school.villageNo.text : '-'} user={props.user}/>
        <ProfileContentRowLane title='ซอย' content={props.user.school.lane ? props.user.school.lane.text : '-'} user={props.user}/>
        <ProfileContentRowRoad title='ถนน' content={props.user.school.road ? props.user.school.road.text : '-'} user={props.user}/>
        <ProfileContentRowSubDistrict title='ตำบล/แขวง' content={props.user.school.subDistrict ? props.user.school.subDistrict.text : '-'} user={props.user}/>
        <ProfileContentRowDistrict title='อำเภอ/เขต' content={props.user.school.district ? props.user.school.district.text : '-'} user={props.user}/>
        <ProfileContentRowProvince title='จังหวัด' content={props.user.school.province ? props.user.school.province.id : '-'} user={props.user} province={props.province}/>
        <ProfileContentRowPostalCode title='รหัสไปรษณีย์' content={props.user.school.postalCode ? props.user.school.postalCode.text : '-'} user={props.user}/>
        <hr/>
        <ProfileContentRow title='สังกัดของโรงเรียน/หน่วยงาน' content={props.user.school.department ? props.user.school.department.text : '-'} />
      </section>
    </div>
  )
}

const ProfileContentRow = (props) => {
  return (
    <div className={styles.row}>
      <span className={styles.subtitle}>{props.title}</span>
      <span className={styles.text} >{props.content}</span>
    </div>
  )
}

const ProfileContentRowAddressNo = (props) => {
  return (
    <div className={styles.row}>
      <span className={styles.subtitle}>{props.title}</span>
      <span className={styles.text} style={{color: props.user.school.addressNo && props.user.school.addressNo.isModified.isModified ? 'red' : ''}}>{props.content}</span>
    </div>
  )
}

const ProfileContentRowVillageNo = (props) => {
  return (
    <div className={styles.row}>
      <span className={styles.subtitle}>{props.title}</span>
      <span className={styles.text} style={{color: props.user.school.villageNo && props.user.school.villageNo.isModified ? 'red' : ''}}>{props.content}</span>
    </div>
  )
}

const ProfileContentRowLane = (props) => {
  return (
    <div className={styles.row}>
      <span className={styles.subtitle}>{props.title}</span>
      <span className={styles.text} style={{color: props.user.school.lane && props.user.school.lane.isModified ? 'red' : ''}}>{props.content}</span>
    </div>
  )
}

const ProfileContentRowRoad = (props) => {
  return (
    <div className={styles.row}>
      <span className={styles.subtitle}>{props.title}</span>
      <span className={styles.text} style={{color: props.user.school.road && props.user.school.road.isModified ? 'red' : ''}}>{props.content}</span>
    </div>
  )
}

const ProfileContentRowSubDistrict = (props) => {
  return (
    <div className={styles.row}>
      <span className={styles.subtitle}>{props.title}</span>
      <span className={styles.text} style={{color: props.user.school.subDistrict && props.user.school.subDistrict.isModified ? 'red' : ''}}>{props.content}</span>
    </div>
  )
}

const ProfileContentRowDistrict = (props) => {
  return (
    <div className={styles.row}>
      <span className={styles.subtitle}>{props.title}</span>
      <span className={styles.text} style={{color: props.user.school.district && props.user.school.district.isModified ? 'red' : ''}}>{props.content}</span>
    </div>
  )
}

const ProfileContentRowProvince = (props) => {  
  let nameSchool 
  let content = props.content 
  nameSchool = props.province.map(p => {
    if(p._id === content){
      return p.name
    }
    return ''
  }).find(n => n !== undefined) 
  return (
    <div className={styles.row}>
      <span className={styles.subtitle}>{props.title}</span>
      <span className={styles.text} style={{color: props.user.school.province && props.user.school.province.isModified ? 'red' : ''}}>{nameSchool}</span>
    </div>
  )
}
const ProfileContentRowPostalCode = (props) => {
  return (
    <div className={styles.row}>
      <span className={styles.subtitle}>{props.title}</span>
      <span className={styles.text} style={{color: props.user.school.postalCode && props.user.school.postalCode.isModified ? 'red' : ''}}>{props.content}</span>
    </div>
  )
}
