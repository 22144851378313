import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Button } from 'antd'
import update from 'immutability-helper'
import { DndProvider, DragSource, DropTarget } from 'react-dnd-cjs'
import HTML5Backend from 'react-dnd-html5-backend-cjs'

import ExerciseIconScience from '../../IconSVG/ExerciseIconScience.svg'
import CompetitionScienceIcon from '../../IconSVG/CompetitionScienceIcon.svg'
import CustomIconScience from '../../IconSVG/CustomIconScience.svg' 
import IconExamScience from '../../IconSVG/IconExamScience.svg'
import './Suggest.scss'

let dragingIndex = -1


class BodyRow extends React.Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget,  ...restProps } = this.props
    const style = { ...restProps.style, cursor: 'move' }

    let { className } = restProps
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward'
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward'
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    )
  }
}
const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index
    return {
      index: props.index,
    }
  },
}
const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}
const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
)
class SuggestScience extends Component {
  constructor(props){
    super(props)
    this.state = {
      examSci: []
    }
  }
  components = {
    body: {
      row: DragableBodyRow,
    },
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps = (nextProps) => {
    if(this.props.data !== nextProps.data){
      this.setState({
        examSci: nextProps.data
      })
    }
  }
  moveRow = async(dragIndex, hoverIndex) => {
    const { updateSeq } = this.props
    const { examSci } = this.state
    const dragRow = examSci[dragIndex]
    let data = {seq: hoverIndex}
    this.setState(
      update(this.state, {
        examSci: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
    )
    await updateSeq(examSci[dragIndex]._id,data)
  }
  render(){
    const { columns,role } = this.props
    const { examSci } = this.state
    return (
      <div className='suggest'>
        <div className='mobile'>
          {
            examSci.map((examSet, i) => {
              const type = examSet.type 
              const name = examSet.name
              let logo = null 
              if(examSet.type === 'G') {
                logo = IconExamScience
              } else if(examSet.type === 'C'){
                logo = CompetitionScienceIcon
              }else if(examSet.type === 'CUSTOM'){
                logo = CustomIconScience
              }else if(examSet.type === 'EX' || examSet.type === 'E'){
                logo = ExerciseIconScience
              } 
              return (
                <Link to={`/examset/${examSet._id}`}
                  className='suggest-item'
                  key={i}>
                  <div className='suggest-item-name'>
                    <img src={logo} />

                    <div className='content'>
                      <h4>{name}</h4>
                      <p>{examSet.code}</p>
                      <p>
                        ประเภท :
                        <span>
                          {
                            type === 'G' ? 'ตามหลักสูตร' : 
                            type === 'C' ? 'แข่งขัน' : 
                            type === 'CUSTOM' ? 'กำหนดเอง' : 
                            type === 'CAT' ? 'CAT' :
                            (type === 'EX' || type === 'E') ? 'แบบฝึกหัด' : '-'
                          }
                        </span>
                      </p>
                    </div>

                  </div>

                  <div className='action'>
                    <Button type='secondary'>
                      เริ่มทำ
                    </Button>
                  </div>
                </Link>
              )
            })
          }
        </div>

        <div className='desktop'>
          {
            role === 'admin' ?
              <DndProvider backend={HTML5Backend}>
                <Table
                  // scroll={{x:true}}
                  rowKey={(record,i) => i}
                  columns={columns}
                  dataSource={examSci}
                  pagination={false}
                  onRow={(examSet, index) => {
                    return {
                      onClick: e => {
                        e.stopPropagation()
                        return this.props.history.push(`/examset/${examSet._id}`)
                      },
                      index,
                      moveRow: this.moveRow,
                    }
                  }}
                  components={this.components}
                />
              </DndProvider>
              : <Table
                // scroll={{x:true}}
                rowKey={(record,i) => i}
                columns={columns}
                dataSource={examSci}
                pagination={false}
                onRow={(examSet) => {
                  return {
                    onClick: e => {
                      e.stopPropagation()
                      return this.props.history.push(`/examset/${examSet._id}`)
                    },
                  }
                }}
              />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {

  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {

    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SuggestScience)
