import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  onUpdateRelation,
  onCreateRelation,
  onRemoveRelation
} from '../../redux/relation/action' 
import {
  Modal,
  Button,
  Input,
  Row,
  Col,
  Divider,
  message,
  Form
} from 'antd'
import BigWarningIcon from '../IconSVG/BigWarningIcon.svg'
import styles from './Relation.module.scss'

class ModalRelation extends Component {
  constructor(props){
    super(props)
    this.state = {
      createRelation: {name: null}, 
      errorAddRelation: null,
      errMessage: null
    }
  }
 
  setCreateRelation = async(e) => { 
    let createRelation = this.state 
    createRelation.name =  e.target.value
    await this.setState({ createRelation })  
    if(e.key == 'Enter') {  
      this.onAddNewRelationName()  
    } 
  }
  
  onUpDateRelationName = async(e) => {  
    e.preventDefault()
    const { fetch, onCloseModal, items } = this.props  
    this.props.form.validateFieldsAndScroll( async(err, values) => {
      if(!err){
        await this.props.onUpdateRelation(items._id,values)
        await fetch()
        onCloseModal()
        message.success('แก้ไขข้อมูลความสัมพันธ์สำเร็จ')
      }
    })
  }
  onAddNewRelationName = async(e) => {
    e.preventDefault()
    const { fetch, onCloseModal } = this.props
    this.props.form.validateFieldsAndScroll( async(err, values) => {
      if(!err){ 
        const res = await this.props.onCreateRelation(values)   
        if(res.error) return await this.setState({ errMessage: res.error })
        await fetch()
        onCloseModal()
        message.success('เพิ่มข้อมูลความสัมพันธ์สำเร็จ')
      } 
    }) 
  }
  onRemoveRelationName = async(relationId) => {
    const { fetch, onCloseModal, onRemoveRelation } = this.props
    await onRemoveRelation(relationId)
    await fetch()
    onCloseModal()
    message.success('ลบข้อมูลความสัมพันธ์สำเร็จ')
  }
  render(){
    const { modalVisible, onCloseModal, items, typeModal , form: { getFieldDecorator }} = this.props
    const { errMessage } = this.state    
    const editModal = (
      <Form onSubmit={this.onUpDateRelationName}>
        <h1  className={styles.header_modal} >แก้ไขข้อมูล</h1>
        <Row >
          <Col offset={4} span={16}  >
            <p className={styles.title_module}>ความสัมพันธ์</p> 
            <Form.Item >
              {
                getFieldDecorator('name',{
                  rules: [
                    {
                      required: true,
                      min: 1,
                      message:'กรุณากรอกอย่างน้อย 1 ตัวอักษร'
                    }
                  ],
                  initialValue: items ? items.name : ''
                })(<Input />)
              }
            </Form.Item>  
          </Col> 
        </Row>
        <Divider/>
        <Row>
          <Col span={12}>
            <span onClick={onCloseModal} style={{ cursor:'pointer', color:'#828282', textDecorationLine: 'underline'}}>ยกเลิก</span>
          </Col>
          <Col span={12}>
            <Button  
              type='primary' 
              htmlType='submit'
              className={ styles.button_save_modal } >บันทึก</Button>
          </Col>
        </Row> 
      </Form>
    )  
    const deleteModal = (
      <div className={styles.header_delete_modal}>
        <Row>
          <Col span={24} align='center'>
            <div>
              <img src={BigWarningIcon}/>
            </div>
            <h1 >ลบข้อมูลความสัมพันธ์</h1>
            <h3>{items ? items.name : ''}</h3>
            <span>คุณต้องการลบข้อมูลความสัมพันธ์นี้หรือไม่</span>
            <Row style={{ paddingTop: '25px' }} >
              <Col span={12} >
                <Button onClick={e=>{
                  e.stopPropagation()
                  onCloseModal()
                }} style={{width: '70%', float: 'right', marginRight: '15px'}}>ไม่ลบ</Button>
              </Col>
              <Col span={12}>
                <Button onClick={e => {
                  e.stopPropagation()
                  this.onRemoveRelationName(items._id)
                }}
                className={ styles.button_delete_modal }
                type='primary' >ลบความสัมพันธ์</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
    
    const addRelationName = (
      <Form onSubmit={this.onAddNewRelationName} >
        <h1 className={styles.header_modal}>เพิ่มข้อมูล</h1>
        <Row >
          <Col offset={4} span={16}  >
            <p className={styles.title_module}>ความสัมพันธ์</p> 
            <Form.Item >
              {
                getFieldDecorator('name',{
                  rules: [
                    {
                      required: true,
                      message: 'กรุณากรอกข้อมูล'
                    }, 
                  ],
                  onChange: () => {
                    this.setState({ errMessage: null })
                  }
                } 
                )(<Input />)  
              }
            </Form.Item>
            {
              errMessage ? 
                <span style={{ color: 'red', fontSize: '12px',marginTop: '-8px',position: 'absolute' }}>{errMessage}</span>
                : null
            } 
          </Col> 
        </Row>
        <Divider/>
        <Row>
          <Col span={12}>
            <span onClick={e=>{e.stopPropagation();onCloseModal()}} style={{ cursor:'pointer', color:'#828282', textDecorationLine: 'underline'}}>ยกเลิก</span>
          </Col>
          <Col span={12}>
            <Button htmlType='submit' className={ styles.button_save_modal } type='primary' >เพิ่มข้อมูล</Button>
          </Col>
        </Row> 
      </Form>
    )
    return ( 
      <div>
        <Modal  
          className={typeModal !== 'delete' ? styles.modal : ''}
          closable={true}
          visible={modalVisible}
          onCancel={e => {
            e.stopPropagation()
            onCloseModal()
            this.setState({ errMessage: null})
          }}
          footer={false}
          centered={true}
          destroyOnClose={true}
          bodyStyle={ typeModal !== 'delete' ? { padding: '60px' } : { padding: '30px' }}
        > 
          {
            typeModal === 'edit' ?
              <div>{editModal}</div>
              : typeModal === 'delete' ?
                <div>
                  {deleteModal}
                </div>
                : <div>
                  {addRelationName}
                </div>
          }
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    { 
      onUpdateRelation,
      onCreateRelation,
      onRemoveRelation
    },
    dispatch
  )
const WrappedModalRelation = Form.create({ name: 'Edit_Form_Relation' })(ModalRelation)
export default connect(mapStateToProps,mapDispatchToProps)(WrappedModalRelation)