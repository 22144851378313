import React, { Component }  from 'react' 
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styles from './School.module.scss'
import {  
  Button,
  Input,
  Dropdown,
  Menu 
} from 'antd'  
import Axios from 'axios'
import Download from 'downloadjs'
import ReactExport from 'react-export-excel' 
import * as moment from 'moment'
import { 
  getProvinceList
} from '../../redux/school/action'
import AddDataIcon from '../../assets/Icon/AddData.svg'  
import ExcelIcon from '../IconSVG/ExcelIcon.svg'
import IconDownload from '../IconSVG/IconDownload.svg'
import IconAddFileOneByOne from '../IconSVG/IconAddFileOneByOne.svg'
import UploadExcel from '../../assets/Icon/UploadExcel.svg'
import {
  IconSearch,
} from '../IconSVG'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class Header extends Component {
  constructor(props){
    super(props)
    this.state = {
      provinceFilters: [],
      searchKey: null,
      searchKeyword: null,
      provinceItem: [],
      checkEnter : false
    }
  }
  componentDidMount= () => {
    this.fetchProvince()
  }
  fetchProvince = async() => {
    const res = await this.props.getProvinceList()
    this.setState({
      provinceItem: res.items
    })
  }
  handleKeyPress = (value) => {
    let { checkEnter, searchKey } = this.state 
    checkEnter = true 
    if(checkEnter && value === ''){
      this.props.fetchSchool()
      checkEnter = false
    } 
    if(value) { 
      searchKey = value
    }
    this.setState({ checkEnter, searchKey }) 
  } 
  handleSearch = async() => {
    const { fetchSchool, setValueOfSearch } = this.props    
    const { searchKey } = this.state 
    setValueOfSearch(searchKey) 
    if(searchKey) return await fetchSchool(searchKey)
    fetchSchool() 
  }
  render() {  
    const { provinceItem } = this.state
    const { onOpenModal, exportExcel }  = this.props   
    let dataExcel = exportExcel 
    provinceItem.forEach(p => {
      dataExcel = dataExcel.map(data => {
        if(data.province === p._id ) {
          data.province = p.name
        }
        return data
      })
    })
      
    const menu = (
      <Menu>
        <Menu.Item>
          <div onClick={e => {
            e.stopPropagation()
            onOpenModal(-1,'add')
          }}>
            <img alt='icon-add-file-one-by-one' src={IconAddFileOneByOne}/>
            เพิ่มทีละรายการ
          </div> 
        </Menu.Item>
        <Menu.Item>
          <div
            onClick={e => {
              e.stopPropagation()
              onOpenModal(-1,'importData')
            }}>
            <img alt='upload-excel' src={UploadExcel}/>
            อัปโหลด Excel
          </div> 
        </Menu.Item>  
        <Menu.Item>
          {/* <Button type='primary'  */}
          <div onClick={e => {
            e.stopPropagation() 
            let fileName = 'school-importing-template.xlsx' 
            let url = `${process.env.REACT_APP_API_URL}/storage/template/${fileName}`
            Axios.get(url, { responseType: 'arraybuffer' })
              .then((response) => {
                // eslint-disable-next-line no-undef
                let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })  
                Download(blob, 'โรงเรียน/หน่วยงาน DownloadTemplate.xlsx')
              })  
          }}>
            <img alt='icon-download' src={IconDownload}/>Download Template
          </div> 
          {/* </Button>  */}
        </Menu.Item>
      </Menu>
    )
    const addButton = (
      <div style={{display: 'flex'}}> 
        <Input
          suffix={ 
            <Button 
              type='primary' 
              style={{paddingLeft: 5, paddingRight: 10}} 
              onClick={() => this.handleSearch()}>
              <IconSearch style={{verticalAlign: 'middle'}}/>ค้นหา
            </Button>
          }
          placeholder='ค้นหา (ชื่อโรงเรียน/หน่วยงาน)'
          maxLength={100}
          className={styles.inputSearch} 
          // onKeyUp={this.handleKeyPress}
          onChange = {(e)=>this.handleKeyPress(e.target.value)}
          onPressEnter={( ) => this.handleSearch()} 
        />
        <div style={{ paddingRight: '16px'}}>
          <Dropdown overlay={menu}>
            <Button type='primary' >
              <img alt='add-data-icon' src={AddDataIcon}/>เพิ่มข้อมูล
            </Button>  
          </Dropdown> 
        </div> 
        <div style={{ paddingRight: '16px'}}>
          <ExcelFile
            filename = {`โรงเรียน/หน่วยงาน ${moment().format('DD-MM-YYYY')}`}
            element={( 
              <Button type = 'primary'><span><img alt='icon-excel' src={ExcelIcon}/>ดาวน์โหลด Excel</span> </Button>
            )}
          > 
            <ExcelSheet data={ dataExcel } name='Employees'>
              <ExcelColumn label='ชื่อ*' value='name'/>
              <ExcelColumn label='เลขที่' value='addressNo'/> 
              <ExcelColumn label='หมู่ที่' value='villageNo'/>
              <ExcelColumn label='ซอย' value='lane'/>
              <ExcelColumn label='ถนน' value='road'/>
              <ExcelColumn label='ตำบล' value='subDistrict'/>
              <ExcelColumn label='อำเภอ' value='district'/>
              <ExcelColumn label='ชื่อจังหวัด*' value='province'/>
              <ExcelColumn label='รหัสไปรษณีย์' value='postalCode'/>
              <ExcelColumn label='สังกัดของโรงเรียน/หน่วยงาน' value='department'/>
              <ExcelColumn label='สถานะ' value='isActive'/>
              <ExcelColumn label='หมายเหตุ' value='remark'/>
            </ExcelSheet> 
          </ExcelFile> 
        </div>  
      </div>
    ) 
    return (
      <div>
        <div className='flex-space-between' style={{marginBottom: '30px'}}>
          <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>โรงเรียน/หน่วยงาน</h1>
          {addButton}
        </div>
      </div>
    )
  }
}
const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {  
      getProvinceList
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(Header)   