import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form, 
  Row,
  Col
} from 'antd'
import { 
  Link
} from 'react-router-dom'
import { generateInputForm } from '../../util/formGenerator'
import SentEmailForgetPassword from './SentEmailForgetPassword'
 

const formItemLayout = {
  colon: false,
  labelAlign: 'left',
  hideRequiredMark: true,
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
}

class FormForgetPassword extends Component {
  
  state = {
    email: ''
  }
  
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onSubmit(values)
        this.setState({ email: values.email })
      }
    })
  }

  render() {
    const { form, errorForgetPassword, isSentEmailForgetPassword } = this.props
    const { email } = this.state

    const inputEmail = generateInputForm({ fieldName: 'email', form, label: 'อีเมล', limit: 254, customRules: [
      { required: true, message: 'กรุณากรอกอีเมล' },
    ], props: {
      autoComplete: 'username'
    } })

    return !isSentEmailForgetPassword ?
      (<Fragment>
        <div className='flex-space-between' style={{ marginBottom: 0 }}>
          <h2 className='text-medium mb-0' style={{ color: '#05007A' }}>ลืมรหัสผ่าน</h2>
        </div>
        <p>กรุณากรอกอีเมลบัญชีของท่านเพื่อขอรหัสผ่านใหม่</p>
        <Form {...formItemLayout} onSubmit={this.handleSubmit} >

          {inputEmail}
          { errorForgetPassword ? <p className='text-regular text-sm' style={{ color: '#F54B5E'}}>{errorForgetPassword}</p> : '' }
          <Row>
            <Col span={12} style={{ paddingTop: '15px' }}>
              <Link className='text-link text-thin' to='/login' tabIndex='-1'>กลับเข้าสู่ระบบ</Link>
            </Col>
            <Col span={12}>
              <Button type='primary' htmlType='submit' id='forgetPasswordSubmitBtn' style={{ width: '100%', marginTop: 10 }}>
                ตั้งรหัสผ่านใหม่
              </Button>
            </Col>
          </Row>
          
        </Form>
      </Fragment>) : (<SentEmailForgetPassword email={email} />)
  }
}


FormForgetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

const WrappedFormForgetPassword = Form.create({ name: 'form_login' })(FormForgetPassword)
export default WrappedFormForgetPassword