import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { 
  Row, 
  message,
  Spin
} from 'antd'
import {
  getTersmAndConditions
} from '../../redux/master/action' 

class TermAndConditionGuest extends Component{
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      termtDetail:'',
    }
  }
 
  componentDidMount(){
    this.fetchTermAndCondition()
  }

  fetchTermAndCondition = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.getTersmAndConditions()
    this.setState({ 
      isLoading: false,
      termtDetail : res
    })
    if (res.error) message.error(res.error)
  }

  render(){
    return(
      <div style ={{color : 'black'}}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <Row>
            {
              this.state.termtDetail.content ? <div dangerouslySetInnerHTML={{__html: this.state.termtDetail.content }}/> : ''
            }
          </Row>
        </Spin>
      </div>)
  }
}

const mapStateToProps = state => {
  return {
    termtDetail : state.master.termtDetail
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTersmAndConditions,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TermAndConditionGuest)