exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".School_row_form__1htzX {\n  padding: 40px;\n  padding-bottom: 40px; }\n  .School_row_form__1htzX h1 {\n    color: #05007A; }\n  .School_row_form__1htzX .School_col_form__2uz5r {\n    padding-bottom: 52px; }\n  .School_row_form__1htzX p {\n    margin-bottom: 0px; }\n\n.School_inputSearch__1RxVI input {\n  width: 393px;\n  margin-right: 16px;\n  border-radius: 20px;\n  border-color: #015EA5; }\n\n.School_group_of_upload_modal__3xbnO .School_modal_upload_file__30jLg {\n  text-align: center; }\n\n.School_cancel_button__lESgq {\n  width: 20%;\n  letter-spacing: 0.192px;\n  text-decoration-line: underline;\n  color: #828282;\n  cursor: pointer; }\n\n.School_icon_row__1yU54 img {\n  cursor: pointer; }\n\n.School_button_save__3grhT {\n  width: 60%;\n  float: right; }\n\n@media (max-width: 1440px) {\n  .School_button_save__3grhT {\n    width: 50%;\n    height: 38px; } }\n", ""]);

// Exports
exports.locals = {
	"row_form": "School_row_form__1htzX",
	"col_form": "School_col_form__2uz5r",
	"inputSearch": "School_inputSearch__1RxVI",
	"group_of_upload_modal": "School_group_of_upload_modal__3xbnO",
	"modal_upload_file": "School_modal_upload_file__30jLg",
	"cancel_button": "School_cancel_button__lESgq",
	"icon_row": "School_icon_row__1yU54",
	"button_save": "School_button_save__3grhT"
};