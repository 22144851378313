import { combineReducers } from 'redux'
import view from './view/reducer'
import master from './master/reducer'
import auth from './auth/reducer'
import group from './group/reducer'
import user from './user/reducer'
import exam from './exam/reducer'
import testing from './testing/reducer'
import statistic from './statistic/reducer'

export default combineReducers({
  view,
  master,
  auth,
  exam,
  group,
  user,
  testing,
  statistic
})
