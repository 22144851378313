/* eslint-disable no-undef */
import React,{ Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from './Report.module.scss'
import Header from './Header'
import { ExamDetailModal } from './ExamStudents'
import * as moment from 'moment'
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
} from 'antd'
import {
  getExamGroupDetail,
  getExamAllGroup,
  getGroupExamDeepStat,
} from '../../redux/report/action'
import {
  editExamSetlink,
} from '../../redux/exam/action'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  getGroupData,
} from '../../redux/group/action'
import { addMedalTier } from '../../util/helper'
import { getExamSetDetail } from '../../redux/exam/action'
import { ExamInfo  } from './ExamStudents'
import ProfileDefault from '../IconSVG/ProfileDefault.svg'
import AverageIcon from '../IconSVG/AverageIcon.svg'
import HighIcon from '../IconSVG/HighIcon.svg'
import LowIcon from '../IconSVG/LowIcon.svg'
import GoldMedalIcon from '../IconSVG/GoldMedalIcon.svg'
import SilverMedalIcon from '../IconSVG/SilverMedalIcon.svg'
import CopperMedalIcon from '../IconSVG/CopperMedalIcon.svg'
import GroupDefaultIcon from '../IconSVG/GroupDefaultIcon.svg'
import ListItems from '../Display/ListItems'
import SearchModal from '../modal/SearchModal'
import ModalStat  from './ModalStat'

import {
  IconCorrect,
  // Copy,
  //IconExamWarning,
  //IconGroup, 
} from '../IconSVG' 
import CopyIcon from '../IconSVG/Copy.svg'


class GroupTeachers extends Component {
  constructor(props){
    super(props)
    this.state = {
      isHover: false,
      indexRow: false,
      visibleModal: false,
      examGroupDetail: [],
      testingStats: {
        minScore: null,
        maxScore: null,
        avgScore: null
      },
      dateLastStartAt : '',
      isSearchModal: false,
      searchResult: [],
      checkSearch: false,
      dataDetail: [],
      examDetail: {}
      ,visibleModalStat: false,
      rowdata: {},

      visibleModalLink: false,
      withoutRegistered: false,

    }
  }
  columnsStudentList = [
    {
      // title: 'logo',
      dataIndex: 'profileImage',
      key: 'profileImage',
      render: (profileImage,record ) => {
        let prize = null
        if (record.medal === 0) {
          prize = GoldMedalIcon
        } else if (record.medal === 1) {
          prize = SilverMedalIcon
        } else if (record.medal === 2) {
          prize = CopperMedalIcon
        } 
        return (
          <div>
            { prize ? <img alt='icon-prize' src={ prize } className = { styles.prizeIcon } /> : ''}
            <img alt='avatar' style = {{ borderRadius: '50%' }} className = { styles.img_logo } src = { profileImage ? profileImage : GroupDefaultIcon } /> 
          </div>
        )
      },
      width: 132
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'โรงเรียน',
      dataIndex: 'schoolName',
      key: 'schoolName'
    },
    {
      title: 'จำนวนครั้งที่ทำ',
      dataIndex: 'count',
      key: 'count',
      align: 'center'
    },
    {
      title: 'วันที่ทำล่าสุด',
      dataIndex: 'latestStartedAt',
      key: 'latestStartedAt',
      render: (latestStartedAt )=> {
        return (<span>{this.dateConverter(latestStartedAt)}</span>)
      },
    },
    {
      title: 'คะแนนที่ได้',
      dataIndex: 'latestScore',
      key: 'latestScore',
      align: 'center'
    },
    {
      // title: 'ดูคำตอบ',
      dataIndex: 'buttonAnswer',
      key: 'buttonAnswer',
      render: (buttonAnswer,row,index)=>{
        return (<div><Button  className={styles.button_hover} onClick={(e)=>{
          e.stopPropagation()
          this.onClickTesting(index)
        }}>ดูคำตอบ</Button><Button  className={styles.button_hover} onClick={(e)=>{
          e.stopPropagation()
          // console.log(this.state.examGroupDetail)
          this.setState({
            visibleModalStat: true,      
            rowdata: {StaticType: 'ตัวชี้วัด', tid: this.state.examGroupDetail[index].testingId, uid: this.state.examGroupDetail[index].userId, eid:this.props.match.params.examId} //gid: this.state.examGroupDetail[index].groupId, 
          })
        }}>ผลการทำสอบ (ตัวชี้วัด)</Button><Button  className={styles.button_hover} onClick={(e)=>{
          e.stopPropagation()
          // console.log(this.state.examGroupDetail)
          this.setState({
            visibleModalStat: true,      
            rowdata: {StaticType: 'สาระการเรียนรู้', tid: this.state.examGroupDetail[index].testingId, uid: this.state.examGroupDetail[index].userId, eid:this.props.match.params.examId} // gid: this.state.examGroupDetail[index].groupId, 
          })
        }}>ผลการทำสอบ (สาระการเรียนรู้)</Button></div>)
      }
    }
  ]
  componentDidMount = () => {
    this.fetchGroupDetail() 
    this.fetchExamDetail()
  }

  fetchExamDetail = async() => { 
    const res = await this.props.getExamSetDetail(this.props.match.params.examId)
    // console.log('---------------')
    // console.log(res)
    // console.log('---------------')
    this.setState({ examDetail: res }) 
  }

  spinLoading = () => { let { isLoading } = this.state; this.setState({ isLoading: !isLoading }) }
  dateConverter = (timestamp) => {
    const dateConVertFromTimeStamp = moment(timestamp * 1000)
    return dateConVertFromTimeStamp.isValid() ? dateConVertFromTimeStamp.add(543, 'years').format('DD/MM/YYYY') : '-'
  }

  fetchGroupDetail = async() => {
    const { match } = this.props
    let {dateLastStartAt} = this.state
    let examId = match.params.examId
    let groupId = match.params.groupId
    
    const groupres = await  this.props.getGroupData(groupId) 
    const { testingStats, usersStats } = await this.props.getExamGroupDetail(examId,groupId)
    if (usersStats.length > 0) {
      dateLastStartAt = this.dateConverter(usersStats.reduce((latestStartedAt, item) => item.latestStartedAt > latestStartedAt ? item.latestStartedAt : latestStartedAt, 0))
    }
    // console.log('============fetchGroupDetail========')
    // console.log(testingStats)
    // console.log(usersStats)
    // console.log(groupres)

    this.setState({ 
      examGroupDetail: addMedalTier(usersStats),
      testingStats,
      dateLastStartAt
      ,groupres
    })
  }
  

  onHover = (index) => {
    this.setState({
      indexRow: index,
      isHover: true
    })
  }
  onNotHover = (index) => {
    this.setState({
      indexRow: index,
      isHover: false
    })
  }
  openModal = () => {
    this.setState({
      visibleModal: true
    })
  }
  closeModal = () => {
    this.setState({
      visibleModal: false
    })
  }
  onClickTesting = (index) => {
    const {  history } = this.props
    const { examGroupDetail } = this.state
    history.push(`/testing/${examGroupDetail[index].testingId}/solution`, {
      cameFrom: 'report',
      groupId: this.props.match.params.groupId,
      examDetail: this.props.match.params.examId,
      fromOwnerExam: history.location.state.cameFrom == 'exam'
    })
  }
  handleSearch = async(value) => { 
    let { searchKeyword, examsFilters, 
      examGroupDetail 
    } = this.state
    let isEmptySearch = true
    searchKeyword = value  
    this.setState({
      checkEdit : true
    })
    if (searchKeyword && this.props.match.path === '/report') {
      isEmptySearch = false
      examsFilters = examGroupDetail ? examGroupDetail.filter(r => r.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 || r.code.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)  : examGroupDetail
    }else if (searchKeyword) {
      isEmptySearch = false
      examsFilters = examGroupDetail ? examGroupDetail.filter(r => r.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)  : examGroupDetail
    }
    this.setExamsFiltersBySearch(examsFilters, isEmptySearch)
  }
  setExamsFiltersBySearch = (exam,isEmptySearch) => { 
    if(!isEmptySearch) {
      this.setState({
        examGroupDetail: exam,
        searchResult: exam,
        checkSearch: true
      })
    }else{
      this.fetchGroupDetail()
      this.setState({ checkSearch: false })
    }
    setTimeout(this.setState({ isLoading: false }),1500)
  }

  showSearchModal = () => {
    this.setState({ isSearchModal: !this.state.isSearchModal})
    this.state.isSearchModal && this.fetchGroupDetail()
  }

  onChangeExamStatus = async (examId, status) => { 
    // console.log("========onChangeExamStatus=======")
    // console.log(examId)
    // console.log(status)
    // console.log("========onChangeExamStatus=======")

    const res = await this.props.editExamSetlink(examId, status);
    // console.log(res);
  }


  render() {
    localStorage.setItem('examId',this.props.match.params.examId.toString()) 
    const {
      visibleModal, examGroupDetail,
      dateLastStartAt, searchResult, checkSearch, testingStats,
      examDetail
      ,rowdata, visibleModalStat
      ,groupres
    } = this.state
    const { history, match, auth, getGroupExamDeepStat } = this.props

const handleCancel = () => {
  this.setState({
        visibleModalLink: false,
    })
};

const labelCol = {
  sm: 12,
  md: 12,
}
const inputCol = {
  sm: 12,
  md: 12,
}

const setlinkvalue = (data,eid) => {
    this.onChangeExamStatus(eid, data)

    this.setState(data)
}

    // let ExamDetail = { 
    //   subject  : localStorage.getItem('subject'),
    //   name     : localStorage.getItem('name'),
    //   code     : localStorage.getItem('code'),
    //   type     : localStorage.getItem('type'),
    //   quantity : localStorage.getItem('quantity')
    // } 
    const GroupInfo = (
      <div className={styles.group_info}>
        <div className='mobile'>
          <div className='group-name'>
            <h1>{this.state.groupres !== undefined && this.state.groupres.name !== undefined ? this.state.groupres.name : '-'}</h1>
            <p>{dateLastStartAt}</p>
          </div>

          <div className={styles.overall_score}>
            <div className={ styles.all_score }>
              <div>
                <h4>คะแนน</h4>
              </div>
              <div className='score'>
                <img src={LowIcon} alt='low-icon'/>
                {/* <h4>{localStorage.getItem('minScore')}</h4> */}
                <h4>{ testingStats.minScore !== null ? testingStats.minScore.toFixed(2) : '-' }</h4>
              </div>
              <div className='score'>
                <img src={HighIcon} alt='high-icon'/>
                {/* <h4>{localStorage.getItem('maxScore')}</h4> */}
                <h4>{ testingStats.maxScore !== null ? testingStats.maxScore.toFixed(2) : '-' }</h4>
              </div>
              <div className='score'>
                <img src={AverageIcon} alt='average-icon'/>
                {/* <h4>{parseFloat(localStorage.getItem('avgScore')).toFixed(2)}</h4> */}
                <h4>{ testingStats.avgScore !== null ? testingStats.avgScore.toFixed(2) : '-' }</h4>
              </div>
            </div>
          </div>
<div style={{display:'flex', marginTop:'15px'}}>
{
          auth.role !== 'student' && (
            <div style={{ paddingLeft: '0px' }}>
              <Button  className='mobile' onClick={() => {
// const {  history } = this.props
history.push(`report/${match.params.examId}/group/${match.params.groupId}/stats/indicators`, { 
//reportExamId: ExamDetail._id, cameFrom: 'report', groupId: record.groupId, groupName: record.group
})
}}>
                ผลสอบ (ตัวชี้วัด)
              </Button>
            </div>
          )
        }

        {
          auth.role !== 'student' && (
            <div style={{ paddingLeft: '10px' }}>
              <Button  className='mobile' onClick={() => {
// const {  history } = this.props
history.push(`/report/${match.params.examId}/group/${match.params.groupId}/stats/strands`, { 
//reportExamId: ExamDetail._id, cameFrom: 'report', groupId: record.groupId, groupName: record.group
})
}}>
                ผลสอบ (สาระการเรียนรู้)
              </Button>

            </div>
          )
        }



{ this.state.groupres !== undefined && auth.role !== 'student' && 
          (
            <div style={{ paddingLeft: '10px' }}>
              <Button className='mobile' 
              
              onClick={e => {
                e.stopPropagation()
      this.setState({
          visibleModalLink: true,
      // const {  } = props 

          withoutRegistered: examDetail.withoutRegistered


      })
      // console.log(examDetail)

                // return this.props.history.push(`/testing/${examDetail._id}/example`, { cameFrom: groupId ? 'group': 'exam', examDetail: examDetail._id, groupId, isExample: true })
              }}
            >
              ลิงก์ภายนอก
            </Button></div>
          )
}


</div>
        </div>

        <div className='desktop'>
          <Row span={24}>
            <Col span={12}>
              <h1 style={{ fontSize: '28px', color: '#05007A' }}> 
                {this.state.groupres !== undefined && this.state.groupres.name !== undefined ? this.state.groupres.name : '-'}
              </h1>
            </Col>
            <Col span={12}>
              <Col span={3} offset={3} align={'center'}><div><img src={LowIcon}/><span>ต่ำสุด</span></div></Col>
              <Col span={3} align={'center'}><div><img src={HighIcon}/><span>สูงสุด</span></div></Col>
              <Col span={3} align={'center'}><div><img src={AverageIcon}/><span>เฉลี่ย</span></div></Col>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <span style={{ color: '#4F4F4F', fontSize: '16px'}}>{dateLastStartAt}</span>
            </Col>
            <Col span={12}>
              <Col span={3}><span className={styles.GroupInfo_text}>คะแนน</span></Col>
              {/* <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{localStorage.getItem('minScore')}</span></Col>
              <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{localStorage.getItem('maxScore')}</span></Col>
              <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{parseFloat(localStorage.getItem('avgScore')).toFixed(2)}</span></Col> */}
              <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{ testingStats.minScore !== null ? testingStats.minScore.toFixed(2) : '-' }</span></Col>
              <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{ testingStats.maxScore !== null ? testingStats.maxScore.toFixed(2)  : '-' }</span></Col>
              <Col span={3} align={'center'}><span className={styles.GroupInfo_text}>{ testingStats.avgScore !== null ? testingStats.avgScore.toFixed(2) : '-' }</span></Col>
            </Col>
          </Row>
<Row>
<Col>
<div style={{display:'flex', marginTop:'5px'}}>
        {
          auth.role !== 'student' && (
            <div style={{ paddingLeft: '0px' }}>
              <Button  className='desktop' onClick={() => {
// const {  history } = this.props
history.push(`/report/${match.params.examId}/group/${match.params.groupId}/stats/indicators`, { 
//reportExamId: ExamDetail._id, cameFrom: 'report', groupId: record.groupId, groupName: record.group
})
}}>
                ผลสอบกลุ่ม (ตัวชี้วัด)
              </Button>
            </div>
          )
        }

        {
          auth.role !== 'student' && (
            <div style={{ paddingLeft: '10px' }}>
              <Button  className='desktop' onClick={() => {


// window.location.href = (`/report/${match.params.examId}/group/${match.params.groupId}/stats`);
// const {  history } = this.props
history.push(`/report/${match.params.examId}/group/${match.params.groupId}/stats/strands`, { 
//reportExamId: ExamDetail._id, cameFrom: 'report', groupId: record.groupId, groupName: record.group
})
}}>
                ผลสอบกลุ่ม (สาระการเรียนรู้)
              </Button>
            </div>
          )
        }
{ this.state.groupres !== undefined && auth.role !== 'student' && 
          (
            <div style={{ paddingLeft: '10px' }}>
              <Button className='desktop' 
              
              onClick={e => {
                e.stopPropagation()
      this.setState({
          visibleModalLink: true,
      // const {  } = props 

          withoutRegistered: examDetail.withoutRegistered


      })
      // console.log(examDetail)

                // return this.props.history.push(`/testing/${examDetail._id}/example`, { cameFrom: groupId ? 'group': 'exam', examDetail: examDetail._id, groupId, isExample: true })
              }}
            >
              ลิงก์ภายนอก
            </Button></div>
          )
}

</div>
</Col>
</Row>
        </div>
      </div>
    )

    const srcData = examGroupDetail.map((item ) => {
      return {
        avatar: item.profileImage ? item.profileImage : ProfileDefault,
        title: item.name,
        medal: item.medal,
        description: moment.unix(item.latestStartedAt).format('DD/MM/YYYY'),
        status: `${item.latestScore}`,
        actions: [],
        path: ''
      }
    })

    const searchData = searchResult.map((item ) => {
      return {
        avatar: item.profileImage ? item.profileImage : ProfileDefault,
        title: item.name,
        medal: item.medal,
        description: moment.unix(item.latestStartedAt).format('DD/MM/YYYY'),
        status: `${item.latestScore}`,
        actions: [],
        path: ''
      }
    })      
    return(
      <div className={styles.report_info}>
        <Header 
          dataSearch={examGroupDetail}
          setExamsFiltersBySearch={this.setExamsFiltersBySearch}
          history={history}
          match={match}
          showSearchModal={this.showSearchModal} 
          {...this.props}
        />

        <ExamInfo groupId={match.params.groupId} getGroupExamDeepStat={getGroupExamDeepStat} examGroupDetail={examGroupDetail} ExamDetail={examDetail} openModal={this.openModal} auth={auth} spinLoading={this.spinLoading} groupData={this.state.groupres} />

        { GroupInfo }

        <div className={styles.report_teacher_info}>
          <div className='mobile'> 
            <ListItems
              srcData = { srcData }
              avatar
              status
              history = { this.props.history } 
              report = { true }
              groupReport = { true }
              isMedal = { true }
            />
          </div>

          <div className='desktop'> 
            <Table
              // rowKey = { record => record.userId }
              columns = { this.columnsStudentList }
              dataSource = { examGroupDetail }
              pagination = { false }
              onRow={(record,rowIndex) => {
                return {
                  onMouseEnter: () => this.onHover(rowIndex),
                  onMouseLeave: () => this.onNotHover(rowIndex)
                }
              }}
            />
          </div>
        </div>

        <ExamDetailModal
          closeModal={this.closeModal}
          visibleModal={visibleModal}
          ExamDetail={examDetail}
          auth={auth}
        />

        <SearchModal
          isSearchModal={this.state.isSearchModal}
          showSearchModal={this.showSearchModal}
          title='ค้นหา'
          placeholder='ชื่อ'
          // action={this.setExamsFiltersBySearch}
          action = { this.handleSearch }
          checkSearch
        >
          {
            checkSearch ? 
              <ListItems
                srcData={searchData}
                avatar
                status
                report = { true } 
                history = { this.props.history }
                groupReport = { true }
              />
              : ''
          }
        </SearchModal>
        <ModalStat
          visibleModalStat={visibleModalStat}
          rowdata={rowdata}
          onCloseModalResultScore={() => {
            this.setState({
              visibleModalStat: false
            })
          }}
        />



{this.state.groupres !== undefined && <Modal
            title="ลิงก์ภายนอก"
            visible = { this.state.visibleModalLink }
            centered = { true }
            width = '640px'
            destroyOnClose= { true }
            bodyStyle = {{paddingTop: '10px'}}
            // maskClosable={ false }
            closable={ true }
            footer={ false }
            // onOk={handleOk} 
onCancel={handleCancel}

          >

<Row  style={{ marginBottom: '30px'}}>
        <Col style={{textAlign: 'left', fontSize: '16px', marginTop: '7px'}} {...labelCol} >ทำได้โดยไม่ต้องสมัครสมาชิก</Col>
        <Col style={{textAlign: 'left', fontSize: '16px'}} {...inputCol} >
          <div className='conditoin-switch-btn'>
            <Button style={{marginRight: '10px'}}
              type={this.state.withoutRegistered ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => {setlinkvalue({'withoutRegistered': true},examDetail._id)}} //)
            >อนุญาต</Button>
            <Button 
              type={!this.state.withoutRegistered ? 'primary' : 'secondary'}
              className={styles.btn_ok}
              onClick={() => {setlinkvalue({'withoutRegistered': false},examDetail._id)}}
            >ไม่อนุญาต</Button>
          </div>
        </Col>
</Row>
{ (examDetail !== null && groupres !== null && examDetail !== undefined && groupres !== undefined) && <Row><Col><CopyToClipboard text={`${process.env.REACT_APP_WEB_URL}/ext/${examDetail.code}/${groupres.code}`}
            onCopy={() => {
    Modal.success({
      closable: true,
      centered: true,
      icon: null,
      maskClosable: true,
      okButtonProps: {hidden: true},
      width: 220,
      content: (
        <div style={{textAlign: 'center'}}>
          <IconCorrect style={{fontSize: 120}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>คัดลอกลิงก์สำเร็จ</h2>
        </div>
      )
    })
  }}
          >
<div style={{textAlign: 'left', fontSize: '16px', marginTop: '7px', cursor:'pointer'}} >
ลิงก์สำหรับทำข้อสอบ คลิกเพื่อคัดลอก <img src={CopyIcon} />
<br/>
<p  style={{marginTop: '10px'}}>{`${process.env.REACT_APP_WEB_URL}/ext/${examDetail.code}/${groupres.code}`}</p>
</div>
</CopyToClipboard></Col></Row> 
}
</Modal>}



      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    auth: state.auth.profile,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getExamGroupDetail,
      getExamAllGroup,
      getExamSetDetail,
      getGroupExamDeepStat,
      getGroupData,
      editExamSetlink
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(GroupTeachers)
