/* eslint-disable no-undef */
import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Header from './Header'
import * as moment from 'moment' 
import IconStar from '../IconSVG/IconStar.svg'
import IconStarGray from '../IconSVG/IconStarGray.svg'
import {
  getExamsAll,
  editExamSet,
  shareExamToGroup,
  deleteExamSet,
  getGroupNotHaveExam,
  getExamInTabAll,
  getTeacherExamInTabAll,
  getStudentExamInTabAll,
  getSuperTeacherExamInTabAll,
  getAdminExamInTabAll,
  onCheckExamSuggestion,
  removeExamSuggestion,
  getCountExam
} from '../../redux/exam/action'

import {
  Spin,
  Switch,
  Dropdown,
  Table,
  Tabs,
  Menu,
  Modal,
  message,
  Icon
} from 'antd'
import {
  IconExamWarning,
  IconCorrect
} from '../IconSVG'
import {
  FacebookShareButton, 
} from 'react-share'
import CustomIconMath from '../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../IconSVG/CustomIconScience.svg'
import ExerciseIconMath from '../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../IconSVG/ExerciseIconScience.svg'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import styles from './ExamsAll.module.scss' 
import ModalShareToGroup from '../Exam/ModalShareToGroup'
import CompetitionScienceIcon from '../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../IconSVG/CompetitionMathIcon.svg'
import IconExamMath from '../IconSVG/ExamMath.svg'
import IconExamScience from '../IconSVG/ExamScience.svg'
import Facebook from '../IconSVG/Facebook.svg'
import Group from '../IconSVG/Group.svg'
import Copy from '../IconSVG/Copy.svg'
import Share from '../IconSVG/Share.svg'
import ShareGray from '../IconSVG/ShareGray.svg'
import Delete from '../IconSVG/Delete.svg'
import DeleteGray from '../IconSVG/DeleteGray.svg' 
import Report from '../IconSVG/Report.svg'
import ReportGray from '../IconSVG/ReportGray.svg'

const { TabPane } = Tabs
class ExamsAll extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      examList: {},
      checkMouserOverDelete: false,
      checkShare: false,
      searchKey: '',
      activeKey: '1',
      examSetToShare: null,
      isShowShareToGroupModal: false,
      groupNotHaveExamList: [],
      checkEnter : false,
      pagination : {},
      hoverCodeExam: false,
      pages: {
        teacher: 1,
        student: 1,
        superTeacher: 1,
        admin: 1
      },
      examCount: {}
    }
  }
  menu = (examSet) => { 
    return ( 
      <Menu className={styles.menuExamSetShare} onClick={ ({ domEvent }) => domEvent.stopPropagation() }>
        <Menu.Item key='0'>
          <FacebookShareButton url={`${process.env.REACT_APP_API_URL}/share/fb/${examSet._id}`}>
            <img alt='facebook' src={Facebook}/> แชร์ Facebook 
          </FacebookShareButton> 
        </Menu.Item>
        <Menu.Item key='1' 
          onClick={ () => this.onOpenShareToGroup(examSet)}
        >
          <img alt='group' src={Group} /> แชร์เข้ากลุ่ม
        </Menu.Item>
        <Menu.Item key='2'>
          <CopyToClipboard 
            text={`${process.env.REACT_APP_WEB_URL}/examset/${examSet._id}`}
            onCopy={this.onCopyLinkSuccess}
          >
            <div>
              <img alt='copy' src={Copy} /> คัดลอกลิงก์
            </div>
          </CopyToClipboard>
        </Menu.Item>
      </Menu>
    )
  }
  columns = [
    {
      // title: 'hello world',
      key: 'suggestStar',
      dataIndex: 'isSuggestion',
      render: (isSuggestion,record,index) => {
        const { checkStar,indexRow } = this.state
        
        const mouseOver = () => { this.setState({ checkStar: true }) }
        const mouserLeave = () => { this.setState({ checkStar: false }) }
        return (
          <img title={'เลือกเป็นข้อสอบแนะนำ'} onClick={e => {
            e.stopPropagation()
            isSuggestion ? this.onRemoveExamSuggestion(record) : this.checkExamSuggestion(record)
          }}
          onMouseOver={mouseOver} onMouseLeave={mouserLeave}
          alt='icon-star'
          src={ isSuggestion? IconStar : (checkStar && indexRow === index ? IconStar :IconStarGray) }
          style={{ cursor: 'pointer' }}
          />
        )
      },
      width: '40px',
      align: 'center'
    },
    {
      title: 'รหัสข้อสอบ',
      dataIndex: 'code',
      sorter: (strA, strB) => {
        let icmp = strA.code.toLowerCase().localeCompare(strB.code.toLowerCase())
        if (icmp !== 0) { 
          return icmp
        } 
        if (strA.code > strB.code) {
          return 1
        } else if (strA.code < strB.code) {
          return -1
        } else {
          return 0
        }
      }, 
      width: '130px',
      onCell: () => {
        return {
          onMouseEnter: () => { this.setState({ hoverCodeExam: true }) },
          onMouseLeave: () => { this.setState({ hoverCodeExam: false }) }
        }
      }
    },
    {
      title: () => {
        return ( <span style={{paddingLeft: '20px'}}>ชื่อ</span> )
      },
      dataIndex: 'name',
      render: (name,record) => { 
        let logo = null
        if(record.type === 'G') {
          if(record.subject === 'คณิตศาสตร์') {
            logo = IconExamMath
          }else {
            logo = IconExamScience
          }
        } else if(record.type === 'C'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CompetitionMathIcon
          }else {
            logo = CompetitionScienceIcon
          }
        }else if(record.type === 'CUSTOM'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CustomIconMath
          }else {
            logo = CustomIconScience
          }
        }else {
          if(record.subject === 'คณิตศาสตร์') {
            logo = ExerciseIconMath
          }else {
            logo = ExerciseIconScience
          }
        }
        return (
          <div><img alt='logo' src={logo} />{name}</div>
        )
      },
      sorter: (strA, strB) => {
        let icmp = strA.name.toLowerCase().localeCompare(strB.name.toLowerCase())
        if (icmp !== 0) { 
          return icmp
        } 
        if (strA.name > strB.name) {
          return 1
        } else if (strA.name < strB.name) {
          return -1
        } else {
          return 0
        }
      }, 
      width: '335px',
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      render: (type) => {
        return (
          <span>{ type === 'G' ? 'ตามหลักสูตร' : 
          type === 'C' ? 'แข่งขัน' : 
          type === 'CAT' ? 'CAT' :
          type === 'CUSTOM' ? 'กำหนดเอง' :
          type === 'E' ? 'แบบฝึกหัด' :
          type === 'EX' ? 'แบบฝึกหัด' : '-' }</span>
        )
      },
      sorter: (strA, strB) => {
        let icmp = strA.type.toLowerCase().localeCompare(strB.type.toLowerCase())
        if (icmp !== 0) { 
          return icmp
        } 
        if (strA.type > strB.type) {
          return 1
        } else if (strA.type < strB.type) {
          return -1
        } else {
          return 0
        }
      }, 
      // width: '30px',
    },
    {
      title: 'จำนวนครั้งที่ทำ',
      dataIndex: 'countTestings',
      align: 'center',
      sorter: (a, b) => a.countTestings - b.countTestings,
    },
    {
      title: 'จัดโดย',
      dataIndex: 'owner',
      render: (owner) => {
        return (
          <span>{owner.firstName+' '+owner.lastName}</span>
        )
      },
      sorter: (strA, strB) => {
        let icmp = strA.owner.firstName.toLowerCase().localeCompare(strB.owner.firstName.toLowerCase())
        if (icmp !== 0) { 
          return icmp
        } 
        if (strA.owner.firstName > strB.owner.firstName) {
          return 1
        } else if (strA.owner.firstName < strB.owner.firstName) {
          return -1
        } else {
          return 0
        }
      }, 
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createdAt',
      render : (createdAt) => {
        return (
          <span>{moment.unix(createdAt).add(543, 'Y').format('DD/MM/YYYY')}</span>
        )
      },
      sorter:(a,b) => a.createdAt - b.createdAt
    },
    {
      title: 'วันที่ทำล่าสุด',
      dataIndex: 'latestTesting',
      render : (latestTesting) => {
        return (
          <span>{latestTesting ? moment.unix(latestTesting).add(543, 'Y').format('DD/MM/YYYY') : '-'}</span>
        )
      },
      sorter:(a,b) => a.latestTesting - b.latestTesting
    },
    {
      title: 'จำนวนกลุ่ม',
      dataIndex: 'groupCount',
      align: 'center',
      sorter:(a,b) => a.groupCount - b.groupCount
    },
    {
      title: 'แสดง',
      dataIndex: 'status',
      render: (status,record) => {
        return (
          <Switch 
            title='แสดงข้อสอบ'
            checkedChildren='เปิด' 
            unCheckedChildren='ปิด' 
            checked={status === 'true' || status === true} 
            onChange={(checked, e) => {
              e.stopPropagation() 
              this.switchExamShow(record) 
            }}
          />
        )
      }
    },
    {
      render : (report,record,index) => {
        const { checkReport, indexRow } = this.state
        const mouserOver = () => { this.setState({ checkReport: true }) }
        const mouseLeave = () => { this.setState({ checkReport: false}) }
        return ( 
          <img alt='report' title='รายงาน' onClick={e => { e.stopPropagation();this.props.history.push(`/report/${record._id}/groups`,{cameFrom: 'allExam'}) }} onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkReport && indexRow === index ? Report : ReportGray} style={{ cursor: 'pointer' }}/>
        )
      }  
    },
    { 
      render: (share,record,index) => {
        const { checkShare, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkShare: true })
        }
        const mouseLeave = () => {
          this.setState({ checkShare: false})
        }
        return (
          <Dropdown overlay={this.menu(record)} trigger={['click']}  onClick={e => e.stopPropagation()}>
            <span title='แชร์' className='ant-dropdown-link actionIcon'>
              <img alt='share' onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={checkShare && indexRow === index ? Share : ShareGray} style={{ cursor: 'pointer' }}/>
            </span>
          </Dropdown> 
        )
      }  
    },
    {
      render: (deleteRow,record,index) => {
        const { checkMouserOverDelete, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkMouserOverDelete: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouserOverDelete: false})
        }
        return (
          <img alt='delete-exam' title='ลบข้อสอบ' onClick={e=>{e.stopPropagation();this.onDeleteExamSet(record)}} onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkMouserOverDelete && indexRow === index ? Delete : DeleteGray} style={{ cursor: 'pointer' }}/>
        )
      }
    }
  ]

  componentDidMount = () => {
    this.fetchCountExamByUserRole()
    this.fetchExams()
  }
  
  fetchExams = async() => { 
    const { searchKey, pages } = this.state
    this.setState({ isLoading: true })
    const role = this.getCurrentRoleActive()
    const examList = await this.props.getExamInTabAll(role, pages[role], searchKey)
    this.setState({
      examList,
      isLoading: false 
    })
  }

  getCurrentRoleActive = () => {
    if (this.state.activeKey === '1') return 'teacher'
    else if (this.state.activeKey === '2') return 'student'
    else if (this.state.activeKey === '3') return 'superTeacher'
    else return 'admin'
  }

  fetchCountExamByUserRole = async () => {
    const { searchKey } = this.state
    const examCount = await this.props.getCountExam(searchKey)
    this.setState({ examCount })
  }

  handleChangeTab = (activeKey) => {
    this.setState({ activeKey }, () => {
      this.fetchExams()
    })
  }

  fetchExamSet = async (filter = null) => { 
    this.setState({ isLoading: true })
    const res = await this.props.getExamSet(filter)
    this.setState({ examList: res, isLoading: false })
    if (res.error) message.error(res.error)
  }

  onSearchGroup = (value) => { 
    let { groupNotHaveExamList,examSetToShare } = this.state
    let isEmptySearch = true
    if(value && value !== [] && value !== ''){
      isEmptySearch = false
      groupNotHaveExamList = groupNotHaveExamList.filter(group => group.name.toLowerCase().indexOf(value.toLowerCase()) > -1 || group.code === value)
    }
    if(!isEmptySearch){
      this.setState({ groupNotHaveExamList })
    }else{
      this.fetchGroups(examSetToShare)
    } 
  }
  onOpenShareToGroup = async (examSet) => {
    await this.fetchGroups(examSet) 
    this.setState({
      isShowShareToGroupModal: true,
      examSetToShare: examSet,
    })
  }
  fetchGroups = async (filter = null) => { 
    this.setState({ isLoading: true }) 
    const res = filter ? await this.props.getGroupNotHaveExam(filter._id) : await this.props.getGroupList(filter)
    this.setState({ groupNotHaveExamList:res , isLoading: false })
    if (res.error) message.error(res.error)
  }
   
  onCloseModalShareToGroup = () => this.setState({isShowShareToGroupModal: false, examSetToShare: null})
  onSubmitShareToGroup = async (id, selectGroupList) => {
    this.setState({ isLoading: true }) 
    let data = { groupIds: selectGroupList } 
    const res = await this.props.shareExamToGroup(id, data)
    this.setState({ isLoading: false })
    if (res.error) return message.error(res.error)
    message.success('เพิ่มข้อสอบลงกลุ่มสำเร็จ')
    this.onCloseModalShareToGroup()
  }
  onDeleteExamSet = (examSet) => { 
    const modal = Modal.confirm({
      centered: true,
      okText: 'ลบข้อสอบ',
      cancelText: 'ไม่ลบ',
      width: 580,
      icon: null,
      title: (
        <button aria-label='Close' className='ant-modal-close' style={{display: 'block'}} onClick={()=> modal.destroy()}>
          <Icon type='close' className='ant-modal-close-x'/>
        </button>
      ),
      content: (
        <div style={{textAlign: 'center'}}>
          <IconExamWarning style={{fontSize: 256, marginTop: -30, marginBottom: -10}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>ลบข้อสอบ</h2>
          <p style={{marginBottom: 0}}>{examSet.name}</p>
          <p className='text-thin text-sm' style={{color: '#3D3D3D', opacity: 0.5}}>{examSet.code}</p>
          <p>คุณต้องการลบชุดข้อสอบนี้หรือไม่</p>
        </div>
      ),
      onOk: async () => { 
        const res = await this.props.deleteExamSet(examSet._id)
        if (res.error) message.error('พบข้อผิดพลาดในการลบชุดข้อสอบนี้')
        message.success('ลบข้อสอบสำเร็จ')
        this.onCloseModalShareToGroup()
        await this.fetchExams()
      },
    })
  }
  setSearchKey = (e) => {
    this.setState({ searchKey: e.target.value })
    if(e.target.value === '' && this.state.checkEnter === true){
      this.fetchExams()
      this.setState({paginationAfterSearch : 1})
    }
  }

  onPaginate = async (pagination) => {
    const { pages } = this.state
    const role = this.getCurrentRoleActive()
    pages[role] = pagination.current
    this.setState({ pagination, pages }, () => this.fetchExams())
  }

  onSearchExamSet = async (value) => {  
    this.setState({
      searchKey: value,
      pages: {
        teacher: 1,
        student: 1,
        superTeacher: 1,
        admin: 1
      }
    }, () => {
      this.fetchCountExamByUserRole({ search: value })
      this.fetchExams()
    })
  }

  switchExamShow = async(exam)=> {
    const { editExamSet } = this.props 
    this.setState({ isLoading: true }) 
    let data = { status: !exam.status }
    await editExamSet(exam._id,data)
    await this.fetchExams()
    this.setState({ isLoading: false }) 
  }
  onCopyLinkSuccess = () => {
    Modal.success({
      closable: true,
      centered: true,
      icon: null,
      maskClosable: true,
      okButtonProps: {hidden: true},
      width: 220,
      content: (
        <div style={{textAlign: 'center'}}>
          <IconCorrect style={{fontSize: 120}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>คัดลอกลิงก์สำเร็จ</h2>
        </div>
      )
    })
  }
  checkExamSuggestion = async(exam) => {
    const { onCheckExamSuggestion } = this.props

    await onCheckExamSuggestion(exam._id)
    this.fetchExams()

    message.success('เพิ่มข้อสอบแนะนำสำเร็จ')
  }
  onRemoveExamSuggestion = async(exam) => {
    const { removeExamSuggestion } = this.props

    this.setState({ isLoading: true })

    await removeExamSuggestion(exam._id)
    this.fetchExams()

    message.success('ยกเลิกข้อสอบแนะนำสำเร็จ')
    this.setState({ isLoading: false })
  }
  render(){
    const { isLoading, examList, searchKey, hoverCodeExam, examCount } = this.state 
    
    return (
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading }>
          <Header onSearchExamSet={this.onSearchExamSet} searchKey={searchKey}/>
          <Tabs defaultActiveKey='1' onChange={this.handleChangeTab}>
            <TabPane tab={`ครู ${examCount.teacher ? `(${examCount.teacher})` : ''}`} key='1'>
              <Table
                scroll={{ x: true }}
                rowKey = {(record, i) => i}
                columns = {this.columns}
                dataSource = {examList.items || []}
                pagination={{ simple: true, defaultCurrent: 1, current : examList.page, position: 'both', total: examList.totalCount }}
                onChange = {this.onPaginate}
                onRow = {(record, rowIndex) => {
                  return {
                    onMouseEnter: () => { this.setState({ indexRow: rowIndex}) },
                    onClick: () => { if(!hoverCodeExam) this.props.history.push(`/examsAll/${record._id}`) }
                  }
                }}
              />
            </TabPane>
            <TabPane tab={`นักเรียน ${examCount.student ? `(${examCount.student})` : ''}`} key='2'>
              <Table
                scroll={{ x: true }}
                rowKey = {(record,i) => i }
                columns = {this.columns}
                dataSource = {examList.items || []}
                pagination={{ simple: true, defaultCurrent: 1, current : examList.page, position: 'both', total: examList.totalCount }}
                onChange = {this.onPaginate}
                onRow = {(record, rowIndex) => {
                  return {
                    onMouseEnter: () => { this.setState({ indexRow: rowIndex}) },
                    onClick: () => { if(!hoverCodeExam) this.props.history.push(`/examset/${record._id}`) }
                  }
                }}
              />
            </TabPane>
            <TabPane tab={`ครูพิเศษ ${examCount.superTeacher ? `(${examCount.superTeacher})` : ''}`} key='3'>
              <Table
                scroll={{x:true}}
                rowKey = {(record,i) => i}
                columns = {this.columns}
                dataSource = {examList.items || []}
                pagination={{ simple: true, defaultCurrent: 1, current : examList.page, position: 'both', total: examList.totalCount }}
                onChange = {this.onPaginate}
                onRow = {(record, rowIndex) => {
                  return {
                    onMouseEnter: () => { this.setState({ indexRow: rowIndex}) },
                    onClick: () => { if(!hoverCodeExam) this.props.history.push(`/examset/${record._id}`) }
                  }
                }}
              />
            </TabPane>
            <TabPane tab={`ผู้ดูแลระบบ ${examCount.admin ? `(${examCount.admin})` : ''}`} key='4'>
              <Table
                scroll={{x:true}}
                rowKey = {(record,i) => i}
                columns = {this.columns}
                dataSource = {examList.items || []}
                pagination={{ simple: true, defaultCurrent: 1, current : examList.page, position: 'both', total: examList.totalCount }}
                onChange = {this.onPaginate}
                onRow = {(record, rowIndex) => {
                  return {
                    onMouseEnter: () => { this.setState({ indexRow: rowIndex}) },
                    onClick: () => { if(!hoverCodeExam) this.props.history.push(`/examset/${record._id}`) }
                  }
                }}
              />
            </TabPane>
          </Tabs>
          
        </Spin>
        <ModalShareToGroup
          isVisible={this.state.isShowShareToGroupModal}
          onAddExamSetToGroup={this.onSubmitShareToGroup}
          onCancel={this.onCloseModalShareToGroup}
          examSet={this.state.examSetToShare}
          groupList={this.state.groupNotHaveExamList}
          onSearchGroupList={this.onSearchGroup}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
    groupList: state.group.groupList,
    auth: state.auth.profile,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getExamsAll,
      editExamSet,
      shareExamToGroup,
      deleteExamSet,
      getGroupNotHaveExam,
      getExamInTabAll,
      getTeacherExamInTabAll,
      getStudentExamInTabAll,
      getSuperTeacherExamInTabAll,
      getAdminExamInTabAll,
      getCountExam,
      removeExamSuggestion,
      onCheckExamSuggestion
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ExamsAll)