import React, { Component } from 'react'
import {
  Route,
  Redirect,
  Switch
} from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ScrollToTop from 'react-router-scroll-top'
import Home from './components/Home'
import { GetProfile } from './redux/auth/action'
import { GetServerConfig } from './redux/master/action'
import { getCookie } from './util/helper'
// import ReactGA from 'react-ga'
// import ReactPixel from 'react-facebook-pixel'
import { SetAxiosToken } from './redux/fetch'

import Register from './components/Register'
import Login from './components/Login'
import ForgetPassword from './components/ForgetPassword'
import ResetPassword from './components/ResetPassword'
import VerifyEmail from './components/VerifyEmail'

import Suggest from './components/Exam/Suggest'
import ExamSet from './components/Exam/examSet'
import ExamSetCreate from './components/Exam/CreateExamSet'
import ExamSetDetail from './components/Exam/DetailExamSet'
import ExamsAll from './components/ExamsAll'

import Test from './components/Testing/index'
import TestingSolution from './components/Testing/TestingSolution'
import TestingExample from './components/Testing/TestingExample'
import TestResult from './components/TestingResult/index'

import Group from './components/Group'
import GroupDetail from './components/Group/GroupDetail'
import ListStudentLeft from './components/Group/ListStudentLeft'
import Profile from './components/Profile'
import Performance from './components/Performance'
import User from './components/User'
import Prefix from './components/PrefixName'
import Relation from './components/Relation'
// import Report from './components/Report'

import AuditTrail from './components/AuditTrail'

import ReportLogin from './components/ReportAdmin/Login'
// import ReportLoginDescription from './components/ReportAdmin/Login'

import ReportFinished from './components/ReportAdmin/FinishedTesting'
// import ReportTestingDescription from './components/ReportAdmin/Testing/DescriptionTesting.js'

import ReportUserAccount from './components/ReportAdmin/UserAccount'
// import ReportUserAccountDescription from './components/ReportAdmin/UserAccount/DescriptionUserAccount.js'

import ReportExamSet from './components/ReportAdmin/ExamSet'
// import ReportExamSetDescription from './components/ReportAdmin/ExamSet/DescriptionExamSet.js'

import ReportTesting from './components/ReportAdmin/Testing'
// import ReportExamSetDescription from './components/ReportAdmin/ExamSet/DescriptionExamSet.js'


import ExamStudents from './components/Report/ExamStudents'
import GroupStudents from './components/Report/GroupStudents'
import ExamTeachers from './components/Report/ExamTeachers'
import GroupTeachers from './components/Report/GroupTeachers'
import GroupTeachersStats from './components/Report/GroupTeachersStats'
import StudentDetailComponent from './components/Layout/StudentComponent/StudentDetailComponent'
import TeacherDetailComponent from './components/Layout/TeacherComponent/TeacherDetailComponent'
import HomeExamSuggest from './components/Layout/HomeExamSuggest'
import ContactUsComponent from './components/Layout/ContactUsComponent'
import AboutUsComponent from './components/Layout/AboutUsComponent'
import TermAndConditionGuest from './components/Layout/TermAndConditionGuest'
import DataPolicyGuest from './components/Layout/DataPolicyGuest'

import SchoolConfig from './components/SchoolConfig'
import Departments from './components/Departments'
import Provinces from './components/Provinces'
import Subjects from './components/Subjects'
import Competitions from './components/Competitions'

import LayoutGuest from './components/Layout/LayoutGuest'
import LayoutWhiteGuest from './components/Layout/LayoutWhiteGuest'
import LayoutCMS from './components/Layout/LayoutCMS'

import ContactUsInHome from './components/ContactUs/ContactUs'
import ServerComponent from './components/Server/ServerComponent'
import EditContactUsInHomeMaster from './components/ContactUs/EditContactUs'
import FaqComponent from './components/FAQ/index'
import DataPolicy from './components/DataPolicy/index'
import TermsAndConditions from './components/TermsAndConditions/index'

import Notice from './components/Notice'
import FaqNonmember from './components/Layout/FAQGuest/FAQComponent'

import FaqMember from './components/FaqMember/FaqMember'
import ContactUsMember from './components/ContactUsMember/ContactUsMember'
import OnBoarding from './components/OnBoarding/OnBoarding'

import { USER_ROLE } from './util/config'
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './components/Consent/CustomCookieConsent.css';
import CookieConsentComponent from './components/Consent/CookieConsentComponent'

const LayoutGuestRoute = ({ component: Component, isAuthenticated, notice, ...rest }) => {   
  return (
    <Route
      {...rest}
      render={props => {
        return (
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: localStorage.getItem('pathDirect') !== 'logout' && localStorage.getItem('pathDirect')  ?  localStorage.getItem('pathDirect') : '/examset',
                state: { from: props.location },
              }}
            />
          ) : (
            notice ?
              <ScrollToTop>
                <LayoutWhiteGuest>
                  <Component {...props} />
                </LayoutWhiteGuest>
              </ScrollToTop>
              :
              <ScrollToTop>
                <LayoutGuest>
                  <Component {...props} />
                </LayoutGuest>
              </ScrollToTop>
          )
        )
      } 
      }
    />
  )
}

const LayoutCMSRoute = ({ component: Component, isAuthenticated, showPrivacyPolicyNotice, ...rest }) => {     
  if (typeof window !== 'undefined') {
    if(!isAuthenticated && (rest.path === '/examset/:id' || localStorage.getItem('pathDirect') !== 'logout' )) { 
      localStorage.setItem('pathDirect', rest.location.pathname)
    } else {
      localStorage.removeItem('pathDirect')
    }
  }

  const needRedirect = (showPrivacyPolicyNotice && (rest.path != '/examset' && rest.path != '/profile'))

  return (
    <Route
      {...rest}
      render={props => { 
        return (
          isAuthenticated ? (
            needRedirect ?  ( <Redirect to='/examset' /> ) :
            ( <LayoutCMS>
              <Component {...props} />
            </LayoutCMS> ) 
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                // pathname: '/',
                state: { from: props.location },
              }}
            />
          )
        )
      }
      }
    />
  )
}

const ReRoute = ({  isAuthenticated, ...rest }) => {     
  return (
            <Redirect
              to={{
                pathname: '/code/' + rest.computedMatch.params.id + '/' + rest.computedMatch.params.gcode,
              }}
            />
  )
}

const AuthDetermineRoute = ({ componentAuth: ComponentAuth, componentGuest: ComponentGuest, isAuthenticated, notice, ...rest }) => {
  // if (serverStatus && serverStatus.isOffline) {
  //   return (<Redirect
  //     to={{
  //       pathname: '/home'
  //     }}
  //   />)
  // }
  return isAuthenticated ? (<LayoutCMSRoute component={ComponentAuth} {...rest} isAuthenticated={true} />) : (<LayoutGuestRoute component={ComponentGuest} notic={notice} {...rest} isAuthenticated={false} />)
}
    

const Routes = ({ isAuthenticated, showPrivacyPolicyNotice }) => (
  <Switch>
    <LayoutGuestRoute exact path='/'                                  component={ Home } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} pageName={'home'} />
    <LayoutGuestRoute       path='/register'                          component={ Register } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutGuestRoute       path='/login'                             component={ Login } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} pageName={'login'} />
    <LayoutGuestRoute       path='/forget-password'                   component={ ForgetPassword } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutGuestRoute       path='/reset-password/:token'             component={ ResetPassword } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutGuestRoute       path='/password'                          component={ Login } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutGuestRoute       path='/auth'                              component={ VerifyEmail } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/profile'                           component={ Profile } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/performance'                       component={ Performance } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/examset'                           component={ ExamSet } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path={['/examset/create/curriculum', '/examset/create/competition', '/examset/create/custom', '/examset/create/cat' , '/examset/create/exercise', '/examset/create/curriculumbook']} component={ ExamSetCreate } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <ReRoute     path='/ext/:id/:gcode'                        isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <AuthDetermineRoute     path={['/examset/:id','/code/:code/:gcode']}                       componentAuth={ExamSetDetail} componentGuest={HomeExamSuggest} isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    {/* <LayoutCMSRoute         path='/examset/:id'                      component={ ExamSetDetail } isAuthenticated={isAuthenticated} /> */}
    <LayoutCMSRoute         path='/examsAll/:id'                      component={ ExamSetDetail } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/suggest/:id'                       component={ ExamSetDetail } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path={['/group/:groupId/examset/:id/extlink','/group/:groupId/examset/:id',]}        component={ ExamSetDetail } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/suggest'                           component={ Suggest } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/examsAll'                          component={ ExamsAll } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/mastercontactus'                    component={ ContactUsInHome } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/server'                             component={ ServerComponent } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/mastereditcontactus'                component={ EditContactUsInHomeMaster } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/faq'                                component={ FaqComponent } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/faqmember'                          component={ FaqMember } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/contactusmember'                          component={ ContactUsMember } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/datapolicy'                         component={ DataPolicy } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/condition'                          component={ TermsAndConditions } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    
    {/* <LayoutCMSRoute   exact path='/report'                             component = { Report }        isAuthenticated={isAuthenticated}/> */}

    <LayoutCMSRoute   exact path='/report/:examId'                     component = { ExamStudents }  isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/report/:examId/groups'              component = { ExamTeachers }  isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/report/exam/:examId/group/:groupId' component = { GroupStudents } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/report/:examId/group/:groupId'      component = { GroupTeachers } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/report/:examId/group/:groupId/stats/:statstype'      component = { GroupTeachersStats } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    
    <LayoutCMSRoute   exact path='/onboarding'      component = { OnBoarding } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    {/* Report Admin */}
    <LayoutCMSRoute         path='/reportaudit'                         component = { AuditTrail } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />

    <LayoutCMSRoute   exact path='/reportlogin'                        component = { ReportLogin } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/reportlogin/description'            component = { ReportLogin } isAuthenticated={isAuthenticated} />

    <LayoutCMSRoute   exact path='/reportfinished'                      component = { ReportFinished } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/reportfinished/description'          component = { ReportFinished } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />

    <LayoutCMSRoute   exact path='/reportaccount'                      component = { ReportUserAccount } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/reportaccount/description'          component = { ReportUserAccount } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />

    <LayoutCMSRoute   exact path='/reportexamset'                      component = { ReportExamSet } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/reportexamset/description'          component = { ReportExamSet } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    
    <LayoutCMSRoute   exact path='/reporttesting'                      component = { ReportTesting } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/reporttesting/description'          component = { ReportTesting } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />

    <Route            exact path='/testing/:id/ext/:extgroup' render={props => (<Test {...props} />) } />    
    <Route            exact path='/testing/:id' render={props => (<Test {...props} />) } />
    <Route            exact path='/testing/:id/solution' render={props => (<TestingSolution {...props} />) } />
    <Route            exact path='/testing/:examId/example' render={props => (<TestingExample {...props} />) }/>
    <Route            exact path='/testing/group/:groupId/examset/:id' render={props => (<Test {...props} />)}/>
    <Route            exact path='/result/:testingId/:examId' render={props => (<TestResult {...props} />) } />
    <LayoutCMSRoute   exact path='/group/listofstudentleft' component={ ListStudentLeft } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/group/:id' component={ GroupDetail } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <AuthDetermineRoute     path='/group/:groupId/examset/:id' componentAuth={ExamSetDetail} componentGuest={HomeExamSuggest} isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute   exact path='/group' component={ Group } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/user' component={ User } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />

    <LayoutCMSRoute         path='/prefix' component={ Prefix } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/relation' component={ Relation } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/school' component={ SchoolConfig } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/departments' component={ Departments } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/provinces' component={ Provinces } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/subjects' component={ Subjects } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutCMSRoute         path='/competitions' component={ Competitions } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />

    <LayoutCMSRoute         path='/notice' component={ Notice } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />

    <LayoutGuestRoute       path='/student' component={ StudentDetailComponent } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutGuestRoute       path='/teacher' component={ TeacherDetailComponent } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutGuestRoute       path='/homesuggest' component={ HomeExamSuggest } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />

    <LayoutGuestRoute   exact    path='/contact' component={ ContactUsComponent } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutGuestRoute   exact    path='/term' component={ TermAndConditionGuest } isAuthenticated={isAuthenticated} notice = {true} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutGuestRoute   exact    path='/data_pivacy' component={ DataPolicyGuest } isAuthenticated={isAuthenticated} notice = {true} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />

    <LayoutGuestRoute   exact   path='/about' component={ AboutUsComponent } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />
    <LayoutGuestRoute   exact   path='/faqguest' component={ FaqNonmember } isAuthenticated={isAuthenticated} showPrivacyPolicyNotice={showPrivacyPolicyNotice} />

    <Redirect to='/' />
  </Switch>
)

class AppRouter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
    this.initial()
  }

  initial = async () => {
    await this.props.GetServerConfig()
    const token = getCookie('token')
    if (token) {
      SetAxiosToken(token)
      await this.props.GetProfile()
    }
    this.setState({ isLoading: false})
  }

  render () {
    const { isLoading } = this.state
    if (isLoading || (getCookie('token') && !this.props.profile)) {
      return <div></div>
    }

    return (
      <div className='application'>
                  <CookieConsentComponent />
        <Routes {...this.props} />
      </div>
    )
  } 
}
  
const mapStateToProps = (state) => {
  const showPrivacyPolicy = (state.auth.profile && (state.auth.profile.role === USER_ROLE.STUDENT || state.auth.profile.role === USER_ROLE.TEACHER) && !state.auth.profile.hasSeenPrivacyPolicy)
  return {
    isAuthenticated: state.auth.token ? true : false,
    showPrivacyPolicyNotice: showPrivacyPolicy,
    profile: state.auth.profile
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetServerConfig,
      GetProfile
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter)
