import React, { Component } from 'react'
import styles from './TestingResult.module.scss'
import {
  Row,
  Col,
  Layout,
  Avatar
} from 'antd'
import NumTestingScoreIcon from '../IconSVG/NumTestingScoreIcon.svg'
import ProfileDefaultIconResultScore from '../IconSVG/ProfileDefaultIconResultScore.svg'
import TimeScoreIcon from '../IconSVG/TimeScoreIcon.svg'
import IPSTLogo from '../IconSVG/IPSTLogo.svg'
import FrameProfileTestResult from '../IconSVG/FrameProfileTestResult.svg'
import BackgroundImgTestResultFirst from '../IconSVG/BackgroundImgTestResultFirst.svg'
import StartBackgroundFrame from '../IconSVG/StartBackgroundFrame.svg'

import CompetitionScienceIcon from '../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../IconSVG/CompetitionMathIcon.svg'
import MathCATIcon from '../IconSVG/MathCATIcon.svg'
import ScienceCATIcon from '../IconSVG/ScienceCATIcon.svg'
import IconScienceExam from '../IconSVG/ExamScience.svg'
import IconMathExam from '../IconSVG/ExamMath.svg'
import CustomIconMath from '../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../IconSVG/CustomIconScience.svg'
import ExerciseIconMath from '../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../IconSVG/ExerciseIconScience.svg'

import MediumScoreBoy from '../IconSVG/MediumScoreBoy.svg'
import MediumScoreGirl from '../IconSVG/MediumScoreGirl.svg'
import LowScoreBoy from '../IconSVG/LowScoreBoy.svg'
import LowScoreGirl from '../IconSVG/LowScoreGirl.svg'
import HighScoreBoy from '../IconSVG/HighScoreBoy.svg'
import HighScoreGirl from '../IconSVG/HighScoreGirl.svg'

import ExamTitle from '../Display/ExamTitle'
import * as moment from 'moment'

const { Header, Content } = Layout
class ResultScore extends Component {
  render(){
    const { user, testingResult, exam, logo, examTime, profileImage = null } = this.props 
    const startTime = moment.unix(testingResult.startedAt)
    const finishTime = moment.unix(testingResult.finishedAt)
    const oldAvgScore = parseFloat(testingResult.oldAvg).toFixed(2)
    const newAvgScore = parseFloat(testingResult.newAvg).toFixed(2) 
    moment.utc(moment(finishTime,'HH:mm:ss').diff(moment(startTime,'HH:mm:ss'))).format('HH:mm:ss') 
    const durationTime = finishTime.diff(startTime)
    let subjectIcon = IconMathExam
    if(exam.type === 'G') {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = IconMathExam
      }else {
        subjectIcon = IconScienceExam
      }
    } else if(exam.type === 'C'){
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = CompetitionMathIcon
      }else {
        subjectIcon = CompetitionScienceIcon
      }
    }else if(exam.type === 'CUSTOM'){
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = CustomIconMath
      }else {
        subjectIcon = CustomIconScience
      }
    } else if(exam.type === 'CAT') {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = MathCATIcon
      }else {
        subjectIcon = ScienceCATIcon
      }
    } else {
      if(exam.subject === 'คณิตศาสตร์') {
        subjectIcon = ExerciseIconMath
      }else {
        subjectIcon = ExerciseIconScience
      }
    }
    
    const owner = exam.owner ? { prefixName: ['teacher', 'superTeacher'].includes(exam.owner.role) ? 'ครู' : '' , fullname: `${exam.owner.firstName} ${exam.owner.lastName}` } : undefined 
     
    return (
      <div id='result' className={styles.background_share} > 
        <Header className={styles.header_testing} >
          <div className='mobile'>
            <div className='subject-detail'>
              <div className='subject-detail-content'>
                <img alt='subject-icon' src={subjectIcon} width={90} height={90} />

                <div className='content'>
                  <h2>{exam.subject} {exam.grade}</h2>
                  <p style={{ color:'#FFFFFF'}}>{exam.code || ''}</p>
                  <p>โดย: {owner ? `${owner.prefixName}${owner.fullname}`: ''}</p>
                  <p>
                    <span><img src={NumTestingScoreIcon} alt='question-count'/>{exam.quantity} ข้อ</span>
                    {
                      exam.duration ? (
                        <span><img alt='time-score-icon' src={TimeScoreIcon}/>{exam.duration / 60} นาที</span>
                      ) : ''
                    }
                  </p>
                </div>
              </div>

              <div className='exam-count'>
                <p>สอบครั้งที่ {testingResult.time}</p>
                <p>{finishTime.format('DD/MM/YYYY HH:mm')} น.</p>
              </div>
            </div>
          </div>

          <div className='desktop'>
            <Row >
              <Col xl={20} lg={20} md={18} offset={2}>
                <Row>
                  <Col xl={10} lg={10} md={10} className={styles.navbar_left}>
                    <ExamTitle exam={exam} />
                  </Col>
                  <Col xl={14} lg={14} md={20} className={styles.navbar_right}>
                    <Row className={styles.row_right}>
                      <span className={styles.exam_time}>สอบครั้งที่ {examTime ? examTime : testingResult.time}</span>
                      {/* <span className={styles.exam_time}>สอบครั้งที่ {testingResult.time}</span> */}
                    </Row>
                    <Row>
                      <span>{finishTime.format('DD/MM/YYYY HH:mm')} น.</span>
                    </Row>
                    <Row>
                      <img alt='num-testing-score-icon' src={NumTestingScoreIcon}/><span style={{ marginRight:'20px' }}>{exam.quantity} ข้อ</span>
                      {exam.duration ? <span><img alt='time-score-icon' src={TimeScoreIcon}/>{exam.duration / 60} นาที</span> : '' }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr style={{ border:'0.25px solid #E0E0E0', width:'83%'}}/>
          </div>
        </Header>

        <Content>
          <div className='mobile'>
            <div className={styles.result_score}>
              <div className='profile-avatar'> 
                { 
                  exam.type === 'CAT' ?  
                    testingResult.theta > 1.6 ? ( user.prefixName === 'นาย' || user.prefixName === 'ด.ช.'  ? <img src={HighScoreBoy}/> : <img alt='high-score-girl' src={HighScoreGirl}/> ) :
                      testingResult.theta > -1.4 ? ( user.prefixName === 'นาย' || user.prefixName === 'ด.ช.' ? <img src={MediumScoreBoy}/> : <img alt='medium-score-girl' src={MediumScoreGirl}/> ) : 
                        testingResult.theta > -3.5 ? ( user.prefixName === 'นาย' || user.prefixName === 'ด.ช.' ? <img src={LowScoreBoy}/> : <img alt='low-score-girl' src={LowScoreGirl}/> ) : 
                          profileImage ? <Avatar style={{ position:'absolute', top: '20px',margin: 'auto',left: 0,right: '5px' }} size={170} src={profileImage} /> : <img alt='profile-default-result-score-icon' src={ProfileDefaultIconResultScore} />: 
                    (profileImage ? <Avatar style={{ position:'absolute', top: '20px',margin: 'auto',left: 0,right: '5px' }} size={170} src={profileImage} /> : <img alt='profile-default-result-score-icon' src={ProfileDefaultIconResultScore} />)
                    // <img src={ProfileDefaultIconResultScore} />: <img src={ProfileDefaultIconResultScore} />
                }  
                <img src={FrameProfileTestResult} />
              </div>

              <div className='name'>
                <h1>{user.prefixName}{user.firstName} {user.lastName}</h1>
              </div>
              <div className='score'>
                <h2>คะแนนครั้งล่าสุดที่คุณทำได้</h2>
                <h1>{testingResult.score}/{exam.quantity}</h1>
              </div>

              <div className='average-score'>
                <div className='old-score'>
                  <h3>คะแนนเฉลี่ยเดิม</h3>
                  <h2>{oldAvgScore && oldAvgScore !== 'NaN' ? oldAvgScore : 0}/{exam.quantity}</h2>
                </div>

                <div className='latest-score'>
                  <h3>คะแนนเฉลี่ยล่าสุด</h3>
                  <h2>{ newAvgScore && newAvgScore !== 'NaN' ? newAvgScore : 0}/{exam.quantity}</h2>
                </div>
              </div>

              <div className='time-used'>
                <h3>เวลาในการสอบ</h3>
                <h2>{durationTime ? moment.utc(moment(finishTime,'HH:mm:ss').diff(moment(startTime,'HH:mm:ss'))).format('HH:mm:ss') : '00:00:00'}</h2>
              </div> 
            </div>
          </div>

          <div className='desktop'>
            <Row>
              <Col className={styles.col_content} offset={5} xl={14} lg={14}>
                <div className={ styles.profile }>
                  <div className={ styles.profile_img }>
                    {/* <img src={ProfileDefaultIconResultScore} /> */}
                    { 
                      exam.type === 'CAT' ?  
                        testingResult.theta > 1.6 ? ( user.prefixName === 'นาย' || user.prefixName === 'ด.ช.'  ? <img src={HighScoreBoy}/> : <img src={HighScoreGirl}/> ) :
                          testingResult.theta > -1.4 ? ( user.prefixName === 'นาย' || user.prefixName === 'ด.ช.' ? <img src={MediumScoreBoy}/> : <img src={MediumScoreGirl}/> ) : 
                            testingResult.theta > -3.5 ? ( user.prefixName === 'นาย' || user.prefixName === 'ด.ช.' ? <img src={LowScoreBoy}/> : <img src={LowScoreGirl}/> ) : 
                              profileImage ? <Avatar size={170} src={profileImage} /> : <img src={ProfileDefaultIconResultScore} />: 
                        (profileImage ? <Avatar size={170} src={profileImage} /> : <img src={ProfileDefaultIconResultScore} />)  
                    }  
                  </div>
                  <div className={ styles.profile_frame }>
                    <img src={FrameProfileTestResult}  />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={5} lg={5}/>
              <Col className={styles.col_content} xl={14} lg={14}><h1 className={styles.name_content}>{user.prefixName}{user.firstName} {user.lastName}</h1></Col>
              <Col xl={5} lg={5}/>
            </Row>
            {
              exam.type === 'CAT' ?
                <div>
                  <Row> 
                    <Col className={styles.col_content} offset={5} xl={14} lg={14}><h2 className={styles.score_text_content}>ระดับความเก่งที่คุณทำได้</h2></Col>
                  </Row>
                  <Row> 
                    <Col className={styles.col_content} offset={5} xl={14} lg={14}>
                      <span className={styles.score_text}>{ testingResult.theta > 1.6 ? 'สูง' : testingResult.theta > -1.4 ? 'ปานกลาง' : 'ต่ำ'}</span> 
                    </Col>
                    <Col className={styles.col_content} offset={5} xl={14} lg={14}>
                      <span className={styles.score_subtitle}>{`(ค่า Theta ${testingResult.theta})`}</span>
                    </Col>
                  </Row>
                </div>
                : 
                <div>
                  <Row>
                    <Col xl={5} lg={5}/>
                    <Col className={styles.col_content} xl={14} lg={14}><h2 className={styles.score_text_content}>คะแนนที่คุณทำได้</h2></Col>
                    <Col xl={5} lg={5}/>
                  </Row>
                  <Row>
                    <Col xl={5} lg={5}/>
                    <Col className={styles.col_content} xl={14} lg={14}><h1 className={styles.score_content}>{testingResult.score}/{exam.quantity}</h1></Col>
                    <Col xl={5} lg={5}/>
                  </Row>
                  <Row>
                    <Col xl={5} lg={5}/>
                    <Col className={styles.col_content} xl={14} lg={14}>
                      <Row className={styles.row_result_content}>
                        <Col xl={8} lg={8}><h3 className={styles.row_result_text_content}>เวลาในการสอบ</h3></Col>
                        <Col xl={8} lg={8}><h3 className={styles.row_result_text_content}>คะแนนเฉลี่ยเดิม</h3></Col>
                        <Col xl={8} lg={8}><h3 className={styles.row_result_text_content}>คะแนนเฉลี่ยล่าสุด</h3></Col>
                      </Row>
                    </Col>
                    <Col xl={5} lg={5}/>
                  </Row>
                  <Row>
                    <Col xl={5} lg={5}/>
                    <Col className={styles.col_content} xl={14} lg={14}>
                      <Row className={styles.row_result_content}>
                        <Col xl={8} lg={8}><h2 className={styles.row_result_text_content}>{`${parseInt((testingResult.testingTime/60)/60)}:${moment(testingResult.testingTime*1000).format('mm')}:${moment(testingResult.testingTime*1000).format('ss')}`}</h2></Col>
                        <Col xl={8} lg={8}><h2 className={styles.row_result_text_content}>{oldAvgScore && oldAvgScore !== 'NaN'  ? oldAvgScore : 0}/{exam.quantity}</h2></Col>
                        <Col xl={8} lg={8}><h2 className={styles.row_result_text_content}>{newAvgScore && newAvgScore !== 'NaN' ? newAvgScore : 0}/{exam.quantity}</h2></Col>
                      </Row>
                    </Col>
                    <Col xl={5} lg={5}>
                      {
                        logo?
                          <div className={styles.logo_IPST}>
                            <img src={IPSTLogo}/>
                          </div>
                          :''
                      }
                    </Col>
                  </Row>
                </div>
            } 
            
            <div className={styles.background_img_curve} style={{ zIndex: 0 }}><img src={BackgroundImgTestResultFirst} /></div>
            <div className={styles.background_img_start}><img src={StartBackgroundFrame}/></div>
          </div>
        </Content>
      </div>
    )
  }
}

export default ResultScore
