import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  message,
  Spin,
  Button,
  Switch,
  Modal,
  Icon,
  Collapse, 
} from 'antd'
import {
  getExamSet,
  editExamSet,
  deleteExamSet,
  shareExamToGroup,
  getFetchExamSuggest,
  updateSequenceSuggestExam,
  removeExamSuggestion
} from '../../../redux/exam/action'
import {
  getGroupList,
} from '../../../redux/group/action'
import {
  IconCorrect,
  IconExamWarning,
} from '../../IconSVG'

import CustomIconMath from '../../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../../IconSVG/CustomIconScience.svg'

import ExerciseIconMath from '../../IconSVG/ExerciseIconMath.svg'
import ExerciseIconScience from '../../IconSVG/ExerciseIconScience.svg'

import IconStar from '../../IconSVG/IconStar.svg'
import IconDownUp from '../../IconSVG/IconDownUp.svg'
import Report from '../../IconSVG/Report.svg'
import ReportGray from '../../IconSVG/ReportGray.svg'
import IconExamMath from '../../IconSVG/IconExamMath.svg'
import IconExamScience from '../../IconSVG/IconExamScience.svg'
import CompetitionScienceIcon from '../../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../../IconSVG/CompetitionMathIcon.svg'
import SearchModal from '../../modal/SearchModal'

import SuggestMath from './SuggestMath'
import SuggestScience from './SuggestScience'
import ModalShareToGroup from '../ModalShareToGroup'
import ExamHeader from '../ExamHeader'

import './Suggest.scss'
import styles from '../Exam.module.scss'
import moment from 'moment'

const { Panel } = Collapse

class Suggest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isShowShareToGroupModal: false,
      examSetToShare: null,
      searchKey: '',
      checkEnter: false,
      examSuggest: [],
      indexRow: null,
      checkReport: null,
      modalRemoveSuggestExam: false,
      hoverCodeExam: false
    }
  }

  columnsSuggestAdmin = [
    {
      //title: '',
      key:'iconDownUp',
      render:() => {
        return (
          <img alt='downup' title='ลาก สลับตำแหน่ง' src={IconDownUp}/>
        )
      },
      align: 'center'
    },
    {
      //title: 'ลำดับ',
      key:'range',
      render:(range,record,index) => {
        return (
          <span>{index+1}</span>
        )
      },
      align: 'center'
    },
    {
      //title: 'ดาว',
      key:'suggestStar',
      render: (record) => {
        return(
          <img alt='star' title='ยกเลิกข้อสอบแนะนำ' style={{ cursor: 'pointer' }} onClick={e => { e.stopPropagation(); this.onRemoveSuggestExam(record) } } src={IconStar} />
        )
      },
      align: 'center'
    },
    {
      title: 'รหัสข้อสอบ',
      dataIndex: 'code',
      key: 'code',
      render: (code) => {  
        const onMouseEnter = () => { this.setState({ hoverCodeExam: true }) }
        const onMouseLeave = () => { this.setState({ hoverCodeExam: false }) }
        return <span onMouseOver={ onMouseEnter } onMouseLeave={ onMouseLeave } >{code}</span> 
      }
    },
    {
      title: () => { return ( <span style = {{ paddingLeft: '20px' }}>ชื่อ</span> ) },
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        let logo = null
        if(record.type === 'G') {
          if(record.subject === 'คณิตศาสตร์') {
            logo = IconExamMath
          }else {
            logo = IconExamScience
          }
        } else if(record.type === 'C'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CompetitionMathIcon
          }else {
            logo = CompetitionScienceIcon
          }
        }else if(record.type === 'CUSTOM'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CustomIconMath
          }else {
            logo = CustomIconScience
          }
        }else {
          if(record.subject === 'คณิตศาสตร์') {
            logo = ExerciseIconMath
          }else {
            logo = ExerciseIconScience
          }
        }
        return (
          <span>
            <img alt='subject' src={logo} />
            {name}
          </span>)
      },
      width: '275px'
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        return (
          <span>{
            type === 'G' ? 'ตามหลักสูตร' : 
            type === 'C' ? 'แข่งขัน' : 
            type === 'CUSTOM' ? 'กำหนดเอง' : 
            type === 'CAT' ? 'CAT' : 'แบบฝึกหัด' 
          }</span>
        )
      }
    },
    {
      title: 'จำนวนครั้งที่ทำ',
      dataIndex: 'countTestings',
      key:'countTestings',
      align: 'center'
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        return (
          <span>{ createdAt ? moment.unix(createdAt).add(543, 'Y').format('DD/MM/YYYY') : '-' }</span>
        )
      }
    },
    {
      title: 'แสดง',
      dataIndex: 'status',
      key: 'status',
      render: (status, examSet) => <Switch checkedChildren='เปิด' unCheckedChildren='ปิด' checked={status === 'true' || status === true} onChange={ (checked, e) => {
        e.stopPropagation()
        this.onChangeExamStatus(examSet._id, checked)
      } } />,
    },
    {
      // title: '-',
      key: 'report',
      render: (report, record,index) => {
        const { indexRow,checkReport } = this.state
        const mouseEnter = () => { this.setState({ checkReport: true }) }
        const mouseLeave = () => { this.setState({ checkReport: false }) } 
        return (
          <img
            alt='report'
            title='รายงาน'
            onMouseEnter={mouseEnter} 
            onMouseLeave={mouseLeave}
            onClick={e => { 
              e.stopPropagation()
              this.props.history.push(`/report/${record._id}/groups`,{cameFrom: 'suggestExam'})
            }}  src={checkReport && indexRow === index ? Report : ReportGray} style={{ cursor: 'pointer' }}/>
        )
      },
    },
  ]
  columnsSuggest = [
    {
      title: 'รหัสข้อสอบ',
      dataIndex: 'code',
      key: 'code',
      render: (code) => {  
        const onMouseEnter = () => { this.setState({ hoverCodeExam: true }) }
        const onMouseLeave = () => { this.setState({ hoverCodeExam: false }) }
        return <span onMouseOver={ onMouseEnter } onMouseLeave={ onMouseLeave } >{code}</span> 
      },
      width: '120px'
    },
    {
      title:() => {
        return (
          <span className={styles.column_Name_Suggest}>ชื่อ</span>
        )
      },
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        let logo = null
        if(record.type === 'G') {
          if(record.subject === 'คณิตศาสตร์') {
            logo = IconExamMath
          }else {
            logo = IconExamScience
          }
        } else if(record.type === 'C'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CompetitionMathIcon
          }else {
            logo = CompetitionScienceIcon
          }
        }else if(record.type === 'CUSTOM'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CustomIconMath
          }else {
            logo = CustomIconScience
          }
        }else {
          if(record.subject === 'คณิตศาสตร์') {
            logo = ExerciseIconMath
          }else {
            logo = ExerciseIconScience
          }
        }
        return (<span><img alt='subject' src={logo} /> {name}</span>)
      },
      width: '250px'
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        return (
          <span>{
            type === 'G' ? 'ตามหลักสูตร' : 
            type === 'C' ? 'แข่งขัน' : 
            type === 'CUSTOM' ? 'กำหนดเอง' : 
            type === 'CAT' ? 'CAT' : 
            (type === 'EX' || type === 'E') ? 'แบบฝึกหัด' : '-'
          }</span>
        )
      },
      width: '150px'
    },
    {
      render: (status,record) => { 
        let text = 'เริ่มทำ'
        if(record.status === 'finished') {
          text = 'ทำซ้ำ'
        } else if(record.status === 'doing') {
          text = 'ทำต่อ'
        }
        return (
          <div>
            <Button className={styles.buttonStartSuggest} >{text}</Button>
          </div>
        )
      }
    }
  ]
  componentDidMount = () => {
    this.fetchExamSetList()
    this.fetchExamSuggest()
  }

  fetchExamSetList = async (filter = null) => {
    this.setState({ isLoading: true })
    const res = await this.props.getExamSet(filter)
    this.setState({ isLoading: false })
    if (res.error) message.error(res.error)
  }

  fetchExamSuggest = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.getFetchExamSuggest()
    await this.setState({ examSuggest: res, isLoading: false })
  }

  fetchGroups = async (filter = null) => {
    this.setState({ isLoading: true })
    const res = await this.props.getGroupList(filter)
    this.setState({ isLoading: false })
    if (res.error) message.error(res.error)
  }

  onSearchExamSet = (value) => {
    let { examSuggest } = this.state
    let isEmptySearch = true
    let searchExam = []
    if(value){
      isEmptySearch = false
      searchExam = examSuggest.filter(exam => exam.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      || exam.code.toLowerCase().indexOf(value.toLowerCase()) > -1
      || exam.type.toLowerCase().indexOf(value.toLowerCase()) > -1 )
    }
    if(!isEmptySearch) {
      let examSuggestMath = []
      let examSuggestScience = []
      searchExam.forEach(e => {
        e.subject === 'คณิตศาสตร์' ? examSuggestMath.push(e) : examSuggestScience.push(e)
      })
      this.setState({
        examSuggest: searchExam,
        examSuggestMath,
        examSuggestScience,
        checkEnter : true
      })
    }else if(value !== [] && value !== ''){
      this.fetchExamSuggest()
    }
  }

  onRemoveSuggestExam = (exam) => {
    const modal = Modal.confirm({
      centered: true,
      okText: 'ย้ายออก',
      cancelText: 'ไม่ย้าย',
      width: 580,
      icon: null,
      title: (
        <button aria-label='Close' className='ant-modal-close' style={{display: 'block'}} onClick={()=> modal.destroy()}>
          <Icon type='close' className='ant-modal-close-x'/>
        </button>
      ),
      content: (
        <div style={{textAlign: 'center'}}>
          <IconExamWarning style={{fontSize: 256, marginTop: -30, marginBottom: -10}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>{exam.name}</h2>
          <p>คุณต้องการย้ายข้อสอบชุดนี้ออกจากข้อสอบแนะนำนี้หรือไม่</p>
        </div>
      ),
      onOk: async () => {
        const res = await this.props.removeExamSuggestion(exam._id)
        if (res.error) message.error('พบข้อผิดพลาดในการย้ายข้อสอบุดข้อสอบนี้')
        message.success('ย้ายข้อสอบออกสำเร็จ')
        await this.fetchExamSuggest()
      },
    })
  }

  onSearchGroup = (value) => {
    this.fetchGroups(value)
  }

  onChangeExamStatus = async(examId, status) => {
    await this.props.editExamSet(examId, {status})
    await this.fetchExamSuggest()
  }

  onDeleteExamSet = (examSet) => {
    const modal = Modal.confirm({
      centered: true,
      okText: 'ลบข้อสอบ',
      cancelText: 'ไม่ลบ',
      width: 580,
      icon: null,
      title: (
        <button aria-label='Close' className='ant-modal-close' style={{display: 'block'}} onClick={()=> modal.destroy()}>
          <Icon type='close' className='ant-modal-close-x'/>
        </button>
      ),
      content: (
        <div style={{textAlign: 'center'}}>
          <IconExamWarning style={{fontSize: 256, marginTop: -30, marginBottom: -10}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>ลบข้อสอบ</h2>
          <p style={{marginBottom: 0}}>{examSet.name}</p>
          <p className='text-thin text-sm' style={{color: '#3D3D3D', opacity: 0.5}}>{examSet.code}</p>
          <p>คุณต้องการลบชุดข้อสอบนี้หรือไม่</p>
        </div>
      ),
      onOk: async () => {
        const res = await this.props.deleteExamSet(examSet.id)
        if (res.error) message.error('พบข้อผิดพลาดในการลบชุดข้อสอบนี้')
        await this.fetchExamSetList()
      },
    })
  }

  onCopyLinkSuccess = () => {
    Modal.success({
      closable: true,
      centered: true,
      icon: null,
      maskClosable: true,
      okButtonProps: {hidden: true},
      width: 220,
      content: (
        <div style={{textAlign: 'center'}}>
          <IconCorrect style={{fontSize: 120}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>คัดลอกลิงก์สำเร็จ</h2>
        </div>
      )
    })
  }

  onOpenShareToGroup = async (examSet) => {
    await this.fetchGroups()
    this.setState({
      isShowShareToGroupModal: true,
      examSetToShare: examSet,
    })
  }

  onCloseModalShareToGroup = () => this.setState({isShowShareToGroupModal: false, examSetToShare: null})

  onSubmitShareToGroup = async (id, selectGrpupList) => {
    this.setState({ isLoading: true })
    const res = await this.props.shareExamToGroup(id, selectGrpupList)
    this.setState({ isLoading: false })
    if (res.error) return message.error(res.error)
    this.onCloseModalShareToGroup()
  }

  setSearchKey = (e) => {
    setTimeout(()=>{
      this.setState({
        searchKey: e
      })
      if(e === '' && this.state.checkEnter === true){
        this.fetchExamSetList()
        this.fetchExamSuggest()
        this.setState({
          checkEnter : false
        })
      }
    },50)
  }

  setIndexRow = (index) => { this.setState({ indexRow: index}) }

  showSearchModal = () => {
    this.setState({ isSearchModal: !this.state.isSearchModal})
    this.state.isSearchModal && this.onSearchExamSet()
  }

  render() {
    const { updateSequenceSuggestExam,auth } = this.props
    const { examSuggest } = this.state
    const searchKey = this.state.searchKey
    let examSuggestMath = []
    let examSuggestScience = []
    examSuggest.forEach(e => {
      e.subject === 'คณิตศาสตร์' ? examSuggestMath.push(e) : examSuggestScience.push(e)
    })
    return (
      <div className={styles.examSetDiv}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <ExamHeader
            search
            searchKey={searchKey}
            setSearchKey = {this.setSearchKey}
            onSearchExamSet={this.onSearchExamSet}
            title='ข้อสอบแนะนำ'
            showSearchModal={this.showSearchModal}
          />

          <div className={styles.div_collapse_style}>
            <Collapse defaultActiveKey={['1']} expandIconPosition={'right'} className={styles.collapse_style}>
              <Panel header={(<p style={{ fontSize: '18px',color: '#112AAE',padding: 0,margin: 0 }}>คณิตศาสตร์</p>)} key='1'>
                <SuggestMath role={auth.role}
                  updateSeq={updateSequenceSuggestExam}
                  setIndexRow={this.setIndexRow}
                  data={examSuggestMath}
                  columns={ auth.role === 'admin'? this.columnsSuggestAdmin : this.columnsSuggest}
                  history={this.props.history}
                  components={this.components} 
                  hoverCodeExam={this.state.hoverCodeExam}
                />
              </Panel>
            </Collapse>
          </div>

          <div className={styles.div_collapse_style}>
            <Collapse defaultActiveKey={['1']} expandIconPosition={'right'} className={styles.collapse_style}>
              <Panel header={(<p style={{ fontSize: '18px',color: '#112AAE',padding: 0,margin: 0 }}>วิทยาศาสตร์</p>)} key='1'>
                <SuggestScience role={auth.role}
                  updateSeq={updateSequenceSuggestExam}
                  setIndexRow={this.setIndexRow}
                  data={examSuggestScience}
                  columns={auth.role === 'admin'? this.columnsSuggestAdmin : this.columnsSuggest}
                  history={this.props.history}
                  components={this.components} 
                  hoverCodeExam={this.state.hoverCodeExam}
                />
              </Panel>
            </Collapse>
          </div>
        </Spin>
        <ModalShareToGroup
          isVisible={this.state.isShowShareToGroupModal}
          onAddExamSetToGroup={this.onSubmitShareToGroup}
          onCancel={this.onCloseModalShareToGroup}
          examSet={this.state.examSetToShare}
          groupList={this.props.groupList}
          onSearchGroupList={this.onSearchGroup}
        />

        <SearchModal
          isSearchModal={this.state.isSearchModal}
          showSearchModal={this.showSearchModal}
          title='ค้นหาข้อสอบ'
          placeholder='ชื่อ, รหัสข้อสอบ, จัดโดย'
          action={this.onSearchExamSet}
        >
          <div className={styles.suggestMobile}>
            <div className={styles.div_collapse_style}>
              <Collapse defaultActiveKey={['1']} expandIconPosition={'right'} className={styles.collapse_style}>
                <Panel header='คณิตศาสตร์' key='1'>
                  <SuggestMath role={auth.role}
                    updateSeq={updateSequenceSuggestExam}
                    setIndexRow={this.setIndexRow}
                    data={examSuggestMath}
                    columns={ auth.role === 'admin'? this.columnsSuggestAdmin : this.columnsSuggest}
                    history={this.props.history}
                    components={this.components}
                  />
                </Panel>
              </Collapse>
            </div>

            <div className={styles.div_collapse_style}>
              <Collapse defaultActiveKey={['1']} expandIconPosition={'right'} className={styles.collapse_style}>
                <Panel header='วิทยาศาสตร์' key='1'>
                  <SuggestScience role={auth.role}
                    updateSeq={updateSequenceSuggestExam}
                    setIndexRow={this.setIndexRow}
                    data={examSuggestScience}
                    columns={auth.role === 'admin'? this.columnsSuggestAdmin : this.columnsSuggest}
                    history={this.props.history}
                    components={this.components}
                  />
                </Panel>
              </Collapse>
            </div>
          </div>
        </SearchModal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.profile,
    examSetList: state.exam.examSetList,
    groupList: state.group.groupList,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getExamSet,
      editExamSet,
      deleteExamSet,
      shareExamToGroup,
      getGroupList,
      getFetchExamSuggest,
      updateSequenceSuggestExam,
      removeExamSuggestion
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Suggest)
