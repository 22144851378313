exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".AuditTrail_detail_nospace__2GtO8 {\n  margin-bottom: 0px;\n  list-style: none;\n  list-style-image: url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20'><circle cx='50%' cy='75%' r='3' fill='orange'/></svg>\"); }\n\n.AuditTrail_inputSearch__13yr_ input {\n  width: 393px;\n  margin-right: 16px;\n  border-radius: 20px;\n  border-color: #015EA5; }\n\n.AuditTrail_auditdetail__A3Dbl {\n  margin-top: 3px;\n  margin-bottom: 3px; }\n\n.AuditTrail_headercol__O7-Sk {\n  display: inline-block;\n  vertical-align: middle;\n  position: relative !important;\n  height: 100%;\n  width: 40px !important;\n  font-size: 17px !important;\n  font-weight: 500;\n  background-color: transparent !important; }\n", ""]);

// Exports
exports.locals = {
	"detail_nospace": "AuditTrail_detail_nospace__2GtO8",
	"inputSearch": "AuditTrail_inputSearch__13yr_",
	"auditdetail": "AuditTrail_auditdetail__A3Dbl",
	"headercol": "AuditTrail_headercol__O7-Sk"
};