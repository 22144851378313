import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  onCreateDepartment,
  onDeleteDepartment
} from '../../redux/department/action' 
import styles from './Departments.module.scss'
import {
  Modal,
  Row,
  Col,
  Button,
  message, 
} from 'antd' 
import AddForm from './AddForm'
import EditForm from './EditForm'
import BigWarningIcon from '../IconSVG/BigWarningIcon.svg'
class ModalDepartments extends Component {
  constructor(props){
    super(props) 
  }  
  onDeleteSchool = async(schoolId) => { 
    const { onCloseModal,onDeleteDepartment,fetch } = this.props
    await onDeleteDepartment(schoolId) 
    message.success('ลบสังกัดโรงเรียนสำเร็จ')
    await fetch()
    onCloseModal()
  } 
  render(){
    const { modalVisible,typeModal,onCloseModal,item, fetch } = this.props  
    const deleteModal = (
      <Modal 
        width='30%'
        closable={true}
        visible={modalVisible}
        onCancel={onCloseModal}
        footer={false}
        className={styles.header_delete_modal}
        centered={true}
      > 
        <Row >
          <Col span={24} align='center'>
            <div>
              <img src={BigWarningIcon}/>
            </div>
            <h1>ลบสังกัดของโรงเรียน/หน่วยงาน</h1>
            <h3>{item ? item.name : ''}</h3>
            <span>คุณต้องการลบสังกัดของโรงเรียน/หน่วยงานนี้หรือไม่</span>
            <Row style={{ paddingTop: '25px' }} >
              <Col span={12} >
                <Button onClick={e=>{
                  e.stopPropagation()
                  onCloseModal()
                }} style={{width: '70%', float: 'right', marginRight: '15px'}}>ไม่ลบ</Button>
              </Col>
              <Col span={12}>
                <Button onClick={e => {
                  e.stopPropagation()
                  this.onDeleteSchool(item._id)
                }} style={{width: '70%', float: 'left',marginLeft: '15px' }} type='primary' >ลบสังกัด</Button>
              </Col>
            </Row>
          </Col>
        </Row> 
      </Modal>
    ) 
    
    return(
      <div>
        {
          typeModal === 'delete' ?
            <div>{deleteModal}</div>
            : 
            <Modal 
              className={typeModal !== 'delete' ? styles.modal : ''} 
              closable={true}
              visible={modalVisible}
              onCancel={onCloseModal}
              footer={false}
              destroyOnClose={true}
              centered={true}
              bodyStyle={ typeModal !== 'delete' ? { padding: '60px' } : ''}
            > 
              {(()=> {
                switch(typeModal){
                  case 'add'       : return <AddForm onCloseModal={onCloseModal} fetch={fetch}/>
                  case 'edit'      : return <EditForm item={item} name={item.name} onCloseModal={onCloseModal} fetch={fetch}/> 
                  default          : return <div> Modal </div>
                }
              })()} 
            </Modal>
        } 
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {  
      onCreateDepartment,
      onDeleteDepartment
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(ModalDepartments)