/* eslint-disable no-undef */
import React, { Component , Suspense } from 'react'
// import Loadable from 'react-loadable'
import Tour from 'reactour'
import {
  Layout,
  Menu,
  Dropdown,
  Spin,
  Drawer,
  Button, 
  Modal,
  Row,
  Icon
} from 'antd'
import { connect } from 'react-redux'
import {
  Link,
  withRouter,
} from 'react-router-dom'
import NoticeAlert from '../Display/NoticeAlert'
import { toast } from 'react-toastify' 
import { bindActionCreators } from 'redux'
import {changeCreateStep} from '../../redux/exam/action'
import {
  getNoticeList 
} from '../../redux/notice/action'
import {
  UserSeenNotice,
  SetSeenTermAndConditionStatus,
  SetSeenTour
} from '../../redux/auth/action'
import {
  GetMasterData
} from '../../redux/master/action'
import styles from './LayoutCMS.module.scss'
import ModalTermMember from './TermAndDataPivacyMember/ModalTermMember'
import ModalDataPolicyMember from './TermAndDataPivacyMember/ModalDataPolicyMember'
import ModalTermDataMember from './TermAndDataPivacyMember/ModalTermDataMember'
import { 
  stepsSearchGroup, 
  stepStudentReport,
  stepTeacherCreateGroup,
  
  stepTourMyExanStudent,
  stepTourMyExanStudentOldUser,
  stepTourStudentCreateExam,
  stepTourMyExamTeacher,
  stepTourTeachertCreateExam,
  stepTeacherTourReport,
  stepTeacherTourGroup,
  stepTourMyExamTeacherOldUser,
  stepTeacherCreateGroupOldUser
} from '../OnBoarding/index'
import logoSSWT from '../../assets/LogoSSWT.svg'
import logoOnline from '../../assets/LogoOnlineTesting.svg'
// import IconUserMenu from '../../assets/Icon/UserMenu.svg'
import IconListUser from '../IconSVG/ListUserIcon.svg'
import Group from '../IconSVG/GroupIcon.svg'
import Exam from '../IconSVG/ExamIcon.svg'
import IconMemberData from '../IconSVG/MemberDataIcon.svg'
import IconSubjectsData from '../IconSVG/IconSubjectsData.svg'
import IconNotice from '../IconSVG/IconNotice.svg'
import IconVector from '../IconSVG/Vector.svg'
import IconHomeData from '../IconSVG/IconHomeData.svg'
import IconLogoutO from '../IconSVG/iconLogout.svg'
import IconEditProfileImg from '../IconSVG/EditProfile.svg'
import IconEditProfileMobile from '../IconSVG/IconEditProfileMobile.svg'
import IconLogoutImg from '../IconSVG/icon_logout.svg'
// import IconMasterData from '../IconSVG/IconMasterData.svg'
import IconVectorSelected from '../IconSVG/Vector_selected.svg'
import DefaultProfile from '../IconSVG/DefaultProfile.svg' 
import Stat from '../IconSVG/StatIcon.svg'
import Welcome from '../../assets/onboard/Welcome.svg'
import {
  UserLogout
} from '../../redux/auth/action' 
// import SubMenu from 'antd/lib/menu/SubMenu'
import ModalTermCondition from '../Register/ModalTermCondition'
import ModalUpdateNotice from './ModalNotice/ModalUpdateNotice'

const { SubMenu } = Menu
const { Header, Content, Sider } = Layout 
let isSeenWelcome = 0
// const MenuItemGroup = Menu.ItemGroup;
// const { SubMenu } = Menu

// const Tour = Loadable({
//   loader: () => import('reactour'),
//   loading: () => null,
// })

const logout = (props) => async () => { 
  // localStorage.removeItem('pathDirect') 
  // localStorage.clear()
  localStorage.setItem('numOfExam',-1)
  localStorage.setItem('pathDirect','logout') 
  await props.UserLogout()   
  props.history.push('/login')
  return (
    props.isLoading ? <Spin size='large' tip='Loading...' spinning={props.isLoading}></Spin> : ''
  )
}

const profileMenu = (props) => (
  <Menu>
    <Menu.Item key={0} id='editProfileBtn'>
      <Link to='/profile'>
        <img alt='icon-edit-profile-img' src={IconEditProfileImg} style={{width : '24px',height : '24px'}} />
        แก้ไขข้อมูลส่วนตัว
      </Link>
    </Menu.Item>
    { props.profile.role === 'student' &&
    <Menu.Item key={1} id='mlProfileBtn'>
      <Link to='/performance'>
        <Icon type='radar-chart' style={{ color: '#05007A', padding: '3px' }} />
        ผลการประเมินโดยภาพรวม
      </Link>
    </Menu.Item>
    }
    <Menu.Item key={2} id='logoutBtn' onClick={logout(props)}>
      <img alt='icon-logout-img' src={IconLogoutImg} className={styles.iconProfileMenu} />
      ออกจากระบบ
    </Menu.Item>
  </Menu>
)


class LayoutCMS extends Component {
  rootSubmenuKeys = ['sub1', 'sub2', 'sub3','sub4', 'sub5'];
  state = {
    menu: false,
    noticeList : '',
    isLoading : false,
    heightDivForToast : 55,
    // isCloseToaseTerm: true,
    // isCloseToaseDataPivacy: true,
    classNameFirst : 'notice_alert notice_alert_first',
    classNameSecond : 'notice_alert notice_alert_second',
    classNameThird : 'notice_alert notice_alert_third',

    isRegisterShowTerm: true,
    registerprivacyPolicy: null,

    isShowTerm: false,
    isShowDataPolicy: false,
    ClosePivace : false,
    isStudentCreateExamTourOpen : false,
    isTeacherTour : false,
    isTeacherTourOpen: false,
    isTourReport : false,
    isTourCode : false,
    exam : false, 
    examCode : true, 
    group : true, 
    report : true, 
    testing: true,
    numOfStep : 0,
    isTour : true,
    modalTour : false,
    isTourGroup : false,
    myExamToReport : false,
    openKeys: ['sub1'],
    showTermNotice: false,
    showPrivacyNotice: false,
    modalList: [],
    noticeMessage: ['', ''],
  }
  componentDidMount = async() =>{
    if(this.props.location.pathname === '/examset'){
      localStorage.setItem('isCloseToaseOne',true)  
      localStorage.setItem('isCloseToaseTwo',true)  
      localStorage.setItem('isCloseToaseThree',true)  
      localStorage.setItem('isCloseToaseTerm',true)
      localStorage.setItem('isCloseToaseDataPivacy',true)
    }
    const checkTour = this.props.profile.isSeenTutorial

    // if(checkTour.exam && checkTour.group && window.screen.width >= 991){
    //   await this.showNotification()
    // }
    // if(window.screen.width < 991){
    //   await this.showNotification()
    // }
    await this.showNotification()
    await this.props.GetMasterData() 
  }
  
  closeToase = async (id) => {
    const data = {}
    data['noticeId'] = id
    await this.props.UserSeenNotice(data)
  }

  agreeTerm = async () =>{
    const data = {}
    data['isSeenTermAndCondition'] = true
    await this.props.SetSeenTermAndConditionStatus(data)
    localStorage.setItem('isCloseToaseTerm',false)
    this.setState({
      isCloseToaseTerm : false,
      isShowTerm : false
    })
  }
  onTermCancel = () => {
    localStorage.setItem('isCloseToaseTerm',false)
    localStorage.setItem('isCloseToaseDataPivacy',false)
    this.setState({
      isShowTerm : false,
      isShowDataPolicy : false 
    })
  }
  agreeBoth = async () =>{
    const id = 1
    const data = {}
    data['isSeenTermAndCondition'] = true
    data['isSeenDataPrivacy'] =  id === 2 ? true : true
    data['privacyPolicy'] = id === 1 ? true : false
    await this.props.SetSeenTermAndConditionStatus(data)
    localStorage.setItem('isCloseToaseTerm',false)
    this.setState({
      isCloseToaseTerm : false,
      isShowTerm : false,
      isShowDataPolicy: false
    }, () => {
      this.setState({
        ClosePivace: true
      })
    })
  }
  agreePrivacy = async (id)=>{
    const data = {}
    data['isSeenDataPrivacy'] =  id === 2 ? true : true
    data['privacyPolicy'] = id === 1 ? true : false
    await this.props.SetSeenTermAndConditionStatus(data)    
    localStorage.setItem('isCloseToaseTerm',false)
    this.setState({
      isShowDataPolicy : false 
    },()=>{
      this.setState({
        ClosePivace : true
      })
    })
  }
  onTermOK =() =>{
    this.setState({
      isShowTerm: false,
      isShowDataPolicy: false
    })
  }

  showNotification = () =>{
    if (this.props.profile.isImportJoin === true && this.props.profile.isSeenTermAndCondition === false ) {
      // console.log('skip notification on first time')
      return
    }
    const  notice  = this.props.profile.notices
    const  term  = this.props.profile.isSeenTermAndCondition
    const  pivacy  = this.props.profile.isSeenDataPrivacy
    const isCloseToaseOne = localStorage.getItem('isCloseToaseOne')
    const isCloseToaseTwo = localStorage.getItem('isCloseToaseTwo')
    const isCloseToaseThree = localStorage.getItem('isCloseToaseThree')
    
    const isCloseToaseDataPivacy  = localStorage.getItem('isCloseToaseDataPivacy')
    const isCloseToaseTerm  = localStorage.getItem('isCloseToaseTerm')

    let privacyNotice = false, termNotice = false
    const { hasSeenPrivacyPolicy } = this.props.profile

    // console.log(this.props.profile)
    // console.log(isCloseToaseDataPivacy)
    // console.log(isCloseToaseTerm)

    if(pivacy === false && this.props.profile.role !== 'admin' && isCloseToaseDataPivacy === 'true'){
      // console.log("Updated Privacy ***")
      privacyNotice = true
    }
    if(term === false && this.props.profile.role !== 'admin' && isCloseToaseTerm === 'true'){
      // console.log("Updated TERM ***")
      termNotice = true
    }
    if (hasSeenPrivacyPolicy && termNotice && privacyNotice) {
      this.setState({
        showTermNotice: true,
        noticeMessage: ['เงื่อนไข ข้อกำหนด และนโยบายคุ้มครองข้อมูลส่วนบุคคล', 'เงื่อนไข ข้อกำหนด และนโยบาย'],
        modalList: ['both'],
      })
    }
    else if (hasSeenPrivacyPolicy && termNotice) {
      this.setState({
        showTermNotice: true,
        noticeMessage: ['เงื่อนไขและข้อกำหนด', 'ข้อกำหนด'],
        modalList: ['term'],
      })
    }
    else if (hasSeenPrivacyPolicy && privacyNotice) {
      this.setState({
        showTermNotice: true,
        noticeMessage: ['นโยบายคุ้มครองข้อมูลส่วนบุคคล', 'นโยบาย'],
        modalList: ['privacy'],
      })
    }
    else {
      this.setState({
        showTermNotice: false,
      })
    }
    if(notice.length > 0){
      if(notice[0] !== undefined && 
        (notice[0].id === 1 ? isCloseToaseOne : notice[0].id === 2 ? isCloseToaseTwo : notice[0].id ===  3 ? isCloseToaseThree : false) === 'true'){
        toast( <div style={{paddingTop: '7px', fontFamily: 'Kanit'}} dangerouslySetInnerHTML={{ __html: notice[0].text }}/>,
          this.options = {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: false, 
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false, 
            closeButton: true,
            onClose:() => {               
              this.closeToase(notice[0].id)
              if(notice[0].id === 1){
                localStorage.setItem('isCloseToaseOne',false)
              }else if(notice[0].id === 2){
                localStorage.setItem('isCloseToaseTwo',false)
              }else if(notice[0].id === 3){
                localStorage.setItem('isCloseToaseThree',false)
              }
            },    
            containerId:'first',
            className: notice[0].id === 1 ? this.state.classNameFirst : notice[0].id === 2 ? this.state.classNameSecond : this.state.classNameThird,
            toastClassName : 'notice_alert_toast',
            bodyClassName: 'notice_alert_body ',
            enableMultiContainer : true
          } )
      }
      if(notice[1] !== undefined && 
        (notice[1].id === 1 ? isCloseToaseOne : notice[1].id === 2 ? isCloseToaseTwo : notice[1].id ===  3 ? isCloseToaseThree : false) === 'true'){
        toast( <div style={{paddingTop: '7px', fontFamily: 'Kanit'}} dangerouslySetInnerHTML={{ __html: notice[1].text}}/>,
          this.options = {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: false, 
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false, 
            closeButton: true,
            onClose:() => {               
              this.closeToase(notice[1].id)
              if(notice[1].id === 1){
                localStorage.setItem('isCloseToaseOne',false)
              }else if(notice[1].id === 2){
                localStorage.setItem('isCloseToaseTwo',false)
              }else if(notice[1].id === 3){
                localStorage.setItem('isCloseToaseThree',false)
              }
            },  
            containerId:'second',
            className: notice[1].id === 1 ? this.state.classNameFirst : notice[1].id === 2 ? this.state.classNameSecond : this.state.classNameThird,
            toastClassName : 'notice_alert_toast',
            bodyClassName: 'notice_alert_body ',
            enableMultiContainer : true
          } )
      }
      if(notice[2] !== undefined && 
        (notice[2].id === 1 ? isCloseToaseOne : notice[2].id === 2 ? isCloseToaseTwo : notice[2].id ===  3 ? isCloseToaseThree : false) === 'true'){
        toast( <div style={{paddingTop: '7px', fontFamily: 'Kanit'}} dangerouslySetInnerHTML={{ __html: notice[2].text}}/>,
          this.options = {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: false, 
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false, 
            closeButton: true,
            onClose:() => {               
              this.closeToase(notice[2].id)
              if(notice[2].id === 1){
                localStorage.setItem('isCloseToaseOne',false)
              }else if(notice[2].id === 2){
                localStorage.setItem('isCloseToaseTwo',false)
              }else if(notice[2].id === 3){
                localStorage.setItem('isCloseToaseThree',false)
              }
            },  
            containerId:'third',
            className: notice[2].id === 1 ? this.state.classNameFirst : notice[2].id === 2 ? this.state.classNameSecond : this.state.classNameThird,
            toastClassName : 'notice_alert_toast',
            bodyClassName: 'notice_alert_body ',
            enableMultiContainer : true
          } )
      }
    }
  }
  nextStepTour = () => {
    this.setState({
      numOfStep : this.state.numOfStep + 1
    })
    return ''
  }
  triggerNextStepTour = () => {

  }
  previousTour = () => {
    this.setState({
      numOfStep : this.state.numOfStep -1
    })
    return ''
  }

  closeTour = (stepName)=>{
    const data = {}
    if(stepName === 'exam'){
      data.isSeenTutorial = {'exam' : true}
      this.setState({
        exam : false,
        isStudentCreateExamTourOpen : true,
        myExamToReport : true
      },()=>{
        this.props.SetSeenTour(data)
      })
    }
    if(stepName === 'examCode'){
      data.isSeenTutorial = { 'examCode' : true }
      this.setState({
        examCode : false
      },()=>{
        this.props.SetSeenTour(data)
      })
    }
    if(stepName ==='report'){
      data.isSeenTutorial =  {'report' : true}
      this.setState({
        report : false,
        myExamToReport : false
      },()=>{
        this.props.SetSeenTour(data)
      })
    }
    if(stepName ==='testing'){
      data.isSeenTutorial = {'testing': true}
      this.setState({
        testing : false
      },async()=>{
        await this.props.SetSeenTour(data)
      })
    }
    if(stepName ==='group'){
      data.isSeenTutorial = {'group' : true}
      this.setState({
        group :false
      },()=>{
        this.props.SetSeenTour(data)
      })
    }
  }
  closeModal = () => {
    this.setState({
      modalTour : false
    })
  }
  modalOnBoarding = () => {
    const  term  = this.props.profile.isSeenTermAndCondition
    const  pivacy  = this.props.profile.isSeenDataPrivacy
    let termNotice = false
    let privacyNotice = false
    if(pivacy === false && this.props.profile.role !== 'admin'){
      privacyNotice = true
    }
    if(term === false && this.props.profile.role !== 'admin'){
      termNotice = true
    }
    if((!termNotice || !privacyNotice) && isSeenWelcome === 0 && this.props.profile.isSeenTutorial.exam === false){
      setTimeout(()=>{
        this.setState({
          modalTour : true
        })
      },2000)
      isSeenWelcome++
    }
    return(
      <div>
        <Modal
          visible={this.state.modalTour}
          centered
          closable = {false}
          footer={[
            <Button style = {{width : 180}} key='submit' type='primary' 
              onClick={() => { 
                this.setState({
                  exam : true,
                  modalTour : false
                })
              }}
            >ตกลง</Button>
          ]}
          bodyStyle={{ textAlign:'center'}}
        >
          <div>
            <Row className = {styles.rowInMordal}>
              <img alt = 'welcome_pic' src = {Welcome} style = {{marginBottom : 24}}></img>
            </Row>
            <Row className = {styles.rowInMordal}>
              <label className = {styles.titleLabel}>ยินดีต้อนรับ</label>
            </Row>
            <Row className = {styles.rowInMordal}>
              <label className = {styles.nameLabel}>{`${ this.props.profile.role === 'student' ? this.props.profile.prefixName : 'คุณครู'}${this.props.profile.firstName} ${this.props.profile.lastName}`}</label>
            </Row>
            <Row className = {styles.rowInMordal}>
              <label className = {styles.descrictionLabel}>เข้าสู่ onlinetesting.ipst.ac.th ระบบการสอบออนไลน์</label>
            </Row>
          </div>
        </Modal>
      </div>
    )
  }

  onRegisterTermOK = async () => {
    await this.setState({ isLoading: true })

    const data = {}
    data['isSeenTermAndCondition'] = true
    data['isSeenDataPrivacy'] =  true 
    data['privacyPolicy'] = this.state.registerprivacyPolicy === true ? true : false
    await this.props.SetSeenTermAndConditionStatus(data)    

    await this.setState({
      isRegisterShowTerm: false,
      isLoading: false
    }) 
  }

  onRegisterTermCancel = () => {
    // this.setState({
    //   isRegisterShowTerm: false,
    // })
  }

  RegistersetPrivacyPolicy = async (privacyPolicy) => {  
    await this.setState({ registerprivacyPolicy: privacyPolicy.target.checked })
    // console.log('--------RegistersetPrivacyPolicy')
    // console.log(privacyPolicy.target.checked)
    // console.log(this.state.registerprivacyPolicy)
  }

  showOnBoarding = () => {
    const profile = this.props.profile.role
    let isSeenTutorial = this.props.profile.isSeenTutorial

    const currentMenutab  = this.props.location.pathname.match(/^\/[^/]*/)[0]
    const currentPath  = this.props.location.pathname
    const exam = isSeenTutorial.exam
    const report = isSeenTutorial.report
    const examCode = isSeenTutorial.examCode
    const group = isSeenTutorial.group    
    const groupTour = isSeenTutorial.testing


if (this.props.profile.isImportJoin === true && this.props.profile.isSeenTermAndCondition === false ) {
    isSeenTutorial.exam = true
    isSeenTutorial.report = true
    isSeenTutorial.examCode = true
    isSeenTutorial.group = true
    isSeenTutorial.testing = true

// console.log(this.props.profile)
    return 

//       return (<Suspense fallback={<React.Fragment />}>
// <ModalTermCondition
//                   key='ModalTermCondition'
//                   isVisible={this.state.isRegisterShowTerm}
//                   onOK={this.onRegisterTermOK}
//                   onCancel={this.onRegisterTermCancel}
//                   role={this.props.profile ? this.props.profile.role : null}
//                   termAndCondition={this.props.termAndCondition}
//                   dataPrivacy={this.props.dataPrivacy}
//                   setPrivacyPolicy = { this.RegistersetPrivacyPolicy }
//                   fromaddgroup = {true}
//                 />
// </Suspense>

//       )

    }

    if(profile === 'student' && localStorage.getItem('numOfExam') < 1){
      if(exam === false && isSeenWelcome !== 0  && currentPath === '/examset'){
        localStorage.setItem('isTourReport',999)
        return(
          <Suspense fallback={<React.Fragment />}>
            <Tour
              steps = {stepTourMyExanStudent}
              isOpen = {this.state.exam}
              onRequestClose={()=>{this.closeTour('exam')}} 
              goToStep = {this.state.numOfStep}
              getCurrentStep = {(val)=>{this.setState({
                numOfStep : val
              })}}
              showNavigation={false}
              showButtons = {false}
              showNumber = {false}
              closeWithMask = {false}
              rounded = {10}
              maskSpace = {10}
              disableInteraction = {true}
              className = {styles.tourTest}
            />
          </Suspense>
        )
      }else
      if(examCode === false && currentPath === '/examset/create/curriculum'){
        return(
          <Tour
            steps = {stepTourStudentCreateExam}
            isOpen = {this.state.examCode}
            onRequestClose={()=>{this.closeTour('examCode')}} 
            goToStep = {this.state.numOfStep}
            showNavigation={false}
            showButtons = {false}
            showNumber = {false}
            closeWithMask = {false}
            rounded = {10}
            maskSpace = {10}
            disableInteraction = {true}
            className = {styles.tourTest}
          />
        )
      }else
      if(report === false && currentPath === '/examset' && exam === true && localStorage.getItem('numOfExam') > 0){
        if(this.state.isTourReport === false){
          setTimeout(()=>{
            this.setState({
              report : true,
              isTourReport : true,
              numOfStep : 0
            })
          },3000)
        }
        return(
          <Suspense fallback={<React.Fragment />}>
            <Tour
              steps = {stepStudentReport}
              isOpen = {this.state.report}
              onRequestClose={()=>{this.closeTour('report')}} 
              showNavigation={false}
              showButtons = {false}
              showNumber = {false}
              closeWithMask = {false}
              rounded = {10}
              maskSpace = {10}
              disableInteraction = {true}
              className = {styles.tourTest}
            />
          </Suspense>
        )   
      }else
      if(group === false && currentMenutab === '/group'){
        return(
          <Suspense fallback={<React.Fragment />}>
            <Tour
              steps = {stepsSearchGroup}
              isOpen = {this.state.group}
              onRequestClose={()=>{this.closeTour('group')}} 
              disableInteraction = {false}
              showNavigation={false}
              showButtons = {false}
              showNumber = {false}
              closeWithMask = {false}
              active = {true}
              disableKeyboardNavigation={true}
              goToStep = {this.state.numOfStep}
              rounded = {10}
              maskSpace = {10} 
              className = {styles.tourTest}
            />
          </Suspense>
        )
      }
    } 
    if(profile === 'student' && parseInt(localStorage.getItem('numOfExam')) > 0){
      if(exam === false && isSeenWelcome !== 0  && currentPath === '/examset'){
        localStorage.setItem('isTourReport',1)
        return(
          <Suspense fallback={<React.Fragment />}>
            <Tour
              steps = {stepTourMyExanStudentOldUser}
              isOpen = {this.state.exam}
              onRequestClose={()=>{this.closeTour('exam')}} 
              goToStep = {this.state.numOfStep}
              getCurrentStep = {(val)=>{this.setState({
                numOfStep : val
              })}}
              showNavigation={false}
              showButtons = {false}
              showNumber = {false}
              closeWithMask = {false}
              rounded = {10}
              maskSpace = {10}
              disableInteraction = {true}
              updateDelay = {10} 
              className = {styles.tourTest}
            />
          </Suspense>
        )
      }else
      if(examCode === false && currentPath === '/examset/create/curriculum'){
        return(
          <Tour
            steps = {stepTourStudentCreateExam}
            isOpen = {this.state.examCode}
            onRequestClose={()=>{this.closeTour('examCode')}} 
            goToStep = {this.state.numOfStep}
            showNavigation={false}
            showButtons = {false}
            showNumber = {false}
            closeWithMask = {false}
            rounded = {10}
            maskSpace = {10}
            disableInteraction = {true}
            className = {styles.tourTest}
          />
        )
      }else
      if(report === false && currentPath === '/examset' && exam === true && localStorage.getItem('numOfExam') > 0 && localStorage.getItem('isTourReport') === '999'){
        if(this.state.isTourReport === false){
          setTimeout(()=>{
            this.setState({
              report : true,
              isTourReport : true,
              numOfStep : 0
            })
          },3000)
        }
        return(
          <Suspense fallback={<React.Fragment />}>
            <Tour
              steps = {stepStudentReport}
              isOpen = {this.state.report}
              onRequestClose={()=>{this.closeTour('report')}} 
              showNavigation={false}
              showButtons = {false}
              showNumber = {false}
              closeWithMask = {false}
              rounded = {10}
              maskSpace = {10}
              disableInteraction = {true}
              className = {styles.tourTest}
            />
          </Suspense>
        )   
      }else
      if(group === false && currentMenutab === '/group'){
        return(
          <Tour
            steps = {stepsSearchGroup}
            isOpen = {this.state.group}
            onRequestClose={()=>{this.closeTour('group')}} 
            disableInteraction = {false}
            showNavigation={false}
            showButtons = {false}
            showNumber = {false}
            closeWithMask = {false}
            active = {true}
            disableKeyboardNavigation={true}
            goToStep = {this.state.numOfStep}
            rounded = {10}
            maskSpace = {10} 
            className = {styles.tourTest}
          />
        )
      }
    } 

    if((profile === 'teacher' || profile === 'superTeacher') && localStorage.getItem('numOfExam') < 1){
      if(exam === false && currentPath === '/examset'){
        if(this.state.exam === true){
          return(
            <Suspense fallback={<React.Fragment />}>
              <Tour
                steps = {stepTourMyExamTeacher}
                isOpen = {this.state.exam}
                onRequestClose={()=>{this.closeTour('exam')}} 
                goToStep = {this.state.numOfStep}
                getCurrentStep = {(val)=>{this.setState({
                  numOfStep : val
                }
                )
                }}
                triggerNextStepTour = {this.triggerNextStepTour}
                changeCreateStep = {this.props.changeCreateStep}
                showNavigation={false}
                showButtons = {false}
                showNumber = {false}
                closeWithMask = {false}
                disableInteraction = {true}
                active = {true}
                disableKeyboardNavigation={true}
                rounded = {10}
                maskSpace = {10} 
                className = {styles.tourTest}             
              />
            </Suspense>
          )
        }if(this.state.exam === false){
          setTimeout(()=>{
            if(report === false && currentPath === '/examset' && exam === false){
              if(this.state.isTourReport === false){
                setTimeout(()=>{
                  this.setState({
                    report : true,
                    isTourReport : true,
                    numOfStep : 0
                  })
                },1000)
              }
              return(
                <Suspense fallback={<React.Fragment />}>
                  <Tour
                    steps = {stepTeacherTourReport}
                    isOpen = {this.state.report}
                    onRequestClose={()=>{this.closeTour('report')}} 
                    showNavigation={false}
                    showButtons = {false}
                    showNumber = {false}
                    closeWithMask = {false}
                    rounded = {10}
                    maskSpace = {10}
                    disableInteraction = {true}
                    className = {styles.tourTest}
                  />
                </Suspense>
              )   
            } 
          },3100)
        }    
      } 
      if(examCode === false && currentPath === '/examset/create/curriculum'){
        return(
          <Tour
            steps = {stepTourTeachertCreateExam}
            isOpen = {this.state.examCode}
            onRequestClose={()=>{this.closeTour('examCode')}} 
            goToStep = {this.state.numOfStep}
            showNavigation={false}
            showButtons = {false}
            showNumber = {false}
            closeWithMask = {false}
            rounded = {10}
            maskSpace = {10}
            disableInteraction = {true}
            className = {styles.tourTest}
          />
        )
      } 
      if(group === false && currentMenutab === '/group'){
        return(
          <Tour
            steps = {stepTeacherCreateGroup}
            isOpen = {this.state.group}
            onRequestClose={()=>{this.closeTour('group')}} 
            goToStep = {this.state.numOfStep} 
            showNavigation={false}
            showButtons = {false}
            showNumber = {false}
            value = {null}
            closeWithMask = {false}
            disableInteraction = {false}
            active = {true}
            disableKeyboardNavigation={true}
            rounded = {10}
            className = {styles.tourTest}
          />)
      } 
      if(groupTour === false && currentMenutab === '/group' && this.props.groupList.length > 0){
        if(this.state.isTourGroup === false){
          setTimeout(()=>{
            this.setState({
              testing : true,
              isTourGroup : true,
              numOfStep : 0
            })
          },3000)
        }
        return(
          <Tour
            steps = {stepTeacherTourGroup}
            isOpen = {this.state.testing}
            onRequestClose={()=>{this.closeTour('testing')}} 
            goToStep = {this.state.numOfStep} 
            showNavigation={false}
            showButtons = {false}
            showNumber = {false}
            value = {null}
            closeWithMask = {false}
            disableInteraction = {false}
            active = {true}
            disableKeyboardNavigation={false}
            rounded = {10}
            maskSpace = {10} 
            className = {styles.tourTest}
          />)
      }
    }
    if((profile === 'teacher' || profile === 'superTeacher') && localStorage.getItem('numOfExam') > 0){
      if(exam === false && currentPath === '/examset'){
        return(
          <Suspense fallback={<React.Fragment />}>
            <Tour
              steps = {stepTourMyExamTeacherOldUser}
              isOpen = {this.state.exam}
              onRequestClose={()=>{this.closeTour('exam')}} 
              goToStep = {this.state.numOfStep}
              getCurrentStep = {(val)=>{this.setState({
                numOfStep : val
              }
              )
              }}
              triggerNextStepTour = {this.triggerNextStepTour}
              changeCreateStep = {this.props.changeCreateStep}
              showNavigation={false}
              showButtons = {false}
              showNumber = {false}
              closeWithMask = {false}
              disableInteraction = {true}
              active = {true}
              disableKeyboardNavigation={true}
              rounded = {10}
              maskSpace = {10}         
              className = {styles.tourTest}     
            />
          </Suspense>)
      } 
      if(examCode === false && currentPath === '/examset/create/curriculum'){
        return(
          <Tour
            steps = {stepTourTeachertCreateExam}
            isOpen = {this.state.examCode}
            onRequestClose={()=>{this.closeTour('examCode')}} 
            goToStep = {this.state.numOfStep}
            showNavigation={false}
            showButtons = {false}
            showNumber = {false}
            closeWithMask = {false}
            rounded = {10}
            maskSpace = {10}
            disableInteraction = {true}
            className = {styles.tourTest}
          />
        )
      } 
      if(report === false && currentPath === '/examset' && exam === true && localStorage.getItem('numOfExam') > 0){ 
        if(this.state.isTourReport === false){
          setTimeout(()=>{
            this.setState({
              report : true,
              isTourReport : true,
              numOfStep : 0
            })
          },3000)
        }
        return(
          <Suspense fallback={<React.Fragment />}>
            <Tour
              steps = {stepTeacherTourReport}
              isOpen = {this.state.report}
              onRequestClose={()=>{this.closeTour('report')}} 
              showNavigation={false}
              showButtons = {false}
              showNumber = {false}
              closeWithMask = {false}
              rounded = {10}
              maskSpace = {10}
              disableInteraction = {true}
              className = {styles.tourTest}
            />
          </Suspense>
        )   
      } 
      if(group === false && currentMenutab === '/group' && this.props.groupList.length === 0){
        return(
          <Tour
            steps = {stepTeacherCreateGroup}stepTeacherCreateGroupOldUser
            isOpen = {this.state.group}
            onRequestClose={()=>{this.closeTour('group')}} 
            goToStep = {this.state.numOfStep} 
            showNavigation={false}
            showButtons = {false}
            showNumber = {false}
            value = {null}
            closeWithMask = {false}
            disableInteraction = {false}
            active = {true}
            disableKeyboardNavigation={true}
            rounded = {10}
            className = {styles.tourTest}
          />)
      } 
      if(group === false && currentMenutab === '/group' && this.props.groupList.length > 0){
        return(
          <Tour
            steps = {stepTeacherCreateGroupOldUser}
            isOpen = {this.state.group}
            onRequestClose={()=>{this.closeTour('group')}} 
            goToStep = {this.state.numOfStep} 
            showNavigation={false}
            showButtons = {false}
            showNumber = {false}
            value = {null}
            closeWithMask = {false}
            disableInteraction = {false}
            active = {true}
            disableKeyboardNavigation={true}
            rounded = {10}
            className = {styles.tourTest}
          />)
      } 
      if(groupTour === false && currentMenutab === '/group' && this.props.groupList.length > 0 && group === true){
        if(this.state.isTourGroup === false){
          setTimeout(()=>{
            this.setState({
              testing : true,
              isTourGroup : true,
              numOfStep : 0
            })
          },3000)
        }
        return(
          <Tour
            steps = {stepTeacherTourGroup}
            isOpen = {this.state.testing}
            onRequestClose={()=>{this.closeTour('testing')}} 
            goToStep = {this.state.numOfStep} 
            showNavigation={false}
            showButtons = {false}
            showNumber = {false}
            value = {null}
            closeWithMask = {false}
            disableInteraction = {false}
            active = {true}
            disableKeyboardNavigation={false}
            rounded = {10}
            maskSpace = {10} 
            className = {styles.tourTest}
          />)
      }
    }
  }

  onToggleMenu = () => {
    this.setState({ menu: !this.state.menu })
  }

  onClose = async () => {
    this.setState({ menu: false }) 
  }
  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1)
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys })
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      })
    }
  }
  onTermNoticeOK = () => {
    const { modalList } = this.state
    if (modalList.length === 0) {
      this.setState({
        showTermNotice: false,
        isShowTerm: false,
        isShowDataPolicy: false,
      })
    }
    else {
      const toShow = modalList.shift()
      // console.log(toShow)
      if (toShow == 'both') {
        this.setState({
          showTermNotice: false,
          isShowTerm: true,
          isShowDataPolicy: true,
          modalList: modalList,
        })
      }
      else if (toShow == 'term') {
        this.setState({
          showTermNotice: false,
          isShowTerm: true,
          isShowDataPolicy: false,
          modalList: modalList,
        })
      }
      else if (toShow == 'privacy') {
        this.setState({
          showTermNotice: false,
          isShowTerm: false,
          isShowDataPolicy: true,
          modalList: modalList,
        })
      }
    }
  }

  render () {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { nextStepTour: this.nextStepTour, previousTour : this.previousTour, numOfStep:this.state.numOfStep, closeTour: this.closeTour })
    )
    const currentMenutab  = this.props.location.pathname.match(/^\/[^/]*/)[0]
    const menuItems = (
      <Menu
        className={styles.layoutMenu}
        selectedKeys={ [this.props.location.pathname.match(/^\/[^/]*/)[0]] }
        defaultSelectedKeys={['/examset']}
        defaultOpenKeys={['examset']}
        mode='inline'
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
      >
        <SubMenu
          key='sub1'
          subMenuOpenDelay = {1}
          subMenuCloseDelay = {1}
          title={
            <p id = {styles.exam_title}
              className={styles.linkMenuItem} to='/examset'>
              <img src={Exam} alt='IconVector' className = {styles.icon_vector}/>
              <span style={{ color: 'white'}} className={styles.title_suggest}>ข้อสอบ/แบบฝึกหัด</span>
            </p>
          }
        >
          <Menu.Item key='/suggest' onClick={this.onClose} data-tut = '.first-step'>
            <Link
              className={styles.linkMenuItem}
              to='/suggest' id = {styles.icon_vector}>
              <img src={currentMenutab === '/suggest' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
              <span className = {styles.title_suggest} >แนะนำ</span>
            </Link>
          </Menu.Item>
          <Menu.Item  key='/examset' onClick={this.onClose} data-tut = 'firsttime'>
            <Link
              abc = 'hello world'
              className={styles.linkMenuItem}
              to='/examset' id = {styles.icon_vector}>
              <img src={currentMenutab === '/examset' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
              <span className = {styles.title_suggest} >ของฉัน</span>
            </Link>
          </Menu.Item>
          {
            this.props.profile.role === 'admin' ?
              <Menu.Item  key='/examsAll'  onClick={this.onClose}>
                <Link
                  className={styles.linkMenuItem}
                  to='/examsAll' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/examsAll' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_suggest}>ทั้งหมด</span>
                </Link>
              </Menu.Item>
              : ''
          }
        </SubMenu>

        <Menu.Item key='/group' onClick={this.onClose}>
          <Link className={styles.linkMenuItem} to='/group'>
            <div data-tut='stepTourMyExanStudentThird' data-tut-teacher='stepTourMyExamTeacherSecond'>
              <img src={Group} alt='IconVector' className = {styles.icon_vector}/>
              <span>กลุ่ม</span>
            </div>
          </Link>
        </Menu.Item>

        {/* Report */}
        {/* {
          this.props.profile.role === 'admin' ? (
            <Menu.Item key='/report' onClick={this.onClose}>
              <Link className={styles.linkMenuItem} to='/report'> 
                <img src={Report} alt='IconVector' className = {styles.icon_vector}/>
                <span className = {styles.title_suggest} >รายงาน</span>
              </Link>
            </Menu.Item>
          ) : ''
        } */}
        {
          this.props.profile.role === 'admin' ?
            <SubMenu
              key='sub2'
              title={
                <p id = {styles.exam_title} className={styles.linkMenuItem} to='/examset'>
                  <img src={Stat} alt='IconVector' className = {styles.icon_vector}/>
                  <span  style={{ color: 'white'}} className = {styles.title_suggest}>สถิติการใช้งาน</span>
                </p>
              }
            >
              <Menu.Item key='/reportaudit'  onClick={this.onClose}>
                <Link className={styles.linkMenuItem} to='/reportaudit' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/reportaudit' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span>ประวัติการแก้ไข</span>
                </Link>
              </Menu.Item>
              <Menu.Item key='/reportlogin'  onClick={this.onClose}>
                <Link className={styles.linkMenuItem} to='/reportlogin' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/reportlogin' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span>เข้าใช้งาน</span>
                </Link>
              </Menu.Item>
              <Menu.Item key='/reportaccount'   onClick={this.onClose}>
                <Link className={styles.linkMenuItem} to='/reportaccount' id = {styles.icon_vector}>
                  <img src={IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span>บัญชีผู้ใช้</span>
                </Link>
              </Menu.Item>
              <Menu.Item key='/reportexamset'   onClick={this.onClose}>
                <Link className={styles.linkMenuItem} to='/reportexamset' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/reportexamset' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span>ชุดข้อสอบ</span>
                </Link>
              </Menu.Item>
              <Menu.Item key='/reporttesting'   onClick={this.onClose} >
                <Link className={styles.linkMenuItem} to='/reporttesting' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/reporttesting' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span>ทำข้อสอบ</span>
                </Link>
              </Menu.Item>
              <Menu.Item key='/reportfinished'   onClick={this.onClose} >
                <Link className={styles.linkMenuItem} to='/reportfinished' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/reportfinished' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span>ส่งข้อสอบ</span>
                </Link>
              </Menu.Item>
              
            </SubMenu>
            :
            null
        }
        {
          this.props.profile.role === 'admin' ? (
            <Menu.Item key='/user' onClick={this.onClose}>
              <Link className={styles.linkMenuItem} to='/user'>
                {/* <IconMasterDataSideBar/> */}
                {/* <IconListUserSideBar/> */}
                <img src={IconListUser} alt='IconVector' className = {styles.icon_vector}/>
                <span className = {styles.title_suggest} >รายชื่อในระบบ</span>
              </Link>
            </Menu.Item>
          ) : ''
        } 
        {
          this.props.profile.role === 'admin' ?
            <SubMenu
              key='sub3'
              title={
                <p id = {styles.exam_title} className={styles.linkMenuItem} to='/examset'>
                  {/* <IconExam /> */}
                  <img src={IconMemberData} alt='IconVector' className = {styles.icon_vector}/>
                  <span  style={{ color: 'white'}} className = {styles.title_suggest}>ข้อมูลสมาชิก</span>
                </p>
              }
            >
              <Menu.Item key='/prefix' onClick={this.onClose}>
                <Link className={styles.linkMenuItem} to='/prefix' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/prefix' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>คำนำหน้าชื่อ</span>
                </Link>
              </Menu.Item>
              <Menu.Item  key='/relation' onClick={this.onClose}>
                <Link className={styles.linkMenuItem} to='/relation' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/relation' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>ความสัมพันธ์</span>
                </Link>
              </Menu.Item>
              <Menu.Item  key='/school' onClick={this.onClose}>
                <Link className={styles.linkMenuItem} to='/school' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/school' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>โรงเรียน</span>
                </Link>
              </Menu.Item>
              <Menu.Item  key='/departments'  onClick={this.onClose}>
                <Link className={styles.linkMenuItem} to='/departments' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/departments' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>สังกัด</span>
                </Link>
              </Menu.Item>
              <Menu.Item  key='/provinces' >
                <Link className={styles.linkMenuItem} to='/provinces' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/provinces' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>จังหวัด</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            :''
        }
        {
          this.props.profile.role === 'admin' ?
            <SubMenu
              key='sub4'
              title={
                <p id = {styles.exam_title} className={styles.linkMenuItem} to='/examset'>
                  {/* <IconSubjectsDataSideBar /> */}
                  <img src={IconSubjectsData} alt='IconVector' className = {styles.icon_vector}/>
                  <span  style={{ color: 'white'}} className = {styles.title_suggest}>ข้อมูลข้อสอบ</span>
                </p>
              }
            >
              <Menu.Item key='/subjects'>
                <Link className={styles.linkMenuItem} to='/subjects' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/subjects' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>วิชา</span>
                </Link>
              </Menu.Item>
              <Menu.Item  key='/competitions' >
                <Link className={styles.linkMenuItem} to='/competitions' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/competitions' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>รายการแข่งขัน</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            :''
        }
        {
          this.props.profile.role === 'admin' ?
            <SubMenu
              key='sub5'
              title={
                <p id = {styles.exam_title} className={styles.linkMenuItem} to='/examset'>
                  <img src={IconHomeData} alt='IconVector' className = {styles.icon_vector}/>
                  <span style={{ color: 'white'}} className = {styles.title_suggest}>ข้อมูลหน้าแรก</span>
                </p>
              }
            >

              <Menu.Item key='/mastercontactus'>
                <Link className={styles.linkMenuItem} to='/mastercontactus' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/mastercontactus' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>ติดต่อเรา</span>
                </Link>
              </Menu.Item>
              <Menu.Item  key='/faq'>
                <Link className={styles.linkMenuItem} to='/faq' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/faq' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>FAQ</span>
                </Link>
              </Menu.Item>
              <Menu.Item key='/condition'>
                <Link className={styles.linkMenuItem} to='/condition' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/condition' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>ข้อกำหนด</span>
                </Link>
              </Menu.Item>
              <Menu.Item  key='/datapolicy'>
                <Link className={styles.linkMenuItem} to='/datapolicy' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/datapolicy' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>Data Policy</span>
                </Link>
              </Menu.Item>
              <Menu.Item  key='/server'>
                <Link className={styles.linkMenuItem} to='/server' id = {styles.icon_vector}>
                  <img src={currentMenutab === '/server' ? IconVectorSelected : IconVector} alt='IconVector' className = {styles.icon_vector}/>
                  <span className = {styles.title_sugges}>ปรับปรุงระบบ</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            :''
        }
        {
          this.props.profile.role === 'admin' ?
            <Menu.Item key='/notice' >
              <Link className={styles.linkMenuItem} to='/notice'>
                {/* <IconNoticeDataSideBar /> */}
                <img src={IconNotice} alt='IconVector' className = {styles.icon_vector}/>
                <span>ประกาศ</span>
              </Link>
            </Menu.Item>
            :''
        }
        <Menu.Item key='/profile' className='mobile'>
          <Link className={styles.linkMenuItem} to='/profile'>
            <img src={IconEditProfileMobile} alt='IconVector' className={styles.icon_vector} />
            <span>แก้ไขข้อมูลส่วนตัว</span>
          </Link> 
        </Menu.Item>
        <Menu.Item key='/noticeMobile' className='mobile' onClick={logout(this.props)} style = {{marginBottom : 100}}>
          <img src={IconLogoutO} alt='IconVector' className={styles.icon_vector} />
          <span>ออกจากระบบ</span>
        </Menu.Item>
      </Menu>
    )
    return (
      <div>
        <Layout className={styles.layout} >
          <Sider className={styles.layoutSidebar} width={'215px'}>
            <div style={{textAlign: 'center', marginBottom: 70}}>
              <a href='http://www.ipst.ac.th' rel='noopener noreferrer' target = '_blank'><img src={logoSSWT} alt='logoSSWT' className={styles.sswtLogo}/></a>
              <a href = '/'><img src={logoOnline} alt='logoOnline' className={styles.onlineLogo} /></a>
            </div>

            {menuItems}

            <div className = {styles.div_linkTerm_dataPolicy} type='flex' justify='space-between'>
              <Link to='/contactusmember'>
                <span className = {styles.linkContactUs}>ติดต่อเรา</span>
              </Link>
              <Link to='/faqmember'>
                <span className = {styles.linkFaq}>คำถามที่ถามบ่อย</span>
              </Link>
            </div>
          </Sider>

          <Layout>
            <Header className={styles.layoutHeader} style = {{zIndex : 4}}>
              <div className={styles.layoutHeaderLogo}>
                <a href='http://www.ipst.ac.th' rel='noopener noreferrer' target = '_blank' >
                  <img src={logoSSWT} alt='logoSSWT' className={styles.sswtLogo} />
                </a>
                <Link to='/'>
                  <img src={logoOnline} alt='logoOnline' className={styles.onlineLogo} />
                </Link>
              </div>

              <div className='user'>
                <div className='mobile'>
                  <span onClick={this.onToggleMenu}>เมนู</span>
                </div>

                <div className='desktop'>
                  <Dropdown overlay={profileMenu(this.props)} trigger={['click']}>
                    <span className='ant-dropdown-link' style={{cursor: 'pointer'}} id='profileMenu'>
                      <span>
                        {(() => {
                          switch(this.props.profile.role){
                            case 'admin'        : return 'Admin '
                            case 'superTeacher' : return 'ครูพิเศษ '
                            case 'teacher'      : return 'ครู/อาจารย์ '
                            case 'student'      : return 'นักเรียน '
                            default             : return ''
                          }
                        })()}
                      </span>
                      <img src={this.props.profile && this.props.profile.profileImage 
                        ? this.props.profile && this.props.profile.profileImage
                        : DefaultProfile} alt='profile' className={styles.avatarImage } />
                      <span>{this.props.profile && this.props.profile.firstName} {this.props.profile && this.props.profile.lastName}</span>
                    </span>
                  </Dropdown>
                </div>
              </div>
            </Header>
            <Header style = {{height : 'auto', backgroundColor : 'orange'}} >
              <div style = {{backgroundColor : 'orange'}}>
                <div className='user'>
                  <div className='mobile'>
                  </div>
                  <div className='desktop' style = {{backgroundColor : 'orange'}}>
                    <NoticeAlert type = 'first' position = 'top-center'/>
                  </div>
                  <ModalTermDataMember
                    key='ModalTermDataMember'
                    isVisible={this.state.isShowTerm && this.state.isShowDataPolicy}
                    onOK={this.agreeBoth}
                    onCancel={this.onTermCancel}
                    role={this.props.profile ? this.props.profile.role : null}
                    termAndCondition={this.props.termAndCondition}
                    dataPrivacy={this.props.dataPrivacy}
                    setPrivacyPolicy = { this.setPrivacyPolicy }
                  />
                  <ModalTermMember
                    key='ModalTermMember'
                    isVisible={this.state.isShowTerm && !this.state.isShowDataPolicy}
                    onOK={this.agreeTerm}
                    onCancel={this.onTermCancel}
                    role={this.props.profile ? this.props.profile.role : null}
                    termAndCondition={this.props.termAndCondition}
                    dataPrivacy={this.props.dataPrivacy}
                    setPrivacyPolicy = { this.setPrivacyPolicy }
                  />
                  <ModalDataPolicyMember
                    key='ModalDataPolicyMember'
                    history = {this.props.history}
                    isVisible={this.state.isShowDataPolicy && !this.state.isShowTerm}
                    onOK={this.agreePrivacy}
                    onCancel={this.onTermCancel}
                    role={this.props.profile ? this.props.profile.role : null}
                    termAndCondition={this.props.termAndCondition}
                    dataPrivacy={this.props.dataPrivacy}
                    setPrivacyPolicy = { this.setPrivacyPolicy }
                  />
                </div>
              </div>
            </Header>
      
            <Content className={styles.layoutContent}>
              {window.screen.width > 991 ? this.showOnBoarding() : ''} 
              {this.props.location.pathname === '/examset' && window.screen.width > 991 && this.props.role !== 'admin' ? this.modalOnBoarding() : ''}
              {childrenWithProps}
            </Content>
            <Drawer
              placement='right'
              closable={false}
              onClose={this.onClose}
              visible={this.state.menu}
              style={{ top: 48 }}
              className={styles.menu_mobile}
            >
              <div className='profile'>
                <img src={this.props.profile && this.props.profile.profileImage 
                  ? this.props.profile && this.props.profile.profileImage
                  : DefaultProfile} alt='profile' className={styles.avatarImage } />
                <div className='detail'>
                  <h3>{this.props.profile && this.props.profile.firstName} {this.props.profile && this.props.profile.lastName}</h3>
                  <p>
                    {(() => {
                      switch(this.props.profile.role){
                        case 'admin'        : return 'Admin '
                        case 'superTeacher' : return 'ครูพิเศษ '
                        case 'teacher'      : return 'ครู/อาจารย์ '
                        case 'student'      : return 'นักเรียน '
                        default             : return ''
                      }
                    })()}
                  </p>
                </div>
              </div>

              <div className='menu'>
                {menuItems}
              </div>

              <div className='bottom-menu'>
                <Link to='faqmember'>
                  <span className = {styles.linkContactUs}>ติดต่อเรา</span>
                </Link>
                <Link to='/faqmember'>
                  <span className = {styles.linkFaq}>คำถามที่ถามบ่อย</span>
                </Link>
              </div>
            </Drawer>
          </Layout>
        </Layout>
        {/* dev/ots2023 show after updated term and/or privacy */}
        <ModalUpdateNotice 
          key="ModalUpdateNotice"
          isVisible={this.state.showTermNotice}
          onOK={this.onTermNoticeOK}
          noticeType={this.state.noticeMessage}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.view.isLoading,
    profile: state.auth.profile,
    termAndCondition: state.master.termAndCondition,
    dataPrivacy: state.master.dataPrivacy,
    groupList: state.group.groupList,
    examSetList: state.exam.examSetList
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserLogout,
      getNoticeList,
      UserSeenNotice,
      SetSeenTermAndConditionStatus,
      GetMasterData,
      SetSeenTour,
      changeCreateStep
    },
    dispatch
  )
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutCMS))
