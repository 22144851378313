import React, { Component, Fragment }  from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Layout,
  Button,
  Menu,
  Dropdown
} from 'antd'
import FlipNumbers from 'react-flip-numbers'

import PauseTestingIcon from '../IconSVG/PauseTestingIcon.svg'
import SentTestingIcon from '../IconSVG/SentTestingIcon.svg'
import IconMore from '../IconSVG/IconMore.svg'
import styles from './Testing.module.scss'
import {
  //GetProfile
} from '../../redux/auth/action'
import * as moment from 'moment'
class HeaderTesting extends Component {
  constructor(props){
    super(props)
    this.state = {
      isTimeStart: false
    }
  }

  componentDidMount() { }

  countdown = () => {
    const { timeRemaining } = this.state

    if (timeRemaining > 0) {
      this.setState({ timeRemaining: this.state.timeRemaining -1000 })
    } else {
      this.props.onFinishTesting(true)
    }
  }

  menuHeader = () => {
    return (
      <Menu>
        <Menu.Item key='0' onClick={this.props.onBreakTesting}>
          <img alt='pause-testing-icon' src={PauseTestingIcon}/>
          พักการสอบ
        </Menu.Item>
        <Menu.Item key='1' onClick={this.props.onFinishTesting}>
          <img alt='sent-testing-icon' src={SentTestingIcon}/>
          ส่งข้อสอบ
        </Menu.Item>
      </Menu>
    )
  }

  backToHome = () => {
    // const redirectPath = getLocationRedirect()
    const { state } = this.props.history.location
    // if (redirectPath) { 
    //   this.props.history.push(redirectPath, {cameFrom: 'group'})
    // } else 
    
    if((this.props.auth && this.props.auth.role === 'student') || (state && state.cameFrom === 'createExam')) {   
      if (state.cameFrom === 'group') {
        this.props.history.push( `/group/${state.groupId}`, { cameFrom: 'group', groupId: state.groupId })
      } else if (state.cameFrom === 'report') {
        if (state.groupId) {
          const locationState = {
            cameFrom: 'group', 
            groupId: state.groupId
          }
          if (state.groupName) {
            locationState.groupName = state.groupName
          } else {
            locationState.examId = state.examId
          }
          this.props.history.push(`/report/exam/${state.examId}/group/${state.groupId}`, locationState)
        } else {
          this.props.history.push(`/report/${state.examId}`, { cameFrom: 'exam', examId: state.examId })
        }
      } else {
        this.props.history.push('/')
      } 
    }  else {    
      if (state.cameFrom === 'report' && this.props.auth.role !== 'student') {
        if (state.examDetail) { // from owner group report page
          this.props.history.push(`/report/${state.examDetail}${state.groupId ? `/group/${state.groupId}` : ''}`, { cameFrom: state.groupId && !state.fromOwnerExam ? 'group' : 'exam', groupId: state.groupId })
        }
        if (state.reportExamId) { // from owner exam report page
          this.props.history.push(`/report/${state.reportExamId}/groups`, { cameFrom: 'exam' })
        }
      } else {
        state && state.cameFrom === 'group' && state.groupId ? this.props.history.push( `/group/${state.groupId}`, { cameFrom: 'exam' }) :
          state && state.examDetail ? this.props.history.push( `/examset/${state.examDetail}`, { cameFrom: 'exam' }) : 
            state && (state.reportExamId && !state.groupId) ? this.props.history.push( `/report/${state.reportExamId}/groups` , { cameFrom: 'exam', groupId: state.groupId, groupName: state.groupName }) :  
              this.props.history.push('/', { cameFrom: 'exam' })
      }
      
    }
  }

  render() {
    const { exam, subjectIcon, isSolution, seqQuestion, cameFrom, time } = this.props  
     
    let hourRemaining, minuteRemaining, secondRemaining, hourRemainingRender, minuteRemainingRender, secondRemainingRender 
    if (!isSolution && exam.duration > 0) {
      const timeRemaining = moment.duration((exam.duration*1000) - time)
      
      if (timeRemaining <= 0) {
        this.props.onFinishTesting(true)
      }

      hourRemaining = timeRemaining.hours()
      minuteRemaining = timeRemaining.minutes()
      secondRemaining = timeRemaining.seconds()

      hourRemainingRender = hourRemaining > 0 ? <span ><FlipNumbers height={12} width={12} color='#00A0FA' background='white' play perspective={50} numbers={`${hourRemaining}`} /></span> : <span style={{ color: '#00A0FA',background:'white' }}>00</span>
      minuteRemainingRender = minuteRemaining > 0 ? <span ><FlipNumbers height={12} width={12} color='#00A0FA' background='white' play perspective={50} numbers={`${minuteRemaining}`} /></span> : <span height={12} width={12} style={{ color: '#00A0FA',background:'white' }}>00</span>
      secondRemainingRender = secondRemaining > 0 ? <span ><FlipNumbers height={12} width={12} color='#00A0FA' background='white' play perspective={50} numbers={`${secondRemaining}`} /></span> : <span height={12} width={12} style={{ color: '#00A0FA',background:'white' }}>00</span>

    } 
    // const timeRemainingDisplay = (<span style={{ color: '#00A0FA'}}>{timeRemainingDuration.hours()} ชั่วโมง {timeRemainingDuration.minutes()} นาที {timeRemainingDuration.seconds()} วินาที</span>)
    const owner = exam.owner ? { prefixName: ['teacher', 'superTeacher'].includes(exam.owner.role) ? 'ครู' : '' , fullname: `${exam.owner.firstName} ${exam.owner.lastName}` } : undefined
    return (
      <Layout.Header className={styles.header_testing}>
        <div className='mobile'>
          <div className='header-test-detail'>
            <p className = { styles.num_of_exam }>{ isSolution ? `เฉลย (${seqQuestion})` : `(${seqQuestion})`}</p>

            {
              (!isSolution && (exam.duration && exam.duration > 0)) && (
                <div className='time'>  
                  เหลือ {hourRemainingRender}:{minuteRemainingRender}:{secondRemainingRender}
                </div>
              )
            }
          </div>

          <div className='header-test-actions'>
            {
              isSolution ? (
                <Button onClick={this.backToHome} >
                  กลับสู่หน้าหลัก
                </Button>
              ) : exam.type !== 'CAT' ? (
                <Dropdown overlay={this.menuHeader} trigger={['click']}>
                  <a className='ant-dropdown-link' href='/#'>
                    <img src={IconMore} alt='icon-more'/>
                  </a>
                </Dropdown>
              ) : ''
            }
          </div>
        </div>

        <div className='desktop'>
          <div className='header-test-detail'>
            <img alt='subject-icon' src={subjectIcon} />

            <div className='content'>
              <h2>{exam.name}</h2>

              <span style={{ color:'#828282'}}>
                {exam.code} โดย {owner ? `${owner.prefixName}${owner.fullname}`: ''} 
              </span>
            </div>
          </div>

          { exam.type !== 'CAT' ? 
            <div className='header-test-actions'>
              <div className='action'>
                {
                  (!isSolution && exam.duration && exam.duration > 0) && (
                    <div className='time' >
                      {/* เหลือ {hourRemainingRender} {minuteRemainingRender} {minuteRemaining < 1 ? secondRemainingRender : ''} */}
                      เหลือ {hourRemainingRender}:{minuteRemainingRender}:{secondRemainingRender}
                    </div>
                  )
                } 
                {
                  isSolution ? (
                    <Button onClick={this.backToHome} 
                    >
                      กลับสู่หน้าหลัก
                    </Button>
                  ) : (
                    <Fragment>
                      {
                        cameFrom !== 'homeSuggest' ?
                          <Button className={styles.button_nav}
                            onClick={this.props.onBreakTesting}
                            data-tut = 'step-testing-third'
                          >
                            <img alt='pause-testing-icon' src={PauseTestingIcon}
                            />
                        พักการสอบ
                          </Button>
                          : ''
                      }

                      <Button className={styles.button_nav}
                        onClick={() => { this.props.onFinishTesting() }}
                        data-tut = 'step-testing-fourth'
                      >
                        <img alt='sent-testing-icon' src={SentTestingIcon}
                        />
                        ส่งข้อสอบ
                      </Button>
                    </Fragment>
                  )
                }
              </div>
            </div> : '' }
        </div> 
      </Layout.Header>
    )
  }
}
const mapStateToProps = state => {
  return{
    auth: state.auth.profile
  }
} 
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { 
      //GetProfile, 
    },
    dispatch
  )
export default connect(mapStateToProps, mapDispatchToProps)(HeaderTesting)
