import React, { Component } from 'react' 
import ImgEmail from '../../assets/ImgEmail.svg'

export default class SentEmailForgetPassword extends Component {

  handleNext = () => {
    this.props.onNext()
  }

  render() {
    const { email } = this.props
    return (
      <div style={{textAlign: 'center'}}>
        <div style={{marginTop: 36, marginBottom: 20}}>
          <img src={ImgEmail} alt='ImgEmail' width='45%'/>
        </div>
        {(
          <div>
            <p className='text-lg text-medium'>ส่งคำร้องขอสำเร็จ</p>
            <p className='text-thin'>
            ระบบทำการส่งข้อมูลการตั้งรหัสผ่านใหม่ไปยังอีเมล<br/> {email} เรียบร้อยแล้ว
            </p>
          </div>
        )}
      </div>
    )
  }
}

SentEmailForgetPassword.propTypes = {
  // haveEmail: PropTypes.bool.isRequired,
  // onNext: PropTypes.func.isRequired,
}