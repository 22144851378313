import { FetchCycle, AxiosRequest } from '../fetch'

// API =====================================================================
const API_COMPETITIONS = '/master/competitions'

// Fetch ===================================================================

const competitionsList                = ()                   => () => AxiosRequest(API_COMPETITIONS,'get')  
const updateCompetition               = (competitionId,data) => () => AxiosRequest(`${API_COMPETITIONS}/${competitionId}`,'patch',data)
const dragToChangePositionCompetition = (competitionId,data) => () => AxiosRequest(`${API_COMPETITIONS}/${competitionId}/seq`,'patch',data)
const competitionOptions              = (project)            => () => AxiosRequest(`${API_COMPETITIONS}/options?project=${project}`, 'get')
const competitionYears = (project, learning_area, key_stage) => () => AxiosRequest(`${API_COMPETITIONS}/years?project=${project}&learning_area=${learning_area}&key_stage=${key_stage}`, 'get')
const otimsCompetitionList = () => () => AxiosRequest(`${API_COMPETITIONS}/otims`, 'get')
// Action =====================================================================

export const getCompetitionsList = () => FetchCycle(competitionsList())  
export const switchCompetition = (competitionId,data) => FetchCycle(updateCompetition(competitionId,data))
export const onDragToChangePositionCompetition = (competitionId,data) => FetchCycle(dragToChangePositionCompetition(competitionId,data))
export const getCompetitionOption = (project) => FetchCycle(competitionOptions(project))
export const getCompetitionYear = (project, learning_area, key_stage) => FetchCycle(competitionYears(project, learning_area, key_stage))
export const getOTIMSCompetitionList = () => FetchCycle(otimsCompetitionList())