import React, { Component, Fragment }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import QRCode from 'qrcode'
import AvatarGroupNoti from '../Group/GroupDetail/AvatarGroupNoti'
import {
  message,
  Spin,
  Table,
  Modal,
  Button,
  Menu,
  Dropdown,
  Icon,
  Avatar
} from 'antd'
import {
  createGroup,
  getGroupList,
  searchGroupList,
  editGroup,
  deleteGroup,
  studentRequestToJoinGroup,
  studentCancelRequestToJoinGroup,
  studentLeaveGroup,
  RemovedStudentInGroup,
  getExamsNotInGroup,
  addExams,
  getExamsInGroup,
  getListLetf,
  setListLetf
} from '../../redux/group/action'
import { toast } from 'react-toastify'
import ModalAddExam from './ModalAddExam'

import { IconCorrect } from '../IconSVG'
import IconCopyLink from '../IconSVG/IconCopyLink.svg'
import IconQRCode from '../IconSVG/IconQRCode.svg'
// import AddExamIcon from '../IconSVG/AddExamIcon.svg'
// import AddExamGrayIcon from '../IconSVG/AddExamGrayIcon.svg'
import Delete from '../IconSVG/Delete.svg'
import DeleteGray from '../IconSVG/DeleteGray.svg'
import ExamAdd from '../IconSVG/ExamAdd.svg'
import ExamAddGray from '../IconSVG/ExamAddGray.svg'
import Share from '../IconSVG/Share.svg'
import ShareGray from '../IconSVG/ShareGray.svg'
import Edit from '../IconSVG/Edit.svg'
import EditGray from '../IconSVG/EditGray.svg' 
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ModalAddGroup from './ModalAddGroup'
import ModalQRCode from './ModalQRCode'
import styles from './Group.module.scss'
import './GroupContent/GroupContent.scss'
import GroupHeader from './GroupHeader'
import ListItems from '../Display/ListItems'
import GoupLeaveBigIcon from '../IconSVG/GoupLeaveBigIcon.svg'
import GoupLeaveBigIcon01 from '../IconSVG/CancelGoupLeaveBigIcon.svg' 
import { StudentSearchPage } from './GroupHeader/Search'
import SearchModal from '../modal/SearchModal'
import DefaultGroup from '../IconSVG/DefaultGroup.svg' 

const STATUS_REMOVE = 'dismiss'
let countToast = 0
class Group extends Component {

  menu = (group) => {
    return(
      <Menu onClick={(e) => e.stopPropagation()}>
        <Menu.Item key='0'>
          <CopyToClipboard
            text={`${window.location.host}/group/${group.id}`}
            onCopy={this.onCopyLinkSuccess}
          >
            <span
              onClick ={e=> {
                e.stopPropagation()
                this.setState({
                  visibleDropdown: false
                })
              }}
            >
              <img alt='icon-copy-link' src={IconCopyLink}/>
            คัดลอกลิงก์</span>
          </CopyToClipboard>
        </Menu.Item>
        <Menu.Item key='1'>
          <span
            onClick ={e=> {
              e.stopPropagation()
              this.onQrCodeGenerate(group)
              this.onOpenQRCodeModal()
              this.setState({
                visibleDropdown: false
              })
            }}
          ><img alt='icon-qr-code' src={IconQRCode}/>แชร์คิวอาร์โค้ด</span>
        </Menu.Item>
      </Menu>
    )} 
  columns = [
    { 
      title: '', dataIndex: 'logo', key: 'logo',
      align: 'center',
      render: (logo,data) => {
        if(logo) { 
          return(
            // <img src={logo ? logo : data.haveStudentRequest ? EllipseNotiInGroup : DefaultGroup} alt='group_logo' className={styles.logoGroup}/>
            data.haveStudentRequest 
              ?
              <Fragment>
                <AvatarGroupNoti
                  imageDefault={  logo ? logo : DefaultGroup }
                />
              </Fragment>
              :
              <Fragment>
                <img src={ logo || DefaultGroup} alt='ImgAvatar' width='100%' style={{ borderRadius: '50%'}} className={styles.logoGroup}/>
              </Fragment>
          )    
        }
      },
      width: '60px',
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
      sorter: (strA, strB) => {
        let icmp = strA.name.toLowerCase().localeCompare(strB.name.toLowerCase())
        if (icmp !== 0) {
          return icmp
        }
        if (strA.name > strB.name) {
          return 1
        } else if (strA.name < strB.name) {
          return -1
        } else {
          return 0
        }
      },
      width: '250px'
    },
    {
      title: 'รหัสกลุ่ม',
      dataIndex: 'code',
      key: 'code',
      render: (code) => {
        return(
          <div data-tut-teacher-group-tour='stepTeacherTourGroup-first'>{code}</div>
        )
      },
      sorter: (strA, strB) => {
        let icmp = strA.code.toLowerCase().localeCompare(strB.code.toLowerCase())
        if (icmp !== 0) {
          return icmp
        }
        if (strA.code > strB.code) {
          return 1
        } else if (strA.code < strB.code) {
          return -1
        } else {
          return 0
        }
      },
      align: 'center',
      width: '120px',
      onCell: () => {
        return {
          onMouseEnter: () => { this.setState({ hoverCodeExam: true }) },
          onMouseLeave: () => { this.setState({ hoverCodeExam: false }) }
        }
      }
    }
  ] 
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isShowAddGroupModal: false,
      editGroup: null,
      encodeGroudId: '',
      isShowQrCodeModal: false,
      imgQRCode: null,
      groupList: null,
      visibleDropdown: false,
      visibleModalDelete : false,
      groupForModal : {},
      indexRow: null,
      checkAddExam: null,
      checkShare: null,
      checkEdit: null,
      checkDelete: null,
      isSearchModal: false,
      searchResult: [],
      isVisibleAddExam: false,
      examsWithoutGroup: [],
      groupSelectAddExam: [],
      groupForCanCel : '',
      visibleModalLeave : false,
      haveStudentRequest : false,
      listofstudentleft : [],
      checkClick: false,
      checkActionMobile : false,
      myGroupSearched : [],
      otherGroupSearched : [],
      totalPage:0,
      hoverCodeExam: false
    }
    if (props.auth.role === 'student') {
      this.columns = this.columns.concat(
        [
          {
            title: 'ผู้สร้างกลุ่ม',
            dataIndex: 'ownerName',
            render: (ownerName) => {
              return <span>{`${ownerName ? ownerName.firstName : ''} ${ownerName ? ownerName.lastName : ''}`}</span>
            },
            key: 'ownerName',
            sorter: (strA, strB) => {
              let icmp = strA.code.toLowerCase().localeCompare(strB.code.toLowerCase())
              if (icmp !== 0) {
                return icmp
              }
              if (strA.ownerName > strB.ownerName) {
                return 1
              } else if (strA.ownerName < strB.ownerName) {
                return -1
              } else {
                return 0
              }
            },
            width: '230px'
          },
          {
            title: 'สถานะ',
            dataIndex: 'status',
            key: 'status',
            render: (text, group) => (
              <div>
                {(() => {
                  if(group.status === 'request'){
                    return (<span>รอการอนุมัติ</span>)
                  }else if(group.status === 'join' && group.deletedAt === null){
                    return (<span>เข้าร่วมแล้ว</span>)  
                  }else if(group.status === 'reject'){
                    return (<span>ถูกปฏิเสธ</span>)  
                  }else if(group.status === STATUS_REMOVE){
                    return (<span>ถูกคัดออก</span>)  
                  }else if(group.status === 'join' && group.deletedAt !== null){
                    return (<span>กลุ่มถูกลบ</span>)  
                  }else{
                    return (<span></span>)  
                  }
                })()}
              </div>
            ),
            width: '130px'
          },
          {
            title: '',
            key: 'action',
            render: (text, group) => (
              <div>
                {(() => {
                  switch (group.status) {
                    case 'request':
                      return (<Button
                        className={styles.btnCancel}
                        onClick={(e) => {
                          e.stopPropagation()
                          // this.onCancelRequest(group)
                          this.setState({
                            visibleModalLeave : true,
                            groupForCanCel : group
                          })
                        }}
                      >ยกเลิกคำขอ</Button>)
                    case 'join':
                      return (
                        <Fragment>
                          {
                            group.status === 'join' && group.deletedAt !== null ?
                              <span style={{ cursor: 'pointer' }} onClick={ e => {
                                e.stopPropagation()
                                this.removedStudent(group)
                              }}>
                                <span style={{textDecoration: 'underline'}}>ตกลง</span>
                              </span>
                              :
                              <span style={{ cursor: 'pointer' }} onClick={ e => {
                                e.stopPropagation()
                                this.setState({
                                  groupForModal : group,
                                  visibleModalDelete : true
                                })
                              // this.onLeaveGroup(group)
                              }}>
                                <span style={{textDecoration: 'underline'}}>ออกจากกลุ่ม</span>
                              </span>
                          }
                        </Fragment>
                      )
                    case 'reject':
                      return (
                        <span style={{ cursor: 'pointer' }} onClick={ e => {
                          e.stopPropagation()
                          this.removedStudent(group)
                        }}>
                          <span style={{textDecoration: 'underline'}}>ตกลง</span>
                        </span>)
                    case STATUS_REMOVE:
                      return (
                        <span style={{ cursor: 'pointer' }} onClick={ e => {
                          e.stopPropagation()
                          this.removedStudent(group)
                        }}>
                          <span style={{textDecoration: 'underline'}}>ตกลง</span>
                        </span>)
                    default: return ''
                  }
                })()}
              </div>
            ),
          },
        ]
      )
    } else if(props.auth.role === 'admin'){ // in role teacher, super_teacher, admin
      this.columns = this.columns.concat(
        [
          {
            title: 'เจ้าของกลุ่ม',
            dataIndex: 'ownerName',
            key: 'ownerName',
            render: (ownerName) => { 
              return (<span>{`${ownerName.firstName} ${ownerName.lastName}`}</span>)
            },
            sorter: (strA, strB) => {
              let icmp = strA.ownerName.firstName.toLowerCase().localeCompare(strB.ownerName.firstName.toLowerCase())
              if (icmp !== 0) {
                return icmp
              }
              if (strA.ownerName.firstName > strB.ownerName.firstName) {
                return 1
              } else if (strA.ownerName.firstName < strB.ownerName.firstName) {
                return -1
              } else {
                return 0
              }
            }, 
            width: '200px'
          },
          {
            title: 'จำนวนชุดข้อสอบ',
            dataIndex: 'examCount',
            key: 'examCount',
            align: 'center',
            sorter: (strA, strB) => {
              if (strA.examCount > strB.examCount) {
                return 1
              } else if (strA.examCount < strB.examCount) {
                return -1
              } else {
                return 0
              }
            },
            width: '180px'
          },
          {
            title: 'สมาชิก',
            dataIndex: 'studentCount',
            key: 'studentCount',
            align: 'center',
            sorter: (strA, strB) => {
              if (strA.studentCount > strB.studentCount) {
                return 1
              } else if (strA.studentCount < strB.studentCount) {
                return -1
              } else {
                return 0
              }
            },
            width: '110px'
          },
          {
            title: 'วันที่สร้าง',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (strA, strB) => {
              let icmp = strA.createdAt.toLowerCase().localeCompare(strB.createdAt.toLowerCase())
              if (icmp !== 0) {
                return icmp
              }
              if (strA.createdAt > strB.createdAt) {
                return 1
              } else if (strA.createdAt < strB.createdAt) {
                return -1
              } else {
                return 0
              }
            },
            width: '130px'
          },
          { 
            key: 'addExam',
            render: (text, group, index) => {
              const { indexRow,checkAddExam } = this.state
              const mouserEnter = () => {this.setState({ checkAddExam: true })}
              const mouseLeave = () => {this.setState({ checkAddExam: false })}
              return(
                <span className={styles.tableAction} title='เพิ่มข้อสอบ'>
                  {/* Create Exam */}
                  <span onClick={ e => {
                    e.stopPropagation()
                    this.openModalAddExam(group)
                  }} className='actionIcon'>
                    <img  alt='exam-add' src={ checkAddExam && (indexRow === index) ? ExamAdd : ExamAddGray } onMouseOver={mouserEnter} onMouseLeave={mouseLeave} />
                  </span>  
                </span>
              )},
            width: '50px'
          },
          {
            key: 'share',
            render: (share,record,index) => {
              const { indexRow,checkShare } = this.state
              const mouserEnter = () => {this.setState({ checkShare: true })}
              const mouseLeave = () => {this.setState({ checkShare: false })}
              return ( 
                <Dropdown overlay={this.menu(record)} trigger={['click']}  onClick={e => e.stopPropagation()}>
                  <span title='แชร์' className='ant-dropdown-link actionIcon'>
                    <img alt='share-icon' src={ checkShare && (indexRow === index) ? Share : ShareGray } onMouseOver={mouserEnter} onMouseLeave={mouseLeave}/>  
                  </span>
                </Dropdown> 
              )
            },
            width: '50px'
            
          },
          {
            key: 'edit',
            render: (edit, record, index) => {
              const { indexRow,checkEdit } = this.state
              const mouserEnter = () => {this.setState({ checkEdit: true })}
              const mouseLeave = () => {this.setState({ checkEdit: false })}
              return ( 
                <span title='แก้ไขกลุ่ม' onClick={ e => {
                  e.stopPropagation()
                  this.onEditGroup(record)
                }} className='actionIcon'>
                  <img alt='edit-icon' src={ checkEdit && (indexRow === index) ? Edit : EditGray } onMouseOver={mouserEnter} onMouseLeave={mouseLeave} />    
                </span>
              )
            },
            width: '50px'
          },
          {
            key: 'deleteData',
            render: (deleteData,record, index) => {
              const { indexRow,checkDelete } = this.state
              const mouserEnter = () => {this.setState({ checkDelete: true })}
              const mouseLeave = () => {this.setState({ checkDelete: false })}
              return ( 
                <span title='ลบกลุ่ม' onClick={ e => {
                  e.stopPropagation()
                  this.onDeleteGroup(record)
                }} className='actionIcon'>
                  <img alt='delete-icon' src={ checkDelete && (indexRow === index) ? Delete : DeleteGray } onMouseOver={mouserEnter} onMouseLeave={mouseLeave} />      
                </span>
              )
            }
          },
        ]
      )
    }
    else { // in role teacher, super_teacher, admin
      this.columns = this.columns.concat(
        [
          {
            title: 'สมาชิก',
            dataIndex: 'studentCount',
            key: 'studentCount',
            align: 'center',
            sorter: (strA, strB) => {
              if (strA.studentCount > strB.studentCount) {
                return 1
              } else if (strA.studentCount < strB.studentCount) {
                return -1
              } else {
                return 0
              }
            },
            width: '110px'
          },
          {
            title: 'วันที่สร้าง',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (strA, strB) => {
              let icmp = strA.createdAt.toLowerCase().localeCompare(strB.createdAt.toLowerCase())
              if (icmp !== 0) {
                return icmp
              }
              if (strA.createdAt > strB.createdAt) {
                return 1
              } else if (strA.createdAt < strB.createdAt) {
                return -1
              } else {
                return 0
              }
            },
            width: '130px'
          },
          { 
            key: 'addExam',
            render: (text, group, index) => {
              const { indexRow,checkAddExam } = this.state
              const mouserEnter = () => {this.setState({ checkAddExam: true })}
              const mouseLeave = () => {this.setState({ checkAddExam: false })}
              return(
                <span className={styles.tableAction}>
                  {/* Create Exam */}
                  <span onClick={ e => {
                    e.stopPropagation()
                    this.openModalAddExam(group)
                  }} className='actionIcon'>
                    <img alt='add-exam-icon' title='เพิ่มข้อสอบ' src={ checkAddExam && (indexRow === index) ? ExamAdd : ExamAddGray } onMouseOver={mouserEnter} onMouseLeave={mouseLeave} data-tut-teacher-group-tour='stepTeacherTourGroup-third'/>  
                  </span>  
                </span>
              )},
            width: '50px'
          },
          {
            key: 'share',
            render: (share,record,index) => {
              const { indexRow,checkShare } = this.state
              const mouserEnter = () => {this.setState({ checkShare: true })}
              const mouseLeave = () => {this.setState({ checkShare: false })}
              return ( 
                <Dropdown overlay={this.menu(record)} trigger={['click']}  onClick={e => e.stopPropagation()}>
                  <span title='แชร์' className='ant-dropdown-link actionIcon'>
                    <img alt='share-icon' src={ checkShare && (indexRow === index) ? Share : ShareGray } onMouseOver={mouserEnter} onMouseLeave={mouseLeave} data-tut-teacher-group-tour='stepTeacherTourGroup-second'/>  
                  </span>
                </Dropdown> 
              )
            },
            width: '50px'
            
          },
          {
            key: 'edit',
            render: (edit, record, index) => {
              const { indexRow,checkEdit } = this.state
              const mouserEnter = () => {this.setState({ checkEdit: true })}
              const mouseLeave = () => {this.setState({ checkEdit: false })}
              return ( 
                <span onClick={ e => {
                  e.stopPropagation()
                  this.onEditGroup(record)
                }} className='actionIcon'>
                  <img alt='edit-icon' title='แก้ไขกลุ่ม' src={ checkEdit && (indexRow === index) ? Edit : EditGray } onMouseOver={mouserEnter} onMouseLeave={mouseLeave} />    
                </span>
              )
            },
            width: '50px'
          },
          {
            key: 'deleteData',
            render: (deleteData,record, index) => {
              const { indexRow,checkDelete } = this.state
              const mouserEnter = () => {this.setState({ checkDelete: true })}
              const mouseLeave = () => {this.setState({ checkDelete: false })}
              return ( 
                <span onClick={ e => {
                  e.stopPropagation()
                  this.onDeleteGroup(record)
                }} className='actionIcon'>
                  <img alt='delete-icon' title='ลบกลุ่ม' src={ checkDelete && (indexRow === index) ? Delete : DeleteGray } onMouseOver={mouserEnter} onMouseLeave={mouseLeave} />      
                </span>
              )
            }
          },
        ]
      )
    }
  }

  componentDidMount = () => {
    this.fetchGroups('', 1)
    if(this.props.auth.role !== 'student'){
      if(this.props.location.pathname === '/group' && countToast === 0){
        this.fetchListStudentJustLeft()
        countToast++
      }
    } 
    this.setState({
      checkClick : false
    })
  }

  onOpenShare  = () => { this.setState({ visibleDropdown: true  })}
  onCloseShare = () => { this.setState({ visibleDropdown: false })}
  onOpenQRCodeModal = () => { this.setState({ isShowQrCodeModal: true})}
  onCloseQRCodeModal = () => { this.setState({ isShowQrCodeModal: false })}

  onQrCodeGenerate = async (group) => {
    // eslint-disable-next-line no-undef
    let url = window.location.href + '/' + group.id
    const generateQRCode = await QRCode.toDataURL(url)
    this.setState({
      imgQRCode: generateQRCode
    })
  }

  onOpenAddGroup = () => {
    this.setState({isShowAddGroupModal: true})
  }

  fetchGroups = async (newFilter = null, newCurrentPage = null) => {
    let { filter, currentPage } = this.state
    if (newFilter != null) {
      filter = newFilter
    }
    if (newCurrentPage != null) {
      currentPage = newCurrentPage
    }
    this.setState({ isLoading: true })
    const res = await this.props.getGroupList(filter, currentPage)
    if (res.error) message.error(res.error)
    this.setState({
      groupList: res,
      filter,
      currentPage,
      isLoading: false,
      // totalPage: 
    })
  }

  viweStudentListLeft = async() => {
    const res = await this.props.setListLetf()
    if (res.error) message.error(res.error)    
  }

  fetchListStudentJustLeft = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.getListLetf()
    if (res.error) message.error(res.error)
    else this.setState({
      listofstudentleft : res
    },()=>{
      if(this.state.listofstudentleft.length > 0 && this.state.checkClick === false){ 
        toast( 
          <div className = {styles.divNotiInGroup} style={{display : 'flex', backgroundColor : '#F7941D',textAlign : 'left', fontFamily :'Kanit', fontWeight : '500'}}> 
            <p style = {{color : '#05007A', marginRight : '30px', marginTop : 'auto',marginBottom : 'auto'}}>มีนักเรียนออกจากกลุ่ม {res.length} คน </p>
            <Link to='/group/listofstudentleft'>
              <Button className ={styles.btnInNotiGroup} onClick = {()=>this.viweStudentListLeft()}><span style = {{color : '#05007A'}}>ดูรายชื่อ</span></Button>
            </Link>
          </div>,this.options = {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: true, 
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false, 
            closeButton: false,
            className:'notice_alert notice_alert_group',
            toastClassName : 'notice_alert_toast',
            bodyClassName: 'notice_alert_body ',
            style: {backgroundColor : '#F7941D'}
          } )
      }
    })
  }

  onCloseModalAddGroup = () => this.setState({isShowAddGroupModal: false, editGroup: null})
  onCloseModalQrCode = () => this.setState({ isShowQrCodeModal: false })
  onSubmitGroup = async (data) => {
    const { logo, name } = data
    if (data.id) {
      await this.props.editGroup(data.id, {
        logo,
        name,
      })
    } else {
      await this.props.createGroup({
        logo,
        name,
      })
    }
    this.onCloseModalAddGroup()
    this.fetchGroups()
  }

  onSearchGroup = (value) => {
    this.fetchGroups(value, 1)
    // this.fetchGroups(value)
  }

  onStudentSearch = async(searchWord) => {
    let { myGroupSearched, otherGroupSearched } = this.state
    this.setState({ searchWord }) 
    if (searchWord) { 
      this.setState({ isLoading: true })
      const resOther = await this.props.searchGroupList(searchWord)
      const resMy = await this.props.getGroupList(searchWord) 

      otherGroupSearched = resOther.filter(ot => ot.status !== 'join')

      myGroupSearched = resMy.filter(my => my.status === 'join' || my.status === 'request')
      this.setState({ 
        isLoading: false,
        otherGroupSearched,
        myGroupSearched,
        checkActionMobile : true
      })
      // this.fetchGroups()
    } else { 
      this.setState({
        checkActionMobile : false,
        searchResult : []
      })
    }
    this.fetchGroups(searchWord, 1)
  }

  onEditGroup = (group) => {
    this.setState({isShowAddGroupModal: true, editGroup: group})
  }

  onDeleteGroup = (group) => {
    const modal = Modal.confirm({
      centered: true,
      okText: 'ลบกลุ่ม',
      cancelText: 'ยกเลิก',
      width: 580,
      icon: null,
      className: 'modal-delete-group',
      title: (
        <button aria-label='Close' className='ant-modal-close' style={{display: 'block'}} onClick={()=> modal.destroy()}>
          <Icon type='close' className='ant-modal-close-x'/>
        </button>
      ),
      content: (
        <div style={{textAlign: 'center'}}>
          <img src={group.logo ? group.logo : DefaultGroup} alt='logo' style={{borderRadius: '50%', width: 170, height: 170, marginBottom: 30}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>ลบกลุ่ม</h2>
          <p className='text-medium' style={{}}>{group.name}</p>
          <p>ต้องการลบกลุ่มนี้หรือไม่</p>
          <div className={styles.modalCreated} >
            <div className={styles.sub_modalCreated} ><Button onClick = {() => modal.destroy()} className = {styles.btnCancle}>ยกเลิก</Button></div>
            <div className={styles.sub_modalCreated_2} ><Button type = 'primary' onClick = {async() => {
              await this.props.deleteGroup(group.id)
              modal.destroy()
              await this.fetchGroups() 
            }} className = {styles.btnLeave}>ลบกลุ่ม</Button></div>
            <div className={styles.sub_modalCreatedMobile} ><Button type = 'link' onClick = {() => modal.destroy()} className = {styles.btnCancleMobile}>ยกเลิก</Button></div>
          </div>
        </div>
      ),
      onOk: async () => {
        await this.props.deleteGroup(group.id)
        await this.fetchGroups()
      },
    })
  }

  onCopyLinkSuccess = () => {
    const modal = Modal.success({
      closable: true,
      centered: true,
      icon: null,
      maskClosable: true,
      okButtonProps: {hidden: true},
      width: 220,
      content: (
        <div style={{textAlign: 'center'}}>
          <IconCorrect style={{fontSize: 120}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>คัดลอกลิงก์สำเร็จ</h2>
        </div>
      )
    })
    setTimeout(() => {
      modal.destroy()
    }, 2000)
  }

  onRequestToJoin = async (group) => { 
    const res = await this.props.studentRequestToJoinGroup(group.id)
    if (res.error) return message.error(res.error)
    // this.onStudentSearch(searchWord) 
    this.fetchGroups(group.code)
  }

  onCancelRequest = async (groupId = null) => {
    const { searchWord, groupForCanCel } = this.state  
    const res = await this.props.studentCancelRequestToJoinGroup(groupForCanCel.id ? groupForCanCel.id : groupId)
    if (res.error){
      message.error(res.error)
    }else {
      message.success('ยกเลิกขอเข้ากลุ่มสำเร็จ')
    }
    this.onStudentSearch(searchWord)
    this.setState({
      visibleModalLeave : false
    })
    this.fetchGroups()
  }

  onLeaveGroup = async (group) => {
    const { searchWord } = this.state
    const res = await this.props.studentLeaveGroup(group.id)
    if (res.error) message.error(res.error)
    this.fetchGroups()
    this.onStudentSearch(searchWord)
  }

  removedStudent = async (group) =>{       
    const { searchWord } = this.state
    const res = await this.props.RemovedStudentInGroup(group.id)

    if (res.error) message.error(res.error)
    this.fetchGroups()
    this.onStudentSearch(searchWord)
  }

  rowClassName = record => {
    return (record.status === STATUS_REMOVE) || (record.status === 'join' && record.deletedAt !== null)? 'rowDisabled' : record.status === 'reject' ? 'rowDisabled' : styles.clickable
  }

  handleCancel = ()=> {
    this.setState({
      visibleModalDelete : false,
      visibleModalLeave : false
    })
  }
  deleteStudent = () =>{
    this.onLeaveGroup(this.state.groupForModal)
    this.setState({
      visibleModalDelete : false
    })
  }

  showSearchModal = () => {
    this.setState({ isSearchModal: !this.state.isSearchModal})
    if(this.state.isSearchModal) this.setState({ checkActionMobile: false })
    this.state.isSearchModal && this.fetchGroups()
  }

  listItems = (array) => { 
    const { auth } = this.props
    const arr = array !== null ? array : []
    const srcData = arr.map((item) => {
      const menuItems = this.props.auth.role === 'student' ? [
        {
          attribute: (
            <span>
              {(() => {
                switch (item.status) {
                  case 'request':
                    return (<Button
                      className={styles.btnCancel}
                      style={{ marginRight: 0, height: '35px'}}
                      status ={item.status}
                      onClick={(e) => {
                        e.stopPropagation()
                        this.setState({
                          visibleModalLeave : true,
                          groupForCanCel : item
                        })
                        // this.onCancelRequest(item) 
                      }}
                    >ยกเลิกคำขอ</Button>)
                  case 'none':
                    return (<Button
                      className={styles.btnCancel}
                      style={{ marginRight: 0 ,color : '#05007A', height: '35px'}}
                      type = 'primary'
                      status ={item.status}
                      onClick={ async(e) => {
                        e.stopPropagation()
                        await this.onRequestToJoin(item)
                        await this.fetchGroups()
                        this.setState({
                          isSearchModal : false
                        }) 
                      }}
                    >ขอเข้าร่วม</Button>)
                  case 'join':
                    return (
                      <span style={{ cursor: 'pointer' }} status ={item.status} onClick={ e => {
                        e.stopPropagation()
                        this.setState({
                          groupForModal : item,
                          visibleModalDelete : true
                        })
                      }}>
                        <span style={{textDecoration: 'underline'}}>ออกจากกลุ่ม</span>
                      </span>)
                  case 'reject':
                    return (
                      <span style={{ cursor: 'pointer' }} status ={item.status} onClick={ e => {
                        e.stopPropagation()
                        this.removedStudent(item)
                      }}>
                        <span style={{textDecoration: 'underline'}}>ตกลง</span>
                      </span>)
                  case STATUS_REMOVE:
                    return (
                      <span style={{ cursor: 'pointer' }} status ={item.status} onClick={ e => {
                        e.stopPropagation()
                        this.removedStudent(item)
                      }}>
                        <span style={{textDecoration: 'underline'}}>ตกลง</span>
                      </span>)
                  default: return ''
                }
              })()}
            </span>
          )
        }
      ] : [
        {
          attribute: (
            <span onClick={ (e) => {e.stopPropagation();this.openModalAddExam(item) }} className='actionIcon'>
              <img src={ExamAdd} alt='exam-add'/> เพิ่มข้อสอบ
            </span>
          ),
        },
        {
          attribute: (
            <CopyToClipboard
              text={`${window.location.host}/group/${item.id}`}
              onCopy={this.onCopyLinkSuccess}
            >
              <span
                onClick ={e=> {
                  e.stopPropagation()
                  this.setState({
                    visibleDropdown: false
                  })
                }}
              >
                <img alt='icon-copy-link' src={IconCopyLink}/>
            คัดลอกลิงก์</span>
            </CopyToClipboard>
          )
        },
        {
          attribute: (
            <span
              onClick ={e=> {
                e.stopPropagation()
                this.onQrCodeGenerate(item)
                this.onOpenQRCodeModal()
                this.setState({
                  visibleDropdown: false
                })
              }}
            ><img alt='icon-qr-code' src={IconQRCode}/>แชร์คิวอาร์โค้ด</span>
          )
        },
        {
          attribute: (
            <span onClick={ e => {
              e.stopPropagation()
              this.onEditGroup(item)
            }} className='actionIcon'>
              <img alt='icon-edit' src={Edit} /> แก้ไข
            </span>
          )
        },
        {
          attribute: (
            <span onClick={ e => {
              e.stopPropagation()
              this.onDeleteGroup(item)
            }}
            className='actionIcon'>
              <img src={Delete} alt='delete'/> ลบ
            </span>
          )
        }
      ]

      return {
        avatar: item.logo ? item.logo : DefaultGroup,
        title: item.name,
        description: auth.role === 'student' ? item.code : item.studentCount,
        status: item.status === 'request' || item.status === 'none' ? false : `วันที่สร้าง: ${item.createdAt}`,
        actions: menuItems,
        path: `/group/${item.id}`,
        
      }
    })

    return srcData
  }

  openModalAddExam = async(group) => {   
    let { groupSelectAddExam } = this.state 
    const { getExamsNotInGroup } = this.props 
    groupSelectAddExam = group
    const res = await getExamsNotInGroup(group.id)  
    this.setState({ groupSelectAddExam, examsWithoutGroup: res, isVisibleAddExam: true }) 
  }
  closeModalAddExam = () => {  this.setState({ isVisibleAddExam: false }) }

  handleAddExam = async (examIds) => { 
    const { groupSelectAddExam } = this.state
    const { addExams, getExamsNotInGroup } = this.props  
    await addExams(groupSelectAddExam.id, examIds)
    await getExamsNotInGroup(groupSelectAddExam.id)
    this.closeModalAddExam()
  }
  onPaginate = (fetchGroups) => {
    this.fetchGroups(null, fetchGroups.current) 
  } 

  render() {
    const { auth, searchedGroupList, nextStepTour } = this.props 
    const { searchWord, groupList, isVisibleAddExam , examsWithoutGroup, groupSelectAddExam, hoverCodeExam, currentPage } = this.state 
    const title = 'กลุ่ม'
    
    return (
      <div className={styles.groupTable}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <GroupHeader
            role={auth.role}
            groupList={groupList}
            onSearchGroup={this.onSearchGroup}
            onOpenAddGroup={this.onOpenAddGroup}
            onStudentSearch={this.onStudentSearch}
            onLeaveGroup={this.onLeaveGroup}
            showSearchModal={this.showSearchModal}
            title={title}
            {...this.props}
          />
          <div className='group-content'>
            <div className='mobile'> 
              <ListItems
                srcData = { this.listItems(groupList) }
                avatar
                status = { groupList }
                groupList = { groupList }
                history = { this.props.history }
                isGroupIndex = { true }
                role = { auth.role }
                pagination = {{
                  simple: true,
                  defaultCurrent: 1,
                  position: 'both',
                  total : groupList ? groupList.totalCount : 0,
                  onChange: (pagination) => { this.onPaginate({ current: pagination }) }
                }}
              />
            </div>

            <div className='desktop'>
              { !searchWord ?
                <Table
                  scroll={{x:true}}
                  columns={this.columns}
                  dataSource={groupList}
                  pagination={{ simple: true, defaultCurrent: 1, position: 'both',total : groupList ? groupList.totalCount : 0, current: currentPage }}
                  onChange = {(pagination) => { this.onPaginate(pagination) }}
                  rowClassName={this.rowClassName}
                  data-tut-teacher-group = 'TeacherCreateGroupThird'
                  // eslint-disable-next-line no-unused-vars
                  onRow={(group, rowIndex) => {
                    return {
                      onClick: () => {
                        setTimeout(()=>{this.props.nextStepTour()},2000)
                        if((this.props.auth.role === 'student' && group.status === 'join' && group.deletedAt === null) || this.props.auth.role !== 'student'){
                          if(!hoverCodeExam) this.props.history.push(`/group/${group.id}`)
                        } 
                        return
                      },
                      onMouseEnter: () => {
                        this.setState({ indexRow: rowIndex })
                      },
                    }
                  }} 
                /> :
                <StudentSearchPage
                  searchWord={searchWord}
                  myGroupList={groupList}
                  myGroupForSearch = {this.state.dataForSearch}
                  history = {this.props.history}
                  otherGroupList={searchedGroupList}
                  // otherGroupList = {this.state.otherGroupSearched}
                  onRequestToJoin={this.onRequestToJoin}
                  onCancelRequest={this.onCancelRequest}
                  showSearchModal={this.showSearchModal}
                  onLeaveGroup={this.onLeaveGroup}
                />
              }
            </div>
          </div>
        </Spin>

        <ModalAddGroup
          isVisible={this.state.isShowAddGroupModal}
          onSubmit={this.onSubmitGroup}
          onCancel={this.onCloseModalAddGroup}
          group={this.state.editGroup} 
          isSeenTutorial={auth}
          nextStepTour={nextStepTour}
        />

        <ModalQRCode
          isVisible={this.state.isShowQrCodeModal}
          onCancel={this.onCloseQRCodeModal}
          onQrCodeGenerate={this.onQrCodeGenerate}
          imgQRCode={this.state.imgQRCode}
        />
        
        <Modal
          visible={this.state.visibleModalDelete}
          onCancel={this.handleCancel}
          footer={false}
        >
          <div style={{textAlign: 'center'}}>
            <Avatar src={GoupLeaveBigIcon ? GoupLeaveBigIcon : DefaultGroup} shape='square' size={150}/>
            <h2 className='text-medium' style={{fontSize: 20}}>ขอออกจากกลุ่ม</h2>
            <p style={{ fontWeight: '500px', fontSize: '28px' }}>{  this.state.groupForModal ? this.state.groupForModal.name : '' }</p>
            <p style={{ fontWeight: 'normal', fontSize: '16px' , color : '#3D3D3D', opacity : '0.5'}}>{  this.state.groupForModal ? this.state.groupForModal.code : '123' }</p>
            <p style={{color: '#4F4F4F', fontSize: 14}}>ต้องการออกจากกลุ่มนี้หรือไม่</p>
            <div className={styles.modalCreated} >
              <div className={styles.sub_modalCreated} ><Button onClick = {this.handleCancel} className = {styles.btnCancle}>ไม่ต้องการออก</Button></div>
              <div className={styles.sub_modalCreated_2} ><Button type = 'primary' onClick = {this.deleteStudent} className = {styles.btnLeave}>ออกจากกลุ่ม</Button></div>
              <div className={styles.sub_modalCreatedMobile} ><Button type = 'link' onClick = {this.handleCancel} className = {styles.btnCancleMobile}>ไม่ต้องการออก</Button></div>
            </div>
          </div>
        </Modal>
        <ModalAddExam group={groupSelectAddExam} onSubmit={this.handleAddExam} exams={examsWithoutGroup} isVisible={isVisibleAddExam} onCancel={this.closeModalAddExam}/>
        <SearchModal
          isSearchModal={this.state.isSearchModal}
          showSearchModal={this.showSearchModal}
          destroyOnClose={true}
          title='ค้นหากลุ่ม'
          placeholder='รหัสกลุ่ม'
          action={this.onStudentSearch}
          value = { searchWord }
        >
          {this.state.checkActionMobile ? 
            <Fragment>
              <div className = {styles.divResultMobile}>
                <span className = {styles.resultTxt}>
                  { `ผลการค้นหา "${this.state.searchWord}" ${this.state.myGroupSearched.length + this.state.otherGroupSearched.length} รายการ` }
                </span>
                <br/>
                <a className={styles.linkTxt} 
                  onClick={() => {
                    this.setState({ 
                      checkActionMobile : false, 
                      searchWord : ''
                    })}}>
                 เคลียร์การค้นหา
                </a>
              </div>
             
             
              <br/>
              <span className = {styles.titleMyExam}>กลุ่มของฉัน</span>
              <ListItems
                srcData = { this.listItems(this.state.myGroupSearched) }
                avatar
                status
                history={ this.props.history }
              /> 
              <br/>
              <span className = {styles.titleMyExam}>กลุ่มอื่น</span>
              <ListItems
                srcData={this.listItems(this.state.otherGroupSearched)}
                avatar
                status
                history={this.props.history}
              /> 
            </Fragment> 
            : ''} 

        </SearchModal>
        <Modal
          visible={this.state.visibleModalLeave}
          onCancel={this.handleCancel}
          footer={false}
        >
          <div style={{textAlign: 'center'}}>
            <Avatar src={GoupLeaveBigIcon01} shape='square' size={150}/>
            <h2 className='text-medium' style={{fontSize: 20}}>ยกเลิกคำขอเข้าร่วม</h2>
            <p style={{ fontWeight: '500px', fontSize: '28px' }}>{  this.state.groupForCanCel ? this.state.groupForCanCel.name : '' }</p>
            <p style={{ fontWeight: 'normal', fontSize: '16px' , color : '#3D3D3D', opacity : '0.5'}}>{  this.state.groupForCanCel ? this.state.groupForCanCel.code : '' }</p>
            <p style={{color: '#4F4F4F', fontSize: 14}}>ต้องการยกเลิกคำขอเข้าร่วมกลุ่มนี้หรือไม่</p>
            <div className={styles.modalCreated} >
              <div className={styles.sub_modalCreated} ><Button onClick = {this.handleCancel}>ไม่ยกเลิก</Button></div>
              <div className={styles.sub_modalCreated_2} ><Button type = 'primary' onClick = {this.onCancelRequest}>ยกเลิกคำขอ</Button></div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}



const mapStateToProps = (state, props) => {
  return {
    groupList: state.group.groupList,
    searchedGroupList: state.group.searchedGroupList,
    auth: state.auth.profile,
    exams: state.group.examsInGroup,
    group: state.group.groupList && state.group.groupList.find(group => group.id === props.match.params.id),
    lisOfStudentJustLeft : state.listofstudentleft
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createGroup,
      getGroupList,
      searchGroupList,
      editGroup,
      deleteGroup,
      studentRequestToJoinGroup,
      studentCancelRequestToJoinGroup,
      studentLeaveGroup,
      RemovedStudentInGroup,
      getExamsNotInGroup,
      addExams,
      getExamsInGroup,
      getListLetf,
      setListLetf
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Group)

