import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom' 
import UserAccountOverview from './UserAccountOverview.js'
import UserAccountDescription from './UserAccountDescription'
class ReportUserAccount extends Component {
  state = {
    isLoading: false
  }

  static defaultProps = {
    collection: []
  }

  componentDidMount () {
    this.loadStats()
  }
  spinOnLoading = () => { this.setState({ isLoading: true }) }
  spinOffLoading = () => { this.setState({ isLoading: false }) }
  loadStats = async() => {
    const { year } = this.props
    this.spinOnLoading()
    await this.props.loadStats('user', year)
    this.spinOffLoading()
  }
  loadDetail = async(year, month ) => {
    this.spinOnLoading() 
    let monthString = month < 10 ? `0${month}` : month 
    await this.props.loadStatsDetail('user', year, monthString)
    this.spinOffLoading()
  }
  getStateUserTransaction = async(year, month) => {
    this.spinOnLoading()
    const res = await this.props.getStateUserTransaction(year, month) 
    this.spinOffLoading()
    return res
  }

  render(){
    const { isLoading } = this.state 
    return(
      <div> 
        <Switch>
          <Route exact
            path='/reportaccount'
            render={() => <UserAccountOverview
              isLoading={isLoading}
              loadDetail={this.loadDetail}
              stateUserTransaction={this.getStateUserTransaction}
              spinOnLoading={this.spinOnLoading}
              spinOffLoading={this.spinOffLoading}
              {...this.props} />
            }
          />

          <Route exact
            path='/reportaccount/description'
            render={() => <UserAccountDescription
              isLoading={isLoading}
              loadDetail={this.loadDetail}
              stateUserTransaction={this.getStateUserTransaction}
              spinOnLoading={this.spinOnLoading}
              spinOffLoading={this.spinOffLoading}
              {...this.props} />
            }
          />
        </Switch>
      </div>)
  }
}

export default ReportUserAccount
