import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  Spin,
  Input,
  Collapse,
  message, 
  Select,
  Form,
  Button
} from 'antd'
import{
  IconSearch,
} from '../IconSVG'
import {
  GetProfileDetail,
} from '../../redux/auth/action'
import {
  onSentFaqToUs
} from '../../redux/contact/action'
import { CKEditor } from 'ckeditor4-react'
import styles from './FaqMember.module.scss'

import {
  GetFaqDetail,
} from '../../redux/master/action'
const {Panel} = Collapse
const { Option } = Select

class FaqMember extends Component {  
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errorLogin: null,
      faqDetail:'',
      dataForSearch : {},
      UserDetail: [],
      faqData : {},
      name : '',
      email : '',
      title : '',
      message : '',
      optionTilte : [
        {value : 'สมัครสมาชิก', text : 'สมัครสมาชิก', key:'1'},
        {value : 'เข้าสู่ระบบ', text : 'เข้าสู่ระบบ', key : '2'},
        {value : 'คำถามอื่นๆ', text : 'คำถามอื่นๆ', key : '3'}
      ],
      editor: null
    }
  }
  componentDidMount = async() =>{
    await this.fetchFaqDetail()
    await this.fetchGetUserDetail()
  }

  fetchFaqDetail = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.GetFaqDetail(this.props.auth.role)
    this.setState({ 
      isLoading: false,
      faqDetail : res,
      dataForSearch : res
    })
    if (res.error) message.error(res.error)
  }
  fetchGetUserDetail = async() => {
    const { GetProfileDetail } = this.props
    this.setState({ isLoading: true })
    const res = await GetProfileDetail()
    this.setState({ 
      name : this.props.auth ? `${this.props.auth.firstName} ${this.props.auth.lastName}` : '',
      email : res !== [] ? res.email : 'example@gmail.com',
      isLoading: false })
  }
  searchFaq=(value)=>{
    const {faqDetail,dataForSearch} = this.state
    let data = faqDetail
    if(data){
      data = dataForSearch.filter(faq => faq.question.toLowerCase().indexOf(value.toLowerCase()) > -1)
      this.setState({
        faqDetail : data
      })
    }
  }
  setText = (value,name)=> {
    const {faqData} = this.state
    faqData['name'] = this.state.name
    faqData['email'] = this.state.email
    faqData[name] = value
    this.setState({
      faqData
    })
  }
  onAdFaq = async (e) => {
    const { onSentFaqToUs } = this.props
    e.preventDefault()
    // if (!e.err) {
    //   const res = await this.props.onSentFaqToUs(this.state.faqData)
    //   if(!res.error){
    //     message.success('ส่งคำถามสำเร็จ')
    //     // this.props.backToHome()
    //   }else{
    //     message.error(res.error)
    //   }
    // }else{
    //   message.error(e.err)
    // }
    this.props.form.validateFieldsAndScroll(async(err, values) => {   
      if(!err){ 
        this.setState({ isLoading: true })
        await onSentFaqToUs(values) 
        this.setState({ isLoading: false })
        message.success('เพิ่มข้อมูลสำเร็จ')
        this.props.form.resetFields()
        if (this.state.editor) {
          this.state.editor.setData('')
        }
      }
    })
  }

  render() {
    const isAuthenticated = this.props.isAuthenticated
    const {faqDetail,optionTilte} = this.state
    const { form: { getFieldDecorator } } = this.props
    return (isAuthenticated ? 
      <div className = {styles.div_faq_container}>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          <div className = {styles.div_container}>
            <div className = {styles.title}>
              <label>FAQ - คำถามที่พบบ่อย</label>
            </div>
            <div className = {styles.div_search_bar}>
              <Input
                allowClear
                prefix={ 
                  <IconSearch style={{verticalAlign: 'middle'}}/>
                }
                placeholder='ค้นหาหัวข้อเพิ่มเติม'
                maxLength={100}
                className={styles.inputSearch} 
                onChange = {e => this.searchFaq(e.target.value)} 
                // onPressEnter={() => this.handleSearch(searchKey)} 
              />
            </div>
            <div className = {styles.div_title_type_question}
            >
              <p className = {styles.title_type_question}>คำถามเกี่ยวกับสมาชิก</p>
            </div>
            <div className = {styles.div_collapse_style}>
              {
                faqDetail ?
                  faqDetail.filter(faq => faq.title === 'การสมัครสมาชิก' || faq.title === 'สมัครสมาชิก').map((faq, index) => {
                    return(
                      <div key = {index}>
                        <Collapse bordered={false} defaultActiveKey={['0']} expandIconPosition={'right'} className = {styles.collapse_style}>
                          <Panel header={(<span className={ styles.text_title_faq }>{faq.question}</span>)} key ={index} className = {styles.collapse_panel_style}>
                            {
                              <div className = {styles.answer} dangerouslySetInnerHTML = {{__html: faq.answer }}/>
                            }
                          </Panel>
                        </Collapse>
                      </div>
                    )
                  })
                  : 
                  ''
              }
            </div>
            <div className = {styles.div_title_type_question}
              // suffix = {<IconAdd></IconAdd>}
            >
              <p className = {styles.title_type_question}>คำถามเกี่ยวกับการเข้าสู่ระบบ</p>
            </div>
            <div className = {styles.div_collapse_style}>
              {
                faqDetail ?
                  faqDetail.map((faq,index) => {
                    if(faq.title === 'การเข้าสู่ระบบ' || faq.title === 'เข้าสู่ระบบ' || faq.title === 'ข้อสอบ'){
                      return(
                        <div key = {index}>
                          <Collapse bordered={false} defaultActiveKey={['0']} expandIconPosition={'right'}>
                            <Panel header={(<span className={ styles.text_title_faq }>{faq.question}</span>)} key ={index} className = {styles.collapse_panel_style}>
                              {
                                <div className = {styles.answer} dangerouslySetInnerHTML = {{__html: faq.answer }}/>
                              }
                            </Panel>
                          </Collapse>
                        </div>
                      )
                    }
                  })
                  :
                  ''
              }
            </div>
          </div>
        </Spin>

        <div className = {styles.div_form} >
          <div className = {styles.contactForm}>
            <div style = {{textAlign : 'center',padding : '20px'}}>
              <label className = {styles.titleSendMsgToUs}>คำถามเพิ่มเติม</label>
            </div>
            {/* <Form >
              <label style = {{color : '#3D3D3D'}}>ชื่อ</label>
              <Input className ={styles.inputInContact}
                type=''
                placeholder= 'สมศักดิ์ ชูจิต'
                maxLength={50}
                autoComplete=''
                value = {this.state.name}
                onChange = {(e) => this.setText(e.target.value,'name')}
              />
              <br/>
              <label style = {{color : '#3D3D3D'}}>อีเมล</label>
              <Input className ={styles.inputInContact}
                type='email'
                placeholder= {this.state.UserDetail !== [] ? this.state.UserDetail.email : 'example@gmail.com'}
                maxLength={50}
                autoComplete='email'
                value = {this.state.email}
                onChange = {(e)=>{this.setText(e.target.value,'email')}}
              />
              <label style = {{color : '#3D3D3D'}}>หัวข้อคำถาม</label>
              <Select placeholder= 'กรุณาเลือก' 
                className ={styles.inputInContact}
                onChange = {(e)=> this.setText(e,'title')}
                // value = {optionTilte}
              >
                {                   
                  optionTilte.map((v, i) => {
                    return(
                      <Option value = {v.value} key = {i} >{v.text}</Option>  
                    )
                  })
                }
              </Select>
              <label style = {{color : '#3D3D3D'}}>ข้อความ</label>
              <CKEditor
                data=''
                config={{
                  toolbarGroups : [
                    { name: 'paragraph', groups: [ 'align', 'list', 'indent', 'blocks', 'bidi', 'paragraph' ] },
                    { name: 'insert', groups: [ 'insert' ] },
                    { name: 'document', groups: [ 'document', 'mode', 'doctools' ] },
                    { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                    { name: 'forms', groups: [ 'forms' ] },
                    { name: 'styles', groups: [ 'styles','Format', 'Font', 'FontSize' ] },
                    { name: 'tools', groups: [ 'tools' ] },
                    { name: 'others', groups: [ 'others' ] },
                    { name: 'about', groups: [ 'about' ] }
                  ],
                  removeButtons : 'Bold,Link,Source,Save,About,NewPage,Preview,Print,Templates,Maximize,ShowBlocks,Styles,Format,Font,FontSize,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Unlink,Anchor,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,CopyFormatting,RemoveFormat,Cut',
                  imageUploadUrl: 'https://exam.sobtid.me/admin/questions/image', 
                  uploadUrl: 'https://exam.sobtid.me/admin/questions/image?responseType=json', 
                  filebrowserUploadUrl: 'https://exam.sobtid.me/admin/questions/imagefile?responseType=json'
                }} 
                onChange = {e => this.setText(e.editor.getData(),'message')}
              />
              <div style = {{textAlign : 'center'}}>
                <Button type='primary' onClick = {this.onAdFaq} id='' style={{ width: '50%', marginTop: 20 }}>
                ส่งคำถามเพิ่มเติม
                </Button>
              </div>
            </Form> */}
            <Form onSubmit={this.onAdFaq} hideRequiredMark={true}>
              <Form.Item label='ชื่อ'>
                {
                  getFieldDecorator('name',{
                    rules: [
                      {
                        required: true,
                        min: 1,
                        message: 'กรุณากรอกชื่อ',
                      }
                    ],
                    initialValue: this.state.name
                  })(<Input className ={styles.inputInContact} disabled/>)
                }
              </Form.Item>
              <Form.Item label='อีเมล'>
                {
                  getFieldDecorator('email',{
                    rules: [
                      {
                        required: true,
                        min: 1,
                        type: 'email',
                        message: 'กรุณากรอกอีเมล',
                      }
                    ],
                    initialValue: this.state.email

                  }) (this.state.email ? (<Input className ={styles.inputInContact} disabled/>) : (<Input placeholder = 'อีเมล'/>))
                }
              </Form.Item>
              <Form.Item label='หัวข้อคำถาม'>
                {
                  getFieldDecorator('question',{
                    rules: [
                      {
                        required: true,
                        min: 1,
                        message: 'กรุณาเลือกหัวข้อคำถาม',
                      }
                    ]
                  })(<Select placeholder= 'กรุณาเลือก' 
                    className ={styles.inputInContact}
                    onChange = {(e)=> this.setText(e,'title')}
                    // value = {optionTilte}
                  >
                    {                   
                      optionTilte.map((v, i) => {
                        return(
                          <Option value = {v.value} key = {i} >{v.text}</Option>  
                        )
                      })
                    }
                  </Select>)
                }
              </Form.Item>
              <Form.Item label='ข้อความ'>
                {
                  getFieldDecorator('text',{
                    rules: [
                      {
                        required: true,
                        min: 1,
                        message: 'กรุณาระบุข้อความ',
                      }
                    ],
                    getValueFromEvent: function(e) {
                      return e.editor.getData()
                    }
                  })(<CKEditor
                    initData=''
                    config={{
                      toolbarGroups : [
                        { name: 'paragraph', groups: [ 'align', 'list', 'indent', 'blocks', 'bidi', 'paragraph' ] },
                        { name: 'insert', groups: [ 'insert' ] },
                        { name: 'document', groups: [ 'document', 'mode', 'doctools' ] },
                        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                        { name: 'forms', groups: [ 'forms' ] },
                        { name: 'styles', groups: [ 'styles','Format', 'Font', 'FontSize' ] },
                        { name: 'tools', groups: [ 'tools' ] },
                        { name: 'others', groups: [ 'others' ] },
                        { name: 'about', groups: [ 'about' ] }
                      ],
                      removeButtons : 'Bold,Link,Source,Save,About,NewPage,Preview,Print,Templates,Maximize,ShowBlocks,Styles,Format,Font,FontSize,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Unlink,Anchor,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,CopyFormatting,RemoveFormat,Cut',
                      imageUploadUrl: 'https://exam.sobtid.me/admin/questions/image', 
                      uploadUrl: 'https://exam.sobtid.me/admin/questions/image?responseType=json', 
                      filebrowserUploadUrl: 'https://exam.sobtid.me/admin/questions/imagefile?responseType=json',
                      editorUrl: 'https://cdn.ckeditor.com/4.17.2/full/ckeditor.js',
                      extraPlugins: 'justify, bidi, smiley, iframe, font, colorbutton, showblocks, find, selectall, pagebreak',
                      language: 'th'
                    }} 
                    onChange = {e => this.setText(e.editor.getData(),'message')}
                    onInstanceReady = {e => this.setState({ editor: e.editor })}
                  />)
                }
              </Form.Item>
              <div style = {{textAlign : 'center'}}>
                <Button htmlType='submit' type='primary'  id='' style={{ width: '50%', marginTop: 20 }}>
                ส่งคำถาม
                </Button>
              </div>
            </Form>
          </div>
       
        </div>
      </div>
      :
      <Redirect to='/examset' />
    )
  }
}

const mapStateToProps = state => ({
  // Redirect after login
  isAuthenticated: state.auth.token ? true : false,
  auth: state.auth.profile,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetFaqDetail,
      GetProfileDetail,
      onSentFaqToUs
    },
    dispatch
  )
const WrappedFormDataSchool = Form.create({ name: 'Add_Form_Province' })(FaqMember)
export default connect(mapStateToProps, mapDispatchToProps)(WrappedFormDataSchool)