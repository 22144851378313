import React, { Component, Fragment }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import AvatarBase64 from '../Register/AvatarBase64'
import { AxiosRequest } from '../../redux/fetch'
import { generateSelectForm, generateInputForm, generateSelectFormInitialValue, generateAutoCompleteForm } from '../../util/formGenerator'
import { removeUndefinedProp, formatSchoolObject } from '../../util/helper'
import DefaultProfile from '../IconSVG/DefaultProfile.svg'
import styles from './Profile.module.scss'
import {
  message,
  Spin,
  Row,
  Col,
  Button,
  Divider,
  Form,
  Checkbox,
} from 'antd'
import {
  GetMasterData,
  onSchoolBySearch
} from '../../redux/master/action'
import { getSearchSchoolById } from '../../redux/school/action'
import {
  GetProfileDetail,
  GetProfile
} from '../../redux/auth/action'
import ModalPrivacyPolicy from './ModalPrivacyPolicy'
import ModalTermCondition from '../Register/ModalTermCondition'
import { autoType } from 'd3'
const API_EDIT_PROFILE = '/auth/profile'
const API_SCHOOL_DETAIL = '/master/schools'
const API_EDIT_PRIVACYPOLICY = '/auth/privacypolicy'

const ROLE_TEACHER = 'teacher'
const ROLE_STUDENT = 'student'

const formItemLayout = {
  colon: false,
  labelAlign: 'left',
  hideRequiredMark: true,
  labelCol: {
    md: { span: 10 },
    xs: { span: 24 },
  },
  wrapperCol: {
    md: { span: 14 },
    xs: { span: 24 },
  },
}

const regExpStrongPassword = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/
 

const AvatarResponsive = (props) => {
  // useMediaQuery can use in functional component only
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })
  return (<div className={`${styles.avatar}`}>
    {
      props.profileImage ?
        <AvatarBase64
          onChange={ props.onAvatarChange }
          imageDefault={ props.profileImage }
          size={ isTabletOrMobile ? 'small' : 'large' }
          check={true}
        /> : 
        <AvatarBase64
          onChange={ props.onAvatarChange }
          imageDefault={ DefaultProfile }
          size={ isTabletOrMobile ? 'small' : 'large' }
          check={true}
        />
    }
    {/* <AvatarBase64
      onChange={ props.onAvatarChange }
      imageDefault={ props.profileImage }
      size={ isTabletOrMobile ? 'small' : 'large' }
    /> */}
  </div>)
}

class Profile extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isFormDirty: false,
      profileImage: null,
      newSchoolDetail: null,
      UserDetail: [],
      schoolsState: [],
      isSearchSchool: false,
      birthYear: -1,
      showParentFields: false,
      showModalPrivacyPolicy: false,
    }
  }

  componentDidMount = () => {
    this.props.GetMasterData()
    this.fetchGetUserDetail()
    this.setState({ schoolsState: this.props.schools })
  }
  fetchGetUserDetail = async() => {
    const { GetProfileDetail } = this.props
    this.setState({ isLoading: true })
    const res = await GetProfileDetail()
    // if (!res.hasOwnProperty('recommendation')) {
    //   res.recommendation = false
    // }
    // console.log("*** GetUserDetail ***")
    // console.log(res)
    // console.log("*** GetUserDetail ***")
    const today = new Date()
    const thisBEYear = today.getFullYear() + 543
    const age = thisBEYear - res.birthYear
    const showParentFields = (age <= 10)
    this.setState({ 
      UserDetail: res, 
      isLoading: false,
      birthYear: res.birthYear == -1 ? null : res.birthYear,
      showParentFields
    })
  }
  onAvatarChange = (imageBase64) => {
    this.setState({ profileImage: imageBase64 })
  }

  matchPassword = (rule, value, callback) => {
    if ( value && value !== this.props.form.getFieldValue('password') ) {
      callback('รหัสผ่านยืนยันไม่ตรงกัน')
    }
    callback()
  }

  onCancel = () => {
    this.props.history.goBack()
  }
  onSelectSchool = async(schoolId) => { 
    await this.onUpdateSchoolDetail(schoolId) 
    this.setState({ 
      isFormDirty: false,
      isSearchSchool: false
    })
  }
  onSchoolChange = async(schoolName) => {   
    const res = await this.props.onSchoolBySearch(schoolName)  
    // const existedSchool = schoolsState.find( (school) => schoolName === school.name )
    // const existedSchool = schools.find( (school) => schoolName === school.name )
    // await this.onUpdateSchoolDetail(res.items.length > 0 && res.items[0]._id)
    const isNewSchool = res.items.length > 0 ? false : true
    this.setState({
      isNewSchool,
      isFormDirty: false,
      isSearchSchool: false
    })
  }
  onSchoolChangeId = async(schoolId) => {  
    const existedSchool = await this.props.getSearchSchoolById(schoolId,1)   
    await this.onUpdateSchoolDetail(existedSchool && existedSchool._id) 
    const isNewSchool = existedSchool ? false : true
    this.setState({
      isNewSchool,
      isFormDirty: false, 
      currentSchoolDetail: existedSchool
    }) 
  }
  searchSchool = _.debounce(async(text) => { 
    const { onSchoolBySearch } = this.props 
    const res = await onSchoolBySearch(text)  
    this.setState({ schoolsState: res.items, isSearchSchool: true })
    return res.items
  },500)
  
  onUpdateSchoolDetail = async (schoolId) => {
    // clear school detail on new school 
    if (!schoolId) {
      this.props.form.setFieldsValue({ addressNo: null, villageNo: null, lane: null, road: null, subDistrict: null, district: null, province: null, postalCode: null, department: null })
      return this.setState({newSchoolDetail: null})
    }

    this.setState({ isLoading: true })
    const res = await AxiosRequest(API_SCHOOL_DETAIL, 'get', schoolId)
    this.setState({ isLoading: false })
    if (res.error) {
      this.setState({
        newSchoolDetail: null,
      })
      return message.error('ไม่สามารถรับข้อมูลโรงเรียนได้')
    }
    this.setState({
      newSchoolDetail: res,
    }) 
    const { name, addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department } = res
    this.props.form.setFieldsValue({ schoolName: name, addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department })
  }

  onSubmitEditProfile = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll( async(err, values) => { 
      this.setState({ isFormDirty: true })
      console.log("*** onSubmitEditProfile ***")
      console.log(values)
      console.log("*** onSubmitEditProfile ***")
      if (!err) {
        let passObj = null
        if (values.old_password && values.password) {
          passObj = {
            old: values.old_password,
            new: values.password
          }
          delete values.old_password
          delete values.password
        }
        const { schoolName, addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department } = values
        const schoolData = {
          schoolName, addressNo, villageNo, lane, road, subDistrict, district, province, postalCode, department
        }
        const params = {
          email: values.email ? values.email : '',
          prefixName: values.prefixName,
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
          profileImage: this.state.profileImage,
          password: passObj,
          school: formatSchoolObject(schoolData, this.state.newSchoolDetail),
          recommendation: values.recommendation,
          // dev/ots2023
          birthYear: values.birthYear,
          parent: {
            parentPrefix: values.parentPrefix,
            parentFirstName: values.parentFirstName,
            parentLastName: values.parentLastName,
            parentEmail: values.parentEmail,
            parentTelephone: values.parentTelephone,
            relation: values.relation,
          }
        }
        removeUndefinedProp(params)
        this.updateProfile(params)

      }
    })
  }

  updateProfile = async (params) => {
    this.setState({ isLoading: true })
    const res = await AxiosRequest(API_EDIT_PROFILE, 'patch', params)
    this.setState({ isLoading: false })
    if (res.error) {
      return message.error('ไม่สามารถแก้ไขข้อมูลส่วนตัวได้')
    }
    await this.props.GetProfile()
    message.success('แก้ไขข้อมูลส่วนตัวสำเร็จ')
    const { hasSeenPrivacyPolicy } = this.state.UserDetail
    this.setState({
      showModalPrivacyPolicy: !hasSeenPrivacyPolicy
    })
  }

  onSelectBirthYear = (value) => {
    const today = new Date()
    const thisBEYear = today.getFullYear() + 543
    const age = thisBEYear - value
    // console.log("*** AGE ***")
    // console.log(age)
    // console.log("*** AGE ***")
    const showParentFields = (age <= 10)
    this.setState({
      birthYear: value,
      showParentFields
    })
  }

  getPrefixesSpecificRole = () => {
    const { prefixes } = this.props  
    const role = "teacher"
    return prefixes.filter(prefixes => prefixes.visible[role])
  }

  getRelations = () => {
    const { relations } = this.props
    return relations.filter(relation => relation.visible)
  }

  setPrivacyPolicy = (privacyPolicy) => {  
    this.setState({ privacyPolicy: privacyPolicy.target.checked })
  }

  onCancelModalPrivacyPolicy = () => {
    this.setState({
      showModalPrivacyPolicy: false
    })
  }

  onSaveModalPrivacyPolicy = async () => {
    // console.log(this.state.privacyPolicy)
    const values = {privacyPolicy: this.state.privacyPolicy}
    this.setState({ isLoading: true })
    const res = await AxiosRequest(API_EDIT_PRIVACYPOLICY, 'patch', values)
    this.setState({ isLoading: false })
    if (res.error) {
      return message.error('ไม่สามารถบันทึกการยอมรับนโยบายคุ้มครองข้อมูลส่วนบุคคลได้')
    }
    await this.props.GetProfile()
    message.success('บันทึกการยอมรับนโยบายคุ้มครองข้อมูลส่วนบุคคลสำเร็จ')
    this.setState({
      showModalPrivacyPolicy: false
    })
    this.props.history.push('/examset')
  }

  render() {
    const { form, profile, prefixes, provinces, schools, departments } = this.props 
    const { getFieldDecorator } = form
    const { isFormDirty, UserDetail, schoolsState, isSearchSchool, showParentFields } = this.state
    const breadcumb = (
      <div className='flex-space-between'>
        <div>
          {/* <Link style={{color: '#006FAD', margin: '0'}} to={'/profile'}>{'ตั้งค่า >'}</Link> */}
          <span className={ styles.header_text }> ข้อมูลผู้ใช้งาน</span>
        </div>
      </div>
    )

    let { role, prefixName, hasSeenPrivacyPolicy } = profile
    const isTeacher = role === ROLE_TEACHER 
    const isStudent = role === ROLE_STUDENT 
    const selectPrefix = generateSelectFormInitialValue({ options: prefixes, fieldName: 'prefixName', form, label: 'คำนำหน้า', isFormDirty, isRequire: true, initialValue: prefixName, nameAsValue: true })
    const inputFirstName = generateInputForm({ fieldName: 'firstName', form, label: 'ชื่อ', isFormDirty, isRequire: true, initialValue: UserDetail.firstName })
    const inputLastName = generateInputForm({ fieldName: 'lastName', form, label: 'นามสกุล', isFormDirty, isRequire: true, initialValue: UserDetail.lastName })
  
    const inputEmail = this.state.UserDetail && this.state.UserDetail.email ? generateInputForm({ fieldName: 'emailForEdit', form, label: 'อีเมล', isFormDirty, isRequire: false, initialValue: this.state.UserDetail ? this.state.UserDetail.email : '', disabled : role !== 'admin' ? true : false, role
      , customRules: [
        { required: isTeacher, message: 'กรุณาใส่อีเมล' },
        { type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' }
      ]})
      : null
    const inputUsername = this.state.UserDetail && this.state.UserDetail.username ? generateInputForm({ fieldName: 'inputForEdit', form, label: 'ชื่อผู้ใช้งาน', isFormDirty, isRequire: false, initialValue: this.state.UserDetail.username ? this.state.UserDetail.username : '', disabled : role !== 'admin' ? true : false, role}) : null
  
    // const selectGender = isTeacher ? generateSelectForm({ options: genders, fieldName: 'gender', form, label: 'เพศ', isFormDirty, isRequire: isRequiredForTeacher, initialValue: gender, nameAsValue: true, freeInput: false, placeholder: 'กรุณาระบุ' }) : null
    const inputOldPassword = generateInputForm({ fieldName: 'old_password', form, label: 'รหัสเก่า', isFormDirty, limit: 20, customRules: [
    ], props: {
      type: 'password',
      autoComplete: 'new-password',
    } })
    const inputPassword = generateInputForm({ fieldName: 'password', form, label: 'รหัสใหม่', isFormDirty, limit: 20, customRules: [
      { min: 6, message: 'มีอย่างน้อย 6 ตัว' },
      { pattern: regExpStrongPassword, message: 'ประกอบด้วยตัวอักษรภาษาอังกฤษและตัวเลข' },
    ], props: {
      type: 'password',
      autoComplete: 'new-password',
    } })
    const inputConfirmPassword = generateInputForm({ fieldName: 'confirm_password', form, label: 'ยืนยันรหัสผ่าน', isFormDirty, limit: 20, customRules: [
      { validator: this.matchPassword }
    ], props: {
      type: 'password',
      autoComplete: 'new-password',
    } })

    let initSchool = {}
    if (UserDetail.school) {
      // set initial school value from profile
      initSchool = {
        name          : UserDetail.school.name.text,
        addressNo     : UserDetail.school.addressNo ? UserDetail.school.addressNo.text : '',
        villageNo     : UserDetail.school.villageNo ? UserDetail.school.villageNo.text : '',
        lane          : UserDetail.school.lane ? UserDetail.school.lane.text : '',
        road          : UserDetail.school.road ? UserDetail.school.road.text : '',
        subDistrict   : UserDetail.school.subDistrict ? UserDetail.school.subDistrict.text : '',
        district      : UserDetail.school.district ? UserDetail.school.district.text : '',
        province      : UserDetail.school.province.id,
        postalCode    : UserDetail.school.postalCode ? UserDetail.school.postalCode.text : '',
        department    : UserDetail.school.department ? UserDetail.school.department.text : '',
      }
    } 
    const selectSchoolName = generateAutoCompleteForm({ options: isSearchSchool ? schoolsState : schools, fieldName: 'schoolName', form, label: isTeacher ? 'โรงเรียน/หน่วยงาน' : 'โรงเรียน', isFormDirty, isRequire: true, initialValue: initSchool.name, nameAsValue: true, freeInput: true , handleSelectChange: this.onSchoolChange, searchSchool: this.searchSchool,onSelectSchool: this.onSelectSchool, onSchoolChangeId: this.onSchoolChangeId })
    const inputAddressNo = generateInputForm({ fieldName: 'addressNo', form, label: 'เลขที่', isFormDirty, isRequire: false, initialValue: initSchool.addressNo, limit: 10 })
    const inputVillageNo = generateInputForm({ fieldName: 'villageNo', form, label: 'หมู่ที่', isFormDirty, isRequire: false, initialValue: initSchool.villageNo, limit: 10 })
    const inputLane = generateInputForm({ fieldName: 'lane', form, label: 'ซอย', isFormDirty, isRequire: false, initialValue: initSchool.lane})
    const inputRoad = generateInputForm({ fieldName: 'road', form, label: 'ถนน', isFormDirty, isRequire: false, initialValue: initSchool.road })
    const inputSubDistrict = generateInputForm({ fieldName: 'subDistrict', form, label: 'เขต/อำเภอ', isFormDirty, isRequire: false, initialValue: initSchool.subDistrict })
    const inputDistrict = generateInputForm({ fieldName: 'district', form, label: 'แขวง/ตำบล', isFormDirty, isRequire: false, initialValue: initSchool.district })  

    
    let provinceProfile = provinces.find( p => { if(p.id === initSchool.province) return p.name }) 
    // const selectProvince = generateSelectForm({ options: provinces, fieldName: 'province', form, label: 'จังหวัด', isFormDirty, isRequire: true, initialValue: initSchool.province , nameAsValue: false, freeInput: true, placeholder: 'กรุณาเลือก' })
    
    const selectProvince = generateSelectFormInitialValue({ options: provinces, fieldName: 'province', form, label: 'จังหวัด', isFormDirty, isRequire: false, initialValue: provinceProfile ? provinceProfile : '', nameAsValue: false, freeInput: false, placeholder: 'กรุณาเลือก' })
    
    const inputPostalCode = generateInputForm({ fieldName: 'postalCode', form, label: 'รหัสไปรษณีย์', isFormDirty, isRequire: false, initialValue: initSchool.postalCode, customRules: [
      { required: false, pattern: /^[0-9]{5}$/ , message: 'กรุณาใส่รหัสไปรษณีย์ให้ครบ 5 หลัก' } ]})

    const selectDepartment = generateSelectFormInitialValue({ options: departments, fieldName: 'department', form, label: 'สังกัด', isFormDirty, isRequire: false, initialValue: initSchool.department, nameAsValue: true, freeInput: false, placeholder: 'กรุณาเลือก' })

    const schoolFields = profile.role === 'student' ? [selectSchoolName,selectProvince] : [
      selectSchoolName,
      inputAddressNo,
      inputVillageNo,
      inputLane,
      inputRoad,
      inputDistrict,
      inputSubDistrict,
      selectProvince,
      inputPostalCode,
      selectDepartment,
    ]

    // dev/ots2023
    const today = new Date()
    const thisBEYear = today.getFullYear() + 543
    const maxAge = 70
    const birthYearOptions = [...Array(maxAge).keys()]
      .map(y => (-1)*y + thisBEYear)
      .map(y => ({ id: y, name: y }))
    const { birthYear } = this.state
    const selectBirthYear = generateSelectForm({ options: birthYearOptions, 
      fieldName: 'birthYear', form, label: 'ปี พ.ศ. เกิด',
      isFormDirty, isRequire: true, initialValue: null,
      nameAsValue: false, freeInput: false, placeholder: 'กรุณาระบุปี พ.ศ. เกิด',
      isRegister: false, handleSelectChange: this.onSelectBirthYear,
      isEmph: !hasSeenPrivacyPolicy, initialValue: birthYear,
    })

    const parentPrefix = UserDetail.parent ? UserDetail.parent.parentPrefix : null
    const parentFirstName = UserDetail.parent ? UserDetail.parent.parentFirstName : null
    const parentLastName = UserDetail.parent ? UserDetail.parent.parentLastName : null
    const relation = UserDetail.parent ? UserDetail.parent.relation : null
    const parentEmail = UserDetail.parent ? UserDetail.parent.parentEmail : null
    const parentTelephone = UserDetail.parent ? UserDetail.parent.parentTelephone : null

    const selectParentPrefix = generateSelectForm({ options: this.getPrefixesSpecificRole(), fieldName: 'parentPrefix', form, label: 'คำนำหน้า', isFormDirty, isRequire: showParentFields, initialValue: null, nameAsValue: true, isRegister:false, placeholder:'เลือกคำนำหน้า', initialValue: parentPrefix })
    const inputParentFirstName = generateInputForm({ fieldName: 'parentFirstName', form, label: 'ชื่อ', isFormDirty, isRequire: showParentFields, isRegister: false, initialValue: parentFirstName })
    const inputParentLastName = generateInputForm({ fieldName: 'parentLastName', form, label: 'นามสกุล', isFormDirty, isRequire: showParentFields, isRegister: false, initialValue: parentLastName })
    const selectRelation = generateSelectForm({ options: this.getRelations(),
      fieldName: 'relation', form, label: 'ความสัมพันธ์', isFormDirty,
      isRequire: showParentFields, initialValue: relation, nameAsValue: true, isRegister: false,
      placeholder: 'เลือกความสัมพันธ์' })

    const inputParentEmail = generateInputForm({ fieldName: 'parentEmail', form, label: 
      'อีเมล',isRegister: false, isFormDirty, limit: 254, initialValue: parentEmail,
      customRules: [
        { required: showParentFields, message: 'กรุณาใส่อีเมล' },
        { type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' },
        // { validator: async (_, value) => {
        //   if (value != email) {
        //     return Promise.resolve()
        //   }
        //   else {
        //     return Promise.reject(new Error('อีเมลของผู้ปกครองต้องไม่ซ้ำกับอีเมลของนักเรียน')) 
        //   }
        // }}
      ]
    })

    const inputParentTelephone = generateInputForm({
      fieldName: 'parentTelephone', form, label: 'โทรศัพท์บ้าน/มือถือ', 
      isRegister: true, isFormDirty, limit: 10, isRequire: false, 
      initialValue: parentTelephone,
      customRules: [
        // { required: false, message: 'กรุณาใส่หมายเลขโทรศัพท์' },
        { regexp: /0\d+/, min: 9, max: 10, message: 'กรุณาระบุเฉพาะหมายเลขโทรศัพท์ โดยไม่ต้องใส่ขีด'}
      ],
      props: {
        type: 'number'
      }
    })


    const { dataPrivacy, termAndCondition } = this.props
    
    return (
      <Fragment>
      <div className={styles.profileDiv}>
        {breadcumb}
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading || !profile} >
          <Form {...formItemLayout} onSubmit={this.onSubmitEditProfile} >
            { profile
              ? (<Row gutter={30}>
                <Col sm={{span:24}}>
                  <div className={styles.avatar}>
                    <p className={`${styles.formHead} text-medium mobile`}>ทั่วไป</p>
                    <p className={`${styles.hideOnDesktop} text-thin`}>รูปโปรไฟล์</p>
                    <AvatarResponsive
                      onAvatarChange={ this.onAvatarChange }
                      profileImage={ profile.profileImage }
                      // profileImage={ profileImage }
                    />
                  </div>
                </Col>
                {/* <Col md={{span:12}} sm={{span:24}}> */}
                <Col xl={{span:9}} lg={{span:12}} sm={{span:24}}>
                  <p className={`${styles.formHead} text-medium desktop`}>ทั่วไป</p>
                  { selectPrefix }
                  { inputFirstName }
                  { inputLastName }
                  {/* { selectGender } */}
                  {/* <p className={`${styles.formHead} text-medium`}>อีเมล</p> */}
                  { inputEmail } 
                  {
                    UserDetail.username
                      ? inputUsername
                      : ''
                  }
                  { isStudent && selectBirthYear }
                  <p className={`${styles.formHead} text-medium`}>รหัสผ่าน</p>
                  { inputOldPassword }
                  { inputPassword }
                  { inputConfirmPassword }
                </Col>
                {/* <Col md={{span:12}} sm={{span:24}}> */}
                <Col xl={{span:9, offset:2}} lg={{span:12}} sm={{span:24}}>
                  <p className={`${styles.formHead} text-medium`}>โรงเรียนและหน่วยงานที่สังกัด</p>
                  { schoolFields }
                  {showParentFields &&
                    <Fragment>
                    <p className={`${styles.formHead} text-medium`}>ผู้ปกครอง</p>
                    {!hasSeenPrivacyPolicy &&
                      <p>เนื่องจากท่านมีอายุไม่เกิน 10 ปี จึงจำเป็นจะต้องได้รับความยินยอมจากผู้ปกครอง
                        กรุณาให้ผู้ปกครองกรอกข้อมูลเพิ่มเติมด้านล่าง</p>
                    }
                    { selectParentPrefix }
                    { inputParentFirstName }
                    { inputParentLastName }
                    { selectRelation }
                    { inputParentEmail }
                    { inputParentTelephone }
                    </Fragment>
                  }
                </Col>

              </Row>
              )
              : null }
            <div className={styles.flex_space_footer}>
              <Form.Item key="recommendation" >
                { getFieldDecorator('recommendation', {
                  rules: [],
                  validateTrigger: false,
                  initialValue: UserDetail.recommendation,
                  valuePropName: "checked",
                })(
                <Checkbox>
                  ยินยอมให้ใช้ข้อมูลการทำข้อสอบ/แบบฝึกหัดเพื่อแนะนำเนื้อหาที่เหมาะสม
                </Checkbox>)}
              </Form.Item>
              <Divider/>
              <div className='flex-space-between '>
                <Button type='link' className={`${styles.btn_cancel} text_underline desktop`} onClick={this.onCancel}>ยกเลิก</Button>
                <Button type='primary' htmlType='submit' className={styles.btn_ok}>บันทึก</Button>
                <Button type='link' className={`${styles.btn_cancel} text_underline mobile`} onClick={this.onCancel}>ยกเลิก</Button> 
              </div>
            </div>
          </Form>
        </Spin>
      </div>
      { profile.role != 'admin' && (profile.isSeenTermAndCondition ? 
      <ModalPrivacyPolicy
        key='ModalPrivacyPolicy'
        isVisible={this.state.showModalPrivacyPolicy}
        onOK={this.onSaveModalPrivacyPolicy}
        onCancel={this.onCancelModalPrivacyPolicy}
        role={role}
        dataPrivacy={dataPrivacy}
        setPrivacyPolicy={this.setPrivacyPolicy}
        fromaddgroup={false}
      />
      :
      <ModalTermCondition
        key='ModalTermCondition'
        isVisible={this.state.showModalPrivacyPolicy}
        onOK={this.onSaveModalPrivacyPolicy}
        onCancel={this.onCancelModalPrivacyPolicy}
        role={role}
        termAndCondition={termAndCondition}
        dataPrivacy={dataPrivacy}
        setPrivacyPolicy = { this.setPrivacyPolicy }
        fromaddgroup = {false}
        saveButtonLabel={profile.isImportJoin && !profile.isSeenTermAndCondition ? 'เริ่มใช้งาน' : 'บันทึก'}
      />
      )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const masterPrefixes = state.master.prefixes
  let prefixes = []
  let checkRole = state.auth.profile.role === 'student' ? true : false
  if (state.auth.profile){
    prefixes = checkRole ? masterPrefixes.filter(prefix => prefix.visible.student) : masterPrefixes.filter(prefix => prefix.visible.teacher)
  } 
  return {
    prefixes: prefixes,
    profile: state.auth.profile,
    provinces: state.master.provinces,
    schools: state.master.schools,
    departments: state.master.departments,
    relations: state.master.relations,
    dataPrivacy: state.master.dataPrivacy,
    termAndCondition: state.master.termAndCondition,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetMasterData,
      GetProfileDetail,
      GetProfile,
      onSchoolBySearch,
      getSearchSchoolById
    },
    dispatch
  )

const WrappedFormEditProfile = Form.create({ name: 'form_edit_profile' })(Profile)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedFormEditProfile))
