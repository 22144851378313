import React, { Component }  from 'react' 

import { isInArray } from '../../../util/helper' 

import { TeacherSearchGroupInput, StudentSearchGroupInput } from './Search' 
import styles from './GroupHeader.module.scss'
class ExamHeader extends Component {
  static defaultProps = {
    title: ''
  }

  render () {
    const { title, role , onLeaveGroup } = this.props
    return (
      <div className={`flex-space-between ${styles.groupHeader}`} >
        <h1>{title}</h1>

        {isInArray(['teacher', 'superTeacher', 'admin'], role) ?
          <TeacherSearchGroupInput onSearchGroup={this.props.onSearchGroup}
            onLeaveGroup={onLeaveGroup}
            nextStepTour={this.props.nextStepTour}
            isSeenTutorial = {this.props.isSeenTutorial}
            onOpenAddGroup={this.props.onOpenAddGroup}
            showSearchModal={this.props.showSearchModal} 
          />
          : <StudentSearchGroupInput 
            onSearchGroup={this.props.onStudentSearch}
            onSearchMyGroup={this.props.onSearchGroup}
            onLeaveGroup={onLeaveGroup}
            showSearchModal={this.props.showSearchModal}
          />
        }
      </div>
    )
  }
}

export default ExamHeader
