import axios from 'axios'
import { BASE_URL ,FetchCycle, AxiosRequest } from '../fetch'

const extractItem = (arr) => {
  try {
    return arr.items.map( t => ({ id: t._id, name: t.name !== undefined ? t.name : (t.text || null), visible: t.visible !== undefined ? t.visible : null }) )
  } catch (e) {
    return []
  }
}

export const SET_MASTER_DATA         = 'master/SET_MASTER_DATA'
export const SET_CONTACT_DETAIL         = 'master/SET_CONTACT_DETAIL'
export const SET_SERVER_STATUS        = 'master/SET_SERVER_STATUS'

// API =====================================================================

const API_GET_PREFIX      = `${BASE_URL}/master/prefixes`
const API_GET_PROVINCE    = `${BASE_URL}/master/provinces?limit=100`
const API_GET_SCHOOL      = `${BASE_URL}/master/schools?limit=100`
const API_GET_DEPARTMENT  = `${BASE_URL}/master/departments`
const API_GET_RELATION    = `${BASE_URL}/master/relations`

const API_GET_CONTACT_DETAIL = '/master/contact'
const API_GET_FAQ_DETAIL = '/master/faq'
const API_GET_TERM_AND_CONDITION = '/master/term'
const API_GET_DATA_PRIVACY = '/master/data-privacy'

const API_GET_TERM = '/master/term'
const API_GET_DATA_POLICY = '/master/data-privacy'

const API_SERVER = '/master/server'
// Fetch =====================================================================

const extractItemFromVisibleRole = (items, role) => {
  return items
    .filter(item => item.visible.some(vis => vis.role === role))
    .sort((a, b) => {
      return a.visible.find(vis => vis.role === role).order - b.visible.find(vis => vis.role === role).order
    })
}

const getContactDetail = () => () => AxiosRequest(API_GET_CONTACT_DETAIL,'get')
const editContactDetail = (newContactDetail) => () => AxiosRequest(API_GET_CONTACT_DETAIL,'patch',newContactDetail)
const fetchTermAndCondition = () => () => AxiosRequest(API_GET_TERM_AND_CONDITION,'get')
const fetchDataPrivacy = () => () => AxiosRequest(API_GET_DATA_PRIVACY,'get')

const getFaqList = () => () => AxiosRequest(API_GET_FAQ_DETAIL, 'get').then(res => {
  return {
    guest: extractItemFromVisibleRole(res, 'guest'),
    student: extractItemFromVisibleRole(res, 'student'),
    teacher: extractItemFromVisibleRole(res, 'teacher')
  }
})
const getFaqDetail = (role) => () => AxiosRequest(API_GET_FAQ_DETAIL,'get').then(res => res.sort((a, b) => {
  if (role != 'guest' || role != 'teacher' || role != 'student') {
    return 0
  }
  return a.visible.find(vis => vis.role === role).order - b.visible.find(vis => vis.role === role).order
}))
const addFaqDetail = (data) => () => AxiosRequest(API_GET_FAQ_DETAIL,'post',data)
const removeFaq   = (faqId)      => () => AxiosRequest(`${API_GET_FAQ_DETAIL}/${faqId}`,'delete')
const editFaqDetail = (faqId,data) => () => AxiosRequest(`${API_GET_FAQ_DETAIL}/${faqId}`,'patch',data)
const updateFaqs = (data) => () => AxiosRequest(API_GET_FAQ_DETAIL,'patch', data)

const getTerm = () => () => AxiosRequest(API_GET_TERM,'get')
const setTerm  = (data) => () => AxiosRequest(API_GET_TERM,'patch',data)

const fetchDatapolicy  = () => ()=> AxiosRequest(API_GET_DATA_POLICY,'get')
const patchDatapolicy  = (dataPolicy) => () => AxiosRequest(API_GET_DATA_POLICY,'patch',dataPolicy)

const getSchoolBySearch = (search) => () => AxiosRequest(`${API_GET_SCHOOL}&search[name]=${search}`,'get')

const getServerConfigApi = () => () => AxiosRequest(`${API_SERVER}`, 'get')
const updateServerConfigApi = (data) => () => AxiosRequest(`${API_SERVER}`, 'patch', data)
// Action Callback =====================================================================

const setServerStatus = (data) => ({ type: SET_SERVER_STATUS, data })


// Action =====================================================================
export const GetMasterData = () => {
  return async (dispatch) => {
    try {
      const masterData = await Promise.all([
        axios.get(API_GET_PREFIX),
        axios.get(API_GET_PROVINCE),
        axios.get(API_GET_SCHOOL),
        axios.get(API_GET_DEPARTMENT),
        axios.get(API_GET_TERM_AND_CONDITION),
        axios.get(API_GET_DATA_PRIVACY),
        axios.get(API_GET_RELATION),
      ])

      const data = {
        prefixes: extractItem(masterData[0].data),
        provinces: extractItem(masterData[1].data),
        // schools: extractItem(masterData[2].data),
        schools: masterData[2].data.items,
        departments: extractItem(masterData[3].data),
        termAndCondition:  masterData[4].data,
        dataPrivacy:  masterData[5].data,
        relations: extractItem(masterData[6].data)
      }

      dispatch({ 
        type: SET_MASTER_DATA, 
        data
      })
    } catch (e) {
      return null
    }
  }
}

export const GetContactDetail = () => FetchCycle(getContactDetail())
export const FetchToEditContactDetail = (data) => FetchCycle(editContactDetail(data))
export const GetTermAndCondition = () => FetchCycle(fetchTermAndCondition())
export const GetDataPrivacy = () => FetchCycle(fetchDataPrivacy())

export const GetFaqList = () => FetchCycle(getFaqList())
export const GetFaqDetail = (role = 'guest') => FetchCycle(getFaqDetail(role))
export const AddFaq = data => FetchCycle(addFaqDetail(data))
export const onRemoveFaq   = (faqId)      => FetchCycle(removeFaq(faqId))
export const onEditFaq   = (faqId,data)    => FetchCycle(editFaqDetail(faqId,data))
export const onUpdateFaq   = (data)    => FetchCycle(updateFaqs(data))
export const getTersmAndConditions = () => FetchCycle(getTerm()) 
export const setTersmAndConditions = (data) => FetchCycle(setTerm(data)) 

export const getDataPolicyInMaster = () => FetchCycle(fetchDatapolicy())
export const setDataPolicyInMaster = (dataPolicy) => FetchCycle(patchDatapolicy(dataPolicy))

export const onSchoolBySearch = (search) => FetchCycle(getSchoolBySearch(search))

export const GetServerConfig = () => FetchCycle(getServerConfigApi(), setServerStatus)
export const UpdateServerConfig = (data) => FetchCycle(updateServerConfigApi(data))
