import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Header from './Header'
import ModalDepartments from './ModalDepartments'
import {
  getDepartmentsList
} from '../../redux/department/action' 
import { 
  Spin,
  Table 
} from 'antd' 
import styles from './Departments.module.scss' 
import Edit from '../IconSVG/Edit.svg'
import EditGray from '../IconSVG/EditGray.svg'
import Delete from '../IconSVG/Delete.svg'
import DeleteGray from '../IconSVG/DeleteGray.svg'
 
class Departments extends Component {
  constructor(props){
    super(props)
    this.state ={
      isLoading: false, 
      departmentList: [],
      departmentItems: [],
      checkMouseOverEdit: false,
      modalVisible: false, 
      typeModal: null,
      item: {}
    }
  }
  columns = [ 
    {
      title: 'สังกัด',
      dataIndex: 'name',
      key: 'name',
      width: '450px',
      sorter: (strA, strB) => {
        let icmp = strA.name.toLowerCase().localeCompare(strB.name.toLowerCase())
        if (icmp !== 0) { 
          return icmp
        } 
        if (strA.name > strB.name) {
          return 1
        } else if (strA.name < strB.name) {
          return -1
        } else {
          return 0
        }
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      render:(edit,record,index)=> {
        const { checkMouseOverEdit, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkMouseOverEdit: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouseOverEdit: false})
        }
        return (
          <div className={styles.icon_row} >
            <img alt= 'edit' title='แก้ไข' onClick={e => {
              e.stopPropagation() 
              this.onOpenModal(record,'edit') 
            }} 
            onMouseOver={mouserOver}  onMouseLeave={mouseLeave} src={checkMouseOverEdit && indexRow === index ? Edit : EditGray}/></div>
        )
      },
      width: '50px'
    },
    {
      title: '',
      dataIndex: '',
      key: 'delete',
      render:(deleteRow,record,index)=> {
        const { checkMouserOverDelete, indexRow } = this.state
        const mouserOver = () => {
          this.setState({ checkMouserOverDelete: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouserOverDelete: false})
        }
        return (
          <div className={styles.icon_row}>
            <img alt='delete' title='ลบข้อมูล' onClick={e =>{
              e.stopPropagation()
              this.onOpenModal(record,'delete')
            }} onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkMouserOverDelete && indexRow === index ? Delete : DeleteGray}/></div>
        )
      }
    },
  ]
   
  componentDidMount= () => {
    this.fetchDepartments()
  }
  fetchDepartments = async() => {
    const { getDepartmentsList } = this.props
    this.setState({ isLoading: true })
    const res = await getDepartmentsList() 
    this.setState({ departmentList: res,departmentItems: res.items, isLoading: false })
  } 
  onOpenModal = (department, type) => {   
    if(type !== 'add'){
      this.fetchDepartments()
      this.setState({
        typeModal: type,
        item: department,
        modalVisible: true
      })
    }else{
      this.setState({ typeModal: type,modalVisible: true })
    } 
  } 
  onCloseModal = () => {
    this.setState({
      modalVisible: false
    })
  }
  setFiltersBySearch = (dataFilters,isEmptySearch) => { 
    if(!isEmptySearch) {
      this.setState({
        departmentItems: dataFilters
      }) 
    }else{
      this.fetchDepartments()
    } 
  }
  render(){
    const { isLoading,departmentItems,modalVisible,typeModal,item } = this.state 
    return (
      <div>
        <Spin size='large' tip='Loading...' spinning={ isLoading }>
          <Header dataSearch={departmentItems} setFiltersBySearch={this.setFiltersBySearch} onOpenModal={this.onOpenModal} departmentItems={departmentItems}/> 
          <Table
            scroll={{x:true}}
            rowKey={ (record,i) => i }
            columns={this.columns}
            pagination={false}
            dataSource={departmentItems}
            components={this.components}
            onRow={(record, index) => ({ 
              onMouseEnter: () => this.setState({ indexRow: index })
            })}
          /> 
          <ModalDepartments item={item} fetch={this.fetchDepartments} typeModal={typeModal} onCloseModal={this.onCloseModal} modalVisible={modalVisible}/>
        </Spin> 
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {   
    
  }
}
const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {  
      getDepartmentsList
    },
    dispatch
  )

export default connect(mapStateToProps,mapDispatchToProps)(Departments)