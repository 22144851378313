/* eslint-disable no-undef */
import React, { Component } from 'react' 
import PropTypes from 'prop-types'
import {
  Modal,
  Button,
  Input,
  Table,
  message,
} from 'antd' 
import { 
  IconSearch,
} from '../IconSVG'
import styles from './Exam.module.scss'
import DefaultGroup from '../IconSVG/DefaultGroup.svg'
import IconScienceExam from '../IconSVG/ExamScience.svg'
import IconMathExam from '../IconSVG/ExamMath.svg'
class ModalShareToGroup extends Component { 
  constructor(props) {
    super(props)
    this.state = {
      selectedRowKeys:[] 
    }
  }

  columns = [
    { title: '',
      dataIndex: 'logo',
      align: 'center',
      width: 70,
      render: logo => <img src={logo ? logo : DefaultGroup} alt='group_logo' className={styles.logoGroup}/>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      render: (name,record) => { 
        return (<div>
          <p style={{marginBottom: 0}}>{name}</p>
          <p className='text-regular' style={{color: '#2F2F2F', fontSize: 10, opacity: 0.5, marginBottom: 0 }}>{record.code}</p>
        </div>)
      },
    },
    {
      title: 'รหัสกลุ่ม',
      dataIndex: 'code',
    }
  ]

  
  onAddToGroup = (e) => {
    e.preventDefault() 
    if (!this.props.examSet) return  
    if(this.state.selectedRowKeys.length === 0) return message.error('กรุณาเลือกกลุ่ม') 
    const { selectedRowKeys } = this.state
    this.props.onAddExamSetToGroup(this.props.examSet._id, selectedRowKeys)
    this.setState({ selectedRowKeys: [] })
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys })
  }

  render() {
    const { examSet, isVisible, groupList } = this.props 
    const { selectedRowKeys } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
      // onChange: (selectedRowKeys, selectedRowKeys) => {
         
      //   this.setState({ selectedRowKeys: selectedRowKeys })
      // },
    } 
    let examSetInfo
    if (isVisible) {
      const iconSubject = examSet.subject === 'คณิตศาสตร์' ? <img alt='icon-math-exam' style={{ width: '65px' }} src={IconMathExam}/> : <img alt='icon-science-exam' style={{ width: '65px' }} src={IconScienceExam}/>
       
      examSetInfo = (
        <div className={styles.modalExamSetInfo}>
          <div style={{display: 'flex'}}>
            <div>
              {iconSubject}
            </div>
            <div>
              <p className='text-medium' style={{color: '#05007A', fontSize: 18, marginBottom: 0}}>{examSet.name}</p>
              <p className='text-regular' style={{color: '#2F2F2F', fontSize: 10, opacity: 0.5, marginBottom: 0 }}>{examSet.code}</p>
              <p className='text-regular' style={{color: '#2F2F2F', fontSize: 10, marginBottom: 0 }}>
                {examSet.type === 'G' ? 'ตามหลักสูตร' : examSet.type === 'C' ? 'แข่งขัน' :  examSet.type === 'CAT' ? 'CAT' : 'กำหนดเอง'}
              </p>
            </div>
          </div>
          <div>
            <Input
              prefix={ window.screen.width  > 991 ? <IconSearch /> : null }
              placeholder='ค้นหา'
              maxLength={'100%'}
              className={styles.inputSearchGroup}
              onPressEnter={ e => this.props.onSearchGroupList(e.target.value) }
              suffix={ window.screen.width <= 991 ? <IconSearch /> : false }
            />
          </div>
        </div>
      )
    }
    let numSelected = selectedRowKeys.length
    return (
      <Modal
        visible={isVisible}
        closable={true}
        // width='55%'
        className={ styles.modal_share_to_group }
        onCancel={this.props.onCancel}
        centered
        footer={<div className='flex-space-center'>
          <Button 
            key='submit' 
            type='primary' 
            htmlType='submit' 
            style={{maxWidth: '180px', width: '100%', color: '#FFFFFF'}}
            onClick={this.onAddToGroup}
          >
            เพิ่ม {numSelected} ข้อสอบเข้ากลุ่มนี้
          </Button>
        </div>
        }
      >
        {examSetInfo}
        <Table
          rowKey = {(record) => record._id}
          rowSelection={rowSelection}
          columns={this.columns}
          dataSource={groupList}
        />
      </Modal>
    )
  }
}

ModalShareToGroup.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onAddExamSetToGroup: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  examSet: PropTypes.object,
  groupList: PropTypes.array.isRequired,
  onSearchGroupList: PropTypes.func.isRequired,
}
 
export default ModalShareToGroup