import { connect } from 'react-redux'
import {
  getStats, getStatsDetail, getStatsType,
  setSelectYear, setSelectMonth, statTestingTransaction
} from '../../../redux/statistic/action'

import Testing from './Testing'

const mapStateToProps = (state) => ({
  collection: state.statistic.collection || [],
  detail: state.statistic.detail || [],
  type: state.statistic.type || [],
  year: state.statistic.year || {},
  month: state.statistic.month || {}
}) 

const mapDispatchToProps = {
  loadStats: (type, year) => getStats(type, year),
  loadStatsDetail: (type, year, month, detail) =>
    getStatsDetail(type, year, month, detail),
  loadStatsType: (type, year, month) =>
    getStatsType(type, year, month),
  setYear: (year) => setSelectYear(year),
  setMonth: (month) => setSelectMonth(month),
  getStatTestingTransaction: (year, month) => statTestingTransaction(year, month)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Testing)
