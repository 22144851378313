import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types' 
import {
  Button,
  Form,
  Input
} from 'antd'
import { 
  Link
} from 'react-router-dom'
import { generateInputForm } from '../../util/formGenerator' 
const FormItem = Form.Item

const formItemLayout = {
  colon: false,
  labelAlign: 'left',
  hideRequiredMark: true,
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
}

class FormLogin extends Component {  
  handleSubmit = (e) => { 
    e.preventDefault()
    this.props.form.validateFieldsAndScroll(async(err, values) => {
      if (!err) {  
        this.props.onSubmit(values)
      }
    }) 
  }

  render() {
    const { form, errorLogin } = this.props 
    const inputEmail = generateInputForm({ fieldName: 'email', form, label: 'ชื่อผู้ใช้งาน / อีเมล', limit: 254, customRules: [
      { required: true, message: 'กรุณากรอก Username หรือ อีเมล' },
    ], props: {
      autoComplete: 'username'
    } })

    return (
      <Fragment>
        <div className='flex-space-between login-register' style={{ marginBottom: 15 }}>
          <h2 className='text-medium mb-0' style={{ color: '#05007A' }}>เข้าสู่ระบบ</h2>
          <Link className='text-link text-thin ' style={{ fontSize: 18}} to='/register' tabIndex='-1'>สมัครสมาชิก</Link>
        </div>
        <Form {...formItemLayout} onSubmit={this.handleSubmit} >

          {inputEmail}

          <FormItem className='login-register'>
            <label htmlFor='form_login_password' className='ant-form-item-required ant-form-item-no-colon text-regular' title='รหัสผ่าน'>รหัสผ่าน</label>
            <Link className='text-link text-thin ' style={{ float: 'right' }} to='/forget-password' tabIndex='-1'>ลืมรหัสผ่าน</Link>
            {form.getFieldDecorator('password', {
              rules: [
                { required: true, message: 'กรุณาใส่รหัสผ่าน' }
              ],
            })(<Input
              type='password'
              placeholder= 'รหัสผ่าน'
              maxLength={20}
              autoComplete='password'
            />)
            }
          </FormItem>
          { errorLogin ? <p className='text-regular text-sm' style={{ color: '#F54B5E'}}>{errorLogin}</p> : '' }

          <Button type='primary' htmlType='submit' id='loginSubmitBtn' style={{ width: '100%', marginTop: 10 }}>
            เข้าสู่ระบบ
          </Button>
        </Form> 
      </Fragment>
    )
  }
}

FormLogin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
} 
const WrappedFormLogin = Form.create({ name: 'form_login' })(FormLogin)
export default WrappedFormLogin