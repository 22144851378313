import React from 'react'
import {
  Card,
  Row,
  Col,
} from 'antd'
import styles from './CardLightMap.module.scss'
import { LightAnimation } from '../../components/animations'
export default (props) => (
  <div className = {styles.cardLightMap}>
    <Row> 
      <Col xs={{ span: 24 }} md={{ span: 14, offset: 5 }} lg={{ span: 12, offset: 6 }}>
        <Card bordered={false} className={styles['card']}>
          {props.children}
        </Card>
        <div style={{textAlign: 'center', marginTop: '25px'}} className = {styles.divLightMap}>
          <div className={styles.animation_content1}>
            <div className='mobile'>
              <LightAnimation width={58} height={81} />
            </div>

            <div className='desktop'>
              <LightAnimation width={116} height={161} />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>
)
