import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
} from 'antd'
import styles from '../../Register/Register.module.scss'
class ModalTermMember extends Component {

  constructor(props) {
    super(props)
    this.state = {
      termConditionCheck: false,
      dataPrivacyCheck: false
    }
  }

  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    })
  }

  render() {
    const { isVisible, onOK, termAndCondition } = this.props

    return (
      <Modal
        visible={isVisible}
        closable={false}
        width={'100%'}
        wrapClassName={styles.modalTermWrap}
        destroyOnClose={true}
        footer={
          <div>
            <Button type = 'primary' onClick={()=>{
              onOK()
              window.location.reload()
            }} >ยอมรับเงื่อนไขและข้อกำหนด</Button>
          </div>
        }
      >
        <div className={`${styles.modalTerm} text-thin`} style={{ overflow: 'auto', height: '600px' }}>
          <h2
            className='text-medium text-center text-lg'
            style={{
              color: '#05007A',
              marginTop: '10px',
              marginBottom: '30px',
              lineHeight: '44px'
            }}
          >
            {termAndCondition ? termAndCondition.title : ''}
          </h2>
          
          <div  dangerouslySetInnerHTML={{__html: termAndCondition.content}} />
          <h2
            className='text-medium text-center text-lg'
            style={{
              color: '#05007A',
              marginTop: '10px',
              marginBottom: '30px',
              lineHeight: '44px'
            }}
          >
          </h2>
        </div>
      </Modal>
    )
  }
}

ModalTermMember.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onOK: PropTypes.func.isRequired,
  // onCancel: PropTypes.func.isRequired,
}

export default ModalTermMember