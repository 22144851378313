import React, { Component, Fragment } from 'react'
import { Input, Button } from 'antd'

import IconSearch from '../IconSVG/Search.svg'

import styles from './SearchBar.module.scss'

class SearchBar extends Component {
  state = {
    searchKey: ''
  }

  static defaultProps = {
    input: false
  }

  handleKeyPress = (e) => {
    this.setState({
      searchKey: e.target.value
    })
  }

  searchButton (text, key) {
    return (
      <Button type='primary'
        style={{right: '0'}}
        onClick={() => this.props.onSearch(key)}
      >
        <img src={IconSearch} />{text || ''}
      </Button>
    )
  }

  render() {
    const { searchKey } = this.state
    const { input, placeholder, onSearch} = this.props 
    return (
      <div className={styles.search_bar}>
        {
          input ? (
            <Input
              suffix={this.searchButton(null,searchKey)}
              placeholder={ placeholder }
              maxLength={ 100 }
              className={ styles.inputSearch }
              onKeyUp={ this.handleKeyPress }
              onPressEnter={() =>  onSearch( searchKey )  } 
            />
          ) : (
            <Fragment>
              <div className='mobile'>
                {/* {this.searchButton()} */}
                <Input
                  suffix={this.searchButton(null,searchKey )}
                  placeholder={ placeholder }
                  maxLength={ 100 }
                  className={ styles.inputSearch }
                  onKeyUp={ this.handleKeyPress }
                  onPressEnter={() => onSearch( searchKey )}
                />
              </div>

              <div className='desktop'>
                <Input
                  suffix={this.searchButton('ค้นหา', searchKey, )}
                  placeholder={ placeholder }
                  maxLength={ 100 }
                  className={ styles.inputSearch }
                  onKeyUp={ this.handleKeyPress }
                  onPressEnter={() => onSearch( searchKey )}
                />
              </div>
            </Fragment>
          )
        }
      </div>
    )
  }
}

export default SearchBar
