import React, { Component }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Spin,
  Switch,
  Modal,
  Button,
  Upload,
  message
} from 'antd'
import {
  getTeacherList,
  getStudentList,
  getSuperTeacherList,
  getAdminList,
  createUser,
  importExcel,
  openUserDetail,
  updateUser,
  removeUser,
  getFetchUserAll,
  onCheckAddress
} from '../../redux/user/action'
import {
  getProvincesList
} from '../../redux/provinces/action'
import IconButtonExcelUpload from '../IconSVG/IconButtonExcelUpload.svg'
import FileIcon from '../IconSVG/FileIcon.svg'
import IconError from '../IconSVG/IconError.svg'
import DefaultProfile from '../IconSVG/DefaultProfile.svg'
import ListUsers from './ListUsers'
import AddUser from './AddUser'
import EditUser from './EditUser'
import { ViewUser } from './ViewUser'
import styles from './User.module.scss'
import * as XLSX from 'xlsx'

class User extends Component {

  columns = [
    { title: '', dataIndex: 'profileImage',
      align: 'center',
      render: profileImage => <img src={profileImage ? profileImage : DefaultProfile} alt='profileImage' className={styles.imageProfile} />,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
    },
    {
      title: 'โรงเรียน',
      dataIndex: 'schoolName',
    },
    {
      title: 'วันที่เป็นสมาชิก',
      dataIndex: 'createdAt',
    },
    {
      title: 'ใช้งานล่าสุด',
      dataIndex: 'updatedAt',
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
    },
    {
      title: 'การใช้งาน',
      render: (text, user) => (
        <span className={styles.tableAction}>
          <Switch checkedChildren='เปิด' unCheckedChildren='ปิด' defaultChecked={!user.isBanned} className={styles.switch} />
        </span>
      ),
    },
    {
      title: 'ยืนยันตัวตน',
      render: (text, user) => (
        <span className={styles.tableAction}>
          { user.isConfirmationEmail ? 'เปิด': <Switch unCheckedChildren='ปิด' defaultChecked={user.isConfirmationEmail} className={styles.switch} />}
        </span>
      ),
    },
  ]

  
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      onAction: 'viewAllUser',
      keyDelete: 1,
      resError: false,
      resErrorMessage: '',
      excelPopup: false,
      excelPopupAlert: false,
      deletePopupAlert: false,
      excelErrorMessage: '',
      deleteErrorMessage: '',
      userDataEdit: '',
      userData:'',
      provincesList: [],
      usersAll: [],
      filtersUser : {
        page: {
          teacher: 1,
          superTeacher: 1,
          student: 1,
          admin: 1
        },
        searchKeyword: '',
        filters: {}
      },
      uploadingFile: false
    }
  }

  componentDidMount = () => {
    this.fetchUsers()
    this.fetchProvinces() 
  }
  UNSAFE_componentWillReceiveProps() {
    this.setState({
      onAction : 'viewAllUser'
    })
  }

  onAddUser = () => {
    this.setState({isShowAddGroupModal: true})
  }

  fetchUsers = async (options = {}) => {
    const { filtersUser } = this.state
    if (options.searchKeyword !== undefined ) {
      filtersUser.searchKeyword = options.searchKeyword
    }
    if(filtersUser.page !== undefined){
      filtersUser.page['teacher'] = 1
      filtersUser.page['student'] = 1
      filtersUser.page['superTeacher'] = 1
      filtersUser.page['admin'] = 1 
    }
    this.setState({ isLoading: true })
    await Promise.all([
      this.props.getTeacherList(filtersUser),
      this.props.getStudentList(filtersUser),
      this.props.getSuperTeacherList(filtersUser),
      this.props.getAdminList(filtersUser)
    ])
    this.setState({ isLoading: false, filtersUser })
  }
  fetchUsersAll = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.getFetchUserAll()  
    this.setState({ isLoading: false }) 
    return res
  }
  fetchProvinces = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.getProvincesList() 
    this.setState({ provincesList:res, isLoading: false })
  }
  addUser = async (data) => {
    let userData = {
      prefixName: data.prefixName,
      firstName: data.firstName,
      lastName: data.lastName,
      school: {
        name: {text: data.schoolName},
        province : { id: data.province.id }
      },
      email: data.email,
      password: data.password,
      gender: data.gender,
      role: data.role,
      username: data.username
    }
    switch (data.role){
      case 'นักเรียน': userData.role = 'student'; break
      case 'ครู': userData.role = 'teacher'; break
      case 'ครูพิเศษ': userData.role = 'super_teacher'; break
      case 'ผู้ดูแลระบบ': userData.role = 'admin'; break
      default: break
    }
    
    await this.props.createUser(userData).then(res => {
      if (res.error) {
        this.setState({
          resError: true,
          resErrorMessage: res.error
        })
      } else {
        this.setState({ onAction: 'viewAllUser' })
        this.fetchUsers()
      }
    })
  }

  onUpdateUser = async (data) => {
    try {
      await this.props.updateUser(data.id,data)
      this.successUpdateMessage()
      await this.fetchUsers()
      this.setState({
        onAction: 'viewAllUser'
      })
    } catch (e) {
      this.errorUpdateMessage()
    }
  }

  openUserDetail = async(user) => { 
    const res = await this.props.openUserDetail(user.id)
    this.user = res
    this.setState({onAction: 'viewUser'}) 
  }

  user = {}
  openUploadExcelWindow = () => {
    this.setState({ excelPopup: true })
  }
  closeUploadExcelWindow = () => {
    this.setState({ excelPopup: false })
  }
  closeExcelErrorAlart = () => {
    this.setState({
      excelPopupAlert: false
    })
  }
  closeUploadAndAlert = () => {
    this.setState({
      excelPopupAlert: false,
      excelPopup: false
    })
  }
  successExcelMessage = () => {
    message.success('อัปโหลดสำเร็จเรียบร้อย',5)
  }
  successDeleteMessage = () => {
    message.success('ลบผู้ใช้เรียบร้อย',5)
  }
  successUpdateMessage = () => {
    message.success('อัปเดตข้อมูลเรียบร้อย',5)
  }
  errorUpdateMessage = () => {
    message.error('อัปเดตข้อมูลไม่สำเร็จ', 5)
  }
  onUpload = () => {
    if(this.state.file === undefined || this.state.file === null){
      this.setState({
        excelErrorMessage: 'กรุณาเลือกไฟล์',
        excelPopupAlert: true
      })
    }else{
      // eslint-disable-next-line no-undef
      const reader = new FileReader(this.state.file)
      reader.onload = (evt) => { // evt = on_file_select event
        /* Parse data */
        const { result } = evt.target
        const wb = XLSX.read(result, {type: 'binary'})
        /* Get first worksheet */
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws)

        const users = data.map(item => ({
          prefixName: item['คำนำหน้า'],
          firstName: item['ชื่อจริง*'],
          lastName: item['นามสกุล*'],
          username: item['username*'],
          email: item['อีเมล*'],
          schoolName: item['โรงเรียน*'],
          password: item['รหัสผ่าน'],
          role: item['ประเภทผู้ใช้งาน'],
        }))
        this.setState({
          uploadingFile: true
        })
        this.props.importExcel({ items: users }).then(res => {
          if (res.error) {
            this.setState({ 
              excelErrorMessage: res.error,
              excelPopupAlert: true,
              uploadingFile: false
            })
          } else {
            this.fetchUsers()
            this.successExcelMessage()
            this.setState({ excelPopup: false, uploadingFile: false })
          }
        })
      }

      reader.readAsBinaryString(this.state.file)
    }
  }
  
  //Setting upload only one file
  handleUploadChange = info => {
    let fileList = [...info.fileList]
    fileList = fileList.slice(-1)
    fileList = fileList.map(file => {
      if (file.response) {
        file.status = 'uploading'
        file.status = 'done'
      }
      return file
    })
    let file = null
    if(fileList.length !== 0){
      file = fileList[0].originFileObj
    }
    this.setState({ 
      fileList, 
      file: file,
      fileContent: file
    })
  }

  onPaginate = async (role, pagination) => {
    let {filtersUser} = this.state
    filtersUser.page[role] = pagination.current
    if (role == 'student') {
      await this.props.getStudentList(filtersUser)
    } else if (role == 'teacher') {
      await this.props.getTeacherList(filtersUser)
    } else if (role == 'super_teacher') {
      await this.props.getSuperTeacherList(filtersUser)
    } else if (role == 'admin') {
      await this.props.getAdminList(filtersUser)
    }
    this.setState({ filtersUser })
  }
  
  onBanUser = async (user, isBan) => {
    await this.props.updateUser(user.id, { isBanned: !isBan })
  }

  onToggleOfflineLoginable = async (user, isOfflineLoginable) => {
    await this.props.updateUser(user.id, { isOfflineLoginable })
  }

  onEditUser = async (userDataEdit) => {
    const userData = await this.props.openUserDetail(userDataEdit.id)
    this.setState({ 
      userDataEdit: userData,
      onAction: 'editUser'
    })
  }
  render() {
    const { teacherList, studentList, superTeacherList, adminList } = this.props
    const { onAction,userDataEdit , filtersUser } = this.state  
    const uploadProps = {
      name: 'file',
      action: '',
      accept: '.xlsx',
      onChange: this.handleUploadChange,
      multiple: false,
      defaultFileList:[{
        status: 'done'
      }]
    }
    return ( 
      <div 
        className={styles.groupDiv}>
        <Modal 
          visible={this.state.excelPopup} 
          onCancel={this.closeUploadExcelWindow}
          className={styles.modal_upload_file}
          footer={[
            <Button className={styles.upload_file_button} key='back' onClick={this.closeUploadExcelWindow}>ยกเลิก</Button>,
            <Button className={styles.upload_file_button} key='submit' type='primary' onClick={this.onUpload} loading={this.state.uploadingFile}>
              <img src={FileIcon} alt='FileIcon' />อัปโหลด
            </Button>
          ]}
          bodyStyle={{ textAlign:'center'}}
        >
          <div className={styles.upload_window_content}>
            <div className={styles.upload_Icon_file}> 
              <img src={IconButtonExcelUpload} alt='IconButtonExcelUpload' />
            </div>
            <div className={styles.upload_window_title}>Upload Excel</div>
            <div className={styles.clickable}>
              <Upload 
                {...uploadProps}
                fileList={this.state.fileList}
                onChange={this.handleUploadChange}
              >
                <img src={FileIcon} alt='FileIcon' />
                <span>แนบไฟล์</span>
              </Upload>
            </div>
          </div>
        </Modal>
        <Modal 
          visible={this.state.deletePopupAlert} 
          onCancel={this.closeExcelErrorAlart}
          className={styles.modal_upload_file}
          footer={[
            <Button className={styles.upload_file_button} key='back' onClick={this.closeUploadAndAlert}>ยกเลิก</Button>,
            <Button className={styles.upload_file_button} key='submit' type='primary' onClick={this.closeExcelErrorAlart}>ลองอีกครั้ง</Button>
          ]}
          bodyStyle={{ textAlign:'center'}}
        >
          <div className={styles.upload_window_content}>
            <div className={styles.upload_Icon_file}> 
              <img src={IconError} alt='IconError' />
            </div>
            <div className={styles.upload_window_title}>Delete Error</div>
            <p>ลบผู้ใช้ผิดพลาด </p>
            <p style={{ color:'red'}}>{this.state.deleteErrorMessage}</p>
            <p>กรุณาลองใหม่อีกครั้ง </p>
          </div>
        </Modal>
        <Modal 
          visible={this.state.excelPopupAlert} 
          onCancel={this.closeExcelErrorAlart}
          className={styles.modal_upload_file}
          footer={[
            <Button className={styles.upload_file_button} key='back' onClick={this.closeUploadAndAlert}>ยกเลิก</Button>,
            <Button className={styles.upload_file_button} key='submit' type='primary' onClick={this.closeExcelErrorAlart}>ลองอีกครั้ง</Button>
          ]}
          bodyStyle={{ textAlign:'center'}}
        >
          <div className={styles.upload_window_content}>
            <div className={styles.upload_Icon_file}> 
              <img src={IconError} alt='IconError' />
            </div>
            <div className={styles.upload_window_title}>Upload Error</div>
            <p>อัปโหลดไฟล์ผิดพลาด </p>
            <p style={{ color:'red'}}>{this.state.excelErrorMessage}</p>
            <p>กรุณาลองใหม่อีกครั้ง </p>
          </div>
        </Modal>

        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          { 
            onAction === 'viewAllUser' ? 
              <ListUsers 
                fetchUsers = {this.fetchUsers}
                fetchUsersAll = {this.fetchUsersAll}
                teacherList={teacherList} 
                studentList={studentList} 
                superTeacherList={superTeacherList} 
                adminList={adminList} 
                filtersUser={filtersUser}
                onClickAddUser={() => this.setState({ onAction: 'addingUser' })}
                onEditUser = {this.onEditUser}
                openUploadExcelWindow={this.openUploadExcelWindow} 
                openUserDetail={this.openUserDetail}
                onPaginate={this.onPaginate}
                onBanUser={this.onBanUser}
                onToggleOfflineLoginable={this.onToggleOfflineLoginable}
                onDeleteUser={ async(userId) => 
                  await this.props.removeUser(userId).then(res => {
                    if (res.error) {
                      this.setState({ 
                        deleteErrorMessage: res.error,
                        excelPopupAlert: true
                      })
                    } else {
                      this.successDeleteMessage()
                      this.fetchUsers()
                    }
                  })
                }
              /> : 
              ''
          }
          
          { 
            onAction === 'editUser' ? 
              <EditUser
                userDataEdit= {userDataEdit}
                onClickCloseEditUser={()=> this.setState({ onAction: 'viewAllUser'})}
                gotoListUser = {() => this.setState({ resError:false , onAction: 'viewAllUser' })}
                onUpdateUser = {this.onUpdateUser}
              /> 
              : 
              ''
          } 
          { 
            onAction === 'addingUser' ? 
              <AddUser 
                onAddUser={this.onAddUser} 
                onSubmitAddUser={this.addUser} 
                gotoListUser = {() => this.setState({ resError:false , onAction: 'viewAllUser' })}
                resErr={this.state.resError}
                resErrorMessage={this.state.resErrorMessage}
              /> 
              : 
              ''
          } 
          {
            onAction === 'viewUser' ?
              <ViewUser 
                user={this.user}
                province={this.state.provincesList.items}
                back={() => this.setState({onAction: 'viewAllUser'})}
                onCheckAddress={this.props.onCheckAddress}
                fetchUsers={this.fetchUsers}
                openUserDetail={this.openUserDetail}
              /> :
              ''
          }
        </Spin>
      </div>
    )
  }
}



const mapStateToProps = state => {
  return {
    auth: state.auth.profile,
    teacherList: state.user.teacherList,
    studentList: state.user.studentList,
    superTeacherList: state.user.superTeacherList,
    adminList: state.user.adminList,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTeacherList,
      getStudentList,
      getSuperTeacherList,
      getAdminList,
      createUser,
      updateUser,
      importExcel,
      openUserDetail,
      removeUser,
      getProvincesList,
      getFetchUserAll,
      onCheckAddress
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(User)