import React from 'react'
import Lottie from 'react-lottie'

import cgHeader from '../srcAnimationJson/CG Header 2.json'

import './CGHeader.module.scss'

class CGHeader extends React.Component {
  static defaultProps = {
    height: 724.97,
    width: 1164
  }

  render () {
    const { width, height } = this.props
    const headerEffect = {
      loop: true,
      autoplay: true,
      animationData: cgHeader,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <div className='atom-animation' style={{ height }}>
        <Lottie
          options={headerEffect}
          height={height}
          width={width}
        />
      </div>
    )
  }
}

export default CGHeader
