import React, { Component }  from 'react'  
import {  Button,Input,  } from 'antd'   
import styles from './Subjects.module.scss'
import {
  IconSearch,
} from '../IconSVG'  
import AddDataIcon from '../../assets/Icon/AddData.svg'  
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getOTIMSSubjectList } from '../../redux/subjects/action'

import ModalSubject from './ModalSubject'
class Header extends Component {
  constructor(props){
    super(props)
    this.state = {
      subjectsFilters: [],
      searchKey: null,
      searchKeyword: null,
      checkEnter : false,
      modalVisible: false
    }
  }
  handleKeyPress = (e) => {
    setTimeout(()=>{
      this.setState({
        searchKey: e
      })
      if(e === '' && this.state.checkEnter === true){
        this.handleSearch()
        this.setState({
          checkEnter : false
        })
      }      
    },50)
  } 
  handleSearch = async(value) => {
    const { dataSearch,setSubjectsFiltersBySearch } = this.props
    let { searchKeyword, subjectsFilters } = this.state
    let isEmptySearch = true
    searchKeyword = value  
    this.setState({
      checkEnter : true
    }) 
    if (searchKeyword) {  
      isEmptySearch = false
      subjectsFilters = dataSearch.filter(r => r.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1)  
    }    
    setSubjectsFiltersBySearch(subjectsFilters, isEmptySearch) 
  }
  onCloseModal = () => {this.setState({ modalVisible: false})} 

  fetchSubject = async() => {
    const data = await this.props.getOTIMSSubjectList()
    this.props.fetch()
  }

  render(){   
    let { searchKey , modalVisible } = this.state
    const addFeatureHeader = (
      <div style={{display: 'flex'}}> 
        <Input
          suffix={ 
            <Button 
              type='primary' 
              style={{paddingLeft: 5, paddingRight: 10}} 
              onClick={() => this.handleSearch(searchKey)} 
            >
              <IconSearch style={{verticalAlign: 'middle'}}/>ค้นหา
            </Button>
          }
          placeholder='ค้นหา (ชื่อวิชา)'
          maxLength={100}
          className={styles.inputSearch} 
          // onKeyUp={this.handleKeyPress}
          onChange = {(e)=>this.handleKeyPress(e.target.value)}
          onPressEnter={() => this.handleSearch(searchKey)} 
        />
        <div style={{ paddingRight: '16px' }}>
          {/* <Button type='primary' onClick={e => {
          e.stopPropagation(); this.setState({ modalVisible: true })
        }}><img alt='add-data-icon' src={AddDataIcon}/>เพิ่มข้อมูล</Button> */}
          <Button type='primary'
            onClick={() => this.fetchSubject()}
          >
            ปรับปรุงข้อมูลวิชา
          </Button>
        </div>
      </div>
    )  
    return (
      <div>
        <div className='flex-space-between' style={{marginBottom: '30px'}}>
          <h1 style={{fontSize: '28px', color: '#05007A', margin: '0'}}>วิชา</h1>
          {addFeatureHeader} 
          {/* <ModalSubject fetch={this.props.fetch} visible={modalVisible} formName={'Add_Subject'} onCancel={this.onCloseModal} /> */}
        </div> 
      </div>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOTIMSSubjectList
    },
    dispatch
  )
 
export default connect(null, mapDispatchToProps)(Header)