exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "tr {\n  vertical-align: text-top; }\n\n.User_imageProfile__1t_XR {\n  width: 38px;\n  height: 38px;\n  border-radius: 50%; }\n\n.User_clickable__2b-IQ {\n  cursor: pointer; }\n\n.User_upload_window_content__3QV6C {\n  font-weight: 500;\n  text-align: center; }\n  .User_upload_window_content__3QV6C .User_upload_Icon_file__13Bxq {\n    margin-bottom: -1.5em; }\n  .User_upload_window_content__3QV6C .User_upload_window_title__TkHIV {\n    font-size: 16pt;\n    padding-bottom: 1em; }\n  .User_upload_window_content__3QV6C div {\n    text-align: center;\n    display: block; }\n  .User_upload_window_content__3QV6C .User_icon_delete_profile__1X2D9 {\n    padding-bottom: 1rem; }\n\n.User_group_of_upload_modal__qxJrs .User_modal_upload_file__2z2DA {\n  text-align: center; }\n\n.User_group_of_edit_page__2BaXy {\n  padding-top: 1rem; }\n  .User_group_of_edit_page__2BaXy .User_group_of_avatar__2siYg {\n    text-align: left; }\n  .User_group_of_edit_page__2BaXy .User_group_of_image_avatar__2Izuh {\n    clip-path: circle(50% at 50% 50%); }\n\n.User_upload_file_button__34-YA {\n  width: 35%; }\n\n.User_link__3mgQe {\n  color: #006FAD;\n  cursor: pointer; }\n\n.User_profileImageSection__1Dwa6 {\n  display: flex;\n  justify-content: center; }\n  .User_profileImageSection__1Dwa6 .User_userImageProfile__1g0jz {\n    margin-top: 1.8em;\n    width: 50%;\n    max-width: 120px;\n    border-radius: 50%; }\n\n.User_profileContentSection__1awxB {\n  margin-top: 3em; }\n  .User_profileContentSection__1awxB .User_title__3dnft {\n    font-size: 24pt;\n    color: #05007A; }\n  .User_profileContentSection__1awxB .User_subtitle__2JB8l {\n    color: #828282;\n    font-weight: 600;\n    max-width: 200px;\n    width: 16rem;\n    display: inline-block; }\n  .User_profileContentSection__1awxB .User_row__1-WBR {\n    margin-top: 1.2em;\n    margin-bottom: 1.4em; }\n\n.User_header_profile_user__3scw6 {\n  display: flex;\n  justify-content: space-between; }\n", ""]);

// Exports
exports.locals = {
	"imageProfile": "User_imageProfile__1t_XR",
	"clickable": "User_clickable__2b-IQ",
	"upload_window_content": "User_upload_window_content__3QV6C",
	"upload_Icon_file": "User_upload_Icon_file__13Bxq",
	"upload_window_title": "User_upload_window_title__TkHIV",
	"icon_delete_profile": "User_icon_delete_profile__1X2D9",
	"group_of_upload_modal": "User_group_of_upload_modal__qxJrs",
	"modal_upload_file": "User_modal_upload_file__2z2DA",
	"group_of_edit_page": "User_group_of_edit_page__2BaXy",
	"group_of_avatar": "User_group_of_avatar__2siYg",
	"group_of_image_avatar": "User_group_of_image_avatar__2Izuh",
	"upload_file_button": "User_upload_file_button__34-YA",
	"link": "User_link__3mgQe",
	"profileImageSection": "User_profileImageSection__1Dwa6",
	"userImageProfile": "User_userImageProfile__1g0jz",
	"profileContentSection": "User_profileContentSection__1awxB",
	"title": "User_title__3dnft",
	"subtitle": "User_subtitle__2JB8l",
	"row": "User_row__1-WBR",
	"header_profile_user": "User_header_profile_user__3scw6"
};